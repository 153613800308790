<template>
  <v-container class="requirement-actions ma-0 pa-0">
      <v-row class="flex-wrap justify-space-around align-center px-0 ma-1 d-flex">
        <v-menu
          :open-on-hover="true"
          location="bottom"
          :disabled="!requirement.reactions.length"
        >
          <template #activator="{ props }">
            <v-btn
              variant="text"
              size="small"
              class="ma-0 px-1 user-reaction-btn"
              tabindex="-1"
              @click="!$store.state.isWorkflowDisabled && updateReaction('UPVOTE', currentReaction)"
              v-bind="props"
              :disabled="$store.state.isWorkflowDisabled"
            >
              <v-icon v-if="currentReaction === 'UPVOTE'" size="small" icon="mdi-thumb-up" />
              <v-icon v-else size="small" icon="mdi-thumb-up-outline"/>
              {{ upvotes.length || "" }}
            </v-btn>

            <v-btn
              variant="text"
              size="small"
              class="ma-0 px-1 user-reaction-btn"
              tabindex="-1"
              @click="updateReaction('DOWNVOTE', currentReaction)"
              v-bind="props"
              :disabled="$store.state.isWorkflowDisabled"
            >
              <v-icon v-if="currentReaction === 'DOWNVOTE'" size="small" icon="mdi-thumb-down" />
              <v-icon v-else size="small" icon="mdi-thumb-down-outline"/>
              {{ downvotes.length || "" }}
            </v-btn>
          </template>

          <v-card>
            <v-list-subheader v-if="upvotes.length" class="votes-list-subheader ml-3">
              <v-icon size="small" class="mr-1" icon="mdi-thumb-up-outline" /> Upvotes
            </v-list-subheader>

            <ul v-if="upvotes.length" class="votes-list">
              <li v-for="vote in upvotes" :key="vote.user.id">
                {{ vote.user.firstname }} {{ vote.user.lastname }}
              </li>
            </ul>

            <v-list-subheader v-if="downvotes.length" class="votes-list-subheader ml-3">
              <v-icon size="small" class="mr-1" icon="mdi-thumb-up-outline" /> Downvotes
            </v-list-subheader>

            <ul v-if="downvotes.length" class="votes-list">
              <li v-for="vote in downvotes" :key="vote.user.id">
                {{ vote.user.firstname }} {{ vote.user.lastname }}
              </li>
            </ul>
          </v-card>
        </v-menu>

        <button
          v-if="!compactReplies"
          class="pa-0 ma-0 reply-btn"
          @click="$emit('open-replies')"
          :disabled="$store.state.isWorkflowDisabled"
          tabindex="-1"
        >
          <span class="text-caption">Reply</span>
          <v-icon size="x-small" class="ml-1" icon="mdi-message-outline" />
        </button>

        <v-btn
          v-else
          size="x-small"
          variant="text"
          class="new-reply-btn ma-0"
          @click="$emit('open-replies')"
          :disabled="$store.state.isWorkflowDisabled"
          tabindex="-1"
          icon="mdi-message-outline"
        />

        <requirement-attachments-dialog :item="requirement">
          <template #default="{ props, attachments }">
            <v-btn
              size="small"
              variant="text"
              class="user-attachment-btn ma-0 px-1 user-reaction-btn"
              v-bind="props"
              tabindex="-1"
              :disabled="$store.state.isWorkflowDisabled"
            >
              <v-icon size="small" icon="mdi-paperclip" />
              {{ (attachments && attachments.length) || "" }}
            </v-btn>
          </template>
        </requirement-attachments-dialog>

        <jira-issue-status-menu
          v-if="jiraData2?.[requirement.id]"
          :requirement="jiraData2?.[requirement.id]"
        >
          <template #default="{ props }">
            <v-btn
              icon
              size="small"
              variant="text"
              class="ma-0"
              v-bind="props"
              tabindex="-1"
            >
              <v-icon size="small">custom:JiraIcon</v-icon>
            </v-btn>
          </template>
        </jira-issue-status-menu>
        <template v-else>
          <v-progress-circular
            v-if="loadingJiraInfo"
            size="14"
            width="1"
            indeterminate>
          </v-progress-circular>
        </template>

        <workflow-delete-item-dialog
          #default="{ props }"
          @open="pinTheDialog"
          :itemType="'Card'"
          :cardId="requirement.id"
          :hideIcon="false"
        >
          <v-btn
            v-bind="props"
            size="small"
            variant="text"
            class="ma-0 px-1 user-reaction-btn"
            tabindex="-1"
            :disabled="$store.state.isWorkflowDisabled"
          >
            <v-icon size="small" icon="mdi-delete-outline"/>
          </v-btn>
        </workflow-delete-item-dialog>

        <card-type-selector
          v-if="!hideTypeSelector"
          :card="requirement"
          small
        />

        <div class="flex-grow-0 flex-shrink-0 pa-0 flex-nowrap d-inline-flex ml-auto">
          <!-- {{ requirement.sortkey }} -->
          <v-tooltip location="top" open-delay="1000" v-if="withDates && dateCreated">
            <template v-slot:activator="{ on, props }">
              <span
                v-bind="props"
                class="text-caption"
                v-on="{ ...on }"
              >
                {{formatDistanceToNowStrict(new Date(dateCreated), { addSuffix: false })}}
              </span>
            </template>

            <div>
              <p class="text-body-2 mb-0">
                Created: {{format(new Date(dateCreated), 'MMMM d, yyyy, HH:mm')}}
              </p>

              <p v-if="dateUpdated" class="text-body-2 mb-0">
                Updated: {{format(new Date(dateUpdated), 'MMMM d, yyyy, HH:mm')}}
              </p>
            </div>
          </v-tooltip>

          <requirement-user-avatar
            v-if="creator"
            :fullname="creator.firstname + ' ' + creator.lastname"
          ></requirement-user-avatar>
          <v-tooltip location="top" v-if="showAsInvisible">
            <template v-slot:activator="{ on, props }">
              <v-container
                v-bind="props"
                class="pa-0 ma-0 px-1 user-reaction-btn"
                v-on="{ ...on }"
                size="x-small"
              >
                <v-icon size="small" icon="mdi-eye-off-outline" />
              </v-container>
            </template>

            <div>
              <p class="text-body-2 mb-0">
                Types marked as Aggregate or Action should only have one card per Event.
              </p>
              <br />
              <p  class="text-body-2 mb-0">
                Remove unnecessary cards from your workflow or change workflow settings. Multiple Action or Aggregate cards will not be visible to the AI assistant.
              </p>
            </div>
          </v-tooltip>
        </div>
      </v-row>
  </v-container>
</template>

<script>
import RequirementAttachmentsDialog from '@/components/RequirementAttachmentsDialog'
import JiraIssueStatusMenu from '@/components/JiraIssueStatusMenu'
import WorkflowDeleteItemDialog from '@/components/WorkflowDeleteItemDialog'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import format from 'date-fns/format'
import RequirementUserAvatar from '@/components/RequirementUserAvatar'
import CardTypeSelector from '@/components/CardTypeSelector'
import { EventBus } from '@/event-bus/event-bus.js'
import store from '../store'

export default {
  components: {
    RequirementAttachmentsDialog,
    JiraIssueStatusMenu,
    WorkflowDeleteItemDialog,
    RequirementUserAvatar,
    CardTypeSelector
  },

  props: {
    requirement: Object,
    compactReplies: Boolean,
    showAsInvisible: Boolean,
    jiraData2: Object,
    loadingJiraInfo: Number,
    withDates: Boolean,
    dateCreated: String,
    dateUpdated: String,
    creator: Object,
    hideTypeSelector: Boolean
  },

  computed: {
    upvotes () {
      return this.requirement.reactions.filter(({ direction }) => direction === 'UPVOTE')
    },
    downvotes () {
      return this.requirement.reactions.filter(({ direction }) => direction === 'DOWNVOTE')
    },
    currentReaction () {
      const { userId } = this.$store.state
      const reaction = this.requirement.reactions.find(({ user }) => user.id === userId)
      return reaction && reaction.direction
    }
  },

  methods: {
    formatDistanceToNowStrict,
    format,
    updateReaction (direction, currentReaction) {
      const workflowId = this.$route.params.id
      const requirementId = this.requirement.id
      store.dispatch('workflow/toggleCardReaction', {
        workflowId,
        input: {
          requirementId,
          direction
        }
      })
    },
    pinTheDialog () {
      EventBus.$emit('pin-dialog', { eventId: this.requirement.eventId })
    }
  }
}
</script>

<style scoped>

.reply-btn {
  min-width: auto;
  display: inline-flex;
  align-items: center;
  height: 28px;
}

.reply-btn:disabled,
.reply-btn:disabled .v-icon {
  color: rgba(0, 0, 0, 0.26) !important;
}

.user-reaction-btn {
  min-width: auto !important;
}

.votes-list-subheader {
  height: 36px;
}

.votes-list {
  margin: 0;
  padding: 0 10px 10px 16px;
  list-style: none;
}
</style>

<style>
.requirement-actions .v-icon {
  font-size: 1rem !important;
}
</style>
