<template>
  <v-dialog :scrollable="true" max-width="30%" v-model="open" content-class="dialog-top-right">
    <template v-slot:activator="{ props }">
      <v-icon v-bind="props" size="small" class="ml-2">mdi-help-circle-outline</v-icon>
    </template>

    <v-card flat>
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title><v-icon> mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>How to use the Fact Table</b></v-toolbar-title>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <h3><span class="bg-red-lighten-4">Fact Table</span></h3>
        While the Event Story captures example data as expressed by domain
        experts, with the Fact Table, we move one step closer to a physical
        implementation of the table in a database. Each field in the Event
        Story becomes either a value (fact) or an ID pointing to an
        associated entity.
        <br /><br />
        Sometimes, a specific external or internal system may hold
        the master data for the entities to which the fact table refers,
        such as a global product database. In such instances, it is useful
        to document the name of the system, the table, and the field name.
        <br />
      </v-card-text><br /><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  }
}
</script>

<style>
.v-dialog .help-ai {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 30%;
  overflow-y: scroll;
}
</style>
