import { WORKFLOW_FIELDS } from '../fragments'
import gql from 'graphql-tag'

export default gql`
  ${WORKFLOW_FIELDS.loc.source.body}
  subscription OnCreateOrUpdateWorkflow($projectId: String!) {
    onCreateOrUpdateWorkflow (projectId: $projectId) {
      ...WorkflowFields
    }
  }
`
