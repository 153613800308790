<template>
  <v-card v-if="$store.state.adminUser" flat>
    <v-tabs v-model="tab">
      <v-tab value="registeredUsers" ripple>Registered Users</v-tab>
      <v-spacer></v-spacer>
      <v-text-field
        class="align-self-right mt-0 pt-0 mr-4"
        append-inner-icon="mdi-magnify"
        variant="underlined"
        v-model="search"
        label="Search"
        single-line
        hide-details>
      </v-text-field>
    </v-tabs>

    <v-card-text flat class="pa-0">
      <v-window v-model="tab">
        <v-data-table
          v-if="users"
          :headers="headersUsers"
          :items="users"
          :items-per-page="-1"
          :search="search"
          hover
          v-model:expanded="expanded"
          show-expand
          v-model:sort-by="sortBy"
        >
          <template v-slot:item.firstname="{ item }">
            <div>
              {{
                (item.firstname + ' ' + item.lastname).length > 15 ? `${(item.firstname + ' ' + item.lastname).substring(0, 15)?.trim()}...` : (item.firstname + ' ' + item.lastname)
              }}
              <v-tooltip
                max-width="400px"
                location="top"
                activator="parent"
                v-if="(item.firstname + ' ' + item.lastname).length > 15"
              >
                {{ item.firstname + ' ' + item.lastname}}
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.projects="{ item }">{{ countProjects(item.organisations) }}</template>
          <template v-slot:item.lastLogin="{ item }">{{ item.lastLogin ? item.lastLogin.substring(0,10) : '' }}</template>
          <template v-slot:item.registrationDate="{ item }">{{ item.registrationDate ? item.registrationDate.substring(0,10) : '' }}</template>
          <template v-slot:item.referralSource="{ item }">
            <div v-if="item.referralSource">
              {{ item.referralSource.length > 15 ? `${item.referralSource.substring(0,15)?.trim()}...` : item.referralSource }}
              <v-tooltip
                max-width="400px"
                location="top"
                activator="parent"
                v-if="item.referralSource && item.referralSource.length > 15"
              >
                {{ item.referralSource ? item.referralSource : '' }}
              </v-tooltip>
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:item.usageIntent="{ item }">
            <div v-if="item.usageIntent">
              {{ item.usageIntent.length > 15 ? `${item.usageIntent.substring(0,15)?.trim()}...` : item.usageIntent }}
              <v-tooltip
                max-width="400px"
                location="top"
                activator="parent"
                v-if="item.usageIntent && item.usageIntent.length > 15"
              >
                {{ item.usageIntent ? item.usageIntent : '' }}
              </v-tooltip>
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:bottom></template>

          <template v-slot:expanded-row="{ item }">
            <td colspan="6">
              <v-card class="mx-4 mb-4 elevation-3">
                <v-data-table
                  :headers="headersProjects"
                  :items="getProjects(item.organisations)"
                  :items-per-page="-1"
                  hover
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:bottom></template>
                  <template v-slot:item.workflows="{ item, index }">
                    <v-dialog
                      v-model="dialog[index]"
                      width="600"
                      :scrim="false">
                      <template v-slot:activator="{ props }">
                        <a href="#" @click.prevent="dialog[index] = true" v-on="props">{{ item.workflows ? item.workflows.length : 0 }}</a>
                      </template>
                      <v-card>
                        <v-layout fluid justify-space-between align-top>
                          <div>
                            <v-card-title
                              class="title-h5"
                              primary-title
                            >
                              Workflows for project '{{ item.name }}'
                            </v-card-title>
                          </div>
                          <v-spacer></v-spacer>
                          <div shrink>
                            <v-btn variant="plain" icon color="grey" @click="dialog[index] = false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </div>
                        </v-layout>
                        <v-card-text class="pa-0">
                          <v-data-table
                            :headers="headersWorkflows"
                            :items="item.workflows"
                            :items-per-page="-1"
                            hover
                            hide-default-footer
                            class="elevation-1"
                            disable-pagination
                          >
                            <template v-slot:bottom></template>
                            <template v-slot:item.name="{ item }"><div style="cursor: pointer;" @click="gotoWorkflow(item.id, item.projectId)">{{ item.name }}</div></template>
                            <template v-slot:item.eventsLength="{ item }">{{ item.eventsJson?.length }}</template>
                          </v-data-table>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            @click="dialog[index] = false">
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:item.teamMembers="{ item }">{{ item.teamMembers ? item.teamMembers.length : 0 }}</template>
                  <template v-slot:item.getAccess="{ item }">
                    <div v-if="alreadyTeamMember(item.teamMembers)">Already team member</div>
                    <div v-else><v-btn @click="createTeamMember(item.id)">Become team member</v-btn></div>
                  </template>
                  <template v-slot:item.lastLogin="{ item }">{{ findLastLogin(item.teamMembers).substring(0,10) }}</template>
                </v-data-table>
              </v-card>
            </td>
          </template>
          <template v-slot:no-data>
            <v-alert v-if="search?.length" color="error" icon="mdi-alert">
              Your search for "{{ search }}" found no results.
            </v-alert>
            <div v-else>
              No data available
            </div>
          </template>
        </v-data-table>
      </v-window>
    </v-card-text>
  </v-card>
  <v-card v-else>
    <v-card-title>Not authorized for this page.</v-card-title>
  </v-card>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'

export default {
  data: () => ({
    tab: 'registeredUsers',
    sortBy: [{ key: 'registrationDate', order: 'desc' }],
    dialog: [],
    search: '',
    expanded: [],
    headersUsers: [
      {
        title: 'Name',
        sortable: true,
        key: 'firstname'
      },
      {
        title: 'Company',
        sortable: true,
        key: 'company'
      },
      {
        title: 'Email',
        sortable: true,
        key: 'email'
      },
      {
        title: 'Projects',
        sortable: false,
        key: 'projects'
      },
      {
        title: 'Last Login',
        sortable: true,
        key: 'lastLogin',
        width: '10%'
      },
      {
        title: 'Login count',
        sortable: true,
        key: 'loginCounter'
      },
      {
        title: 'Registration Date',
        sortable: true,
        key: 'registrationDate'
      },
      {
        title: 'Referral Source',
        sortable: false,
        key: 'referralSource'
      },
      {
        title: 'Usage Intent',
        sortable: false,
        key: 'usageIntent'
      },
      {
        key: 'data-table-expand'
      }
    ],
    headersWorkflows: [
      {
        title: 'Name',
        align: 'left',
        sortable: true,
        key: 'name'
      },
      {
        title: 'Events',
        align: 'right',
        sortable: false,
        key: 'eventsLength'
      }
    ],
    headersProjects: [
      {
        title: 'Project Name',
        sortable: true,
        key: 'name'
      },
      {
        title: 'Organisation',
        sortable: true,
        key: 'organisation.name'
      },
      {
        title: 'Workflows',
        sortable: false,
        key: 'workflows'
      },
      {
        title: 'Team Members',
        sortable: false,
        key: 'teamMembers'
      },
      {
        title: 'Get access as team member',
        sortable: false,
        key: 'getAccess'
      },
      {
        title: 'Last login by a team member',
        align: 'end',
        sortable: false,
        key: 'lastLogin'
      }
    ]
  }),
  computed: {
    ...mapState({
      users: state => state.user.users
    })
  },
  methods: {
    alreadyTeamMember (teamMembers) {
      for (const teamMember of teamMembers) {
        if (teamMember.userId === store.state.userId) {
          return true
        }
      }
      return false
    },
    async createTeamMember (projectId) {
      await store.dispatch('teamMembers/createTeamMember', {
        email: store.state.userInfo,
        projectId
      })
    },
    gotoWorkflow (workflowId, projectId) {
      const routeData = this.$router.resolve('/workflow/' + projectId + '/' + workflowId)
      window.open(routeData.href, '_blank')
    },
    countProjects (organsations) {
      let res = 0
      for (let i = 0; i < organsations.length; i++) {
        res = res + organsations[i].projects.length
      }
      return res
    },
    getProjects (organsations) {
      const res = []
      for (let i = 0; i < organsations.length; i++) {
        res.push(...organsations[i].projects)
      }
      return res
    },
    findLastLogin (teamMembers) {
      let lastLogin = ''
      for (let i = 0; i < teamMembers.length; i++) {
        if (teamMembers[i].lastLogin > lastLogin) {
          lastLogin = teamMembers[i].lastLogin
        }
      }
      return lastLogin
    }
  },
  mounted () {
    this.$store.dispatch('user/listUsers')
  },
  watch: {
    listUsers (val) {
      if (val) {
        this.users = val
      } else {
        this.users = []
      }
    }
  }
}
</script>

<style>
</style>
