<template>
  <v-menu
    :min-width="menuWidth"
    :max-width="menuWidth"
    :disabled="$store.state.isWorkflowDisabled"
  >
    <template #activator="{ props }">
      <slot name="activator" :props="props">
        <v-avatar class="color-item" :color="color || 'rgb(238,238,238)'" v-bind="props" size="24"></v-avatar>
      </slot>
    </template>

    <v-card>
      <v-card-text :class="{'pa-1': appearance === 'diagram'}">
        <v-row class="color-picker flex-wrap justify-lg-space-between ma-1">
          <v-col
            cols="3"
            v-for="colorOption in colorOptions"
            :class="{
              'text-center': true,
              'pa-0': true,
              'mt-1': appearance !== 'diagram',
              'mb-1': appearance !== 'diagram'
            }"
            :key="computeRGBString(colorOption)"
          >
            <v-avatar
              class="color-item"
              :size="avatarSize"
              :color="computeRGBString(colorOption)"
              @click="$emit('change', colorOption)"
            >
              <v-icon size="small" color="white" v-if="color === computeRGBString(colorOption)">mdi-check</v-icon>
            </v-avatar>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    color: String,
    variants: Array, // options: 'accent', 'darken', 'main', 'lighten'
    appearance: String // 'diagram', 'regular' [default]
  },

  data () {
    return {
      colors: {
        accent: [
          // red
          { red: 213, green: 0, blue: 0 },
          // pink
          { red: 197, green: 17, blue: 98 },
          // purple
          { red: 170, green: 1, blue: 255 },
          // deep purple
          { red: 98, green: 1, blue: 234 },
          // blue
          { red: 40, green: 98, blue: 255 },
          // teal
          { red: 0, green: 191, blue: 165 },
          // green
          { red: 0, green: 200, blue: 83 },
          // yellow
          { red: 255, green: 214, blue: 0 },
          // orange
          { red: 255, green: 109, blue: 0 },
          // brown
          { red: 62, green: 39, blue: 35 },
          // blue grey
          { red: 39, green: 50, blue: 56 },
          // blue grey
          { red: 33, green: 33, blue: 33 }
        ],
        darken: [
          { red: 183, green: 28, blue: 28 },
          { red: 136, green: 14, blue: 79 },
          { red: 74, green: 20, blue: 140 },
          { red: 50, green: 27, blue: 146 },
          { red: 13, green: 71, blue: 161 },
          { red: 0, green: 77, blue: 64 },
          { red: 27, green: 94, blue: 32 },
          { red: 245, green: 127, blue: 22 },
          { red: 230, green: 81, blue: 0 },
          { red: 93, green: 64, blue: 55 },
          { red: 69, green: 90, blue: 100 },
          { red: 97, green: 97, blue: 97 }
        ],
        main: [
          { red: 244, green: 67, blue: 53 },
          { red: 233, green: 30, blue: 99 },
          { red: 156, green: 38, blue: 176 },
          { red: 103, green: 59, blue: 183 },
          { red: 34, green: 150, blue: 243 },
          { red: 0, green: 150, blue: 136 },
          { red: 76, green: 175, blue: 80 },
          { red: 255, green: 235, blue: 59 },
          { red: 255, green: 152, blue: 1 },
          { red: 121, green: 85, blue: 72 },
          { red: 96, green: 125, blue: 139 },
          { red: 158, green: 158, blue: 158 }
        ],
        lighten: [
          { red: 239, green: 154, blue: 154 },
          { red: 244, green: 142, blue: 177 },
          { red: 206, green: 147, blue: 216 },
          { red: 179, green: 157, blue: 219 },
          { red: 144, green: 202, blue: 249 },
          { red: 128, green: 203, blue: 196 },
          { red: 165, green: 214, blue: 167 },
          { red: 255, green: 245, blue: 157 },
          { red: 255, green: 204, blue: 128 },
          { red: 188, green: 170, blue: 164 },
          { red: 176, green: 190, blue: 197 },
          { red: 224, green: 224, blue: 224 }
        ]
      }
    }
  },

  computed: {
    colorOptions () {
      const result = []
      const { accent, darken, main, lighten } = this.colors

      if (Array.isArray(this.variants) && this.variants.length) {
        for (let i = 0, l = accent.length; i < l; i++) {
          if (this.variants.includes('accent')) result.push(accent[i])
          if (this.variants.includes('darken')) result.push(darken[i])
          if (this.variants.includes('main')) result.push(main[i])
          if (this.variants.includes('lighten')) result.push(lighten[i])
        }
      } else {
        for (let i = 0, l = accent.length; i < l; i++) {
          result.push(
            accent[i],
            darken[i],
            main[i],
            lighten[i]
          )
        }
      }

      return result
    },

    menuWidth () {
      return this.appearance === 'diagram' ? '100' : '160'
    },

    avatarSize () {
      return this.appearance === 'diagram' ? '16' : '24'
    }
  },

  methods: {
    computeRGBString (color) {
      return `rgb(${color.red},${color.green},${color.blue})`
    }
  }
}
</script>

<style scoped>
  .color-item {
    cursor: pointer;
  }
</style>
