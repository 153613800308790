import randomUUID from '@/utils/uuid'
import { workflowApiHelpers } from '@/api/workflowApiHelpers'
import cardHelpers from '@/helpers/cardHelpers'

export default {
  isValidShape (jsonData) {
    return jsonData && jsonData.id && jsonData.type && jsonData.description
  },
  prepareAddShape (jsonData, selectedEvent, svgGraphData, svgLanesData, workflow, requirementTypes) {
    const shape = this.copyEventFields(jsonData, selectedEvent, svgGraphData, svgLanesData, workflow, requirementTypes)
    return shape
  },
  copyEventFields (jsonData, selectedEvent, svgGraphData, svgLanesData, workflow, requirementTypes) {
    const shape = {
      id: this.getIdForNewShape(jsonData, svgGraphData, workflow),
      description: jsonData.description,
      laneId: this.getLaneIdForNewShape(jsonData, svgLanesData, workflow),
      groupId: undefined,
      type: jsonData.type,
      conditionLabels: this.getConditionLabels(jsonData, selectedEvent),
      requirementsJson: this.getCards(jsonData, svgGraphData, workflow, requirementTypes),
      dataModels: jsonData.dataModels || undefined,
      subprocess: jsonData.subprocess, // check if we need to remove subprocess if the workflow doesn't exist
      color: jsonData.color
    }
    shape.offset = selectedEvent ? undefined : workflowApiHelpers.findPreferredOffset(shape, svgGraphData, svgLanesData)
    return shape
  },
  getIdForNewShape (jsonData, svgGraphData, workflow) {
    let id
    if (!svgGraphData[jsonData.id] && workflow.id === jsonData.workflowId) {
      // if the shape does not exist and the workflow is the same
      // then keep the id because it is a CUT-paste operation
      id = jsonData.id // keep the id
    } else {
      // if the shape shape exists and or we have switched workflow
      // then generate a new id because it is likely a COPY-paste operation
      id = randomUUID()
    }
    return id
  },
  getLaneIdForNewShape (jsonData, svgLanesData, workflow) {
    if (svgLanesData[jsonData.laneId]) {
      return jsonData.laneId
    }
    // find lane by name
    const lanes = Object.values(svgLanesData)
    const lane = lanes.find(lane => lane.name === jsonData.laneName)
    if (lane) {
      return lane.id
    }
    // find the last lane in svgLanesData
    let maxIndex = 0
    for (const laneId in svgLanesData) {
      if (svgLanesData[laneId].index > maxIndex) {
        maxIndex = svgLanesData[laneId].index
      }
    }
    const laneId = Object.keys(svgLanesData).find(laneId => svgLanesData[laneId].index === maxIndex)
    return laneId
  },
  getConditionLabels (jsonData, selectedEvent) {
    if (jsonData.conditionLabels?.length > 0) {
      for (const label of jsonData.conditionLabels) {
        if (label.parentId === selectedEvent.id) {
          return [label]
        }
      }
    }
    return undefined
  },
  getCards (jsonData, svgGraphData, workflow, requirementTypes) {
    let keepIDs
    if (!svgGraphData[jsonData.id] && workflow.id === jsonData.workflowId) {
      keepIDs = true
    } else {
      keepIDs = false
    }
    let lastSortKey = cardHelpers.getLastCardOnSortKey(workflow)?.sortkey || ''
    const res = jsonData.requirementsJson?.map(card => {
      // put all the copied cards at the end of the backlog
      // generate a new sortkeys based on the last one
      lastSortKey = cardHelpers.generateNewSortKey(lastSortKey)
      return {
        id: keepIDs ? card.id : randomUUID(),
        description: card.description,
        releaseNo: card.releaseNo || undefined,
        sortkey: lastSortKey,
        cardTypeId: this.getCardTypeId(card, requirementTypes, jsonData.requirementTypes),
        done: card.done,
        boundedContext: this.getBoundedContext(card, workflow)
      }
    })
    return res
  },
  getCardTypeId (card, requirementTypes, oldRequirementTypes) {
    let requirementType = requirementTypes.find(rt => rt.id === card.cardTypeId)
    if (requirementType) {
      return requirementType.id
    }
    const oldRequirementType = oldRequirementTypes.find(rt => rt.id === card.cardTypeId)
    if (oldRequirementType) {
      const requirementType = requirementTypes.find(rt => rt.title === oldRequirementType.title)
      if (requirementType) {
        return requirementType.id
      }
    }
    requirementType = requirementTypes.find(rt => rt.defaultType === true)
    if (requirementType) {
      return requirementType.id
    }
    return undefined
  },
  getBoundedContext (card, workflow) {
    if (card.boundedContextId && workflow.boundedContexts.find(bc => bc.id === card.boundedContextId)) {
      return card.boundedContextId
    } else {
      return undefined
    }
  },
  getOffset (laneId, workflow) {
    const lane = workflow.lanes.find(lane => lane.id === laneId)
    if (lane) {
      return lane.offset
    } else {
      return 0
    }
  }
}
