<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
  >

    <template v-slot:activator="{ props }">
      <slot :props="props"></slot>
    </template>

    <jira-issue-status
      :requirement="requirement"
      :credentials="credentials"
    ></jira-issue-status>
  </v-menu>
</template>

<script>
import JiraIssueStatus from '@/components/JiraIssueStatus'

export default {
  components: {
    JiraIssueStatus
  },

  props: ['requirement', 'credentials'],

  data () {
    return {
      open: false
    }
  }
}
</script>
