<template>
  <div class="ma-2" v-if="user">
    <v-card>
      <v-tabs v-model="active" class="mb-4">
        <v-tab value="organisations" ripple> My Organisations </v-tab>
        <v-tab value="projects" ripple> My Projects </v-tab>
        <v-spacer></v-spacer>
        <v-col cols="3" v-if="active === 'organisations'" class="text-right pa-2">
          <settings-organisation-dialog
            ref="dialogOrganisation"
            buttonTheme="dark"
            buttonText="Create new organisation"
          />
        </v-col>
        <v-col cols="3" v-if="active === 'projects'" class="text-right pa-2">
          <settings-project-dialog
            ref="dialogProject"
            buttonTheme="dark"
            buttonText="Create new project"
            createOrganisationLabel="create one under MY ORGANISATIONS"
            :set-project="false"
          />
        </v-col>
      </v-tabs>
      <v-window v-model="active">
        <v-window-item value="organisations">
          <v-card flat>
            <v-data-table
              :headers="headersOrganisations"
              :items="organisations"
              :items-per-page="-1"
              :search="searchOrganisations"
              hover
              class="elevation-1 round-bottom"
              no-data-text="0 organisations, click on 'CREATE NEW ORGANISATION'!"
            >
            <template v-slot:bottom></template>
              <template #item="props">
                <tr>
                  <td>{{ props.item.name }}</td>
                  <td>{{ user.firstname + ' ' + user.lastname }} (you)</td>
                  <td class="text-right">
                    <v-icon
                      size="small"
                      class="mr-2"
                      @click="editItemOrganisation(props.item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon size="small" @click="deleteItemOrganisation(props.item)">
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>

              <template v-slot:no-data>
                <v-alert v-if="searchOrganisations?.length" color="error" icon="mdi-alert">
                  Your search for "{{ searchOrganisations }}" found no results.
                </v-alert>
                <div v-else>
                  No data available
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-window-item>
        <v-window-item value="projects">
          <v-card flat>
            <v-data-table
              :headers="headersProjects"
              :items="projects"
              :items-per-page="-1"
              :search="searchProjects"
              hover
              class="elevation-1 round-bottom"
              no-data-text="0 projects, click on 'CREATE NEW PROJECT'!"
            >
              <template v-slot:bottom></template>
              <template #item="props">
                <tr
                  :style="{ cursor: 'pointer' }"
                  @click="setProject(props.item)"
                >
                  <td>
                    {{ props.item.name }}
                  </td>
                  <td>{{ props.item.organisation.name }}</td>
                  <td>{{ user.firstname + ' ' + user.lastname }} (you)</td>
                  <td class="text-right">
                    <v-icon
                      size="small"
                      class="mr-2"
                      @click.stop="editItemProject(props.item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon size="small" @click.stop="deleteItemProject(props.item)" id="delete-project">
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>

              <template v-slot:no-data>
                <v-alert v-if="searchProjects?.length" color="error" icon="mdi-alert">
                  Your search for "{{ searchProjects }}" found no results.
                </v-alert>
                <div v-else>
                  No data available
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-window-item>
      </v-window>
    </v-card>
    <!-- <settings-app-integrations></settings-app-integrations> -->
  </div>
</template>

<script>
import { EventBus } from '@/event-bus/event-bus.js'
import SettingsOrganisationDialog from '@/components/SettingsOrganisationDialog'
import SettingsProjectDialog from '@/components/SettingsProjectDialog'
import projectApi from '@/api/projectApi'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    SettingsOrganisationDialog,
    SettingsProjectDialog
  },
  data: () => ({
    rules: {
      required: value => !!value || 'Required.',
      counter: value => value.length <= 30 || 'Max 30 characters'
    },
    searchProjects: '',
    searchOrganisations: '',
    active: 'organisations',
    headersOrganisations: [
      {
        title: 'Organisation',
        align: 'left',
        sortable: true,
        key: 'name'
      },
      {
        title: 'Owner',
        align: 'left',
        sortable: false
      },
      {
        title: '',
        align: 'center',
        sortable: false
      }
    ],
    headersProjects: [
      {
        title: 'Project',
        align: 'left',
        sortable: true,
        key: 'name'
      },
      {
        title: 'Organisation',
        align: 'left',
        sortable: true,
        key: 'organisationName'
      },
      {
        title: 'Owner',
        align: 'left',
        sortable: false
      },
      {
        title: '',
        align: 'center',
        sortable: false
      }
    ]
  }),

  computed: {
    ...mapState({
      organisations: state => state.organisations.organisations || [],
      projectMemberships: state => state.projectMemberships.projectMemberships,
      user: state => state.user.user
    }),
    ...mapGetters({
      projects: 'projectMemberships/myProjects'
    })
  },
  methods: {
    setProject (item) {
      EventBus.$emit('set-project', item)
    },
    editItemOrganisation (item) {
      this.$refs.dialogOrganisation.setItem(Object.assign({}, item))
      this.$refs.dialogOrganisation.openOrganisationDialog()
    },
    editItemProject (item) {
      this.$refs.dialogProject.setItem(Object.assign({}, item))
      this.$refs.dialogProject.openProjectDialog()
    },
    deleteItemOrganisation (item) {
      const del = confirm('Are you sure you want to delete this organisation?')
      if (del) {
        projectApi.deleteOrganisation(item)
      }
    },
    deleteItemProject (item) {
      const del = confirm('Are you sure you want to delete this project and all its team members, events and cards? This operation cannot be undone.')
      if (del) {
        projectApi.deleteProject(item)
      }
    }
  }
}
</script>

<style scoped>
.setting-search-field {
  padding-top: 6px;
}

.hidden {
  display: none;
}

.active-project {
  font-weight: bold !important;
}
</style>
