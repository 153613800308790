export const loading = {
  namespaced: true,
  state: () => ({
    loading: false,
    loadingMessage: null
  }),
  mutations: {
    startLoading (state, message = null) {
      state.loading = true
      state.loadingMessage = message
    },
    stopLoading (state) {
      state.loading = false
      state.loadingMessage = null
    }
  },
  getters: {
    getLoadingState: (state) => state.loading,
    getLoadingMessage: (state) => state.loadingMessage
  }
}
