<template>
  <div v-if="isLoading" style="width: 100%; height: 624px; display: flex; justify-content: center; align-items: center;">
    <div style="width: 64px; height: 64px;">
      <v-progress-circular
        color="black"
        size="64"
        indeterminate
      />
    </div>
  </div>
  <div v-else style="display: inline;" @mousedown.stop>
    <v-row class="ma-0">
      <v-col sm="3" class="pa-0">
        <v-list
          height="583"
          bg-color="transparent"
          density="compact"
          open-strategy="multiple"
          v-model:selected="tabs"
          v-model:opened="opened"
        >
          <file-list-item name="__spec" displayName="Api Specification" :objects="objects"/>

          <template v-if="fileTree && Object.keys(fileTree).length">
            <v-divider class="my-2"></v-divider>

            <file-list-item :name="Object.keys(fileTree)[0]" :children="Object.values(fileTree)[0]" :objects="objects" :opened="opened"/>
          </template>
        </v-list>

        <v-list
          :style="fileTree && Object.keys(fileTree).length ? '' : 'opacity: 0; pointer-events: none;'"
          class="pt-0"
        >
          <v-divider class="mb-2"></v-divider>

          <v-list-item
            class="file-tree-item mb-0"
            prepend-icon="mdi-cloud-download"
            title="Download ZIP"
            @click="downloadZip"
          />
        </v-list>

      </v-col>
      <v-col sm="9" class="pa-0" style="background-color: white; border-left: 1px solid rgb(230, 230, 230);">
        <div
          v-if="openedTab === '__spec'"
          class="d-flex flex-column h-100"
          style="position: relative;"
          @keydown="keyDown"
        >
          <div class="d-flex" style="max-width: 800px;">
            <div class="d-flex"></div>
            <span class="toolbar-top">
              API Specification
            </span>
            <v-spacer></v-spacer>
            <v-tooltip location="top" open-delay="1000">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  style="border-radius: 0px !important;"
                  variant="text"
                  density="compact"
                  class="text-none"
                  :disabled="!objects.__spec?.isDirty"
                  @click="saveFile(objects.__spec)"
                >
                  <v-icon class="mr-2">mdi-content-save</v-icon> Save
                </v-btn>
              </template>
              <span>Save changes Ctrl/Cmd+S</span>
            </v-tooltip>

            <v-tooltip location="top" open-delay="1000">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  style="border-radius: 0px !important;"
                  variant="text"
                  density="compact"
                  class="text-none"
                  :active="!editApiSpecification"
                  @click="clickEditSpecification(false)"
                >
                  <v-icon class="mr-2">mdi-view-quilt</v-icon> Specification settings
                </v-btn>
              </template>
              <span>Choose tech stack by selecting between predefined settings</span>
            </v-tooltip>
            <v-tooltip location="top" open-delay="1000">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  style="border-radius: 0px !important;"
                  variant="text"
                  density="compact"
                  class="text-none"
                  :active="editApiSpecification"
                  @click="clickEditSpecification(true)"
                >
                  <v-icon class="mr-2">mdi-pencil</v-icon> Customize specification
                </v-btn>
              </template>
              <span>Customize your tech stack by editing the specification manually</span>
            </v-tooltip>
          </div>
          <v-progress-linear v-if="objects.__spec?.isLoading" indeterminate style="position: absolute;"></v-progress-linear>

          <code-editor v-if="editApiSpecification" v-model="objects.__spec.contents" v-model:isDirty="objects.__spec.isDirty" language="yaml"></code-editor>
          <div v-else class="d-flex flex-column pa-4 h-100" style="max-width: 800px;">
            <p class="pt-6 pb-2">
              Select a language/ framework:
            </p>
            <v-btn-toggle
              :disabled="objects.__spec.isLoading"
              variant="outlined"
              divided
              mandatory
              v-model="spec['info.framework']"
            >
              <v-btn
                v-for="spec in specSettings"
                :key="spec.appType"
                :value="spec.title"
                @click="updateFrameworkSelection(spec.title)"
                class="mr-2 text-none"
                style="border-left: 1px solid rgb(230, 230, 230) !important;"
              >
                {{spec.title}}
              </v-btn>
            </v-btn-toggle>

            <template v-if="spec['info.framework'] === 'Swagger Documentation'">
              <p class="pt-6 pb-2">
                Select a format:
              </p>
              <v-btn-toggle
                :disabled="objects.__spec.isLoading"
                variant="outlined"
                divided
                mandatory
                v-model="spec['info.language']"
              >
                <v-btn
                  v-for="name in ['JSON', 'YAML']"
                  :key="name"
                  :value="name"
                  class="mr-2 text-none"
                  style="border-left: 1px solid rgb(230, 230, 230) !important;"
                >
                  {{name}}
                </v-btn>
              </v-btn-toggle>
            </template>

            <template v-if="databaseOptions.length > 1">
              <p class="pt-6 pb-2">
                Select a database:
              </p>
              <v-btn-toggle
                :disabled="objects.__spec.isLoading"
                variant="outlined"
                divided
                mandatory
                v-model="spec['info.database']"
              >
                <v-btn
                  v-for="name in databaseOptions"
                  :key="name"
                  :value="name"
                  class="mr-2 text-none"
                  style="border-left: 1px solid rgb(230, 230, 230) !important;"
                >
                  {{name}}
                </v-btn>
              </v-btn-toggle>
            </template>

            <template v-if="testingOptions.length > 1">
              <p class="pt-6 pb-2">
                Select a testing framework:
              </p>
              <v-btn-toggle
                :disabled="objects.__spec.isLoading"
                variant="outlined"
                divided
                mandatory
                v-model="spec['testing.unitTests']"
              >
                <v-btn
                  v-for="name in testingOptions"
                  :key="name"
                  :value="name"
                  class="mr-2 text-none"
                  style="border-left: 1px solid rgb(230, 230, 230) !important;"
                >
                  {{name}}
                </v-btn>
              </v-btn-toggle>
            </template>

            <v-spacer></v-spacer>

            <div>
              <div class="d-flex mb-14">
                <span class="pr-2" style="font-weight: 500; padding-top: 22px;">API Name:</span>
                <v-text-field
                  v-model="spec['info.name']"
                  :disabled="objects.__spec.isLoading"
                  label=""
                  placeholder="Give your API a name"
                  variant="underlined"
                  hide-details
                />
              </div>
            </div>
          </div>

          <!-- <v-textarea
            class="mb-16 pa-4 pt-0"
            v-model="customInstructions"
            :disabled="isApiSpecificationLoading"
            label="Custom Instructions (optional)"
            placeholder="Provide a short description what your API should do and how it should work."
            variant="outlined"
            rows="2"
            hide-details
          /> -->

          <div v-if="editApiSpecification" class="toolbar-shadow"></div>
          <div class="toolbar-absolute-bottom d-flex overflow-hidden">
            <v-btn
              v-if="objects.__spec.isLoading"
              @click="stopGenerating()"
              class="mr-4"
            >
              <v-icon>mdi-stop</v-icon> Stop generating
            </v-btn>
            <v-btn
              v-else
              @click="generateEntireApi()"
              class="generate-api-btn mr-4"
            >
              {{Object.keys(this.objects).length > 1 ? 'Regenerate' : 'Generate'}} API
            </v-btn>

            <!-- <v-checkbox
              v-if="!editApiSpecification"
              density="compact"
              v-model="includeUserStories"
              label="Include user stories & other cards"
              hide-details
            /> -->
            <v-btn
              v-if="editApiSpecification"
              @click="createApiSpecificationFromTemplate()"
              class="mr-4"
            >
              <v-icon class="pr-2 pt-1">mdi-restore</v-icon> Restore specification
            </v-btn>

            <v-spacer></v-spacer>
            <ai-version-selector v-model="aiVersion" style="transform: translateY(-8px); max-width:200px;" />
          </div>
        </div>

        <template v-else>
          <template v-for="(object, filename) in objects" :key="filename">
            <div v-if="openedTab === filename" style="position: relative;">
              <div class="d-flex">
                <span class="toolbar-top">
                  {{filename.replaceAll('/', ' > ')}}
                </span>
                <v-spacer></v-spacer>
                <v-tooltip location="top" open-delay="1000">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      style="border-radius: 0px !important;"
                      variant="text"
                      density="compact"
                      class="text-none"
                      :disabled="!object.isDirty"
                      @click="saveFile(object)"
                    >
                      <v-icon class="mr-2">mdi-content-save</v-icon> Save
                    </v-btn>
                  </template>
                  <span>Save changes Ctrl/Cmd+S</span>
                </v-tooltip>
                <v-tooltip location="top" open-delay="1000">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      style="border-radius: 0px !important;"
                      variant="text"
                      density="compact"
                      class="text-none"
                      :active="object.isEditingPrompt"
                      @click="object.isEditingPrompt = !object.isEditingPrompt"
                    >
                      <v-icon class="mr-2">mdi-pencil</v-icon> Edit prompt
                    </v-btn>
                  </template>
                  <span>Add more context to the prompt used for code generation</span>
                </v-tooltip>
                <v-btn
                  v-if="object.isLoading"
                  style="border-radius: 0px !important; margin-right: 134px;"
                  variant="text"
                  density="compact"
                  class="text-none"
                  @click="stopGenerating(object)"
                >
                  <v-icon class="mr-2">mdi-stop</v-icon> Stop generating
                </v-btn>
                <v-tooltip v-else location="top" open-delay="1000">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      style="border-radius: 0px !important; margin-right: 134px;"
                      variant="text"
                      density="compact"
                      class="text-none"
                      @click="generateCodeDialog = true"
                    >
                      <v-icon class="mr-2">mdi-creation</v-icon> Generate code
                    </v-btn>
                  </template>
                  <span>Fill in the contents of this file using AI</span>
                </v-tooltip>
              </div>
              <v-progress-linear v-if="object.isLoading" style="position: absolute;" indeterminate></v-progress-linear>

              <div v-if="object.isEditingPrompt" class="pa-4" style="height: 600px">
                <v-textarea
                  label="Prompt"
                  variant="outlined"
                  no-resize
                  v-model="object.prompt"
                />
                <v-btn @click="generateCode(object, object.prompt)">
                  Regenerate code using prompt
                </v-btn>
              </div>
              <code-editor
                v-else-if="object"
                v-model="object.contents"
                v-model:isDirty="object.isDirty"
                @save="saveFile(object)"
                :language="getLangaugeFromFilename(filename)"
              />
            </div>
          </template>
        </template>
      </v-col>
    </v-row>

    <v-dialog v-model="askBeforeClearingFiles" max-width="400">
      <v-card title="Warning">
        <v-card-text>
          Do you want to remove all your files in this bounded context and generate a new file structure with AI?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="() => { askBeforeClearingFiles = false; generateEntireApi(true) }">Ok</v-btn>
          <v-btn @click="askBeforeClearingFiles = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="generateCodeDialog" max-width="400">
      <v-card title="Generate code">
        <v-card-text>
          Do you want to clear this file and generate new code with AI?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="generateCodeDialog = false; generateCode(currentObject)">Yes</v-btn>
          <v-btn @click="generateCodeDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ChatLog } from '@/api/chatGPT/chatLog'
import { chatGPT } from '@/api/chatGPT/index'
import JSZip from 'jszip'
import FileListItem from '@/components/FileListItem.vue'
import CodeEditor from '@/components/CodeEditor.vue'
import AiVersionSelector from '@/components/AiVersionSelector.vue'
import ApiSpecificationBuilder from '@/codegen/apiSpecificationBuilder'

export default {
  components: {
    CodeEditor,
    FileListItem,
    AiVersionSelector
  },
  props: [
    'boundedContext'
  ],
  data () {
    return {
      aiVersion: 0,
      systemPromptFileStructureWithCode: 'a project described in the API specification provided below',
      systemPromptFileStructureWithSwagger: 'a swagger documentation describing the API endpoint methods, structure and short description.',
      systemPromptGenerateCode: 'Suggest the source code for a given file based on the instructions provided below.\nFirst comes an API specification written in YAML format which explains the endpoints and general information. Then the file tree structure is presented and lastly comes a description of the file and its purpose. Generate only source in the provided langauge as suggested by the filename. Format: make sure to only output code.',
      customInstructions: '',
      isLoading: true,
      openedTab: '__spec',
      tabs: ['spec'], // for vuetify
      opened: ['website-frontend', 'website-frontend/src', 'website-frontend/src/routes', 'website-frontend/src/models', 'website-frontend/src/middleware'],
      inputPromptDirty: false,
      fileTree: {},
      objects: {},
      spec: {},
      specSettings: [
        {
          title: 'NodeJS with Express',
          appType: 'nodeJsExpress',
          database: ['SQL-based', 'MongoDB', 'DynamoDB'],
          testing: ['Jest', 'Mocha']
        },
        {
          title: 'C# with ASP.NET Core',
          appType: 'aspDotNetCSharp',
          database: ['SQL-based', 'MongoDB', 'DynamoDB'],
          testing: ['xUnit', 'NUnit', 'MSTest']
        },
        { title: 'Swagger Documentation', appType: 'openAPI' }
      ],
      editApiSpecification: false,
      includeUserStories: false,
      askBeforeClearingFiles: false,
      generateCodeDialog: false
    }
  },
  computed: {
    ...mapState({
      workflow: state => state.workflow.workflow,
      savedObjects: state => state.boundedContexts.boundedContexts
    }),
    systemPromptFileStructure () {
      let result = ''
      if (this.customInstructions) {
        result += this.customInstructions
      } else if (this.spec['info.framework'] === 'Swagger Documentation') {
        result += `Act as an expert in swagger documentation with understanding of RESTful APIs.
Suggest a file structure for the documentation and try to modularize the documentation into logical pieces in separated files.`
      } else {
        result += `Act as an expert programmer with understanding of clean code principles, clean architecture and RESTful APIs.
Suggest a file structure for a project described in the following API specification.`
      }
      result += '\n\nUse the API specification provided below to generate the file structure'
      result += '\nFormat: generate valid JSON array with objects, where each object contains a filepath which should represent the file structure and prompt for generating the file or the actual contents of the file: '
      if (this.spec['info.framework'] === 'Swagger Documentation') {
        const ext = this.spec['info.language'] ? this.spec['info.language'].toLowerCase() : 'json'
        result += `[{"filepath":"project-name/paths/endpoint-a.${ext}","prompt":"Document endpoint A with relevant information"},{"filepath":"project-name/schemas/model-a.${ext}","prompt":"Document the fields in the model"},{"filepath":"project-name/openapi.${ext}","prompt":"Join together all files with $ref to other files"}]`
      } else {
        result += '[{"filepath":"project-name/src/index.js","prompt":"Fill in details about what this file should do, provide details from API specification"},{"filepath":"project-name/README.md","contents":"For simple files such as .env and README put the text here in contents"}]'
      }
      return result
    },
    selectedTemplate () {
      return this.specSettings.find(it => it.title === this.spec['info.framework'])
    },
    databaseOptions () {
      return this.selectedTemplate?.database || []
    },
    testingOptions () {
      return this.selectedTemplate?.testing || []
    },
    currentObject () {
      return this.objects[this.openedTab] || null
    }
  },
  mounted () {
    if (this.savedObjects && this.savedObjects[this.boundedContext.id]) {
      this.fileTree = {}
      this.addFiles(this.savedObjects[this.boundedContext.id])
      this.loadApiSpecification()
      this.isLoading = false
    } else if (this.boundedContext.id) {
      this.$store.dispatch('boundedContexts/getObjectsFromServer', this.boundedContext.id).then((objects) => {
        this.fileTree = {}
        this.addFiles(objects)
        this.loadApiSpecification()
        this.isLoading = false
      })
    }
  },
  methods: {
    keyDown (event) {
      if (this.objects.__spec?.isDirty && ((event.ctrlKey || event.metaKey) && event.key === 's')) {
        event.preventDefault()
        event.stopPropagation()
        this.saveFile(this.objects.__spec)
        return false
      }
    },
    clickEditSpecification (customizeSpec) {
      if (!customizeSpec) {
        this.loadTemplateSettings(this.objects.__spec.contents)
      }

      this.editApiSpecification = customizeSpec
    },
    addFile (object, openDirectory = false) {
      const parts = object.filepath.split('/')

      if (object.filepath !== '__spec') {
        let curr = this.fileTree
        let currPath = ''
        parts.forEach(part => {
          if (!curr[part]) {
            curr[part] = {}
          }
          curr = curr[part]

          // make sure new directories are opened
          if (openDirectory && !part.includes('.')) {
            if (currPath) {
              currPath += '/'
            }
            currPath += part

            if (!this.opened.includes(currPath)) {
              this.opened.push(currPath)
            }
          }
        })
      }

      const objectCopy = { ...object }
      if (!objectCopy.contents) {
        objectCopy.contents = ''
      }
      this.objects[object.filepath] = objectCopy
    },
    addFiles (objects) {
      objects.forEach(object => {
        this.addFile(object, true)
      })
    },
    async saveFile (object) {
      const parts = object.filepath.split('/')

      if (!object.id) {
        this.objects[object.filepath] = await this.$store.dispatch('boundedContexts/createObject', object)
      } else {
        await this.$store.dispatch('boundedContexts/updateObjectContents', object)

        if (object.filepath === '__spec') {
          this.$store.commit('snackbar/showMessage', { content: 'Saved API Specification', timeout: 3000, color: 'blue', centered: false }, { root: true })
        } else {
          this.$store.commit('snackbar/showMessage', { content: 'Saved ' + parts[parts.length - 1], timeout: 3000, color: 'blue', centered: false }, { root: true })
        }
      }
      object.isDirty = false
    },
    downloadZip () {
      const result = new JSZip()
      const addFilesToZip = (zip, fileTree, path) => {
        Object.keys(fileTree).forEach(name => {
          if (Object.keys(fileTree[name]).length) {
            const folder = zip.folder(name)
            addFilesToZip(folder, fileTree[name], path + name + '/')
          } else {
            zip.file(name, this.objects[path + name].contents)
          }
        })
      }
      addFilesToZip(result, this.fileTree, '')
      result.generateAsync({ type: 'blob' }).then(content => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(content)
        link.download = this.boundedContext.name + '.zip'
        link.click()
      })
    },
    getLangaugeFromFilename (filename) {
      const mapExtensionToLanguage = {
        js: 'javascript',
        cs: 'csharp',
        json: 'json',
        md: 'markdown',
        yaml: 'yaml',
        env: 'shell'
      }

      const extension = filename.split('.').pop()
      // TODO: this function seems to run every time chatGPT gives us a response. It should be optimized.
      // console.log(extension, mapExtensionToLanguage[extension])
      return mapExtensionToLanguage[extension]
    },
    buildFileTree (result, object) {
      const entry = {
        title: object.name,
        value: object.name
      }
      result.push(entry)

      if (object.type === 'directory') {
        entry.children = []
        object.children.forEach(child => {
          this.buildFileTree(entry.children, child)
        })
      }
    },
    loadApiSpecification () {
      const object = this.objects.__spec
      if (object) {
        this.editApiSpecification = true
        this.loadTemplateSettings(object.contents)
      } else {
        this.spec = {
          'info.name': this.boundedContext.name + ' API',
          'info.framework': this.specSettings[0].title,
          'info.database': this.databaseOptions[0],
          'testing.unitTests': this.testingOptions[0]
        }

        let object = this.objects.__spec
        if (!object) {
          this.objects.__spec = {
            filepath: '__spec',
            boundedContextId: this.boundedContext.id,
            prompt: '',
            contents: ''
          }
          object = this.objects.__spec
        }
        this.createApiSpecificationFromTemplate()
        this.saveFile(object)
      }

      this.$nextTick(() => {
        this.objects.__spec.isDirty = false
      })
    },
    updateTemplateSettings (template) {
      const spec = { ...this.spec }
      if (this.selectedTemplate?.appType) {
        const templateFunction = this[this.selectedTemplate.appType]
        if (typeof templateFunction === 'function') {
          templateFunction(spec)
        }
      }

      const builder = new ApiSpecificationBuilder(spec, template)
      builder.updateSecurity()
      builder.updateDependencies()
      builder.updateTesting()
      builder.updateEnvironment()
      builder.updateInfo()
      this.objects.__spec.contents = builder.toString()
    },
    loadTemplateSettings (template) {
      const spec = {}
      const isDirty = this.objects.__spec.isDirty

      const lines = template.split('\n')
      for (let i = 0; i < lines.length; i++) {
        if (lines[i].startsWith('info:')) {
          spec['info.name'] = lines[i + 1].split('name: ')[1]
          spec['info.framework'] = lines[i + 2].split('framework: ')[1]
          if (spec['info.framework'] === 'Swagger Documentation') {
            spec['info.language'] = lines[i + 3].split('language: ')[1]
          } else {
            spec['info.database'] = lines[i + 3].split('database: ')[1]
          }
          this.editApiSpecification = false
        }

        if (lines[i].startsWith('testing:')) {
          spec['testing.unitTests'] = lines[i + 1].split('unit testing framework: ')[1]
        }
      }

      this.spec = spec
      this.$nextTick(() => {
        this.objects.__spec.isDirty = isDirty
      })
    },
    createApiSpecificationFromTemplate () {
      const spec = { ...this.spec }

      if (this.selectedTemplate) {
        const templateFunction = this[this.selectedTemplate.appType]

        if (typeof templateFunction === 'function') {
          templateFunction(spec)
        }
      }

      const builder = new ApiSpecificationBuilder(spec)
      builder.pushInfo()
      builder.pushEnvironment()
      builder.pushDependencies()
      builder.pushEndpoints(this.boundedContext, this.includeUserStories)
      builder.pushModels(this.boundedContext, this.workflow.dataModels)
      builder.pushSecurity()
      builder.pushTesting()
      builder.pushDocumentation()

      let apiSpecification = '# API Specification begins here\n'
      apiSpecification += builder.toString() + '\n'
      apiSpecification += '# API Specification ends here\n'
      this.objects.__spec.contents = apiSpecification
    },
    updateFrameworkSelection (framework) {
      this.spec = {
        'info.name': this.spec['info.name'],
        'info.framework': framework,
        'info.database': this.spec['info.database']
      }
      if (framework === 'Swagger Documentation') {
        this.spec['info.database'] = null
        this.spec['testing.unitTests'] = null
        this.spec['info.language'] = 'JSON'
      }
    },
    nodeJsExpress (spec) {
      // TODO: this code should be moved to codegen folder!

      // Technical details
      spec['info.language'] = 'javascript'
      spec['info.runtime'] = 'nodejs'

      // Security
      spec['security.authentication'] = 'passport with JWT'
      spec['security.authorization'] = 'Role-based access control for endpoints'
      spec['security.dataValidation'] = 'joi' // TODO: should be configurable, maybe use express-validator
      spec['security.errorHandling'] = 'Standardized error response structure. Use try-catch blocks and middleware for error handling.'

      // Documentation
      spec['documentation.api'] = 'swagger' // TODO: customizable

      // Setup dependencies
      spec.dependencies = [
        'express',
        'body-parser',
        'cors',
        'dotenv',
        'joi'
      ]

      spec.environment = [
        'PORT: 3000'
      ]

      // Database
      if (spec['info.database'] === 'SQL-based') {
        spec.dependencies.push('sequelize')
        spec.dependencies.push('sqlite3')
        spec.environment.push('DATABASE_STORAGE: ./database.sqlite')
      } else if (spec['info.database'] === 'MongoDB') {
        spec.dependencies.push('mongoose')
        spec.environment.push('DATABASE_URL: mongodb://127.0.0.1:27017/local')
      } else if (spec['info.database'] === 'DynamoDB') {
        spec.dependencies.push('aws-sdk')
        spec.environment.push('DATABASE_URL: http://localhost:8000')
      }

      // Testing framework
      if (spec['testing.unitTests'] === 'Jest') {
        spec.dependencies.push('jest')
      } else if (spec['testing.unitTests'] === 'Mocha') {
        spec.dependencies.push('mocha')
        spec.dependencies.push('chai')
      }

      // Security
      spec.dependencies.push('passport')
      spec.dependencies.push('passport-jwt')
    },
    aspDotNetCSharp (spec) {
      // TODO: this code should be moved to codegen folder!

      // Technical details
      spec['info.language'] = 'C#'
      spec['info.runtime'] = '.NET'

      // Security
      spec['security.authentication'] = 'JWT'
      spec['security.authorization'] = 'Role-based access control for endpoints'
      spec['security.dataValidation'] = 'FluentValidation'
      spec['security.errorHandling'] = 'Standardized error response structure. Use try-catch blocks and middleware for error handling.'

      // Documentation
      spec['documentation.api'] = 'swagger' // TODO: customizable

      // Setup dependencies
      spec.dependencies = [
        'Microsoft.AspNetCore.Mvc.NewtonsoftJson',
        'Microsoft.EntityFrameworkCore.Tools',
        'Swashbuckle.AspNetCore',
        'FluentValidation.AspNetCore'
      ]

      spec.environment = [
        'PORT: 3000'
      ]

      // Database
      if (spec['info.database'] === 'SQL-based') {
        spec.dependencies.push('Microsoft.EntityFrameworkCore.SqlServer')
        spec.environment.push('DATABASE_URL: Server=(localdb)\\mssqllocaldb;Database=localdb;Trusted_Connection=True;MultipleActiveResultSets=true')
      } else if (spec['info.database'] === 'MongoDB') {
        spec.dependencies.push('MongoDB.Driver')
        spec.environment.push('DATABASE_URL: mongodb://localhost:27017')
      } else if (spec['info.database'] === 'DynamoDB') {
        spec.dependencies.push('AWSSDK.DynamoDBv2')
        spec.environment.push('DATABASE_URL: http://localhost:8000')
      }

      // Testing
      if (spec['testing.unitTests'] === 'xUnit') {
        spec.dependencies.push('xunit')
        spec.dependencies.push('xunit.runner.visualstudio')
      } else if (spec['testing.unitTests'] === 'NUnit') {
        spec.dependencies.push('nunit')
        spec.dependencies.push('nunit3testadapter')
      } else if (spec['testing.unitTests'] === 'MSTest') {
        spec.dependencies.push('MSTest.TestFramework')
        spec.dependencies.push('MSTest.TestAdapter')
      }
    },
    openAPI () {
      // TODO: this code should be moved to codegen folder!ew

      this.spec.environment = null
      this.spec.dependencies = null

      // TODO: add this
      return {}
    },
    stopGenerating (object) {
      chatGPT.abortFetch()
      if (object) {
        object.isLoading = false
      } else {
        this.objects.__spec.isLoading = false
      }
    },
    async generateEntireApi (force = false) {
      const apiSpecification = this.objects.__spec.contents
      if (!apiSpecification) {
        this.$store.commit('snackbar/showMessage', { content: 'Cannot generate code without API specification', timeout: 6000, color: 'red', centered: true }, { root: true })
        return 0
      }

      const hasFileStructure = await this.generateFileStructure(force)
      if (hasFileStructure) {
        this.objects.__spec.isLoading = true

        const promises = Object.values(this.objects).map(object => {
          if (object.filepath === '__spec') return Promise.resolve()
          return this.generateCode(object)
        })
        await Promise.all(promises)
        this.objects.__spec.isLoading = false
      }
    },
    async generateFileStructure (force = false) {
      const apiSpecification = this.objects.__spec.contents
      if (Object.keys(this.fileTree).length > 0 && !force) {
        this.askBeforeClearingFiles = true
        return false
      }

      this.objects.__spec.isLoading = true
      this.fileTree = {}
      this.objects = { __spec: this.objects.__spec }
      this.opened = []

      await this.$store.dispatch('boundedContexts/deleteBoundedContext', this.boundedContext.id)
      this.objects.__spec.id = null // reset id so it gets created again
      await this.saveFile(this.objects.__spec)
      this.objects.__spec.isLoading = true

      if (this.spec['info.framework'] === 'Swagger Documentation') {
        const object = {
          filepath: 'swagger.' + this.spec['info.language'].toLowerCase(),
          boundedContextId: this.boundedContext.id,
          prompt: 'Generate swagger documentation describing the API endpoint methods, structure and short description. Use OpenAPI version 3.0.0.',
          contents: ''
        }
        this.addFile(object, true)
        this.saveFile(object)
        return true
      }

      this.chat = new ChatLog()
      this.chat.pushSystem(this.systemPromptFileStructure)
      this.chat.pushUser(apiSpecification)

      await this.chat.streamJsonResponses((response) => {
        if (!this.objects.__spec.isLoading) return false
        if (typeof response.filepath === 'string') {
          let contents = null
          let prompt = null
          if (response.contents) {
            contents = response.contents.replaceAll(/\\n/g, '\n')
          }
          if (response.prompt) {
            prompt = response.prompt.replaceAll(/\\n/g, '\n')
          }

          const object = {
            filepath: response.filepath,
            boundedContextId: this.boundedContext.id,
            prompt,
            contents
          }
          this.addFile(object, true)
          this.saveFile(object)
        }
      }, this.aiVersion)
      const successful = this.objects.__spec.isLoading
      this.objects.__spec.isLoading = false
      return successful
    },
    async generateCode (object, customPrompt = null) {
      const apiSpecification = this.objects.__spec.contents
      if (object.filepath === '__spec') return
      if (!(object.contents || object.prompt || customPrompt) || !object.filepath) {
        this.$store.commit('snackbar/showMessage', { content: 'Cannot generate code with empty prompt', timeout: 6000, color: 'red', centered: true }, { root: true })
        return 0
      }

      this.chat = new ChatLog()
      this.chat.pushSystem(this.systemPromptGenerateCode)

      let userPrompt = apiSpecification + '\n\n'
      userPrompt += 'Here is the current file tree:\n'
      userPrompt += this.fileTreeToYaml(this.fileTree) + '\n\n'
      const prompt = customPrompt || object.contents || object.prompt
      userPrompt += `Generate code for file \`${object.filepath}\`:\n${prompt}`
      this.chat.pushUser(userPrompt)

      object.isEditingPrompt = false
      object.isLoading = true
      object.contents = ''

      let state = 'text'
      let numCodeBlocks = 0
      await this.chat.streamTextResponse((str) => {
        // parse out the code from the response
        while (str) {
          const codeIndex = str.indexOf('```')
          if (state === 'text') {
            if (codeIndex !== -1) {
              state = 'code-header'
              str = str.slice(codeIndex + 3)
              continue
            } else if (numCodeBlocks === 0) {
              object.contents += str
            }
          } else if (state === 'code-header') {
            const endIndex = str.indexOf('\n')
            if (endIndex !== -1) {
              str = str.slice(endIndex + 1)
              state = 'code'
              if (numCodeBlocks === 0) {
                object.contents += ''
              }
              numCodeBlocks++
              continue
            }
          } else if (state === 'code') {
            if (codeIndex !== -1) {
              state = 'text'
              object.contents += str.slice(0, codeIndex)
              str = str.slice(codeIndex + 3)
              continue
            } else {
              object.contents += str
            }
          }

          str = ''
        }
      }, this.aiVersion)

      await this.saveFile(object)
      object.isLoading = false
    },
    fileTreeToYaml (fileTree, result = '', indent = 0) {
      Object.keys(fileTree).forEach(name => {
        for (let i = 0; i < indent; i++) result += '  '

        if (Object.keys(fileTree[name]).length) {
          result += name + ':\n'
          result = this.fileTreeToYaml(fileTree[name], result, indent + 1)
        } else {
          result += name + '\n'
        }
      })
      return result
    }
  },
  watch: {
    spec: {
      handler: function () {
        if (this.objects.__spec) {
          this.objects.__spec.isDirty = true
          this.updateTemplateSettings(this.objects.__spec.contents)
        }
      },
      deep: true
    },
    tabs (val) {
      if (val[0]) {
        this.openedTab = val[0]
      } else {
        this.tabs[0] = this.openedTab
      }
    }
  }
}
</script>

<style scoped>

.toolbar-top {
  font-size: 12.25px;
  font-weight: 500;
  padding: 2px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toolbar-absolute-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 8px 4px 8px 16px !important;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  background-color: white;
  z-index: 9999999;
}

.toolbar-shadow {
  box-shadow: #dddddd 0 6px 6px -6px inset;
  transform: rotate(180deg);
  position: absolute;
  bottom: 56px;
  height: 6px;
  width: 100%;
}

</style>
