const { v1: uuidv1 } = require('uuid')

export default function randomUUID () {
  if (process.env.JEST_WORKER_ID) {
    return 'test-uuid'
  }
  let uuid
  if (typeof crypto.randomUUID === 'function') {
    uuid = crypto.randomUUID()
  } else {
    uuid = uuidv1()
  }
  return uuid
}
