import wrap from 'word-wrap'

export function replaceForeignObjectsWithTextAndCenter (div) {
  if (!div) {
    return
  }

  // Foreign objects are used in SVG graph to display text that could be updated by the user (Event boxes, labels,
  // decisions, lanes etc.)
  const foreignObjects = div.getElementsByTagName('foreignObject')

  for (let i = foreignObjects.length - 1; i >= 0; i--) {
    const foreignObject = foreignObjects[i]
    const textParentContainer = foreignObject.querySelector('div.text-cell')
    const isLabelElement = !!foreignObject.querySelector('div.label-name')

    if (!textParentContainer) {
      continue
    }

    const textNode = extractTextNode(foreignObject)
    const parentNodeOfForeignObject = foreignObject.parentNode
    const rectElement = parentNodeOfForeignObject.querySelector('rect')
    const foreignObjectX = parseFloat(foreignObject.getAttributeNS(null, 'x'))

    if (foreignObjectX) {
      const text = createSvgTextElementFromForeignObject(textNode, isLabelElement)
      replaceForeignObjectWithSvgText(
        textParentContainer,
        foreignObjectX,
        text,
        parentNodeOfForeignObject,
        foreignObject,
        isLabelElement
      )

      if (rectElement && parentNodeOfForeignObject.tagName.toLowerCase() === 'g') {
        adjustTextHeightForRectElement(rectElement, text)
        continue
      }

      const foreignObjectY = parseFloat(foreignObject.getAttributeNS(null, 'y'))
      adjustTextHeightForPlainElement(foreignObjectY, text)
    }
  }
}

function extractTextNode (foreignObject) {
  // If the node is event, the text will be in event-name class, if it is a label, label-name class is used
  // and if the text is from gateway, gateway-name class must return the text value
  return foreignObject.querySelector('div.text-cell.event-name') ||
    foreignObject.querySelector('div.text-cell.label-name') ||
    foreignObject.querySelector('div.text-cell.gateway-name')
}

function createSvgTextElementFromForeignObject (textNode, isLabelElement) {
  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text')

  if (isLabelElement) {
    text.setAttribute(
      'style',
      'text-anchor: left; dominant-baseline: left; fill: black; font-size: 9px; font-family:Roboto,Arial;'
    )
    return text
  }

  text.setAttribute(
    'style',
    'text-anchor: middle; dominant-baseline: middle; fill: black; font-size: 9px; font-family:Roboto,Arial;'
  )
  return text
}

function replaceForeignObjectWithSvgText (innerDiv, foreignObjectX, text, parentG, foreignObject, isLabelElement) {
  const cutLength = isLabelElement ? 17 : 11

  // Wrap words in multiple lines to provide similar behaviour as with HTML inside foreignObject
  const unprocessedWords = innerDiv.textContent.split(' ')

  // Cut words that are longer than cutLength
  const cutWords = []
  unprocessedWords.forEach(word => {
    while (word.length > cutLength) {
      cutWords.push(word.slice(0, cutLength))
      word = word.slice(cutLength)
    }

    if (word.length > 0) {
      cutWords.push(word)
    }
  })

  const words = wrap(cutWords.join(' '), { width: cutLength, cut: false, trim: false })

  const lineHeight = 1.1 // Adjust this value for desired line spacing

  const textX = isLabelElement ? foreignObjectX : foreignObjectX + 47
  const lines = words.split('\n')
  lines.forEach(line => {
    const tspan = createTspanElementFromSingleLine(textX, lineHeight, text, line)

    // If text is from label, add label-text class so its height can be positioned properly later on
    if (isLabelElement) {
      text.classList.add('label-text')
    }
    text.appendChild(tspan)
  })

  parentG.replaceChild(text, foreignObject)
}

function createTspanElementFromSingleLine (textX, lineHeight, text, line) {
  const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan')
  tspan.setAttribute('x', `${textX}`)
  tspan.setAttribute('dy', `${lineHeight}em`)
  tspan.setAttribute('style', text.getAttribute('style'))
  tspan.textContent = line
  return tspan
}

function adjustTextHeightForRectElement (rectElement, textElement) {
  if (rectElement && textElement) {
    const gHeight = parseFloat(rectElement.getAttribute('height'))
    const rectY = parseFloat(rectElement.getAttribute('y'))

    // Height offset is calculated based on how many rows a text has (how many tspans are present s)
    const heightOffset = textElement.children.length >= 1 ? textElement.children.length * 6 : 6

    // This formula tries to center the text inside event box as good as possible
    let textY = Math.floor(rectY + (gHeight) / 2) - heightOffset

    // If text is from event label move it above the event box
    if (textElement.classList.contains('label-text')) {
      textY = rectY - heightOffset - 12
    }
    textElement.setAttribute('y', textY)
  }
}

function adjustTextHeightForPlainElement (foreignObjectY, textElement) {
  if (foreignObjectY && textElement) {
    // 10 is used based on visual testing.
    const textY = foreignObjectY + 10
    textElement.setAttribute('y', textY.toString())
  }
}

export function addSwimLanes (flowchartTransformed, lanesData, laneColor, hasGroups = false) {
  // Get the lane IDs from the provided SVG object
  const laneIds = Object.keys(lanesData)

  // Iterate over the lane IDs
  laneIds.forEach((laneId, index) => {
    const lane = lanesData[laneId]
    // Calculate the vertical position of the text element
    const laneContainerWidth = 30

    const offset = hasGroups ? 30 : 0
    const yPosition = (lane.height / 2) + lane.y + offset
    const yRectPosition = lane.y + offset
    const rotationAngle = -90

    // Create a group element as a container for the text and rectangle
    const group = document.createElementNS('http://www.w3.org/2000/svg', 'g')

    // Create a rectangle element
    const rectangle = createRectangleContainer(yRectPosition, laneContainerWidth, lane, laneColor)

    // Create a bottom border line element
    const bottomBorder = createBottomBorder(yRectPosition, lane, laneContainerWidth)

    // Create a text element
    const text = createLaneTextSvgElement(laneContainerWidth, yPosition, rotationAngle, lane)

    // Append the rectangle and text elements to the group
    group.appendChild(rectangle)
    group.appendChild(bottomBorder)
    group.appendChild(text)

    // Append the text element to the new SVG
    flowchartTransformed.appendChild(group)
  })
}

function createRectangleContainer (yRectPosition, laneContainerWidth, lane, laneColor) {
  const rectangle = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
  rectangle.setAttribute('x', '0')
  rectangle.setAttribute('y', yRectPosition.toString())
  rectangle.setAttribute('width', `${laneContainerWidth}`)
  rectangle.setAttribute('height', `${lane.height}`)
  rectangle.setAttribute('fill', laneColor || '#E0E0E0')

  return rectangle
}

function createBottomBorder (yRectPosition, lane, laneContainerWidth) {
  const bottomBorder = document.createElementNS('http://www.w3.org/2000/svg', 'line')
  bottomBorder.setAttribute('x1', '0')
  bottomBorder.setAttribute('y1', (yRectPosition + lane.height).toString())
  bottomBorder.setAttribute('x2', `${laneContainerWidth}`)
  bottomBorder.setAttribute('y2', (yRectPosition + lane.height).toString())
  bottomBorder.setAttribute('stroke', 'black')
  bottomBorder.setAttribute('stroke-width', '1.5')

  return bottomBorder
}

function createLaneTextSvgElement (laneContainerWidth, yPosition, rotationAngle, lane) {
  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text')
  text.setAttribute('style', 'text-anchor: middle; dominant-baseline: middle; fill: black; font-size: 12px; font-family:Roboto,Arial;')
  // Set the attributes for the text element
  text.setAttribute('x', `${laneContainerWidth / 2}`)
  text.setAttribute('y', (yPosition + 4).toString())
  text.setAttribute('text-anchor', 'middle')
  text.setAttribute('dominant-baseline', 'central')
  text.setAttribute('transform', `rotate(${rotationAngle}, ${laneContainerWidth / 2}, ${yPosition})`)

  // Set the text content to the lane name
  text.textContent = lane.name

  return text
}
