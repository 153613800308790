import gql from 'graphql-tag'

export default gql`
  subscription OnAddRequirementComment($workflowId: String) {
    onAddRequirementComment(workflowId: $workflowId) {
      requirementId
      workflowId
      id
      author {
        id
        firstname
        lastname
      }
      text
      dateCreated
      dateUpdated
      generatedByAi
    }
  }
`
