<template>
  <div class="view-only-banner">
    <v-tooltip location="bottom" open-delay="1000">
      <template v-slot:activator="{ props }">
        <div>
          <v-icon color="white">mdi-eye</v-icon>
          <span class="view-only-banner__text">View only mode</span>
          <v-icon color="white" v-bind="props">info_outline</v-icon>
        </div>
      </template>
      <div>
        You have to be invited to the project to be able to edit this workflow.
      </div>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  name: 'view-mode-tooltip'
}
</script>
<style>
:root {
  --color-primary: #022a44;
  --light-blue: #3183fb;
}

.view-only-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 39px;
  background: #4C83D1;
}

.view-only-banner__text {
  padding: 0 10px;
}
</style>
