import positionHelpers from './positionHelpers'
export default class Checks {
  static checkDiagramConsistency (svgGraph, svgLanes, svgGroups, events) {
    let errors = 0
    const errorList = []
    const tmp = {}
    let tmpSet

    // check that all parent >> child connections are ok
    const parentIds = new Set()
    const svgGraphValues = Object.values(svgGraph || {})
    let foundStartNode = false
    const hasGroups = Object.keys(svgGroups || {})?.length > 0
    for (const node of svgGraphValues) {
      // check that there is at least one start node
      if (node.parents[0] === 'start') {
        foundStartNode = true
      }

      for (const childId of node?.childIds || []) {
        // check that parent is set on all children
        if (!svgGraph[childId]?.parents.includes(node.id)) {
          errors++
          errorList.push('ERROR: "' + node.name + '" has a broken connection to "' + (svgGraph[childId]?.name || childId) + '" : Parent not set on child')
        }

        // check that no node of tpye 'bpmn:Task' has more than one child
        // seems to be covered by other tests, cannot trigger this error
        // if (node.childIds.length > 1) {
        //   if (node.type === 'bpmn:Task') {
        //     errors++
        //     errorList.push('ERROR: ' + node.name + ' has more than one child')
        //   }
        // }
      }

      // check if an event of type 'bpmn:Task' NOT occurs as parent more than one time
      for (const parentId of node.parents) {
        if (parentId !== 'start' && parentIds.has(parentId) && svgGraph[parentId].type === 'bpmn:Task') {
          errors++
          errorList.push('ERROR: ' + svgGraph[parentId].name + ' has more than one child')
          errorList.push(JSON.stringify(node))
        }
        parentIds.add(parentId)
      }

      // check that all child >> parent connections are ok
      for (const parentId of node.parents) {
        if (parentId !== 'start') {
          if (!svgGraph[parentId] || (svgGraph[parentId].type === 'bpmn:Task' && !svgGraph[parentId]?.childIds?.includes(node.id))) {
            errors++
            errorList.push('ERROR: ' + node.name + ' has a broken connection to ' + svgGraph[parentId]?.name + ' : Child not set on parent')
          }
        }
      }

      // check that there are no nodes lying on top of each other
      const keyString = 'slotX:' + Math.floor(node.x / 160) + '-slotY:' + Math.floor(node.y / 140)
      if (tmp[keyString]) {
        errors++
        errorList.push('ERROR: two nodes overlapping at: ' + keyString + '. Node ' + tmp[keyString] + ' and ' + node.name)
      }
      tmp[keyString] = node.name

      // check for duplicate parents or children
      tmpSet = new Set(node.childIds)
      if (node.childIds && tmpSet.size !== node.childIds.length) {
        errors++
        errorList.push('ERROR: Duplicate childIds on node: ' + node.name)
      }
      tmpSet = new Set(node.parents)
      if (node.parents && tmpSet.size !== node.parents.length) {
        errors++
        errorList.push('ERROR: Duplicate parents on node: ' + node.name + ' Parents: ' + node.parents)
      }

      // check for nodes in wrong lane
      if (svgLanes) {
        const laneId = node.laneId || svgGraph[node.parents[0]].laneId
        if (node.y < svgLanes[laneId].y || node.y > (svgLanes[laneId].y + svgLanes[laneId].height)) {
          errors++
          errorList.push('ERROR: Node in wrong lane: ' + node.name + ' not in lane ' + node.laneId)
        }
      }

      // check for nodes with negative y values
      if (node.y < 0) {
        errors++
        errorList.push('ERROR: Negative y coordinates on on node: ' + node.name)
      }

      // verify that if start exists, it is always on index 0
      if (node.parents.includes('start')) {
        if (node.parents[0] !== 'start') {
          errors++
          errorList.push(`ERROR: Node ${node.name} has parent start in an index larger than 0`)
        }
      }

      // verify that all start nodes are in slotX:0
      if (node.parents.includes('start') && node.x !== 80) {
        if (hasGroups && positionHelpers.findGroupStartingAtCoordinate(node.x, svgGroups)) {
          // ok with start node at the start of a group
        } else {
          errors++
          errorList.push('ERROR: Start node not in slot 0: ' + node.name)
        }
      }

      // verify that all nodes in slotX:0 are start nodes
      if (node.x === 80 && !node.parents.includes('start')) {
        errors++
        errorList.push('ERROR: Node not in slot 0 that is not a start node: ' + node.name)
      }

      // verify that all nodes have parents
      if (!node.parents || node.parents.length === 0) {
        errors++
        errorList.push('ERROR: Node without parent ' + node.name)
      }

      // verify that all nodes either have parent[0] on the left side or is a start node
      if (node.parents[0] !== 'start' && svgGraph[node.parents[0]]?.x > node.x) {
        errors++
        errorList.push(node.name + ' has no parent on the left side and is not a start node')
      }

      // verify that all nodes that are located right after a group divder
      // have the correct groupId set except for the first group
      if (hasGroups) {
        const group = positionHelpers.findGroupStartingAtCoordinate(node.x, svgGroups)
        if (group?.startSlotX > 0 && group?.id !== node.groupId) {
          errors++
          errorList.push(`ERROR: Node ${node?.name} does not have the correct groupId set. Expected: ${group?.id} but got: ${node?.groupId}`)
        }
      }
    }

    if (!foundStartNode && svgGraphValues.length > 0) {
      errors++
      errorList.push('ERROR: No start node found')
    }

    // svgGraph should have the same number of nodes as events or +/- one (for adding deleting)
    if (events && events.length !== svgGraphValues.length && (events.length + 1) !== svgGraphValues.length && (events.length + -1) !== svgGraphValues.length) {
      errors++
      errorList.push(`ERROR: Number of events in diagram: ${svgGraphValues.length} does not match number of events in events array: ${events.length}`)
    }

    // traverse the graph from all starting points and count the number of nodes
    // this check is no longer needed because it's coverd but the test above 'Child not set on parent'
    // const nodeMap = dataHelpers.createMainParentChildEventMap(svgGraphValues)
    // const svgConnectedNodeCount = countConnectedNodes(nodeMap, 'start')
    // if (svgConnectedNodeCount !== svgGraphValues.length) {
    //   errors++
    //   errorList.push('ERROR: Number of connected nodes in diagram: ' + svgConnectedNodeCount + ' does not match number of total nodes in graph: ' + svgGraphValues.length)
    // }

    return { errors, errorList }
  }
}

// not needed at the moment
// function countConnectedNodes (graph, startNode) {
//   const visited = new Set() // To keep track of visited nodes and avoid duplicates

//   function visit (node) {
//     // If node has already been visited, return immediately
//     if (visited.has(node)) {
//       return
//     }

//     // Mark this node as visited
//     if (node !== 'start') {
//       visited.add(node)
//     }

//     // Recursively visit all children
//     if (graph[node]) {
//       graph[node].forEach(child => visit(child.id))
//     }
//   }

//   // Start the visitation from the specified start node
//   visit(startNode)

//   // Return the number of unique nodes visited
//   return visited.size
// }
