<template>
  <div class="path-container">
    <svg :width="svgWidth" :height="svgHeight">
      <path
        :d="path"
        stroke="rgb(118, 118, 118)"
        stroke-linejoin="round"
        stroke-width="1"
        fill="none"
      ></path>
    </svg>
  </div>
</template>

<style scoped>
.path-container {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: -1;
}
</style>

<script>
export default {
  props: {
    startElementId: String,
    endElementId: String,
    refreshKey: Number,
    start: {
      type: String,
      default: 'right'
    },
    end: {
      type: String,
      default: 'left'
    }
  },
  data () {
    return {
      startPos: { x: 0, y: 0 },
      endPos: { x: 0, y: 0 },
      svgWidth: 0,
      svgHeight: 0,
      path: ''
    }
  },
  mounted () {
    this.updatePath()
    window.addEventListener('resize', this.updatePath)
    this.$nextTick(() => {
      this.updatePath()
    })
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.updatePath)
  },
  methods: {
    updatePath () {
      const { xStart, xEnd, yStart, yEnd } = this.getCoordinates()
      const d = 'M' + (xStart) + ',' + yStart +
        ' L' + (xStart) + ',' + (yEnd - 10) +
        ' L' + (xEnd) + ',' + (yEnd - 10) +
        ' L' + (xEnd) + ',' + yEnd
      this.path = d
    },
    getCoordinates () {
      const startElement = document.getElementById(this.startElementId)
      const endElement = document.getElementById(this.endElementId)

      if (!startElement || !endElement) return

      const startRect = startElement.getBoundingClientRect()
      const endRect = endElement.getBoundingClientRect()

      // Find parent element with classname service-border
      let parentElement = startElement.offsetParent
      while (parentElement && !parentElement.classList.contains('service-border')) {
        parentElement = parentElement.offsetParent
      }

      if (parentElement) {
        this.svgWidth = parentElement.offsetWidth
        this.svgHeight = parentElement.offsetHeight
      }

      if (this.start === 'bottom') {
        this.startPos = {
          x: startRect.left - parentElement.getBoundingClientRect().left + startRect.width / 2,
          y: startRect.top - parentElement.getBoundingClientRect().top + startRect.height
        }
      }

      if (this.end === 'top') {
        this.endPos = {
          x: endRect.left - parentElement.getBoundingClientRect().left + endRect.width / 2,
          y: endRect.top - parentElement.getBoundingClientRect().top
        }
      }
      return {
        xStart: this.startPos.x,
        yStart: this.startPos.y,
        xEnd: this.endPos.x,
        yEnd: this.endPos.y
      }
    }
  },
  watch: {
    refreshKey () {
      this.$nextTick(() => {
        this.updatePath()
      })
    }
  }
}
</script>
