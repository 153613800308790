import gql from 'graphql-tag'
import { WORKFLOW_FIELDS } from '../fragments'

export default gql`
  ${WORKFLOW_FIELDS}
  query listWorkflows($projectId: String!) {
    listWorkflows(projectId: $projectId) {
      project {
        workflows {
          ...WorkflowFields
        }
      }
    }
  }
`
