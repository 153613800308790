<template>
  <div>
    <div v-if="loadingState">
      <v-overlay class="loading-overlay text-center align-center justify-center" :model-value="true">
        <v-progress-circular
          color="white"
          indeterminate
          size="64"
        />
      </v-overlay>
    </div>
    <div v-else-if="workflow && workflow.id === $route.params.id"
      :data-test-workflow="true"
      :data-test-version="latestVersionNumberForCypress"
      class="overflow-hidden"
    >
      <navigation-top>
        <navigation-top-workflow
          :previousVersionExists="previousVersionExists"
          :versions="versions"
          :releases="releases"
          :showDone="showDone"
        />
      </navigation-top>

      <bpmn
        ref="bpmn"
        :key="JSON.stringify(showReleaseNo)"
        :workflow="workflow"
        :show-done="showDone"
        @move-right="moveRight"
        @move-left="moveLeft"
      ></bpmn>

      <v-layout class="tab-button-container">
        <button
          class="tab-button"
          @click="$router.push({ query: { selectedTab: 0 } })"
          v-bind:class="{ inactive: selectedTab != 0, active: selectedTab == 0 }"
        >
          <template v-if="!selectedEvent">All Cards</template>
          <template v-else>
            {{
              'Cards for "' +
              (selectedEvent && selectedEvent.description.substring(0, 20)) +
              (selectedEvent && selectedEvent.description.length > 20
                ? '.."'
                : '"')
            }}
          </template>
          <span
            v-if="selectedEvent && selectedTab == 0"
            @click.stop="changeSelectedEvent(null)"
            class="text-caption text-decoration-underline"
          >
            (Show all)
          </span>
        </button>
        <button
          class="tab-button flex-shrink-0"
          @click="$router.push({ query: { selectedTab: 1 } })"
          v-bind:class="{ inactive: selectedTab != 1, active: selectedTab == 1 }"
        >
          User Story Map
        </button>
        <button
          class="tab-button flex-shrink-0"
          @click="$router.push({ query: { selectedTab: 2 } })"
          v-bind:class="{ inactive: selectedTab != 2, active: selectedTab == 2 }"
        >
          Data Models
          <span
            v-if="selectedEvent && selectedTab == 2"
            @click.stop="
              changeSelectedEvent(null);
            "
            class="text-caption text-decoration-underline"
            >(Show matrix)</span
          >
        </button>
        <button
          class="tab-button flex-shrink-0"
          @click="$router.push({ query: { selectedTab: 3 } })"
          v-bind:class="{ inactive: selectedTab != 3, active: selectedTab == 3 }"
        >
          Aggregates
        </button>
        <button
          class="tab-button flex-shrink-0"
          @click="$router.push({ query: { selectedTab: 4 } })"
          v-bind:class="{ inactive: selectedTab != 4, active: selectedTab == 4 }"
        >
          History
        </button>
        <v-spacer></v-spacer>
      </v-layout>

      <workflow-backlog
        v-if="selectedTab == 0 || selectedTab == 1"
        :eventId="this.$route.params.eventId"
        :workflow="workflow"
        :show-done="showDone"
        :jiraData2="jiraData2"
        :loadingJiraInfo="loadingJiraInfo"
        :selectedTab="selectedTab"
        :releases="releases"
        @show-all="showAll"
        @display-done="onShowDoneChange"
        @ask-for-reply="onAskForReply"
        ref="backlog"
      ></workflow-backlog>

      <template v-if="selectedTab == 2">
        <beam
          v-if="this.$route.params.eventId"
          :event="selectedEvent"
          :generatorFactTableFunc="$refs?.bpmn?.$refs?.generator.addFactTable"
          :generatorEventStoryFunc="$refs?.bpmn?.$refs?.generator.addEventStory"
        />
        <beam-summary
          v-else
          :events="events"
          :parentChildEventMap="createMainParentChildEventMap(events)"
        />
      </template>

      <workflow-aggregates
        v-if="selectedTab == 3 && this.svgGraphData && this.svgLanesData"
        :events="events"
        :event="selectedEvent"
        :svgGroups="this.$refs.bpmn?.svgGroups"
      @role-blur="(ev, oldName, index) => $refs?.bpmn.blurOnRoleName(ev, oldName, index)"
    ></workflow-aggregates>

      <workflow-history
        :workflow="this.workflow"
        :versions="versions"
        v-if="selectedTab == 4"
      ></workflow-history>

      <workflow-delete-item-dialog
          ref="deleteItemDialog"
          :itemType="'Event'"
          :hideIcon="true"
      ></workflow-delete-item-dialog>
    </div>
    <not-found v-else />
  </div>
</template>

<script>
import Bpmn from '@/components/Bpmn'
import WorkflowBacklog from '@/components/WorkflowBacklog'
import BeamSummary from '@/components/BeamSummary'
import Beam from '@/components/Beam'
import WorkflowAggregates from '@/components/WorkflowAggregates'
import WorkflowHistory from '@/components/WorkflowHistory'
import WorkflowDeleteItemDialog from '@/components/WorkflowDeleteItemDialog'
import workflowApi from '@/api/workflowApi'
import recentWorkflowsApi from '@/api/recentWorkflowsApi'
import { diagramHelpers } from '@/mixins/diagramHelpers.js'
import { workflowHelpers } from '@/mixins/workflowHelpers.js'
import dataHelpers from '@/helpers/dataHelpers'
import cognitoAuth from '@/cognito'
import NavigationTop from '@/components/NavigationTop'
import NavigationTopWorkflow from '@/components/NavigationTopWorkflow'
import NotFound from '@/pages/NotFound'
import { mapState, mapGetters } from 'vuex'
import 'svg2pdf.js'
import copyPasteHelpers from '../helpers/copyPasteHelpers'

export default {
  name: 'Workflow',
  components: {
    Bpmn,
    WorkflowBacklog,
    Beam,
    BeamSummary,
    WorkflowHistory,
    NavigationTop,
    NotFound,
    WorkflowDeleteItemDialog,
    NavigationTopWorkflow,
    WorkflowAggregates
  },
  mixins: [diagramHelpers, workflowHelpers],
  data () {
    return {
      eventType: '',
      showDone: false,
      latestVersionNumberForCypress: null,
      accessToken: null,
      loadingJiraInfo: 0,
      checkVersionTimeout: null
    }
  },
  computed: {
    ...mapState({
      workflow: state => state.workflow.workflow,
      workflowHistory: state => state.workflowHistory.workflowHistory,
      jiraIssues: state => state.jira.jiraIssues,
      showReleaseNo: state => state.workflow.showReleaseNo,
      requirementTypes: state => state.requirementTypes.requirementTypes
    }),
    ...mapGetters({
      loadingState: 'workflow/getLoadingState',
      svgGraphData: 'svgGraphData/svgGraphData',
      svgLanesData: 'svgGraphData/svgLanesData',
      svgGroupsData: 'svgGraphData/svgGroupsData'
    }),
    versions () {
      const res = this.workflowHistory ? [...this.workflowHistory] : []
      return res
    },
    selectedTab () {
      if (this.$route.query?.selectedTab) {
        return this.$route.query.selectedTab
      } else {
        return 0
      }
    },
    previousVersionExists () {
      let index
      if (this.workflow) {
        const restoreVersion = this.workflow.sameAsVersion ? (this.workflow.sameAsVersion - 1) : this.workflow.version - 1
        index = this.versions?.findIndex(element => element.version === restoreVersion)
      }
      if (index >= 0) {
        return true
      } else {
        return false
      }
    },
    events () {
      return this.workflow.eventsJson || []
    },
    releases () {
      if (!this.events) { return [] }
      let requirements = []
      for (const event of this.events) {
        if (event.requirementsJson) {
          requirements = requirements.concat(event.requirementsJson)
        }
      }
      const res = new Set()
      for (const requirement of requirements) {
        if (requirement.releaseNo) {
          res.add(requirement.releaseNo)
        }
      }
      return [...res]
        .sort(function (a, b) { return a - b })
        .map(value => ({
          value,
          text: `R${value}`,
          title: `R${value}`
        }))
    },
    selectedEvent () {
      const { eventId } = this.$route.params
      if (eventId && this.events) {
        const event = this.events.find(wt => wt.id === eventId)
        return event || null
      }
      return null
    },
    jiraData2 () {
      const result = this.jiraIssues?.reduce(function (map, req) {
        map[req.requirementId] = req
        return map
      }, {})
      return result || {}
    }
  },
  watch: {
    'workflow.version': {
      handler: function (newVal) {
        this.$nextTick(() => {
          this.latestVersionNumberForCypress = newVal
        })
      },
      immediate: true
    },
    'workflow.status': {
      handler: function (newVal) {
        this.updateWorkflowStatus(newVal)
      },
      immediate: true
    },
    'workflow.id': {
      handler: async function (newVal) {
        if (!this.$route.params.projectId || !this.$route.params.id || !newVal) {
          return
        }

        try {
          const session = await cognitoAuth.getSession()
          this.accessToken = session.getAccessToken().getJwtToken()
          if (this.accessToken) {
            this.$store.dispatch('jira/getJiraIssues', {
              apiToken: this.accessToken,
              projectId: this.$route.params.projectId,
              workflowId: this.$route.params.id
            })
          }
        } catch (error) {}
        recentWorkflowsApi.add(this.workflow)
      },
      immediate: true
    },
    '$store.state.workflow.isViewOnlyEnabled': {
      handler: function (newVal) {
        this.updateWorkflowStatus(this.workflow?.status)
      }
    }
  },
  created () {
    window.addEventListener('keydown', this.keydown)
    document.addEventListener('copy', this.onCopy)
    document.addEventListener('paste', this.onPaste)
    document.addEventListener('cut', this.onCut)
  },
  beforeUnmount () {
    // it is imortant to remove event listener when component is destroyed
    // otherwise component's reference in callback becomes broken
    window.removeEventListener('keydown', this.keydown)
  },
  methods: {
    updateWorkflowStatus (status) {
      if (this.$store.state.workflow.isViewOnlyEnabled) {
        this.$store.commit('setWorkflowDisabled', true)
        return true
      }
      this.$store.commit('setWorkflowDisabled', status === 2)
      return status === 2
    },
    onAskForReply (event) {
      this.$refs.bpmn.onAskForReply(event)
    },
    createMainParentChildEventMap (events) {
      return dataHelpers.createMainParentChildEventMap(events)
    },
    undo: workflowApi.undo,
    redo: workflowApi.redo,
    keydown (event) {
      if (event.target.tagName === 'INPUT') {
        return
      }
      if (this.workflowSettingsOpen || this.workflowStatusCompleted || this.$store.state.workflow.isViewOnlyEnabled) {
        return
      }
      if (event.key === 'z' && (event.ctrlKey || event.metaKey) && !event.shiftKey) {
        this.undo(this.workflow)
      }
      if ((event.key === 'y' && event.ctrlKey) || (event.key === 'y' && event.metaKey) || (event.key === 'z' && event.metaKey && event.shiftKey)) {
        this.redo(this.workflow)
      }
      if (!this.selectedEvent) {
        return
      }
      if (this.commentsDialog || this.dialog || this.exportDialog || this.exportJiraDialog) {
        return
      }
      if (event.keyCode === 13) {
        const eventId = this.$route.params.eventId
        const contenteditable = document.getElementById(eventId)
        setTimeout(() => {
          contenteditable.focus()
        }, 0)
        return
      }
      if ((event.keyCode === 37 && event.altKey === false) || (event.keyCode === 9 && event.shiftKey === true)) { // left arrow (select previous event)
        this.moveLeft()
        event.preventDefault()
        return
      }
      if (event.keyCode === 38 && event.altKey === false) { // up arrow (select event above)
        const nodes = Object.values(this.svgGraphData).filter(node => node.x === this.svgGraphData[this.selectedEvent.id].x && node.y < this.svgGraphData[this.selectedEvent.id].y)
        nodes.sort(function (a, b) { return b.y - a.y })
        if (nodes[0]) {
          this.selectEvent(nodes[0].id)
          event.preventDefault()
        }
        return
      }
      if ((event.keyCode === 39 && event.altKey === false) || (event.keyCode === 9 && event.shiftKey === false)) { // right arrow (select next event)
        this.moveRight()
        event.preventDefault()
        return
      }
      if (event.keyCode === 40 && event.altKey === false) { // down arrow (select event below)
        const nodes = Object.values(this.svgGraphData).filter(node => (node.x === this.svgGraphData[this.selectedEvent.id].x) && (node.y > this.svgGraphData[this.selectedEvent.id].y))
        nodes.sort(function (a, b) { return a.y - b.y })
        if (nodes[0]) {
          this.selectEvent(nodes[0].id)
          event.preventDefault()
        }
        return
      }
      if (event.keyCode === 38 && event.altKey === true) { // option - up arrow (create event on lane above)
        if (this.workflowStatusCompleted) {
          return
        }

        const laneId = this.$refs.bpmn.svgLanes[this.selectedEvent.laneId].laneAbove
        if (laneId) {
          this.$refs.bpmn.addShape({ laneId })
        }
        return
      }
      if (event.keyCode === 39 && event.altKey === true) { // option - right arrow (create event on same lane)
        if (this.workflowStatusCompleted) { return }
        this.$refs.bpmn.addShape()
        return
      }
      if (event.keyCode === 40 && event.altKey === true) { // option - down arrow (create event on  lane below)
        if (this.workflowStatusCompleted) { return }
        event.preventDefault()
        const shape = { laneId: this.$refs.bpmn.svgLanes[this.selectedEvent.laneId].laneBelow }
        const options = { createNewLaneIfNoLaneId: true }
        this.$refs.bpmn.addShape(shape, options)
        return
      }
      if (event.key === 'Backspace' && event.altKey === true) {
        if (this.workflowStatusCompleted) { return }
        if (this.selectedEvent) {
          this.$refs.deleteItemDialog.openDialog()
        }
      }
      if (
        // when user starts to type on a selected
        // event or decision without cursor
        (event.keyCode === 8 ||
          (event.keyCode >= 48 &&
            event.keyCode <= 90) ||
          (event.keyCode >= 160 &&
            event.keyCode <= 173)) &&
        event.ctrlKey === false &&
        event.metaKey === false &&
        event.altKey === false &&
        // This is to prevent typing in the event when text editor (e.g. in aggregates) is focused
        event.target.classList.contains('ql-editor') === false
      ) {
        const eventId = this.$route.params.eventId
        const contenteditable = document.getElementById(eventId)
        contenteditable.innerHTML = ''
        contenteditable.focus()
      }
    },
    moveLeft () {
      if (this.selectedEvent.parents[0] && this.selectedEvent.parents[0] !== 'start') {
        this.selectEvent(this.selectedEvent.parents[0])
      }
    },
    moveRight () {
      const nodes = Object.values(this.svgGraphData).filter(node => node.parents[0] === this.selectedEvent.id)
      const currentY = this.svgGraphData[this.selectedEvent.id].y
      nodes.sort((a, b) => { return Math.abs(currentY - a.y) - Math.abs(currentY - b.y) })
      if (nodes[0]) {
        this.selectEvent(nodes[0].id)
      }
    },
    showAll () {
      this.eventId = null
      this.$router.push({ path: '/workflow/' + this.$route.params.projectId + '/' + this.$route.params.id }).catch(() => { })
    },
    selectEvent (eventId) {
      this.$router.push({ path: '/workflow/' + this.$route.params.projectId + '/' + this.$route.params.id + '/' + eventId }).catch(() => { })
    },
    onShowDoneChange (val) {
      this.showDone = val
    },
    checkForVersionGap () {
      if (this.workflow) {
        const restoreVersion = this.workflow.sameAsVersion ? (this.workflow.sameAsVersion - 1) : this.workflow.version - 1
        const index = this.versions?.findIndex(element => element.version === restoreVersion)
        if (index === -1) {
          this.$store.dispatch('workflowHistory/listWorkflowHistory', {
            projectId: this.workflow.projectId,
            workflowId: this.workflow.id,
            subscribe: false
          })
        }
      }
      this.checkVersionTimeout = setTimeout(this.checkForVersionGap, 5000)
    },

    // copy active event to clipboard
    onCopy (event) {
      const tag = event.target.tagName
      if (tag !== 'BODY') {
        // don't catch these events because they can be input fields
        return
      }
      if (this.selectedEvent) {
        // copy the json data to the clipboard's json format
        const jsonData = JSON.stringify({
          ...this.selectedEvent,
          workflowId: this.workflow.id,
          requirementTypes: this.requirementTypes,
          color: this.selectedEvent.color, // ? this.selectedEvent.color : this.workflow.eventDefinition.color,
          laneName: this.svgLanesData[this.selectedEvent.laneId].name
        })
        event.clipboardData.setData('application/json', jsonData)
        // if we have an active cursor on the event, then
        // do a standard copy of the selection
        // but if the whole event is selected (no cursor), then
        // copy the whole description to the clipboard
        if (document.activeElement.id !== this.selectedEvent.id) {
          // special copy, full description
          event.clipboardData.setData('text/plain', this.selectedEvent?.description)
          event.preventDefault()
        } else {
          // dont't prevent default, let the browser do the copy
          // of the selected text, the json object is already copied
        }
        this.$store.commit('snackbar/showMessage', { content: 'Copied', color: 'white' }, { root: true })
      }
    },

    onPaste (event) {
      const tag = event.target.tagName
      if (tag !== 'BODY') {
        // don't catch these events because they can be input fields
        return
      }
      if (this.workflowStatusCompleted) {
        return
      }
      if (!this.$refs.bpmn) {
        return
      }
      // only paste if we don't have a cursor on the event
      // if we have a cursor, then let the browser handle the paste
      if (!this.selectedEvent?.id || (this.selectedEvent.id !== document.activeElement.id)) {
        try {
          const clipboardData = event.clipboardData.getData('application/json')
          if (clipboardData) {
            const jsonData = JSON.parse(clipboardData)
            if (copyPasteHelpers.isValidShape(jsonData)) {
              const shape = copyPasteHelpers.prepareAddShape(jsonData, this.selectedEvent, this.svgGraphData, this.svgLanesData, this.workflow, this.requirementTypes)
              this.$refs.bpmn.addShape(shape)
              event.preventDefault()
            } else {
              this.$store.commit('snackbar/showMessage', { content: 'Invalid element', color: 'red' }, { root: true })
            }
          }
        } catch (error) {
        // Handle JSON parse errors or any other errors
          console.error('Error parsing clipboard data:', error)
          this.$store.commit('snackbar/showMessage', { content: 'Error pasting data. Please try again.', color: 'red' }, { root: true })
        }
      }
    },

    onCut (event) {
      const tag = event.target.tagName
      if (tag !== 'BODY') {
        // don't catch these events because they can be input fields
        return
      }
      if (this.selectedEvent?.id && (this.selectedEvent.id !== document.activeElement.id)) {
        if (this.svgGraphData[this.selectedEvent.id].childIds?.length > 1) {
          if (this.$refs.deleteItemDialog) {
            this.$refs.deleteItemDialog.openDialog()
          }
        } else {
          this.onCopy(event)
          workflowApi.deleteEventFromWorkflow(this.selectedEvent.id, this.svgGraphData, this.svgLanesData, this.svgGroupsData, this.workflow)
          const parentId = this.selectedEvent.parents[0]
          if (this.svgGraphData[parentId]) {
            this.$router.push({ path: '/workflow/' + this.$route.params.projectId + '/' + this.workflow.id + '/' + parentId }).catch(() => { })
          } else {
            this.$router.push({ path: '/workflow/' + this.$route.params.projectId + '/' + this.workflow.id }).catch(() => { })
          }
        }
      }
    }
  },
  async mounted () {
    if (!window.Cypress) {
      this.checkVersionTimeout = setTimeout(this.checkForVersionGap, 5000)
    }
  },
  unmounted () {
    clearTimeout(this.checkVersionTimeout)
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.workflow-heading {
  cursor: pointer;
}

.setting-search-field {
  padding-top: 6px;
}

.v-list__tile__sub-title {
  font-size: 10px;
}

.theme--light.v-subheader {
  text-decoration: underline;
  color: black;
}

.container {
  padding-top: 0px;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.hidden {
  display: none;
}

.hover-underline:hover {
  text-decoration: underline;
}

.approval-button {
  margin-right: 0px;
}

.v-list__tile_comments > div {
  height: auto;
}

.export-button {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.5px;
  border-radius: 10px !important;
}

.workflow-settings-button {
  min-width: auto !important;
}

.v-tooltip__content {
  background: white;
  color: var(--color-primary);
  border-radius: 10px;
  max-width: 320px;
}

nowrap {
  white-space: nowrap;
}
</style>

<style>
.select-release-no {
  width: 200px;
}

.select-release-no .v-field {
  min-height: 36px !important;
}

.select-release-no .v-field .v-field__input {
  padding: 0 !important;
}

.select-release-no .v-field {
  min-height: 36px !important;
  background: #183053 !important;
  border-radius: 10px !important;
}

.select-release-no .v-field .v-field__field label,
.select-release-no .v-field .v-icon {
  color: white;
}

.select-release-no .v-field .v-field__field label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.select-release-no .v-field .v-field__field .field__input input {
  margin-top: -20px;
}

.tab-button-container {
  width: 50%;
  height: 36px;
  position: sticky;
  left: 0;
  max-width: 50%;
  overflow: hidden;
}

.tab-button:first-child {
  margin-left: 10px;
}

.tab-button {
  padding: 0 10px;
  max-width: 400px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tab-button.active {
  font-weight: 500;
  border-bottom: 2px solid #022a44;
}

.tab-button.inactive {
  color: #9e9e9e;
  font-weight: 400;
}

.tab-button:focus {
  outline: 0;
}

.access-level-select-container span {
  margin: 0 5px;
}

.access-level-select >>> fieldset {
  border-style: none;
}

.access-level-select  .v-select__selection {
  color: #4C83D1 !important;
  font-size: 14px;
}

.access-level-select.v-icon {
  color: #4C83D1 !important;
}

.access-level-select {
  border: none; /* Remove default border */
  outline: none; /* Remove outline when clicked */
  appearance: none; /* Remove default styling on some browsers */
  margin: 5px;

  max-width: 130px;
  :focus {
    outline:none;
  }
}

.access-level-select option {
  border: none; /* Remove border on option elements */
  padding: 5px; /* Add some padding for visual separation */
  font-size: 16px; /* Set a font size */
  color: #333; /* Set font color */
  appearance: none;
}

.access-disabled .v-select__selection  {
  color: grey !important;
}
</style>
