<template>
  <g>
    <!-- extra wrapper for filter to work around safari bug -->
    <g filter="url(#shadow-event)">
      <rect
        :id="'r-' + node.id"
        :x="node.x"
        :y="node.y"
        width="100"
        height="80"
        :style="{
          'stroke-width': isSelectedEvent(node.id)
            ? '2px'
            : node.outlined
            ? '1px'
            : '0px',
          stroke: color,
          'fill-opacity': node.outlined ? '0' : '1',
          fill: isSelectedEvent(node.id) ? RGBToHSL(color) : color,
        }"
        :class="{
          'connection-target': connectionTarget,
        }"
      >
      </rect>
    </g>
    <defs>
      <filter
        id="shadow-event"
        x="-20%"
        y="-20%"
        width="140%"
        height="140%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        color-interpolation-filters="linearRGB"
      >
        <feDropShadow
          stdDeviation="3 3"
          in="SourceGraphic"
          dx="2"
          dy="2"
          flood-opacity="1"
          x="0%"
          y="0%"
          width="100%"
          height="100%"
          result="dropShadow1"
        />
      </filter>
    </defs>

    <!-- editable Event Label -->
    <foreignObject
      v-if="node.showConditionLabelOnEvent"
      :x="node.x - 12"
      :y="node.y - 31"
      width="120"
      height="32"
    >
      <bpmn-event-label
        :node="node"
        :condition-label="getConnectionLabel(this.node, this.node.parents[0])"
        @label-blur="blurOnConditionLabels($event, node, node.parents[0])"
        @label-focus="focusOnConditionLabel"
      ></bpmn-event-label>
    </foreignObject>

    <!-- editable Event name -->
    <foreignObject
      :x="node.x + 3"
      :y="node.y"
      width="94"
      height="80"
    >
      <div
        @mousedown="onEventClick"
        class="text-box justify-center align-center"
        @dragover.prevent="eventDragoverHandler"
        @drop.prevent="eventDropHandler"
      >
        <div
          ref="eventName"
          :id="node.id"
          class="text-cell event-name align-center justify-center draggable"
          :data-test="svgGraphData?.[node.id]?.name"
          :data-test-version="svgGraphData?.[node.id]?.version"
          :contenteditable="!$store.state.isWorkflowDisabled"
          @keydown.stop.exact="checkCharacterLimit($event, 40)"
          @keydown.shift.exact="checkCharacterLimit($event, 40)"
          @keydown.stop.prevent.enter="toggleFocus"
          @keydown.stop.esc="restoreValue($event, node.name)"
          @keydown.stop.tab.exact="moveRight($event)"
          @keydown.stop.tab="moveLeft($event)"
          @blur.stop="blurOnDescription($event, node)"
          @paste="onPaste($event, $refs.eventName, 40)"
          v-text="node.name || node.description"
          @input="() => updateFontSizeDynamically(this.$refs.eventName)"
        ></div>
      </div>
    </foreignObject>
  </g>
</template>

<script>
import BpmnEventLabel from '@/components/BpmnEventLabel'
import { diagramHelpers } from '@/mixins/diagramHelpers.js'
import workflowApi from '@/api/workflowApi'
import { updateFontSizeDynamically } from '@/utils/update-font.js'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    BpmnEventLabel
  },

  mixins: [diagramHelpers],

  props: {
    connectionTarget: Boolean,
    disabled: Boolean,
    node: Object,
    svgGraph: Object,
    svgLanes: Object,
    eventDefinition: Object,
    workflow: Object,
    svgGroups: Object
  },

  methods: {
    updateFontSizeDynamically,
    RGBToHSL (color) {
      color = color.replace('rgb(', '')
      color = color.replace(')', '')
      let [r, g, b] = color.split(',')
      r /= 255
      g /= 255
      b /= 255
      const l = Math.max(r, g, b)
      const s = l - Math.min(r, g, b)
      const h = s
        ? l === r
          ? (g - b) / s
          : l === g
            ? 2 + (b - r) / s
            : 4 + (r - g) / s
        : 0
      const res = [
        60 * h < 0 ? 60 * h + 360 : 60 * h,
        100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
        (100 * (2 * l - s)) / 2
      ]
      const string = `hsl(${res[0]}, ${res[1]}%, ${(15 + res[2])}%)`
      return string
    },
    moveRight (event) {
      this.$emit('move-right')
      event.target.blur()
      event.preventDefault()
    },
    moveLeft (event) {
      this.$emit('move-left')
      event.target.blur()
      event.preventDefault()
    },
    onEventClick (event) {
      if (this.connectionTarget) {
        this.addConnection(this.node.id)
      } else {
        this.changeSelectedEvent(this.node.id)
        // event.stopPropagation()
        // var div = document.getElementById(this.node.id)
        // setTimeout(function () {
        //   div.focus()
        // }, 0)
      }
    },

    eventDragoverHandler (ev) {
      ev.dataTransfer.dropEffect = 'copy'
    },

    eventDropHandler (ev) {
      if (ev.dataTransfer.getData('component') === 'user-story-mapping') {
        const data = ev.dataTransfer.getData('text')
        workflowApi.moveRequirementJson(this.$route.params.id, '', ev.target.id, data)
      } else {
        const cardTypeId = ev.dataTransfer.getData('text/plain')
        this.addCardToEvent(this.node.id, cardTypeId, '<p>New card</p>')
      }
    }
  },

  mounted () {
    if (this.$refs.eventName) {
      updateFontSizeDynamically(this.$refs.eventName)
    }
    if (this.node.selectEventText) {
      // yuriy
      // for some reason `this.$nextTick` doesn't fix the problem
      // with async state change of the component
      // `setTimeout` does a trick and allows to select the text
      // when element becomes selected
      //
      // staffan:
      // disabled because of cypress tests
      // window.setTimeout(() => {
      //   const node = this.$el.querySelector('.event-name')
      //   if (node) {
      //     const selection = window.getSelection()
      //     const range = document.createRange()
      //     range.selectNodeContents(node)
      //     selection.removeAllRanges()
      //     selection.addRange(range)
      //   }
      // }, 1000)
    }
  },

  computed: {
    ...mapGetters({
      baseData: 'svgGraphData/baseData',
      svgGraphData: 'svgGraphData/svgGraphData'
    }),
    ...mapState({
      requirementTypes: state => state.requirementTypes.requirementTypes
    }),
    color () {
      return this.node.color ? this.node.color.rgb : this.eventDefinition.color.rgb
    }
  }
}
</script>

<style scoped>
rect {
  stroke: black;
  stroke-width: 2px;
}

.v-tooltip__content {
  background: white;
  color: var(--color-primary);
  border-radius: 10px;
}

.v-tooltip__content code {
  background: var(--color-primary);
  color: white;
}

.connection-target {
  animation: target 0.8s ease-in-out both infinite;
}

@keyframes target {
  0% {
    stroke-width: 2px;
    stroke: black;
  }

  50% {
    stroke-width: 5px;
  }

  100% {
    stroke-width: 2px;
    stroke: black;
  }
}

.event-name {
  word-break: break-word;
  height: 80px;
  max-height: 80px;
  min-height: 80px;
}

.event-name:focus-visible {
  outline: none;
}

/* .shadow {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
} */
</style>
