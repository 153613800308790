<template>
  <div>
    <navigation-top />
    <v-card v-if="project" class="mt-2">
      <v-tabs>
        <v-tab ripple> Project Team Members </v-tab>

        <v-spacer></v-spacer>

        <v-text-field
          name="search-team-members"
          class="align-self-center mt-0 pt-0"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          :single-line="true"
          variant="underlined"
          hide-details
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <invite-team-member-dialog>
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" color="primary" class="ma-2 align-self-center"
              >Invite Team Member to Project</v-btn
            >
          </template>
        </invite-team-member-dialog>

      </v-tabs>
      <v-window>
        <v-card flat>
          <v-data-table
            :headers="headersTeamMembers"
            :items="formattedData"
            :search="search"
            hide-default-footer
            :must-sort="true"
            class="elevation-1 round-bottom"
            disable-pagination
          >
            <template v-slot:bottom></template>
            <template #item="props">
              <tr>
                <td>
                  {{ props.item.user?.fullname }}
                  {{
                    props.item.userId == $store.state.userId ? "(you)" : ""
                  }}
                </td>

                <td>
                  {{
                    props.item.user ? props.item.user.email : props.item.email
                  }}
                </td>

                <td class="text-right">
                  {{
                    !props.item.user
                      ? "No signup yet"
                      : props.item.lastLogin
                        ? props.item.lastLogin.substring(0, 10)
                        : "No login to this project"
                  }}
                </td>

                <td class="text-right">
                  <v-chip
                    small
                    v-if="
                        props.item.user &&
                        props.item.user.id === project.organisation.userId
                      "
                  >admin</v-chip
                  >
                </td>

                <td class="text-center">
                  <v-icon
                    v-if="
                        !props.item.user ||
                        props.item.user.id !== project.organisation.userId
                      "
                    size="small"
                    @click="deleteItemTeamMember(props.item)"
                  >
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-window>
    </v-card>
  </div>
</template>

<script>
import InviteTeamMemberDialog from '@/components/InviteTeamMemberDialog'
import projectApi from '@/api/projectApi'
import NavigationTop from '../components/NavigationTop.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    InviteTeamMemberDialog,
    NavigationTop
  },
  data: () => ({
    headersTeamMembers: [
      {
        title: 'Name',
        align: 'start',
        sortable: true,
        value: 'user.fullname'
      },
      {
        title: 'Email',
        align: 'start',
        sortable: true,
        value: 'user.email'
      },
      {
        title: 'Last login to project',
        align: 'end',
        sortable: false,
        value: 'lastLogin'
      },
      {
        title: '',
        align: 'end',
        sortable: false
      },
      {
        title: '',
        align: 'center',
        sortable: false
      }
    ],
    search: ''
  }),

  computed: {
    ...mapState({
      listTeamMembers: state => state.teamMembers.teamMembers
    }),
    ...mapGetters({
      project: 'projectMemberships/activeProject'
    }),
    formattedData () {
      if (!this.listTeamMembers) return []
      const res = this.listTeamMembers.map(teamMember => {
        return {
          ...teamMember,
          user: teamMember.user
            ? {
                ...teamMember.user,
                fullname: `${teamMember.user.firstname} ${teamMember.user.lastname}`
              }
            : {
                email: teamMember.email,
                lastLogin: 'No login to this project'
              }
        }
      })
      return res
    }
  },

  methods: {
    deleteItemTeamMember (item) {
      projectApi.deleteTeamMember(this.$router, item, this.$route.params.projectId)
    }
  }
}
</script>

<style scoped>
.align-self-center {
  align-self: center;
}
</style>
