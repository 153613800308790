import ListWorkflowHistory from '@/graphql/queries/ListWorkflowHistory'
import { apolloClient } from '@/vue-apollo'
import OnCreateOrUpdateWorkflow from '@/graphql/subscriptions/OnCreateOrUpdateWorkflow'
import OnUpdateRequirementsJson from '@/graphql/subscriptions/OnUpdateRequirementsJson'

export const workflowHistory = {
  namespaced: true,
  state: {
    workflowHistory: null
  },
  mutations: {
    setWorkflowHistory (state, { workflowHistory, workflow }) {
      state.workflowHistory = workflowHistory
      if (state.workflowHistory?.findIndex((historyRecord) => historyRecord.version === workflow.version) === -1) {
        // add current version to the top of the history
        state.workflowHistory.unshift({
          id: workflow.id,
          updatedAt: workflow.updatedAt,
          updateNote: workflow.updateNote,
          version: workflow.version
        })
      }
    },
    addHistoryRecord (state, payload) {
      const addVersion = payload.version
      const index = state.workflowHistory.findIndex((workflow) => workflow.version === addVersion)
      if (index >= 0) {
        // replace existing record
        state.workflowHistory[index] = {
          id: payload.id,
          updatedAt: payload.updatedAt,
          updateNote: payload.updateNote,
          version: payload.version
        }
      } else {
        // check for gaps in the history
        if (state.workflowHistory.length > 0 && addVersion >= 3) {
          if (state.workflowHistory.findIndex((workflow) => workflow.version === addVersion - 2) === -1) {
            // found a gap, thow and error
            throw new Error('Gap in workflow history')
          }
        }
        // add current version to the top of the history
        state.workflowHistory.unshift({
          id: payload.id,
          updatedAt: payload.updatedAt,
          updateNote: payload.updateNote,
          version: payload.version
        })
      }
    }
  },
  actions: {
    async listWorkflowHistory ({ commit, dispatch, rootState }, { projectId, workflowId, subscribe = true }) {
      try {
        // Skip this query if user is anonymous and in view only mode
        if (rootState.workflow.isViewOnlyEnabled === true) {
          return
        }
        const res = await apolloClient.query({
          query: ListWorkflowHistory,
          variables: {
            projectId,
            workflowId
          },
          fetchPolicy: 'no-cache'
        })
        commit('setWorkflowHistory', { workflowHistory: res.data?.listWorkflowHistory?.workflowHistory, workflow: rootState.workflow.workflow })

        if (rootState.isUserLoggedIn === false) {
          // don't subscribe if user is not logged in or view only mode is enabled
          return
        }

        if (subscribe) {
          apolloClient.subscribe({
            query: OnCreateOrUpdateWorkflow,
            variables: { projectId },
            fetchPolicy: 'no-cache'
          }).subscribe({
            next (res) {
              if (workflowId !== res.data.onCreateOrUpdateWorkflow.id) return
              try {
                commit('addHistoryRecord', res.data.onCreateOrUpdateWorkflow)
              } catch (e) {
                console.log(e)
                dispatch('listWorkflowHistory', { projectId, workflowId, subscribe: false })
              }
            },
            error (error) {
              console.error('Subscription error:', error)
            }
          })

          apolloClient.subscribe({
            query: OnUpdateRequirementsJson,
            variables: { workflowId },
            fetchPolicy: 'no-cache'
          }).subscribe({
            next (res) {
              try {
                commit('addHistoryRecord', {
                  id: res.data.onUpdateRequirementsJson.workflowId,
                  ...res.data.onUpdateRequirementsJson
                })
              } catch (e) {
                console.log(e)
                dispatch('listWorkflowHistory', { projectId, workflowId, subscribe: false })
              }
            },
            error (error) {
              console.error('Subscription error:', error)
            }
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
