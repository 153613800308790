import gql from 'graphql-tag'

export default gql`
  mutation createTeamMember($projectId: String!, $email: String!) {
    createTeamMember(projectId: $projectId, email: $email) {
      projectId
      userId
      createTeamMemberFunction {
        id
        userId
        email
        projectId
        lastLogin
        user {
          id
          firstname
          lastname
          email
        }
        project {
          id
          name
          organisationId
          organisation {
            id
            name
            userId
            user {
              id
              firstname
              lastname
            }
          }
        }
      }
    }
  }
`
