export const cardHelpers = {
  methods: {
    getTeamMembersReactionsList (requirementStatuses, teamMembers) {
      const res = teamMembers?.map(teamMember => {
        return {
          ...teamMember,
          ...requirementStatuses.find(element => element.userId === teamMember.userId)
        }
      })
      return res
    },
    getAverage (teamMembersReactions) {
      let totalScore = 0
      let noOfVotes = 0

      for (const index in teamMembersReactions) {
        const teamMemberReaction = teamMembersReactions[index]

        if (teamMemberReaction && teamMemberReaction.prio < 5) {
          totalScore = totalScore + teamMemberReaction.prio
          noOfVotes++
        }
      }
      if (noOfVotes > 0) {
        const res = totalScore / noOfVotes
        return Math.round(res)
      } else {
        return ''
      }
    }
  }
}
