<template>
  <v-card class="form-container pa-6">
    <img src="@/assets/qlerify-logo-small.png"
        name="preview-image-url" alt="Qlerify logo" class="mx-auto" style="display: block;"/>
    <h3 class="text-h5 font-weight-bold pb-4 text-center">Reset password</h3>

    <p class="text-center">If you have an account, you will receive a new registsration<br />
      code by email that you can use to set a new password.
    </p>

    <v-form ref="form" v-model="valid">
      <v-container>
        <v-row class="my-0">
          <v-col class="pa-0">
            <p class="text-caption ma-0 text-grey-darken-2">
              Email
            </p>
            <v-text-field
              label="Email"
              type="email"
              variant="solo"
              v-model="username"
              :rules="[rules.required, rules.email]"
              validate-on="blur"
              v-on:keydown.enter="submit"
              autofocus
            ></v-text-field>
          </v-col>
        </v-row>

        <div class="bg-error" v-html="error" />
        <div class="message" v-html="message" />

        <v-row class="my-0">
          <v-col class="pa-0 d-flex justify-center">
            <v-btn color="primary" @click="submit">Request reset Password</v-btn>
          </v-col>
        </v-row>

        <v-row class="my-6">
          <v-col class="pa-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="pa-0 d-flex justify-center">
            <p class="ma-0">
              Go back to login?
              <router-link :to="{ path: '/login' }" tabindex="5">
                <span class="light--text">Login</span>
              </router-link>
            </p>
          </v-col>
        </v-row>

      </v-container>
    </v-form>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      username: '',
      error: null,
      valid: true,
      message: null,
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      }
    }
  },
  methods: {
    async submit (event) {
      event.preventDefault()
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.$cognitoAuth.forgotPassword(this.username, (err, result) => {
          if (err) {
            this.error = err.message
          } else {
            this.$router.replace('/confirm-reset-password?username=' + this.username)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
div.error {
  color: red;
  background: none !important;
  border: none;
}

.form-container {
  max-width: 600px;
  margin: 24px auto;
}
</style>
