<template>
  <v-card-text class="mt-4 text-black tab-content">
    <div class="text-subtitle-1 font-weight-bold">
      General Settings
    </div>
    <v-card class="pa-3 elevation-3">
      <v-layout class="d-flex flex-wrap align-center justify-space-between">
        <v-col>
          <div
            class="text-caption font-weight-medium text-uppercase text-truncate"
          >
            Workflow Status
          </div>
          <div>(Set to completed to lock for changes)</div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="4"
          class="
            d-flex
            text-caption
            font-weight-medium
            text-uppercase text-truncate text-center
          "
        >
          <v-select
            flat
            variant="solo"
            hide-details
            density="compact"
            bg-color="primary"
            class="rounded-lg status-select"
            v-model="status"
            :items="statuses"
            :disabled="loadingState"
            @update:model-value="updateWorkflowStatus"
          ></v-select>
        </v-col>
      </v-layout>
    </v-card>
  </v-card-text>
</template>

<script>
import workflowApi from '@/api/workflowApi'
import { mapState, mapGetters } from 'vuex'

export default {
  data () {
    return {
      statuses: [
        { value: 0, title: 'In Progress' },
        { value: 2, title: 'Completed' }
      ],
      status: 0
    }
  },

  methods: {
    updateWorkflowStatus (status) {
      workflowApi.updateWorkflowMutation(
        this.$route.params.projectId,
        {
          id: this.$route.params.id,
          status: this.status,
          expectedVersion: this.workflow.version,
          updateNote: {
            action: this.status === 2 ? 'marked workflow as completed' : 'marked workflow as in progress'
          }
        }
      )
    }
  },
  computed: {
    ...mapState({
      workflow: state => state.workflow.workflow
    }),
    ...mapGetters({
      loadingState: 'loading/getLoadingState'
    })
  },
  watch: {
    workflow: {
      handler (val) {
        this.status = val.status
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style>
.tab-content {
  height: 1000px;
}

.status-select {
  width: 160px;
}

.status-select .v-select__selection {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
}
</style>
