<template>
  <div>
    <h5 class="workflow-name card-title">
      <template v-if="disabled">
        {{ workflow.projectName ? workflow.projectName + ' > ' : '' }} > {{ workflow.name }}
      </template>

      <router-link
        v-else
        :to="{ path: `/workflow/${workflow.projectId}/${workflow.id}` }"
      >
        {{ workflow.projectName ? workflow.projectName + ' >' : '' }} {{ workflow.name }}
      </router-link>
    </h5>

    <v-card class="pa-2 d-flex workflow-card align-center" min-width="361px" max-width="361px">
      <div v-if="disabled" class="d-flex">
        <img
          width="228"
          height="92"
          src="@/assets/workflow-placeholder.svg"
          alt="workflow placeholder"
        />
      </div>

      <router-link
        v-else
        :to="{ path: `/workflow/${workflow.projectId}/${workflow.id}` }"
        class="d-flex"
      >
        <img
          width="228"
          height="92"
          src="@/assets/workflow-placeholder.svg"
          alt="workflow placeholder"
        />
      </router-link>

      <div class="mr-3 ml-auto">
        <div class="text-subtitle-2 text-right">
          {{ eventsNum }} Event{{ eventsNum === 1 ? "" : "s" }}
        </div>
        <div class="text-subtitle-2 text-right">
          {{ cardsNum }} Card{{ cardsNum === 1 ? "" : "s" }}
        </div>
        <div v-if="workflow.starred" class="text-sm-right">
          <v-tooltip location="top">
            <template v-slot:activator="{ on, props }">
              <v-icon v-bind="props" v-on="{ ...on }" size="small" @click="toggleStarred()">mdi-star</v-icon>
            </template>
            <span>Starred workflow. Click to remove star.</span>
          </v-tooltip>
        </div>
      </div>
      <v-menu v-if="!disabled && !hideActions">
        <template #activator="{ on, props }">
          <v-btn
            v-bind="props"
            variant="plain"
            size="x-small"
            class="workflow-card-more-btn px-0"
            v-on="{ ...on }"
          >
            <v-icon style="font-size: 24px;">mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <v-list density="compact">
          <v-list-item
            @click="$emit('edit', workflow)"
            prepend-icon="mdi-pencil"
            title="Edit"
          />

          <v-list-item
            @click="deleteWorkflow(workflow.id, $route.params.projectId)"
            prepend-icon="mdi-delete-outline"
            title="Delete"
          />

          <v-list-item
            @click="toggleStarred()"
            prepend-icon="mdi-star"
            :title="workflow.starred ? 'Remove from starred' : 'Add to starred'"
          />

          <v-list-group
            @click.stop
          >
            <template #activator="{ props }">
              <v-list-item
                v-bind="props"
                prepend-icon="mdi-content-copy"
                title="Create a copy in project:"
              />
            </template>

            <v-list-item
              v-for="(projectMembership, index) in projectMemberships"
              :key="index"
              :disabled="processingRequest === workflow.id"
              @click="callCloneWorkflow(projectMembership.project.id)"
              prepend-icon="mdi-folder-outline"
            >
              <v-list-item-title>
                {{ projectMembership.project?.name }} {{ projectMembership.project?.id == $route.params.projectId ? '(this project)' : '' }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-menu>
    </v-card>
  </div>
</template>

<script>
import workflowApi, { deleteWorkflow, cloneWorkflow } from '@/api/workflowApi'
import { mapState } from 'vuex'

export default {
  props: {
    workflow: Object,
    disabled: Boolean,
    hideActions: Boolean
  },

  data () {
    return {
      processingRequest: null
    }
  },

  methods: {
    toggleStarred () {
      const starred = !this.workflow.starred
      workflowApi.updateWorkflowNoHistoryMutation(
        this.workflow.projectId,
        {
          id: this.workflow.id,
          expectedVersion: this.workflow.version,
          starred
        }
      )
    },
    async callCloneWorkflow (targetProjectId) {
      this.processingRequest = this.workflow.id
      await this.cloneWorkflow(targetProjectId, this.workflow.id)
      this.processingRequest = null
    },
    deleteWorkflow,
    cloneWorkflow
  },

  computed: {
    ...mapState({
      projectMemberships: state => state.projectMemberships.projectMemberships
    }),

    eventsNum () {
      return this.workflow.eventsJson ? this.workflow.eventsJson.length : 0
    },

    cardsNum () {
      if (!Array.isArray(this.workflow.eventsJson)) {
        return 0
      }

      return this.workflow.eventsJson.reduce((result, event) => {
        if (!Array.isArray(event.requirementsJson)) {
          return result
        }

        return result + event.requirementsJson.length
      }, 0)
    }
  }
}
</script>

<style scoped>
.workflow-card {
  position: relative;
}

.card-title a {
  text-decoration: none;
  color: currentColor;
}

.workflow-name {
  font-weight: 500;
  font-size: 14px;
}

.project-project {
  font-weight: 500;
  font-size: 12px;
  color: #4F5359;
}

.workflow-card-more-btn {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
