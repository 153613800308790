<template>
  <v-dialog v-model="open" content-class="dialog-top-right">
    <template v-slot:activator="{ props }">
      <v-icon v-bind="props" size="small" class="ml-2">mdi-help-circle-outline</v-icon>
    </template>

    <v-card class="help-ai justify-center">
      <v-toolbar density="compact" dark color="primary">
        <v-toolbar-title><v-icon>mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>How to use Event Matrix</b></v-toolbar-title>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <h3>Event Matrix</h3>
        The Event Matrix provides an overview of the event
        stories. For each data field, check marks show in
        which stories it's used. It helps you identify data
        fields that might be missing or are misspelled.
        <br />
        The table groups data fields into 7 columns: <b>Who, What, When, Where, Why, How, How many</b>.
        <br /><br />
        <v-icon size="small">mdi-check</v-icon>&nbsp;A checkmark indicates that the field is being used in the corresponding event story.
        <br /><br />
        <v-icon size="small">mdi-star</v-icon>&nbsp;A star indicates that an associated entity can be created in connection with the corresponding event.
        <br /><br />
        Click on an event in the diagram or in the first column of the Event Matrix to create/update/delete event stories.
      </v-card-text><br /><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  }
}
</script>

<style>
.v-dialog .help-ai {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 30%;
  overflow-y: scroll;
}
</style>
