import gql from 'graphql-tag'

export default gql`
  subscription OnCreateRequirementAttachment($workflowId: String) {
    onCreateRequirementAttachment(workflowId: $workflowId) {
      id
      type
      url
      dateCreated
      name
      mimeType
      requirementId
      workflowId
    }
  }
`
