const snackbarPlugin = {
  install: (app, { store }) => {
    if (!store) {
      throw new Error('Please provide vuex store.')
    }

    app.config.globalProperties.$snackbar = {
      showMessage: function (options) {
        store.commit(
          'snackbar/showMessage',
          options,
          { root: true }
        )
      }
    }
  }
}
export default snackbarPlugin
