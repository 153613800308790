<template>
  <v-card class="pa-4">
    <h3 class="text-h5 font-weight-bold pb-4">
      Not found.
    </h3>
    <template v-if="currentUser">
      <span class="light--text">The page may not exist or you may not have access with the current logged in user: <strong>{{ currentUser?.email }}</strong></span>
      <br />
      <br />
      <router-link :to="{ path: '/dashboard' }">
        <span class="light--text">Go to dashboard</span>
      </router-link> or
      <a @click="logout()">
        <span class="text-decoration-underline light--text">Logout</span>
      </a>
      to change user
    </template>
    <template v-else>
      <span class="light--text">The page may not exist or you may not have access to it.</span>
      <br />
      <br />
      <router-link :to="{ path: '/login' }">
        <span class="text-decoration-underline light--text">Go to Login</span>
      </router-link>
    </template>
  </v-card>
</template>

<script>
import { authHelpers } from '@/api/authHelpers'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      currentUser: state => state.user.user
    })
  },
  methods: {
    logout () {
      authHelpers.logoutAndClearStore()
      this.$nextTick(function () {
        this.$router.push({ path: '/login' })
      })
    }
  }
}
</script>

<style scoped>
</style>
