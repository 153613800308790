export default `
  mutation CreateBCObject($input: CreateBCObjectInput!) {
    createBCObject(input: $input) {
      id
      boundedContextId
      filepath
      prompt
      contents
    }
  }
`
