<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 13.4954C0.723858 13.4954 0.5 13.7192 0.5 13.9954C0.5 14.2715 0.723858 14.4954 1 14.4954V13.4954ZM15 3.93444L15.3232 4.31598L15.7401 3.96283L15.3514 3.57878L15 3.93444ZM12.3817 0.644341C12.1853 0.450249 11.8687 0.45214 11.6746 0.648564C11.4806 0.844989 11.4824 1.16157 11.6789 1.35566L12.3817 0.644341ZM11.7072 6.06812C11.4964 6.2466 11.4703 6.5621 11.6488 6.77282C11.8272 6.98354 12.1427 7.00968 12.3535 6.83121L11.7072 6.06812ZM1 14.4954C2.88949 14.4954 4.30007 14.522 5.31833 14.4482C6.31887 14.3757 7.10751 14.2012 7.62106 13.6575C8.13151 13.1171 8.26097 12.3253 8.30071 11.3632C8.34095 10.3891 8.28788 9.03699 8.28788 7.28807H7.28788C7.28788 9.1024 7.34087 10.3704 7.30156 11.3219C7.26176 12.2856 7.12607 12.7252 6.89409 12.9708C6.66522 13.2131 6.23416 13.3792 5.24606 13.4508C4.27569 13.5211 2.9287 13.4954 1 13.4954V14.4954ZM8.28788 7.28807C8.28788 6.30024 8.44286 5.68439 8.68456 5.29095C8.91295 4.91916 9.25331 4.69045 9.77245 4.55238C10.3117 4.40897 11.0082 4.37331 11.9005 4.38015C12.7453 4.38663 13.8558 4.43444 15 4.43444V3.43444C13.8109 3.43444 12.8532 3.38742 11.9082 3.38018C11.0108 3.3733 10.1959 3.40501 9.51543 3.58598C8.81487 3.77229 8.2272 4.12498 7.83249 4.76752C7.45108 5.3884 7.28788 6.21981 7.28788 7.28807H8.28788ZM15.3514 3.57878L12.3817 0.644341L11.6789 1.35566L14.6486 4.29009L15.3514 3.57878ZM14.6768 3.55289L11.7072 6.06812L12.3535 6.83121L15.3232 4.31598L14.6768 3.55289Z" />
  </svg>
</template>

<script>
export default {
  name: 'connection-icon'
}
</script>

<style scoped>
svg {
  height: 16px;
  width: 15px;
}

path {
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0.5px !important;
}
</style>
