<template>
  <v-container fluid class="ma-0 pa-0">
    <v-layout v-if="Boolean(replies.length)">
      <v-col class="pa-0" @click="$emit('expand-change', !expand)" style="cursor: pointer; font-size: 12px;">
        <v-icon size="small" :class="{ expanded: expand, 'expand-icon': true }"
          >mdi-menu-down</v-icon
        >
        {{ expand ? "Hide " : "Show " }}
        {{ replies.length }}
        {{ replies.length === 1 ? " reply" : " replies" }}
      </v-col>
    </v-layout>

    <v-expand-transition>
      <v-container v-show="expand" fluid class="ma-0 pa-0">
        <requirement-reply
          v-for="reply in sortedReplies"
          :key="reply.id"
          :requirement-id="requirementId"
          :reply-form-visible="replyFormVisible"
          :reply="reply"
          :with-dates="withDates"
          :hideAvatar="hideAvatar"
          @hide-reply-form="$emit('hide-reply-form')"
        ></requirement-reply>

        <v-layout class="mt-4" v-if="replyFormVisible">
          <v-col class="ml-4 pa-0">
            <bpmn-requirement-textarea-form
              @submit="submitReply"
              @cancel="$emit('hide-reply-form')"
              :showAi="showAi"
            >
            </bpmn-requirement-textarea-form>
          </v-col>
        </v-layout>
      </v-container>
    </v-expand-transition>
  </v-container>
</template>

<script>
import RequirementReply from '@/components/RequirementReply'
import BpmnRequirementTextareaForm from '@/components/BpmnRequirementTextareaForm'
import { mapState } from 'vuex'
import store from '../store'

export default {
  components: {
    RequirementReply,
    BpmnRequirementTextareaForm
  },

  props: ['requirementId', 'expand', 'replyFormVisible', 'replies', 'withDates', 'hideAvatar', 'showAi'],

  computed: {
    ...mapState({
      currentUser: state => state.user.user
    }),
    sortedReplies () {
      const sortedArray = [...this.replies]
      if (sortedArray.length > 1) {
        sortedArray.sort(function (a, b) {
          if (a.dateCreated < b.dateCreated) {
            return -1
          }
          if (a.dateCreated > b.dateCreated) {
            return 1
          }
          return 0
        })
      }
      return sortedArray
    },
    currentUserFullname () {
      if (!this.currentUser) {
        return ''
      }

      const { firstname, lastname } = this.currentUser

      return `${firstname} ${lastname}`
    }
  },

  methods: {
    async submitReply (payload) {
      const workflowId = this.$route.params.id
      const requirementId = this.requirementId
      await store.dispatch('workflow/addCardReply', {
        workflowId,
        requirementId,
        input: {
          text: payload.text,
          generatedByAi: false
        }
      })
      this.$emit('hide-reply-form')
      if (payload.generatedByAi) {
        this.$emit('ask-for-reply', { text: payload.text, requirementId, workflowId })
      }
    }
  }
}
</script>

<style scoped>
.expand-icon {
  transition: transform 0.3s ease-in-out;
}

.expand-icon.expanded {
  transform: rotate(180deg);
}
</style>
