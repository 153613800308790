import store from '@/store'
import { apolloClient } from '@/vue-apollo'
import GetWorkflowSummary from '@/graphql/queries/GetWorkflowSummary'

export default class RecentWorkflowsApi {
  static MAX_LENGTH = 6

  static add (workflow) {
    if (!workflow?.id) return

    this.updateList(store.state.userId, (recentWorkflows) => {
      const addRecent = {
        id: workflow.id,
        projectId: workflow.projectId,
        name: workflow.name,
        eventsJson: (workflow.eventsJson || []).map(event => {
          return {
            id: event.id,
            requirementsJson: (event.requirementsJson || []).map(card => { return { id: card.id } })
          }
        }),
        lastOpened: new Date().getTime()
      }
      recentWorkflows = recentWorkflows.filter(it => it.id !== addRecent.id)
      recentWorkflows.unshift(addRecent)
      return recentWorkflows
    })
  }

  static delete (workflowId) {
    if (store.state.userId) {
      this.updateList(store.state.userId, (recentWorkflows) => {
        return recentWorkflows.filter(it => it.id !== workflowId)
      })
    }

    this.updateList(null, (recentWorkflows) => {
      return recentWorkflows.filter(it => it.id !== workflowId)
    })
  }

  static deleteProject (projectId) {
    if (store.state.userId) {
      this.updateList(store.state.userId, (recentWorkflows) => {
        return recentWorkflows.filter(it => it.projectId !== projectId)
      })
    }

    this.updateList(null, (recentWorkflows) => {
      return recentWorkflows.filter(it => it.projectId !== projectId)
    })
  }

  static getList () {
    const idToWorkflow = {}
    const str = localStorage.getItem(this.getHashKey() || '') || '[]'
    const recentUnauthorized = JSON.parse(str || '[]')
    recentUnauthorized.forEach(it => { idToWorkflow[it.id] = it })

    if (store.state.userId) {
      const str = localStorage.getItem(this.getHashKey(store.state.userId) || '') || '[]'
      try {
        const userRecent = JSON.parse(str)
        userRecent.forEach(it => {
          const prev = idToWorkflow[it.id]
          if (prev) {
            idToWorkflow[it.id] = prev.lastOpened > it.lastOpened ? prev : it
          } else {
            idToWorkflow[it.id] = it
          }
        })
      } catch (e) {
        console.error(e)
      }
    }

    const recentWorkflows = Object.values(idToWorkflow)
    // TODO: sorting isn't correct if unauthorized and authorized user visited the same workflow!
    recentWorkflows.sort((a, b) => b.lastOpened - a.lastOpened)

    recentWorkflows.length = Math.min(this.MAX_LENGTH, recentWorkflows.length)
    return recentWorkflows
  }

  static updateList (userId, updateFn) {
    try {
      const hashKey = this.getHashKey(userId)
      let recentWorkflows = JSON.parse(localStorage.getItem(hashKey) || '[]')
      recentWorkflows = updateFn(recentWorkflows)
      recentWorkflows.length = Math.min(this.MAX_LENGTH, recentWorkflows.length)
      localStorage.setItem(hashKey, JSON.stringify(recentWorkflows))
    } catch (e) {
      console.error(e)
    }
  }

  static getHashKey (userId = null) {
    return 'recentWorkflows' + (userId ? ('-' + userId) : '')
  }

  static async getWorkflowSummary (recentWorkflow, projectName) {
    try {
      const response = await apolloClient.query({
        query: GetWorkflowSummary,
        variables: {
          workflowId: recentWorkflow.id,
          projectId: recentWorkflow.projectId
        }
      })

      const workflow = response.data.getWorkflow
      if (!workflow) throw Error('Workflow not found')
      return {
        id: workflow.id,
        name: workflow.name,
        projectId: workflow.projectId,
        projectName: projectName || 'Public',
        eventsJson: workflow.eventsJson
      }
    } catch (e) {
      this.delete(recentWorkflow.id)
    }
    return null
  }
}
