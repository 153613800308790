<template>
  <v-menu
    location="bottom"
  >
    <template v-slot:activator="{ props }">
      <v-btn
        icon
        v-bind="props"
        class="current-user-avatar pa-0"
        size="small"
        color="primary"
      >
        {{initials}}
      </v-btn>
    </template>

    <v-card>
      <v-list density="compact">
        <v-list-item :to="{ path: '/account' }" prepend-icon="mdi-account">
            <v-list-item-title class="text-uppercase">My profile</v-list-item-title>
        </v-list-item>

        <v-list-item @click="logout" prepend-icon="mdi-logout">
            <v-list-item-title class="text-uppercase">Log out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { authHelpers } from '@/api/authHelpers'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      currentUser: state => state.user.user
    }),
    initials () {
      if (!this.currentUser) return ''

      const { firstname, lastname } = this.currentUser

      return `${firstname.charAt(0)}${lastname.charAt(0)}`.toUpperCase()
    }
  },

  methods: {
    logout () {
      authHelpers.logoutAndClearStore()
      this.$nextTick(function () {
        this.$router.push({ path: '/login' })
      })
    }
  }
}
</script>
