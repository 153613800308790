<template>
  <v-dialog
    v-model="open"
    max-width="500px"
    :scrollable="true"
  >
    <template #activator="{ props }">
      <slot :props="props" :attachments="item.attachments"></slot>
    </template>

    <v-card flat>
      <v-row class="justify-space-around">
        <v-col>
          <v-card-title>
            <span class="text-h5">Attachments</span>
          </v-card-title>
        </v-col>

        <v-col class="flex-shrink-1 flex-grow-0">
          <v-btn
            variant="text"
            icon
            color="grey"
            @click="open = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-card-text v-if="item.attachments && item.attachments.length">
        <requirement-attachment-card
          v-for="attachment in item.attachments"
          :key="attachment.id"
          :attachmentId="attachment.id"
          :type="attachment.type"
          :title="attachment.name || attachment.url"
          :date="attachment.dateCreated"
          :url="attachment.url"
          :mimeType="attachment.mimeType"
          :item="item"
        ></requirement-attachment-card>
      </v-card-text>

      <v-card-actions class="px-6">
        <requirement-new-attachment-menu :item="item">
          <template #default="{ props }">
            <v-btn color="blue-darken-1" variant="text" v-bind="props">
              <v-icon start>mdi-attachment</v-icon>
              Add attachment
            </v-btn>
          </template>
        </requirement-new-attachment-menu>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RequirementAttachmentCard from '@/components/RequirementAttachmentCard'
import RequirementNewAttachmentMenu from '@/components/RequirementNewAttachmentMenu'
import { EventBus } from '@/event-bus/event-bus.js'

export default {
  props: ['item'],

  components: {
    RequirementAttachmentCard,
    RequirementNewAttachmentMenu
  },

  watch: {
    open (val) {
      if (val) {
        EventBus.$emit('pin-dialog', this.item)
      }
    }
  },

  data () {
    return {
      open: false
    }
  }
}
</script>
