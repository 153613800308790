<template>
  <v-dialog
    id="workflow-comments-dialog"
    :model-value="open"
    max-width="500px"
    @update:model-value="handleToogleDialog"
    v-on:keydown.stop
  >
    <v-card>
      <v-toolbar dense flat color="white">
        <v-toolbar-title>{{ formTitleComment }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon color="grey" @click="closeDialog">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form ref="formComments" v-model="validComments">
          <v-textarea
            class="ml-2 mr-2"
            counter="280"
            v-model="message"
            :label="vote == 2 ? 'Comment (required for thumbs down)' :  'Comment (optional)'"
            :rules="vote == 2 ? [rules.required, rules.max280chars] : [rules.max280chars]"
          ></v-textarea>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="text" @click="closeDialog">Cancel</v-btn>
        <v-btn color="primary" @click="updateUserStatusWorkflow()">
          {{ vote == 2 ? 'Disapprove workflow' : 'Approve workflow' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'

export default {
  props: {
    withVote: Number,
    open: Boolean
  },

  data () {
    return {
      vote: null,
      message: '',
      validComments: true,
      rules: {
        required: value => !!value || 'Required.',
        max280chars: value => (!value || (value && value.length <= 280)) || 'Max 280 characters'
      }
    }
  },

  computed: {
    ...mapState({
      workflow: state => state.workflow.workflow
    }),
    userStatusWorkflow () {
      let res = null
      if (this.workflow.userStatusesWorkflow) {
        const index = this.workflow.userStatusesWorkflow.findIndex((us) => us.userId === store.state.userId)
        res = this.workflow.userStatusesWorkflow[index]
      }
      return res
    },

    formTitleComment () {
      return this.userStatusWorkflow
        ? 'Edit comment'
        : 'Add comment'
    }
  },

  watch: {
    open (newVal) {
      if (open) {
        this.message = (
          this.userStatusWorkflow &&
          this.userStatusWorkflow.message
        ) || ''

        if (this.withVote) {
          this.onlyUpdateComment = false
          this.vote = this.withVote
        } else {
          this.onlyUpdateComment = true
          this.vote = (
            this.userStatusWorkflow &&
            this.userStatusWorkflow.vote
          ) || null
        }
      }
    }
  },

  methods: {
    closeDialog () {
      this.$refs.formComments.reset()
      this.$emit('close')
      this.vote = null
      this.message = ''
      this.validComments = true
    },

    async updateUserStatusWorkflow () {
      const { valid } = await this.$refs.formComments.validate()
      if (valid) {
        await store.dispatch('workflow/updateUserStatusWorkflow', {
          workflowId: this.$route.params.id,
          vote: this.vote || undefined,
          message: this.message || undefined,
          projectId: this.$route.params.projectId
        })
        this.$emit('close')
      }
    },

    handleToogleDialog (val) {
      if (!val) {
        this.$emit('close')
      }
    }
  }
}
</script>
