import midString from '@/utils/mid-string'

export default {
  newSortkeyLastInList (workflow) {
    let lastRequirementSortkey = ''
    const lastCard = this.getLastCardOnSortKey(workflow)
    if (lastCard) {
      lastRequirementSortkey = lastCard.sortkey
    }
    const sortkey = this.generateNewSortKey(lastRequirementSortkey)
    return sortkey
  },

  newSortkeyNextInList (workflow, sortkey) {
    const cards = this.getCardsFromSortkey(workflow, sortkey, 2)
    const afterKey = cards[0]?.sortkey
    const beforeKey = cards[1]?.sortkey
    const newSortkey = this.generateNewSortKey(afterKey, beforeKey)
    return newSortkey
  },

  getLastCardOnSortKey (workflow) {
    let lastCard
    const sortedCards = getSortedRequirements(workflow)
    if (sortedCards?.length > 0) {
      lastCard = sortedCards[sortedCards.length - 1]
    }
    return lastCard
  },

  getCardsFromSortkey (workflow, sortkey, numberOfCards) {
    let counter = 0
    const res = []
    const sortedRequirements = getSortedRequirements(workflow)
    if (sortedRequirements?.length > 0) {
      const index = sortedRequirements.findIndex(requirement => requirement.sortkey === sortkey)
      if (index >= 0) {
        for (let i = index; i < sortedRequirements.length; i++) {
          res.push(sortedRequirements[i])
          counter++
          if (counter === numberOfCards) {
            break
          }
        }
      }
    }
    return res
  },

  generateNewSortKey (after = '', before = '') {
    const newSortkey = midString(after, before)
    return newSortkey
  }
}

function getAllRequirements (workflow) {
  let requirements = []
  if (!workflow.eventsJson) return
  for (const event of workflow.eventsJson) {
    if (event.requirementsJson) {
      requirements = requirements.concat(event.requirementsJson)
    }
  }
  return requirements
}

function getSortedRequirements (workflow) {
  let sortedRequirements = []
  const allRequirements = getAllRequirements(workflow)
  if (allRequirements?.length > 0) {
    sortedRequirements = allRequirements.sort(
      (a, b) => a.sortkey < b.sortkey ? -1 : 1
    )
  }
  return sortedRequirements
}
