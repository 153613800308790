import gql from 'graphql-tag'

export default gql`
  subscription onUpdateTeamMemberLastLogin($projectId: String!)  {
    onUpdateTeamMemberLastLogin (projectId: $projectId) {
      id
      projectId
      lastLogin
    }
  }
`
