<template>
  <v-card width="100%">
    <v-card-text class="ai-description-container">
      <v-menu content-class="example-options-content" >
        <template #activator="{ props }">
          <v-textarea
            v-bind="props"
            class="no-border"
            v-model="textModel"
            @keyup="changeDescription"
            variant="plain"
            rows="3"
            :label="label"
            @update:model-value="showExamples = false"
            :autofocus="true"
            :rules="rules"
            ref="textarea"
            :persistent-hint="true"
            hint="Continue the sentense 'Create a workflow containing ...'"
          ></v-textarea>
        </template>

        <v-list>
          <v-list-item v-for="example in examples" :key="example" @click="populateTextarea(example)">
            <v-list-item-title>{{ example }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-text>
  </v-card>
</template>

<script>
import { chatGPT } from '@/api/chatGPT/index'

export default {
  name: 'ExampleTextarea',
  props: {
    label: String,
    text: String
  },
  computed: {
    textModel: {
      get () { return this.textModelData },
      set (value) { this.textModelData = value },
      cache: false
    },
    examples () {
      return chatGPT.getExamples()
    }
  },
  data () {
    return {
      textModelData: this.text,
      showExamples: false,
      rules: [v => !!v || 'Required.']
    }
  },
  methods: {
    populateTextarea (example) {
      this.$emit('change-description', { description: example, skipDebounce: true })
      this.textModel = example
      this.showExamples = false
    },

    changeDescription (value) {
      this.$emit('change-description', { description: this.textModel })
    }
  },
  mounted () {
    this.textModel = this.text
  }
}
</script>
<style>
.no-border .v-input__control .v-field .v-field__outline .v-input__outline::before {
  /* Your styles here */
  border-style: none;
  /* Other styles */
}

.no-border .v-input__control .v-field .v-field__outline .v-input__outline::after {
  /* Your styles here */
  border-style: none;
  /* Other styles */
}

.example-options-content{
  max-height: 250px !important;
}
.no-border >>> .v-field__outline::before {
  border-style: none !important;
}
</style>
