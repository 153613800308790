import gql from 'graphql-tag'
import { WORKFLOW_FIELDS } from '../fragments'

export default gql`
  ${WORKFLOW_FIELDS}
  mutation CloneWorkflow($workflowId: String!, $projectId: String!) {
    cloneWorkflow(workflowId: $workflowId, projectId: $projectId) {
      ...WorkflowFields
    }
  }
`
