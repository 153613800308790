<template>
  <v-card ref="dialog" class="dialogContainer" @keydown.stop flat style="overflow: visible;">
    <v-card v-if="isOpen" :class="'dialog' + (isActive ? ' dialog--active' : '')" elevated>
      <v-toolbar class="dialogToolbar" density="compact" elevation="0" dark color="primary" @mousedown="startMoveDrag">
        <v-icon class="ml-3">mdi-bullseye-arrow</v-icon>
        <v-toolbar-title><b>Workflow Goals</b></v-toolbar-title>
        <v-icon>mdi-drag-vertical</v-icon>
        <v-btn variant="text" icon class="workflowGoals-close-btn" @click.native="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container ref="scrollableContainerRef" class="content px-4 py-0">
        <goal-list
          ref="goalList"
          placeholder="You have not added any goals in this workflow yet."
          :disabled="disabled"
          :goals="workflow.goals"
          @changed="updateGoals"
        >
        </goal-list>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn :disabled="disabled" color="grey" variant="text" @click="userAddGoal"><v-icon>mdi-plus</v-icon>Add Goal</v-btn>
        <v-spacer></v-spacer>
        <v-icon ref="resizeHandle" class="resizeHandle" size="large" @mousedown="startResizeDrag">mdi-resize-bottom-right</v-icon>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import GoalList from '@/components/GoalList.vue'
import workflowApi from '@/api/workflowApi'
import debounce from '@/utils/debounce'

export default {
  name: 'WorkflowGoals',
  props: {
    workflow: Object
  },
  components: {
    GoalList
  },
  data () {
    return {
      isOpen: false,
      isActive: false,
      dragData: {
        offsetX: 0,
        offsetY: 0,
        dragging: false
      },
      dragRegion: {
        xmin: 68,
        ymin: 64,
        xmax: window.innerWidth - 29,
        ymax: window.innerHeight - 60
      },
      onResize: debounce(this.moveIntoView, 500)
    }
  },
  computed: {
    disabled () {
      return this.$store.state.isWorkflowDisabled
    }
  },
  methods: {
    userAddGoal () {
      this.$refs.goalList.userAddGoal()
    },

    async updateGoals (goals, updateNote, isNewGoal) {
      await workflowApi.updateWorkflowMutation(
        this.$route.params.projectId,
        {
          id: this.$route.params.id,
          status: this.status,
          expectedVersion: this.workflow.version,
          goals,
          updateNote
        }
      )

      if (isNewGoal) {
        const container = this.$refs.scrollableContainerRef?.$el
        container.scroll({ top: 9999999999, behavior: 'smooth' })
      }
    },

    startDrag (event, mode) {
      if (mode === 'resizing') {
        const dialogRect = this.$refs.scrollableContainerRef?.$el.getBoundingClientRect()
        this.dragData.offsetX = dialogRect.width - event.clientX
        this.dragData.offsetY = dialogRect.height - event.clientY
        this.dragData.mode = mode
      } else if (mode === 'moving') {
        const dialogRect = this.$refs.dialog.$el.getBoundingClientRect()
        this.dragData.offsetX = event.clientX - dialogRect.x
        this.dragData.offsetY = event.clientY - dialogRect.y
        this.dragData.mode = mode
      } else {
        this.dragData.mode = null
      }

      // performance: remove transition during dragging
      this.$refs.dialog.$el.style.transitionProperty = 'none'
      event.preventDefault()
    },

    startMoveDrag (event) {
      this.startDrag(event, 'moving')
    },

    startResizeDrag (event) {
      this.startDrag(event, 'resizing')
    },

    handleDrag (event) {
      if (this.dragData.mode === 'resizing') {
        let width = event.clientX + this.dragData.offsetX
        let height = event.clientY + this.dragData.offsetY
        if (width < 300) width = 300
        if (height < 300) height = 300
        this.setSize(width, height)
      } else if (this.dragData.mode === 'moving') {
        const x = event.clientX - this.dragData.offsetX
        const y = event.clientY - this.dragData.offsetY
        this.setPosition(x, y)
      }
    },

    stopDrag () {
      if (this.dragData.mode) {
        this.dragData.mode = null
        this.$refs.dialog.$el.style.transitionProperty = 'all'
      }
    },

    moveIntoView () {
      this.dragRegion.xmax = window.innerWidth - 29
      this.dragRegion.ymax = window.innerHeight - 60
      const dialogRect = this.$refs.dialog?.$el.getBoundingClientRect()
      if (dialogRect) {
        this.setPosition(dialogRect.x || 0, dialogRect.y || 0)
      }
    },

    setSize (width, height) {
      const dialogRect = this.$refs.dialog.$el.getBoundingClientRect()
      const container = this.$refs.scrollableContainerRef?.$el

      const min = this.clampToDragRegion(dialogRect.x, dialogRect.y, 0, 100)
      const max = this.clampToDragRegion(dialogRect.x + width, dialogRect.y + height, 0, 100)

      width = max.x - min.x
      height = max.y - min.y
      container.style.width = width + 'px'
      container.style.height = height + 'px'
    },

    setPosition (x, y) {
      const dialog = this.$refs.dialog.$el
      const dialogRect = dialog.getBoundingClientRect()
      const pos = this.clampToDragRegion(x, y, dialogRect.width, dialogRect.height)
      dialog.style.left = pos.x + 'px'
      dialog.style.top = pos.y + 'px'
    },

    clampToDragRegion (x, y, width = 0, height = 0) {
      if (x < this.dragRegion.xmin) x = this.dragRegion.xmin
      if (y < this.dragRegion.ymin) y = this.dragRegion.ymin
      if (x > this.dragRegion.xmax - width) x = this.dragRegion.xmax - width
      if (y > this.dragRegion.ymax - height) y = this.dragRegion.ymax - height
      return { x, y }
    },

    open () {
      if (this.isOpen) {
        this.close()
      } else {
        this.isOpen = true
        this.$nextTick(() => {
          this.isActive = true
          this.moveIntoView()
        })
      }
    },

    close () {
      this.isActive = false
      setTimeout(() => {
        this.isOpen = false
      }, 300)
    }
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
    document.addEventListener('mousemove', this.handleDrag)
    document.addEventListener('mouseup', this.stopDrag)

    this.setPosition(window.innerWidth - 344, 80)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
    document.removeEventListener('mousemove', this.handleDrag)
    document.removeEventListener('mouseup', this.stopDrag)
  }
}
</script>

<style scoped>

.dialogContainer {
  background-color: transparent;
  position: fixed;
  z-index: 5;
}

.dialog {
  /* TODO: replicates v-dialog animation, v-dialog causes issue
  with scrolling and interacting with cards, might be fixed in newer versions. */
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: scale(0.5);
  opacity: 0;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
}

.dialog--active {
  transform: scale(1);
  opacity: 1;
}

.dialogToolbar {
  cursor: move
}

.content {
  overflow: hidden;
  width: 300px;
  height: 338px;
  overflow-y: auto;
}

.resizeHandle {
  cursor: nwse-resize;
}
</style>
