<template>
  <v-dialog
    :model-value="open"
    @update:model-value="handleOpenChange"
    fullscreen
    :scrim="false"
    @keydown.stop
  >
    <v-card flat>
      <v-toolbar
        flat
        dense
        color="white"
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn class="media-download-btn" download icon :href="url">
            <v-icon>mdi-cloud-download</v-icon>
          </v-btn>

          <v-btn class="media-close-btn" icon @click="handleClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="media-container pa-0">
        <requirement-attachment-media-image
          v-if="mediaType === 'image'"
          :title="title"
          :src="url"
        ></requirement-attachment-media-image>

        <requirement-attachment-media-unknown
          v-else
          :url="url"
        ></requirement-attachment-media-unknown>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import RequirementAttachmentMediaImage from '@/components/RequirementAttachmentMediaImage'
import RequirementAttachmentMediaUnknown from '@/components/RequirementAttachmentMediaUnknown'

export default {
  components: {
    RequirementAttachmentMediaImage,
    RequirementAttachmentMediaUnknown
  },
  props: {
    open: Boolean,
    title: String,
    url: String,
    mediaType: String
  },
  methods: {
    handleOpenChange (open) {
      this.$emit('open-change', open)
    },
    handleClose () {
      this.$emit('open-change', false)
    }
  }
}
</script>

<style>
  .media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 48px);
  }
</style>
