import randomUUID from '@/utils/uuid'
import dragFunctions from '../helpers/dragFunctions'
import dataHelpers from '../helpers/dataHelpers'
import positionHelpers from '@/helpers/positionHelpers'

export const workflowApiHelpers = {
  findUpdatedNodes (svgGraph, workflow, svgLanes) {
    const parents = []
    let foundUpdatedNode = false
    const res = {}
    const eventsMap = workflow?.eventsJson?.reduce((map, value) => {
      map[value.id] = value
      return map
    }, {})
    for (const newEventId in svgGraph) {
      const newEvent = svgGraph[newEventId]
      const oldEvent = eventsMap?.[newEventId]
      let updatedFields = {}
      let dirtyFlag = false
      if (!oldEvent) {
        updatedFields = newEvent
        dirtyFlag = true
      } else {
        if (oldEvent.color?.red !== newEvent.color?.red ||
          oldEvent.color?.green !== newEvent.color?.green ||
          oldEvent.color?.blue !== newEvent.color?.blue) {
          updatedFields.color = newEvent.color
          dirtyFlag = true
        }
        if (!equalConditionLabels(oldEvent.conditionLabels, newEvent.conditionLabels)) {
          updatedFields.conditionLabels = newEvent.conditionLabels
          dirtyFlag = true
        }
        if (JSON.stringify(oldEvent.dataModels) !== JSON.stringify(newEvent.dataModels)) {
          updatedFields.dataModels = newEvent.dataModels
          dirtyFlag = true
        }
        if (oldEvent.description !== newEvent.name) {
          updatedFields.name = newEvent.name
          dirtyFlag = true
        }
        if (oldEvent.laneId !== newEvent.laneId) {
          updatedFields.laneId = newEvent.laneId
          dirtyFlag = true
        }
        if (oldEvent.groupId !== newEvent.groupId) {
          updatedFields.groupId = newEvent.groupId
          dirtyFlag = true
        }
        if (oldEvent.offset !== newEvent.offset) {
          updatedFields.offset = newEvent.offset
          dirtyFlag = true
        }
        if (JSON.stringify(oldEvent.subprocess) !== JSON.stringify(newEvent.subprocess)) {
          updatedFields.subprocess = newEvent.subprocess
          dirtyFlag = true
        }
        if (JSON.stringify(oldEvent.parents) !== JSON.stringify(newEvent.parents)) {
          updatedFields.parents = newEvent.parents
          dirtyFlag = true
        }
        if (oldEvent.type !== newEvent.type) {
          updatedFields.type = newEvent.type
          dirtyFlag = true
        }
      }
      if (dirtyFlag) {
        updatedFields.version = newEvent.version
        updatedFields.id = newEvent.id
        updatedFields.index = newEvent.index
        // if we are passing in a laneId, then also pass in index for validation
        if (updatedFields.laneId) {
          updatedFields.laneIndex = svgLanes[updatedFields.laneId].index
        }
        res[newEventId] = updatedFields
        parents.push(...newEvent.parents)
        foundUpdatedNode = true
      }
    }
    if (foundUpdatedNode) {
      // make sure to pass along all parents
      // to ensure database transactional consistency
      // only id and version needed for parents that
      // are not being updated
      for (const parent of parents) {
        if (!res[parent] && parent !== 'start') {
          res[parent] = {
            id: eventsMap[parent].id,
            version: eventsMap[parent].version
          }
        }
      }
      return res
    } else {
      return []
    }
  },

  getIndexesAndEventFromRequirementId (workflow, requirementId) {
    let eventId = null
    let eventIndex = 0
    let requirementIndex = 0
    for (const event of workflow.eventsJson) {
      requirementIndex = 0
      if (event.requirementsJson) {
        for (const requirement of event.requirementsJson) {
          if (requirement.id === requirementId) {
            eventId = event.id
            // requirementInput = Object.assign(requirement, requirementInput, { requirementIndex })
            return { eventId, eventIndex, requirementIndex, requirement }
          }
          requirementIndex++
        }
      }
      eventIndex++
    }
  },

  findDeletedEvent (svgGraph, workflow) {
    const svgGraphKeys = Object.keys(svgGraph)
    if (svgGraphKeys?.length < workflow?.eventsJson?.length) {
      for (const event of workflow.eventsJson) {
        if (!svgGraphKeys.includes(event.id)) {
          const index = workflow.eventsJson.findIndex(ev => ev.id === event.id)
          return {
            deleteIndex: index,
            deleteVersion: workflow.eventsJson[index].version,
            deleteId: workflow.eventsJson[index].id,
            deletedParents: workflow.eventsJson[index].parents
          }
        }
      }
    }
    return {}
  },

  addLane (svgLanes, laneName) {
    const laneId = randomUUID()
    if (!svgLanes) { svgLanes = {} }
    const lanesCount = Object.keys(svgLanes).length || 0
    const lastLane = Object.values(svgLanes).find(lane => lane.index === lanesCount - 1)
    const lastLaneId = lastLane?.id || null
    svgLanes[laneId] = {
      id: laneId,
      x: 0,
      y: svgLanes[lastLaneId] ? svgLanes[lastLaneId].y + svgLanes[lastLaneId].height - 10 : 0, // -10 necessary because the last lane is 10px higher to fit color dots
      height: 150, // 150 instead of 140 necessary because the last lane is 10px higher
      name: laneName || 'Lane ' + (lanesCount + 1),
      laneAbove: lastLaneId,
      emptyLane: false,
      index: lastLaneId ? svgLanes[lastLaneId].index + 1 : 0
    }
    if (lastLaneId) {
      svgLanes[lastLaneId].height = svgLanes[lastLaneId].height - 10 // -10 necessary because the last lane is 10px higher
    }
    return laneId
  },

  hasGapBetween (svgGraph, currentId, childId, gap = 0) {
    if (svgGraph[currentId] &&
      svgGraph[childId] &&
      ((positionHelpers.getSlotXFromId(childId, svgGraph) - positionHelpers.getSlotXFromId(currentId, svgGraph)) > gap)) {
      return true
    } else {
      return false
    }
  },

  isMainParent (svgGraph, parentId, childId) {
    if (svgGraph[childId]?.parents[0] === parentId) {
      return true
    }
    return false
  },

  isParent (svgGraph, parentId, childId) {
    if (svgGraph[childId]?.parents.includes(parentId)) {
      return true
    }
    return false
  },

  // special case when the colliding event has another parent that is a gateway
  eventHasAnotherParentThatIsAGateway (svgGraph, event, parentId) {
    for (const id of event.parents) {
      if (id !== parentId &&
        svgGraph[id].type === 'bpmn:ExclusiveGateway' &&
        svgGraph[event.id].x >= svgGraph[id].x) {
        return true
      }
    }
    return false
  },

  offsetOccupied (svgGraph, offset, parentId, laneId, eventsSharingSameSpace) {
    if (eventsSharingSameSpace?.length > 0) {
      const index = eventsSharingSameSpace.findIndex(event => {
        const testFlag =
          event.offset === offset && // look for colliding offset..
          (
            !svgGraph[event.parents[0]] || // ..that doesn't have a parent (start node)
            (
              !(workflowApiHelpers.isParent(svgGraph, parentId, event.id) && svgGraph[parentId].type === 'bpmn:Task') && // ..that won't move away
              svgGraph[event.parents[0]].y <= svgGraph[parentId].y // ..and the colliding event's parent is higher up
            ) || // or
            workflowApiHelpers.eventHasAnotherParentThatIsAGateway(svgGraph, event, parentId)
          )
        return testFlag
      })
      if (index >= 0) {
        return true
      }
    }
    return false
  },

  // find all connections that are not the main connections
  // basically all connections where the parent is not the
  // first parent in the parents array (not parents[0])
  findExtraConnections (svgGraph) {
    const res = []
    const tmpArr = Object.values(svgGraph || {})
    const eventsWithExtraConnections = tmpArr.filter(event => event.parents && event.parents.length > 1)
    for (const eventWithExtraConnections of eventsWithExtraConnections) {
      for (let [index, extraConnectionParentId] of eventWithExtraConnections.parents.entries()) {
        if (index > 0) {
          if (extraConnectionParentId === 'start') {
            extraConnectionParentId = eventWithExtraConnections.id
          }

          res.push({
            parentId: extraConnectionParentId,
            childId: eventWithExtraConnections.id,
            startSlotX: positionHelpers.getSlotXFromId(extraConnectionParentId, svgGraph) + 1,
            endSlotX: positionHelpers.getSlotXFromId(eventWithExtraConnections.id, svgGraph) - 1,
            slotY: svgGraph[extraConnectionParentId].type === 'bpmn:Task'
              ? positionHelpers.getSlotYFromId(extraConnectionParentId, svgGraph)
              : positionHelpers.getSlotYFromId(eventWithExtraConnections.id, svgGraph)
          })
        }
      }
    }
    return res
  },

  // long connections are main connections (parents[0])
  // that are longer than one slot and reach into a new group
  findLongConnections (svgGraph) {
    const res = []
    // start nodes cannot have long main connections into them
    // only the first nodes in a group can have a long main connection
    const nodes = Object.values(svgGraph).filter(node => node.groupId && !node.parents.includes('start'))
    for (const node of nodes) {
      const childSlotX = positionHelpers.getSlotXFromPoint(node.x)
      const parentSlotX = positionHelpers.getSlotXFromPoint(svgGraph[node.parents[0]].x)
      if (childSlotX - parentSlotX > 1) {
        const parentId = node.parents[0]
        res.push({
          parentId,
          childId: node.id,
          startSlotX: parentSlotX + 1,
          endSlotX: childSlotX - 1,
          slotY: svgGraph[parentId].type === 'bpmn:Task' ? positionHelpers.getSlotYFromPoint(svgGraph[parentId].y) : positionHelpers.getSlotYFromPoint(svgGraph[node.id].y)
        })
      }
    }
    return res
  },

  getPathsOccupyingSlot (slotX, slotY, svgGraph) {
    // extra connections
    const extraConnections = workflowApiHelpers.findExtraConnections(svgGraph)
    // long connections crossing group boundaries
    const longConnections = workflowApiHelpers.findLongConnections(svgGraph)
    const collidingExtraConnections = [...extraConnections, ...longConnections].filter(
      connection => {
        return (connection.slotY === slotY) &&
          (connection.startSlotX <= slotX) &&
          (connection.endSlotX >= slotX)
      }
    )
    return collidingExtraConnections
  },

  isNodeCrossedByPath (node, svgGraph) {
    const slotX = positionHelpers.getSlotXFromPoint(node.x)
    const slotY = positionHelpers.getSlotYFromPoint(node.y)
    let pathsOccupyingSlot = workflowApiHelpers.getPathsOccupyingSlot(slotX, slotY, svgGraph)
    pathsOccupyingSlot = pathsOccupyingSlot.filter(path => path.parentId !== node.parentIds?.[0] || (svgGraph[path.parentId].type === 'bpmn:ExclusiveGateway'))
    return pathsOccupyingSlot.length > 0
  },

  createVerticalSpaceForOffset (offset, eventsSharingSameSpace, parentId, svgGraph, svgLanes) {
    let index
    if ((index = eventsSharingSameSpace?.findIndex(
      (event) => {
        return (event.offset === offset) && (!parentId || !workflowApiHelpers.isParent(svgGraph, parentId, event.id))
      }
    )) >= 0) {
      this.createVerticalSpaceForOffset(offset + 1, eventsSharingSameSpace, parentId)
      svgGraph[eventsSharingSameSpace[index].id].offset++
      svgGraph[eventsSharingSameSpace[index].id].y = positionHelpers.getYPointFromOffset(svgGraph[eventsSharingSameSpace[index].id].offset, eventsSharingSameSpace[index].laneId, svgLanes)
      if (!workflowApiHelpers.isNodeWithinLaneBoundaries(svgGraph[eventsSharingSameSpace[index].id], svgLanes)) {
        svgLanes[svgGraph[eventsSharingSameSpace[index].id].laneId].height = (svgGraph[eventsSharingSameSpace[index].id].offset + 1) * 140
        workflowApiHelpers.updateLane(svgLanes[svgGraph[eventsSharingSameSpace[index].id].laneId], svgGraph, svgLanes)
      }
    }
  },

  isNodeWithinLaneBoundaries (node, svgLanes) {
    if (svgLanes[node.laneId]?.height >= (node.offset + 1) * 140) {
      return true
    } else {
      return false
    }
  },

  // can I remove these?
  updateNode (node, svgGraph) {
    if (node) {
      svgGraph[node.id] = node
    }
  },

  // can I remove these?
  updateNodes (laneId, svgGraph, svgLanes) {
    for (const node in svgGraph) {
      if (svgGraph[node].laneId === laneId) {
        const nodeCopy = Object.assign({}, svgGraph[node], { y: svgLanes[laneId].y + 30 + svgGraph[node].offset * 140 })
        workflowApiHelpers.updateNode(nodeCopy, svgGraph)
      }
    }
  },

  // can I remove these?
  updateLane (lane, svgGraph, svgLanes) {
    if (lane) {
      delete svgLanes[lane.id]
      svgLanes[lane.id] = lane
      if (lane.laneBelow) {
        const updatedLane = Object.assign({}, svgLanes[lane.laneBelow], { y: svgLanes[lane.id].y + svgLanes[lane.id].height })
        this.updateLane(updatedLane, svgGraph, svgLanes)
        this.updateNodes(updatedLane.id, svgGraph, svgLanes)
      }
    }
  },

  replaceChild (node, oldChild, newChild) {
    if (node) {
      if (!node.childIds) { node.childIds = [] }
      // remove new child if it already exists on node to avoid adding it twice
      let index = node.childIds.indexOf(newChild?.id)
      if (index >= 0) {
        node.childIds.splice(index, 1)
      }

      // update node with new child
      index = node.childIds.indexOf(oldChild?.id)
      if (index >= 0) {
        if (newChild && newChild.id !== node.id) {
          const newChildId = newChild.id
          const temp = [...node.childIds] // use temp to fix weird bug
          temp.splice(index, 1, newChildId)
          node.childIds = temp
        } else {
          node.childIds.splice(index, 1)
        }
      } else {
        node.childIds.push(newChild.id)
      }
    }
  },

  // return true if the new path is closer
  // to the child than the existing path
  selectNewPath (newPath, existingPath, svgGraph, child) {
    const newPathParentId = newPath[0].parent
    const newPathParent = svgGraph[newPathParentId]
    const existingPathParentId = existingPath[0].parent
    if (existingPathParentId === 'start') {
      // if the existing path is the start node
      // then this it's always closest to the child
      return false
    }
    const existingPathParent = svgGraph[existingPathParentId]
    const childNode = svgGraph[child.id]
    if (newPathParent?.x < childNode.x && newPathParent.x > existingPathParent.x) {
      return true
    } else {
      return false
    }
  },

  // takes a svgGraph and svgGroups and returns all paths of the svgGraph
  // a path is a list of connections (or edges)
  // the current path ends and a new path begins when a group boundary is crossed
  // a connection is an object with the following properties:
  // - child: the id of the child node
  // - parent: the id of the parent node
  // - x: the x coordinate of the child node
  findPaths (svgGraph, svgGroups, aggregatedPaths, parentChildMap = null, currentNode = 'start', path = null, pathLengths = {}) {
    if (parentChildMap === null) {
      // this is a recursive function, if parentChildMap is not passed in
      // we need to create it, then we can pass it in to avoid recalculating it
      parentChildMap = dataHelpers.createAllParentChildEventMap(svgGraph)
    }
    // if current path is empty begin with an empty array
    const currentPath = path || []
    if (!parentChildMap[currentNode]) {
      // no more children, cuurrent path ends here
      if (currentPath.length > 0) {
        aggregatedPaths.push(currentPath)
      }
    } else {
      // current node has children
      for (const child of parentChildMap[currentNode]) {
        const childGroup = workflowApiHelpers.getGroupFromNode(child, svgGroups, svgGraph)
        let parentGroup
        if (currentNode === 'start') {
          parentGroup = workflowApiHelpers.getGroupFromNode(child, svgGroups, svgGraph)
        } else {
          parentGroup = workflowApiHelpers.getGroupFromNode(svgGraph[currentNode], svgGroups, svgGraph)
        }
        if (parentGroup !== childGroup) {
          // every time we hit a group boundary we break up the path
          // it reduces complexity to work with one group at a time
          if (currentPath.length > 0) {
            // build up the return value by adding one path at a time
            aggregatedPaths.push(currentPath)
          }
          // now we start a new path
          if (!child.groupId) {
            // special case, the child is not the in the start of the group
            // so we know it belongs to another path, so just leave it
            continue
          }
          if (parentGroup.startSlotX > childGroup.startSlotX) {
            // jumping backwards in groups, we don't want to follow this path
            continue
          }
          const newPath = [{ child: child.id, parent: currentNode, x: child.x, groupStartSlotX: childGroup?.startSlotX }]
          let selectNewPath = true
          const existingPathIndex = aggregatedPaths.findIndex(path => path[0]?.child === child.id)
          // if there already is an existing path with the same start node
          if (existingPathIndex >= 0) {
            // check if we should select the new path
            // or keep the existing path
            selectNewPath = this.selectNewPath(newPath, aggregatedPaths[existingPathIndex], svgGraph, child)
            if (selectNewPath) {
              aggregatedPaths.splice(existingPathIndex, 1)
            }
          }
          if (selectNewPath) {
            this.findPaths(svgGraph, svgGroups, aggregatedPaths, parentChildMap, child.id, newPath, pathLengths)
          }
        } else {
          // if we find the child in pathLengths we know we have already
          // calculated the length of the path to this child
          // if the current path is NOT longer than the path we have already
          // calculated we can stop the current path and add it to the aggregatedPaths
          if (pathLengths[child.id]) {
            if (path && path.length <= pathLengths[child.id]) {
              if (currentPath.length > 0) {
                aggregatedPaths.push(currentPath)
                continue
              }
            }
          } else {
            pathLengths[child.id] = path?.length || 0
          }
          // if we are not crossing a group boundary we continue the current path
          const loopbackToPreviousGroup = parentGroup?.startSlotX > childGroup?.startSlotX
          const loopbackToStart = currentPath.length > 1 && (child.parents[0] === 'start')
          const circularPath = currentPath.findIndex(path => path.parent === child.id) >= 0
          const childIsAGroupStartNode = (typeof child?.groupId === 'string' && child?.groupId !== '')
          const loopBackToGroupStart = childIsAGroupStartNode && currentPath.length > 1 && childGroup?.id === parentGroup?.id
          if (!loopbackToStart && !loopbackToPreviousGroup && !circularPath && !loopBackToGroupStart) {
            // continue the current path
            if (parentChildMap[currentNode].length === 1) {
              // if there is only one child then we can just continue the current path
              // then we don't need to create a new copy of the array
              // we just use push, better for performance
              currentPath.push({ child: child.id, parent: currentNode, x: child.x, groupStartSlotX: childGroup?.startSlotX })
              this.findPaths(svgGraph, svgGroups, aggregatedPaths, parentChildMap, child.id, currentPath, pathLengths)
            } else {
              this.findPaths(svgGraph, svgGroups, aggregatedPaths, parentChildMap, child.id, [...currentPath, { child: child.id, parent: currentNode, x: child.x, groupStartSlotX: childGroup?.startSlotX }], pathLengths)
            }
          } else {
            // if we are in a loopback situation or circular path then we end the current path
            if (currentPath.length > 0) {
              aggregatedPaths.push(currentPath)
            }
          }
        }
      }
    }
  },

  // get all paths and sort them,
  // first by group from left to right,
  // and then by length. So we can lay out the
  // longest paths first when rendering the workflow
  getLongestPaths (svgGraph, svgGroups) {
    const aggregatedPaths = []
    workflowApiHelpers.findPaths(svgGraph, svgGroups, aggregatedPaths)
    aggregatedPaths.sort((arr1, arr2) => {
      if (arr2.length === arr1.length && arr2[0].groupStartSlotX === arr1[0].groupStartSlotX) {
        // if the lengths are the same, then we need
        // to sort on something else that is consistent
        // so paths don't change order randomly when they have the same length
        const strArr1 = JSON.stringify(arr1.map((el) => { return el.parent }))
        const strArr2 = JSON.stringify(arr2.map((el) => { return el.parent }))
        if (strArr1 < strArr2) {
          return -1
        } else {
          return 1
        }
      }
      if (arr2[0].groupStartSlotX === arr1[0].groupStartSlotX) {
        return arr2.length - arr1.length
      }
      return arr1[0].groupStartSlotX - arr2[0].groupStartSlotX
    })
    return aggregatedPaths
  },

  getEventXBasedOnParentAndGroup (eventId, parentId, svgGraph, svgGroups) {
    if (svgGraph[eventId].groupId) {
      // if the event has a group, then we use the group
      // for positioning
      if (!svgGroups) {
        throw new Error('svgGroups is required!!!!')
      }
      const group = svgGroups[svgGraph[eventId].groupId]
      return positionHelpers.getXPointFromSlot(group.startSlotX)
    } else if (parentId === 'start') {
      // start node wihtout a group
      // is always at x = 80
      return 80
    } else {
      // all other nodes are positioned based on the parent
      return svgGraph[parentId].x + 160
    }
  },

  // streches out the diagram to the right
  // by caluclating all possible paths and then
  // reaaranging the nodes so the longest paths
  // are used when rendering the diagram
  // parents[0] is the main parent used for rendering
  //
  // remove starting points can not be used when
  // dragging in the diagram, but can be used for
  // all other cases when all events are locked
  // in their positions
  updateEventsWithLongestPath (svgGraph, svgGroups, removeStartingPoints = true) {
    // console.log('svgGraph', JSON.parse(JSON.stringify(svgGraph)))
    // console.log('svgGroups', JSON.parse(JSON.stringify(svgGroups)))
    // console.log('removeStartingPoints', removeStartingPoints)
    const movedNodeIds = new Set()
    const alreadyWalked = {}
    // check this
    if (removeStartingPoints) {
      for (const eventId in svgGraph) {
        if (svgGraph[eventId].parents[0] === 'start') {
          workflowApiHelpers.removeStartingPointIfNotConnected(eventId, svgGraph, svgGroups)
        }
      }
    }
    const longestPaths = workflowApiHelpers.getLongestPaths(svgGraph, svgGroups)
    for (const longestPath of longestPaths) {
      for (const step of longestPath) {
        const currentEvent = svgGraph[step.child]
        if (!alreadyWalked[currentEvent.id]) {
          if (currentEvent.parents[0] !== step.parent) {
            const group = positionHelpers.getGroupFromXCoordinate(currentEvent.x, svgGroups)
            const parentGroup = positionHelpers.getGroupFromXCoordinate(svgGraph[step.parent]?.x, svgGroups)
            // if (event.groupId === null && parentGroup?.startSlotX < group?.startSlotX) {
            if (group?.id !== parentGroup?.id) {
              // update the order of the parents array
              // if the parent is in a group and the group is to the left
              if (parentGroup?.startSlotX < group?.startSlotX) {
                currentEvent.parents.splice(currentEvent.parents.indexOf(step.parent), 1)
                currentEvent.parents.unshift(step.parent)
                movedNodeIds.add(currentEvent.id)
              } else {
                // don't update the order of the parents array
                // because this is a backward connection
                // console.log('DON\'T UPDATE')
              }
            } else {
              // put step.parent at the beginning of the parents array
              currentEvent.parents.splice(currentEvent.parents.indexOf(step.parent), 1)
              currentEvent.parents.unshift(step.parent)
              movedNodeIds.add(currentEvent.id)
            }
          }
          const newEventX = this.getEventXBasedOnParentAndGroup(currentEvent.id, step.parent, svgGraph, svgGroups)
          const oldX = currentEvent.x
          if (oldX !== newEventX) {
            currentEvent.x = newEventX
            if (newEventX > oldX) {
              // when moving an event to the right
              // we sometimes need to move the group as well
              const group = positionHelpers.findGroupStartingAtCoordinate(newEventX, svgGroups)
              if (group && group.startSlotX !== 0 && !currentEvent.groupId) {
                dragFunctions.moveGroupRight(group, svgGroups)
              }
            }
          }
          alreadyWalked[currentEvent.id] = true
        }
      }
    }
    // console.log('svgGraph AFTER', JSON.parse(JSON.stringify(svgGraph)))
    return movedNodeIds
  },

  getGroupFromParents (eventId, svgGraph, svgGroups) {
    // Check for valid inputs
    if (!svgGroups || Object.keys(svgGroups).length === 0) {
      return undefined
    }
    // Check if the event ID is present in the graph
    const event = svgGraph[eventId]
    if (!event) {
      return undefined
    }
    // Return the group if the event has a groupId
    if (event.groupId) {
      return svgGroups[event.groupId]
    }
    // Special case when the first parent is 'start'
    if (event.parents && event.parents[0] === 'start') {
      return positionHelpers.getGroupFromSlotX(0, svgGroups)
    }
    // Recursively check the parent's group
    return workflowApiHelpers.getGroupFromParents(event.parents[0], svgGraph, svgGroups)
  },

  getGroupFromNode (node, svgGroups, svgGraph) {
    // Check for valid inputs
    if (!svgGroups || Object.keys(svgGroups).length === 0) {
      return undefined
    }
    // Directly return the group if node has a groupId and it exists in svgGroups
    if (node.groupId && svgGroups[node.groupId]) {
      return svgGroups[node.groupId]
    }
    // Attempt to get the group based on the x coordinate
    const groupFromX = positionHelpers.getGroupFromXCoordinate(node.x, svgGroups)
    if (groupFromX) {
      return groupFromX
    }
    // Attempt to get the group based on the node's parents in the graph
    const groupFromParents = workflowApiHelpers.getGroupFromParents(node.id, svgGraph, svgGroups)
    if (groupFromParents) {
      return groupFromParents
    }
    // Return undefined if no group was found
    return undefined
  },

  // align all nodes in the svgGraph object
  // if a node belongs to a group, then align with the group
  // otherwise align with its main parent
  alignBranches (svgGraph, svgGroups) {
    const startIds = []
    Object.values(svgGraph).forEach(node => {
      if (node.parents[0] === 'start') {
        startIds.push(node.id)
      }
    })
    const movedNodeIds = new Set()
    const processedNodes = new Set()
    // traverse all nodes in the graph
    // note, we passing in a flag to not keep
    // track of visited nodes, so we need to
    // keep track of visited nodes ourselves
    // because we need to know if a node has been processed
    this.traverseChildNodes(svgGraph, startIds, node => {
      if (processedNodes.has(node.id)) {
        // stop traversal of this branch
        // if the node has already been processed
        return 1
      }
      if (node.parents[0] !== 'start' && !processedNodes.has(node.parents[0])) {
        // stop traversal of this branch
        // if the parent has not been processed yet
        // because then then this node will be processed
        // after the parent has been processed
        return 1
      }
      const nodeX = node.x
      if (node.groupId && svgGroups?.[node.groupId]?.startSlotX > 0) {
        // if the node has a groupId, then always align with the group
        node.x = positionHelpers.getXPointFromSlot(svgGroups[node.groupId].startSlotX)
      } else if (svgGraph[node.parents[0]]) {
        // if the child doesn't have a groupId, then align with its parent
        node.x = svgGraph[node.parents[0]].x + 160
        // if a node is moved right and by doing so it enters a new group, then move the group right
        if (node.x > nodeX) {
          const group = positionHelpers.findGroupStartingAtCoordinate(node.x, svgGroups)
          if (group && group.startSlotX !== 0) {
            dragFunctions.moveGroupRight(group, svgGroups)
          }
        }
      } else if (node.parents[0] === 'start') {
        // if the parent is the start node, then align with the start node
        node.x = 80
      }
      if (node.x !== nodeX) {
        // the child has been moved
        // collect the ids of all moved nodes and return
        movedNodeIds.add(node.id)
      }
      processedNodes.add(node.id)
    }, false)
    return movedNodeIds
  },

  findCollision (svgGraph, node, laneId = null) {
    for (const n in svgGraph) {
      if (svgGraph[n].placeholder !== true &&
        svgGraph[n].x === node.x &&
        svgGraph[n].y === node.y &&
        svgGraph[n].id !== node.id &&
        (laneId === null || node.laneId === svgGraph[n].laneId) &&
        (!workflowApiHelpers.isMainParent(svgGraph, node.id, svgGraph[n].id) && !workflowApiHelpers.isMainParent(svgGraph, svgGraph[n].id, node.id))) {
        return svgGraph[n]
      }
    }
    return null
  },

  moveDownNode (node, svgGraph, svgLanes) {
    node.offset += 1
    node.y += 140
    if (!workflowApiHelpers.isNodeWithinLaneBoundaries(node, svgLanes)) {
      svgLanes[node.laneId].height = (node.offset + 1) * 140
      workflowApiHelpers.updateLane(svgLanes[node.laneId], svgGraph, svgLanes)
    }
  },

  highestAndLowestNode (svgGraph, node1, node2) {
    const node1Parent = svgGraph[node1.parents[0]] || node1
    const node2Parent = svgGraph[node2.parents[0]] || node2
    if (node1Parent.y < node2Parent.y) {
      return { highestNode: node1, lowestNode: node2 }
    }
    if (node1Parent.y > node2Parent.y) {
      return { highestNode: node2, lowestNode: node1 }
    }
    if (node1Parent.y === node2Parent.y) {
      if (node1.y > node1Parent.y) {
        if (node1Parent.x < node2Parent.x) {
          return { highestNode: node2, lowestNode: node1 }
        } else {
          return { highestNode: node1, lowestNode: node2 }
        }
      } else {
        if (node1Parent.x < node2Parent.x) {
          return { highestNode: node1, lowestNode: node2 }
        } else {
          return { highestNode: node2, lowestNode: node1 }
        }
      }
    }
  },

  updateVerticalPositionsIfCollision (svgGraph, svgLanes, movedNodeId, forceMoveOtherShapes = false) {
    const movedNode = svgGraph[movedNodeId]
    if (movedNode) {
      let targetNode
      if ((targetNode = workflowApiHelpers.findCollision(svgGraph, movedNode))) {
        if (forceMoveOtherShapes) {
          workflowApiHelpers.moveDownNode(targetNode, svgGraph, svgLanes)
          this.updateVerticalPositionsIfCollision(svgGraph, svgLanes, targetNode.id)
          this.updateVerticalPositionsIfCollision(svgGraph, svgLanes, movedNode.id)
        } else {
          const { highestNode, lowestNode } = this.highestAndLowestNode(svgGraph, movedNode, targetNode)
          workflowApiHelpers.moveDownNode(lowestNode, svgGraph, svgLanes)
          this.updateVerticalPositionsIfCollision(svgGraph, svgLanes, lowestNode.id)
          this.updateVerticalPositionsIfCollision(svgGraph, svgLanes, highestNode.id)
        }
      }
      if (workflowApiHelpers.isNodeCrossedByPath(movedNode, svgGraph)) {
        workflowApiHelpers.moveDownNode(movedNode, svgGraph, svgLanes)
        const allIds = Object.keys(svgGraph)
        allIds.forEach(id => this.updateVerticalPositionsIfCollision(svgGraph, svgLanes, id))
      }
    }
  },

  // Performs a depth-first traversal on a graph represented by an object.
  // Traversal starts from the nodes in the stackOfIds and uses a callback
  // to control traversal behavior:
  //   - callback(node) returns 0 or undefined: continue traversal.
  //   - callback(node) returns 1: stop traversal of this branch.
  //   - callback(node) returns 2: completely stop traversal.
  traverseChildNodes (svgGraph, stackOfIds, callback, keepTrackOfVisited = true) {
    if (!Array.isArray(stackOfIds)) {
      stackOfIds = [stackOfIds]
    }
    const visited = new Set()

    while (stackOfIds.length > 0) {
      const currentId = stackOfIds.shift()
      const currentNode = svgGraph[currentId]

      if (keepTrackOfVisited) {
        if (visited.has(currentId)) continue
        visited.add(currentId)
      }

      const callbackResult = callback(currentNode)

      if (callbackResult === 2) return // completely stop traversal
      if (callbackResult === 1) continue // stop traversal of this branch, but continue with others

      if (currentNode.childIds) {
        for (const childId of currentNode.childIds) {
          if (!visited.has(childId)) {
            stackOfIds.push(childId)
          }
        }
      }
    }
  },

  findPreferredOffset (shape, svgGraph, svgLanes) {
    const parentId = shape?.parents?.[0]
    let parentNode = parentId && svgGraph[parentId]
    if (!parentNode) {
      // no parent node so the parent is 'start'
      parentNode = {
        offset: 0,
        x: -80,
        laneId: shape.laneId
      }
    }
    let offset = parentNode.offset
    const eventsSharingSameSpace = Object.values(svgGraph).filter(node => (node.x === parentNode.x + 160) && (node.laneId === shape.laneId))

    const slotX = positionHelpers.getSlotXFromPoint(parentNode.x) + 1 // X slot one step right of parent
    if (parentNode.laneId !== shape.laneId) {
      // this can happen when adding to anohter lane with short keys
      // by pressing option - arrow key down on an event on the bottom lane
      offset = 0
    } else {
      // if parent is not a gateway and has a child, then align with child
      if (parentNode.type !== 'bpmn:ExclusiveGateway' && parentNode?.childIds?.length > 0) {
        if (!workflowApiHelpers.hasGapBetween(svgGraph, parentId, parentNode.childIds[0], 1)) {
          if (workflowApiHelpers.offsetOccupied(svgGraph, offset, parentId, shape.laneId, eventsSharingSameSpace)) {
            offset = svgGraph[parentNode.childIds[0]].offset
          }
        }
      }
    }
    // preferred offset found, now update it if needed and make space for it
    while (workflowApiHelpers.offsetOccupied(svgGraph, offset, parentId, shape.laneId, eventsSharingSameSpace) ||
      workflowApiHelpers.isNodeCrossedByPath({ parentIds: [parentId], x: positionHelpers.getXPointFromSlot(slotX), y: positionHelpers.getYPointFromOffset(offset, shape.laneId, svgLanes) }, svgGraph)) {
      offset++
    }

    workflowApiHelpers.createVerticalSpaceForOffset(offset, eventsSharingSameSpace || null, parentId, svgGraph, svgLanes)

    return offset
  },

  insertShapeIntoSvgGraph (shape, svgGraph, svgLanes, svgGroups) {
    const parentNode = svgGraph[shape.parents[0]]
    shape.offset = workflowApiHelpers.findPreferredOffset(shape, svgGraph, svgLanes)
    shape.y = svgLanes[shape.laneId].y + 30 + shape.offset * 140
    shape.x = parentNode ? parentNode.x + 160 : 80
    shape.childIds = parentNode?.type === 'bpmn:ExclusiveGateway' ? undefined : (parentNode ? parentNode.childIds : undefined)
    shape.placeHolder = true
    shape.selectEventText = true
    shape.groupId = null
    shape.outlined = true
    shape.showConditionLabelOnEvent = false
    shape.margin = shape.type === 'bpmn:ExclusiveGateway' ? 33 : 0
    shape.id = shape.id || randomUUID() // if we copy paste a node, then we already have an id at this point
    svgGraph[shape.id] = shape

    // if the new shape is outside the lane boundary, increase the lane
    // height by one unit and trigger an update
    if (!workflowApiHelpers.isNodeWithinLaneBoundaries(shape, svgLanes)) {
      svgLanes[shape.laneId].height = (shape.offset + 1) * 140
      workflowApiHelpers.updateLane(svgLanes[shape.laneId], svgGraph, svgLanes)
    }

    if (!parentNode) {
      // if this it's the first event, we don't need
      // to align other parts of the diagram
      return
    }

    // update parent and child references
    if (parentNode.type !== 'bpmn:ExclusiveGateway') {
      dragFunctions.replaceParent(svgGraph[parentNode?.childIds?.[0]], parentNode, svgGraph[shape.id])
    }
    dragFunctions.recomputeChildIds(svgGraph)

    // align all nodes in the diagram on the x-axis
    let movedNodeIds
    if (parentNode.type === 'bpmn:Task') {
      // make room sideways
      movedNodeIds = workflowApiHelpers.updateEventsWithLongestPath(svgGraph, svgGroups)
      // adjustGroupPosition is done after updateEventsWithLongestPath because
      // the old value of the group divider is needed by updateEventsWithLongestPath
      this.adjustGroupPosition(shape, svgGroups)
      movedNodeIds.add(workflowApiHelpers.alignBranches(svgGraph, svgGroups))
    } else {
      this.adjustGroupPosition(shape, svgGroups)
      movedNodeIds = workflowApiHelpers.alignBranches(svgGraph, svgGroups)
      movedNodeIds.add(shape.id)
    }

    // align all nodes in the diagram on the y-axis
    movedNodeIds.forEach(movedNodeId => { workflowApiHelpers.updateVerticalPositionsIfCollision(svgGraph, svgLanes, movedNodeId) })
  },

  // move the group divider to the right if needed
  adjustGroupPosition (shape, svgGroups) {
    const group = positionHelpers.findGroupStartingAtCoordinate(shape.x, svgGroups)
    if (group && group.startSlotX !== 0) {
      dragFunctions.moveGroupRight(group, svgGroups)
    }
  },

  addNewShapeToSvgGraphAndAlignAllNodes (shape, options, svgLanes, svgGraph, svgGroups) {
    const parentId = shape.parents[0]
    const parentNode = svgGraph[shape.parents[0]]
    if (!shape.laneId) {
      // new lane will be created
      shape.laneId = workflowApiHelpers.addLane(svgLanes, options.laneName)
    }
    const newShape = {
      id: shape.id || undefined,
      parents: [parentId],
      type: shape.type,
      name: shape.description,
      laneId: shape.laneId,
      subprocess: shape.subprocess || undefined,
      color: shape.color || parentNode?.color,
      dataModels: shape.dataModels,
      requirementsJson: shape.requirementsJson,
      conditionLabels: shape.conditionLabels
    }
    workflowApiHelpers.insertShapeIntoSvgGraph(newShape, svgGraph, svgLanes, svgGroups)
    return newShape.id
  },

  getNextDescription (events, suggestedDescription = null) {
    if (!events) {
      events = this.events
    }
    if (!suggestedDescription) {
      suggestedDescription = 'New event'
    }
    let description = suggestedDescription
    let counter = 2
    while (events?.findIndex(wt => wt.description === description) >= 0) {
      description = suggestedDescription + ' ' + counter
      counter++
    }
    return description
  },

  getNextDecisionDescription (events) {
    let description = 'New decision'
    let counter = 2
    while (events.findIndex(wt => wt.description === description) >= 0) {
      description = 'New decision ' + counter
      counter++
    }
    return description
  },

  refreshDiagram (svgGraph, svgLanes, svgGroups) {
    if (svgGraph) {
      // console.log('this.svgGraph BEFORE', JSON.parse(JSON.stringify(svgGraph)))
      workflowApiHelpers.updateEventsWithLongestPath(svgGraph, svgGroups)
      // console.log('this.svgGraph AFTER', JSON.parse(JSON.stringify(svgGraph)))
      const allIds = Object.keys(svgGraph)
      allIds.forEach(id => workflowApiHelpers.updateVerticalPositionsIfCollision(svgGraph, svgLanes, id))
    }
  },

  refreshDiagramPartially (svgGraph, svgLanes, svgGroups, { draggedNode }, selectedElement) {
    const draggedNodeX = draggedNode?.x
    const movedNodeIds = workflowApiHelpers.updateEventsWithLongestPath(svgGraph, svgGroups, false)
    // console.log('movedNodeIds check 1', movedNodeIds)
    // const movedNodeIds2 = workflowApiHelpers.alignBranches(svgGraph, svgGroups)
    // console.log('movedNodeIds check 1', movedNodeIds2)
    movedNodeIds.forEach(movedNodeId => {
      if (movedNodeId !== selectedElement.id) {
        workflowApiHelpers.updateVerticalPositionsIfCollision(svgGraph, svgLanes, movedNodeId)
      }
    })
    if (draggedNodeX) {
      // restore the x coordinate of the dragged node
      draggedNode.x = draggedNodeX
    }
  },

  countNodes (parentIds, parentId = 'start') {
    let counter = 0
    for (const event of parentIds[parentId] || []) {
      if (parentIds[event.id]) {
        counter = counter + 1 + this.countNodes(parentIds, event.id)
      } else {
        counter++
      }
    }
    return counter
  },

  getRemovableSequenceFlows (events, eventId) {
    const res = []
    const eventsWithMoreThanOneParent = events.filter(wt => wt.parents && wt.parents.length > 1)
    const childrenOfSelectedEvent = eventsWithMoreThanOneParent.filter(wt => wt.parents.includes(eventId))
    for (let i = 0; i < childrenOfSelectedEvent.length; i++) {
      const index = events.findIndex(wt => wt.id === childrenOfSelectedEvent[i].id)
      const tmpEvents = JSON.parse(JSON.stringify(events))
      tmpEvents[index].parents = tmpEvents[index].parents.filter(parent => parent !== eventId)
      const tmpParentIdMatrix = dataHelpers.createMainParentChildEventMap(tmpEvents)
      const countNodes = this.countNodes(tmpParentIdMatrix)
      if (countNodes === tmpEvents.length) {
        res.push(childrenOfSelectedEvent[i])
      }
    }
    return res
  },

  deleteEventFromDiagram (eventId, svgGraph, svgGroups) {
    const eventNode = svgGraph[eventId]
    const childNode = svgGraph[eventNode.childIds?.[0]]
    let parentNode = svgGraph[eventNode.parents?.[0]]

    // transfer groupId from event to child if the event has a groupId
    // and the child doesn't have a groupId
    // TODO: get child node from positin instead of from groupId
    if (eventNode.groupId && childNode && !childNode.groupId && eventNode.x < childNode.x) {
      const findOtherStartingPoint = dragFunctions.findOtherStartingPoint(childNode, eventNode.id, svgGraph, true)
      if (!findOtherStartingPoint) {
        childNode.groupId = eventNode.groupId
      }
    }

    // if there is another parent, then fetch it
    // otherwise replaceParent will add 'start' as parent
    if (!parentNode) {
      parentNode = dragFunctions.findOtherStartingPoint(childNode, eventNode.id, svgGraph, true)
      if (parentNode?.id === childNode?.id) {
        // if the parent is the same as the child
        // then it's not a real parent
        parentNode = null
      }
    }

    dragFunctions.replaceParent(childNode, eventNode, parentNode)
    workflowApiHelpers.replaceChild(parentNode, eventNode, childNode)
    if (parentNode && childNode) {
      this.removeGroupFromChildIfSameGroup(parentNode.id, childNode.id, svgGraph, svgGroups)
    }

    const otherParentIds = eventNode.parents.slice(1) // create a copy and remove the first parent (parent[0]) in the copy
    for (const otherParentId of otherParentIds) {
      // remove extra connections to the event about to be deleted
      svgGraph[otherParentId].childIds = svgGraph[otherParentId].childIds.filter(childId => childId !== eventId)
    }
    delete svgGraph[eventId]
  },

  // the function removes 'start' from targetEventId's parents array
  // if the event is not connected through a loop or from another group
  // located to the right of the current event
  // so that we always can strech out diagrams to the right as much as possible
  removeStartingPointIfNotConnected (targetEventId, svgGraph, svgGroups) {
    // console.log('targetEventId', targetEventId)
    // console.log('svgGraph', JSON.parse(JSON.stringify(svgGraph)))
    // console.log('svgGroups', JSON.parse(JSON.stringify(svgGroups)))
    if (svgGraph[targetEventId].parents.findIndex(parentId => parentId === 'start') === -1) {
      return
    }
    if (svgGraph[targetEventId].parents.length <= 1) {
      return
    }
    const parentIds = svgGraph[targetEventId].parents
    let removeStartingPoint
    // do the check for each parent of the target event
    for (const parentId of parentIds.filter(id => id !== 'start')) {
      removeStartingPoint = true
      // Keep starting point only if it's connected via a loop inside the same group
      // or if it's connected back from another group
      const targetGroup = workflowApiHelpers.getGroupFromNode(svgGraph[targetEventId], svgGroups, svgGraph)
      // if (targetGroup) {
      if (dragFunctions.hasAncestorDescendantRelationshipInsideGroup(targetEventId, parentId, targetGroup?.id, svgGraph, svgGroups)) {
        removeStartingPoint = false
      }
      // }
      if (removeStartingPoint && Object.keys(svgGroups)?.length > 0) {
        const targetGroup = workflowApiHelpers.getGroupFromNode(svgGraph[targetEventId], svgGroups, svgGraph)
        const currentGroup = workflowApiHelpers.getGroupFromNode(svgGraph[parentId], svgGroups, svgGraph)
        if (targetGroup?.id !== currentGroup?.id && targetGroup.startSlotX < currentGroup.startSlotX) {
          removeStartingPoint = false
        }
      }
      // if there is a connection from left or same column to the targetEventId
      // then always remove the starting point
      if (svgGraph[parentId].x <= svgGraph[targetEventId].x) {
        removeStartingPoint = true
      }
      if (removeStartingPoint) {
        break
      }
    }
    if (removeStartingPoint) {
      this.removeStartingPointFromNode(svgGraph[targetEventId])
    }
    // console.log('svgGraph AFTER', JSON.parse(JSON.stringify(svgGraph)))
  },

  removeStartingPointFromNode (node) {
    node.parents = node.parents.filter(parent => parent !== 'start')
  },

  // removes the connection from the parent
  // to the child and updates svgGraph
  deleteConnectionFromEvent (parentId, childId, svgGraph, svgGroups) {
    if (!parentId || !childId) {
      return
    }
    const parentEvent = svgGraph[parentId]
    const childEvent = svgGraph[childId]
    const parentIndex = childEvent?.parents.findIndex(parent => parent === parentId)
    // remove parent from child
    childEvent.parents.splice(parentIndex, 1)
    // set or remove start node on child if needed
    dragFunctions.updateStartNodeStatus(childEvent, svgGraph, svgGroups)
    // remove child from parent
    let index = parentEvent.childIds.indexOf(childEvent.id)
    if (index >= 0) {
      index = parentEvent.childIds.splice(index, 1)
    }

    // add group based on slot x
    if (Object.keys(svgGroups).length > 0) {
      const childGroup = positionHelpers.getGroupFromXCoordinate(childEvent.x, svgGroups)
      // let hasParentsInSameGroup = false
      const parentsInTargetGroupLeftSide = dragFunctions.getParentsInTargetGroup(childEvent, childGroup.id, svgGraph, svgGroups, true, false)
      // parentsInTargetGroupLeftSide?.forEach(parentId => {
      //   // next row looks like a bug, because it's passing in parentId as groupId
      //   console.log('###############################')
      //   console.log('parentId', parentId)
      //   if (dragFunctions.hasAncestorDescendantRelationshipInsideGroup(childEvent.id, parentId, childGroup.id, svgGraph, svgGroups)) {
      //     // it's a loopback connection
      //   } else {
      //     // no loopback connection
      //     hasParentsInSameGroup = true
      //   }
      // })
      if (parentsInTargetGroupLeftSide.length > 0 || childGroup.startSlotX === 0) {
        // if the child has parent 0 in the same group as itself then don't add a group
        // or if the group is the first group, then don't add a group
        childEvent.groupId = null
      } else {
        childEvent.groupId = childGroup.id
      }
    }

    for (const eventId in svgGraph) {
      if (svgGraph[eventId].parents[0] === 'start') {
        workflowApiHelpers.removeStartingPointIfNotConnected(eventId, svgGraph, svgGroups)
      }

      // remove group from node if it has a group but
      // now has a parent in the same group that
      // is not a decendant of the node in an
      // unbroken chain inside the group
      // for example when there is a circle of events
      // inside a group and one of the events in the middle
      // is disconnected
      const childNode = svgGraph[eventId]
      if (childNode.groupId) {
        const parentsInTargetGroup = dragFunctions.getParentsInTargetGroup(childNode, childNode.groupId, svgGraph, svgGroups)
        parentsInTargetGroup?.forEach(parentId => {
          if (dragFunctions.hasAncestorDescendantRelationshipInsideGroup(childNode.id, parentId, childNode.groupId, svgGraph, svgGroups)) {
            // don't update the group id
          } else {
            // remove groupId because we have a parent in the same group
            // that is not a child of the node
            childNode.groupId = null
          }
        })
      }
    }
  },

  // adds a connection from the sourceEvent
  // to the targetEvent, updates svgGraph
  addConnectionToEvent (sourceEventId, targetEventId, svgGraph, svgGroups) {
    if (!sourceEventId || !targetEventId) {
      return
    }
    svgGraph[targetEventId].parents.push(sourceEventId)
    if (!svgGraph[sourceEventId].childIds) {
      svgGraph[sourceEventId].childIds = []
    }
    svgGraph[sourceEventId].childIds.push(targetEventId)
    workflowApiHelpers.removeStartingPointIfNotConnected(targetEventId, svgGraph, svgGroups)

    this.removeGroupFromChildIfSameGroup(sourceEventId, targetEventId, svgGraph, svgGroups)
  },

  // if the child and the parents are connected and
  // in the same group, then remove the group from the child
  // so that the child is not displayed in the same slot as the parent
  removeGroupFromChildIfSameGroup (parentId, childId, svgGraph, svgGroups) {
    const parentGroup = positionHelpers.getGroupFromXCoordinate(svgGraph[parentId].x, svgGroups)
    const childGroup = positionHelpers.getGroupFromXCoordinate(svgGraph[childId].x, svgGroups)
    // if (svgGraph[childId].parents[0] === parentId && parentGroup?.id === childGroup?.id) {

    if (parentGroup && parentGroup?.id === childGroup?.id && parentId !== childId) {
      const childIsAlsoParent = dragFunctions.hasAncestorDescendantRelationshipInsideGroup(childId, parentId, parentGroup.id, svgGraph, svgGroups)
      if (!childIsAlsoParent || svgGraph[parentId].x <= svgGraph[childId].x) {
        svgGraph[childId].groupId = null
      }
    }
  },

  deleteGroup (groupId, svgGraph, svgGroups) {
    const groupToDelete = svgGroups[groupId]
    if (!groupToDelete) return

    const { index, width } = groupToDelete
    delete svgGroups[groupId]

    const remainingGroups = Object.values(svgGroups)

    if (index === 0) {
      handleFirstGroupDeletion(groupId, remainingGroups, width, svgGraph, svgGroups)
    } else {
      handleNonFirstGroupDeletion(groupId, index, remainingGroups, width, svgGraph, svgGroups)
    }
    this.updateGroupStartingPosition(svgGraph, svgGroups)
  },

  updateGroupStartingPosition (svgGraph, svgGroups) {
    for (const eventId in svgGraph) {
      const event = svgGraph[eventId]
      const group = positionHelpers.getGroupFromXCoordinate(svgGraph[eventId].x, svgGroups)
      event.groupId = null
      if (!group || group.startSlotX === 0) {
        // all events in the first group should have groupId set to null
        event.groupId = null
      } else {
        const parentsInTargetGroup = dragFunctions.getParentsInTargetGroup(event, group.id, svgGraph, svgGroups, false, false)
        if (parentsInTargetGroup.length === 0) {
          event.groupId = group.id
        } else {
          parentsInTargetGroup?.every(parentId => {
            if (dragFunctions.hasAncestorDescendantRelationshipInsideGroup(event.id, parentId, group.id, svgGraph, svgGroups)) {
              if (event.x < svgGraph[parentId].x) {
                event.groupId = group.id
                return true
              } else {
                return false
              }
            } else {
              event.groupId = null
              return false
            }
          })
        }
      }
    }
  }
}

/* *************** */
/* local functions */
/* *************** */
function handleFirstGroupDeletion (groupId, remainingGroups, width, svgGraph, svgGroups) {
  const secondGroup = remainingGroups.find(group => group.index === 1)
  if (!secondGroup) return

  const secondGroupId = secondGroup.id
  svgGroups[secondGroupId].startSlotX = 0
  svgGroups[secondGroupId].x = 0
  svgGroups[secondGroupId].width =
  remainingGroups.length === 1
    ? -1
    : svgGroups[secondGroupId].width + width

  for (const event of Object.values(svgGraph)) {
    if (event.groupId === secondGroupId || event.groupId === groupId) {
      event.groupId = null
      if (event.parents[0] !== 'start') {
        event.parents.unshift('start')
      }
    }
  }
}

function handleNonFirstGroupDeletion (groupId, index, remainingGroups, width, svgGraph, svgGroups) {
  const prevGroup = remainingGroups.find(group => group.index === (index - 1))
  if (!prevGroup) return

  const prevGroupId = prevGroup.id
  const isLastGroup = index === remainingGroups.length
  svgGroups[prevGroupId].width = isLastGroup ? -1 : svgGroups[prevGroupId].width + width

  for (const event of Object.values(svgGraph)) {
    if (event.groupId === groupId) {
      const parentsInTargetGroup = dragFunctions.getParentsInTargetGroup(event, prevGroupId, svgGraph, svgGroups)
      if (parentsInTargetGroup?.length > 0) {
        event.groupId = null
      } else {
        event.groupId = prevGroupId
      }
    }
  }
}

function equalConditionLabels (oldConditionLabels, newConditionLabels) {
  if ((oldConditionLabels?.length || 0) !== (newConditionLabels?.length || 0)) {
    return false
  }
  for (let i = 0; i < (oldConditionLabels?.length || 0); i++) {
    if (oldConditionLabels[i].name !== newConditionLabels[i].name ||
      oldConditionLabels[i].parentId !== newConditionLabels[i].parentId) {
      return false
    }
  }
  return true
}
