<template>
  <div style="position: relative;" v-if="!$route.params.eventId && parentChildEventMap">
    <v-btn disabled  style="position: absolute; top: -40px !important; right: 0 !important;" color="gray" class="mb-2 elevation-1 add-data-model-button">&nbsp;&nbsp;Select an event to add a data model&nbsp;&nbsp;</v-btn>
    <v-card class="backlog-container">
      <v-layout wrap>
        <v-col cols="12">
          <template v-if="Object.keys(dataModels).length == 0">
            <span xs4 style="align-items: center; display: flex;" class="subheading font-weight-bold">No&nbsp;<span class="bg-yellow-lighten-4 rounded-lg px-1">Event Story</span>&nbsp;or&nbsp;<span class="bg-red-lighten-4 rounded-lg px-1">Fact Table</span>&nbsp;created yet.
              <workflow-help-dialog-beam />
            </span>
            <br/>Select an event and click on <span class="bg-yellow-lighten-4 rounded-lg px-1">Event Story</span> to create a data model.
          </template>
          <span v-else class="subheading font-weight-bold">Event Matrix</span>
          <workflow-help-dialog-event-matrix />
          (Select an event to edit its data model)
        </v-col>
      </v-layout>
      <v-data-table v-if="sortedEvents && sortedEvents.length > 0" hide-default-footer class="v-datatable v-table theme--light elevation-1" :items="[{ test: '1' }]" disable-pagination>
        <template v-slot:bottom></template>
        <!-- headers -->
        <template v-slot:headers>
          <tr style="height: 100px;">
            <th style="min-width: 200px;"></th>
            <th class="text-center field-tags border-0" v-for="(fieldName, index) in getHeaders('[Who]')" :key="'who' + index">{{ fieldName.substring(0, 16) }}</th>
            <th class="text-center field-tags border-0" v-for="(fieldName, index) in getHeaders('[What]')" :key="'what' + index">{{ fieldName.substring(0, 16) }}</th>
            <th class="text-center field-tags border-0" v-for="(fieldName, index) in getHeaders('[Where]')" :key="'where' + index">{{ fieldName.substring(0, 16) }}</th>
            <th class="text-center field-tags border-0" v-for="(fieldName, index) in getHeaders('[How many]')" :key="'howmany' + index">{{ fieldName.substring(0, 16) }}</th>
            <th class="text-center field-tags border-0" v-for="(fieldName, index) in getHeaders('[Why]')" :key="'why' + index">{{ fieldName.substring(0, 16) }}</th>
            <th class="text-center field-tags border-0" v-for="(fieldName, index) in getHeaders('[How]')" :key="'how' + index">{{ fieldName.substring(0, 16) }}</th>
            <th class="text-center field-tags border-0" v-for="(fieldName, index) in getHeaders('[When]')" :key="'when' + index">{{ fieldName.substring(0, 16) }}</th>
          </tr>
          <tr>
            <th></th>
            <th class="type-header" :colspan="getHeaders('[Who]').length">Who</th>
            <th class="type-header" :colspan="getHeaders('[What]').length">What</th>
            <th class="type-header" :colspan="getHeaders('[Where]').length">Where</th>
            <th class="type-header" :colspan="getHeaders('[How many]').length">How many</th>
            <th class="type-header" :colspan="getHeaders('[Why]').length">Why</th>
            <th class="type-header" :colspan="getHeaders('[How]').length">How</th>
            <th class="type-header" :colspan="getHeaders('[When]').length">When</th>
          </tr>
        </template>

        <!-- cells -->
        <template v-slot:item>
          <template v-for="(task, taskIndex) in sortedEvents">
            <tr v-for="(dataModel, modelIndex) in task.dataModels && task.dataModels.filter(dataModel => dataModel.type === 'Event Story')" :key="modelIndex + '-' + taskIndex">
              <td style="cursor: pointer; background-color: #eeeeee; font-size: 12px; font-weight: 600; color: rgba(0,0,0,.54); width: 300px;" @click="changeSelectedEvent(task.id)">
                {{ task.name }} by {{ svgLanesData[task.laneId].name }}
              </td>
              <td :class="{ 'table-cell-border': index == 0 }" v-for="(fieldName, index) in getHeaders('[Who]')" :key="'who' + index"><v-icon>{{getIcon(task.id, modelIndex, fieldName)}}</v-icon></td>
              <td :class="{ 'table-cell-border': index == 0 }" v-for="(fieldName, index) in getHeaders('[What]')" :key="'what' + index"><v-icon>{{getIcon(task.id, modelIndex, fieldName)}}</v-icon></td>
              <td :class="{ 'table-cell-border': index == 0 }" v-for="(fieldName, index) in getHeaders('[Where]')" :key="'where' + index"><v-icon>{{getIcon(task.id, modelIndex, fieldName)}}</v-icon></td>
              <td :class="{ 'table-cell-border': index == 0 }" v-for="(fieldName, index) in getHeaders('[How many]')" :key="'howmany' + index"><v-icon>{{getIcon(task.id, modelIndex, fieldName)}}</v-icon></td>
              <td :class="{ 'table-cell-border': index == 0 }" v-for="(fieldName, index) in getHeaders('[Why]')" :key="'why' + index"><v-icon>{{getIcon(task.id, modelIndex, fieldName)}}</v-icon></td>
              <td :class="{ 'table-cell-border': index == 0 }" v-for="(fieldName, index) in getHeaders('[How]')" :key="'how' + index"><v-icon>{{getIcon(task.id, modelIndex, fieldName)}}</v-icon></td>
              <td :class="{ 'table-cell-border': index == 0 }" v-for="(fieldName, index) in getHeaders('[When]')" :key="'when' + index"><v-icon>{{getIcon(task.id, modelIndex, fieldName)}}</v-icon></td>
            </tr>
          </template>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { diagramHelpers } from '@/mixins/diagramHelpers.js'
import WorkflowHelpDialogBeam from '@/components/WorkflowHelpDialogBeam'
import WorkflowHelpDialogEventMatrix from '@/components/WorkflowHelpDialogEventMatrix'
import { mapGetters } from 'vuex'

export default {
  mixins: [diagramHelpers],
  components: {
    WorkflowHelpDialogBeam,
    WorkflowHelpDialogEventMatrix
  },
  props: [
    'parentChildEventMap',
    'events'
  ],
  data () {
    return {
      state: {},
      valid: false,
      dialogReq: false,
      search: '',
      sourceMenu: {},
      menu: {},
      defaultSort: {
        sortBy: 'row',
        totalItems: 10000,
        rowsPerPage: 10000
      },
      select: null,
      types: [
        '[Who]',
        '[What]',
        '[When]',
        '[Where]',
        '[How many]'
      ],
      fieldsHeaders: [
        { text: 'Column name', value: 'column' },
        { text: 'Table name', value: 'table' },
        { text: 'Source system', value: 'system' },
        { text: 'Sample data', value: 'sample' }
      ],
      fields: [
        {
          system: 'sys.0084.OO',
          table: 'OQ_orderinf',
          column: 'orderquantity',
          sample: '1\n10'
        },
        {
          system: 'sys.0084.OO',
          table: 'OQ_orderinf',
          column: 'orderid',
          sample: '10001\n25973'
        },
        {
          system: 'sys.0084.OO',
          table: 'OQ_orderinf',
          column: 'revenue',
          sample: '350 €\n63 50 €'
        },
        {
          system: 'sys.5791.IS',
          table: 'IS_shop',
          column: 'shopnumber',
          sample: '0001\n0022\n2400\n0000'
        },
        {
          system: 'sys.5791.IS',
          table: 'IS_shop',
          column: 'shopname',
          sample: 'www.IKEA.com\nIKEA Kungens Kurva\nIKEA München\nN/A'
        },
        {
          system: 'sys.5791.IS',
          table: 'IS_shop',
          column: 'shopdesc',
          sample: 'Store\nOnline\nN/A'
        }
      ],
      datamodels: {
        '3b74cd33-c51d-4fe9-85b9-dfc7adfaa5c7': {
          name: 'Customer Order',
          cols: [
            {
              name: 'Customer',
              type: '[Who]',
              fieldName: 'custname',
              tableName: 'CC_customer',
              systemName: 'sys.5791.CC',
              exampleData: ['Niclas Persson', 'Traton Group']
            },
            {
              name: 'Seller',
              type: '[Who]',
              fieldName: 'sellerid',
              tableName: 'CC_customer',
              systemName: 'sys.5791.CC',
              exampleData: ['Store 123', 'Store 456']
            },
            {
              name: 'Quantity',
              type: '[How many]',
              fieldName: 'orderquantity',
              tableName: 'OQ_orderinf',
              systemName: 'nsys.0084',
              exampleData: ['1', '10', '135']
            },
            {
              name: 'Id',
              type: '[How]',
              fieldName: 'orderid',
              tableName: 'OQ_orderinf',
              systemName: 'sys.0084.OO',
              exampleData: ['10001', '86431']
            }
          ]
        },
        '26067e5b-04f4-449c-ab0a-b30fe301676a': {
          name: 'Product Shipment',
          cols: [
            {
              name: 'Customer',
              type: '[Who]',
              fieldName: 'custname',
              tableName: 'CC_customer',
              systemName: 'sys.5791.CC',
              exampleData: ['Niclas Persson', 'Traton Group']
            },
            {
              name: 'Quantity',
              type: '[How many]',
              fieldName: 'orderquantity',
              tableName: 'OQ_orderinf',
              systemName: 'nsys.0084',
              exampleData: ['1', '10', '135']
            },
            {
              name: 'Id',
              type: '[How]',
              fieldName: 'shopmentid',
              tableName: 'OQ_shipmentinf',
              systemName: 'sys.0084.OO',
              exampleData: ['20001', '20002']
            }
          ]
        }
      },
      headers: [
        { text: 'Field 1', value: 'col0' },
        { text: 'Field 2', value: 'col1' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      svgGraphData: 'svgGraphData/svgGraphData',
      svgLanesData: 'svgGraphData/svgLanesData'
    }),
    sortedEvents () {
      // create a copy of the events array and sort it
      const events = Object.values(this.svgGraphData)
      return [...events].sort((a, b) => a.x - b.x)
    },
    dataModels () {
      const res = {}
      for (const event of this.sortedEvents) {
        if (event.dataModels && event.dataModels.length > 0) {
          if (!res[event.id]) {
            res[event.id] = []
          }
          res[event.id] = res[event.id].concat(event.dataModels.filter(dataModel => dataModel.type === 'Event Story'))
        }
      }
      return res
    }
  },
  methods: {
    pushChildren (res, task) {
      const children = this.parentChildEventMap[task.id]
      if (children) {
        for (const child of children) {
          res.push(child)
          this.pushChildren(res, child)
        }
      }
      return res
    },
    getIcon (eventId, modelIndex, name) {
      const index = this.dataModels[eventId] && this.dataModels[eventId][modelIndex].dataFields.findIndex(col => col.name === name)
      if (index >= 0) {
        const tags = this.dataModels[eventId][modelIndex].dataFields[index].tags
        if (tags && tags.includes('CREATE')) {
          return 'mdi-star'
        } else {
          return 'mdi-check'
        }
      }
      return ''
    },
    getHeaders (type) {
      const res = new Set()
      for (const eventId in this.dataModels) {
        if (this.dataModels[eventId]) {
          for (const model of this.dataModels[eventId]) {
            for (const field of model.dataFields) {
              if (type === field.type) {
                res.add(field.name)
              }
            }
          }
        }
      }
      if (res.size === 0) {
        res.add('')
      }
      return Array.from(res)
    },
    changeState (index) {
      if (this.state[index] === undefined) {
        this.$set(this.state, index, 1)
      } else {
        if (this.state[index] === 2) {
          this.$set(this.state, index, 0)
        } else {
          this.$set(this.state, index, this.state[index] + 1)
        }
      }
    },
    addColumn () {
      this.headers.push({
        text: 'Field ' + (this.headers.length + 1),
        value: 'col' + (this.headers.length)
      })
    },
    blurHeader (event, index) {
      const newObj = Object.assign(this.headers[index], { text: event.target.innerText })
      this.$set(this.headers, index, newObj)
    },
    blurCell (event, row, column) {
      const newObj = Object.assign(this.rows[row], { ['col' + column]: event.target.innerText })
      this.$set(this.rows, row, newObj)
    },
    clickType (event, row, column) {
      const newObj = Object.assign(this.rows[row], { ['col' + column]: event.target.innerText })
      this.$set(this.rows, row, newObj)
    }
  }
}
</script>

<style scoped>
.v-btn {
    padding: 0 0;
    margin-left: 0;
    margin-right: 0;
    min-width: 40px;
    text-transform: none;
  }
.v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
  box-shadow: none;
}

.field-tags {
  transform: rotate(60deg);
  border-right: 0px solid #d9d9d9;
  font-size: 12px;
  padding: 0px !important;
  width: 15px;
  height: 15px;
  text-decoration: underline;
}

.type-header {
  background-color: #eeeeee;
  padding: 6px 24px 6px 24px;
  width: 120px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.table-cell-border {
  border-left: 1px solid #d9d9d9;
  text-align: center;
}

.add-data-model-button {
  height: 30px;
  border-radius: 10px !important;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
}

</style>
