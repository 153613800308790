<template>
  <v-dialog :scrollable="true" max-width="30%" v-model="open" content-class="dialog-top-right">
    <template v-slot:activator="{ props }">
      <v-icon v-bind="props" class="ml-2">mdi-help-circle-outline</v-icon>
    </template>

    <v-card flat>
      <v-toolbar density="compact" flat dark color="primary">
        <v-toolbar-title><v-icon>mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>Create Event Story</b></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3" style="height: 400px">
        <h3><span class="bg-yellow-lighten-4">Create Event Story</span></h3>
        There are three different ways you can create an Event Story:<br /><br />
        <ul>
          <li><b>Add Event Story with AI</b><br />This uses AI to analyze the workflow and creates a table suggesting an event story, featuring six columns and three rows of example data in each column. Note: Information about your workflow will be shared with OpenAI</li><br />
          <li><b>Table with static example data</b><br />A simple Event Story containing example data will be created.</li><br />
          <li><b>Empty table</b><br />An empty table will be created.</li><br />
        </ul>
        <br />
      </v-card-text><br /><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  }
}
</script>

<style>
.v-dialog .help-ai {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 30%;
  overflow-y: scroll;
}
</style>
