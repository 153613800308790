export const dialog = {
  namespaced: true,
  state: () => ({
    dialog: false,
    message: null,
    callback: null
  }),
  mutations: {
    askOkCancel (state, { message = null, callback = null }) {
      state.dialog = true
      state.message = message
      state.callback = callback
    },
    closeDialog (state) {
      state.dialog = false
      state.message = null
      state.callback = null
    }
  },
  getters: {
    getDialogState: (state) => state.dialog,
    getDialogMessage: (state) => state.message,
    getDialogCallback: (state) => state.callback || (() => {})
  }
}
