<template>
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.75 9H5.25V9.5V11.5H2V0.5H11V11.5H7.75V9.5V9H7.25H5.75Z"
      class="stroke--current-color"
    />
    <path d="M8 7V6H9V7H8Z" class="stroke--current-color" />
    <path d="M4 7V6H5V7H4Z" class="stroke--current-color" />
    <path d="M8 3.5V2.5H9V3.5H8Z" class="stroke--current-color" />
    <path d="M4 3.5V2.5H5V3.5H4Z" class="stroke--current-color" />
  </svg>
</template>

<script>
export default {
  name: 'organisation-icon'
}
</script>

<style scoped>
.stroke--current-color {
  stroke: currentColor;
}

rect {
  fill: none;
}
</style>
