// Import all pages
import AccountLogin from '@/pages/AccountLogin'
import AccountRegister from '@/pages/AccountRegister'
import AccountConfirm from '@/pages/AccountConfirm'
import AccountLoginGlHomes from '@/pages/AccountLoginGlHomes.vue'
import AccountResetPassword from '@/pages/AccountResetPassword'
import AccountConfirmResetPassword from '@/pages/AccountConfirmResetPassword'
import Account from '@/pages/Account'
import DashboardStart from '@/pages/DashboardStart'
import DashboardProject from '@/pages/DashboardProject'
import Settings from '@/pages/Settings'
import AdminRegisteredUsers from '@/pages/AdminRegisteredUsers'
import Workflows from '@/pages/Workflows'
import TeamMembers from '@/pages/TeamMembers'
import OnlineHelp from '@/pages/OnlineHelp'
import Workflow from '@/pages/Workflow'
import NotFound from '@/pages/NotFound'

const routes = [
  { path: '/login', component: AccountLogin, meta: { requiresNoAuth: true } },
  { path: '/login-with-provider/glhomes', component: AccountLoginGlHomes, meta: { requiresNoAuth: true } },
  { path: '/signup', component: AccountRegister, meta: { requiresNoAuth: true } },
  { path: '/confirm', component: AccountConfirm, meta: { requiresNoAuth: true } },
  { path: '/reset-password', component: AccountResetPassword, meta: { requiresNoAuth: true } },
  { path: '/confirm-reset-password', component: AccountConfirmResetPassword, meta: { requiresNoAuth: true } },
  { path: '/account', component: Account, meta: { requiresAuth: true } },
  { path: '/dashboard/:projectId', component: DashboardProject, meta: { requiresAuth: true, requiresProject: true } },
  { path: '/dashboard', component: DashboardStart, meta: { requiresAuth: true } },
  { path: '/', redirect: '/dashboard', requiresProject: true },
  { path: '/workflows/:projectId', component: Workflows, meta: { requiresAuth: true, requiresProject: true } },
  { path: '/team/:projectId', component: TeamMembers, meta: { requiresAuth: true, requiresProject: true } },
  { path: '/onlinehelp', component: OnlineHelp, meta: { requiresAuth: false, requiresProject: false } },
  { path: '/workflow/:projectId/:id', component: Workflow, meta: { allowNonTeamMember: true, requiresProject: true } },
  { path: '/workflow/:projectId/:id/:eventId', component: Workflow, meta: { allowNonTeamMember: true, requiresProject: true } },
  { path: '/settings', component: Settings, meta: { requiresAuth: true } },
  { path: '/admin', component: AdminRegisteredUsers, meta: { requiresAuth: true } },
  { path: '/404', component: NotFound },
  { path: '/:pathMatch(.*)*', redirect: '/404' }
]

export default routes
