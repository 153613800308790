import gql from 'graphql-tag'

export default gql`
  mutation updateUser($firstname: String, $lastname: String, $email: String!, $company: String, $phoneNumber: String, $job: String, $picture: PictureInput, $increaseLoginCounter: Boolean) {
    updateUser(firstname: $firstname, lastname: $lastname, email: $email, company: $company, phoneNumber: $phoneNumber, job: $job, picture: $picture, increaseLoginCounter: $increaseLoginCounter) {
        id
        email
        firstname
        lastname
        company
        phoneNumber
        job
        picture {
          name
          url
          file {
            region
            bucket
            key
          }
          mimeType
        }
      }
  }
`
