export default `
  mutation UpdateBCObject($id: ID!, $input: UpdateBCObjectInput!) {
    updateBCObject(id: $id, input: $input) {
      id
      boundedContextId
      filepath
      prompt
      contents
    }
  }
`
