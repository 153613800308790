import positionHelpers from './positionHelpers'
import { workflowApiHelpers } from '@/api/workflowApiHelpers'

export default {
  getSurroundingNodes (id, svgGraph, svgGroups) {
    let childNode, grandChildNode, parentNode, grandParentNode
    const draggedNode = svgGraph[id]
    if (draggedNode.childIds?.length > 0) {
      let closestChildId = this.findClosestNode(draggedNode.id, draggedNode.childIds, svgGraph, 'right-side', svgGroups)
      // special case for finding child nodes that are connected with a loop back, could probably be solved in a better way
      if (!closestChildId && draggedNode.childIds.length === 1) {
        closestChildId = draggedNode.childIds[0]
      }
      if (closestChildId) {
        // var childNode = Object.assign({}, svgGraph[closestChildId])
        childNode = svgGraph[closestChildId]
      }
    }
    if (childNode?.childIds?.length > 0) {
      let closestGrandChildId = this.findClosestNode(draggedNode.id, childNode.childIds, svgGraph, 'right-side', svgGroups)
      // special case for finding child nodes that are connected with a loop back, could probably be solved in a better way
      if (!closestGrandChildId && childNode.childIds.length === 1) {
        closestGrandChildId = childNode.childIds[0]
      }
      if (closestGrandChildId) {
        grandChildNode = svgGraph[closestGrandChildId]
      }
    }
    const closestParentIds = draggedNode.parents.filter(it => it !== 'start')
    if (closestParentIds.length > 0) {
      const closestParentId = this.findClosestNode(draggedNode.id, closestParentIds, svgGraph, 'left-side', svgGroups)
      if (closestParentId) {
        parentNode = svgGraph[closestParentId]
      } else {
        // special case when dragging left over a node with a gap to fill
        parentNode = svgGraph[draggedNode.parents[0]]
      }
      // const keys = Object.keys(svgGroups)
      // const hasGroups = keys?.length > 0
      // if (!parentNode?.groupId && hasGroups) {
      //   if (parentNode?.x === 80 && parentNode.parents[0] === 'start') {
      //     parentNode.groupId = this.getGroupFromSlotX(0, svgGroups).id
      //   }
      // }
    }
    const closestGrandParentIds = (parentNode?.parents || []).filter(it => it !== 'start')
    if (closestGrandParentIds.length > 0) {
      const closestGrandParentId = this.findClosestNode(draggedNode.id, closestGrandParentIds, svgGraph, 'left-side', svgGroups, true)
      if (closestGrandParentId) {
        grandParentNode = svgGraph[closestGrandParentId]

        // if found grand parent is not a decision
        // and one of the other grand parents is a decision
        // and if the parent is closer than the closest grand parent
        // then the gateway is the grand parent (beause of the connection)
        if (grandParentNode.type !== 'bpmn:ExclusiveGateway') {
          const index = closestGrandParentIds.findIndex(parent => svgGraph[parent].type === 'bpmn:ExclusiveGateway' && svgGraph[parent].id !== draggedNode.id)
          if (index >= 0) {
            if (Math.abs(draggedNode.y - parentNode.y) <= Math.abs(draggedNode.y - grandParentNode.y)) {
              if (svgGraph[parentNode.parents[index]] && (svgGraph[parentNode.parents[index]].x < draggedNode.x)) { // ensure the grand parent is on the left side
                grandParentNode = svgGraph[parentNode.parents[index]]
              }
            }
          }
        }
      }
    }
    return { grandParentNode, parentNode, draggedNode, childNode, grandChildNode }
  },

  findClosestNode (currentNodeId, candidateNodeIds, svgGraph, side, svgGroups, acceptDistantGroup = false) {
    let shortestDistanceToNodeY = null
    let closestNode = null
    const currentNode = svgGraph[currentNodeId]
    for (let i = 0; i < candidateNodeIds.length; i++) {
      const candidateNode = svgGraph[candidateNodeIds[i]]
      if (candidateNode.id === currentNodeId) {
        continue
      }
      let testFlag = false
      if (side === 'right-side') {
        if (candidateNode.x >= currentNode.x) {
          testFlag = true
        }
      }
      if (side === 'left-side') {
        if (candidateNode.x <= currentNode.x) {
          testFlag = true
        }
        if (this.isCandidateNodeInDistantGroup(currentNode, closestNode, candidateNode, svgGroups) && !acceptDistantGroup) {
          testFlag = false
        }
      }
      if (testFlag) {
        const distanceToNodeY = Math.abs(candidateNode.y - currentNode.y)
        if (distanceToNodeY < shortestDistanceToNodeY || !closestNode) {
          closestNode = candidateNode
          shortestDistanceToNodeY = distanceToNodeY
        }
      }
    }
    return closestNode?.id
  },

  // check if the candidate node is in a group that is
  // further away from the current node than the closest node
  // because when we are dragging left into a group
  // then we should not consider nodes that are in a group
  // that is further away if there is a parent node in a closer group
  isCandidateNodeInDistantGroup (currentNode, closestNode, candidateNode, svgGroups) {
    if (!closestNode) {
      return false
    }
    const candidateGroup = positionHelpers.getGroupFromXCoordinate(candidateNode.x, svgGroups)
    if (!candidateGroup) {
      return false
    }
    const closestNodeGroup = positionHelpers.getGroupFromXCoordinate(closestNode.x, svgGroups)
    if (!closestNodeGroup) {
      return false
    }
    if (candidateGroup.startSlotX < closestNodeGroup.startSlotX) {
      return true
    }
  },

  // align parial branches to the right of each node passed in ids
  // stop aligning at the first occurence of a node that doesn't need to be aligned
  // supports dragging by not aligning to the dragged node (but its parent instead)
  alignBranchesPartially (svgGraph, svgGroups, selectedElement, ids) {
    let movedNodeIds = new Set()
    if (ids) {
      for (const id of ids) {
        if (svgGraph[id]) {
          const node = svgGraph[id]
          // if node is a start node then create a fake parent object with only x: -80 for alignment
          let parent = node.parents.find(id => id === 'start') ? { x: -80 } : svgGraph[node.parents[0]]
          if (selectedElement && parent.id === selectedElement.id) {
            // if parent is being dragged we can't align to it
            // in that case align to parents parent
            if (node.groupId && svgGroups[node.groupId]?.startSlotX > 0) { // startSlotX === 0 should be treated as no group
              const group = svgGroups[node.groupId]
              const x = positionHelpers.getXPointFromSlot(group.startSlotX)
              if (node.x !== x) {
                node.x = x
                if (node.groupId && svgGroups[node.groupId]) {
                  svgGroups[node.groupId].x = node.x - 30
                  svgGroups[node.groupId].startSlotX = positionHelpers.getSlotXFromPoint(node.x)
                }
                movedNodeIds = new Set([...movedNodeIds, id, ...this.alignBranchesPartially(svgGraph, svgGroups, selectedElement, node.childIds)])
              }
            } else {
              let parentX
              if (parent.groupId && svgGroups[parent.groupId]?.startSlotX > 0) { // startSlotX === 0 should be treated as no group
                const group = svgGroups[parent.groupId]
                parentX = positionHelpers.getXPointFromSlot(group.startSlotX)
                if (node.x !== (parentX + 160)) {
                  node.x = parentX + 160
                  if (node.groupId && svgGroups[node.groupId]) {
                    svgGroups[node.groupId].x = node.x - 30
                    svgGroups[node.groupId].startSlotX = positionHelpers.getSlotXFromPoint(node.x)
                  } else {
                    const group = positionHelpers.findGroupStartingAtCoordinate(node.x, svgGroups)
                    if (group && group.startSlotX !== 0) {
                      this.moveGroupRight(group, svgGroups)
                    }
                  }
                  movedNodeIds = new Set([...movedNodeIds, id, ...this.alignBranchesPartially(svgGraph, svgGroups, selectedElement, node.childIds)])
                }
              } else {
                parent = svgGraph[parent.parents[0]] || { x: -80 }
                parentX = positionHelpers.getParentX(node, parent, svgGroups)
                if (node.x !== (parentX + 320)) {
                  node.x = parentX + 320
                  if (svgGroups) {
                    if (node.groupId && svgGroups[node.groupId]) {
                      svgGroups[node.groupId].x = node.x - 30
                      svgGroups[node.groupId].startSlotX = positionHelpers.getSlotXFromPoint(node.x)
                    } else {
                      const group = positionHelpers.findGroupStartingAtCoordinate(node.x, svgGroups)
                      if (group && group.startSlotX !== 0) {
                        this.moveGroupRight(group, svgGroups)
                      }
                    }
                  }
                  movedNodeIds = new Set([...movedNodeIds, id, ...this.alignBranchesPartially(svgGraph, svgGroups, selectedElement, node.childIds)])
                }
              }
            }
          } else {
            const parentX = positionHelpers.getParentX(node, parent, svgGroups)
            if (node.x !== (parentX + 160)) {
              node.x = parentX + 160
              if (svgGroups) {
                if (node.groupId && svgGroups[node.groupId]) {
                  svgGroups[node.groupId].x = node.x - 30
                  svgGroups[node.groupId].startSlotX = positionHelpers.getSlotXFromPoint(node.x)
                } else {
                  const group = positionHelpers.findGroupStartingAtCoordinate(node.x, svgGroups)
                  if (group && group.startSlotX !== 0) {
                    this.moveGroupRight(group, svgGroups)
                  }
                }
              }
              movedNodeIds = new Set([...movedNodeIds, id, ...this.alignBranchesPartially(svgGraph, svgGroups, selectedElement, node.childIds)])
            }
          }
        }
      }
    }
    return movedNodeIds
  },

  moveGroupRight (group, svgGroups) {
    // move group recursively
    const groupArray = Object.values(svgGroups)
    let nextGroup = groupArray.find(g => g.startSlotX === (group.startSlotX + 1))

    if (nextGroup?.id === group.id) {
      nextGroup = null
    }
    group.startSlotX++
    group.x = group.x + 160
    const previousGroup = groupArray.find(g => g.index === (group.index - 1))
    if (previousGroup) {
      previousGroup.width += 160
    }
    if (nextGroup) {
      this.moveGroupRight(nextGroup, svgGroups)
    }
  },

  // moves the groupId from the parentNode to the childNode
  // and also to all other children in the same group as
  // the passed in child, if the parentNode is a decision
  moveGroupIdToChildNodes (parentNode, childNode, svgGraph, svgGroups) {
    if (childNode) {
      childNode.groupId = parentNode.groupId
      // special case if parentNode is a decision
      if (parentNode.type === 'bpmn:ExclusiveGateway') {
        for (const childId of parentNode.childIds) {
          const child = svgGraph[childId]
          if (workflowApiHelpers.getGroupFromNode(child, svgGroups, svgGraph)?.id === childNode.groupId) {
            if (child.parents[0] === parentNode.id) {
              child.groupId = parentNode.groupId
            }
          }
        }
      }
    }
  },

  switchCurrentNodeWithChild ({ parentNode, draggedNode, childNode, grandChildNode }, svgGraph, svgGroups) {
    let isParentNode = true
    if (this.isNode2MainLineDescentantOfNode1(draggedNode.id, parentNode?.id, svgGraph)) {
      // if parent node is a main descentand of dragged node then
      // parent node is not really a parent node
      isParentNode = false
    }
    if (!isParentNode) {
      parentNode = null
    }

    // check if the childNode has another starting point apart
    // from the dragged node
    const foundOtherStartingPoint = this.findOtherStartingPoint(childNode, draggedNode.id, svgGraph, true)

    // update draggedNode,
    // the childNode will become the new parent
    // and the old parentNode will be disconnected
    // from the draggedNode
    this.replaceParent(draggedNode, parentNode, childNode)

    // if there is no parentNode, that means draggedNode is a starting point
    // and childNode will beceome a new starting point
    // unless childNode has another starting point
    // then childNode should not become a starting point
    if (!parentNode) {
      parentNode = this.findOtherStartingPoint(childNode, draggedNode.id, svgGraph, true)
    }

    // update childNode
    // the parentNode will become the new parent
    this.replaceParent(childNode, draggedNode, parentNode)

    // give grand child new parent
    if (grandChildNode && grandChildNode.id !== draggedNode.id) {
      if (childNode.type !== 'bpmn:ExclusiveGateway' || (Math.abs(grandChildNode.y - draggedNode.y) < 100)) {
        this.replaceParent(grandChildNode, childNode, draggedNode)
      }
    }

    // compute the new childIds
    this.recomputeChildIds(svgGraph)

    // Swap groups
    if (draggedNode && childNode) {
      const currentGroup = positionHelpers.getGroupFromXCoordinate(draggedNode.x, svgGroups)
      const childGroup = positionHelpers.getGroupFromXCoordinate(childNode.x, svgGroups)
      const otherParentsOfChildInGroup = this.getParentsInTargetGroup(childNode, childGroup?.id, svgGraph, svgGroups, true, false).filter(parentId => parentId !== draggedNode.id)
      if ((currentGroup?.id === childGroup?.id && otherParentsOfChildInGroup.length > 0) || (foundOtherStartingPoint && childNode.groupId)) {
        // don't swap becaue the child already has another parent
        // with a goupid that we don't want to move
        // to align with in the same group
        draggedNode.groupId = null
      } else {
        const tmpGroupId = draggedNode.groupId
        draggedNode.groupId = childNode.groupId
        childNode.groupId = tmpGroupId
      }
    }
  },

  switchCurrentNodeWithParent ({ grandParentNode, parentNode, draggedNode, childNode }, svgGraph, svgGroups) {
    // update parent with new parents
    this.replaceParent(parentNode, grandParentNode, draggedNode)

    // merge with different grandParent if current is connected to more than one staring points
    if (!grandParentNode) {
      grandParentNode = this.findOtherStartingPoint(draggedNode, parentNode.id, svgGraph, true)
    }

    // update current (first with new parent and then with new child)
    this.replaceParent(draggedNode, parentNode, grandParentNode)

    // update CHILD (with new parent)
    if (childNode && childNode.id !== parentNode.id) {
      this.replaceParent(childNode, draggedNode, parentNode)
    }

    // compute the new childIds
    this.recomputeChildIds(svgGraph)

    // Update groups
    if (draggedNode && parentNode) {
      const group = workflowApiHelpers.getGroupFromNode(parentNode, svgGroups, svgGraph)
      if (group) {
        const parentsInTargetGroup = this.getParentsInTargetGroup(draggedNode, group.id, svgGraph, svgGroups, true, false)
        if (parentNode.groupId && !draggedNode.groupId && parentsInTargetGroup.length > 0) {
          draggedNode.groupId = null
          parentNode.groupId = null
        } else {
          // Swap groups
          const tmpGroupId = draggedNode.groupId
          draggedNode.groupId = parentNode.groupId
          parentNode.groupId = tmpGroupId
        }
      }
    }

    // Swap groups, special case when dragging a node to the left
    // inside a group, the parent is in the same group, and there
    // are two grand parent candidates, one inside the group and
    // one grand parent in a previous group (with a gap)
    // then we need to set the group on the dragged node
    if (grandParentNode) {
      const draggedNodeGroup = workflowApiHelpers.getGroupFromNode(draggedNode, svgGroups, svgGraph)
      const grandParentNodeGroup = workflowApiHelpers.getGroupFromNode(grandParentNode, svgGroups, svgGraph)
      if (draggedNode.groupId === null &&
          parentNode.groupId === null &&
          grandParentNodeGroup &&
          grandParentNodeGroup.id !== draggedNodeGroup?.id) {
        draggedNode.groupId = draggedNodeGroup.id
      }
    }
  },

  recomputeChildIds (svgGraph) {
    for (const eventId in svgGraph) {
      svgGraph[eventId].childIds = []
    }
    for (const eventId in svgGraph) {
      for (const parentId of svgGraph[eventId].parents) {
        if (parentId !== 'start') {
          svgGraph[parentId].childIds.push(eventId)
        }
      }
    }
  },

  replaceParent (node, oldParent, newParent, forceNewParentAsMain = false) {
    if (node) {
      const oldParentId = oldParent ? oldParent.id : 'start'
      const newParentId = newParent ? newParent.id : 'start'

      // remove new parent if it already exists on node to avoid adding it twice
      let index = node.parents.indexOf(newParentId)
      if (index >= 0) {
        node.parents.splice(index, 1)
      }

      // update node with new child
      index = node.parents.indexOf(oldParentId)
      if (index >= 0) {
        if (forceNewParentAsMain || newParentId === 'start') {
          node.parents.splice(index, 1)
          node.parents.unshift(newParentId)
        } else {
          if (newParentId !== node.id && !node.parents.includes(newParentId)) { // can't be parent of itself, and avoid duplicates
            node.parents.splice(index, 1, newParentId)
          } else {
            node.parents.splice(index, 1)
          }
        }
      }
    }
  },

  // returns one parent (if any exists) that leads to a new starting point other than firstStartId
  findOtherStartingPoint (node, firstStartId, svgGraph, onlyLeftSide = false) {
    const visited = new Set()

    if (node) {
      for (const parentId of node.parents) {
        // if the node itself is a starting point
        // then return it as the other starting point
        if (parentId === 'start') {
          return node
        }
        // add a single parentId to an array
        // so we can add more parentIds to the array
        // as we traverse the graph
        const parentIds = [parentId]
        for (let i = 0; i < parentIds.length; i++) {
          const eventId = parentIds[i]
          if (eventId === firstStartId) {
            continue
          }

          if (eventId === node.id) {
            continue
          }

          if (visited.has(eventId)) {
            continue
          }
          if (eventId !== 'start') {
            visited.add(eventId)
          }

          if (eventId === 'start') {
            // ensure the parent is on the left side
            // and not from a backwards connection
            if (svgGraph[parentId]?.x < node.x || !onlyLeftSide) {
              return svgGraph[parentId]
            }
          }

          if (onlyLeftSide && svgGraph[eventId]?.x > node.x) {
            continue
          }

          const parent = svgGraph[eventId]
          if (parent) {
            parentIds.push(...parent.parents)
          }
        }
      }
    }
    return null
  },

  handleGroupSwitch ({ parentNode, draggedNode, childNode }, newSlotX, oldSlotX, slotToGroupMap, svgGraph, svgGroups) {
    // console.log('svgGraph', JSON.parse(JSON.stringify(svgGraph)))
    // console.log('svgGroups', JSON.parse(JSON.stringify(svgGroups)))
    // console.log('draggedNode.id', draggedNode.id)
    let switchedPlaces = false
    let preserveOrder = false
    const newGroup = slotToGroupMap[newSlotX]
    const oldGroup = slotToGroupMap[oldSlotX]
    if (newGroup && newGroup?.id !== oldGroup?.id) {
      if (newGroup.id !== draggedNode.groupId && newSlotX < oldSlotX) {
        // dragging left into a group
        if (childNode) {
          // update all children that are left in
          // the old group as the dragged node is being
          // moved left into the new group
          draggedNode.childIds?.forEach(childId => {
            const childNode = svgGraph[childId]
            const childGroup = slotToGroupMap[positionHelpers.getSlotXFromId(childId, svgGraph)]
            if (childGroup.id === draggedNode.groupId) {
              const node = {
                parents: childNode.parents.filter(parentId => parentId !== draggedNode.id),
                x: childNode.x
              }
              if (this.getParentsInTargetGroup(node, childGroup.id, svgGraph, svgGroups, true).length === 0) {
                this.moveGroupIdToChildNodes(draggedNode, childNode, svgGraph, svgGroups)
                switchedPlaces = true
              }
            }
          })
          const childGroupId = positionHelpers.getGroupFromXCoordinate(childNode.x, svgGroups)?.id
          if (childGroupId === newGroup.id && this.getParentsInTargetGroup(draggedNode, childGroupId, svgGraph, svgGroups).length === 0) {
            // special case: dragging node left into the group where the child is
            // and where the dragged node has no parent in the group
            childNode.groupId = null
            const index = childNode.parents.findIndex(parentId => parentId === 'start')
            if (index >= 0) {
              childNode.parents.splice(index, 1)
            }
            draggedNode.groupId = newGroup.id
            // do we always add start when necessary?
            // if (!draggedNode.parents.includes('start')) {
            //   draggedNode.parents.unshift('start')
            // }
            switchedPlaces = true
          }
        }
        const parentGroup = parentNode && slotToGroupMap[positionHelpers.getSlotXFromId(parentNode?.id, svgGraph)]
        if (!parentNode || parentGroup.id !== newGroup.id) {
          if (newGroup.startSlotX === 0) {
            // special case: dragging left into the first group
            // the dragged node does not have a parent in the group
            // the first group always have groupId null
            draggedNode.groupId = null
            if (draggedNode.parents[0] !== 'start') {
              draggedNode.parents.unshift('start')
            }
          } else {
            draggedNode.groupId = newGroup.id
            // if the draggedNode has child nodes in the new group
            // then they must get their groupId set to null
            // because they are no longer the first node in the group
            draggedNode.childIds?.forEach(childId => {
              if (svgGraph[childId].groupId === newGroup.id) {
                svgGraph[childId].groupId = null
              }
            })
          }
          // if the draggedNode has child nodes in the new group
          // then remove remove 'start' from their parentIds array
          // if exists because they are no longer the first node in the group
          draggedNode.childIds?.forEach(childId => {
            const group = workflowApiHelpers.getGroupFromNode(svgGraph[childId], svgGroups, svgGraph)
            if (group.id === newGroup.id && svgGraph[childId].parents.includes('start')) {
              workflowApiHelpers.removeStartingPointFromNode(svgGraph[childId])
            }
          })
          switchedPlaces = true
        } else {
          draggedNode.groupId = null
          draggedNode.childIds?.forEach(childId => {
            if (this.hasAncestorDescendantRelationshipInsideGroup(childId, draggedNode.id, newGroup.id, svgGraph, svgGroups) === false) {
              // if the draggedNode has child nodes in the new group
              // then remove remove 'start' from their parentIds array
              // if exists because they are no longer the first node in the group
              const group = workflowApiHelpers.getGroupFromNode(svgGraph[childId], svgGroups, svgGraph)
              if (group.id === newGroup.id && svgGraph[childId].parents.includes('start')) {
                workflowApiHelpers.removeStartingPointFromNode(svgGraph[childId])
              }
              // if the draggedNode has child nodes in the new group
              // then they must get their groupId set to null
              // because they are no longer the first node in the group
              if (svgGraph[childId].groupId === newGroup.id) {
                svgGraph[childId].groupId = null
              }
            }
          })
          switchedPlaces = true
        }
      } else if (newGroup.id !== draggedNode.groupId && newSlotX > oldSlotX) {
        // dragging right into the next group
        const parentsInTargetGroup = this.getParentsInTargetGroup(draggedNode, newGroup.id, svgGraph, svgGroups, false, true)
        let findOtherStartingPoint = false
        parentsInTargetGroup.forEach(parentId => {
          if (this.findOtherStartingPoint(svgGraph[parentId], draggedNode.id, svgGraph)) {
            findOtherStartingPoint = true
          }
        })
        if (findOtherStartingPoint) {
          // special case when dragging right into the next group
          // and at least one parent is already in this target group
          // so the dragged node should not be the first node in the target group
          // if there is a child node it's also not the first node in the group
          // the first node in the group is to the left of the dragged node
          draggedNode.groupId = null
          draggedNode.childIds?.forEach(childId => {
            if (svgGraph[childId] && svgGraph[childId].groupId === newGroup.id) {
              // if the child node is pinned to another starting point
              // then keep the group id becase we don't want to move the child
              if (!this.findOtherStartingPoint(svgGraph[childId], draggedNode.id, svgGraph)) {
                svgGraph[childId].groupId = null
              }
            }
          })
          draggedNode.parents = draggedNode.parents.filter(parentId => parentId !== 'start')
          if (!parentsInTargetGroup.includes(draggedNode.parents[0])) {
            const tmp = draggedNode.parents.shift()
            draggedNode.parents.push(tmp)
          }
          switchedPlaces = true
        } else {
          draggedNode.groupId = newGroup.id
          switchedPlaces = true
          // if the draggedNode has child nodes in the old group
          // then they might have to become start nodes
          // if they don't have other parents to the right
          draggedNode.childIds?.forEach(childId => {
            this.updateStartNodeStatus(svgGraph[childId], svgGraph, svgGroups)
          })
        }
        draggedNode.childIds?.forEach(childId => {
          if (draggedNode.groupId === svgGraph[childId]?.groupId) {
            // remove groupId from child nodes
            // that are in the same group as the
            // dragged node is being moved to
            svgGraph[childId].groupId = null
            switchedPlaces = true
            preserveOrder = true
          }
          const group = workflowApiHelpers.getGroupFromNode(svgGraph[childId], svgGroups, svgGraph)
          if (oldGroup.id === group.id) {
            // if there are children left in the previous group
            // then they might need to be assigned a groupId
            if (this.getParentsInTargetGroup(svgGraph[childId], oldGroup.id, svgGraph, svgGroups, false, false).length === 0) {
              if (oldGroup.startSlotX !== 0) {
                svgGraph[childId].groupId = oldGroup.id
              } else {
                svgGraph[childId].groupId = null
              }
            }
          }
        })
      }
      // update the start node status for the dragged node
      this.updateStartNodeStatus(draggedNode, svgGraph, svgGroups)
    }
    // console.log('svgGraph AFTER', JSON.parse(JSON.stringify(svgGraph)))
    // console.log('svgGroups AFTER', svgGroups)
    return { switchedPlaces, preserveOrder }
  },

  // returns an array of all the parent Ids
  // of the draggedNode that are in the target group
  // or an empty array
  // by setting exludeParentsThatAreAlsoChildren to true
  // we exclude parents that are also descendants
  // of the draggedNode inside the target group
  // where all decendants are in the same group
  getParentsInTargetGroup (draggedNode, groupId, svgGraph, svgGroups, onlyLeftSide = false, exludeParentsThatAreAlsoChildren = false) {
    const res = []
    if (draggedNode?.parents) {
      draggedNode.parents.forEach(parentId => {
        const group = positionHelpers.getGroupFromXCoordinate(svgGraph[parentId]?.x, svgGroups)
        if (group && group.id === groupId && (!onlyLeftSide || svgGraph[parentId].x < draggedNode.x)) {
          let isParentNode = true
          if (this.isNode2MainLineDescentantOfNode1(draggedNode.id, parentId, svgGraph)) {
            // if parent node is a main descentand of dragged node then
            // parent node is not really a parent node
            isParentNode = false
          }
          if (isParentNode) {
            res.push(parentId)
          }
        }
      })
    }
    if (exludeParentsThatAreAlsoChildren) {
      return res.filter(parentId => this.hasAncestorDescendantRelationshipInsideGroup(draggedNode.id, parentId, groupId, svgGraph, svgGroups) === false)
    }
    return res
  },

  // traverse upwards from the child node
  // using only the main parent (parent[0])
  // until we find the parent node or the start node
  isNode2MainLineDescentantOfNode1 (nodeId1, nodeId2, svgGraph) {
    let res = false
    let tmpNode = svgGraph[nodeId2]
    while (tmpNode && tmpNode.parents[0] !== 'start') {
      tmpNode = svgGraph[tmpNode.parents[0]]
      if (tmpNode.id === nodeId1) {
        res = true
        break
      }
    }
    return res
  },

  // takes two nodes and checks if the descendantId is a descendant
  // of the ancestorId inside the groupId and make sure that
  // any nodes in between also are in the same groupId
  // this function is important when we drag a node into
  // a group where the dragged node as a parent in the target group
  // and also a child in the same target group, because if the parent
  // also is a child or descendant of the dragged node then it should be ignored
  // as a parent in the target group
  hasAncestorDescendantRelationshipInsideGroup (ancestorId, descendantId, groupId, svgGraph, svgGroups) {
    let hasRelationship = false
    workflowApiHelpers.traverseChildNodes(svgGraph, ancestorId, (descendant) => {
      if (descendant.id === ancestorId) {
        // don't check the ancestor itself
        return 0 // continue
      }
      const descendantGroup = workflowApiHelpers.getGroupFromNode(descendant, svgGroups, svgGraph)
      if (descendantGroup?.id !== groupId) {
        // we have left the group
        // so stop traversing this branch
        return 1
      }
      if (descendant.id === descendantId) {
        // we have reached the descendant
        // without leaving the group
        // so we have a relationship
        hasRelationship = true
        return 2 // stop traversing all branches
      }
      return 0 // continue
    })
    return hasRelationship
  },

  updateStartNodeStatus (childEvent, svgGraph, svgGroups) {
    if (!childEvent) {
      return
    }
    childEvent.parents = childEvent.parents.filter(parentId => parentId !== 'start')
    if (this.isStartNode(childEvent, svgGraph, svgGroups)) {
      childEvent.parents.unshift('start')

      // when adding a start parent to a node
      // we need to make sure that the child nodes
      // if they are in the same group
      // don't have start as a parent
      childEvent.childIds?.forEach(childId => {
        const childGroup = workflowApiHelpers.getGroupFromNode(svgGraph[childEvent.id], svgGroups, svgGraph)
        const childChildGroup = workflowApiHelpers.getGroupFromNode(svgGraph[childId], svgGroups, svgGraph)
        if (childGroup?.id === childChildGroup?.id) {
          svgGraph[childId].parents = svgGraph[childId].parents.filter(parentId => parentId !== 'start')
        }
      })
    } else {
      // not a start node
      childEvent.parents = childEvent.parents.filter(parentId => parentId !== 'start')
    }
  },

  // checks if a node is a start node
  isStartNode (node, svgGraph, svgGroups) {
    // 1. No parents means start node
    if (!node.parents.length) {
      return true
    }
    if (node.parents.length === 1 && node.parents[0] === 'start') {
      return true
    }

    const hasGroups = Object.values(svgGroups || {}).length > 0
    let nodeGroup
    if (hasGroups) {
      nodeGroup = workflowApiHelpers.getGroupFromNode(node, svgGroups, svgGraph)
    }
    for (const parentId of node.parents) {
      let parentGroup

      // don't consider the start node as a parent
      if (parentId === 'start') {
        continue
      }

      // 2. if there are groups, and one parent is in a earlier group, it is NOT a start node
      if (hasGroups) {
        parentGroup = workflowApiHelpers.getGroupFromNode(svgGraph[parentId], svgGroups, svgGraph)
        if (parentGroup && parentGroup.startSlotX < nodeGroup.startSlotX) {
          return false
        }
      }

      // 3. if there are no groups or all parents are in the same group,
      // and one parent is not also a child, it is NOT a start node
      if (!hasGroups || (parentGroup && parentGroup.id === nodeGroup.id)) {
        if (!this.hasAncestorDescendantRelationshipInsideGroup(node.id, parentId, parentGroup?.id, svgGraph, svgGroups)) {
          return false
        }
      }
    }

    // 4. if there are no gropus or all parents are in the same group,
    // if all parents are also children, then check x coordinate of
    // parents, if one parent has a lower x, the node is NOT a start node
    for (const parentId of node.parents) {
      if (svgGraph[parentId].x < node.x) {
        return false
      }
    }

    // 5. otherwise it is a start node
    return true
  }
}
