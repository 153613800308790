<template>
  <v-row class="d-flex ma-0 py-5">
    <!-- #region Roles (first column) -->
    <v-col sm="2" class="col-container justify-space-around">
      <template v-for="(role, roleIndex) in system.roles" :key="`${roleIndex}`">
        <ConnectingLine
          :startElementId="`role-${systemIndex}-${role.id}`"
          :endElementId="`command-${systemIndex}-${roleIndex}`"
          :refreshKey="refreshKey"
          :spacingStartElement="20"
        />
      </template>
      <template v-for="(role, roleIndex) in system.roles" :key="roleIndex">
        <v-card
          v-if="role && (roleIndex == 0 || (role.name != system.roles[roleIndex - 1].name))"
          :id="`role-${systemIndex}-${role.id}`"
          class="d-flex flex-column align-center"
        >
          <v-icon class="my-2">mdi-human</v-icon>
          <bpmn-swim-lane-title
            class="px-3"
            :lane="role"
            :index="role.laneIndex"
            :svgGraph="svgGraph"
            :svgLanes="svgLanes"
            :workflow="workflow"
            disableReorder
            @name-blur="roleBlur($event, roleIndex)">
          </bpmn-swim-lane-title>
        </v-card>
      </template>
    </v-col>
    <!-- #endregion -->
    <!-- #region Trigger/Action cards (second column) -->
    <v-col sm="2" class="col-container justify-space-around">
      <template v-for="(event, eventIndex) in system.events" :key="eventIndex">
        <ConnectingLine
          :startElementId="`command-${systemIndex}-${eventIndex}`"
          :endElementId="`system-${systemIndex}`"
          :refreshKey="refreshKey"
          :spacingEndElement="20"
        />
        <div :id="`command-${systemIndex}-${eventIndex}`" class="my-2" style="min-width: 200px;">
          <requirement-description
            v-if="system.actions[eventIndex] && system.actions[eventIndex].id"
            :item="system.actions[eventIndex]"
            hide-type-selector
            hide-replies
            hide-actions
            @text-delta="refreshKey++"
          >
            <template v-slot:data-fields v-if="showFields && system.dataFields?.length">
              <v-divider thickness="2"></v-divider>
              <div v-if="showFields && getDataFieldsFromEvent(event).length > 0" class="pa-2">
                <template v-for="(field, index4) in getDataFieldsFromEvent(event)">
                  <div v-if="field.tags?.includes('WRITE') || field.tags?.includes('WRITE')" :key="index4"
                    class="field-name">
                    {{ field.name }}
                  </div>
                </template>
              </div>
              <div v-else-if="showFields" class="pa-2">
                No data model created
              </div>
            </template>
          </requirement-description>
          <v-btn
            v-else-if="triggerCardType"
            class="add-trigger-btn"
            size="small"
            :style="{
              'border-color': getBackgroundColour(triggerCardType) + ' !important',
            }"
            block
            @click="
              submitCardForm(system.events[eventIndex].id, triggerCardType.id, `<p>Trigger for ${system.events[eventIndex].description}</p>`)
              " :disabled="disabled"
          >
            + Add
          </v-btn>
          <aggregate-type-selector
            v-else
            :requirementTypes="requirementTypes"
            :isTrigger="true"
          />
        </div>
      </template>
    </v-col>
    <!-- #endregion -->
    <!-- #region System cards (third column) -->
    <v-col sm="6" class="col-container justify-center">
      <v-card
        v-if="system"
        class="ma-5"
        :class="{
          'elevation-1': (showEntities && findAllDimensionsInCurrentSystem.length > 0),
          'service-border': (showEntities && findAllDimensionsInCurrentSystem.length > 0)
        }"
        style="z-index: 1;"
        :id="`system-${systemIndex}`"
        variant="text"
      >
        <div class="pa-2 pb-0">
          <requirement-description
            v-if="system.systemCard"
            :item="system.systemCard"
            :boundedContexts="boundedContexts"
            :showBoundedContext="true"
            hide-type-selector
            hide-replies
            hide-actions
            style="min-width: 100px; max-width: 200px; margin: auto;"
          >
            <template v-slot:default>
              <text-editor
                class="pa-1"
                :id="system.systemCard.id"
                :index="systemIndex"
                :text="system.systemCard.description"
                :disabled="$store.state.isWorkflowDisabled"
                @text-update="updateSystemDescription"
                @text-delta="() => refreshKey++"
                @move-focus="(dir, target) => target.firstChild.blur()"
                @keydown.native.stop=""
              />
            </template>
            <template v-slot:data-fields>
              <template  v-if="showFields && system.dataFields?.length">
                <v-divider thickness="2"></v-divider>
                <div v-if="showFields && system.dataFields?.length" class="pa-2">
                  <div v-for="(field, index4) in system.dataFields" :key="index4" class="field-name">{{ field.name }}</div>
                </div>
                <div v-else-if="showFields" class="pa-2">
                  No data model created
                </div>
              </template>
              <workflow-aggregate-select-bounded-context
                :requirement="system.systemCard"
                :boundedContexts="boundedContexts"
              />
            </template>
          </requirement-description>
          <v-btn v-else-if="systemCardType" class="add-system-btn" size="small" :style="{
            'border-color': getBackgroundColour(systemCardType) + ' !important',
          }"
            block @click="
              submitCardForm(system.events[0].id, systemCardType.id, `<p>System for ${system.events[0].description}</p>`)
              " :disabled="disabled">
            + Add
          </v-btn>
          <aggregate-type-selector
            v-else :requirementTypes="requirementTypes"
            :isTrigger="false"
          />

          <!-- System: entities under aggregate root -->
          <div v-if="showEntities" class="d-flex justify-space-between pa-2 pt-0" style="max-width: 400px; flex-direction: row-reverse;">
            <template v-if="system.systemCard">
              <template v-for="columnName in ['left', 'right']" :key="columnName">
                <div class="d-flex" style="max-width: 45%; min-width: 45%; flex-direction: column; justify-content: space-between; align-items: center; font-size: 13px;">
                  <template v-for="(dimension, index) in starDimensions(columnName, system.events[0].id, system.systemCard.boundedContext)" :key="index">
                    <v-card
                      :id="`${system.systemCard.boundedContext}-${columnName}-end-element-${dimension.dimensionName}`"
                      class="d-flex flex-column align-start mt-5 bg-white hover-container"
                      min-width="150"
                      :ref="`left-dimensions-${index}`"
                    >
                      <v-card-text
                        class="requirement-card-body rounded-lg pa-1 hover-container"
                        :style="{
                          'border-color': cardTypeColor(systemCardType),
                          'border-radius': '1px',
                          'font-size': '13px !important',
                        }"
                      >
                        {{ dimension.dimensionName }}
                        <template v-if="showFields && system.dataFields?.length">
                          <v-divider thickness="2" style="width: 100%;"></v-divider>
                          <template v-for="(field, index2) in dimension" :key="index2">
                            <div v-if="field">
                              {{ field.name }}
                            </div>
                          </template>
                        </template>
                        <workflow-aggregate-select-bounded-context
                          :dimension="dimension"
                          :boundedContexts="boundedContexts"
                        />
                      </v-card-text>
                    </v-card>
                    <connecting-path
                      :endElementId="`${system.systemCard.boundedContext}-${columnName}-end-element-${dimension.dimensionName}`"
                      :startElementId="system.systemCard.id"
                      :refreshKey="refreshKey"
                      start="bottom"
                      end="top"
                    />
                  </template>
                </div>
              </template>
            </template>
          </div>
        </div>

        <!-- System: entities under integration with other contexts -->
        <template v-if="showEntities && system.systemCard && findDimensionsNotInBoundedContext(system.systemCard.boundedContext, system.events[0].id).length > 0">
          <div class="integration-divider text-white mt-1 mb-0">&nbsp;Integration with other bounded contexts</div>
          <div class="d-flex justify-space-between pa-2 pt-0" style="max-width: 400px; lex-direction: row-reverse;">
            <template v-if="system.systemCard">
              <template v-for="columnName in ['left', 'right']" :key="columnName">
                <div class="d-flex" style="max-width: 45%; min-width: 45%; flex-direction: column; justify-content: space-between; align-items: center; font-size: 13px;">
                  <template v-for="(dimension, index) in starDimensions(columnName, system.events[0].id, system.systemCard.boundedContext, true)" :key="index">
                    <v-card
                      :id="`${system.systemCard.boundedContext}-integration-${columnName}-end-element-${dimension.dimensionName}`"
                      class="d-flex flex-column align-start mt-5 bg-white hover-container"
                      min-width="150"
                      :ref="`left-dimensions-${index}`"
                    >
                      <v-card-text
                        class="requirement-card-body rounded-lg pa-1 hover-container"
                        :style="{
                          'border-color': '#868686',
                          'border-radius': '1px',
                          'font-size': '13px !important',
                        }"
                      >
                        {{ dimension.dimensionName }}
                        <template v-if="showFields && system.dataFields?.length">
                          <v-divider thickness="2" style="width: 100%;"></v-divider>
                          <template v-for="(field, index2) in dimension" :key="index2">
                            <div v-if="field">
                              {{ field.name }}
                            </div>
                          </template>
                        </template>
                        <workflow-aggregate-select-bounded-context
                          :dimension="dimension"
                          :boundedContexts="boundedContexts"
                        />
                      </v-card-text>
                    </v-card>
                    <connecting-path
                      :endElementId="`${system.systemCard.boundedContext}-integration-${columnName}-end-element-${dimension.dimensionName}`"
                      :startElementId="system.systemCard.id"
                      :refreshKey="refreshKey"
                      start="bottom"
                      end="top"
                    />
                  </template>
                </div>
              </template>
            </template>
          </div>
        </template>
      </v-card>
    </v-col>
    <!-- #endregion -->
    <!-- #region Events (last column) -->
    <v-col sm="2" class="col-container justify-space-around">
      <template v-for="(event, eventIndex) in system.events" :key="eventIndex">
        <div :id="`event-${systemIndex}-${eventIndex}`" class="ma-3" @mousedown.stop @click.stop
          style="max-width: 100px; max-height: 90px;">
          <svg>
            <bpmn-event :eventDefinition="workflow.eventDefinition" :workflow="workflow" :svgGraph="svgGraph"
              :svgLanes="svgLanes" :node="{ ...event, x: 0, y: 0 }" class="bpmn-event-container"></bpmn-event>
          </svg>
        </div>
        <ConnectingLine
          :startElementId="`system-${systemIndex}`"
          :endElementId="`event-${systemIndex}-${eventIndex}`"
          :refreshKey="refreshKey"
          :spacingStartElement="20"
        />
      </template>
    </v-col>
    <!-- #endregion -->
  </v-row>
</template>

<script>
import workflowApi from '@/api/workflowApi'
import ConnectingLine from '@/components/ConnectingLine'
import ConnectingPath from '@/components/ConnectingPath'
import requirementTypesApi from '@/api/requirementTypesApi'
import RequirementDescription from '@/components/RequirementDescription'
import AggregateTypeSelector from '@/components/AggregateTypeSelector'
import BpmnEvent from '@/components/BpmnEvent'
import BpmnSwimLaneTitle from '@/components/BpmnSwimLaneTitle'
import TextEditor from '@/components/TextEditor.vue'
import { diagramHelpers } from '@/mixins/diagramHelpers'
import { mapState, mapGetters } from 'vuex'
import WorkflowAggregateSelectBoundedContext from './WorkflowAggregateSelectBoundedContext.vue'

export default {
  mixins: [diagramHelpers],
  components: {
    ConnectingLine,
    ConnectingPath,
    RequirementDescription,
    AggregateTypeSelector,
    TextEditor,
    BpmnEvent,
    BpmnSwimLaneTitle,
    WorkflowAggregateSelectBoundedContext
  },
  props: [
    'system',
    'systemIndex',
    'showFields',
    'showEntities',
    'boundedContexts',
    'refreshTrigger'
  ],
  data () {
    return {
      open: false,
      svgGraph: null,
      svgLanes: null,
      refreshKey: 0
    }
  },
  computed: {
    ...mapState({
      requirementTypes: state => state.requirementTypes.requirementTypes,
      workflow: state => state.workflow.workflow
    }),
    ...mapGetters({
      loadingState: 'loading/getLoadingState',
      svgGraphData: 'svgGraphData/svgGraphData',
      svgLanesData: 'svgGraphData/svgLanesData'
    }),

    systemCardType () {
      const cardType = this.requirementTypes?.find(type => type.aggregateType)
      return cardType
    },

    triggerCardType () {
      const cardType = this.requirementTypes?.find(type => type.triggerType)
      return cardType
    },

    roleDefinition () {
      return this.workflow.roleDefinition
    },

    disabled () {
      return this.$store.state.isWorkflowDisabled
    },

    findAllDimensionsInCurrentSystem () {
      const res = []
      const dataModels = this.workflow.dataModels || []
      for (const dataModel of dataModels) {
        for (const event of this.system.events) {
          if (dataModel.connectedToEvents.includes(event.id)) {
            res.push(dataModel)
            break
          }
        }
      }
      return res
    }
  },
  methods: {
    cardTypeColor (cardType) {
      // return '#868686'
      return requirementTypesApi.getBackgroundColour(cardType)
    },
    getDataFieldsFromEvent (event) {
      const dataModel = event.dataModels?.find(model => model.type === 'Event Story')
      return dataModel?.dataFields || []
    },
    getBackgroundColour (cardType) {
      return requirementTypesApi.getBackgroundColour(cardType)
    },
    async submitCardForm (eventId, cardTypeId, text) {
      this.textAreaFormLoading = true
      await workflowApi.createRequirementJson(this.$route.params.id, eventId, {
        cardTypeId,
        description: text
      })
    },

    updateSystemDescription (html, _, systemIndex) {
      const itemIds = this.system.systemCardIds
      if (itemIds?.length > 0) {
        const update = itemIds.map(itemId => {
          return { requirementId: itemId, description: html }
        })
        workflowApi.updateRequirementsJsonMutation(this.$route.params.id, update)
      }
    },

    async roleBlur (event, roleIndex) {
      const currentRole = this.system.roles[roleIndex]
      const newRoleName = event.target.innerText
      if (newRoleName === currentRole.name) return
      if (newRoleName === '') {
        event.target.innerText = currentRole.name
        return
      }

      let events, updateNote
      const updatedSvgLanes = { ...this.svgLanes }
      const mergeWithRole = Object.values(this.svgLanes).find(lane => lane.name === newRoleName)
      if (mergeWithRole) {
        events = this.system.events.filter(event => event.laneId === currentRole.id)
        events.forEach(event => {
          this.svgGraph[event.id].y = mergeWithRole.y + 30
          this.svgGraph[event.id].laneId = mergeWithRole.id
          this.svgGraph[event.id].laneIndex = mergeWithRole.index
        })
        updateNote = {
          action: 'moved events',
          target: events.map(it => it.name).join(', ')
        }
      } else {
        updatedSvgLanes[currentRole.id].name = newRoleName
        updateNote = {
          action: 'updated lane name',
          target: newRoleName
        }
      }
      await workflowApi.updateWorkflow({
        svgGraph: this.svgGraph,
        svgLanes: this.svgLanes,
        workflow: this.workflow,
        updateNote
      })
    },
    starDimensions (columnName, eventId, boundedContext, notInBoundedContext = false) {
      const res = []
      let index = 0
      if (this.workflow.dataModels) {
        let dataModels
        if (notInBoundedContext) {
          dataModels = this.findDimensionsNotInBoundedContext(boundedContext, eventId)
        } else {
          dataModels = this.findDimensionsInBoundedContext(boundedContext, eventId)
        }
        for (const dataModel of dataModels) {
          if (index % 2 === 0 && columnName === 'left') {
            res.push({ ...dataModel.dataFields, dimensionName: dataModel.name, boundedContext: dataModel.boundedContext })
          } else if (index % 2 !== 0 && columnName === 'right') {
            res.push({ ...dataModel.dataFields, dimensionName: dataModel.name, boundedContext: dataModel.boundedContext })
          }
          index++
        }
      }
      return res
    },
    findDimensionsInBoundedContext (boundedContext, eventId) {
      return this.findAllDimensionsInCurrentSystem?.filter(model => model.boundedContext === boundedContext)
    },
    findDimensionsNotInBoundedContext (boundedContext, eventId) {
      const res = this.findAllDimensionsInCurrentSystem?.filter(model => model.boundedContext !== boundedContext)
      return res
    }
  },
  watch: {
    svgGraphData: {
      handler: async function (newValue) {
        this.svgGraph = JSON.parse(JSON.stringify(newValue))
      },
      immediate: true
    },
    svgLanesData: {
      handler: async function (newValue) {
        this.svgLanes = JSON.parse(JSON.stringify(newValue))
      },
      immediate: true
    },
    refreshTrigger: {
      handler: function () {
        this.refreshKey++
      }
    }
  }
}
</script>

<style scoped>

.service-border {
  background-color: rgb(var(--v-theme-surface));
  border: 1px solid gray;
  min-width: 350px;
}

.integration-divider {
  font-size: 12px;
  width: 100%;
  background-color: gray;
  text-align: center;
}

.col-title {
  font-weight: bold;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  user-select: none;
}

.col-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.field-name {
  text-transform: lowercase;
  font-size: 13px;
}

.field-name::first-letter {
  text-transform: uppercase;
}

button {
  border-style: solid !important;
  border-width: 2px !important;
}
.requirement-card-body {
    border-width: 1px;
    border-top-width: 8px;
    border-style: solid;
    width: 100%;
  }
</style>
