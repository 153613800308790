<template>
  <td @click="setFocus()">
    <v-menu v-model="menu" width="500" :scrim="false" :close-on-content-click="false">
      <template v-slot:activator="{ on, value }">
        <v-btn
          :disabled="$store.state.isWorkflowDisabled"
          v-on:click="on.click"
          v-on:keydown="value ? on.keydown($event) : keydownCell($event)"
          class="table-button"
          color="white"
          style="height: 100%;"
        >
          <pre ref="cell" :tabindex="tab" class="text-left" style="font-family: 'Roboto';">{{ value ? value : '...' }}</pre>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h6 bg-grey-lighten-3">
          Select dimension or fact
        </v-card-title>
        <v-layout wrap>
          <v-col cols="8"></v-col>
          <v-col cols="4">
            <v-text-field
              class="setting-search-field"
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details>
            </v-text-field>
          </v-col>
        </v-layout>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          hide-default-footer
          class="elevation-1"
          disable-pagination
        >
          <template v-slot:bottom></template>
          <template v-slot:item slot-scope="props">
            <tr @click="sourceMenu = {}; clickDataSource(indexDataModel, indexDataField, props.item.entity)">
              <td>{{ props.item.entity }}</td>
              <td>{{ props.item.keyType }}</td>
              <td>{{ props.item.sourceSystem }}</td>
              <td>{{ props.item.table }}</td>
              <td>{{ props.item.type }}</td>
            </tr>
          </template>
        </v-data-table>
        <!-- <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="sourceMenu[indexDataField] = false">&nbsp;&nbsp;&nbsp;Add new&nbsp;&nbsp;&nbsp;</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-menu>
  </td>
</template>

<script>
export default {
  name: 'beam-table-cell-select-from-table',

  props: {
    value: String,
    headers: Array,
    items: Array,
    tab: Number
  },

  data: () => ({
    menu: false,
    search: ''
  }),

  methods: {
    keydownCell (event) {
      switch (event.keyCode) {
        case 37: // left
        case 38: // up
        case 39: // right
        case 40: // down
          this.$emit('leave-cell', event)
      }
      event.stopPropagation()
    },
    setFocus () {
      this.$refs.cell.focus()
    }
  }

}
</script>

<style>
pre {
  outline: none;
}

.table-button {
  padding: 0 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  min-width: 40px !important;
  height: 30px !important;
  text-transform: none !important;
  letter-spacing: normal !important;
}

.table-button:not(.v-btn--depressed):not(.v-btn--flat) {
  box-shadow: none;
}

</style>
