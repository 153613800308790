export default `
  query listTeamMembers ($projectId: String!) {
    listTeamMembers (projectId: $projectId) {
      id
      userId
      email
      projectId
      lastLogin
      user {
        id
        firstname
        lastname
        email
      }
    }
  }
`
