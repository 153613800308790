<template>
  <v-dialog :scrollable="true" max-width="30%" v-model="open" content-class="dialog-top-right">
    <template v-slot:activator="{ props }">
      <v-btn variant="text" icon size="small" v-bind="props" class="mr-2">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>

    <v-card flat>
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title><v-icon> mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>How to use Data Models</b></v-toolbar-title>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <h3>Data Models Overview</h3>
        To gain a deeper understanding of a business process,
        it's helpful to collect sample data.<br /><br />
        <h3><span class="bg-yellow-lighten-4">Event Story</span></h3>
        Start by creating an Event Story.
        Ask users or domain experts for real-world example data
        for each business event. Fill in the Event Story table
        with fields (columns) and examples (rows).<br /><br />
        <h3><span class="bg-red-lighten-4">Fact Table</span></h3>
        The Event Story captures example data as expressed by
        domain experts. With the Fact Table, we move one step closer
        to a physical implementation of the table in a database.
        Each field in the Event Story becomes either a value (fact)
        or an ID pointing to an associated entity.<br /><br />
        <h3><span class="bg-green-lighten-4">Dimensions</span></h3>
        The dimensions represent associated entities referenced by
        the Event Story and Fact Table. It can also be helpful to
        think of the dimensions as details describing the business
        event. Later, when connecting Business Intelligence (BI) to
        analyse events, we typically group the data by the dimensions.
        <br /><br />
        <h3><span>Get started</span></h3>
        To get started, create an Event Story. You can use AI
        to help you get off to a quick start. Then, continue
        with the Fact Table, and finally, move on to Dimensions.
        <br /><br />
        <h3><span>Video</span></h3>
        <a href="https://www.youtube.com/watch?v=vMjWU7MZC0Y" target="_blank">Click here for a video showing the process of creating data models.</a>
        <br />
      </v-card-text><br /><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  }
}
</script>

<style>
</style>
