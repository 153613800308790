<template>
  <v-card class="dashboard-container pa-6 mt-3 mx-0">
    <h3 class="text-h5 font-weight-bold pb-4" >Or start with a demo project</h3>

    <p>This demo project contains several example workflows. By clicking "Start with Demo Project," you'll get a copy of the project named "DEMO". You can edit these examples or copy any workflow and continue working on the copy.</p>

    <v-container fluid v-if="demoWorkflows && demoWorkflows.length">
      <v-row>
        <v-col
          v-for="workflow in demoWorkflows"
          :key="workflow.id"
          cols="6"
        >
          <dashboard-workflow-card
            :workflow="workflow"
            disabled
          ></dashboard-workflow-card>
        </v-col>

        <v-col
          cols="6"
          :class="{
            'd-flex': true,
            'align-end': true,
            'justify-end': true,
            'ml-auto': workflowsNum % 2 === 0
          }"
        >
          <v-btn
            color="primary"
            @click="createDemoProject"
            :loading="loading"
          >
            Start with demo project
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import DashboardWorkflowCard from '@/components/DashboardWorkflowCard'
import { cloneWorkflow } from '@/api/workflowApi'
import { mapState } from 'vuex'
import store from '@/store'

export default {
  components: {
    DashboardWorkflowCard
  },

  props: {
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...mapState({
      organisations: state => state.organisations.organisations,
      demoWorkflows: state => state.workflows.demoWorkflows
    }),
    workflowsNum () {
      return this.demoWorkflows ? this.demoWorkflows.length : 0
    }
  },

  methods: {
    async createDemoProject () {
      let organisation

      if (Array.isArray(this.organisations)) {
        organisation = this.organisations[0]
      } else {
        this.error = 'Cannot find user organisations.'
        return
      }

      this.loading = true

      const projectId = await store.dispatch('project/createProject', {
        name: 'DEMO',
        organisationId: organisation.id
      })
      await Promise.all(
        this.demoWorkflows.map(
          ({ id }) => this.cloneWorkflow(projectId, id)
        )
      )
      this.$router.push('/dashboard/' + projectId).catch(() => { })
      this.loading = false
    },
    cloneWorkflow
  },

  async mounted () {
    try {
      await this.$store.dispatch('workflows/listDemoWorkflows')
    } catch (e) {
      console.log(e)
    }
  }
}
</script>

<style scoped>
.dashboard-container {
  max-width: 800px;
  margin-left: 12px;
  margin-top: 12px;
}
</style>
