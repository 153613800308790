<template>
  <v-card class="dashboard-container pa-6 mt-3 mx-0 mb-4">
    <h3 class="text-h5 font-weight-bold pb-4">Watch our introduction video</h3>
    <v-container fluid v-if="!isCypressRunning()">
      <iframe width="100%" height="518" :src="'https://www.youtube.com/embed/FpX3YIPSHHo'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </v-container>
  </v-card>
</template>

<script>
export default {
  methods: {
    isCypressRunning () {
      return window.Cypress
    }
  }
}
</script>

<style scoped>
.dashboard-container {
  max-width: 800px;
  margin-left: 12px;
  margin-top: 12px;
}
</style>
