import gql from 'graphql-tag'

export default gql`
  mutation createOrganisation($name: String!, $id: String) {
    createOrganisation(name: $name, id: $id) {
        id
        name
        userId
        user {
          id
          firstname
          lastname
        }
        projects {
          id
          name
        }
        createdAt
        updatedAt
      }
  }
`
