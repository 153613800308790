<template>
  <img :src="src" :alt="title" class="attachment-media-image" />
</template>

<script>
export default {
  props: {
    title: String,
    src: String
  }
}
</script>

<style>
  .attachment-media-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
</style>
