export default `
  query ListBCObjects($boundedContextId: String!) {
    listBCObjects(boundedContextId: $boundedContextId) {
      id
      boundedContextId
      filepath
      prompt
      contents
    }
  }
`
