export default `
  mutation updateRequirementsJson($projectId: String!, $input: UpdateRequirementsJsonInput!) {
    updateRequirementsJson(projectId: $projectId, input: $input) {
      workflowId
      version
      updatedAt
      updateNote {
        userRealName
        action
        target
      }
      requirements {
        requirementId
        requirementIndex
        description
        sortkey
        cardTypeId
        releaseNo
        done
        version
        eventId
        eventIndex
        boundedContext
      }
    }
  }
`
