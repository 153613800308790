<template>
  <td @click="setFocus()">
    <div class="d-flex align-center flex-row"
         ref="cell"
         :id="tab"
         :tabindex="tab"
         @keydown="keydownCell">
      <v-chip-group
        v-model="selection"
        selected-class="chip-selected"
        multiple
      >
        <v-chip v-for="(option, index) in tagsOptions"
                :disabled="$store.state.isWorkflowDisabled"
                :key="index"
                :value="option"
                :color="selection && selection.includes(option) ? 'secondary' : ''"
                class="chip-option"
                size="x-small"
                >
          {{capitalizeFirstLetter(option)}}
        </v-chip>
      </v-chip-group>
    </div>
  </td>
</template>

<script>
export default {
  name: 'beam-table-cell-checkbox',

  props: {
    tags: Array,
    tab: Number
  },

  computed: {
    selection: {
      get: function () { return this.tags || [] },
      set: function (value) { this.$emit('set-tags', value) }
    }
  },

  data: function () {
    return {
      tagsOptions: [
        'READ',
        'WRITE',
        'CREATE'
      ]
    }
  },

  methods: {
    keydownCell (event) {
      switch (event.keyCode) {
        case 37: // left
        case 38: // up
        case 39: // right
        case 40: // down
          this.$emit('leave-cell', event)
          break
        case 67: // c
          this.addTagToSelection(2)
          break
        case 82: // r
          this.addTagToSelection(0)
          break
        case 87: // w
          this.addTagToSelection(1)
          break
      }
      event.stopPropagation()
      event.preventDefault()
    },
    setFocus () {
      if (this.tab) {
        document.getElementById(`${this.tab}`).focus()
      }
    },
    addTagToSelection (tagOptionIndex) {
      if (this.selection.includes(this.tagsOptions[tagOptionIndex]) === false) {
        this.selection.set([...this.selection, this.tagsOptions[tagOptionIndex]])
      } else {
        this.selection.set(this.selection.filter(e => e !== this.tagsOptions[tagOptionIndex]))
      }
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    }
  }

}
</script>

<style>
.chip-option {
  padding: 5px 5px;
}
</style>
