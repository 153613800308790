<template>
  <v-dialog :scrollable="true" max-width="30%" v-model="open">
    <template #activator="{ props }">
      <slot :props="props"></slot>
    </template>

    <v-card flat>
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title><v-icon>mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>Which AI version to use?</b></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <h3>Get help by our AI copilot</h3>
        You can choose between two different versions of ChatGPT: 3.5 or 4.
        GPT-4 is trained with newer data, is smarter, handles longer prompts
        and conversations more effectively, makes fewer factual errors, and
        includes many new safeguards.
        <br />
        <br />
        <strong>Note</strong>: Generating the workflow using version 4 may take a little longer.
        <br />
        <br />
        You can also select a model that has been trained with business process datasets containing some of the most common processes for enterprises, called <strong>Qlerify</strong>.
      </v-card-text><br /><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['openedFromSettings'],
  data () {
    return {
      open: false
    }
  }
}
</script>

<style>
.v-dialog .help-ai {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 30%;
  overflow-y: scroll;
}
</style>
