import store from '@/store'
import recentWorkflowsApi from './recentWorkflowsApi'
import { workflowApiHelpers } from './workflowApiHelpers'
import cardHelpers from '@/helpers/cardHelpers'
import Checks from '@/helpers/checks'

export default class WorkflowApi {
  static getWorkflowFromWorkflows (workflowId) {
    if (workflowId === store.state.workflow.workflow?.id) {
      return store.state.workflow.workflow
    } else {
      const index = store.state.workflows.workflows.findIndex(workflow => workflow.id === workflowId)
      return store.state.workflows.workflows[index]
    }
  }

  static async createWorkflow (workflow, silent = false) {
    return await store.dispatch('workflows/createWorkflow', { workflow, silent })
  }

  static async updateWorkflowMutation (projectId, input) {
    await store.dispatch('workflow/updateWorkflow', { projectId, input })
  }

  static async updateWorkflowNoHistoryMutation (projectId, input) {
    await store.dispatch('workflow/updateWorkflowNoHistory', { projectId, input })
  }

  // this function can handle muliple changes to the diagram,
  // it exctracts changes done to the svgGraph and svgLanes
  // arguments and updates the database accordingly
  static async updateWorkflow ({ svgGraph = null, svgLanes = null, svgGroups = null, workflowName = null, dataModels = null, workflow, updateNote }) {
    const events = workflow?.eventsJson
    workflowApiHelpers.refreshDiagram(svgGraph, svgLanes, svgGroups)
    if (svgGraph !== null) {
      const { errors, errorList } = Checks.checkDiagramConsistency(svgGraph, svgLanes, svgGroups, events)
      if (errors !== 0) {
        store.commit('snackbar/showMessage', { content: 'Error updating workflow, try again or see log', timeout: 6000, color: 'red', centered: true }, { root: true })
        console.error('Failed with ' + errors + ' errors. ' + errorList.toString())
        return
      }
    }
    let eventsInput, lanesInput, groupsInput, deleteIndex, deleteVersion, deleteId, deletedParents
    if (svgGraph) {
      // check if event has been deleted
      ({ deleteIndex, deleteVersion, deleteId, deletedParents } = workflowApiHelpers.findDeletedEvent(svgGraph, workflow))
      const svgGraphUpdatedNodes = workflowApiHelpers.findUpdatedNodes(svgGraph, workflow, svgLanes)
      // add parents of deleted event to updated nodes (to ensure database consistency)
      if (deletedParents) {
        for (const parent of deletedParents) {
          if (!svgGraphUpdatedNodes[parent] && parent !== 'start') {
            svgGraphUpdatedNodes[parent] = {
              id: svgGraph[parent].id,
              index: workflow.eventsJson.findIndex(event => event.id === svgGraph[parent].id),
              version: svgGraph[parent].version
            }
          }
        }
      }
      eventsInput = Object.entries(svgGraphUpdatedNodes)
      eventsInput = eventsInput.map(([, node]) => {
        // we use index -2 to indicate that the workflow is empty
        // and -1 to indicate that we add an event
        // and any other index to indicate tha we update an existing event
        let index = -2
        if (workflow.eventsJson?.length > 0) {
          index = workflow.eventsJson.findIndex(event => event.id === node.id)
        }
        return {
          id: node.id,
          index,
          description: node.name,
          laneId: node.laneId,
          laneIndex: node.laneIndex,
          groupId: index >= 0 ? node.groupId : undefined,
          type: node.type,
          conditionLabel: node.conditionLabel,
          conditionLabels: node.conditionLabels ? node.conditionLabels.map(item => ({ parentId: item.parentId, name: item.name })) : undefined,
          parents: node.parents,
          offset: node.offset,
          dataModels: node.dataModels,
          requirementsJson: node.requirementsJson,
          color: node.color || undefined,
          subprocess: node.subprocess?.id ? node.subprocess.id : (node.subprocess || (node.subprocess === null ? null : undefined)),
          expectedVersion: node.version || undefined
        }
      })
    }
    if (svgLanes) {
      lanesInput = this.findUpdatedLanes(svgLanes, workflow)
    }
    if (svgGroups) {
      groupsInput = this.findUpdatedGroups(svgGroups, workflow)
    }
    if (lanesInput || groupsInput || eventsInput?.length > 0 || workflowName || deleteIndex >= 0 || dataModels) {
      await this.updateWorkflowMutation(
        workflow.projectId,
        {
          id: workflow.id,
          name: workflowName || undefined,
          eventsJson: eventsInput || undefined,
          lanes: lanesInput || undefined,
          groups: groupsInput || undefined,
          expectedVersion: workflow.version,
          deleteIndex: deleteIndex >= 0 ? deleteIndex : undefined,
          deleteVersion: deleteVersion || undefined,
          deleteId: deleteId || undefined,
          dataModels: dataModels || undefined,
          updateNote
        }
      )
    }
  }

  // dataModelsOnEvents are Event Stories & Fact Tables
  // dataModelsOnWorkflow are Dimensions
  static async updateDataModels ({ eventId, dataModelsOnEvent, dataModelsOnWorkflow, workflow }) {
    if (!eventId) {
      throw new Error('eventId is missing')
    }
    const eventIndex = workflow.eventsJson.findIndex(ev => ev.id === eventId)
    if (eventIndex === -1) {
      throw new Error('Event not found')
    }
    const event = workflow.eventsJson[eventIndex]
    const inputNodes = []
    inputNodes.push({
      id: eventId,
      dataModels: dataModelsOnEvent || undefined,
      expectedVersion: event.version,
      index: eventIndex
    })
    // add parents to the mutation for server side validation
    for (const parentId of event?.parents || []) {
      const index = workflow.eventsJson.findIndex(ev => ev.id === parentId)
      if (index >= 0) {
        inputNodes.push({
          id: workflow.eventsJson[index].id,
          expectedVersion: workflow.eventsJson[index].version,
          index
        })
      }
    }
    if (inputNodes.length > 0) {
      await store.dispatch('workflow/updateWorkflow', {
        projectId: workflow.projectId,
        input: {
          id: workflow.id,
          eventsJson: inputNodes || undefined,
          expectedVersion: workflow.version,
          dataModels: dataModelsOnWorkflow || undefined,
          updateNote: {
            action: 'updated data models',
            target: ''
          }
        }
      })
    }
  }

  static async undo (workflow) {
    // if sameAsVersion is set, then we are in a restored version
    const restoreVersion = workflow.sameAsVersion ? (workflow.sameAsVersion - 1) : workflow.version - 1
    WorkflowApi.restorePreviousWorkflowVersion(restoreVersion, workflow)
  }

  static async redo (workflow) {
    if (workflow.sameAsVersion > 0 && workflow.sameAsVersion < (workflow.version - 1)) {
      const restoreVersion = (workflow.sameAsVersion + 1)
      WorkflowApi.restorePreviousWorkflowVersion(restoreVersion, workflow)
    }
  }

  static async restorePreviousWorkflowVersion (restoreVersion, workflow) {
    if (restoreVersion >= 1) {
      await store.dispatch('workflow/restorePreviousWorkflowVersion', { restoreVersion, workflow })
    }
  }

  static async moveRequirementJson (workflowId, oldEventId, newEventId, requirementId, release = undefined, sortkey = undefined) {
    const workflow = await this.getWorkflowFromWorkflows(workflowId)
    const { requirementIndex, eventIndex } = workflowApiHelpers.getIndexesAndEventFromRequirementId(workflow, requirementId)
    const input = {
      workflowId: workflow.id,
      expectedVersion: workflow.version,
      oldEventId,
      oldEventIndex: eventIndex,
      newEventId,
      newEventIndex: workflow.eventsJson.findIndex(event => event.id === newEventId),
      requirementId,
      requirementIndex,
      release,
      sortkey
    }
    return await store.dispatch('workflow/moveCard', { input })
  }

  static getUpdateNoteFromRequirements (requirements) {
    if (requirements.length === 1) {
      if (requirements[0].updateNote) {
        return requirements[0].updateNote
      } else {
        return { action: 'updated card', target: requirements[0].description }
      }
    } else {
      return {
        action: 'updated ' + requirements.length + ' requirements',
        target: requirements.map(req => req.description).join('\n\n')
      }
    }
  }

  static async createRequirementJson (workflowId, eventId, requirement) {
    const workflow = await this.getWorkflowFromWorkflows(workflowId)
    const eventIndex = workflow.eventsJson.findIndex(event => event.id === eventId)
    const input = {
      workflowId: workflow.id,
      expectedVersion: workflow.version,
      updateNote: {
        action: 'added card',
        target: requirement.description
      },
      requirements: [{
        description: requirement.description,
        done: requirement.done || false,
        cardTypeId: requirement.cardTypeId,
        releaseNo: requirement.releaseNo,
        requirementIndex: -1,
        sortkey: requirement.sortkey || cardHelpers.newSortkeyLastInList(workflow),
        expectedVersion: requirement.version,
        eventId,
        eventIndex,
        boundedContext: requirement.boundedContext
      }]
    }

    return await store.dispatch('workflow/updateCards', { input })
  }

  static async updateRequirementsJsonMutation (workflowId, requirementsInput) {
    const workflow = await this.getWorkflowFromWorkflows(workflowId)
    const updateNote = this.getUpdateNoteFromRequirements(requirementsInput)
    const input = {
      workflowId: workflow.id,
      expectedVersion: workflow.version,
      updateNote,
      requirements: []
    }
    for (let requirementInput of requirementsInput) {
      const { eventId, eventIndex, requirementIndex, requirement } = workflowApiHelpers.getIndexesAndEventFromRequirementId(workflow, requirementInput.requirementId)
      requirementInput = Object.assign({ ...requirement }, requirementInput, { requirementIndex })
      input.requirements.push({
        description: requirementInput.description,
        done: requirementInput.done,
        requirementId: requirementInput.requirementId,
        cardTypeId: requirementInput.cardTypeId || requirement.cardTypeId,
        releaseNo: requirementInput.releaseNo === undefined ? requirement.releaseNo : requirementInput.releaseNo,
        requirementIndex: requirementInput.requirementIndex,
        sortkey: requirementInput.sortkey,
        expectedVersion: requirementInput.version,
        eventId,
        eventIndex,
        boundedContext: requirementInput.boundedContext
      })
    }
    return await store.dispatch('workflow/updateCards', { input })
  }

  static async deleteRequirementJson (workflowId, deleteRequirementInput, firstAttempt = true) {
    const workflow = await this.getWorkflowFromWorkflows(workflowId)
    const { eventId, eventIndex, requirementIndex, requirement } = workflowApiHelpers.getIndexesAndEventFromRequirementId(workflow, deleteRequirementInput.requirementId)
    const input = {
      projectId: workflow.projectId,
      workflowId: workflow.id,
      eventId,
      eventIndex,
      requirementId: deleteRequirementInput.requirementId,
      requirementIndex,
      expectedVersion: workflow.version,
      requirementDescription: requirement.description
    }
    return await store.dispatch('workflow/deleteCard', { input })
  }

  static async addEventToWorkflow (shape, options, svgLanes, svgGraph, svgGroups, workflow) {
    // UI update
    const newEventId = workflowApiHelpers.addNewShapeToSvgGraphAndAlignAllNodes(
      shape,
      options,
      svgLanes,
      svgGraph,
      svgGroups
    )
    // action to be displayed in the update note
    let action
    if (shape.type === 'bpmn:ExclusiveGateway') {
      action = 'added decision'
    } else {
      if (shape.subprocess) {
        action = 'added subprocess'
      } else {
        action = 'added event'
      }
    }
    // backend update
    await this.updateWorkflow({
      svgGraph,
      svgLanes,
      svgGroups,
      workflow,
      updateNote: {
        action,
        target: shape.description
      }
    })
    return newEventId
  }

  static async deleteEventFromWorkflow (eventId, svgGraphData, svgLanesData, svgGroupsData, workflow) {
    // UI update
    // mutate copies of the computed svgGraphData & svgLanesData
    const svgGraph = JSON.parse(JSON.stringify(svgGraphData))
    const svgLanes = JSON.parse(JSON.stringify(svgLanesData))
    const svgGroups = JSON.parse(JSON.stringify(svgGroupsData))
    const event = svgGraph[eventId]
    const name = event.name
    workflowApiHelpers.deleteEventFromDiagram(event.id, svgGraph, svgGroups)

    // action to be displayed in the update note
    let action
    switch (event.type) {
      case 'bpmn:Task':
        if (event.subprocess) {
          action = 'deleted subprocess'
        } else {
          action = 'deleted event'
        }
        break
      case 'bpmn:ExclusiveGateway':
        action = 'deleted decision'
        break
    }

    // backend update
    await WorkflowApi.updateWorkflow({
      svgGraph,
      svgGroups,
      svgLanes,
      workflow,
      updateNote: {
        action,
        target: name
      }
    })
  }

  static async deleteConnectionFromEvent (parentId, childId, svgGraph, svgLanes, svgGroups, workflow) {
    // UI update
    workflowApiHelpers.deleteConnectionFromEvent(parentId, childId, svgGraph, svgGroups)
    // backend update
    await this.updateWorkflow({
      svgGraph,
      svgLanes,
      svgGroups,
      workflow,
      updateNote: {
        action: 'deleted connection to',
        target: name
      }
    })
  }

  static async addConnectionToEvent (sourceEventId, targetEventId, svgGraph, svgLanes, svgGroups, workflow) {
    // UI update
    workflowApiHelpers.addConnectionToEvent(sourceEventId, targetEventId, svgGraph, svgGroups)

    // backend update
    this.updateWorkflow({
      svgGraph,
      svgLanes,
      svgGroups,
      workflow,
      updateNote: {
        action: 'added connection to',
        target: svgGraph[targetEventId].name
      }
    })
  }

  static updateGroupStartingPosition (groupId, svgGraph, svgLanes, svgGroups, workflow) {
    // UI update
    workflowApiHelpers.updateGroupStartingPosition(svgGraph, svgGroups)

    // backend update
    this.updateWorkflow({
      svgGraph,
      svgLanes,
      svgGroups,
      workflow,
      updateNote: {
        action: 'moved group',
        target: svgGroups[groupId].name
      }
    })
  }

  static findUpdatedLanes (svgLanes, workflow) {
    let lanesInput = Object.entries(svgLanes)
    let index = 0
    let dirtyFlag = false
    for (const [id, lane] of lanesInput) {
      if (workflow.lanes?.[index]?.id !== id || workflow.lanes?.[index]?.name !== lane.name) {
        dirtyFlag = true
      }
      index++
    }
    if (!dirtyFlag) {
      if (workflow.lanes?.length !== lanesInput?.length) {
        dirtyFlag = true
      }
    }
    if (dirtyFlag) {
      lanesInput = lanesInput.map(([, lane]) => ({
        id: lane.id,
        name: lane.name
      }))
    } else {
      lanesInput = undefined
    }
    return lanesInput
  }

  static findUpdatedGroups (svgGroup, workflow) {
    let groupsInput = Object.entries(svgGroup)
    let index = 0
    let dirtyFlag = false
    for (const group of Object.values(svgGroup)) {
      const oldGroup = workflow.groups?.[index]
      if (!oldGroup ||
          oldGroup.name !== group.name ||
          oldGroup.startSlotX !== group.startSlotX) {
        dirtyFlag = true
      }
      index++
    }
    if (!dirtyFlag) {
      if (workflow.groups?.length !== groupsInput?.length) {
        dirtyFlag = true
      }
    }
    if (dirtyFlag) {
      groupsInput = groupsInput.map(([, group]) => ({
        id: group.id,
        name: group.name,
        startSlotX: group.startSlotX
      }))
    } else {
      groupsInput = undefined
    }
    return groupsInput
  }

  static deleteGroup (group, svgGraph, svgLanes, svgGroups, workflow) {
    // UI update
    workflowApiHelpers.deleteGroup(group.id, svgGraph, svgGroups)

    // backend update
    this.updateWorkflow({
      svgGraph,
      svgLanes,
      svgGroups,
      workflow,
      updateNote: {
        action: 'deleted group',
        target: group.name
      }
    })
  }
}

export async function deleteWorkflow (workflowId, projectId) {
  const del = confirm('Are you sure you want to delete this workflow including all of its content such as events, cards, comments, votes, etc? This operation cannot be undone.')
  if (del) {
    store.commit('loading/startLoading')
    await store.dispatch('workflows/deleteWorkflow', { workflowId, projectId })
    recentWorkflowsApi.delete(workflowId)
    store.commit('loading/stopLoading')
  }
}

export async function cloneWorkflow (targetProjectId, workflowId) {
  return await store.dispatch('workflows/cloneWorkflow', { targetProjectId, workflowId })
}
