<template>
  <v-container v-if="currentUser" wrap class="ma-0 px-0">
    <v-row no-gutters>
      <v-col>
        <v-alert v-if="message" color="success">
          {{ message }}
        </v-alert>
        <v-alert v-if="error" color="error">
          {{ error }}
        </v-alert>
        <v-card v-if="!editing" class="profile d-flex flex-column">
          <v-card-text class="flex-grow-1 ml-2">
            <v-row>
              <v-col>
                <br />
                <div class="profile-pic-container">
                  <v-avatar class="profile-pic" size="100">
                    <div v-if="currentUser.picture && currentUser.picture.url" class="pic-container">
                      <img :src="currentUser.picture.url" alt="Profile Picture" class="pic">
                    </div>
                    <div v-else class="pic-container">
                      <v-icon color="grey" size="100">mdi-account-circle</v-icon>
                    </div>
                  </v-avatar>
                </div>
                <h3 class="mt-3">{{ currentUser.firstname + ' ' + currentUser.lastname }}</h3>
                <div class="text-body-2">{{ currentUser.email }}</div>
                <div class="text-body-2" v-if="currentUser.phoneNumber">{{ currentUser.phoneNumber }}</div>
                <div class="text-body-2" v-if="currentUser.job">{{ currentUser.job }}</div>
                <div class="text-body-2">{{ currentUser.company }}</div>
              </v-col>
              <v-col>
                <div shrink>
                  <v-btn class="edit-btn" icon variant="text" @click="editing = true">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="align-end mt-3">
            <v-btn color="primary" class="my-2 mr-2 ml-2" @click="logout">Logout</v-btn>
            <v-btn class="my-2" @click="dialog = true" color="primary">Change Password</v-btn>
          </v-card-actions>
        </v-card>

        <v-card v-if="editing" @keydown.enter="updateProfile" class="edit-profile">
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid">
                <v-container>
                  <v-row class="pb-2">
                    <v-col cols="10" class="pa-0">
                      <div class="edit-profile-pic-container">
                        <v-avatar class="profile-pic" size="150">
                          <div v-if="picture !== null && picture.url !== null" class="pic-container">
                            <img :src="picture.url" alt="Profile Picture" class="pic"
                              @click="showDropdown = !showDropdown">
                            <div>
                              <v-menu v-model="showDropdown" class="drop-down-menu" >
                                <template v-slot:activator="{ props }">
                                  <div class="camera-icon" v-bind="props">
                                    <v-icon color="white">mdi-camera</v-icon>
                                  </div>
                                </template>
                                <v-list>
                                  <v-list-item @click="openFilePicker">Change Profile Picture</v-list-item>
                                  <v-list-item @click="deletePicture">Delete Profile Picture</v-list-item>
                                </v-list>
                              </v-menu>
                            </div>
                          </div>
                          <div v-else class="pic-container">
                            <v-icon color="grey" size="150" @click="openFilePicker">mdi-account-circle</v-icon>
                            <div class="camera-icon" @click="openFilePicker">
                              <v-icon color="white">mdi-camera</v-icon>
                            </div>
                          </div>
                          <input
                            type="file"
                            ref="fileInput"
                            accept="image/*"
                            style="display: none;"
                            @change="handleFileUpload"
                          />
                        </v-avatar>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pb-2">
                    <v-col cols="6" class="pa-0">
                      <v-text-field
                        id="first-name"
                        color="secondary"
                        autofocus
                        name="firstname"
                        label="First Name"
                        variant="solo"
                        validate-on="blur"
                        v-model="firstname"
                        :maxLength="40"
                        :rules="[rules.required]"
                        hint="At most 40 characters"
                      ></v-text-field>
                    </v-col>
                    <v-col col="6" class="pa-0 ml-4">
                      <v-text-field
                        id="last-name"
                        color="secondary"
                        autofocus
                        label="Last Name"
                        variant="solo"
                        validate-on="blur"
                        v-model="lastname"
                        :maxLength="40"
                        :rules="[rules.required]"
                        hint="At most 40 characters"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pb-2">
                    <v-col class="pa-0" cols="6">
                      <v-text-field
                        id="phone-number"
                        color="secondary"
                        autofocus
                        label="Phone Number"
                        variant="solo"
                        validate-on="blur"
                        v-model="phoneNumber"
                        :rules="[rules.phoneNumber]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pb-2">
                    <v-col class="pa-0" cols="6">
                      <v-text-field
                        id="company"
                        color="secondary"
                        autofocus
                        label="Company"
                        variant="solo"
                        validate-on="blur"
                        v-model="company"
                        maxLength="40"
                        ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pb-2">
                    <v-col class="pa-0" cols="6">
                      <v-text-field
                        id="job-title"
                        color="secondary"
                        autofocus
                        label="Team/Job Title"
                        variant="solo"
                        validate-on="blur"
                        v-model="job"
                        maxLength="40"
                        ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-card-actions class="justify-end">
                    <v-btn color="gray" @click="closeEditing">Cancel</v-btn>
                    &nbsp;&nbsp;
                    <v-btn color="primary" variant="elevated" :loading="loading" @click="updateProfile">Save</v-btn>
                  </v-card-actions>
                </v-container>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-container fluid justify-space-between align-top class="d-flex flex-row">
              <v-card-title class="flex-grow-1">
                <span class="text-h5">
                  <p>Change password</p>
                </span>
              </v-card-title>
              <div class="flex-shrink-1">
                <v-btn variant="text" icon color="grey" @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-container>
            <v-card-text>
              <v-container grid-list-md>
                <v-form ref="form" v-model="valid">
                  <v-container class="pt-0" wrap>
                    <v-text-field
                      color="secondary"
                      variant="underlined"
                      prepend-icon="mdi-lock"
                      name="password"
                      label="Old password"
                      v-model="oldpass"
                      :append-icon="e1 ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="() => (e1 = !e1)"
                      :type="e1 ? 'password' : 'text'" min="8"
                      :rules="[rules.required]"
                      hint="At least 8 characters" counter
                    ></v-text-field>
                    <v-text-field
                      color="secondary"
                      variant="underlined"
                      prepend-icon="mdi-lock"
                      name="newpassword"
                      label="New password"
                      v-model="newpass"
                      :append-icon="e1 ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="() => (e1 = !e1)"
                      :type="e1 ? 'password' : 'text'"
                      min="8"
                      :rules="[rules.required]"
                      hint="At least 8 characters, requires a mix of characters and numbers"
                      counter
                    ></v-text-field>
                    <v-card-actions class="pt-4">
                      <v-spacer></v-spacer>
                      <v-btn color="gray" @click="dialog = false">Cancel</v-btn>
                      &nbsp;&nbsp;
                      <v-btn color="primary" variant="elevated" id="change-password" @click="updatePassword">Change</v-btn>
                    </v-card-actions>
                  </v-container>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Storage from '@/storage'
import store from '@/store'
import { authHelpers } from '@/api/authHelpers'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      editing: false,
      dialog: false,
      valid: true,
      loading: false,
      showDropdown: false,
      pictureDeletionRequested: false,
      oldpass: '',
      newpass: '',
      error: null,
      message: null,
      id: '',
      firstname: '',
      lastname: '',
      email: '',
      phoneNumber: null,
      company: null,
      job: '',
      picture: null,
      oldPicKey: '',
      storage: null,
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        phoneNumber: (value) => {
          const pattern = /^\+[1-9]\d{1,14}$/
          return !value || pattern.test(value) || 'Invalid phone number. Must start with a + sign followed by country code and numbers without spaces or punctuation'
        }
      },
      e1: true
    }
  },
  mounted () {
    this.storage = new Storage()
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.user
    })
  },
  methods: {
    logout () {
      authHelpers.logoutAndClearStore()
      this.$nextTick(function () {
        this.$router.push({ path: '/login' })
      })
    },
    async updatePassword () {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.$cognitoAuth.changePassword(this.oldpass, this.newpass, (err, result) => {
          if (err) {
            this.error = 'Incorrect password. If you created your account using Google, please reset your password on the Login page.'
            console.log(err)
          } else {
            this.updateMessage('Password change successful.')
            this.dialog = false
          }
        })
      }
    },
    updateMessage (newMessage) {
      this.message = newMessage
      setTimeout(() => {
        this.message = ''
      }, 5000)
    },
    async updateProfile () {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.loading = true

        try {
          await this.$cognitoAuth.updateAttributes(this.firstname, this.lastname, this.phoneNumber, this.company)

          this.error = null

          if (this.pictureDeletionRequested) {
            this.picture = {
              name: null,
              url: null,
              file: null,
              mimeType: null
            }
          }
          await this.updateUser()

          if (this.oldPicKey) {
            await this.deletePictureFile(this.oldPicKey)
          }
          this.updateMessage('Profile updated successfully.')
          this.closeEditing()
        } catch (err) {
          this.error = err.message
        } finally {
          this.loading = false
        }
      } else {
        this.error = 'One or more fields are invalid.'
      }
    },
    // Calls apollo mutation that updates user attributes in the store
    async updateUser () {
      const variables = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        phoneNumber: this.phoneNumber,
        company: this.company,
        picture: this.picture,
        job: this.job,
        increaseLoginCounter: false
      }
      await store.dispatch('user/updateUser', variables)
    },
    openFilePicker () {
      this.$refs.fileInput.click()
    },
    async handleFileUpload (event) {
      this.error = ''
      const file = event.target.files[0]

      if (this.pictureDeletionRequested) {
        this.pictureDeletionRequested = false
      }

      if (!this.picIsEmpty(this.picture)) {
        this.oldPicKey = this.picture.file.key
      }

      try {
        const data = await this.storage.uploadFile(
          `profile-pictures/${this.id}/${file.name}`,
          file
        )
        const { Location, Bucket, Key } = data

        this.picture = {
          name: file.name,
          url: Location,
          file: {
            region: Storage.region,
            bucket: Bucket,
            key: Key
          },
          mimeType: file.type
        }
        this.showDropdown = false
      } catch (e) {
        console.log(e)
        this.error = 'Failed to upload file'
      } finally {
        if (!this.error) {
          store.commit('snackbar/showMessage', { content: 'Picture uploaded' }, { root: true })
        }
      }
    },
    async deletePictureFile (key) {
      // Delete previous profile picture
      try {
        await this.storage.deleteFile(key)
      } catch (error) {
        console.error('Error deleting previous profile picture:', error)
        this.error = 'An error occurred while uploading file'
      }
    },
    deletePicture () {
      this.oldPicKey = this.picture.file.key
      this.picture = null
      this.pictureDeletionRequested = true
    },
    picIsEmpty (pic) {
      // true if picture is an empty object or null
      return (pic && !pic.url) || !pic
    },
    closeDialog () {
      this.oldpass = ''
      this.newpass = ''
      this.error = ''
      this.valid = true
    },
    closeEditing () {
      this.error = ''
      this.editing = false
      this.pictureDeletionRequested = false
      this.showDropdown = false
      this.oldPicKey = ''
      this.valid = true
    }
  },
  watch: {
    dialog (val) {
      val || this.closeDialog()
    },
    editing (val) {
      if (val) {
        this.firstname = this.currentUser.firstname
        this.lastname = this.currentUser.lastname
        this.email = this.currentUser.email
        this.phoneNumber = this.currentUser.phoneNumber
        this.company = this.currentUser.company
        this.job = this.currentUser.job
        if (!this.picIsEmpty(this.currentUser.picture)) {
          this.picture = {
            name: this.currentUser.picture.name,
            url: this.currentUser.picture.url,
            file: {
              region: this.currentUser.picture.file.region,
              bucket: this.currentUser.picture.file.bucket,
              key: this.currentUser.picture.file.key
            },
            mimeType: this.currentUser.picture.mimeType
          }
        }
        this.id = this.currentUser.id
      }
    }
  }
}
</script>

<style scoped>
.drop-down-menu .v-menu__content.v-menu__content--active {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.edit-profile-pic-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin-top: 30px;
  margin-bottom: 50px;
  margin-left: 10px;
  overflow: hidden;
  border-radius: 50%;
}

.profile-pic-container {
  width: 100px;
  height: 100px;
}

.pic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.profile-pic {
  position: relative;
  overflow: hidden;
}

.pic {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.camera-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  size: 20px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}

.edit-btn {
  position: absolute;
  top: 10px;
  right: 8px;
}

.profile {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 0px;
  max-width: 600px;
  max-height: 500px;
}

.edit-profile {
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 800px;
  max-height: auto;
}

div.error {
  color: red;
  background: none !important;
  border: none;
}

.required:after {
  content: " *";
  color: red;
}
</style>
