<template>
  <v-dialog
    :scrollable="true"
    max-width="30%"
    v-model="open"
    content-class="dialog-top-right"
  >
    <template v-slot:activator="{ props }">
      <v-btn variant="text" icon size="x-small" v-bind="props" class="mr-2">
        <v-icon> mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>

    <v-card flat>
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title><v-icon> mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>Generate Cards</b></v-toolbar-title>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <h3>Generate cards</h3>
        AI can generate cards for each step in the workflow.
        You can select the number of cards to be generated for
        each type (0 - 3). <template v-if="!settings">The
        card types are configurable under workflow settings (<v-icon icon="mdi-cog-outline" size="x-small"></v-icon> top right).</template>
        <br ><br />
        The name of the card type and the description are used by the AI to generate the cards.
        Use the singular form for the best results.<br /><br />
        <b>Hint: </b>A type marked as 'Aggregate' or 'Command' should only have one card. The available options in this 'Use AI' column will reflect this.<br /><br />
        For example: "<b>A user story describes a feature of a software system that is used in this step of the process.</b>".
      </v-card-text><br /><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    settings: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      open: false
    }
  }
}
</script>

<style>
.v-dialog .help-ai {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 30%;
  overflow-y: scroll;
}
</style>
