<template>
  <!--    :nudge-width="150"-->
  <v-menu
    :disabled="$store.state.isViewOnlyEnabled"
    content-class="workflows-list-menu"
    location="bottom"
    close-delay="500"
  >
    <template v-slot:activator="{ props }">
      <div v-bind="props" style="display: inline-block; cursor: pointer; white-space: nowrap;">
        &nbsp;&nbsp;>
        <v-tooltip location="bottom" open-delay="1000">
          <template v-slot:activator="{ on, props }">
            <span @click="userRenameWorkflow($event)" class="workflow-quick-rename" style="vertical-align: middle;">
              <v-icon size="small" class="ml-2"
              >mdi-file-tree</v-icon
              >
              {{ workflow.name }}
            </span>
            <v-icon v-bind="props" v-on="{ ...on }" size="small" class="hover-effect ml-2"
              >mdi-chevron-down</v-icon
            >
          </template>
          <span v-if="$store.state.isUserLoggedIn">
            Workflow, click here to change workflow
          </span>
          <span v-else>
            Workflow name
          </span>
        </v-tooltip>
      </div>
    </template>

    <v-list v-if="$store.state.isUserLoggedIn">
      <template v-for="(item, index) in workflows" :key="index">
        <v-list-item :key="index + '-heading'" v-if="index == 0">

            <v-list-item-subtitle class="text-black text-decoration-underline"
              >Workflows in project
              <strong>{{ project?.name }}:</strong></v-list-item-subtitle
            >

        </v-list-item>
        <v-list-item
          @click="
            $router.push({
              path: '/workflow/' + item.projectId + '/' + item.id,
            }).catch(() => {})
          "
        >
          <v-list-item-title
            class="text-body-1"
            :class="{ 'font-weight-bold': $route.params.projectId == item.id }"
          >
            <v-icon size="small" class="ml-2">mdi-file-tree</v-icon>
            {{ item.name }}
            <v-icon v-if="$route.params.id == item.id">
              <v-icon size="x-small" color="black">mdi-check</v-icon>
            </v-icon>
          </v-list-item-title>
        </v-list-item>
      </template>
      <v-list-item>
        <v-list-item-title class="d-flex flex-row">
          <workflow-dialog ref="dialogWorkflow">
            <template #activator="{ props }">
              <div
                v-bind="props"
                class="text-decoration-underline text-caption mr-10 v-cursor-pointer"
              >
                Create new workflow
              </div>
            </template>
          </workflow-dialog>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import workflowApi from '@/api/workflowApi'
import WorkflowDialog from '@/components/WorkflowDialog'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    WorkflowDialog
  },
  // data () {},
  computed: {
    ...mapGetters({
      project: 'projectMemberships/activeProject'
    }),
    ...mapState({
      workflows: state => state.workflows.workflows,
      workflow: state => state.workflow.workflow
    })
  },
  methods: {
    userRenameWorkflow (ev) {
      ev.stopPropagation()
      ev.preventDefault()

      if (!this.$store.state.isWorkflowDisabled) {
        this.$emit('rename', this.workflow.name)
      }
    },

    renameWorkflow (newName) {
      newName = (newName || '').trim()
      if (newName.length === 0 || newName === this.workflow.name) {
        return
      }

      if (newName.length > 40) {
        alert('A workflow name must be max 40 characters')
        return
      }

      const index = this.workflows.findIndex(ws => (ws.name === newName))
      if (index >= 0) {
        alert('A workflow with this name already exists.')
        return
      }

      workflowApi.updateWorkflow({
        workflowName: newName,
        updateNote: {
          action: 'changed workflow name to',
          target: this.workflow.name
        },
        workflow: this.workflow
      })
    }
  }
}
</script>

<style>
.workflows-list-menu {
  max-height: calc(100vh - 20px);
}
</style>

<style scoped>
.hover-effect {
  transition: transform 200ms;
}

.hover-effect:hover {
  transform: translate(0, 4px);
}
</style>
