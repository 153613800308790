<template>
  <v-row class="d-flex mx-5 my-0">
  <v-col sm="2" class="col-title text-center">
    <v-tooltip location="top" open-delay="1000">
      <template v-slot:activator="{ on }">
        <span v-on="{ ...on }">Role</span>
      </template>
      <span>The role taken from the swim lanes</span>
    </v-tooltip>
  </v-col>
  <v-col sm="2" class="col-title text-center">
    <v-tooltip location="top" open-delay="1000">
      <template v-slot:activator="{ on }">
        <span v-on="{ ...on }">Command</span>
      </template>
      <span>Cards configured as commands</span>
    </v-tooltip>
  </v-col>
  <v-col sm="6" class="col-title text-center">
    <v-tooltip location="top" open-delay="1000">
      <template v-slot:activator="{ on }">
        <span v-on="{ ...on }">Aggregate / System</span>
      </template>
      <span>Cards configured as Aggregates</span>
    </v-tooltip>
  </v-col>
  <v-col sm="2" class="col-title text-center">
    <v-tooltip location="top" open-delay="1000">
      <template v-slot:activator="{ on }">
        <span v-on="{ ...on }">Resulting event</span>
      </template>
      <span>The result of the role performing the action on the aggregate / system</span>
    </v-tooltip>
  </v-col>
</v-row>
</template>
