import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import snackbarPlugin from './plugins/snackbar'
import loadingStatePlugin from './plugins/loadingState'
import 'quill/dist/quill.snow.css'
import { CognitoAuthPlugin } from '@/cognito/cognito'
import { VOffline } from 'v-offline'

const vm = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(CognitoAuthPlugin)
  .use(snackbarPlugin, { store })
  .use(loadingStatePlugin, { store })
  .component('VOffline', VOffline)
  .mount('#app')

if (window.Cypress) {
  window.store = store
}

export default vm
