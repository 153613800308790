import cognitoAuth from '@/cognito'
import { EventBus } from '@/event-bus/event-bus.js'

let cypressRunning
try {
  if (Cypress) {
    cypressRunning = true
  }
} catch (e) {
  cypressRunning = false
}

class GraphQLClient {
  constructor (endpoint) {
    if (cypressRunning) {
      this.endpoint = Cypress.env('VUE_APP_APPSYNC_GRAPHQL_ENDPOINT')
    } else {
      this.endpoint = process.env.VUE_APP_APPSYNC_GRAPHQL_ENDPOINT
    }
  }

  async query ({ operationName, query, variables = {} }, { failSilently = false } = {}) {
    try {
      const jwtToken = await cognitoAuth.getIdTokenPromise()
      const response = await fetch(this.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken
        },
        body: JSON.stringify({
          operationName,
          query,
          variables
        })
      })

      if (response.status === 401) {
        EventBus.$emit('redirect-to-login')
        throw new Error('Unauthorized')
      }

      if (!response.ok) {
        throw new Error(`Network error: ${response.status} - ${response.statusText}`)
      }

      const jsonResponse = await response.json()

      // Error handling for GraphQL errors
      if (jsonResponse.errors) {
        const message = jsonResponse.errors.map((error) => error.message).join('\n')
        throw new Error(`GraphQL error: ${message}`)
      }

      return jsonResponse.data
    } catch (error) {
      if (failSilently) {
        return
      }
      console.trace('error', error.message)
      if (error.message.includes('Unauthorized') ||
          error.message.includes('Not Authorized') ||
          error.message.includes('Invalid Refresh Token')) {
        EventBus.$emit('redirect-to-login')
      }
      throw error
    }
  }
}

const client = new GraphQLClient()
export default client
