<template>
  <v-layout class="diagram-actions">
    <v-tooltip location="bottom" max-width="220" open-delay="1000">
      <template v-slot:activator="{ props }">
        <button
          class="add-button captures-description"
          @click="addRole"
          v-bind="props"
          :disabled="$store.state.isWorkflowDisabled"
        >
          +
        </button>
      </template>

      <div>
        <p class="mb-0">Click here to add a new lane</p>
      </div>
    </v-tooltip>

    <v-tooltip location="bottom" max-width="500" open-delay="1000">
      <template v-slot:activator="{ props }">
        <button
          class="add-button captures-description"
          :disabled="$store.state.isWorkflowDisabled"
          @click="$emit('add-shape')"
          v-bind="props"
        >
          Add event
          <v-icon>custom:TaskIcon</v-icon>
      </button>
      </template>

      <div>
        <p class="text-body-2 mb-1"></p>
        <p class="mb-0">
          Add a new event to the right of the event you’ve selected
        </p>
        <p class="mb-0">
          <code>ALT/⌥ + ARROW RIGHT</code> - Create a new event in the same lane
        </p>
        <p class="mb-0">
          <code>ALT/⌥ + ARROW UP</code> - Create a new event in lane above
        </p>
        <p class="mb-0">
          <code>ALT/⌥ + ARROW DOWN</code> - Create a new event in lane below
        </p>
      </div>
    </v-tooltip>

    <v-tooltip location="bottom" max-width="220" open-delay="1000">
      <template v-slot:activator="{ props }">
        <button
          class="add-button captures-description"
          :disabled="$store.state.isWorkflowDisabled || !selectedEventId"
          @click="$emit('add-shape', { type: 'bpmn:ExclusiveGateway' })"
          v-bind="props"
        >
          Add decision
          <v-icon>custom:DecisionIcon</v-icon>
        </button>
      </template>

      <div>
        <p class="mb-0">
          Click here to add a new decision to the right of the event you’ve
          selected
        </p>
      </div>
    </v-tooltip>

    <v-tooltip location="bottom" max-width="220" open-delay="1000">
      <template v-slot:activator="{ props }">
        <button
          class="add-button"
          :disabled="$store.state.isWorkflowDisabled || !selectedEventId || addConnectionDisabled"
          @click="eventBusEmitEnableTargets()"
          v-bind="props"
        >
          Add connection
          <v-icon>custom:ConnectionIcon</v-icon>
        </button>
      </template>

      <div>
        <p class="mb-0">
          Click here to add a new connection to the right of the event you’ve
          selected
        </p>
      </div>
    </v-tooltip>

    <v-tooltip location="bottom" max-width="220" open-delay="1000">
      <template v-slot:activator="{ props: tooltipActivator }">
        <bpmn-diagram-actions-shape-top-transform
          :base-data="baseData"
          :selectedEvent="selectedEvent"
          :svgGraph="svgGraph"
          :svgGroups="svgGroups"
          :svgLanes="svgLanes"
          @add-shape="$emit('add-shape', $event)"
        >
          <template v-slot:buttons="props">
            <button
              style="height: 100%"
              class="add-button"
              :disabled="$store.state.isWorkflowDisabled || !selectedEventId"
              @click="props.openDialog()"
              v-bind="tooltipActivator"
            >
              Add subprocess
              <v-icon>custom:SubworkflowIcon</v-icon>
            </button>
          </template>
        </bpmn-diagram-actions-shape-top-transform>
      </template>
      <div>
        <p class="mb-0">
          Click here to add a new subprocess to the right of the event you’ve
          selected
        </p>
      </div>
    </v-tooltip>

    <button
      class="add-button captures-description"
      :disabled="$store.state.isWorkflowDisabled || !selectedEventId"
      @click="$emit('add-shape-ai', 'bpmn:Task', selectedEventId)"
    >
      Add event with AI&nbsp;
      <v-icon>mdi-creation</v-icon>
    </button>

    <v-tooltip location="bottom" max-width="220" open-delay="1000">
      <template v-slot:activator="{ props }">
        <button
          class="add-button"
          :disabled="$store.state.isWorkflowDisabled"
          @click="$emit('add-group', selectedEventId)"
          v-bind="props"
        >
          Add group
          <v-icon>mdi-group</v-icon>
        </button>
      </template>

      <div>
        <p>
          Click here to add a new group. After adding 2 groups you will get a vertical divder that you can move sideways.
        </p>
      </div>
    </v-tooltip>

    <v-spacer></v-spacer>

    <div v-if="!$store.state.isWorkflowDisabled" class="dnd-card-types-container mr-1">
      <v-tooltip
        location="top"
        v-for="requirementType in requirementTypes"
        :key="requirementType.id"
      >
        <template v-slot:activator="{ props }">
          <i
            class="pa-0 mx-1 rounded add-card-of-type"
            :draggable="true"
            v-bind="props"
            :data-card-type-id="requirementType.id"
            :style="{ background: requirementType.color.rgb }"
            @dragstart="handleDragStart"
            >
            <v-icon v-if="requirementType.aggregateType" icon="mdi-shape-outline" size="small"></v-icon>
            <v-icon v-if="requirementType.triggerType" icon="mdi-code-braces" size="small"></v-icon>
          </i
          >
        </template>

        <div>
          <p class="mb-0">{{ requirementType.title }} (Drag to event to create card)</p>
          <p class="mb-0" v-if="requirementType.aggregateType">This card type will be used for <b>Aggregate/System</b> in Aggregates view</p>
          <p class="mb-0" v-if="requirementType.triggerType">This card type will be used for <b>Commands</b> in the Aggregates view</p>
        </div>
      </v-tooltip>
    </div>

    <button
      :disabled="$store.state.isWorkflowDisabled"
      @click="openCommentDialog(1)"
      data-cy="workflow-upvote"
      class="mr-2"
    >
      <v-icon
        :color="currentWorkflowVote == 1 ? 'blue' : 'grey'"
        size="x-small"
        icon="mdi-thumb-up-outline"
      ></v-icon>
      {{ countThumbsUp }}
    </button>

    <button
      :disabled="$store.state.isWorkflowDisabled"
      @click="openCommentDialog(2)"
      data-cy="workflow-downvote"
      class="mr-2"
    >
      <v-icon
        :color="currentWorkflowVote == 2 ? 'blue' : 'grey'"
        size="x-small"
        icon="mdi-thumb-down-outline"
      ></v-icon>
      {{ countThumbsDown }}
    </button>

    <button
      :disabled="$store.state.isWorkflowDisabled"
      @click="feedbackDialog = true"
      class="mr-2"
    >
      {{
        workflowApprovalRate
          ? `${workflowApprovalRate}% approved`
          : "No approvals yet"
      }}
    </button>

    <workflow-comments-dialog
      :open="commentsDialog"
      :with-vote="commentsDialogVote"
      @close="closeCommentsDialog"
    ></workflow-comments-dialog>

    <workflow-feedback-dialog
      v-model:open="feedbackDialog"
    ></workflow-feedback-dialog>
  </v-layout>
</template>

<script>
import BpmnDiagramActionsShapeTopTransform from '@/components/BpmnDiagramActionsShapeTopTransform'
import WorkflowCommentsDialog from '@/components/WorkflowCommentsDialog'
import WorkflowFeedbackDialog from '@/components/WorkflowFeedbackDialog'
import { EventBus } from '@/event-bus/event-bus.js'
import { diagramHelpers } from '@/mixins/diagramHelpers'
import { mapState } from 'vuex'

export default {
  mixins: [diagramHelpers],

  props: {
    selectedEvent: Object,
    requirementTypes: Array,
    baseData: Object,
    svgGraph: Object,
    svgGroups: Object,
    svgLanes: Object
  },

  components: {
    BpmnDiagramActionsShapeTopTransform,
    WorkflowCommentsDialog,
    WorkflowFeedbackDialog
  },

  data: () => ({
    commentsDialog: false,
    commentsDialogVote: null,
    feedbackDialog: false
  }),

  computed: {
    ...mapState({ workflow: state => state.workflow.workflow }),
    selectedEventId () {
      return this.$route.params.eventId
    },
    currentWorkflowVote () {
      if (!this.workflow) { return [] }
      const entry = this.workflow?.userStatusesWorkflow?.find(entry => entry.userId === this.$store.state.userId)
      return entry?.vote
    },
    countThumbsUp () {
      if (this.workflow && this.workflow.userStatusesWorkflow) {
        const count = this.workflow.userStatusesWorkflow.filter(item => item.vote === 1).length
        return count
      } else {
        return 0
      }
    },
    countThumbsDown () {
      if (this.workflow && this.workflow.userStatusesWorkflow) {
        const count = this.workflow.userStatusesWorkflow.filter(item => item.vote === 2).length
        return count
      } else {
        return 0
      }
    },
    workflowApprovalRate () {
      const upvotes = this.countThumbsUp
      if (!upvotes) { return null }
      return Math.round(upvotes / this.workflow.teamMembers.length * 100)
    },
    addConnectionDisabled () {
      const res = (this.selectedEvent?.childIds?.length > 0) && (this.selectedEvent?.type !== 'bpmn:ExclusiveGateway')
      return res
    }
  },

  methods: {
    handleDragStart (ev) {
      ev.dataTransfer.setData('text/plain', ev.target.dataset.cardTypeId)
    },
    addRole () {
      const roleName = 'Lane ' + ((this.workflow.lanes?.length || 0) + 1)
      this.addLane(this.svgGraph, this.svgLanes, this.svgGroups, this.workflow, roleName, true)
    },
    openCommentDialog (vote = null) {
      this.commentsDialogVote = vote
      this.commentsDialog = true
    },

    closeCommentsDialog () {
      this.commentsDialog = false
      this.commentsDialogVote = null
    },

    eventBusEmitEnableTargets () {
      EventBus.$emit('enable-connection-targets', true)
    }
  }
}
</script>

<style scoped>
.v-tooltip__content {
  background: white;
  color: var(--color-primary);
  border-radius: 10px;
}

.v-tooltip__content code {
  background: var(--color-primary);
  color: white;
}

.dnd-card-types-container {
  display: flex;
  align-items: center;
}

.add-card-of-type {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: move;
}

.diagram-actions {
  width: 100%;
  height: 36px;
  border-top: 1px solid #e3e3e3;
}

</style>
