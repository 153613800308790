import gql from 'graphql-tag'

export default gql`
  subscription OnCreateRequirementType($workflowId: String) {
    onCreateRequirementType(workflowId: $workflowId) {
      id
      slot
      title
      description
      color {
        red
        green
        blue
        rgb
      }
      showInDiagram
      showInBacklog
      aiCount
      triggerType
      aggregateType
      defaultType
    }
  }
`
