<template>
  <div>
    <v-card class="requirement-card rounded-lg">
      <v-card-text
        class="requirement-card-body rounded-lg pa-0 hover-container"
        :style="{ 'border-color': cardTypeColor, 'border-radius': '1px'}"
      >
        <slot>
          <text-editor
            class="pa-1"
            :id="item.id"
            :text="item.description"
            :disabled="$store.state.isWorkflowDisabled"
            @text-update="updateDescription($event, item.id)"
            @text-delta="$emit('text-delta')"
            @move-focus="moveFocus"
            @keydown.native.stop=""
          />
        </slot>
        <slot name="data-fields" />
        <v-container v-if="!hideActions" fluid class="pa-0 mt-3">
          <v-row class="ma-0 align-center">
            <v-col class="pa-0">
              <requirement-actions
                :requirement="item"
                :compact-replies="compactReplies"
                :show-as-invisible="showAsInvisible"
                :jiraData2="jiraData2"
                :loadingJiraInfo="loadingJiraInfo"
                :with-dates="withDates"
                :date-created="item.created"
                :date-updated="item.updated"
                :creator="item.creator"
                @open-replies="openReplies"
                :hideTypeSelector="hideTypeSelector"
              ></requirement-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <requirement-replies
      v-if="!hideReplies"
      class="my-2"
      :requirementId="item.id"
      :replies="item.comments"
      :expand="repliesOpened"
      :reply-form-visible="replyFormVisible"
      :with-dates="replyWithDates"
      :hide-avatar="hideAvatar"
      :showAi="showAi"
      @expand-change="handleExpandChange"
      @hide-reply-form="replyFormVisible = false"
      @ask-for-reply="$emit('ask-for-reply', $event)"
    ></requirement-replies>
  </div>
</template>

<script>
import RequirementActions from '@/components/RequirementActions'
import RequirementReplies from '@/components/RequirementReplies'
import requirementTypesApi from '@/api/requirementTypesApi'
import TextEditor from '@/components/TextEditor.vue'
import workflowApi from '@/api/workflowApi'

export default {
  components: {
    RequirementActions,
    RequirementReplies,
    TextEditor
  },

  props: {
    item: Object,
    compactReplies: Boolean,
    withDates: Boolean,
    replyWithDates: Boolean,
    jiraData2: Object,
    loadingJiraInfo: Number,
    hideAvatar: Boolean,
    hideTypeSelector: Boolean,
    hideActions: Boolean,
    hideReplies: Boolean,
    showAi: Boolean,
    showAsInvisible: Boolean,
    boundedContexts: Array
  },

  data () {
    return {
      repliesOpened: false,
      replyFormVisible: false,
      keyDownPressed: false
    }
  },

  computed: {
    cardTypeColor () {
      if (this.showAsInvisible) {
        return '#868686'
      }

      const { cardType } = this.item
      return requirementTypesApi.getBackgroundColour(cardType)
    },
    boundedContextNames () {
      const res = this.boundedContexts.map((bc) => bc.name)
      return res
    }
  },

  methods: {
    moveFocus (direction, target) {
      target.firstChild.blur()
    },
    updateDescription (html, itemId) {
      workflowApi.updateRequirementsJsonMutation(this.$route.params.id, [{ requirementId: itemId, description: html }])
    },
    openReplies () {
      this.repliesOpened = true
      this.replyFormVisible = true
    },

    handleExpandChange (expand) {
      if (expand) {
        this.repliesOpened = true
      } else {
        this.repliesOpened = false
        this.replyFormVisible = false
      }
    }
  }
}
</script>

<style scoped>
  .requirement-card {
    overflow: hidden;
  }

  .requirement-card-body {
    border-width: 1px;
    border-top-width: 8px;
    border-style: solid;
  }

  .text-nowrap {
    white-space: nowrap;
  }

  .v-tooltip__content {
    background: white;
    color: var(--color-primary);
    border-radius: 10px;
  }
</style>
