<template>
  <div class="transform-actions-outer-container">
    <!-- slot for buttons with 3 buttons as default -->
    <slot
      name="buttons"
      :transformShape="transformShape"
      :openDialog="openDialog"
    >
      <!-- default content -->
      <v-tooltip location="top" open-delay="1000">
        <template v-slot:activator="{ props: tooltipActivator }">
          <button v-if="!disableGateway" @click="transformShape('event')">
            <v-icon
              size="small"
              color="white"
              v-bind="tooltipActivator"
            >
              custom:TaskIcon
            </v-icon>
          </button>
        </template>
        <div>
          <p class="text-body-2 mb-1">Change shape to event</p>
        </div>
      </v-tooltip>

      <v-tooltip location="top" open-delay="1000">
        <template v-slot:activator="{ props: tooltipActivator }">
          <button @click="transformShape('decision')">
            <v-icon
              size="small"
              color="white"
              v-bind="tooltipActivator"
            >
              custom:DecisionIcon
            </v-icon>
          </button>
        </template>
        <div>
          <p class="text-body-2 mb-1">Change shape to decision</p>
        </div>
      </v-tooltip>

      <v-tooltip location="top" open-delay="1000">
        <template v-slot:activator="{ props: tooltipActivator }">
          <button @click="transformShape('subprocess')">
            <v-icon
              size="small"
              color="white"
              v-bind="tooltipActivator"
            >
              custom:SubworkflowIcon
            </v-icon>
          </button>
        </template>
        <div>
          <p class="text-body-2 mb-1">Change shape to subprocess</p>
        </div>
      </v-tooltip>
    </slot>

    <!-- dialog for selecting subworkflow -->
    <v-dialog v-model="subworkflowDialog" max-width="400px">
      <v-card flat class="selectSubworkflowDialog">
        <v-toolbar flat dense color="white">
          <v-toolbar-title>Select workflow</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon >mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-col cols="12">
            <v-select
              v-model="selectWorkflow"
              :items="subProcessOptions"
              item-title="name"
              item-value="id"
              label="Select workflow"
              return-object
              single-line
              hide-details
              class="select-workflow-handle"
            ></v-select>
          </v-col>

          <v-col cols="12" v-if="selectWorkflow && selectWorkflow.id === newWorkflowId">
            <v-text-field
              v-on:keydown.stop
              v-on:keydown.enter="onSelectWorkflow"
              v-model="newWorkflowTitle"
              label="Enter Workflow Title"
              :rules="[rules.required]"
              hide-details
            ></v-text-field>
          </v-col>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="text" @click="closeDialog">Cancel</v-btn>
          <v-btn
            color="primary"
            @click="onSelectWorkflow"
            :disabled="!selectWorkflow"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <!-- Add subworkflow dialog -->
</template>

<script>
import workflowApi from '@/api/workflowApi'
import store from '@/store'
import { workflowApiHelpers } from '@/api/workflowApiHelpers'
import { mapState } from 'vuex'

export default {
  data: () => {
    return {
      subworkflowDialog: false,
      selectWorkflow: null,
      newWorkflowId: 'NEW_WORKFLOW',
      newWorkflowTitle: '',
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  props: {
    selectedEvent: Object,
    baseData: Object,
    svgGraph: Object,
    svgGroups: Object,
    svgLanes: Object
  },
  methods: {
    async transformShape (shapeType) {
      const shapeId = this.selectedEvent.id
      if (!this.transformShapeId) {
        this.transformShapeId = { shapeType, shapeId }
      }
      let type
      switch (shapeType) {
        case 'decision':
          type = 'bpmn:ExclusiveGateway'
          break
        case 'event':
        case 'subprocess':
          type = 'bpmn:Task'
          break
        default:
          return
      }
      let subWorkflowId = null
      if (shapeType === 'subprocess') {
        if (!this.selectWorkflow) {
          this.subworkflowDialog = true
          return
        } else if (this.selectWorkflow.id === this.newWorkflowId) {
          subWorkflowId = await workflowApi.createWorkflow({ projectId: this.$route.params.projectId, name: this.newWorkflowTitle })
        } else {
          subWorkflowId = this.selectWorkflow.id
        }
      }
      this.svgGraph[shapeId].type = type
      this.svgGraph[shapeId].margin = type === 'bpmn:ExclusiveGateway' ? 33 : 0
      this.svgGraph[shapeId].subprocess = subWorkflowId
      workflowApi.updateWorkflow({
        svgGraph: this.svgGraph,
        svgLanes: this.svgLanes,
        svgGroups: this.svgGroups,
        workflow: this.workflow,
        updateNote: {
          action: 'changed shape of',
          target: this.svgGraph[shapeId].name
        }
      })
      this.closeDialog()
    },
    async onSelectWorkflow () {
      if (this.transformShapeId) {
        const { shapeType, shapeId } = this.transformShapeId
        await this.transformShape(shapeType, shapeId)
        this.closeDialog()
      } else {
        this.createSubworkflow()
      }
    },
    closeDialog () {
      this.description = ''
      this.selectWorkflow = null
      this.newWorkflowTitle = ''
      this.subworkflowDialog = false
      this.transformShapeId = null
      this.$emit('transform-shape')
    },
    openDialog () {
      this.subworkflowDialog = true
    },
    async createSubworkflow () {
      let description
      if (this.selectWorkflow.id === this.newWorkflowId) {
        description = this.newWorkflowTitle
      } else {
        description = this.selectWorkflow.name
      }
      description = workflowApiHelpers.getNextDescription(this.workflow.eventsJson || [], description)
      let subprocess
      if (this.selectWorkflow.id === this.newWorkflowId) {
        subprocess = await workflowApi.createWorkflow({ projectId: this.$route.params.projectId, name: this.newWorkflowTitle })
      } else {
        subprocess = this.selectWorkflow.id
      }
      this.$emit('add-shape', { subprocess, description })
      this.closeDialog()
    },
    async createWorkflow () {
      const { projectId } = store.state
      const name = this.newWorkflowTitle
      const workflowId = await workflowApi.createWorkflow({ projectId, name })
      return workflowId
    }
  },
  computed: {
    ...mapState({ workflows: state => state.workflows.workflows || [] }),
    ...mapState({ workflow: state => state.workflow.workflow }),
    subProcessOptions () {
      // exclude current workflow from the list of options
      const options = this.workflows
        ? this.workflows.filter(({ id }) => id !== this.$route.params.id)
        : []

      return [{
        id: this.newWorkflowId,
        name: 'Create new workflow'
      }].concat(options)
    },
    disableGateway () {
      return (Array.isArray(this.selectedEvent.childIds) && this.selectedEvent.childIds.length > 1)
    }
  },
  watch: {
    selectWorkflow (newVal) {
      if (newVal && newVal.id === this.newWorkflowId) {
        this.newWorkflowTitle = this.selectedEvent.name
      }
    }
  }
}
</script>

<style scoped>
.transform-actions-outer-container.position-fixed {
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
  padding: 0 1px;
  background: #343434;
}

.transform-actions-outer-container.position-fixed > button {
  padding: 2px 3px;
}

.dark {
  background: #343434;
}

button {
  line-height: 1em;
}
</style>
