<template>
  <v-dialog :scrollable="true" max-width="30%" v-model="open" content-class="dialog-top-right">
    <template v-slot:activator="{ props }">
      <v-icon v-bind="props" size="small" class="ml-2">mdi-help-circle-outline</v-icon>
    </template>

    <v-card flat="true">
      <v-toolbar density="compact" flat="true" dark color="primary">
        <v-toolbar-title><v-icon> mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>How to use the Event Story</b></v-toolbar-title>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <h3><span class="bg-yellow-lighten-4">Event Story</span></h3>
        Start by creating an Event Story. Ask users or domain experts
        for real-world example data for each business event. Fill in
        the Event Story table with fields (columns) and examples (rows).
        Each field (column) belongs to one of seven categories:
        <b>Who, What, When, Where, Why, How, and How Many</b>.
        These categories act as a checklist, ensuring all relevant
        aspects of the event are covered.
        <br /><br />
        <h3>Read, write, create</h3>
        Each field (column) can also be tagged with <b>read, write, or create</b>:
        <ul class="ml-5">
          <li><b>Read:</b>&nbsp;Part of the event's read model or query. Data provided to the actor performing the command.</li>
          <li><b>Write:</b>&nbsp;Part of the event's write model or command. Data provided by the actor performing the command.</li>
          <li><b>Create:</b>&nbsp;Create: An entity that can be created by the actor before or after invoking the command. If you want to keep the model strictly to one command per event, then move the creation of this entity into its own event.</li>
        </ul>
        <br />
        <h3>Example data</h3>
        The example data are all the rows below “Tags”. Fill these
        rows with real example data (but mind GDPR). There are six different types
        of example rows, briefly explained below:
        <ul class="ml-5">
          <li><b>Typical data:</b> Ask for the most common example of data for each field.</li>
          <li><b>Different 1 & 2:</b> Ask for the second and third most common examples that differ from the first and from each other.</li>
          <li><b>Repeat:</b> Create a row as identical to 'Typical data' as possible. The goal is to ensure that we haven't missed a unique identifier. It should not be possible to create an exact copy of another row; if that occurs, add a field that can be used as a unique identifier.</li>
          <li><b>Missing:</b> Construct a row with the fewest possible values, filling in only the mandatory fields. The purpose is to understand which data are mandatory and which are not.</li>
          <li><b>Group:</b> Add a row that includes multiple items in each field where possible. For example, if a customer can order multiple products in a single order, represent this by placing two article IDs separated by a '+' sign.</li>
        </ul>
        <br />
        <b>Note:</b>&nbsp;Some of the example rows are initially hidden and will be shown as data is added.
      </v-card-text><br /><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  }
}
</script>

<style>
.v-dialog .help-ai {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 30%;
  overflow-y: scroll;
}
</style>
