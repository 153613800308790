<template>
  <g @dragover.prevent="eventDragoverHandler" @drop.prevent="eventDropHandler">
    <!-- diamond shape for Exclusive Gateway -->
    <!-- class="shadow" -->
    <g
      :transform="`translate(${node.x + node.margin}, ${
        node.y + 40
      }) scale(0.7 0.7)`"
    >
      <g filter="url(#shadow-gateway)">
        <polygon
          :class="{
            draggable: true,
            'connection-target': connectionTarget,
          }"
          @mousedown="onGatewayClick"
          :id="'p-' + node.id"
          :name="node.name"
          :data-test="svgGraphData[node.id]?.name"
          points="0,0 25,-25 50,0 25,25"
          :style="{
            fill: isSelectedEvent(node.id) ? 'white' : color,
            'fill-opacity': node.outlined ? '0' : '1',
            stroke: color,
            strokeWidth:
              (isSelectedEvent(node.id) ? 4 : node.outlined ? 1 : 0) + 'px',
          }"
        ></polygon>
        <path
          v-if="!node.outlined"
          transform="matrix(1 0 0 1 0 -25)"
          d="m 16,15 7.43,9.71 -7.43,9.71 3.43,0 5.71,-7.46 5.71,7.46 3.43,0 -7.43,-9.71 7.43,-9.71 -3.43,0 -5.71,7.46 -5.71,-7.46 -3.43,0 z"
          style="
            fill: black;
            stroke-width: 0.5px;
            stroke: black;
            pointer-events: none;
          "
        ></path>
      </g>
      <defs>
        <filter id="shadow-gateway" x="0" y="0" width="200%" height="200%">
          <feDropShadow dx="2" dy="2" stdDeviation="3" />
        </filter>
      </defs>
    </g>

    <!-- editable Event Label -->
    <foreignObject
      v-if="node.showConditionLabelOnEvent"
      :x="node.x - 12"
      :y="node.y - 28"
      width="120"
      height="32"
    >
      <bpmn-event-label
        :node="node"
        :condition-label="getConnectionLabel(node, node.parents[0])"
        :characterLimit="30"
        @change-event="$emit('change-event', $event)"
        @label-blur="blurOnConditionLabels($event, node, node.parents[0])"
        @label-focus="focusOnConditionLabel"
      ></bpmn-event-label>
    </foreignObject>

    <!-- editable Exclusive Gateway Name -->
    <foreignObject
      :x="node.x + 3"
      :y="node.y + 58"
      width="94"
      height="60"
    >
      <div @mousedown="onGatewayClick" class="text-box text-centered" >
        <div
          ref="gatewayName"
          :id="node.id"
          class="draggable text-cell gateway-name d-flex justify-center text-center pa-0 ma-0 w-100"
          :data-test-decision-name="svgGraphData[node.id]?.name"
          :data-test-version="svgGraphData[node.id]?.version"
          :contenteditable="!$store.state.isWorkflowDisabled && node.id == $route.params.eventId"
          @keydown.stop.exact="checkCharacterLimit($event, 30)"
          @keydown.shift.exact="checkCharacterLimit($event, 30)"
          @keydown.stop.prevent.enter="toggleFocus"
          @keydown.stop.esc="restoreValue($event, node.name)"
          @blur.stop="blurOnDescription($event, node)"
          @keydown.stop.tab.exact="moveRight($event)"
          @keydown.stop.tab.shift.exact="moveLeft($event)"
          @paste="onPaste($event, $refs.gatewayName, 30)"
          v-text="node.name"
          style="vertical-align: top; overflow-wrap: anywhere"
          @input="() =>updateFontSizeDynamically(this.$refs.gatewayName)"
        ></div>
      </div>
    </foreignObject>
  </g>
</template>

<script>
import BpmnEventLabel from '@/components/BpmnEventLabel'
import { diagramHelpers } from '@/mixins/diagramHelpers.js'
import { updateFontSizeDynamically } from '@/utils/update-font.js'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    BpmnEventLabel
  },

  mixins: [diagramHelpers],

  props: {
    node: Object,
    connectionTarget: Boolean,
    svgGraph: Object,
    svgGroups: Object,
    svgLanes: Object,
    workflow: Object,
    eventDefinition: Object
  },

  methods: {
    updateFontSizeDynamically,
    moveRight (event) {
      this.$emit('move-right')
      event.target.blur()
      event.preventDefault()
    },
    moveLeft (event) {
      this.$emit('move-left')
      event.target.blur()
      event.preventDefault()
    },
    onGatewayClick () {
      if (this.connectionTarget) {
        this.addConnection(this.node.id)
      } else {
        this.changeSelectedEvent(this.node.id)
      }
    },

    eventDragoverHandler (ev) {
      ev.dataTransfer.dropEffect = 'move'
    },

    eventDropHandler (ev) {
      const cardTypeId = ev.dataTransfer.getData('text/plain')
      this.addCardToEvent(this.node.id, cardTypeId, '<p>New card</p>')
    }
  },

  computed: {
    ...mapGetters({
      svgGraphData: 'svgGraphData/svgGraphData',
      baseData: 'svgGraphData/baseData'
    }),
    ...mapState({
      requirementTypes: state => state.requirementTypes.requirementTypes
    }),
    color () {
      return this.node.color ? this.node.color.rgb : this.eventDefinition.color.rgb
    }
  },

  mounted () {
    if (this.$refs.gatewayName) {
      updateFontSizeDynamically(this.$refs.gatewayName)
    }
    if (this.node.selectEventText) {
      // yuriy:
      // for some reason `this.$nextTick` doesn't fix the problem
      // with async state change of the component
      // `setTimeout` does a trick and allows to select the text
      // when element becomes selected
      //
      // staffan:
      // diabled because of cypress tests
      // window.setTimeout(() => {
      //   const node = this.$el.querySelector('.gateway-name')
      //   if (node) {
      //     const selection = window.getSelection()
      //     const range = document.createRange()
      //     range.selectNodeContents(node)
      //     selection.removeAllRanges()
      //     selection.addRange(range)
      //   }
      // }, 1000)
    }
  }
}
</script>

<style scoped>
.connection-target {
  animation: target 0.8s ease-in-out both infinite;
}

.text-centered{
  justify-content : center;
  line-height: 1.2
}

.gateway-name {
  //max-height: 40px
}

.gateway-name:focus-visible {
  outline: none;
}

@keyframes target {
  0% {
    stroke-width: 2px;
    stroke: black;
  }

  50% {
    stroke-width: 5px;
    stroke: black;
  }

  100% {
    stroke-width: 2px;
    stroke: black;
  }
}
/* .shadow {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
} */
</style>
