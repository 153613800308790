import gql from 'graphql-tag'

export default gql`
  mutation DeleteRequirementType($workflowId: ID!, $id: ID!) {
    deleteRequirementType(workflowId: $workflowId, id: $id) {
      id
      workflowId
    }
  }
`
