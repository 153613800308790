export const examples = {
  dataFieldsExampleCreateOrder: [
    {
      category: 'Who',
      connectingWord: '',
      fieldName: 'Customer Name',
      tags: 'READ',
      exampleData: ['Example data', 'Example data', 'Example data']
    },
    {
      category: 'What',
      word: '',
      connectingWord: 'orders',
      fieldName: 'Product Name',
      tags: 'READ, WRITE',
      exampleData: ['Example data', 'Example data', 'Example data']
    },
    {
      category: 'When',
      connectingWord: 'on',
      fieldName: 'Delivery Time',
      tags: 'READ, WRITE',
      exampleData: ['Example datetime', 'Example datetime', 'Example datetime']
    },
    {
      category: 'Where',
      connectingWord: 'to',
      fieldName: 'Delivery Address',
      tags: 'READ, WRITE, CREATE',
      exampleData: ['Example data', 'Example data', 'Example data']
    },
    {
      category: 'How many',
      connectingWord: 'in',
      fieldName: 'Quantity',
      tags: 'WRITE',
      exampleData: ['x', 'y', 'z']
    },
    {
      category: 'How',
      connectingWord: 'with',
      fieldName: 'Comment',
      tags: 'WRITE',
      exampleData: ['Example comment', 'Example comment', 'Example comment']
    }
  ],
  dataFieldsExampleDeliverOrder: [
    {
      category: 'Who',
      connectingWord: '',
      fieldName: 'Supplier',
      tags: 'READ',
      exampleData: ['Example data', 'Example data', 'Example data']
    },
    {
      category: 'What',
      word: '',
      connectingWord: 'delivers',
      fieldName: 'Product Name',
      tags: 'READ',
      exampleData: ['Example data', 'Example data', 'Example data']
    },
    {
      category: 'When',
      connectingWord: 'on',
      fieldName: 'Delivery Time',
      tags: 'READ',
      exampleData: ['Example datetime', 'Example datetime', 'Example datetime']
    },
    {
      category: 'Where',
      connectingWord: 'to',
      fieldName: 'Delivery Address',
      tags: 'READ',
      exampleData: ['Example data', 'Example data', 'Example data']
    },
    {
      category: 'How many',
      connectingWord: 'in',
      fieldName: 'Quantity',
      tags: 'READ, WRITE',
      exampleData: ['x', 'y', 'z']
    },
    {
      category: 'How',
      connectingWord: 'with',
      fieldName: 'Shipping method',
      tags: 'READ',
      exampleData: ['Example data', 'Example data', 'Example data']
    }
  ],
  factTableExampleCreateOrder: [
    {
      category: 'Who',
      fieldName: 'Customer ID',
      exampleData: ['Example data', 'Example data', 'Example data']
    },
    {
      category: 'What',
      fieldName: 'Order ID',
      exampleData: ['Example data', 'Example data', 'Example data']
    },
    {
      category: 'When',
      fieldName: 'Delivery Time',
      exampleData: ['Example datetime', 'Example datetime', 'Example datetime']
    },
    {
      category: 'Where',
      fieldName: 'Delivery Address ID',
      exampleData: ['Example data', 'Example data', 'Example data']
    },
    {
      category: 'How many',
      fieldName: 'Quantity',
      exampleData: ['x', 'y', 'z']
    },
    {
      category: 'How',
      fieldName: 'Comment',
      exampleData: ['Example comment', 'Example comment', 'Example comment']
    }
  ],
  customerDimensionTableExample: [
    {
      category: 'Who',
      fieldName: 'Customer ID',
      exampleData: ['Example data', 'Example data', 'Example data']
    }, {
      category: 'Who',
      fieldName: 'Customer Name',
      exampleData: ['Example data', 'Example data', 'Example data']
    }, {
      category: 'Where',
      fieldName: 'Customer Address',
      exampleData: ['Example data', 'Example data', 'Example data']
    }, {
      category: 'What',
      fieldName: 'Phone Number',
      exampleData: ['Example data', 'Example data', 'Example data']
    }
  ],
  productDimensionTableExample: [
    {
      category: 'What',
      fieldName: 'Product ID',
      exampleData: ['Example data', 'Example data', 'Example data']
    }, {
      category: 'What',
      fieldName: 'Product Name',
      exampleData: ['Example data', 'Example data', 'Example data']
    }, {
      category: 'What',
      fieldName: 'Product Type',
      exampleData: ['Example data', 'Example data', 'Example data']
    }, {
      category: 'How Many',
      fieldName: 'Stock Level',
      exampleData: ['x', 'y', 'z']
    }
  ]
}
