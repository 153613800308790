<template>
  <v-dialog
    :scrollable="true"
    max-width="30%"
    v-model="open"
    content-class="dialog-top-right"
  >
    <template #activator="{ props }">
      <slot :props="props"></slot>
    </template>

    <v-card flat>
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title><v-icon> mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>Generate Example Data</b></v-toolbar-title>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <h3>Example Data</h3>
        You can choose to generate examples of data that are relevant
        for each step of the process. This data will be available
        to edit under the Data Models tab.<br /><br />
      </v-card-text><br /><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  }
}
</script>

<style>
.v-dialog .help-ai {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 30%;
  overflow-y: scroll;
}
</style>
