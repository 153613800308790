import gql from 'graphql-tag'

export default gql`
  query getWorkflow($workflowId: String!, $projectId: String!) {
    getWorkflow(workflowId: $workflowId, projectId: $projectId) {
      id
      projectId
      name
      accessLevel
      eventsJson {
        id
        requirementsJson {
          id
        }
      }
    }
  }
`
