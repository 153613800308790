<template>
  <v-dialog :scrollable="true" max-width="30%" v-model="open">
    <template #activator="{ props }">
      <slot :props="props"></slot>
    </template>

    <v-card flat >
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title><v-icon> mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>How to use AI with Qlerify</b></v-toolbar-title>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <h3>Get help by our AI copilot</h3>
        Use AI to generate workflows and cards to get started quickly.
        Examples of cards that you might want to generate with AI are:
        user stories, business rules, average lead time etc.<br /><br />
        <ol class="ml-5">
          <li class="mb-1">Describe your workflow in a way similar
            to the examples in the drop down list (click on the field with
            the label "Create a workflow containing..."). For example:
            "<b>a process for ordering home delivery of food in 5 steps</b>".
            Whatever you write should fit as a continuation of the
            sentence "<b>Create a workflow containing ...</b>"
          </li>
          <li class="mb-1">If you want to generate cards, then select the number
          of cards (for each card type) to generate for each step. 0, 1, 2 or 3</li>
          <li class="mb-1">If you want to generate example data, select the
            number of examples to generate for each process step (0, 3, 4, 5 or 6).
          </li>
          <li class="mb-1">Press <b>GENERATE WORKFLOW</b>. It can take up to a few minutes
            to complete. NOTE: The more example data and cards you generate the longer time it will take.
          </li>
          <li class="mb-1">After the workflow is created you can add additional events and cards with AI
            one by one. You can also ask questions to the AI in the comments section of each card.
          </li>
          <li class="mb-1">If you want to generate a full workflow with AI, you need to start from an empty workflow
        and use the button <b>Generate workflow with AI</b>
          </li>
        </ol><br />

        <h3>Configure the cards</h3>
        You can configure the cards as you wish in workflow settings
        (the <v-icon size="x-small">mdi-cog</v-icon> icon top right). Whatever you write as <b>type name</b>
        and <b>description</b> should fit into the following template:
        <b>"For each process step, create 0-3 example(s) of a [type name]: [description]</b>"  It is
        important to have a precise definition (description) of the card types.<br />
        <br />
        <h3>Note</h3>
        When adding events to an existing workflow or asking questions,
        then information about your current workflow will be shared with Open AI.<br />
      </v-card-text><br /><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['openedFromSettings'],
  data () {
    return {
      open: false
    }
  }
}
</script>

<style>
.v-dialog .help-ai {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 30%;
  overflow-y: scroll;
}
</style>
