<template>
  <div style="display: block; overflow: auto;">
    <v-card-text class="mt-4 text-black tab-content">
      <!-- ************ -->
      <!-- prompt begin -->
      <!-- ************ -->
      <div class="text-subtitle-1 font-weight-bold">
        Get help from AI to build your workflow
        <v-tooltip location="right">
          <template v-slot:activator="{ props }">
            <v-icon size="small" v-bind="props">mdi-information-outline</v-icon>
          </template>
          <span>Describe the process or user journey to be used when adding new events with AI.</span>
        </v-tooltip>
        <workflow-help-dialog-ai
          #default="{ props }"
          :openedFromSettings="true"
          content-class="dialog-top-right"
        >
          <v-icon v-bind="props" size="small" class="ml-2"> mdi-help-circle-outline</v-icon>
        </workflow-help-dialog-ai>
      </div>
      <v-form ref="formGenerateDiagram" v-model="validForm">
        <v-col class="flex-wrap" cols="12">
          <v-row>
            <ai-description-input
              :text="workflow.aiDescription"
              @change-description="debouncedSaveAiDescription($event)"
              label="Create a workflow containing "
              ref="aiDescription"
              class="elevation-2"/>
          </v-row>
          <v-checkbox
            :model-value="workflow.aiVerbPastTense"
            @update:model-value="saveAiVerbSetting()"
            label="Use past tense verbs to describe events"
            hide-details
            class="ml-n2 mt-2"
          ></v-checkbox>
        </v-col>
      </v-form>
      <!-- ********** -->
      <!-- prompt end -->
      <!-- ********** -->

      <!-- ************************ -->
      <!-- card type settings begin -->
      <!-- ************************ -->
      <div class="mt-4 text-subtitle-1 font-weight-bold">
        Card Type Settings
      </div>
      <v-card class="pa-2 elevation-3">
        <v-row class="flex-wrap align-center pb-2">
          <v-col xs2 class="text-caption font-weight-medium text-uppercase text-truncate">
            Type name
          </v-col>

          <v-col xs1 class="text-caption text-uppercase text-truncate text-center">
            Color
          </v-col>

          <v-col xs2 class="text-caption font-weight-medium text-uppercase text-truncate text-left">
            Show in diagram
          </v-col>

          <v-col xs3 class="text-caption font-weight-medium text-uppercase text-truncate text-left">
            Show in backlog
          </v-col>
          <v-col xs1 class="text-caption font-weight-medium text-uppercase text-truncate text-left">
            Use AI
            <workflow-help-dialog-cards :settings="true" #default="{ props }">
              <v-btn variant="text" icon size="x-small" v-bind="props" class="mr-2">
                <v-icon icon="mdi-help-circle-outline"></v-icon>
              </v-btn>
            </workflow-help-dialog-cards>
          </v-col>
          <v-col xs1 class="text-caption font-weight-medium text-uppercase text-truncate text-left">
            Command
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon size="small" v-bind="props">mdi-information-outline</v-icon>
              </template>
              <span>Pick one card type to be used for Commands in the 'Aggregates' section.</span>
            </v-tooltip>
          </v-col>
          <v-col xs1 class="text-caption font-weight-medium text-uppercase text-truncate text-left">
            Aggr.
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon size="small" v-bind="props">mdi-information-outline</v-icon>
              </template>
              <span>Pick one card type to be used for Aggregates (or Systems) in the 'Aggregates' section.</span>
            </v-tooltip>
          </v-col>
          <v-col xs1 class="text-caption font-weight-medium text-uppercase text-truncate text-left">
            Default
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon size="small" v-bind="props">mdi-information-outline</v-icon>
              </template>
              <span>Default card type for new cards. Only enabled for types with SHOW IN BACKLOG selected.</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <workflow-settings-requirement-type v-for="requirementType in requirementTypes" :key="requirementType.id"
          :requirement-type="requirementType" :enableEdit="enableEdit" :disabled="$store.state.isWorkflowDisabled"
          @disable-edit="enableEdit = null"></workflow-settings-requirement-type>

        <div class="d-flex justify-end mt-2 mr-3">
          <v-btn color="primary" size="small" @click="createRequirementType()" :disabled="$store.state.isWorkflowDisabled">Add Type</v-btn>
        </div>
      </v-card>
      <!-- ********************** -->
      <!-- card type settings end -->
      <!-- ********************** -->

      <!-- ********** -->
      <!-- BEAM begin -->
      <!-- ********** -->
      <div class="d-flex justify-space-between align-center mt-10">
        <div class="text-subtitle-1 font-weight-bold flex-grow-1 flex-shrink-0">
          Generate Data Models with AI
          <workflow-help-dialog-example-data #default="{ props }">
            <v-icon v-bind="props" size="small">mdi-help-circle-outline</v-icon>
          </workflow-help-dialog-example-data>
        </div>
      </div>
      <v-card class="pa-2 elevation-3">
        <v-col wrap align-center pb-2>
          <v-row xs12 class="text-caption font-weight-medium text-uppercase">
            When using AI: For each step generate a Data Model with 3 - 6 fields.
          </v-row>
        </v-col>
        <v-row class="py-2 align-center flex-wrap">
          <v-col class="d-flex justify-center align-center" cols="1">
            <v-select
              v-model="dataFieldCount"
              variant="solo"
              hide-details
              :flat="true"
              density="compact"
              :items="[0, 3, 4, 5, 6]"
              @update:model-value="saveBeamAiOptions"
            >
              <template v-slot:append>&nbsp;</template>
            </v-select>
          </v-col>
          <v-col class="type-title align-center justify-center">
            <b>Data fields</b> for this step
          </v-col>
        </v-row>
      </v-card>
      <!-- ******** -->
      <!-- BEAM end -->
      <!-- ******** -->

      <!-- **************** -->
      <!-- AI version begin -->
      <!-- **************** -->
      <div class="d-flex justify-space-between align-center mt-10">
        <div class="text-subtitle-1 font-weight-bold flex-grow-1 flex-shrink-0">
          Select AI model
          <workflow-help-dialog-ai-version
            #default="{ props }"
            content-class="dialog-top-right"
          >
            <v-icon v-bind="props" size="small">mdi-help-circle-outline</v-icon>
          </workflow-help-dialog-ai-version>
        </div>
      </div>
      <v-card class="pa-2 elevation-3">
        <v-row>
          <v-col cols="2">
            <ai-version-selector v-model="aiVersion" />
          </v-col>
        </v-row>
      </v-card>
      <!-- ************** -->
      <!-- AI version end -->
      <!-- ************** -->

      <ai-prompt-export #default="{ props }">
        <v-btn size="small" v-bind="props" variant="text" class="my-2">Export AI Prompt</v-btn>
      </ai-prompt-export>
      <br />
    </v-card-text>
  </div>
</template>

<script>
import WorkflowSettingsRequirementType from '@/components/WorkflowSettingsRequirementType'
import store from '@/store'
import workflowApi from '@/api/workflowApi'
import WorkflowHelpDialogExampleData from '@/components/WorkflowHelpDialogExampleData'
import WorkflowHelpDialogCards from '@/components/WorkflowHelpDialogCards'
import WorkflowHelpDialogAi from '@/components/WorkflowHelpDialogAi'
import WorkflowHelpDialogAiVersion from '@/components/WorkflowHelpDialogAiVersion.vue'
import AiDescriptionInput from '@/components/AiDescriptionInput.vue'
import AiPromptExport from '@/components/AiPromptExport.vue'
import AiVersionSelector from '@/components/AiVersionSelector.vue'
// import throttle from '@/utils/throttle'
import debounce from '@/utils/debounce'
import { mapState } from 'vuex'

export default {
  components: {
    AiDescriptionInput,
    AiVersionSelector,
    WorkflowHelpDialogAiVersion,
    WorkflowSettingsRequirementType,
    WorkflowHelpDialogExampleData,
    WorkflowHelpDialogCards,
    WorkflowHelpDialogAi,
    AiPromptExport
  },

  computed: {
    ...mapState({
      workflow: state => state.workflow.workflow,
      requirementTypes: state => state.requirementTypes.requirementTypes
    })
  },

  data () {
    return {
      aiVersion: 0,
      dataFieldCount: 0,
      createTypeOpen: false,
      createTypeFetching: false,
      enableEdit: null,
      validForm: true,
      isLoading: true,
      rules: {
        required: value => !!value || 'Required.'
      },
      saveAiDescriptionDebouncer: debounce(e => { this.saveAiDescription(e) }, 1000),
      aiModels: [
        { name: 'Qlerify', version: 5 },
        { name: 'OpenAI ChatGPT-4o', version: 6 },
        { name: 'OpenAI ChatGPT-4o-mini', version: 3 },
        { name: 'OpenAI ChatGPT-4', version: 4 }
      ]
    }
  },

  methods: {
    debouncedSaveAiDescription ({ description, skipDebounce }) {
      if (skipDebounce) {
        this.saveAiDescription(description)
      } else {
        this.saveAiDescriptionDebouncer(description)
      }
    },
    saveAiDescription (e) {
      workflowApi.updateWorkflowNoHistoryMutation(
        this.$route.params.projectId,
        {
          id: this.$route.params.id,
          expectedVersion: this.workflow.version,
          aiDescription: e
        }
      )
    },
    async saveAiVerbSetting () {
      await workflowApi.updateWorkflowNoHistoryMutation(
        this.$route.params.projectId,
        {
          id: this.$route.params.id,
          expectedVersion: this.workflow.version,
          aiVerbPastTense: !this.workflow.aiVerbPastTense
        }
      )
    },
    saveBeamAiOptions () {
      workflowApi.updateWorkflowNoHistoryMutation(
        this.$route.params.projectId,
        {
          id: this.$route.params.id,
          expectedVersion: this.workflow.version,
          beamAiOptions: {
            dataFieldCount: this.dataFieldCount
          }
        }
      )
    },
    async createRequirementType () {
      let counter = 1
      while (this.requirementTypes.findIndex(rt => rt.title === `Card type ${counter}`) >= 0) {
        counter++
      }
      const title = `Card type ${counter}`
      const description = `Card type ${counter} description`
      const color = {
        red: 158,
        green: 158,
        blue: 158
      }
      const workflowId = this.$route.params.id
      this.createTypeFetching = true
      const input = {
        title,
        description,
        color,
        showInDiagram: true,
        showInBacklog: true,
        triggerType: false,
        aggregateType: false,
        defaultType: false
      }
      const requirementType = await store.dispatch('requirementTypes/createRequirementType', { workflowId, input })
      this.$nextTick(() => {
        this.enableEdit = requirementType.id
      })
      this.createTypeOpen = false
      this.createTypeFetching = false
    }
  },
  watch: {
    workflow: {
      handler (newVal) {
        this.beamAiOptions = newVal.beamAiOptions
        if (newVal?.beamAiOptions?.dataFieldCount) {
          this.dataFieldCount = newVal.beamAiOptions.dataFieldCount
        } else {
          this.dataFieldCount = 0
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>
.tab-content {
  height: 1000px;
}
::v-deep .v-select__menu-icon {
    display: none !important;
}
</style>
