<template>
  <v-menu
    v-model="open"
    @update:model-value="handleMenuOpen"
    :close-on-content-click="false"
  >
    <template #activator="{ props }">
      <slot :props="props"></slot>
    </template>

    <v-card>
      <v-list-subheader class="px-4 pt-4">Attach from</v-list-subheader>

      <v-list>
        <!-- eslint-disable-next-line vue/valid-v-on -->
        <v-list-item class="attachment-file-container" @click="">
          <template v-slot:prepend>
            <v-icon start>mdi-file</v-icon>
          </template>
          <v-list-item-title>Computer</v-list-item-title>

          <input
            class="attachment-file-input"
            type="file"
            accept="image/*,video/*,.pdf,.doc,.docx,.xls,.xlsx"
            @change="addFileAttachment"
          />
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list-subheader class="px-4 pt-4">Attach link</v-list-subheader>

      <v-card-text class="url-attachment-container">
        <v-row class="justify-space-between align-center">
          <v-col>
            <v-text-field
              variant="underlined"
              class="url-attachment-input"
              type="url"
              placeholder="Paste any link here"
              v-model="linkAttachment"
            ></v-text-field>
          </v-col>
          <v-col class="flex-shrink-1">
            <v-btn variant="text" class="url-attach-btn" @click="addLinkAttachment">
              Attach
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import Storage from '@/storage'
import store from '@/store'

// attachment object
//  {
//    id: String!
//    bytes: Number
//    date: String | Date!
//    name: String!
//    mimeType: String
//    user: User
//    url: String!
//  }
export default {
  props: ['item'],

  data () {
    return {
      linkAttachment: '',
      open: false
    }
  },

  methods: {
    handleMenuOpen (open) {
      if (!open) {
        this.linkAttachment = ''
      }
    },
    async addLinkAttachment () {
      const workflowId = this.$route.params.id
      const re = /^https?:\/\//
      let link
      this.error = ''
      if (re.test(this.linkAttachment)) {
        link = this.linkAttachment
      } else {
        link = `https://${this.linkAttachment}`
      }
      await store.dispatch('workflow/createCardAttachment', {
        workflowId,
        input: {
          type: 'LINK',
          name: link,
          url: link,
          requirementId: this.item.id
        }
      })
      this.closeMenu()
    },
    async addFileAttachment (ev) {
      const maxSize = 100 * 1024 * 1024 // 100 MB in bytes
      const projectId = this.$route.params.projectId
      const workflowId = this.$route.params.id

      this.error = ''

      const file = ev.target.files[0]
      const storage = new Storage()

      if (!file) return

      if (file.size > maxSize) {
        return store.commit('snackbar/showMessage', {
          content: 'Please upload an attachment that is 100MB or smaller.',
          timeout: 6000,
          color: 'red',
          centered: true
        }, { root: true })
      }

      try {
        // this.$loadingState.start()
        const data = await storage.uploadFile(
          `${projectId}/${workflowId}/${this.item.id}/${file.name}`,
          file
        )
        const { Location, Bucket, Key } = data
        await store.dispatch('workflow/createCardAttachment', {
          workflowId,
          input: {
            type: 'FILE',
            requirementId: this.item.id,
            url: Location,
            name: file.name,
            file: {
              region: Storage.region,
              bucket: Bucket,
              key: Key
            },
            mimeType: file.type
          }
        })
        this.closeMenu()
      } catch (e) {
        console.log(e)
        this.error = 'An error occurred while creating attachment'
        this.closeMenu()
        this.$loadingState.stop()
      }
    },
    closeMenu () {
      this.open = false
      this.linkAttachment = ''
    }
  }
}
</script>

<style>
.attachment-file-container {
  position: relative;
  cursor: pointer;
}

.attachment-file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  opacity: 0;
  cursor: pointer;
}

.url-attachment-container {
  padding-top: 0;
}

.url-attachment-input {
  width: 300px;
}
</style>
