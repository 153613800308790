import gql from 'graphql-tag'

export default gql`
  subscription OnDeleteCurrentTeamMember($userId: String!)  {
    onDeleteCurrentTeamMember (userId: $userId) {
      id
      projectId
      userId
    }
  }
`
