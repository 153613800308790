export default `
  query listProjectMembershipsForCurrentUser {
    listProjectMembershipsForCurrentUser {
      id
      userId
      lastLogin
      project {
        id
        name
        organisationId
        organisation {
          id
          name
          userId
          user {
            id
            firstname
            lastname
          }
        }
        jiraForgeData {
          projectId
        }
        goals {
          title
          description
        }
      }
    }
  }
`
