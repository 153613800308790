<template>
  <v-select
    :rules="[rules.required]"
    :modelValue="modelValue"
    :items="aiModels"
    item-title="name"
    item-value="version"
    label="Select AI model"
    hide-no-data
    hide-details
    variant="solo"
    @update:model-value="saveAiVersion"
    :flat="true"
  />
</template>

<script>
import workflowApi from '@/api/workflowApi'
import { mapState } from 'vuex'

export default {
  name: 'AiVersionSelector',
  props: {
    modelValue: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      aiModels: [
        { name: 'Qlerify', version: 5 },
        { name: 'OpenAI ChatGPT-4o', version: 6 },
        { name: 'OpenAI ChatGPT-4o-mini', version: 3 },
        { name: 'OpenAI ChatGPT-4', version: 4 }
      ],
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  computed: {
    ...mapState({
      workflow: state => state.workflow.workflow
    })
  },
  methods: {
    async saveAiVersion (newVal) {
      this.$emit('update:modelValue', newVal)
      await workflowApi.updateWorkflowNoHistoryMutation(
        this.$route.params.projectId,
        {
          id: this.$route.params.id,
          expectedVersion: this.workflow.version,
          aiVersion: newVal
        }
      )
    }
  },
  watch: {
    workflow: {
      handler (newVal) {
        this.$emit('update:modelValue', newVal.aiVersion || 3)
      },
      deep: true,
      immediate: true
    }
  }
}

</script>
