import { apolloClient } from '@/vue-apollo'
import listProjectMembershipsForCurrentUser from '@/graphql/queries/ListProjectMembershipsForCurrentUser'
import OnCreateTeamMemberForUser from '@/graphql/subscriptions/OnCreateTeamMemberForUser'
import OnDeleteCurrentTeamMember from '@/graphql/subscriptions/OnDeleteCurrentTeamMember'
import UpdateTeamMemberLastLogin from '@/graphql/mutations/UpdateTeamMemberLastLogin'
import client from '@/graphql/client'

export const projectMemberships = {
  namespaced: true,
  state: {
    projectMemberships: null,
    activeProjectId: null
  },
  mutations: {
    setProjectMemberships (state, payload) {
      state.projectMemberships = payload
    },
    addProjectMembership (state, payload) {
      const index = state.projectMemberships.findIndex(membership => membership.id === payload.id)
      if (index === -1) {
        state.projectMemberships.push(payload)
      }
    },
    deleteProjectMembership (state, payload) {
      state.projectMemberships = state.projectMemberships.filter(membership => membership.project.id !== payload.projectId)
    },
    updateProject (state, payload) {
      const index = state.projectMemberships.findIndex(membership => membership.project.id === payload.id)
      state.projectMemberships[index].project = payload
    },
    updateTeamMemberLastLogin (state, payload) {
      const index = state.projectMemberships.findIndex(membership => membership.id === payload.id)
      if (index >= 0) {
        state.projectMemberships[index] = { ...state.projectMemberships[index], lastLogin: payload.lastLogin }
      }
    },
    setActiveProjectId: (state, payload) => {
      state.activeProjectId = payload
    }
  },
  actions: {
    async updateTeamMemberLastAccessToProject ({ commit, state }, { projectId }) {
      const index = state.projectMemberships?.findIndex(membership => membership.project.id === projectId)
      if (index > -1) {
        const teamMemberId = state.projectMemberships[index].id
        try {
          const res = await apolloClient.mutate({
            mutation: UpdateTeamMemberLastLogin,
            variables: {
              id: teamMemberId,
              projectId
            }
          })
          commit('updateTeamMemberLastLogin', res.data.updateTeamMemberLastLogin)
        } catch (e) {
          console.log(e)
        }
      }
    },

    async listProjectMembershipsForCurrentUser ({ commit, rootState }) {
      const res = await client.query({
        operationName: 'listProjectMembershipsForCurrentUser',
        query: listProjectMembershipsForCurrentUser
      })
      commit('setProjectMemberships', res.listProjectMembershipsForCurrentUser)

      apolloClient.subscribe({
        query: OnCreateTeamMemberForUser,
        variables: {
          userId: rootState.userId
        },
        fetchPolicy: 'no-cache'
      }).subscribe({
        next (res) {
          commit('addProjectMembership', res.data.onCreateTeamMember.createTeamMemberFunction)
        },
        error (error) {
          console.error('Subscription error:', error)
        }
      })

      apolloClient.subscribe({
        query: OnDeleteCurrentTeamMember,
        variables: {
          userId: rootState.userId
        },
        fetchPolicy: 'no-cache'
      }).subscribe({
        next (res) {
          commit('deleteProjectMembership', res.data.onDeleteCurrentTeamMember)
        },
        error (error) {
          console.error('Subscription error:', error)
        }
      })
    }
  },
  getters: {
    activeProject: (state) => {
      if (!state.activeProjectId) return null
      return state.projectMemberships.find(it => it.project.id === state.activeProjectId)?.project
    },
    myProjects (state, getters, rootState) {
      const res = []
      for (let i = 0; i < state.projectMemberships.length; i++) {
        if (state.projectMemberships[i].project.organisation.userId === rootState.userId) {
          const obj = Object.assign({}, state.projectMemberships[i].project)
          res.push(obj)
        }
      }
      return res
    }
  }
}
