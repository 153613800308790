<template>
  <v-dialog max-width="30%" v-model="open" content-class="dialog-top-right">
    <template v-slot:activator="{ props }">
      <v-icon v-bind="props" size="small"> mdi-help-circle-outline</v-icon>
    </template>

    <v-card flat>
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title><v-icon> mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>Star Schema</b></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon icon="mdi-close"></v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <h3><v-icon icon="mdi-star"></v-icon>&nbsp;Associated Entities / Star Schema</h3>
        This view shows entities referenced by the Event Story and the Fact Table.
        It can also be helpful to think of the entities as dimensions with details
        describing the business event. Later, when connecting Business Intelligence
        (BI) to analyse events, we typically group the data by the dimensions.
        <br /><br />
        To prepare for future reporting needs, examine all the fields
        of each dimension and ask yourself if these fields cover
        all the reporting requirements for the business event.
        <br /><br />
      </v-card-text><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  }
}
</script>

<style>
.v-dialog .help-ai {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 30%;
  overflow-y: scroll;
}
</style>
