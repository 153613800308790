import gql from 'graphql-tag'

export default gql`
  query listDemoWorkflows {
    listDemoWorkflows {
      id
      name
      eventsJson {
        requirementsJson {
          id
        }
      }
    }
  }
`
