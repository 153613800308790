<template>
  <v-layout
    wrap
    align-center
    class="py-2 flex-wrap flex-column pa-4"
  >
    <v-row>
      <v-col
        cols="4"
        class="type-title"
      >
        {{definition.title}}
      </v-col>

      <v-col cols="1" class="text-center">
        <workflow-settings-requirement-type-color-picker
          :color="definition.color && definition.color.rgb"
          :variants="['lighten']"
          @change="changeColor"
        ></workflow-settings-requirement-type-color-picker>
      </v-col>
    </v-row>
    <v-row xs12>
      <v-col cols="12">
        <div
          v-if="!showDescription"
          class="text-caption text-light-blue expension-btn"
          @click="openDescription"
        >
          Show description <v-icon size="x-small" color="light-blue">chevron_right</v-icon>
        </div>

        <div
          v-else
          class="text-caption text-light-blue expension-btn"
          @click="closeDescription"
        >
          Hide description <v-icon size="x-small" color="light-blue">chevron_right</v-icon>
        </div>

        <v-expand-transition>
          <v-card
            v-show="showDescription"
            class="rounded-lg"
          >
          <textarea
            v-model="descriptionModel"
            rows="2"
            class="pa-2"
            ref="textareaElement"
            @focus="computeHeight"
            @keydown.exact="computeHeight"
            @keydown.enter.exact.prevent="submitDescriptionChange"
            @keydown.alt.enter.exact.prevent="handleDescriptionAltEnter"
            @keydown.27.stop="cancelDescriptionChange"
            @cut="computeHeight"
            @paste="computeHeight"
            @drop="computeHeight"
            :disabled="$store.state.isWorkflowDisabled"
          ></textarea>

            <v-layout justify-end class="pa-1">
              <v-btn
                size="small"
                class="ma-0 mx-2 action-button"
                color="primary"
                variant="text"
                :disabled="descriptionModel === definition.description"
                @click="cancelDescriptionChange"
              >Cancel</v-btn>

              <v-btn
                size="small"
                class="ma-0 action-button"
                color="primary"
                :disabled="descriptionModel === definition.description"
                @click="submitDescriptionChange"
              >Save</v-btn>
            </v-layout>
          </v-card>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import WorkflowSettingsRequirementTypeColorPicker from '@/components/WorkflowSettingsRequirementTypeColorPicker'

export default {
  components: {
    WorkflowSettingsRequirementTypeColorPicker
  },

  props: {
    definition: Object,
    disabled: Boolean
  },

  data () {
    return {
      showDescription: false,
      descriptionModel: ''
    }
  },

  methods: {
    _emitUpdate (payload) {
      const {
        id,
        color: { red, green, blue },
        description
      } = this.definition

      const input = {
        color: { red, green, blue },
        description,
        ...payload
      }

      this.$emit('update-definition', id, input)
    },

    changeColor (color) {
      this._emitUpdate({ color })
    },

    computeHeight () {
      if (!this.$refs.textareaElement) {
        return
      }

      const { textareaElement } = this.$refs

      this.$nextTick(() => {
        setImmediate(() => {
          textareaElement.style.height = 'auto'
          textareaElement.style.height = `${textareaElement.scrollHeight}px`
        })
      })
    },

    openDescription () {
      this.descriptionModel = this.definition.description || ''
      this.showDescription = true

      setTimeout(() => {
        if (this.$refs.textareaElement) {
          this.$refs.textareaElement.focus()
        }
      }, 50)
    },

    closeDescription () {
      this.showDescription = false
    },

    handleDescriptionAltEnter (event) {
      const { textareaElement } = this.$refs
      const startPosition = textareaElement.selectionStart
      const endPosition = textareaElement.selectionEnd
      const result = [
        this.descriptionModel.slice(0, startPosition),
        '\n',
        this.descriptionModel.slice(endPosition)
      ].join('')

      // update textarea value
      this.descriptionModel = result

      // set cursor position
      this.$nextTick(() => {
        textareaElement.selectionEnd = endPosition + 1
      })
    },

    cancelDescriptionChange () {
      this.descriptionModel = this.definition.description || ''
      this.$refs.textareaElement.blur()
    },

    submitDescriptionChange () {
      this._emitUpdate({ description: this.descriptionModel })
    }
  }
}
</script>

<style scoped>
  .type-title {
    height: 28px;
    line-height: 28px !important;
  }

  textarea {
    width: 100%;
    resize: none;
    outline: none;
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    min-height: 40px;
  }

  .action-button {
    border-radius: 6px !important;
    min-width: 60px;
  }

  .expension-btn {
    cursor: pointer;
  }
</style>
