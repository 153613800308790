<template>
  <div>
    <navigation-top />
    <v-container v-if="workflows && !loadingState" class="pa-0 ml-0" style="max-width: 1280px;">
      <v-row>
        <v-col cols="8" class="pr-0">
          <template v-for="workflowType in ['starredWorkflows', 'notStarredWorkflows']">
            <v-card v-if="workflowsByType[workflowType]?.length > 0 || workflowType == 'notStarredWorkflows'" class="pa-6 mb-4" :key="workflowType">
              <h2 v-if="workflowType == 'starredWorkflows'">Starred workflows</h2>
              <h2 v-else>
                {{ workflowsByType['starredWorkflows']?.length > 0 ? "Other" : "All"}}
                workflows in {{ project?.name }}
              </h2>
              <v-row class="py-3">
                <!-- place holder for creating new workflow if no workflows -->
                <v-card
                  v-if="workflowType == 'notStarredWorkflows' && workflowsByType['notStarredWorkflows'].length == 0 && workflowsByType['starredWorkflows'].length == 0"
                  style="height: 108px; width: 361px; margin-bottom: 14px;"
                  class="mx-3 mt-8 d-flex workflow-card align-center justify-center"
                >
                  <div class="d-flex align-center">
                    No workflows yet
                  </div>
                </v-card>
                <template v-else>
                  <!-- list workflow -->
                  <v-col
                    v-for="workflow in workflowsByType[workflowType]"
                    :key="workflow.id"
                    cols="6"
                  >
                    <dashboard-workflow-card
                      :workflow="workflow"
                      @edit="editWorkflow"
                    ></dashboard-workflow-card>
                  </v-col>
                </template>
              </v-row>
              <div v-if="workflowType == 'notStarredWorkflows'" class="d-flex justify-end mt-4">
                <workflow-dialog ref="dialogWorkflow">
                  <template #activator="{ props }">
                    <v-btn color="primary" v-bind="props">
                      Create New Workflow
                    </v-btn>
                  </template>
                </workflow-dialog>
              </div>
            </v-card>
          </template>

          <dashboard-ai-project
            :insideProject="true"
          ></dashboard-ai-project>
        </v-col>

        <v-col cols="8" lg="4" class="pr-sm-0 pr-lg-3">
          <v-card class="dashboard-container pa-6 ma-0 d-flex flex-column" style="min-height: 290px;">
            <h3 class="text-h5 font-weight-bold pb-4">Project members</h3>
            <base-team-member v-for="member in activeMembers" :teamMember="member" :project="project" :key="member.id"/>
            <!-- <v-divider v-if="this.pendingMembers.length"></v-divider> -->
            <v-list-subheader v-if="this.pendingMembers.length" class="px-0">Members not logged in to project yet</v-list-subheader>
            <base-team-member v-for="member in pendingMembers" :teamMember="member" :project="project" :key="member.id"/>
            <div class="d-flex justify-end mt-4 mt-auto">
              <invite-team-member-dialog>
                <template #activator="{ props }">
                  <v-btn v-bind="props" color="primary">Invite a new member</v-btn>
                </template>
              </invite-team-member-dialog>
            </div>
          </v-card>

          <v-card class="dashboard-container pa-6 ma-0 mt-3 d-flex flex-column" style="min-height: 290px;">
            <h3 class="text-h5 font-weight-bold pb-4">Project Goals</h3>
            <goal-list
              ref="goalList"
              placeholder="You have not added any goals in this project yet."
              :goals="project?.goals || []"
              @changed="updateGoals"
            ></goal-list>
            <div class="d-flex justify-end mt-4 mt-auto">
              <v-btn @click="userAddGoal" color="primary">Add project goal</v-btn>
            </div>
          </v-card>
        </v-col>
        <v-col cols="8" class="pr-0">
          <div class="my-1">
            <v-icon size="small" icon="mdi-arrow-left" color="grey-darken-1"/>
            <router-link :to="{ path: '/' }">
              <span class="light--text">Back to all projects</span>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import WorkflowDialog from '@/components/WorkflowDialog'
import InviteTeamMemberDialog from '@/components/InviteTeamMemberDialog'
import DashboardWorkflowCard from '@/components/DashboardWorkflowCard'
import NavigationTop from '@/components/NavigationTop'
import DashboardAiProject from '@/components/DashboardAiProject'
import BaseTeamMember from '@/components/BaseTeamMember'
import GoalList from '@/components/GoalList'
import projectApi from '@/api/projectApi'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    WorkflowDialog,
    InviteTeamMemberDialog,
    DashboardWorkflowCard,
    NavigationTop,
    BaseTeamMember,
    GoalList,
    DashboardAiProject
  },
  props: {
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      workflows: state => state.workflows.workflows,
      listTeamMembers: state => state.teamMembers.teamMembers
    }),
    ...mapGetters({
      project: 'projectMemberships/activeProject',
      loadingState: 'loading/getLoadingState'
    }),
    activeMembers () {
      if (!Array.isArray(this.listTeamMembers) || !this.listTeamMembers.length) {
        return []
      }

      return this.listTeamMembers.filter(({ lastLogin }) => lastLogin)
    },

    pendingMembers () {
      if (!Array.isArray(this.listTeamMembers) || !this.listTeamMembers.length) {
        return []
      }

      return this.listTeamMembers.filter(({ lastLogin }) => !lastLogin)
    },

    workflowsByType () {
      const res = {
        starredWorkflows: this.starredWorkflows,
        notStarredWorkflows: this.notStarredWorkflows
      }
      return res
    },

    starredWorkflows () {
      const res = []
      for (const workflow of this.workflows) {
        if (workflow.starred) {
          res.push(workflow)
        }
      }
      return res
    },

    notStarredWorkflows () {
      const res = []
      for (const workflow of this.workflows) {
        if (!workflow.starred) {
          res.push(workflow)
        }
      }
      return res
    }
  },

  methods: {
    userAddGoal () {
      this.$refs.goalList.userAddGoal()
    },

    async updateGoals (goals) {
      const updatedProject = JSON.parse(JSON.stringify(this.project))
      updatedProject.goals = goals
      await projectApi.updateProject(updatedProject)
    },

    editWorkflow (item) {
      this.$refs.dialogWorkflow[0].setItem(Object.assign({}, item))
      this.$refs.dialogWorkflow[0].openWorkflowDialog()
    }
  }
}
</script>

<style scoped>
.dashboard-container a {
  color: white;
}

.dashboard-container {
  max-width: 1000px;
  min-height: 250px;
  /* margin-left: 12px;
  margin-top: 12px; */
}

/* .row {
  border: 1px solid blue;
}
*/

</style>
