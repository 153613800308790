<template>
  <v-dialog :scrollable="true" max-width="30%" v-model="open" content-class="dialog-top-right">
    <template v-slot:activator="{ props }">
      <v-icon v-bind="props" class="ml-2">mdi-help-circle-outline</v-icon>
    </template>

    <v-card>
      <v-toolbar density="compact" flat dark color="primary">
        <v-toolbar-title><v-icon> mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>Create Dimension</b></v-toolbar-title>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <h3><span class="bg-green-lighten-4">Create Dimension</span></h3>
        There are four different ways you can create a Dimension:<br /><br />
        <ul>
          <li>
            <b>Fact Table + Dimensions from Event Story with AI</b>
            <br />
            This method uses AI to analyze the Event Story and create a Fact Table and
            multiple Dimension Tables. It is recommended if you do not have a Fact Table
            and need a quick starting point.
          </li>
          <br />
          <li>
            <b>Dimensions from Fact Table with AI</b>
            <br />
            This method uses AI to analyze the Fact Table and create the applicable Dimension
            Tables. More than one Dimension Table can be created if they do not already exist.
            It is recommended if you have an elaborate Fact Table.
          </li>
          <br />
          <li>
            <b>Static example data</b>
            <br />
            A single Dimension Table containing example data will be created.
          </li>
          <br />
          <li>
            <b>Empty table</b>
            <br />
            An empty Dimension Table will be created.
          </li>
        </ul>
        <br />
        Note: When using AI, information about your workflow will be shared with OpenAI.
        <br />
      </v-card-text><br /><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  }
}
</script>

<style>
.v-dialog .help-ai {
  position: absolute;
  top: -400px;
  right: 400px;
  max-width: 30%;
  overflow-y: scroll;
}
</style>
