import gql from 'graphql-tag'

export default gql`
  subscription OnDeleteWorkflow($projectId: String!) {
    onDeleteWorkflow (projectId: $projectId) {
      deleteWorkflow {
        id
      }
      projectId
    }
  }
`
