<template>
  <v-dialog v-model="dialogTeamMember" max-width="500px">
    <template v-slot:activator="{ props }">
      <slot name="activator" :props="props"></slot>
    </template>

    <v-card>
      <v-container class="pr-0">
        <v-row class="v-container--fluid justify-space-between align-top">
          <v-card-title>
            <span class="text-h5">Invite Team Member to Project</span>
          </v-card-title>

            <v-btn variant="text" icon color="grey" @click="closeTeamMember">
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-row>
      </v-container>
      <v-card-text>
        <v-container grid-list-md>
          <v-form ref="form" v-model="valid">
            <v-layout wrap>
              <v-col cols="12">
                <v-text-field
                  name="team-member-email"
                  label="Team Member Email"
                  :autofocus="true"
                  validate-on="blur"
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                  @keyup.enter="saveTeamMember"
                ></v-text-field>
                <!-- keyup.enter doesn't work without this row, seems like a bug in vue or vuetify -->
                <v-text-field class="hidden" disabled></v-text-field>
              </v-col>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="text" @click="closeTeamMember">Cancel</v-btn>
        <v-btn color="primary" @click="saveTeamMember">Invite</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SES from '@/ses'
import { mapActions, mapGetters, mapState } from 'vuex'
import store from '@/store'

export default {
  data () {
    return {
      dialogTeamMember: false,
      email: '',
      rules: {
        required: value => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      valid: true
    }
  },

  computed: {
    ...mapGetters({
      project: 'projectMemberships/activeProject'
    }),
    ...mapState({
      currentUser: state => state.user.user
    })
  },

  methods: {
    ...mapActions({
      createTeamMember: 'teamMembers/createTeamMember'
    }),
    closeTeamMember () {
      this.email = ''
      this.valid = true
      this.dialogTeamMember = false
    },

    async saveTeamMember () {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const email = this.email
        const res = await this.createTeamMember({ email, projectId: this.$route.params.projectId })
        if (res) {
          try {
            const emailService = new SES()
            await emailService.sendEmail(
              email,
              this.project,
              this.currentUser.firstname + ' ' + this.currentUser.lastname
            )
            store.commit('snackbar/showMessage', { content: 'Invitation has been sent' }, { root: true })
            this.closeTeamMember()
          } catch (e) {
            console.log(e)
            store.commit('snackbar/showMessage', { content: 'Could not send email', timeout: 6000, color: 'red' }, { root: true })
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.hidden {
  display: none;
}
</style>
