<template>
  <div>
    <v-card>
      <v-card-actions class="pl-3 pr-3 pt-3 pb-3">
        <v-btn color="primary" class="pl-2 pr-2" @click="exportJson(jsonData)">Download JSON file</v-btn>
        <v-btn class="pl-2 pr-2" @click="closeDialog()">Cancel</v-btn>
      </v-card-actions>
      <v-card-text>
        <pre class="ma-5 pa-5">{{ jsonData }}</pre>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { cardHelpers } from '@/mixins/cardHelpers.js'
import { mapState } from 'vuex'

export default {
  props: ['workflow'],
  mixins: [cardHelpers],
  data () {
    return {}
  },
  methods: {
    exportJson (arrData) {
      let jsonContent = 'data:text/json;charset=utf-8,'
      jsonContent += JSON.stringify(this.workflow, null, 2)
      const data = encodeURI(jsonContent)
      const link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute('download', 'export.json')
      link.click()
    },
    closeDialog () {
      this.$emit('closeExportJsonDialog')
    },
    removeAttributes (obj, attributeNames = ['version', 'updateNote', 'teamMembers']) {
      const attributesToRemove = [...attributeNames]
      if (Array.isArray(obj)) {
        // If the object is an array, recursively call this function on each item in the array
        return obj.map((item) => this.removeAttributes(item))
      } else if (typeof obj === 'object' && obj !== null) {
        // If the object is not an array and is an object (and not null), recursively call this function on each property
        const newObj = {}
        Object.keys(obj).forEach((key) => {
          if (!attributesToRemove.includes(key)) {
            newObj[key] = this.removeAttributes(obj[key])
          }
        })
        return newObj
      } else {
        // If the object is not an array and is not an object, return the value as is
        return obj
      }
    }
  },
  computed: {
    ...mapState({
      requirementTypes: state => state.requirementTypes.requirementTypes
    }),
    jsonData () {
      return JSON.stringify(this.removeAttributes(this.workflow), null, 4)
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  padding: 1em;
  font-family: sans-serif;
  color: #444;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  padding: 0.3em 0.5em;
  border: 1px solid #ddd;
}
.dl dt {
  float: left;
  color: #999;
}

html, body, textarea {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
}
textarea {
 vertical-align:top;
}
</style>
