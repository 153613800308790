import store from '@/store'

export default class requirementTypesApi {
  static getBackgroundColour (cardType) {
    if (cardType && cardType.color && this.cardTypeExists(cardType)) {
      return 'rgb(' + cardType.color.red + ',' + cardType.color.green + ',' + cardType.color.blue + ')'
    } else {
      return '#eeeeee'
    }
  }

  static getTextColour (cardType) {
    if (cardType && cardType.color && this.cardTypeExists(cardType)) {
      if ((cardType.color.red * 0.299 + cardType.color.green * 0.587 + cardType.color.blue * 0.114) > 186) {
        return '#000000'
      } else {
        return '#ffffff'
      }
    } else {
      return '#000000'
    }
  }

  static async cardTypeExists (cardType) { // requires async, don't know why
    if (!cardType) {
      return false
    }
    const cardTypes = store.state.requirementTypes.requirementTypes
    return cardTypes.includes(cardType.id)
  }

  static async updateRequirementType (workflowId, requirementType, payload) {
    const {
      id,
      title,
      color: { red, green, blue },
      description,
      showInBacklog,
      showInDiagram,
      triggerType,
      aggregateType,
      defaultType,
      aiCount
    } = requirementType

    const input = {
      title,
      color: { red, green, blue },
      description,
      showInBacklog,
      showInDiagram,
      triggerType,
      aggregateType,
      defaultType,
      aiCount,
      ...payload
    }
    await store.dispatch('requirementTypes/updateRequirementType', { workflowId, id, input })
  }
}
