export default {
  // creates a map with eventIDs as keys
  // each value is an array of ONLY events that has the key in its parents[0] prop
  // parents[0] is the main parent of an event.
  // an event is always rendered next to it's main parent
  // except if it belongs to a different group than the parent
  // the return value of this function can be used
  // to quicky find the children to be rendered next to (or after) an event
  createMainParentChildEventMap (events) {
    const resultMap = {
      start: []
    }
    for (const event of events || []) {
      const parent = event.parents[0]
      if (parent) {
        if (!resultMap[parent]) {
          resultMap[parent] = []
        }
        resultMap[parent].push(event)
      }
    }
    return resultMap
  },

  // creates a map with eventIDs as keys
  // each value is an array of ALL events that has the key in its parents prop
  // the return value of this function can be used
  // to quicky find all children of a parent
  createAllParentChildEventMap (svgGraph) {
    const eventsArray = Object.values(svgGraph || {})
    eventsArray.sort(function (a, b) { return a.id < b.id })
    const resultMap = {
      start: []
    }
    for (const event of eventsArray) {
      if (svgGraph[event.id].parents) {
        for (const parent of svgGraph[event.id].parents) {
          if (parent) {
            if (!resultMap[parent]) {
              resultMap[parent] = []
            }
            resultMap[parent].push(svgGraph[event.id])
          }
        }
      }
    }
    return resultMap
  },

  slotToGroupMap (groups, noOfSlots) {
    const lastGroup = groups[groups.length - 1]
    const lastSlot = Math.max(noOfSlots, lastGroup.startSlotX + 2)
    let res = null
    let currentGroup = 0
    let nextGroup = 1
    if (groups[currentGroup]) {
      res = {}
      for (let slot = 0; slot <= lastSlot; slot++) {
        if (!groups[nextGroup] || (slot < groups[nextGroup].startSlotX)) {
          res[slot] = groups[currentGroup]
        } else if (slot === groups[nextGroup].startSlotX) {
          currentGroup = nextGroup
          nextGroup++
          res[slot] = groups[currentGroup]
        }
      }
    }
    return res
  }
}
