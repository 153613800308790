<template>
  <div v-on:keydown.stop>
    <v-data-table
      v-if="items"
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      :search="search"
      :custom-filter="filterOnRealease"
      :custom-key-sort="customSort"
      :group-by="releaseNoAssigned(items) ? groupBy : []"
      :group-header="false"
      no-data-text="No cards (click on 'Add Card ...' to add one)"
      :class="releaseNoAssigned(items) ? 'hide-first-column ' : '' + 'elevation-1'"
      must-sort
      ref="sortableTable"
    >
      <template v-slot:bottom></template>

      <template #group-header="props">
        <td
          colspan="8"
          class="bg-grey-darken-2 px-4 py-2 text-white"
          :data-release-no="props.group"
        >
          <b>
            {{ props.isGroupOpen(props.item) ? '' : props.toggleGroup(props.item) }}
            {{ props.item.value ? "Release " + props.item.value : "No release assigned" }}
          </b>
        </td>
      </template>

      <template #item="props">
        <tr
          class="sortableRow"
          :key="props.item.id"
          :id="props.item.id"
          :data-sortkey="props.item.sortkey"
          :data-workflow-event-id="props.item.eventId"
          :data-release-no="props.item.releaseNo"
        >
          <td class="px-1">
            <v-btn
              style="cursor: move"
              icon="mdi-drag-horizontal-variant"
              class="sortHandle"
              tabindex="-1"
              :disabled="$store.state.isWorkflowDisabled"
            >
            </v-btn>
          </td>

          <td class="text-center row-number">
            {{ idIndexMap[props.item.id] + 1 }}
          </td>

          <td
            class="text-left description-column"
          >
            <div class="mt-1 mb-1">
              <requirement-description
                :item="props.item"
                :creator="props.item.creator"
                :jiraData2="jiraData2"
                :loadingJiraInfo="loadingJiraInfo"
                :showAi="true"
                @ask-for-reply="onAskForReply($event, props.item)"
                withDates
                replyWithDates
                hideTypeSelector
              >
                <requirement-card-description-field
                  :item="props.item"
                  :index="idIndexMap[props.item.id] * 4 + 1"
                  :focus-card="focusCard"
                  @move-focus="moveFocus"
                  :scrollIntoViewArg="true"
                ></requirement-card-description-field>
              </requirement-description>
            </div>
          </td>

          <!-- <td class="text-center">
            <users-menu
              :card-id="props.item.id"
              :items="teamMembersList"
              :selected="props.item.assignee?.id"
              @change="$emit('assign-card', props.item, $event)"
            ></users-menu>
          </td> -->

          <td
            class="text-center"
            @click="focusOnTabindex(idIndexMap[props.item.id] * 4 + 2)"
          >
            <card-type-selector
              :card="props.item"
              :tabIndex="idIndexMap[props.item.id] * 4 + 2"
              @keydown-card-type="keydownCardType"
            />
          </td>

          <td
            class="text-center"
            @click="focusOnTabindex(idIndexMap[props.item.id] * 4 + 3)"
          >
            <v-menu v-model="menu[props.item.id]" :disabled="$store.state.isWorkflowDisabled" style="z-index: 99999;">
              <template v-slot:activator="{ props: btnProps }">
                <v-btn
                  v-bind="btnProps"
                  :tabindex="idIndexMap[props.item.id] * 4 + 3"
                  @keydown="
                    !$store.state.isWorkflowDisabled &&
                      keydownReleaseNo($event, props.item.id, btnProps.onKeydown)
                  "
                  color="white"
                  class="status-button release-no"
                  :disabled="$store.state.isWorkflowDisabled"
                >
                  <div>
                    <template v-if="props.item.releaseNo">
                      R{{ props.item.releaseNo }}
                    </template>
                    <template v-else>
                      <v-icon class="select-release-for-card" size="small" icon="mdi-dots-horizontal" />
                    </template>
                  </div>
                </v-btn>
              </template>

              <v-list>
                <v-list-subheader>Select release:</v-list-subheader>
                <v-list-item
                  v-for="releaseNo in availableReleases"
                  :key="releaseNo"
                  @click="$emit('change-release-no', props.item, releaseNo)"
                >
                  <v-list-item-action> R{{ releaseNo }} </v-list-item-action>
                </v-list-item>
                <v-list-item @click="$emit('change-release-no', props.item, null)">
                  <v-list-item-action>
                    <v-icon size="small" icon="mdi-dots-horizontal" />
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>

          <td
            class="text-center"
            style="height: 100%; align-items: center"
            @click="focusOnTabindex(idIndexMap[props.item.id] * 4 + 4)"
          >
            <div style="display: grid; grid-template-columns: 47% 6% 47%">
              <v-menu
                v-model="menuPrio[props.item.id]"
                style="justify-self: end; height: 30px !important; z-index: 99999;"
                :disabled="$store.state.isWorkflowDisabled"
              >
                <template #activator="{ props: btnProps }">
                  <v-btn
                    v-bind="btnProps"
                    style="
                      min-width: 25px;
                      max-width: 25px;
                      margin: 0px;
                      padding: 0 0px 0 0;
                      justify-self: end;
                    "
                    :tabindex="idIndexMap[props.item.id] * 4 + 4"
                    :disabled="$store.state.isWorkflowDisabled"
                    @keydown="
                      !$store.state.isWorkflowDisabled &&
                           keydownPrio($event, props.item.id, btnProps.onKeydown)
                    "
                    color="white"
                    class="status-button prio"
                  >
                    <template #default>
                      <template v-if="props.item.status && props.item.status.prio">
                        <v-avatar color="white" size="20">
                          <span class="text-none">{{
                            getMoSCoW(props.item.status.prio)
                          }}</span>
                        </v-avatar>
                      </template>

                      <template v-else>
                        <v-icon size="small" icon="mdi-dots-horizontal" />
                      </template>
                    </template>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-subheader>Select your priority:</v-list-subheader>
                  <v-list-item
                    v-for="myPrio in [1, 2, 3, 4]"
                    :key="myPrio"
                    @click="$emit('change-status', props.item, myPrio)"
                  >
                    <v-list-item-action style="flex-direction: row">
                      <v-col cols="4">
                        <v-avatar :color="getColor(myPrio)" size="30">
                          <span>{{ getMoSCoW(myPrio) }}</span>
                        </v-avatar>
                      </v-col>
                      <v-col cols="8">
                        <div>{{ prioDescriptions[myPrio - 1] }}</div>
                      </v-col>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>

              <div
                style="
                  align-self: center;
                  justify-self: center;
                  grid-column-start: 2;
                "
                class="mx-2"
              >
                &nbsp;/&nbsp;
              </div>

              <v-icon
                v-if="!props.item.status"
                size="x-small"
                style="
                  align-self: center;
                  justify-self: start;
                  grid-column-start: 3;
                  grid-row-start: 1;
                "
                icon="mdi-dots-horizontal" />

              <v-tooltip location="top" v-else open-delay="1000">
                <template v-slot:activator="{ props: tooltipSlot }">
                  <v-avatar
                    :color="
                      getColor(
                        this.getAverage(props.item.requirementStatuses)
                      )
                    "
                    size="30"
                    style="
                      cursor: pointer;
                      align-self: center;
                      grid-column-start: 3;
                      grid-row-start: 1;
                    "
                    @click="$emit('open-requirement-dialog', props.item)"
                  >
                    <span v-bind="tooltipSlot"
                      ><strong>{{
                        getMoSCoW(
                          this.getAverage(props.item.requirementStatuses)
                        )
                      }}</strong></span
                    >
                  </v-avatar>

                  <div
                    style="
                      grid-column-start: 2;
                      grid-column-end: 4;
                      justify-self: start;
                      align-self: start;
                      font-size: 0.7rem;
                      white-space: nowrap;
                    "
                    class="font-weight-bold text--disabled button"
                  >
                    &nbsp;&nbsp;{{
                      getCompletedVotesAndComments(props.item.requirementStatuses)
                    }}
                  </div>
                </template>

                <div>
                  <p class="text-body-2 mb-1 text-decoration-underline">Team Member Votes</p>
                  <div
                    style="display: grid; grid-template-columns: 75% 25%"
                    v-for="(teamMemberReaction, index) in getTeamMembersReactionsList(props.item.requirementStatuses, listTeamMembers)"
                    :key="index"
                  >
                    <p class="mb-0">
                        {{
                          teamMemberReaction.user?.firstname +
                          " " +
                          teamMemberReaction.user?.lastname
                        }}
                      </p>
                      <v-avatar
                        :color="
                          getColor(teamMemberReaction.prio)"
                        size="20"
                        class="text-right"
                        style="justify-self: end"
                      >
                        {{
                          getMoSCoW(teamMemberReaction.prio)
                        }}
                      </v-avatar>
                  </div>
                </div>
              </v-tooltip>
            </div>
          </td>

          <td
            class="text-left"
            @click="!$store.state.isWorkflowDisabled && openDialog(props.item)"
            style="cursor: pointer"
          >
            {{ formatEventName(props.item.eventId) }}
          </td>

          <td class="text-right actions-on-cards" style="white-space: nowrap">
            <v-menu style="z-index: 99999;">
              <template #activator="{ props: btnProps }">
                <v-btn v-bind="btnProps" tabindex="-1" icon="mdi-menu"/>
              </template>

              <v-list>
                <v-list-item
                  :disabled="$store.state.isWorkflowDisabled"
                  @click="$emit('open-requirement-dialog', props.item)"
                >
                  <template v-slot:prepend>
                    <v-icon color="grey">mdi-message-text</v-icon>
                  </template>

                  <v-list-item-title>Votes</v-list-item-title>
                </v-list-item>

                <v-list-item
                  :disabled="$store.state.isWorkflowDisabled"
                  @click="duplicateRequirement(props.item)"
                >
                  <template v-slot:prepend>
                    <v-icon color="grey">mdi-content-copy</v-icon>
                  </template>

                  <v-list-item-title>Duplicate</v-list-item-title>
                </v-list-item>

                <workflow-delete-item-dialog
                  #default="{ props: deleteBtnProps }"
                  :itemType="'Card'"
                  :cardId="props.item.id"
                >
                  <v-list-item v-bind="deleteBtnProps"
                    :disabled="$store.state.isWorkflowDisabled">
                    <template v-slot:prepend>
                      <v-icon color="grey">mdi-delete</v-icon>
                    </template>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </workflow-delete-item-dialog>

                <v-list-item
                  :disabled="$store.state.isWorkflowDisabled"
                  @click="
                    $emit('item-actions-done', props.item, !props.item.done)
                  "
                >
                  <template v-slot:prepend>
                    <v-icon color="grey">{{
                      props.item.done ? "mdi-undo" : "mdi-check"
                    }}</v-icon>
                  </template>

                  <v-list-item-title>{{
                    props.item.done ? "Reopen" : "Done"
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!-- Change event dialog -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-row fluid justify-space-between align-top>
          <v-col>
            <!-- if new -->
            <v-card-title>
              <span class="text-h5">Move card</span>
            </v-card-title>
          </v-col>
          <v-col class="flex-shrink-1 flex-grow-0">
            <v-btn variant="text" icon color="grey" @click="closeDialog">
              <v-icon >mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text>
          <v-container grid-list-md>
            <v-form ref="form" v-model="validEvents">
              <v-row wrap>
                <v-col cols="12" v-if="workflow.eventsJson?.length > 0">
                  <v-autocomplete
                    :rules="[rules.required]"
                    ref="sequenceInput"
                    v-model="eventId"
                    :items="workflow.eventsJson"
                    variant="underlined"
                    hide-no-data
                    hide-details
                    color="blue-grey-lighten-2"
                    label="Select new event"
                    :item-title="formatValidTargets"
                    item-value="id"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" v-else> No valid targets </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="text" @click="closeDialog">Cancel</v-btn>
          <v-btn color="primary" class="move-card" @click="moveRequirement">{{
            "Move card"
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import RequirementDescription from '@/components/RequirementDescription'
import RequirementCardDescriptionField from '@/components/RequirementCardDescriptionField'
import WorkflowDeleteItemDialog from '@/components/WorkflowDeleteItemDialog'
import CardTypeSelector from '@/components/CardTypeSelector'
import workflowApi from '@/api/workflowApi'
import { cardHelpers } from '@/mixins/cardHelpers.js'
import cardHelpers2 from '@/helpers/cardHelpers.js'
import { mapState } from 'vuex'

export default {
  components: {
    RequirementDescription,
    RequirementCardDescriptionField,
    WorkflowDeleteItemDialog,
    CardTypeSelector
  },

  mixins: [cardHelpers],

  props: {
    items: Array,
    events: Object,
    idIndexMap: Object,
    roleName: String,
    availableReleases: Number,
    computeAverage: Function,
    bpmn: Object,
    search: String,
    workflow: Object,
    focusCard: String,
    jiraData2: Object,
    loadingJiraInfo: Number
  },

  data () {
    return {
      // commented the following row because it looks like it's not needed
      // if you need it, also add :key="tableKey" to the v-data-table tag
      // and the watcher and the method refreshTable
      // tableKey: 0, // Initial key
      prioDescriptions: ['Must have', 'Should have', 'Could have', 'Won\'t have'],
      menu: {},
      menuPrio: {},
      dialog: false,
      validEvents: true,
      rules: { required: value => !!value || 'Required.' },
      eventId: null,
      editRequirement: null,
      groupBy: [{ key: 'releaseIndex', order: 'desc' }],
      headers: [
        { title: 'Reorder', align: 'start', sortable: false, width: '1%', key: 'reorder' },
        { title: 'Row', align: 'left', value: 'sortkey', sortable: false, width: '1%', key: 'row' },
        { title: 'Description', align: 'left', sortable: false, value: 'description', key: 'description' },
        // { title: 'Assignee', align: 'center', sortable: false, width: '10%' },
        { title: 'Type', align: 'center', sortable: false, value: 'cardType.title', width: '13%', key: 'type' },
        { title: 'Release', align: 'center', sortable: false, width: '10%', groupable: true, key: 'releaseIndex' },
        { title: 'My Prio / Team Prio', align: 'center', value: 'prio', sortable: false, width: '10%', key: 'prio' },
        { title: 'Lane / Event', align: 'left', sortable: false, width: '15%', key: 'event' },
        { title: 'Actions', value: 'rank', align: 'right', sortable: false, width: '1%', key: 'actions' }
      ],
      customSort: {
        releaseIndex: (a, b) => {
          const releaseA = a || 9999999999
          const releaseB = b || 9999999999
          return releaseB - releaseA
        }
      }
    }
  },

  computed: {
    ...mapState({
      listTeamMembers: state => state.teamMembers.teamMembers || []
    }),

    teamMembersList () {
      if (!Array.isArray(this.workflow?.teamMembers)) {
        return []
      }
      return this.workflow.teamMembers.map(({ user }) => user)
    }
  },

  methods: {
    // commented this part because it looks like it's not needed
    // refreshTable () {
    //   this.tableKey++ // Increment the key to force re-render
    //   this.$nextTick(() => {
    //     this.initSortable()
    //   })
    // },
    initSortable () {
      this.sortable = new Sortable(this.$refs.sortableTable.$el.getElementsByTagName('tbody')[0], {
        draggable: '.sortableRow',
        handle: '.sortHandle',
        ghostClass: 'sortable-ghost', // Class name for the drop placeholder
        chosenClass: 'sortable-chosen', // Class name for the chosen item
        dragClass: 'sortable-drag',
        scroll: true,
        forceFallback: true,
        scrollSensitivity: 100,
        onEnd: this.onEndDrag,
        onStart: function (event) {
          Array.prototype.forEach.call(
            document.querySelectorAll('.v-row-group__header'),
            function (el, index) {
              if (!el.classList.contains('sortableRow') && index !== 0) {
                el.classList.add('sortableRow')
              }
            }
          )
          Array.prototype.forEach.call(
            document.querySelectorAll('.sortableRow:not(.sortable-chosen)'),
            function (el, index) {
              if (!el.classList.contains('white-background-on-hover') && index !== 0) {
                el.classList.add('white-background-on-hover')
              }
            }
          )
        }
      })
    },
    async duplicateRequirement (item) {
      await workflowApi.createRequirementJson(this.$route.params.id, item.eventId, {
        description: item.description,
        cardTypeId: item.cardTypeId,
        releaseNo: item.releaseNo,
        sortkey: cardHelpers2.newSortkeyNextInList(this.workflow, item.sortkey)
      })
    },
    onAskForReply (event, item) {
      const ev = this.events[item.eventId]
      this.$emit('ask-for-reply', Object.assign({}, event, { node: ev }))
    },
    releaseNoAssigned (cards) {
      const index = cards?.findIndex((card) => card.releaseNo !== null)
      if (index >= 0) {
        return true
      } else {
        return false
      }
    },
    formatEventName (eventId) {
      const res = this.events[eventId] ? this.getLaneNameFromId(this.events[eventId].laneId) + ' / ' + this.events[eventId].description : ''
      return res
    },
    async moveRequirement () {
      await workflowApi.moveRequirementJson(this.$route.params.id, this.$route.params.id, this.eventId, this.editRequirement.id)
      this.closeDialog()
    },
    formatValidTargets (item) {
      return this.getLaneNameFromId(item.laneId) + ' — ' + item.description
    },
    getLaneNameFromId (laneId) {
      let res = ''
      const index = this.workflow.lanes.findIndex((lane) => lane.id === laneId)
      if (index >= 0) {
        res = this.workflow.lanes[index].name
      }
      return res
    },
    openDialog (card) {
      this.editRequirement = card
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
      this.editRequirement = null
    },
    filterOnRealease (value, search, item) {
      if (typeof search !== 'string' || !search.trim()) {
        return true
      }

      const {
        id,
        description,
        releaseNo,
        cardType,
        status,
        creator,
        eventId
      } = item.raw
      let computedStatus

      if (status) {
        const statusValues = ['mo', 's', 'co', 'w']
        const prioIndex = status.prio - 1
        computedStatus = statusValues[prioIndex]
      }

      const searchFields = [
        id,
        description,
        releaseNo,
        'r' + releaseNo,
        cardType && cardType.title,
        computedStatus,
        creator && `${creator.firstname} ${creator.lastname}`,
        eventId
      ].filter(Boolean).join(' ').toLowerCase()
      const searchText = search.toString().toLowerCase()

      return searchFields.includes(searchText)
    },
    getColor (prio) {
      switch (prio) {
        case 1:
          return '#CAF7CF'
        case 2:
          return '#FFD781'
        case 3:
          return 'yellow' // Yellow
        case 4:
          return '#FE8787'
        default:
          return 'white'
      }
    },
    getMoSCoW (prio) {
      switch (prio) {
        case 1:
          return 'Mo'
        case 2:
          return 'S'
        case 3:
          return 'Co' // Yellow
        case 4:
          return 'W'
        default:
          return '...'
      }
    },
    isActive (tabIndex) {
      return document.querySelector(`[tabindex='${tabIndex}']`).isActive
    },
    moveFocus (direction, target, className) {
      switch (direction) {
        case 37: // left arrow
        case 'left':
          this.focusOnPreviousTabindex(target)
          break

        case 38: // up arrow
        case 'up':
          this.focusOnPreviousRow(target, className)
          break

        case 39: // right arrow
        case 'right':
          this.focusOnNextTabindex(target)
          break

        case 13: // enter
        case 40: // down arrow
        case 'down':
          this.focusOnNextRow(target, className)
          break
      }
    },
    keydownReleaseNo (event, itemId, fallback) {
      if (!this.menu[itemId]) {
        this.moveFocus(event.keyCode, event.target, 'release-no')
      } else {
        fallback(event)
      }
    },
    keydownCardType (event) {
      this.moveFocus(event.keyCode, event.target, 'card-type')
    },
    keydownPrio (event, itemId, fallback) {
      if (!(event.target && this.menuPrio[itemId])) {
        this.moveFocus(event.keyCode, event.target, 'prio')
      } else {
        fallback(event)
      }
    },
    focusOnNextRow (target, className) {
      const tr = target.closest('tr')
      let nextTr = tr.nextSibling

      while (nextTr && nextTr.nodeName !== 'TR') {
        nextTr = nextTr.nextSibling
        if (nextTr && nextTr.className === 'v-row-group__header') {
          nextTr = nextTr.nextSibling
        }
      }

      if (nextTr) {
        const nextFocusElement = nextTr.getElementsByClassName(className)[0]
        if (nextFocusElement) {
          nextFocusElement.focus()
        }
      } else {
        target.blur()
        target.focus()
      }
    },
    focusOnPreviousRow (target, className) {
      const tr = target.closest('tr')
      if (tr) {
        let prevTr = tr.previousSibling
        while (prevTr && prevTr.nodeName !== 'TR') {
          prevTr = prevTr.nextSibling
          if (prevTr && prevTr.className === 'v-row-group__header') {
            prevTr = prevTr.nextSibling
          }
        }

        if (prevTr) {
          const prevFocusElement = prevTr.getElementsByClassName(className)[0]
          if (prevFocusElement) {
            prevFocusElement.focus()
          }
        }
      }
    },
    focusOnTabindex (tabindex, event) {
      if (event && event.target.nodeName === 'TEXTAREA') {
        return
      }
      document.querySelector(`[tabindex='${tabindex}']`).focus()
    },
    focusOnPreviousTabindex (target) {
      const prevTabindex = parseInt(target.tabIndex) - 1
      if (prevTabindex > 0) {
        const previousElement = document.querySelector(`[tabindex='${prevTabindex}']`)
        if (previousElement) {
          previousElement.focus()
        }
      }
    },
    focusOnNextTabindex (target) {
      const nextTabindex = parseInt(target.tabIndex) + 1
      if (nextTabindex > 0) {
        const nextElement = document.querySelector(`[tabindex='${nextTabindex}']`)
        if (nextElement) {
          nextElement.focus()
        }
      }
    },
    getCompletedVotesAndComments (teamMembersReactions) {
      let res
      let noOfVotes = 0
      // let noOfComments = 0
      for (const index in teamMembersReactions) {
        if (teamMembersReactions[index]) {
          if (
            teamMembersReactions[index].prio > 0 &&
            teamMembersReactions[index].prio < 5
          ) {
            noOfVotes++
          }

          if (
            teamMembersReactions[index].message &&
            teamMembersReactions[index].message.length > 0
          ) {
            // noOfComments++
          }
        }
      }

      if (noOfVotes === 1) {
        res = '(' + noOfVotes + ' VOTE)'
      } else {
        if (noOfVotes > 0) {
          res = '(' + noOfVotes + ' VOTES)'
        } else {
          res = ''
        }
      }

      // if (noOfComments === 1) {
      //   res += ', ' + noOfComments + ' comment'
      // } else if (noOfComments > 1) {
      //   res += ', ' + noOfComments + ' comments'
      // }

      return res
    },
    onEndDrag (event) {
      this.$emit('drag-reorder', event)
      Array.prototype.forEach.call(
        document.querySelectorAll('.white-background-on-hover'),
        function (el, index) {
          el.classList.remove('white-background-on-hover')
        }
      )
    }
  },
  // commented this part because it looks like it's not needed
  // watch: {
  //   items: {
  //     handler: function (newVal, oldVal) {
  //       // this.refreshTable()
  //     },
  //     deep: true
  //   }
  // },
  mounted () {
    this.initSortable()
  }
}
</script>

<style scoped>

.v-btn.status-button {
  padding: 0 0;
  margin-left: 0;
  margin-right: 0;
  min-width: 40px;
}

.v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
  box-shadow: none;
}

pre {
  font-family: "Roboto";
  font-size: inherit;
  font-weight: inherit;
  display: inherit;
  padding: inherit;
  padding-left: 0;
  vertical-align: inherit;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-break: break-word; /* Internet Explorer 5.5+ */
}

pre[contenteditable="true"]:focus {
  outline: none;
}

pre:focus {
  outline: none;
}

[contenteditable="true"] br {
  display: none;
}

.sortableRow > td:focus-within {
  outline: 2px solid #4d90fe;
}

.fullname {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--light-blue);
}

.btn {
  /* sets the background for the base class */
  background: rgb(var(--red), var(--green), var(--blue));

  /* calculates perceived lightness using the sRGB Luma method
    Luma = (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255 */
  --r: calc(var(--red) * 0.2126);
  --g: calc(var(--green) * 0.7152);
  --b: calc(var(--blue) * 0.0722);
  --sum: calc(var(--r) + var(--g) + var(--b));
  --perceived-lightness: calc(var(--sum) / 255);

  /* shows either white or black color depending on perceived darkness */
  color: hsl(
    0,
    0%,
    calc((var(--perceived-lightness) - var(--threshold)) * -10000000%)
  );
}

.description-column {
  min-width: 300px;
  max-width: 30vw;
  z-index: 0;
}

.v-tooltip__content {
  background: white;
  color: var(--color-primary);
  border-radius: 10px;
}

.v-tooltip__content code {
  background: var(--color-primary);
  color: white;
}

.sortable-chosen {
  background: #eee;
}

.white-background-on-hover:hover {
  background: #fff !important;
}
</style>

<style>
.hide-first-column .v-data-table__td:first-child {
  display: none;
}
</style>
