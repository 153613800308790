import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js'
import { ref } from 'vue'

let cypressRunning
try {
  if (Cypress) {
    cypressRunning = true
  }
} catch (e) {
  cypressRunning = false
}

export default class CognitoAuth {
  constructor () {
    this.userSession = null
    if (cypressRunning) {
      this.configure({
        UserPoolId: Cypress.env('VUE_APP_COGNITO_USER_POOL_ID'),
        ClientId: Cypress.env('VUE_APP_COGNITO_USER_POOL_CLIENT_ID')
      })
    } else {
      this.configure({
        UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
        ClientId: process.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID
      })
    }
  }

  configure (options) {
    this.userPool = new CognitoUserPool({
      UserPoolId: options.UserPoolId,
      ClientId: options.ClientId
    })
  }

  isAuthenticated (cb) {
    const cognitoUser = this.getCurrentUser()
    if (cognitoUser != null) {
      cognitoUser.getSession((err) => {
        if (err) {
          return cb(err, false)
        }
        return cb(null, true)
      })
    } else {
      cb(null, false)
    }
  }

  async isAuthenticatedPromise () {
    return new Promise((resolve) => {
      try {
        const cognitoUser = this?.getCurrentUser()
        if (cognitoUser) {
          cognitoUser.getSession((err, session) => {
            if (!err && session) {
              resolve(session)
            } else {
              resolve(false)
            }
          })
        } else {
          resolve(false)
        }
      } catch (err) {
        Promise.reject(err)
      }
    })
  }

  signup (firstname, lastname, company, phoneNumber, username, pass, referralSource, usageIntent, cb) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'given_name',
        Value: firstname
      }),
      new CognitoUserAttribute({
        Name: 'family_name',
        Value: lastname
      }),
      new CognitoUserAttribute({
        Name: 'custom:organisation',
        Value: company
      }),
      new CognitoUserAttribute({
        Name: 'phone_number',
        Value: phoneNumber
      }),
      new CognitoUserAttribute({
        Name: 'custom:referral_source',
        Value: referralSource
      }),
      new CognitoUserAttribute({
        Name: 'custom:usage_intent',
        Value: usageIntent
      })
    ]

    this.userPool.signUp(username.toLowerCase(), pass, attributeList, null, cb)
  }

  confirmRegistration (username, code, cb) {
    const cognitoUser = new CognitoUser({
      Username: username.toLowerCase(),
      Pool: this.userPool
    })
    cognitoUser.confirmRegistration(code, true, cb)
  }

  resendCode (username, code, cb) {
    const cognitoUser = new CognitoUser({
      Username: username.toLowerCase(),
      Pool: this.userPool
    })
    cognitoUser.resendConfirmationCode(cb)
  }

  forgotPassword (username, cb) {
    const cognitoUser = new CognitoUser({
      Username: username.toLowerCase(),
      Pool: this.userPool
    })

    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        cb(null, result)
      },
      onFailure: function (err) {
        cb(err)
      },
      inputVerificationCode () {
        // cb(Error('Verification code not implemented.'))
        cb()
      }
    })
  }

  confirmPassword (username, code, password, cb) {
    const cognitoUser = new CognitoUser({
      Username: username.toLowerCase(),
      Pool: this.userPool
    })

    cognitoUser.confirmPassword(code, password, {
      onSuccess: (result) => {
        cb(null, result)
      },
      onFailure: function (err) {
        cb(err)
      }
    })
  }

  changePassword (oldPassword, newPassword, cb) {
    const cognitoUser = this.getCurrentUser()

    // getSession() will load valid tokens cached in the local store so we can
    // authenticate and change the users password
    cognitoUser.getSession((err) => {
      if (err) {
        return cb(err, false)
      }
      cognitoUser.changePassword(oldPassword, newPassword, cb)
    })
  }

  // Updates user information
  async updateAttributes (firstname, lastname, phoneNumber, company) {
    const cognitoUser = this.getCurrentUser()

    const attributeList = [
      new CognitoUserAttribute({
        Name: 'given_name',
        Value: firstname
      }),
      new CognitoUserAttribute({
        Name: 'family_name',
        Value: lastname
      }),
      new CognitoUserAttribute({
        Name: 'phone_number',
        Value: phoneNumber
      }),
      new CognitoUserAttribute({
        Name: 'custom:organisation',
        Value: company
      })
      // ... other attributes if needed
    ]

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err) => {
        if (err) {
          reject(err)
        } else {
          cognitoUser.updateAttributes(attributeList, (error, result) => {
            if (error) {
              reject(error)
            } else {
              resolve(result)
            }
          })
        }
      })
    })
  }

  signin (username, pass) {
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: username.toLowerCase(),
        Password: pass
      })
      const cognitoUser = new CognitoUser({
        Username: username.toLowerCase(),
        Pool: this.userPool
      })
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          resolve(result)
        },
        onFailure: (err) => {
          reject(err)
        }
      })
    })
  }

  /**
   * Logout of your cognito session.
   */
  logout () {
    if (this.getCurrentUser()) {
      this.getCurrentUser().signOut((err) => {
        console.log('revoke token error', err)
      })
    }
  }

  /**
   * Resolves the current token based on a user session. If there
   * is no session it returns null.
   * @param {*} cb callback
   */
  getIdToken (cb) {
    if (this.getCurrentUser() == null) {
      return cb(null, null)
    }
    this.getCurrentUser().getSession((err, session) => {
      if (err) return cb(err)
      if (session.isValid()) {
        return cb(null, session.getIdToken().getJwtToken())
      }
      cb(Error('Session is invalid'))
    })
  }

  getIdTokenPromise () {
    return new Promise((resolve, reject) => {
      if (this.getCurrentUser() == null) {
        return resolve(null)
      }
      this.getCurrentUser().getSession((err, session) => {
        if (err) {
          return reject(err)
        }
        if (session.isValid()) {
          return resolve(session.getIdToken().getJwtToken())
        }
      })
    })
  }

  getCurrentUser () {
    return this.userPool?.getCurrentUser()
  }

  async getUserAttributes () {
    const user = this.getCurrentUser()
    return await new Promise((resolve, reject) => {
      user.getSession((err) => {
        if (err) {
          reject(err)
        } else {
          user.getUserAttributes((err, result) => {
            if (err) {
              reject(err)
            } else {
              resolve(result)
            }
          })
        }
      })
    })
  }

  getSession () {
    return new Promise((resolve, reject) => {
      try {
        this.userPool.getCurrentUser().getSession((err, session) => {
          if (err) {
            return reject(err.message)
          }
          if (session.isValid()) {
            return resolve(session)
          }
        })
      } catch (err) {
        return reject(new Error('No current user'))
      }
    })
  }

  // very primitive change listener
  onChange = ref(() => {})
}

export const CognitoAuthPlugin = function (app) {
  let cypressRunning
  try {
    if (Cypress) {
      cypressRunning = true
    }
  } catch (e) {
    cypressRunning = false
  }

  const cognitoAuth = new CognitoAuth()
  if (cypressRunning) {
    cognitoAuth.configure({
      UserPoolId: Cypress.env('VUE_APP_COGNITO_USER_POOL_ID'),
      ClientId: Cypress.env('VUE_APP_COGNITO_USER_POOL_CLIENT_ID')
    })
  } else {
    cognitoAuth.configure({
      UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID
    })
  }

  // Expose as a global property
  app.config.globalProperties.$cognitoAuth = cognitoAuth
}
