import dataHelpers from '@/helpers/dataHelpers'
import positionHelpers from '@/helpers/positionHelpers'

export const svgGraphData = {
  namespaced: true,
  state: {},
  getters: {
    baseData: (state, getters, rootState) => {
      const columns = []
      const columnsWithCards = []
      const laneHeights = {}
      const events = rootState.workflow.workflow.eventsJson || []
      const filteredEvents = rootState.workflow.showReleaseNo && rootState.workflow.showReleaseNo.length > 0
        ? filterRequirements(events, rootState.workflow.showReleaseNo)
        : events
      const parentChildEventMap = dataHelpers.createMainParentChildEventMap(filteredEvents)

      createColumnsMap(parentChildEventMap, getters.svgGroupsData, 'start', 0, columns, laneHeights, columnsWithCards)

      for (const column of columns) {
        for (const lane in column) {
          laneHeights[lane] = Math.max(laneHeights[lane], column[lane].length)
        }
      }

      const cardIdToEventIdMap = {}
      for (const event of filteredEvents) {
        if (!event.requirementsJson) continue

        for (const req of event.requirementsJson) {
          cardIdToEventIdMap[req.id] = event.id
        }
      }

      return {
        columns,
        laneHeights,
        columnsWithCards,
        cardIdToEventIdMap,
        parentChildEventMap
      }
    },
    svgGroupsData: (state, getters, rootState) => {
      const svgGroupsData = {}
      if (rootState.workflow.workflow.groups) {
        let prevGroup = null
        const sortedGroups = JSON.parse(JSON.stringify(rootState.workflow.workflow.groups))
        sortedGroups.sort((a, b) => a.startSlotX - b.startSlotX)
        let index = 0
        for (const group of sortedGroups) {
          svgGroupsData[group.id] = {
            id: group.id,
            name: group.name,
            startSlotX: group.startSlotX,
            x: positionHelpers.getGroupXPointFromSlot(group.startSlotX),
            width: positionHelpers.getGroupXPointFromSlot(1),
            index
          }
          if (prevGroup) {
            prevGroup.width = positionHelpers.getGroupXPointFromSlot(group.startSlotX) - prevGroup.x
          }
          prevGroup = svgGroupsData[group.id]
          index++
        }

        if (prevGroup) {
          prevGroup.width = -1
        }
      }

      return svgGroupsData
    },
    svgLanesData: (state, getters, rootState) => {
      const svgLanesData = {}
      let yPosLanes = 0
      let laneAbove = null
      if (rootState.workflow.workflow.lanes) {
        for (const [index, lane] of rootState.workflow.workflow.lanes.entries()) {
          const height = 140 * (getters.baseData.laneHeights[lane.id] || 1)
          svgLanesData[lane.id] = {
            id: lane.id,
            x: 0,
            y: svgLanesData[laneAbove] ? svgLanesData[laneAbove].y + svgLanesData[laneAbove].height : 0,
            height,
            name: lane.name,
            laneAbove,
            emptyLane: !getters.baseData.laneHeights[lane.id],
            index
          }
          if (laneAbove) {
            svgLanesData[laneAbove].laneBelow = lane.id
          }
          laneAbove = lane.id
          yPosLanes = yPosLanes + height
        }
        if (svgLanesData[laneAbove]) {
          svgLanesData[laneAbove].height += 10 // add height to the last lane
        }
      }
      return svgLanesData
    },
    svgGraphData: (state, getters, rootState) => {
      let events, i
      const svgGraphData = {}
      const hasDirectGatewayParent = (parents, currentX) => {
        if (!Array.isArray(parents) || !parents.length) {
          return false
        }
        const parentId = parents.find((parent) => svgGraphData[parent] && svgGraphData[parent].type === 'bpmn:ExclusiveGateway')
        if (parentId) {
          if ((currentX - svgGraphData[parentId]?.x) === 160) {
            return true
          }
        }
        return false
      }
      const showReleaseNo = rootState.workflow.showReleaseNo
      for (let column = 0; column < getters.baseData.columns.length; column++) {
        for (const role in getters.baseData.columns[column]) {
          events = getters.baseData.columns[column][role]

          const offsetList = []
          const detectDuplicates = new Set()
          if (events.length > 1) {
            for (i = 0; i < events.length; i++) {
              // create an offsetList [0, 1, 2, 3, 4, etc ] and remove the offsets that are used
              // then use the remaining numbers for nodes without offset
              offsetList.push(i)

              // set duplicate offsets to null
              if (detectDuplicates.has(events[i].offset)) {
                events[i] = JSON.parse(JSON.stringify(events[i]))
                events[i].offset = null
              } else {
                detectDuplicates.add(events[i].offset)
              }
            }
            // sort on offset and put null last
            events.sort((a, b) => { return (a.offset === null) - (b.offset === null) || +(a.offset > b.offset) || -(a.offset < b.offset) })
          }
          for (i = 0; i < (events.length); i++) {
            const offset = (events[i].offset > 0 || events[i].offset === 0) ? events[i].offset : offsetList.length === 0 ? events[i].offset : offsetList.shift()
            // const x = events[i].parents.find(id => id === 'start') ? 80 : svgGraphData[events[i].parents[0]].x + 160
            const x = 80 + 160 * column
            svgGraphData[events[i].id] = {
              id: events[i].id,
              parents: events[i].parents,
              x,
              y: getters.svgLanesData[events[i].laneId].y + 30 + offset * 140,
              type: events[i].type,
              margin: events[i].type === 'bpmn:ExclusiveGateway' ? 33 : 0,
              name: events[i].description,
              laneId: events[i].laneId,
              groupId: events[i].groupId,
              outlined: events[i].requirementsJson?.length === 0 && showReleaseNo && showReleaseNo.length > 0,
              conditionLabel: events[i].conditionLabel || undefined,
              conditionLabels: events[i].conditionLabels || undefined,
              showConditionLabelOnEvent: hasDirectGatewayParent(events[i].parents, x),
              offset,
              childIds: svgGraphData[events[i].id] ? svgGraphData[events[i].id].childIds : undefined,
              requirementsJson: events[i].requirementsJson?.filter(req => (!showReleaseNo || (showReleaseNo.length === 0) || showReleaseNo.includes(req.releaseNo))) || [],
              dataModels: events[i].dataModels,
              subprocess: events[i].subprocess,
              color: events[i].color,
              version: events[i].version
            }
            const offsetIndex = offsetList.findIndex(offset => offset === events[i].offset)
            if (offsetIndex >= 0) {
              offsetList.splice(offsetIndex, 1)
            }
            if (svgGraphData[events[i].id].y < 0) { svgGraphData[events[i].id].y = 0 } // bug fix for when nodes jumps to minus coordinades because of unwanted collisions between svg elements
            // add child
            for (let j = 0; j < events[i].parents.length; j++) {
              if (events[i].parents[j] !== 'start') {
                if (!svgGraphData[events[i].parents[j]] || !svgGraphData[events[i].parents[j]].childIds) {
                  if (!svgGraphData[events[i].parents[j]]) {
                    svgGraphData[events[i].parents[j]] = {}
                  }
                  svgGraphData[events[i].parents[j]].childIds = []
                }
                svgGraphData[events[i].parents[j]].childIds.push(events[i].id)
              }
            }
          }
        }
      }
      return svgGraphData
    }
  }
}

// build column by column
function createColumnsMap (parentChildEventMap, groups, parentId, column, columns, laneHeights, columnsWithCards) {
  for (const event of parentChildEventMap[parentId]) {
    let thisColumnIndex = column
    if (event.groupId && groups[event.groupId]?.startSlotX > 0) {
      thisColumnIndex = groups[event.groupId].startSlotX
    }

    // since the function is called recursively, the column might already exist, in that case add to it, otherwise initiate
    let thisColumn, thisColumnWithCards
    if (columns[thisColumnIndex]) {
      thisColumn = columns[thisColumnIndex]
    } else {
      thisColumn = []
      columns[thisColumnIndex] = thisColumn
    }
    if (columnsWithCards[thisColumnIndex]) {
      thisColumnWithCards = columnsWithCards[thisColumnIndex]
    } else {
      thisColumnWithCards = {}
      thisColumnWithCards[thisColumnIndex] = thisColumn
    }

    // ******
    // EVENTS
    // ******
    // get lane name
    const laneId = event.laneId
    // init array if emtpy
    if (!thisColumn[laneId]) { thisColumn[laneId] = [] }
    // add to matrix
    thisColumn[laneId].push(event)

    // ******
    // CARDS
    // ******
    // get lane name
    for (const card of event.requirementsJson || []) {
      const release = card.releaseNo || 'unassigned'
      // init array if emtpy
      if (!thisColumnWithCards[release]) { thisColumnWithCards[release] = [] }
      // add to matrix
      thisColumnWithCards[release].push(card)
      columnsWithCards[thisColumnIndex] = thisColumnWithCards
    }

    // calculation for figuring out the height of this swim lane
    const currentCellHeight = (event.offset + 1) > (thisColumn[laneId].length - 1) ? event.offset + 1 : thisColumn[laneId].length - 1
    if (!laneHeights[laneId] || laneHeights[laneId] < currentCellHeight) {
      laneHeights[laneId] = currentCellHeight
    }

    if (parentChildEventMap[event.id]) {
      // add next column to this.columns
      createColumnsMap(parentChildEventMap, groups, event.id, thisColumnIndex + 1, columns, laneHeights, columnsWithCards)
    }
  }
}

// filter on releaseNo
function filterRequirements (events, showReleaseNo) {
  const res = []
  const copyOfEvents = JSON.parse(JSON.stringify(events))
  for (let i = 0; i < copyOfEvents.length; i++) {
    copyOfEvents[i].requirementsJson = copyOfEvents[i].requirementsJson?.filter(req => showReleaseNo.includes(req.releaseNo)) || []
    res.push(copyOfEvents[i])
  }
  return res
}
