import gql from 'graphql-tag'

export default gql`
  mutation createUser($firstname: String, $lastname: String, $email: String!, $company: String, $phoneNumber: String, $job: String, $picture: PictureInput, $referralSource: String, $usageIntent: String) {
    createUser(firstname: $firstname, lastname: $lastname, email: $email, company: $company, phoneNumber: $phoneNumber, job: $job, picture: $picture, referralSource: $referralSource, usageIntent: $usageIntent) {
      id
      email,
      firstname,
      lastname,
      phoneNumber,
      company,
      referralSource
      usageIntent
    }
  }
`
