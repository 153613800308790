import store from '@/store'

export default class ProjectApi {
  static async updateTeamMemberLastAccessToProject (projectId) {
    return await store.dispatch('projectMemberships/updateTeamMemberLastAccessToProject', { projectId })
  }

  static async deleteOrganisation (item) {
    return await store.dispatch('organisations/deleteOrganisation', item)
  }

  static async deleteProject (item) {
    return await store.dispatch('project/deleteProject', item)
  }

  static async updateProject (item) {
    await store.dispatch('project/updateProject', item)
  }

  static async createProject (item, setProject = true) {
    return await store.dispatch('project/createProject', item)
  }

  static async updateOrganisation (item) {
    return await store.dispatch('organisations/updateOrganisation', item)
  }

  static async createOrganisation (item) {
    return await store.dispatch('organisations/createOrganisation', item)
  }

  static async getProjects () {
    const res = []
    try {
      const memberships = store.state.projectMemberships.projectMemberships
      for (const membership of memberships) {
        if (membership.project) {
          res.push(membership.project)
        }
      }
      return res
    } catch (e) {
      console.log(e)
      return []
    }
  }

  static async getNextProjectName (projectName) {
    const projects = await this.getProjects()
    let number = 1
    while (projects.findIndex(project => project.name === projectName + ' ' + number) !== -1) {
      number++
    }
    return projectName + ' ' + number
  }

  static async deleteTeamMember (router, teamMember, projectId) {
    const del = confirm('Are you sure you want to remove this team member from this project?')
    if (del) {
      await store.dispatch('teamMembers/deleteTeamMember', {
        teamMemberId: teamMember.id,
        projectId,
        userId: teamMember.userId,
        email: teamMember.email
      })
      if (teamMember.userId === store.state.userId) {
        router.push('/')
      }
    }
  }
}
