<template>
  <v-card class="mt-4 ml-4">
    <v-card-text class="pa-1" v-if="!editMode">
      <p class="mb-0 text-black">{{ reply.text }}</p>

      <v-row class="ma-0 align-center">
        <v-col class="pa-0" v-if="!$store.state.isWorkflowDisabled && $store.state.userId === reply.author.id">
          <v-btn
            icon
            size="small"
            variant="text"
            class="ma-1"
            density="compact"
            @click="openEditForm"
          >
            <v-icon size="small" icon="mdi-pencil" />
          </v-btn>

          <v-btn
            icon
            size="small"
            variant="text"
            density="compact"
            class="ma-1"
            @click="deleteRequirementComment"
          >
            <v-icon size="small" icon="mdi-delete" />
          </v-btn>
        </v-col>

        <div class="flex-grow-0 flex-shrink-0 pa-0 ml-auto flex-nowrap">
          <v-tooltip location="top" open-delay="1000" v-if="withDates && reply.dateCreated">
            <template v-slot:activator="{ on, props }">
              <span
                v-bind="props"
                class="text-nowrap text-caption"
                v-on="{ ...on }"
              >
                {{formatDistanceToNowStrict(new Date(reply.dateCreated), { addSuffix: true })}}
              </span>
            </template>

            <div>
              <p class="text-body-2 mb-0">
                Created: {{format(new Date(reply.dateCreated), 'MMMM d, yyyy, HH:mm')}}
              </p>

              <p v-if="reply.dateUpdated" class="text-body-2 mb-0">
                Updated: {{format(new Date(reply.dateUpdated), 'MMMM d, yyyy, HH:mm')}}
              </p>
            </div>
          </v-tooltip>

          <requirement-user-avatar
            :fullname="reply.author.firstname + ' ' + reply.author.lastname"
            :generated-by-ai="reply.generatedByAi"
          ></requirement-user-avatar>
        </div>
      </v-row>
    </v-card-text>

    <bpmn-requirement-textarea-form
      v-else
      :initial-text="reply.text"
      :loading="loading"
      :showAi="showAi"
      @submit="submitReply"
      @cancel="editMode = false"
    >
      <template #prepend-actions>
        <requirement-user-avatar
          v-if="!hideAvatar"
          :fullname="reply.author.firstname + ' ' + reply.author.lastname"
          :generated-by-ai="reply.generatedByAi"
        ></requirement-user-avatar>
      </template>
    </bpmn-requirement-textarea-form>
  </v-card>
</template>

<script>
import RequirementUserAvatar from '@/components/RequirementUserAvatar'
import BpmnRequirementTextareaForm from '@/components/BpmnRequirementTextareaForm'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import format from 'date-fns/format'
import store from '../store'

export default {
  components: {
    RequirementUserAvatar,
    BpmnRequirementTextareaForm
  },

  props: ['reply', 'requirementId', 'replyFormVisible', 'withDates', 'hideAvatar', 'showAi'],

  data () {
    return {
      editMode: false,
      loading: false
    }
  },

  watch: {
    replyFormVisible (newValue) {
      if (newValue) {
        this.editMode = false
      }
    }
  },

  methods: {
    formatDistanceToNowStrict,
    format,

    openEditForm () {
      this.editMode = true
      this.$emit('hide-reply-form')
    },

    async submitReply ({ text }) {
      const workflowId = this.$route.params.id
      const requirementId = this.requirementId
      const { id } = this.reply

      this.loading = true

      await store.dispatch('workflow/updateCardReply', {
        workflowId,
        requirementId,
        id,
        input: {
          text
        }
      })

      this.loading = false
      this.editMode = false
    },

    async deleteRequirementComment () {
      const workflowId = this.$route.params.id
      const requirementId = this.requirementId
      const { id } = this.reply
      await store.dispatch('workflow/deleteCardReply', {
        workflowId,
        requirementId,
        id
      })
    }
  }
}
</script>

<style scoped>
  .fullname {
    text-transform: uppercase;
    font-weight: bold;
    color: var(--light-blue);
  }

  .v-tooltip__content {
    background: white;
    color: var(--color-primary);
    border-radius: 10px;
  }
</style>
