<template>
  <v-alert
    v-if="showWarning"
    icon="$warning"
    type="warning"
    class="alert"
    text="For the best experience, use Chrome instead of Safari due to potential UI issues."></v-alert>
</template>

<script>
export default {
  data () {
    return {
      showWarning: false
    }
  },
  mounted () {
    this.checkBrowser()
  },
  methods: {
    checkBrowser () {
      const ua = navigator.userAgent.toLowerCase()
      if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
        // Safari detected
        this.showWarning = true
      }
    }
  }
}
</script>

<style scoped>
.alert {
  font-size: 16px;
}
</style>
