<template>
  <div id="app">
    <v-app id="inspire">
      <navigation-left v-if="$store.state.isUserLoggedIn"/>
      <v-main :class="{ 'app-main-container': $store.state.isUserLoggedIn }">
        <safari-browser-warning />
        <view-mode-tooltip v-if="$route.params.projectId && $route.params.id && $store.state.workflow.isViewOnlyEnabled"/>
        <v-container :fluid="true" class="pa-0 px-4">
          <template v-if="online">
            <router-view />
            <snackbar />
            <v-overlay class="loading-overlay text-center align-center justify-center" :model-value="loadingState" persistent>
              <v-progress-circular
                color="white"
                indeterminate
                size="64"
              />
              <div class="loading-message" v-if="loadingMessage">{{loadingMessage}}</div>
            </v-overlay>
            <v-dialog max-width="500" :modelValue="dialogState">
              <template v-slot:default>
                <v-card title="Warning">
                  <v-card-text>
                    {{ dialogMessage }}
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      variant="text"
                      @click="dialogClick('ok')"
                    >Ok</v-btn>
                    <v-btn
                      variant="text"
                      @click="dialogClick('cancel')"
                    >Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
          <template v-else>
            <div class="d-flex xs6 mt-2">
              <div class="text-h5 workflow-heading">Offline</div>
              <p>
                You appear to be offline! As soon as you are online again you
                will be reconnected.
              </p>up
            </div>
          </template>
        </v-container>
      </v-main>

      <v-footer color="primary" :absolute="true" :app="true" class="pb-1 pt-1 app-footer">
        <span class="text-white text-right ml-4 mr-4"
          >&copy; {{ new Date().getFullYear() }} Qlerify</span
        >
        <a
          href="../../Qlerify_terms_of_service_and_privacy_policy.pdf"
          target="_blank"
          class="text-white text-center mr-4"
          >Terms of Service & Privacy Policy</a
        >
        <a
          href="http://www.qlerify.com/cookie-policy"
          target="_blank"
          class="text-white text-center mr-4"
          >Cookie Policy</a
        >
      </v-footer>
      <!-- hidden div for testing web sockets with cypress -->
      <div style="display: none" data-test="websocket" :data-test-version="$store.state.wsMessageForCypress?.version" />
    </v-app>
  </div>
</template>

<script>
// import CookieLaw from '@/components/CookieLaw.vue'
import NavigationLeft from '@/components/NavigationLeft'
import SafariBrowserWarning from '@/components/SafariBrowserWarning'
import Snackbar from '@/components/Snackbar.vue'
import { mapGetters } from 'vuex'
import projectApi from '@/api/projectApi'
import { EventBus } from '@/event-bus/event-bus.js'
import ViewModeTooltip from '@/components/ViewModeTooltip.vue'

export default {
  components: {
    ViewModeTooltip,
    Snackbar,
    NavigationLeft,
    SafariBrowserWarning
    // CookieLaw
  },
  data: () => ({
    online: true,
    opened: true,
    mini: true
  }),
  computed: {
    ...mapGetters({
      loadingState: 'loading/getLoadingState',
      loadingMessage: 'loading/getLoadingMessage',
      dialogState: 'dialog/getDialogState',
      dialogMessage: 'dialog/getDialogMessage',
      dialogCallback: 'dialog/getDialogCallback'
    })
  },
  methods: {
    dialogClick (action) {
      this.dialogCallback(action)
      this.$store.commit('dialog/closeDialog')
    },
    setProject (item) {
      this.$router.push('/dashboard/' + item.id).catch(() => { })
    },
    handleConnectivityChange (status) {
      this.updateUserQueries()
      this.updateProjectQueries(this.$route.params.projectId)
      this.updateWorkflowQueries(this.$route.params.id)
      this.online = status
    },
    async updateProjectQueries (projectId) {
      if (projectId) {
        const teamMembersPromise = this.$store.dispatch('teamMembers/listTeamMembers', { projectId })
        const workflowsPromise = this.$store.dispatch('workflows/listWorkflows', projectId)
        await Promise.all([teamMembersPromise, workflowsPromise])
        this.$store.commit('projectMemberships/setActiveProjectId', projectId)
      }
    },

    async updateWorkflowQueries (workflowId) {
      if (workflowId) {
        this.$store.commit('workflow/setLoading', true)
        await this.$store.dispatch('workflow/getWorkflow', this.$route.params)
        // run listWorkflowHistory after getWorkflow
        // because we need the current workflow to be set
        // when setting the workflow history in the store
        // also run listRequirementTypes after getWorkflow
        // so we can avoid subscriptions on public workflows
        const requirementTypesPromise = this.$store.dispatch('requirementTypes/listRequirementTypes', {
          workflowId: this.$route.params.id
        })
        const listWorkflowHistoryPromise = await this.$store.dispatch('workflowHistory/listWorkflowHistory', {
          projectId: this.$route.params.projectId,
          workflowId: this.$route.params.id
        })
        await Promise.all([requirementTypesPromise, listWorkflowHistoryPromise])
        this.$store.commit('workflow/setLoading', false)
      } else {
        this.$store.commit('workflow/setWorkflow', {})
      }
    },

    async updateUserQueries (user) {
      if (user) {
        // run listProjectMembershipsForCurrentUser first
        // because this one must be completed before we can
        // updateTeamMemberLastAccessToProject in the project
        // watcher. This is to avoid exceptoins in Cypress
        try {
          await this.$store.dispatch('projectMemberships/listProjectMembershipsForCurrentUser')
        } catch (error) {
          this.$store.commit('logout')
          this.$router.push({ path: '/login' })
        }
        const organisationsPromise = this.$store.dispatch('organisations/listOrganisations')
        const getCurrentUserPromise = this.$store.dispatch('user/getCurrentUser')
        await Promise.all([organisationsPromise, getCurrentUserPromise])
      }
    }
  },
  async mounted () {
    // this.$loadingState.stop()
    EventBus.$on('set-project', item => {
      this.setProject(item)
    })
    EventBus.$on('redirect-to-login', () => {
      this.$router.push({ path: '/login' })
    })
  },
  watch: {
    '$store.state.userId': {
      handler: async function (user) {
        if (user) {
          try {
            this.$loadingState.start()
            await this.updateUserQueries(user)
            this.$loadingState.stop()
          } catch (error) {
            console.log('error', error)
            this.$store.commit('logout')
            this.$loadingState.stop()
            this.$router.push({ path: '/login' })
          }
        }
      },
      immediate: true
    },
    '$route.params.projectId': {
      handler: async function (projectId) {
        if (projectId && !this.$store.state.workflow.isViewOnlyEnabled && this.$store.state.isUserLoggedIn) {
          try {
            this.$loadingState.start()
            await this.updateProjectQueries(projectId)
            this.$nextTick(() => {
              // delay updateTeamMemberLastAccessToProject so that
              // listProjectMembershipsForCurrentUser is ready before
              // updateTeamMemberLastAccessToProject is called. This is
              // in order to avoid exceptions in Cypress
              projectApi.updateTeamMemberLastAccessToProject(projectId)
            })
            this.$loadingState.stop()
          } catch (error) {
            console.log(error)
            this.$store.commit('logout')
            this.$loadingState.stop()
            this.$router.push({ path: '/login' })
          }
        } else {
          this.$store.commit('projectMemberships/setActiveProjectId', null)
        }
      },
      immediate: true
    },
    '$route.params.id': {
      handler: async function (workflowId) {
        if (workflowId) {
          try {
            await this.updateWorkflowQueries(workflowId)
          } catch (error) {
            console.log(error)
            // this.$router.push({ path: '/login' })
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  min-width: 1280px;
}

.theme--light.v-application {
  background: #f1f2f2;
}

.v-toolbar__title {
  margin-left: 0px;
}

.app-main-container {
  padding-left: 56px !important;
}

.app-footer {
  z-index: 7 !important;
}

.projects-list-menu {
  max-height: calc(100vh - 20px);
}
</style>

<style>
:root {
  --color-primary: #022a44;
  --light-blue: #3183fb;
  font-size: 14px;
}

.v-field {
  font-size: 14px !important;
}

.v-btn:not(.v-btn--icon),
.v-dialog,
.v-tooltip__content,
.v-menu__content,
.v-card:not(.v-card--flat),
.v-expansion-panels {
  border-radius: 10px !important;
}

.v-card-text {
  font-size: 1rem !important;
}

.v-expansion-panel:first-child {
  border-radius: 10px 10px 0 0;
}

.v-expansion-panel:last-child {
  border-radius: 0 0 10px 10px;
}

.round-bottom,
.round-bottom .v-table,
.round-bottom .v-data-table {
  border-radius: 0 0 10px 10px !important;
}

.v-tabs-bar {
  border-radius: 10px 10px 0 0;
}

.loading-overlay {
  z-index: 20001 !important;
}

.loading-message {
  color: #fff;
  font-size: 15px;
  margin-top: 20px;
}

.show-on-hover {
  opacity: 0;
}

.show-on-hover:hover {
  opacity: 1;
}

.hover-container:hover + .show-on-hover {
  opacity: 1;
}

.hover-container:hover > div .show-on-hover {
  opacity: 1;
}

.hover-container:hover > .show-on-hover {
  opacity: 1;
}

.dialog-top-right {
  top: 0;
  right: 0;
  margin: 0 !important;
}
</style>
