<template>
  <div class="line-container">
    <svg :width="svgWidth" :height="svgHeight">
      <line
        :x1="startPos.x"
        :y1="startPos.y"
        :x2="endPos.x"
        :y2="endPos.y"
        stroke="rgb(118, 118, 118)"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </svg>
  </div>
</template>

<style scoped>
.line-container {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;
}
</style>

<script>
export default {
  props: {
    startElementId: String,
    endElementId: String,
    refreshKey: Number,
    spacingStartElement: {
      type: Number,
      default: 0
    },
    spacingEndElement: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      startPos: { x: 0, y: 0 },
      endPos: { x: 0, y: 0 },
      svgWidth: 0,
      svgHeight: 0
    }
  },
  mounted () {
    this.updateLinePositions()
    window.addEventListener('resize', this.updateLinePositions)
    this.$nextTick(() => {
      this.updateLinePositions()
    })
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.updateLinePositions)
  },
  methods: {
    updateLinePositions () {
      const startElement = document.getElementById(this.startElementId)
      const endElement = document.getElementById(this.endElementId)

      if (!startElement || !endElement || !startElement.offsetParent || !endElement.offsetParent) return

      const startRect = startElement.getBoundingClientRect()
      const endRect = endElement.getBoundingClientRect()

      this.startPos = {
        x: startRect.right - startElement.offsetParent.getBoundingClientRect().left - this.spacingStartElement,
        y: startRect.top - startElement.offsetParent.getBoundingClientRect().top + startRect.height / 2
      }

      this.endPos = {
        x: endRect.left - startElement.offsetParent.getBoundingClientRect().left + this.spacingEndElement,
        y: endRect.top - startElement.offsetParent.getBoundingClientRect().top + endRect.height / 2
      }

      this.svgWidth = startElement.offsetParent.offsetWidth
      this.svgHeight = startElement.offsetParent.offsetHeight
    }
  },
  watch: {
    refreshKey () {
      this.$nextTick(() => {
        this.updateLinePositions()
      })
    }
  }
}
</script>
