import gql from 'graphql-tag'

export default gql`
  subscription OnUpdateUserStatusWorkflow($workflowId: String!)  {
    onUpdateUserStatusWorkflow (workflowId: $workflowId) {
      workflowId
      userId
      vote
      message
      lastUpdate
    }
  }
`
