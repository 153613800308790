<template>
  <v-navigation-drawer
    :model-value="opened"
    v-model:rail="mini"
    class="app-navigation-drawer"
    mobile-breakpoint="0"
  >
    <v-list
      @mouseenter="openDrawer"
      @mouseleave="closeDrawer"
    >
      <v-list-item
        :to=" $store.state.workflow.isViewOnlyEnabled ? '/dashboard' : `/dashboard/${$route.params.projectId || ''}`"
        @click="mini = true"
        prepend-icon="mdi-view-dashboard"
      >
          <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="$route.params.projectId && !this.$store.state.workflow.isViewOnlyEnabled"
        :to="`/workflows/${$route.params.projectId || ''}`"
        @click="mini = true"
        prepend-icon="mdi-exit-to-app"
      >
          <v-list-item-title>
            Workflows {{ workflows ? " (" + workflows.length + ")" : "" }}
          </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="$route.params.projectId && !this.$store.state.workflow.isViewOnlyEnabled"
        :to="`/team/${$route.params.projectId || ''}`"
        @click="mini = true"
        prepend-icon="mdi-dots-hexagon"
      >
          <v-list-item-title>
            Team Members
            {{ listTeamMembers ? " (" + listTeamMembers.length + ")" : "" }}
          </v-list-item-title>
      </v-list-item>

      <v-list-item
        href="https://www.qlerify.com/help"
        target="_blank"
        @click="mini = true"
        prepend-icon="mdi-help-circle"
      >
          <v-list-item-title>Online Help</v-list-item-title>
      </v-list-item>

      <v-list-item
        href="https://webforms.pipedrive.com/f/6NfI0XGDzXIe8nGmpN1ly8pQUw2YUXE6GnPVoWuCyliiWcg13YOiNmQBCeX3PF6nbJ"
        target="_blank"
        @click="mini = true"
        prepend-icon="mdi-lifebuoy"
      >
          <v-list-item-title>Contact Support</v-list-item-title>

      </v-list-item>

      <v-list-item to="/settings" @click="mini = true" prepend-icon="mdi-cog">
          <v-list-item-title>
            Manage Organisations
          </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="$store.state.adminUser" to="/admin/" @click="mini = true" prepend-icon="mdi-account-multiple">
          <v-list-item-title>
            Admin
          </v-list-item-title>
      </v-list-item>
    </v-list>
    <img
      style="height: 48px"
      src="@/assets/qlerify-logo.png"
      alt="Qlerify"
      class="mr-5 mt-2 logo"
    />
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    opened: true,
    mini: true,
    timeoutHandle: null
  }),
  methods: {
    openDrawer () {
      this.timeoutHandle = setTimeout(() => {
        this.mini = false
      }, 500)
    },
    closeDrawer () {
      if (this.timeoutHandle) {
        clearTimeout(this.timeoutHandle)
      }
      this.mini = true
    }
  },
  computed: {
    ...mapState({
      workflows: state => state.workflows.workflows || [],
      listTeamMembers: state => state.teamMembers.teamMembers || []
    })
  }
}
</script>

<style scoped>
.app-navigation-drawer {
  top: 0px !important;
  height: 100%;
  max-height: 100% !important;
}

.app-navigation-drawer.v-navigation-drawer--mini-variant {
  box-shadow: none;
}

.logo {
  transform: rotate(-90deg);
  position: absolute;
  bottom: 80px;
  left: -30px;
}
</style>

<style>
.app-navigation-drawer .v-icon {
  font-size: 24px !important
}
</style>
