import gql from 'graphql-tag'

export default gql`
  mutation updateProject($id: String!, $organisationId: String!, $name: String!, $goals: [GoalInput!]) {
    updateProject(id: $id, organisationId: $organisationId, name: $name, goals: $goals) {
      id
      name
      organisationId
      organisation {
        id
        name
        userId
        user {
          id
          firstname
          lastname
        }
      }
      jiraForgeData {
        projectId
      }
      goals {
        title
        description
      }
    }
  }
`
