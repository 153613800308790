<template>
  <v-card-text class="mt-4 text-black tab-content">
    <div class="text-subtitle-1 font-weight-bold">Event & Lane Settings</div>

    <v-card class="pa-3 elevation-3">
      <v-layout wrap align-center>
        <v-col cols="4"
          xs4
          class="text-caption font-weight-medium text-uppercase text-truncate align-left"
        >
          Name
        </v-col>

        <v-col cols="1"
          xs1
          class="text-caption font-weight-medium text-uppercase text-truncate text-center"
        >
          Color
        </v-col>
      </v-layout>

      <workflow-settings-basic-definition
        v-for="definition in definitions"
        :key="definition.id"
        :definition="definition"
        :disabled="$store.state.isWorkflowDisabled"
        @update-definition="updateWorkflowEntityDefinition"
      ></workflow-settings-basic-definition>
    </v-card>
  </v-card-text>
</template>

<script>
import WorkflowSettingsBasicDefinition from '@/components/WorkflowSettingsBasicDefinition'
import workflowApi from '@/api/workflowApi'

export default {
  components: {
    WorkflowSettingsBasicDefinition
  },

  props: {
    workflow: Object
  },

  computed: {
    definitions () {
      if (!this.workflow) {
        return []
      }

      const { roleDefinition, eventDefinition } = this.workflow

      return [
        { id: 'EVENT', ...eventDefinition },
        { id: 'ROLE', ...roleDefinition }
      ]
    }
  },

  methods: {
    async updateWorkflowEntityDefinition (entityKind, input) {
      const inputVariables = {
        id: this.$route.params.id,
        expectedVersion: this.workflow.version
      }
      if (entityKind === 'EVENT') {
        inputVariables.eventDescription = input.description
        inputVariables.eventColor = input.color
      } else { // 'ROLE'
        inputVariables.roleDescription = input.description
        inputVariables.roleColor = input.color
      }
      await workflowApi.updateWorkflowNoHistoryMutation(
        this.$route.params.projectId,
        { ...inputVariables }
      )
    }
  }
}
</script>

<style scoped>
  .tab-content {
    height: 1000px;
  }
</style>
