<template>
  <v-dialog
    :model-value="open"
    @update:model-value="$emit('update:open', $event)"
    max-width="1000px"
    min-width="1000px">
    <v-card flat>
      <v-toolbar flat>
        <v-toolbar-title class="text-h5 font-weight-regular">Workflow: {{ workflow.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon color="grey" @click="$emit('update:open', false)">
          <v-icon icon="mdi-close"></v-icon>
        </v-btn>
      </v-toolbar>
      <template v-for="section in commentSections" v-bind:key="section.index">
        <v-list>
          <v-layout v-bind:key="section.index">
            <v-col cols="6" class="text-left">{{ section.heading }}</v-col>
            <v-col cols="6" v-if="section.vote == 1" style="font-weight: 400;" class="text-left">Comments</v-col>
          </v-layout>

          <v-layout class="mb-3" v-for="item in teamMembers.filter(tm => (tm.vote === section.vote) || (section.vote === null && !tm.vote))" v-bind:key="item.index">
            <v-col cols="1" class="text-center">
              <v-icon icon="mdi-account" size="large"></v-icon>
            </v-col>

            <v-col cols="4" v-if="item.id">
              <v-list-item-title v-text="findName(item.id)"></v-list-item-title>
            </v-col>

            <v-col cols="1">
              <v-icon v-if="section.vote == 1" icon="mdi-thumb-up" :color="'blue'" size="small"></v-icon>
              <v-icon v-if="section.vote == 2" icon="mdi-thumb-down" :color="'red'" size="small"></v-icon>
            </v-col>

            <v-col cols="6">
              <div v-if="item.userId == $store.state.userId && section.vote">
                <v-dialog
                  :modelValue="dialog"
                  @update:modelValue="toggleEditDialog($event, item)"
                  max-width="500px"
                >
                  <template v-slot:activator="{ props }">
                    <div v-if="item.message?.length > 0" class="user-message" :data-cy="section.vote == 1 ? 'upvote-comment' : 'downvote-comment'">
                      {{ item.message }}
                      <v-icon v-bind="props" icon="mdi-pencil" style="width: 40px;" class="ml-1" size="small"></v-icon><br />
                      <span class="font-weight-light">
                        <v-tooltip location="bottom" open-delay="1000">
                          <template v-if="item.lastUpdate" v-slot:activator="{ props }">
                            <span v-bind="props">{{ new Date(item.lastUpdate).toDateString().substring(4) }}</span>
                          </template>
                          <span v-if="item.lastUpdate">{{ new Date(item.lastUpdate).toLocaleTimeString() }}</span>
                        </v-tooltip>
                      </span>
                    </div>
                    <div v-else v-bind="props" style="white-space: nowrap; text-decoration: underline;">
                      (Add your comment)
                    </div>
                  </template>

                  <template v-slot:default>
                    <v-card class="px-2">
                      <v-textarea
                        @blur="updateMessage(myMessage.substring(0,280), item.vote)"
                        v-model="myMessage"
                        :rules="[rules.max280chars]"
                        label="Your comments"
                        variant="outlined"
                        flat
                        ref="textareainput"
                        counter="280"
                        class="mt-2">
                      </v-textarea>
                    </v-card>
                  </template>
                </v-dialog>
              </div>
              <div v-else>
                <div class="user-message">
                  {{ item.message }} <br />
                  <span class="font-weight-light">
                    <v-tooltip location="bottom" open-delay="1000">
                      <template v-if="item.lastUpdate" v-slot:activator="{ props }">
                        <span v-bind="props">{{ new Date(item.lastUpdate).toDateString().substring(4) }}</span>
                      </template>
                      <span v-if="item.lastUpdate">{{ new Date(item.lastUpdate).toLocaleTimeString() }}</span>
                    </v-tooltip>
                  </span>
                </div>
              </div>
            </v-col>
          </v-layout>
        </v-list>
        <v-divider></v-divider>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'

export default {
  props: {
    open: Boolean
  },
  data: () => ({
    dialog: false,
    myMessage: null,
    commentSections: [
      {
        heading: 'Approved',
        vote: 1
      },
      {
        heading: 'Disapproved',
        vote: 2
      },
      {
        heading: 'No reply',
        vote: null
      }
    ],
    rules: {
      max280chars: value => (!value || (value && value.length <= 280)) || 'Max 280 characters'
    }
  }),
  methods: {
    toggleEditDialog (event, item) {
      this.dialog = event
      this.myMessage = item.message
    },
    findName (userId) {
      const teamMember = this.workflow.teamMembers.find(tm => tm.id === userId)
      return teamMember.email ? teamMember.email : teamMember.user.firstname + ' ' + teamMember.user.lastname
    },
    async updateMessage (message, vote) {
      await store.dispatch('workflow/updateUserStatusWorkflow', {
        workflowId: this.$route.params.id,
        message: message || undefined,
        vote,
        projectId: this.$route.params.projectId
      })
      this.commentsDialog = false
    },
    getUserStatusWorkflowForUser (userId) {
      let res = {}
      if (this.workflow.userStatusesWorkflow) {
        const index = this.workflow.userStatusesWorkflow.findIndex((us) => us.userId === userId)
        res = this.workflow.userStatusesWorkflow[index]
      }
      return res
    }
  },
  computed: {
    ...mapState({ workflow: state => state.workflow.workflow }),
    teamMembers () {
      let res = []
      if (this.workflow.teamMembers) {
        res = this.workflow.teamMembers
        res = res.map((tm) => {
          return Object.assign({ ...tm }, this.getUserStatusWorkflowForUser(tm.userId))
        })
      }
      return res
    }
  }
}
</script>

<style scoped>

.user-message {
  word-break: break-word;
}

</style>
