<template>
  <v-row class="my-6">
    <v-col class="pa-0">
      <v-btn class="google-cta rounded" @click="googleSignIn">
        <div class="google-cta__logo-container">
          <div class="google-logo-bg">
            <img src="../assets/icon-google-svg.svg" alt="G">
          </div>
        </div>
        <div class="google-cta__text-container">
          <span v-if="isForSignUp">Sign up with Google</span>
          <span v-else>Continue with Google</span>
        </div>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

export default {
  name: 'google-sign-in-button',
  props: {
    isForSignUp: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    googleSignIn () {
      Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
    }
  }
}
</script>
<style>

a {
  color: rgba(0, 0, 0, 0.87);
}

.google-logo-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 29px;
  height: 29px;
  padding: 5px;
  border-radius: 2px;
}

.google-cta {
  padding: 3px !important;
  display: block !important;
  width: 100%;
  border-radius: 2px !important;
  background: #3871DF !important;
  color: white !important;
  font-style: normal;
  text-transform: none !important;
  font-family: Inter, Roboto, Arial, sans-serif;
  line-height: 24px;
  letter-spacing: 1px;
  > .v-btn__content {
    justify-content: left;
    > .google-cta__text-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
}
</style>
