<template>
  <v-dialog v-model="dialog" max-width="400px" class="delete-item-dialog" v-on:keydown.stop>
    <template v-if="!hideIcon" v-slot:activator="{ props }">
      <slot :props="props">
        <v-tooltip location="top" open-delay="1000">
          <template v-slot:activator="{ props: onHoverProps }">
            <v-icon
              color="white"
              size="small"
              v-bind="{ ...props, ...onHoverProps }"
            >
              mdi-delete-outline
            </v-icon>
          </template>
          <template #default>
            <div>
              <p class="text-body-2 mb-1">Delete</p>
              <p class="mb-0">
                <code>ALT/⌥ + BACKSPACE</code> - Delete selected event
              </p>
            </div>
          </template>
        </v-tooltip>
      </slot>
    </template>
    <v-card>
      <!-- cannot delete (only for decision)-->
      <template v-if="itemType != 'Card' && eventCanBeDeleted == false">
        <v-card-title>
          <span class="text-h5">Cannot delete event</span>
        </v-card-title>
        <v-card-text>You cannot remove this event because there is more than one event attached after it.</v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" variant="text" @click="closeDialog()" @keydown.enter.prevent.stop="keydownEnter()">Close</v-btn>
        </v-card-actions>
      </template>
      <!-- confirm delete (event or card) -->
      <template v-else>
        <v-card-title>
          <span class="text-h5">Confirm deletion</span>
        </v-card-title>
        <v-card-text v-if="itemType === 'Card'"> Are you sure you want to delete this card?</v-card-text>
        <v-card-text v-else> Are you sure you want to delete this {{ itemType }} from the workflow including all its
          cards?</v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" variant="text" @click="closeDialog()">Cancel</v-btn>
          <v-btn
            color="primary"
            :class="{
              'confirm-delete-card': itemType == 'Card',
              'confirm-delete-event': itemType != 'Card'
            }"
            :loading="loading"
            @click="deleteItem()"
            ref="deleteButton"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import workflowApi from '@/api/workflowApi'
import { mapState, mapGetters } from 'vuex'

export default {
  props: [
    'itemType',
    'cardId',
    'hideIcon'
  ],
  data () {
    return {
      dialog: false,
      loading: false
    }
  },
  methods: {
    async deleteItem () {
      this.loading = true
      const event = this.svgGraphData[this.eventId]
      if (this.itemType === 'Card') {
        const workflowId = this.$route.params.id
        await workflowApi.deleteRequirementJson(workflowId, { requirementId: this.cardId })
      } else {
        if (this.eventCanBeDeleted) {
          await workflowApi.deleteEventFromWorkflow(event.id, this.svgGraphData, this.svgLanesData, this.svgGroupsData, this.workflow)
          const parentId = event.parents[0]
          if (this.svgGraphData[parentId]) {
            this.$router.push({ path: '/workflow/' + this.$route.params.projectId + '/' + this.workflow.id + '/' + parentId }).catch(() => { })
          } else {
            this.$router.push({ path: '/workflow/' + this.$route.params.projectId + '/' + this.workflow.id }).catch(() => { })
          }
        }
      }
      this.loading = false
      this.closeDialog()
    },
    openDialog () {
      this.dialog = true
      this.$nextTick(() => this.$refs.deleteButton?.$el.focus())
    },
    closeDialog () {
      this.dialog = false
    },
    keydownEnter () {
      // item cannot be deleted
      setTimeout(() => {
        this.closeDialog()
      }, 100)
    }
  },
  computed: {
    ...mapState({
      workflow: state => state.workflow.workflow
    }),
    ...mapGetters({
      svgGraphData: 'svgGraphData/svgGraphData',
      svgLanesData: 'svgGraphData/svgLanesData',
      svgGroupsData: 'svgGraphData/svgGroupsData'
    }),
    eventCanBeDeleted () {
      const events = this.workflow.eventsJson
      const childEvents = events?.filter(ev => ev.parents.includes(this.$route.params.eventId))
      if (childEvents?.length > 1) {
        return false
      }
      return true
    },
    eventId () {
      return this.$route.params.eventId
    }
  },
  watch: {
    dialog (val) {
      if (val) {
        this.$emit('open')
      }
    }
  }
}
</script>

<style scoped>
</style>
