<template>
  <text-editor
    ref="editor"
    class="description"
    :id="'editor' + item.id"
    :tabindex="index"
    :text="item.description"
    :disabled="$store.state.isWorkflowDisabled"
    @text-update="updateDescription"
    @move-focus="moveFocus">
  </text-editor>
</template>

<script>
import workflowApi from '@/api/workflowApi'
import TextEditor from '@/components/TextEditor.vue'
import { EventBus } from '@/event-bus/event-bus.js'

export default {
  props: {
    item: Object,
    index: Number,
    focusCard: String,
    scrollIntoViewArg: Boolean
  },

  components: {
    TextEditor
  },

  methods: {
    updateDescription (html) {
      workflowApi.updateRequirementsJsonMutation(this.$route.params.id, [{ requirementId: this.item.id, description: html }])
    },

    focusOnCard (id) {
      if (this.item?.id === id) {
        this.$el.scrollIntoView(this.scrollIntoViewArg)
        this.$el.focus()
        this.$refs.editor.setCaretAtEndOfEditor()
      }
    },

    moveFocus (direction, target, className) {
      this.$emit('move-focus', direction, target, className)
    }
  },

  watch: {
    focusCard: function (id) {
      this.focusOnCard(id)
    }
  },

  mounted () {
    if (!this.$store.state.isWorkflowDisabled) {
      EventBus.$on('focus-card', card => {
        this.$nextTick(() => {
          this.focusOnCard(card.id)
        })
      })
    }
  }
}
</script>

<style scoped>
.ql-container.ql-snow {
  border: none !important;
}

.description {
  padding: 3px 5px !important;
}
</style>

<style>
.description p {
  margin-bottom: 0px !important;
  color: black;
}

.ql-editor {
  user-select: text;
}
</style>
