import gql from 'graphql-tag'

export default gql`
  mutation UpdateRequirementComment(
    $id: ID!,
    $input: RequirementCommentInput!,
    $workflowId: String!,
    $requirementId: String!
  ) {
    updateRequirementComment(
      id: $id,
      input: $input,
      workflowId: $workflowId,
      requirementId: $requirementId
    ) {
      workflowId
      requirementId
      id
      text
      dateUpdated
    }
  }
`
