<template>
  <td
    class="text-left bg-blue-lighten-5"
    @click="setFocus()"
    @keydown="keydownCell($event)"
  >
    <v-menu v-model="menu">
      <template v-slot:activator="{ on, props, value: openMenu }">
        <v-btn
          v-bind="props"
          :disabled="$store.state.isWorkflowDisabled"
          @keydown="openMenu ? on.keydown($event) : keydownButton($event)"
          color="blue-lighten-5"
          class="table-button">
          <div
            ref="cell"
            :tabindex="tab">
            {{ value ? value : '...' }}
          </div>
        </v-btn>
      </template>

      <v-list>
        <v-list-subheader>Select type:</v-list-subheader>
        <v-list-item
          v-for="type in types"
          :key="type"
          @click.stop="$emit('click-type', $event); menu = false"
        >
          <v-list-item-action>{{type}}</v-list-item-action>
        </v-list-item>
        <v-list-item @click="$emit('click-type', $event); menu = false">
          <v-list-item-action>...</v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </td>
</template>

<script>
export default {
  name: 'beam-table-cell-select',

  props: {
    value: String,
    types: Array,
    tab: Number
  },

  data () {
    return {
      menu: false
    }
  },

  methods: {
    keydownButton (event) {
      switch (event.keyCode) {
        case 13: // enter
        case 37: // left
        case 38: // up
        case 39: // right
        case 40: // down
          event.preventDefault()
          event.stopPropagation()
          this.$emit('leave-cell', event)
      }
    },
    keydownCell (event) {
      if (this.$store.state.isWorkflowDisabled) {
        return
      }

      switch (event.keyCode) {
        case 32: // space
          event.preventDefault()
          this.menu = !this.menu
      }
    },
    setFocus () {
      this.$refs.cell.focus()
    }
  }

}
</script>

<style>
.table-button {
  outline: none;
}

.table-button div {
  outline: none;
}

.table-button {
  padding: 0 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  min-width: 40px !important;
  height: 30px !important;
  text-transform: none !important;
  letter-spacing: normal !important;
}

.table-button:not(.v-btn--depressed):not(.v-btn--flat) {
  box-shadow: none;
}

</style>
