<template>
  <v-card class="form-container pa-6">
    <img src="@/assets/qlerify-logo-small.png"
        name="preview-image-url" alt="Qlerify logo" class="mx-auto" style="display: block;"/>
    <h3 class="text-h5 font-weight-bold pb-4 text-center">Confirm reset password</h3>
    <p class="text-center">Requires a new registration code sent to you by email.</p>
    <v-form ref="form" v-model="valid">
      <v-container>
        <v-row class="my-0">
          <v-col class="pa-0 pb-2">
            <v-text-field
              label="Email"
              type="email"
              variant="solo"
              v-model="username"
              :rules="[rules.required, rules.email]"
              validate-on="blur"
              ref="email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col class="pa-0 pb-2">
            <v-text-field
              label="Registration code"
              type="text"
              variant="solo"
              v-model="code"
              :rules="[rules.required]"
              validate-on="blur"
              ref="code"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col class="pa-0">
            <v-text-field
              label="New password"
              v-model="pass"
              :append-inner-icon="e1 ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append-inner="() => (e1 = !e1)"
              :type="e1 ? 'password' : 'text'"
              min="8"
              :rules="[rules.required]"
              hint="At least 8 characters, requires a mix of characters and numbers"
              counter
              validate-on="blur"
              v-on:keydown.enter="submit"
            ></v-text-field>
          </v-col>
        </v-row>

        <div class="bg-error" v-html="error" />
        <div class="message" v-html="message" />

        <v-row class="my-0">
          <v-col class="pl-0 pr-0 pb-0 d-flex justify-center">
            <v-btn color="primary" @click="submit">Confirm Reset Password</v-btn>
          </v-col>
        </v-row>

        <v-row class="my-6">
          <v-col class="pa-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="pa-0">
            <p class="ma-0 text-center">
              Go back to login?
              <router-link :to="{ path: '/login' }" tabindex="5">
                <span class="light--text">Login</span>
              </router-link>
            </p>
          </v-col>
        </v-row>

      </v-container>
    </v-form>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      username: '',
      code: '',
      pass: '',
      error: null,
      valid: true,
      message: null,
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      e1: true
    }
  },
  methods: {
    async submit (event) {
      event.preventDefault()
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.$cognitoAuth.confirmPassword(this.username, this.code, this.pass, (err, result) => {
          if (err) {
            this.error = err.message
          } else {
            this.$router.replace({ path: '/login', query: { resetPasswordSuccess: true, username: this.username } })
          }
        })
      }
    }
  },
  created () {
    if (this.$route.query.username !== '') {
      this.username = this.$route.query.username
    }
  },
  mounted () {
    if (this.$route.query.username) {
      this.$refs.code.focus()
    } else {
      this.$refs.email.focus()
    }
  }
}
</script>

<style scoped>
div.error {
  color: red;
  background: none !important;
  border: none;
}

.form-container {
  max-width: 600px;
  margin: 24px auto;
}
</style>
