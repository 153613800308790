<template>
  <v-dialog
    width="1200px"
    :scrollable="true"
    :model-value="open"
    @update:model-value="handleToogleDialog"
    v-on:keydown.stop
    v-on:cut.stop
    v-on:copy.stop
    v-on:paste.stop
  >
    <v-card :flat="true">
      <v-toolbar
        dense
        :flat="true"
        color="primary"
        dark
        extended
        extension-height="40"
      >
        <v-toolbar-title>Settings</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn data-test="close-settings" variant="text" icon  @click="$emit('close')" >
          <v-icon icon="mdi-close" color="grey" />
        </v-btn>

        <template #extension>
          <v-container fluid class="pa-0">
            <v-divider class="toolbar-divider"></v-divider>

            <workflow-settings-tabs
              :tabs="tabs"
              :active-tab="activeTabId"
              @change="onTabChange"
            ></workflow-settings-tabs>
          </v-container>
        </template>
      </v-toolbar>

      <workflow-settings-template-cards v-if="activeTabId === 'CARDS'" :workflow="workflow" :disabled="$store.state.isWorkflowDisabled" />
      <workflow-settings-template-events-roles v-if="activeTabId === 'TYPES'" :workflow="workflow" :disabled="$store.state.isWorkflowDisabled" />
      <workflow-settings-general v-if="activeTabId === 'GENERAL'" :workflow="workflow" :disabled="$store.state.isWorkflowDisabled" />
    </v-card>
  </v-dialog>
</template>

<script>
import WorkflowSettingsTabs from '@/components/WorkflowSettingsTabs'
import WorkflowSettingsGeneral from '@/components/WorkflowSettingsGeneral'
import WorkflowSettingsTemplateEventsRoles from '@/components/WorkflowSettingsTemplateEventsRoles'
import WorkflowSettingsTemplateCards from '@/components/WorkflowSettingsTemplateCards'

export default {
  components: {
    WorkflowSettingsTabs,
    WorkflowSettingsGeneral,
    WorkflowSettingsTemplateEventsRoles,
    WorkflowSettingsTemplateCards
  },

  props: {
    workflow: Object,
    open: Boolean,
    tabId: String
  },

  data () {
    return {
      tabs: [
        { id: 'CARDS', title: 'AI & Cards' },
        { id: 'TYPES', title: 'Events & Lanes' },
        { id: 'GENERAL', title: 'General' }
      ],
      activeTabId: 'CARDS'
    }
  },

  computed: {
    activeTab () {
      return this.tabs.find(({ id }) => id === this.activeTabId)
    }
  },

  watch: {
    open (newVal) {
      this.$nextTick(() => {
        if (!newVal) {
          this.activeTabId = 'CARDS'
        } else if (this.tabId) {
          this.activeTabId = this.tabId
        }
      })
    }
  },

  methods: {
    handleToogleDialog (val) {
      if (!val) {
        this.$emit('close')
      }
    },

    onTabChange (tab) {
      this.activeTabId = tab
    }
  }
}
</script>

<style scoped>
  .toolbar-divider {
    border-color: white !important;
  }
</style>
