<template>
  <v-dialog v-model="openAiInfo" width="450">
    <v-card>
      <v-col>
        <v-row class="justify-space-between align-top">
          <v-card-title>
            <v-icon class="ml-2" icon="mdi-creation"></v-icon>&nbsp;&nbsp;
            <span class="text-h5">Disclaimer</span>
          </v-card-title>

          <v-btn variant="text" icon color="grey" @click="openAiInfo = false">
            <v-icon icon="mdi-close"></v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-card-text>
        <v-container grid-list-md>
          <p class="mb-4">By using our AI Copilot you agree to share data with Open AI. Open AI is committed to building trust by
            protecting customer data, models and products. Open AI only retains data sent through the API for up to 30
            days for abuse and monitoring purposes.</p>
          <p class="my-4"> <strong>Read more about how Open AI manages customer data here:</strong></p>
          <p class="my-4"><a href="https://openai.com/policies/privacy-policy" target="_blank">Open AI Privacy policy</a></p>
          <p class="my-4"><a href="https://openai.com/policies/api-data-usage-policies" target="_blank">Open AI data usage policies</a>
          </p>
          <p class="my-4"><a href="https://openai.com/security" target="_blank">Open AI security</a></p>
        </v-container>
        <v-checkbox v-model="aiDisclaimerPreference" :label="$route.params.projectId ? 'Don\'t show again for this project' : 'Don\'t show again when creating projects'" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="text" @click="openAiInfo = false">Cancel</v-btn>
        <v-btn color="primary" variant="elevated" @click="saveDisclaimerPreference">{{
          "OK"
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      openAiInfo: false,
      aiDisclaimerPreference: localStorage.getItem(`showAiInfo-${this.$route.params.projectId ? this.$route.params.projectId : 'dashboard'}`) === 'true'
    }
  },
  props: ['open'],
  methods: {
    saveDisclaimerPreference () {
      this.setShowAiInfo(`${this.aiDisclaimerPreference}`)
      this.openAiInfo = false
      this.$emit('conditions-accepted')
    },
    openDialog () {
      if (this.getShowAiInfo()) {
        this.openAiInfo = false
        this.$emit('conditions-accepted')
      } else {
        this.openAiInfo = true
      }
    },
    close () {
      this.openAiInfo = false
      this.$emit('close')
    },
    getShowAiInfo () {
      if (this.$route.params.projectId) {
        return localStorage.getItem(`showAiInfo-${this.$route.params.projectId}`) === 'true'
      } else {
        return localStorage.getItem('showAiInfo-dashboard') === 'true'
      }
    },
    setShowAiInfo (value) {
      if (this.$route.params.projectId) {
        localStorage.setItem(`showAiInfo-${this.$route.params.projectId}`, value)
      } else {
        localStorage.setItem('showAiInfo-dashboard', value)
      }
    }
  }
}
</script>
