import gql from 'graphql-tag'

export default gql`
  subscription OnUpdateUserStatus($workflowId: String!)  {
    onUpdateUserStatus (workflowId: $workflowId) {
      userStatus {
        id
        userId
        requirementId
        prio
        message
        lastUpdate
      }
      workflowId
      eventId
    }
  }
`
