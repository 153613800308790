<template>
  <v-card flat>
    <v-layout column class="text-sm-center">
      <v-icon size="120">broken_image</v-icon>

      <p>We can't generate preview for this file.</p>
      <p>Try to download the file to view it.</p>

      <v-btn color="primary" download :href="url">
        Download
      </v-btn>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: {
    url: String
  }
}
</script>
