import { apolloClient } from '@/vue-apollo'
import GetCurrentUser from '@/graphql/queries/GetCurrentUser'
import ListUsers from '@/graphql/queries/ListUsers'
import CreateUser from '@/graphql/mutations/CreateUser'
import UpdateUser from '@/graphql/mutations/UpdateUser'

export const user = {
  namespaced: true,
  state: {
    user: null,
    users: null
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload
    },
    updateUser (state, payload) {
      state.user = payload
    },
    setUsers (state, payload) {
      state.users = payload
    }
  },
  actions: {
    async createUser ({ commit }, input) {
      try {
        const res = await apolloClient.mutate({
          mutation: CreateUser,
          variables: {
            ...input
          }
        })
        commit('setUser', res.data.createUser)
      } catch (e) {
        commit('snackbar/showMessage', { content: 'An error occurred while creating user', timeout: 6000, color: 'red' }, { root: true })
      }
    },

    async updateUser ({ commit }, input) {
      try {
        const res = await apolloClient.mutate({
          mutation: UpdateUser,
          variables: {
            ...input
          }
        })
        commit('setUser', res.data.updateUser)
      } catch (e) {
        commit('snackbar/showMessage', { content: 'An error occurred while updating user', timeout: 6000, color: 'red' }, { root: true })
      }
    },

    async getCurrentUser ({ commit }) {
      const res = await apolloClient.query({
        query: GetCurrentUser,
        fetchPolicy: 'no-cache'
      })
      commit('setUser', res.data.getCurrentUser)
      return res
    },

    async listUsers ({ commit }) {
      const res = await apolloClient.query({
        query: ListUsers,
        fetchPolicy: 'no-cache'
      })
      commit('setUsers', res.data.listUsers)
      return res
    }
  }
}
