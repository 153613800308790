import gql from 'graphql-tag'

export default gql`
  subscription OnUpdateRequirementType($workflowId: String) {
    onUpdateRequirementType(workflowId: $workflowId) {
      workflowId
      requirementTypes {
        id
        slot
        title
        description
        color {
          red
          green
          blue
          rgb
        }
        showInDiagram
        showInBacklog
        aiCount
        triggerType
        aggregateType
        defaultType
        workflowId
      }
    }
  }
`
