<template>
  <v-card class="table-container beam-table-container mb-2" :class="{'order-last' : dataModel.type == 'Dimension' && !(dataModel.connectedToEvents?.includes(eventId))}">
    <!-- <pre>{{ dataModel }}</pre> -->
    <v-toolbar flat color="white">
      <v-col cols="8" style="display: flex;" class="subheading font-weight-bold">
        <div
          class="main-label px-1 mr-1"
          :class="{
            'bg-yellow-lighten-4': dataModel.type == 'Event Story',
            'bg-green-lighten-4': dataModel.type == 'Dimension',
            'bg-red-lighten-4': dataModel.type == 'Fact Table'
          }">
          {{ dataModel.type }}:
        </div>
        <template v-if="dataModel.type == 'Dimension'">
          <v-icon @click="!$store.state.isWorkflowDisabled && removeConnectedToEvents(eventId)" v-if="dataModel.connectedToEvents?.includes(eventId)" class="ml-1">mdi-checkbox-marked</v-icon>
          <v-icon @click="!$store.state.isWorkflowDisabled && addConnectedToEvents(eventId)" v-else class="ml-1">mdi-checkbox-blank-outline</v-icon>
        </template>

        <div
          v-if="dataModel.type == 'Dimension'"
          class="ml-2 dimension-name"
          :id="`data-model-name-${indexDataModel}`"
          :contenteditable="!$store.state.isWorkflowDisabled"
          @keydown.stop.esc="restoreValue($event, dataModel.name)"
          @blur="blurDataModelName($event)"
          @keydown.stop.enter="$event.target.blur()"
          v-text="dataModel.name"
          title="Click to edit"
        ></div>
        <div
          v-else
          class="ml-2"
          v-text="`${eventDescription} by ${laneName}`"
          title="Event description and lane name"
        ></div>
        <workflow-help-dialog-event-story v-if="dataModel.type == 'Event Story'" />
        <workflow-help-dialog-fact-table v-if="dataModel.type == 'Fact Table'" />
        <workflow-help-dialog-dimension v-if="dataModel.type == 'Dimension'" />
      </v-col>
      <v-spacer></v-spacer>
      <v-col v-if="!$store.state.isWorkflowDisabled" xs4 class="text-right">
        <v-menu>
          <template #activator="{ props }">
            <v-btn icon="mdi-menu" variant="text" v-bind="props" tabindex="-1" data-test="data-model-actions-menu" />
          </template>
          <v-list>
            <v-list-item @click="focusOnField(`data-model-name-${indexDataModel}`)" v-if="dataModel.type == 'Dimension'">
              <v-list-item-action>
                <v-icon class="mr-2" color="grey">mdi-pencil</v-icon> Edit Name
              </v-list-item-action>
            </v-list-item>
            <v-list-item @click="$emit('delete-data-model', indexDataModel)">
              <v-list-item-action>
                <v-icon class="mr-2" color="grey">mdi-delete</v-icon> Delete
              </v-list-item-action>
            </v-list-item>
            <v-list-item @click="changeSelectedEvent(null)">
              <v-list-item-action>
                <v-icon class="mr-2" color="grey">mdi-matrix</v-icon> View Event Matrix
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-toolbar>
    <v-table class="elevation-1">
      <template v-slot:default v-if="!(dataModel.type == 'Dimension' && !dataModel.connectedToEvents?.includes(eventId))">
        <tbody v-if="dataModel.dataFields.length > 0">
          <template v-for="(row, index) in rows">

            <!-- type: verb -->
            <tr v-if="row.type == 'verb'" :key="index">
              <td v-if="dataModel.dataFields.length > 0" class="field-label" rowspan="2">{{ row['rowLabel'] }}</td>
              <td v-else>Click the plus sign to add a field</td>
              <td v-for="(field, indexDataField) in dataModel.dataFields" :key="indexDataField" class="px-0 field-name-wrapper header-cell">
                <beam-table-cell
                  :key="idTable(indexDataModel, index, indexDataField)"
                  :ref="idTable(indexDataModel, index, indexDataField)"
                  :tab="idTable(indexDataModel, index, indexDataField)"
                  v-model:value="field[row['fieldName']]"
                  @leave-cell="leaveCell($event, indexDataModel, index, indexDataField)"
                  @blur="$emit('blur-table-cell', $event, indexDataModel, indexDataField, row['fieldName'], null, eventId)"
                  tag="div"
                  header
                />
                <div class="delete-data-field" v-if="!$store.state.isWorkflowDisabled">
                  <v-icon size="small" color="white" @click="$emit('move-left', indexDataModel, indexDataField)">mdi-chevron-left</v-icon>
                  <v-icon size="small" color="white" @click="$emit('move-right', indexDataModel, indexDataField)">mdi-chevron-right</v-icon>
                  <v-icon size="small" color="white" @click="$emit('delete-data-field', indexDataModel, indexDataField)">mdi-delete</v-icon>
                  <v-icon size="small" color="white" @click="$emit('add-column', indexDataModel, indexDataField)">mdi-plus</v-icon>
                </div>
              </td>
              <td
                class="add-field-button px-0 header-cell text-white text-center"
                @click="!$store.state.isWorkflowDisabled ? $emit('add-column', indexDataModel) : null"
                rowspan="2">
                <b>+</b>
              </td>
            </tr>

            <!-- type: header-after-verb -->
            <tr v-if="row.type == 'header-after-verb'" :key="index">
              <td v-for="(field, indexDataField) in dataModel.dataFields" :key="indexDataField" class="px-0 header-cell">
                <beam-table-cell
                  :key="idTable(indexDataModel, index, indexDataField)"
                  :ref="idTable(indexDataModel, index, indexDataField)"
                  :tab="idTable(indexDataModel, index, indexDataField)"
                  v-model:value="field[row['fieldName']]"
                  @leave-cell="leaveCell($event, indexDataModel, index, indexDataField)"
                  @blur="$emit('blur-table-cell', $event, indexDataModel, indexDataField, row['fieldName'], null, eventId)"
                  tag="div"
                  header
                />
              </td>
            </tr>

            <!-- type: header -->
            <tr v-if="row.type == 'header'" :key="index">
              <td class="field-label">{{ dataModel.dataFields.length > 0 ? row['rowLabel'] + ':' : 'Click the plus sign to add a field' }}</td>
              <td disabled="true" v-for="(field, indexDataField) in dataModel.dataFields" :key="indexDataField" class="px-0 field-name-wrapper">
                <beam-table-cell
                  :key="idTable(indexDataModel, index, indexDataField)"
                  :ref="idTable(indexDataModel, index, indexDataField)"
                  :tab="idTable(indexDataModel, index, indexDataField)"
                  v-model:value="field[row['fieldName']]"
                  @leave-cell="leaveCell($event, indexDataModel, index, indexDataField)"
                  @blur="$emit('blur-table-cell', $event, indexDataModel, indexDataField, row['fieldName'], null, eventId)"
                  tag="div"
                  header
                />
                <div class="delete-data-field text-white" v-if="!$store.state.isWorkflowDisabled">
                  <v-icon size="small" color="white" @click="$emit('move-left', indexDataModel, indexDataField)">mdi-chevron-left</v-icon>
                  <v-icon size="small"  @click="$emit('move-right', indexDataModel, indexDataField)">mdi-chevron-right</v-icon>
                  <v-icon size="small"  @click="$emit('delete-data-field', indexDataModel, indexDataField)">mdi-delete</v-icon>
                </div>
              </td>
              <td
                class="add-field-button px-0 header-cell text-white text-center"
                @click="!$store.state.isWorkflowDisabled ? $emit('add-column', indexDataModel) : null">
                <b>+</b>
              </td>
            </tr>

            <!-- type: text -->
            <tr v-if="row.type == 'text'" :key="index">
              <td class="field-label">{{ row['rowLabel'] }}:</td>
                <beam-table-cell
                  v-for="(field, indexDataField) in dataModel.dataFields" :key="idTable(indexDataModel, index, indexDataField)"
                  :ref="idTable(indexDataModel, index, indexDataField)"
                  :tab="idTable(indexDataModel, index, indexDataField)"
                  v-model:value="field[row['fieldName']]"
                  @leave-cell="leaveCell($event, indexDataModel, index, indexDataField)"
                  @blur="$emit('blur-table-cell', $event, indexDataModel, indexDataField, row['fieldName'], null, eventId)"
                />
            </tr>

            <!-- type: select -->
            <tr v-if="row.type == 'select'" :key="index">
              <td class="field-label">{{ row['rowLabel'] }}:</td>
              <template v-for="(field, indexDataField) in dataModel.dataFields" :key="idTable(indexDataModel, index, indexDataField)">
                <beam-table-cell-select
                  :ref="idTable(indexDataModel, index, indexDataField)"
                  :tab="idTable(indexDataModel, index, indexDataField)"

                  v-model:value="field[row['fieldName']]"
                  :types="types"
                  @leave-cell="leaveCell($event, indexDataModel, index, indexDataField)"
                  @blur="$emit('blur-table-cell', $event, indexDataModel, indexDataField, row['fieldName'], null, eventId)"
                  @click-type="$emit('click-type', $event, indexDataModel, indexDataField)" />
              </template>
              <td class="bg-blue-lighten-5"></td>
            </tr>

            <!-- type: dimension -->
            <tr v-if="row.type == 'dimension'" :key="index">
              <td class="field-label">Dimension / Fact:</td>
              <template v-for="(field, indexDataField) in dataModel.dataFields" :key="idTable(indexDataModel, index, indexDataField)">
                <beam-table-cell-select-from-table
                  :ref="idTable(indexDataModel, index, indexDataField)"
                  :tab="idTable(indexDataModel, index, indexDataField)"
                  v-model:value="field[row['fieldName']]"
                  :headers="dimensionsHeaders"
                  :items="dimensions"
                  @leave-cell="leaveCell($event, indexDataModel, index, indexDataField)"
                  @blur="$emit('blur-table-cell', $event, indexDataModel, indexDataField, row['fieldName'], null, eventId)"
                  @click-type="$emit('click-type', $event, indexDataModel, indexDataField)"
                />
              </template>
              <td></td>
            </tr>

            <!-- type: dimension-data -->
            <tr v-if="row.type == 'dimension-data'" :key="index">
              <td class="field-label">{{ row['rowLabel'] }}</td>
              <template v-for="(field, indexDataField) in dataModel.dataFields" :key="idTable(indexDataModel, index, indexDataField)">
                <beam-table-cell

                  :ref="idTable(indexDataModel, index, indexDataField)"
                  :tab="idTable(indexDataModel, index, indexDataField)"
                  v-model:value="field[row['fieldName']]"
                  @leave-cell="leaveCell($event, indexDataModel, index, indexDataField)"
                  @blur="$emit('blur-table-cell', $event, indexDataModel, indexDataField, row['fieldName'], null, eventId)"
                  cell dimension-data monospace />
              </template>
              <td class="bg-orange-lighten-5"></td>
            </tr>

            <!-- type: checkbox -->
            <tr v-if="row.type == 'checkbox'" :key="index">
              <td class="field-label">{{ row['rowLabel'] }}</td>
              <template v-for="(field, indexDataField) in dataModel.dataFields" :key="idTable(indexDataModel, index, indexDataField)">
                <beam-table-cell-checkbox

                  :ref="idTable(indexDataModel, index, indexDataField)"
                  :tab="idTable(indexDataModel, index, indexDataField)"
                  :tags="field['tags']"
                  @leave-cell="leaveCell($event, indexDataModel, index, indexDataField)"
                  @set-tags="$emit('click-tags', $event, indexDataModel, indexDataField)"
                />
              </template>
              <td></td>
            </tr>

            <!-- type: example-data -->
            <template v-if="row.type == 'example-data'">
              <tr v-for="exampleIndex in (getNumberOfExampleDataRows(dataModel.dataFields) + 3)" :key="index + (exampleIndex - 1)">
                <td class="field-label" v-if="exampleIndex == 1">
                  Typical data:
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <v-icon size="x-small" v-bind="props" class="ml-0">mdi-help-circle-outline</v-icon>
                    </template>
                    <span>Ask for the most common example of data for each field.</span>
                  </v-tooltip>
                </td>
                <td class="field-label" v-if="exampleIndex == 2">
                  Different:
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <v-icon size="x-small" v-bind="props" class="ml-0">mdi-help-circle-outline</v-icon>
                    </template>
                    <span>Ask for the second most common example that is different from the typical row above.</span>
                  </v-tooltip>
                </td>
                <td class="field-label" v-if="exampleIndex == 3">
                  Different:
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <v-icon size="x-small" v-bind="props" class="ml-0">mdi-help-circle-outline</v-icon>
                    </template>
                    <span>Ask for the third most common example that is different from both rows above.</span>
                  </v-tooltip>
                </td>
                <td class="field-label" v-if="exampleIndex == 4">
                  Repeat:
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <v-icon size="x-small" v-bind="props" class="ml-0">mdi-help-circle-outline</v-icon>
                    </template>
                    <span>Create a row as identical to "Typical data" as possible. The goal is to make sure that we haven't missed out on a unique identifier.</span>
                  </v-tooltip>
                </td>
                <td class="field-label" v-if="exampleIndex == 5">
                  Missing:
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <v-icon size="x-small" v-bind="props" class="ml-0">mdi-help-circle-outline</v-icon>
                    </template>
                    <span>Construct a row with the fewest possible values, fill in only the mandatory fields.</span>
                  </v-tooltip>
                </td>
                <td class="field-label" v-if="exampleIndex == 6">
                  Group:
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <v-icon size="x-small" v-bind="props" class="ml-0">mdi-help-circle-outline</v-icon>
                    </template>
                    <span>Add a row that includes multiple items in each field where possible. For instance, if a customer can order multiple products in a single order, represent this by adding two article IDs with a '+' in between.</span>
                  </v-tooltip>
                </td>
                <td class="field-label" v-if="exampleIndex > 6"></td>
                <template v-for="(field, indexDataField) in dataModel.dataFields" :key="idTable(indexDataModel, index + exampleIndex - 1, indexDataField)">
                  <beam-table-cell
                    :ref="idTable(indexDataModel, index + exampleIndex - 1, indexDataField)"
                    :tab="idTable(indexDataModel, index + exampleIndex - 1, indexDataField)"
                    v-model:value="field['exampleData'][exampleIndex - 1]"
                    @leave-cell="leaveCell($event, indexDataModel, index + exampleIndex - 1, indexDataField)"
                    @blur="$emit('blur-table-cell', $event, indexDataModel, indexDataField, 'exampleData', exampleIndex - 1, eventId)"
                    monospace cell />
                </template>
                <td></td>
              </tr>
            </template>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td>Click the plus sign on the right to add a field!</td>
            <td
              class="add-field-button px-0 header-cell text-white text-center"
              @click="!$store.state.isWorkflowDisabled ? $emit('add-column', indexDataModel) : null">
              <b>+</b>
            </td>
          </tr>
        </tbody>
      </template>
    </v-table>
  </v-card>
</template>

<script>
import BeamTableCell from '@/components/BeamTableCell'
import BeamTableCellSelect from '@/components/BeamTableCellSelect'
import BeamTableCellSelectFromTable from '@/components/BeamTableCellSelectFromTable'
import BeamTableCellCheckbox from '@/components/BeamTableCellCheckbox'
import { diagramHelpers } from '@/mixins/diagramHelpers.js'
import WorkflowHelpDialogEventStory from '@/components/WorkflowHelpDialogEventStory'
import WorkflowHelpDialogFactTable from '@/components/WorkflowHelpDialogFactTable'
import WorkflowHelpDialogDimension from '@/components/WorkflowHelpDialogDimension'

export default {
  name: 'beam-table',

  mixins: [diagramHelpers],

  components: {
    BeamTableCell,
    BeamTableCellSelect,
    BeamTableCellSelectFromTable,
    BeamTableCellCheckbox,
    WorkflowHelpDialogEventStory,
    WorkflowHelpDialogFactTable,
    WorkflowHelpDialogDimension
  },

  data () {
    return {
      types: [
        '[Who]',
        '[What]',
        '[When]',
        '[Where]',
        '[How]',
        '[How many]'
      ],
      dimensionsHeaders: [
        { title: 'Entity', value: 'entity' },
        { title: 'Key', value: 'keyType' },
        { title: 'Source System', value: 'sourceSystem' },
        { title: 'Table name', value: 'table' },
        { title: 'Type', value: 'type' }
      ]
    }
  },

  props: {
    dataModel: Object,
    dimensions: Array,
    indexDataModel: Number,
    rows: Array,
    eventId: String,
    eventDescription: String,
    laneName: String
  },

  methods: {
    removeConnectedToEvents (eventId) {
      this.$emit('remove-connected-to-events', eventId)
    },
    addConnectedToEvents (eventId) {
      this.$emit('add-connected-to-events', eventId)
    },
    blurDataModelName (event) {
      if (event.target.innerText.length === 0) {
        this.restoreValue(event, this.dataModel.name)
        return
      }
      this.$emit('blur-data-model-name', event)
    },
    focusOnField (id) {
      document.getElementById(id).focus()
    },
    focusOnRef (ref) {
      if (this.$refs[ref]) {
        if (this.$refs[ref][0].setFocus) {
          this.$refs[ref][0].setFocus()
        } else if (this.$refs[ref].setFocus) {
          this.$refs[ref].setFocus()
        } else {
          document.getElementById(ref).focus()
        }
      }
    },
    getNumberOfExampleDataRows (dataFields) {
      let numberOfExampleDataRows = 0
      const allExampleDataArrays = dataFields.map(field => field.exampleData)
      for (const exampleDataArray of allExampleDataArrays) {
        for (const [index, exampleData] of exampleDataArray.entries()) {
          if (exampleData && exampleData.trim().length > 0) {
            if (index > numberOfExampleDataRows) {
              numberOfExampleDataRows = index
            }
          }
        }
      }
      return numberOfExampleDataRows
    },
    idTable (indexDataModel, row, column) {
      return (indexDataModel + 1) * 10000 + row * 100 + column
    },
    leaveCell (event, indexDataModel, row, indexDataField) {
      const ref = this.idTable(indexDataModel, row, indexDataField)
      switch (event.keyCode) {
        case 13: // enter
          this.focusOnRef(ref + 100) // move one cell down
          break
        case 37: // left arrow
          this.focusOnRef(ref - 1) // move left
          break
        case 38: // up arrow
          this.focusOnRef(ref - 100) // move down
          break
        case 39: // right arrow
          this.focusOnRef(ref + 1) // move right
          break
        case 40: // down arrow
          this.focusOnRef(ref + 100) // move down
          break
      }
      event.preventDefault()
      event.stopPropagation()
    }
  }
}
</script>

<style>
.main-label {
  color: #444;
  border-radius: 10px;
}

.header-cell {
  background-color: #041f34;
  padding: 6px 16px 6px 16px;
  font-size: 14px;
  font-weight: 500;
}

.delete-data-field {
  background-color: #041f34;
  display: none;
  cursor: pointer;
  position: absolute;
  top: 0%;
  line-height: 33px;
  right: 10px;
}

.field-name-wrapper {
  height: 100%;
  position: relative;
}

.field-name-wrapper:hover .delete-data-field {
  display: block;
}

.delete-data-field:focus {
  display: block;
}

.add-field-button {
  width: 50px;
}

.add-field-button:hover {
  cursor: pointer;
}

tr > td:focus-within, td:focus {
  outline: 2px solid #4D90FE;
  z-index: 3000;
  position: relative;
}

.v-btn__content div {
  outline: none;
}

.beam-table-container table tbody td,
.beam-table-container table.v-table tbody th {
  height: 36px !important;
  min-width: 150px !important;
}

.table-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);;
}

.field-label {
  background-color: #eee;
  padding: 6px 24px 6px 24px !important;
  width: 132px;
  font-size: 12px !important;
  font-weight: 500;
  color: rgba(0,0,0,.54);
}

.order-last {
  order: 200;
}

.dimension-name {
  border: 1px solid transparent;
  padding: 1px;
}

.dimension-name[contenteditable="true"]:hover {
  background-color: #f0f0f0;
  border: 1px dashed #ccc;
}

.dimension-name[contenteditable="true"]:focus {
  border: 1px solid rgb(77, 144, 254);
  background-color: #e9f7ff;
}
</style>
