import cognitoAuth from '@/cognito'
import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'

export default class SES {
  async sendEmail (recipientEmail, project, senderName) {
    // console.log('project.organisation.name', project.organisation.name)
    const params = {
      Destination: {
        ToAddresses: [
          recipientEmail
        ]
      },
      Message: {
        Body: {
          Html: {
            Charset: 'UTF-8',
            Data: `
              <div>
                <div style="background: #022A44">
                  <img
                    src="https://app.qlerify.com/static/img/qlerify-logo.ba59ccb.png"
                    alt="Qlerify logo"
                    style="width: 150px; display: block;"
                  >
                </div>

                <div style="padding: 20px; margin-bottom: 50px;">
                  <p>You have been invited to work with the project <b>${project.organisation.name}: ${project.name}</b> in the collaboration tool Qlerify. You were invited by ${senderName}. Follow this link to login or sign up:</p>
                  <p style="font-size: 18px;"><a href="${window.location.origin}/dashboard/${project.id}">${window.location.origin}/dashboard/${project.id}</a></p>
                </div>

                <div style="max-width: 340px; margin: 0 auto;">
                  <hr>
                  <p style="text-align: center;">This email was sent to you from Qlerify.</p>
                  <p style="text-align: center;">
                    <img
                      src="https://app.qlerify.com/static/img/qlerify-logo.ba59ccb.png"
                      alt="Qlerify logo"
                      style="width: 100px;"
                    >
                  </p>
                  <p style="text-align: center;">Phone: +46 (0)701 84 95 50</p>
                  <p style="text-align: center;">Email: <a href="mailto:support@qlerify.com">support@qlerify.com</a></p>
                  <p style="text-align: center;"><a href="https://qlerify.com">https://qlerify.com</a></p>
                </div>
              </div>
            `
          },
          Text: {
            Charset: 'UTF-8',
            Data: `You have been invited to work with the project ${project.organisation.name}: ${project.name} in the collaboration tool Qlerify. You were invited by ${senderName}. Follow this link to login or sign up: ${window.location.origin}/signup`
          }
        },
        Subject: {
          Charset: 'UTF-8',
          Data: 'Invitation to Qlerify by ' + senderName
        }
      },
      Source: 'support@qlerify.com',
      ReplyToAddresses: [
        'support@qlerify.com'
      ]
    }

    const logins = {}
    logins['cognito-idp.' + process.env.VUE_APP_AWS_REGION + '.amazonaws.com/' + process.env.VUE_APP_COGNITO_USER_POOL_ID] = await cognitoAuth.getIdTokenPromise()
    const credentials = fromCognitoIdentityPool({
      clientConfig: { region: process.env.VUE_APP_AWS_REGION },
      identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
      logins
    })
    const client = new SESClient({ region: process.env.VUE_APP_AWS_REGION, credentials })
    const command = new SendEmailCommand(params)

    try {
      await client.send(command)
    } catch (error) {
      console.log(error)
    }
  }
}
