import { apolloClient } from '@/vue-apollo'
import CreateProject from '@/graphql/mutations/CreateProject'
import UpdateProject from '@/graphql/mutations/UpdateProject'
import DeleteProject from '@/graphql/mutations/DeleteProject'

export const project = {
  namespaced: true,
  actions: {
    async createProject ({ commit }, { organisationId, name }) {
      try {
        const res = await apolloClient.mutate({
          mutation: CreateProject,
          variables: {
            name,
            organisationId
          },
          fetchPolicy: 'no-cache'
        })
        commit('projectMemberships/addProjectMembership', res.data.createProject.createAdminTeamMember, { root: true })
        commit('snackbar/showMessage', { content: 'Created' }, { root: true })
        return res.data.createProject.createProjectFunction.id
      } catch (e) {
        console.log(e)
      }
    },

    async updateProject ({ commit, state, rootState }, item) {
      try {
        const res = await apolloClient.mutate({
          mutation: UpdateProject,
          variables: {
            id: item.id,
            organisationId: item.organisationId,
            name: item.name,
            goals: item.goals
          },
          fetchPolicy: 'no-cache'
        })
        commit('projectMemberships/updateProject', res.data.updateProject, { root: true })
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
        return res.data.updateProject.id
      } catch (e) {
        console.log(e)
      }
    },

    async deleteProject ({ commit }, { id }) {
      try {
        const res = await apolloClient.mutate({
          mutation: DeleteProject,
          variables: { id },
          fetchPolicy: 'no-cache'
        })
        commit('projectMemberships/deleteProjectMembership', { projectId: res.data.deleteProject.id }, { root: true })
        commit('snackbar/showMessage', { content: 'Deleted' }, { root: true })
        return res.data.deleteProject
      } catch (e) {
        console.log(e)
      }
    }
  }
}
