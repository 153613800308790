<template>
  <v-btn
    color="primary"
    :disabled="$store.state.isWorkflowDisabled || !$route.params.eventId"
    class="elevation-1 add-requirement-button"
    @click="saveRequirement(true)"
  >
    <template v-if="$route.params.eventId">
      Add card to &nbsp;
      <span style="text-transform: none">
        {{
          currentEvent && currentEvent.description
            ? '"' +
              currentEvent.description.substring(0, 30) +
              (currentEvent.description.length > 30 ? ".." : "") +
              '"'
            : ""
        }}
      </span>
    </template>

    <template v-else> Select an event to add cards </template>
  </v-btn>
</template>

<script>
import workflowApi from '@/api/workflowApi'
import { mapState } from 'vuex'

export default {
  props: [
    'currentEvent',
    'requirementTypes'
  ],
  computed: {
    ...mapState({
      showReleaseNo: state => state.workflow.showReleaseNo
    })
  },
  methods: {
    async saveRequirement (focus = false) {
      const cardType = this.requirementTypes.find(type => type.defaultType)
      const releaseNo = this.showReleaseNo?.length > 0 ? this.showReleaseNo[this.showReleaseNo.length - 1] : undefined
      const res = await workflowApi.createRequirementJson(this.$route.params.id, this.currentEvent.id, {
        description: '<p>New Card</p>',
        cardTypeId: cardType ? cardType.id : undefined,
        releaseNo
      })
      if (focus && res.requirements?.length) {
        this.$emit('focus-card', { id: res.requirements[0].id })
      }
    }
  }
}
</script>

<style scoped>
.v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
  box-shadow: none;
}

.add-requirement-button {
  height: 30px !important;
  max-width: 310px;
  border-radius: 10px !important;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.add-requirement-button .v-btn__content {
  height: 16px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline;
  max-width: 100%;
}

</style>
