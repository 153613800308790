<template>
  <v-dialog :scrollable="true" max-width="30%" v-model="open" content-class="dialog-top-right">
    <template #activator="{ props }">
      <slot :props="props"></slot>
    </template>

    <v-card :flat="true" >
      <v-toolbar dense :flat="true" dark color="primary">
        <v-toolbar-title><v-icon> mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>Create a workflow with AI</b></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <h3>Quickly create a workflow with AI</h3>
        If you want to get started quickly, you can create a workflow here
        by completing the sentence "Create a workflow containing ..."

        <br /><br />

        You can try one of the examples by clicking on it and modify
        it as you wish. You can also write your own sentence.<br /><br />

        To start generating the workflow, click on <b>CREATE WITH AI</b>
      </v-card-text><br /><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['openedFromSettings'],
  data () {
    return {
      open: false
    }
  }
}
</script>

<style>
.v-dialog .help-ai {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 30%;
  overflow-y: scroll;
}
</style>
