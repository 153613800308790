import gql from 'graphql-tag'

export default gql`
  mutation addRequirementComment($workflowId: String!, $requirementId: ID!, $input: RequirementCommentInput!) {
    addRequirementComment(workflowId: $workflowId, requirementId: $requirementId, input: $input) {
      workflowId
      requirementId
      id
      author {
        id
        firstname
        lastname
      }
      text
      dateCreated
      dateUpdated
      generatedByAi
    }
  }
`
