export const updateFontSizeDynamically = (elementRef, onWidth = false, defaultFontSize = 14, minFontSize = 10) => {
  elementRef.style.fontSize = defaultFontSize + 'px'

  // eslint-disable-next-line no-unmodified-loop-condition
  while (true) {
    const scrollDimension = onWidth ? elementRef.scrollWidth : elementRef.scrollHeight
    const clientDimension = onWidth ? elementRef.clientWidth : elementRef.clientHeight

    if (scrollDimension <= clientDimension || elementRef.style.fontSize <= minFontSize) {
      break
    }

    const currentFontSize = parseInt(window.getComputedStyle(elementRef).fontSize)
    elementRef.style.fontSize = `${currentFontSize - 1}px`
  }
}
