import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes' // Assuming all route definitions are moved here
import store from '@/store'
import cognitoAuth from '@/cognito'
import { authHelpers } from '@/api/authHelpers'

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresNoAuth = to.matched.some(record => record.meta.requiresNoAuth)
  const requiresProject = to.matched.some(record => record.meta.requiresProject)
  const allowNonTeamMember = to.matched.some(record => record.meta.allowNonTeamMember)

  try {
    const authenticated = await cognitoAuth.isAuthenticatedPromise()

    if (requiresAuth && (!authenticated || !store.state.isUserLoggedIn)) {
      handleUnauthenticatedUser(to, next)
      return
    }

    if (authenticated) {
      await handleAuthenticatedUser(to, from, next, requiresNoAuth, requiresProject, allowNonTeamMember)
      return
    }

    if (shouldRedirectToWorkflow(to, from)) {
      redirectToWorkflow(to, from, next)
      return
    }

    next()
  } catch (error) {
    console.error('Authentication error', error)
    authHelpers.logoutAndClearStore()
    next('/login/')
  }
})

function handleUnauthenticatedUser (to, next) {
  authHelpers.logoutAndClearStore()
  next(`/login${to.params.projectId ? `?projectId=${to.params.projectId}` : '/'}`)
}

async function handleAuthenticatedUser (to, from, next, requiresNoAuth, requiresProject, allowNonTeamMember) {
  if (allowNonTeamMember) {
    nextWithQueryParams(to, from, next)
    return
  }

  if (requiresNoAuth) {
    next()
    return
  }

  const validProject = requiresProject ? await projectIsValid(to.params.projectId) : true

  if (!validProject) {
    next('/404')
    return
  }

  nextWithQueryParams(to, from, next)
}

function nextWithQueryParams (to, from, next) {
  if (!hasQueryParams(to) && hasQueryParams(from) && !!from.query.selectedTab && to.path.includes('/workflow/')) {
    // keep selected tab open
    next({
      path: to.path,
      query: from.query
    })
  } else {
    next()
  }
}

function redirectToWorkflow (to, from, next) {
  next({
    path: to.path,
    params: to.params,
    query: from.query
  })
}

function shouldRedirectToWorkflow (to, from) {
  return !hasQueryParams(to) && hasQueryParams(from) && from.query.selectedTab && to.path.includes('/workflow/')
}

function hasQueryParams (route) {
  return !!Object.keys(route.query).length
}

async function projectIsValid (projectId) {
  if (store.state.adminUser) {
    return true
  }
  return await checkProjectInMemberships(projectId)
}

async function checkProjectInMemberships (projectId, fetchPolicy) {
  let listProjectMembershipsForCurrentUser = store.state.projectMemberships.projectMemberships
  if (listProjectMembershipsForCurrentUser?.some(membership => membership.project.id === projectId)) {
    return true
  }
  await store.dispatch('projectMemberships/listProjectMembershipsForCurrentUser')
  listProjectMembershipsForCurrentUser = store.state.projectMemberships.projectMemberships
  if (listProjectMembershipsForCurrentUser?.some(membership => membership.project.id === projectId)) {
    return true
  }
  return false
}

export default router
