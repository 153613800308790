import gql from 'graphql-tag'

export default gql`
  mutation DeleteRequirementComment($id: ID!, $workflowId: String!, $requirementId: String!) {
    deleteRequirementComment(id: $id, workflowId: $workflowId, requirementId: $requirementId) {
      workflowId
      requirementId
      id
    }
  }
`
