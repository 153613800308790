import gql from 'graphql-tag'

export default gql`
  mutation deleteWorkflow($workflowId: String!, $projectId: String!) {
    deleteWorkflow(workflowId: $workflowId, projectId: $projectId) {
      projectId
      deleteWorkflow {
        id
      }
    }
  }
`
