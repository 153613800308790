<template>
  <v-dialog :scrollable="true" max-width="30%" v-model="open" content-class="dialog-top-right">
    <template v-slot:activator="{ props }">
      <v-icon v-bind="props" size="small" class="ml-2">mdi-help-circle-outline</v-icon>
    </template>

    <v-card flat>
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title><v-icon> mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>How to use Dimensions</b></v-toolbar-title>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <h3><span class="bg-green-lighten-4">Dimensions</span></h3>
          The dimensions represent associated entities referenced by
          the Event Story and Fact Table. It can also be helpful to
          think of the dimensions as details describing the business
          event. Later, when connecting Business Intelligence (BI) to
          analyse events, we typically group the data by the dimensions.
          <br /><br />
          A dimension can be reused across multiple events. For example,
          if you have a dimension called 'Customer' and multiple events
          reference this customer in their Event Stories, you can reuse
          the same 'Customer' dimension for all those events. To do this,
          click the checkbox next to the dimension name.
        <br /><br /><br />
      </v-card-text><br /><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  }
}
</script>

<style>
.v-dialog .help-ai {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 30%;
  overflow-y: scroll;
}
</style>
