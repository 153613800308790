import gql from 'graphql-tag'
import { WORKFLOW_FIELDS } from '../fragments'

export default gql`
  ${WORKFLOW_FIELDS}
  mutation moveRequirementJson($projectId: String!, $input: MoveRequirementJsonInput!) {
    moveRequirementJson(projectId: $projectId, input: $input) {
      ...WorkflowFields
    }
  }
`
