import gql from 'graphql-tag'

export default gql`
  subscription OnDeleteRequirementComment($workflowId: String) {
    onDeleteRequirementComment(workflowId: $workflowId) {
      workflowId
      requirementId
      id
    }
  }
`
