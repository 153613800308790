<template>
  <v-card>
    <v-card-text v-if="jiraIssue">
      <div class="text-body-2">
        {{ jiraIssue.issueKey }}: {{ jiraIssue.summary }}
      </div>
      <div class="text-caption">
        Status: <b>{{ jiraIssue.statusName }}</b>
      </div>
      <div class="text-caption issue-type">
        Type:
        <img :src="jiraIssue.typeIcon" alt="issue type" class="ml-1 mr-1" />
        <b>{{ jiraIssue.typeName }}</b>
        <v-spacer></v-spacer>
        <v-tooltip location="top">
          <template v-slot:activator="{ on, props }">
            <img
              v-bind="props"
              style="width: 20px"
              :src="jiraIssue.userAvatar"
              alt="issue type"
              class="ml-1 mr-1"
              v-on="{ ...on }"
            />
          </template>
          <span>{{ jiraIssue.userName }}</span>
        </v-tooltip>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: ['requirement'],

  computed: {
    jiraIssue () {
      return JSON.parse(this.requirement.jiraIssue)
    }
  }
}
</script>

<style scoped>
.issue-type {
  display: flex;
  align-items: center;
}
</style>
