<template>
  <div>
    <v-card v-for="(goal, index) in goals" :key="goal.title + index" class="my-4">
      <v-toolbar color="white" elevation="0" density="compact" height="44px" class="pt-2 hover-container">
        <h3
          class="goalTitle mx-3"
          :contenteditable="disabled ? 'false' : 'plaintext-only'"
          @keydown="userUpdateGoalTitle($event, index)"
          @blur="updateGoalTitle($event, index)"
        >{{goal.title}}</h3>
        <v-btn v-if="!disabled"
          color="grey" variant="text"
          class="delete-goal-btn show-on-hover"
          @click="userDeleteGoal(index)"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="px-3 pt-0">
        <text-editor
          class="editor"
          :index="index"
          :text="goal.description"
          :disabled="disabled"
          @text-update="userUpdateGoal"
          @move-focus="moveFocus">
        </text-editor>
      </v-card-text>
    </v-card>
    <div v-if="!goals?.length" class="my-8">
      {{ placeholder }}
    </div>
  </div>
</template>

<script>
import TextEditor from '@/components/TextEditor.vue'

export default {
  name: 'GoalList',
  props: {
    goals: Array,
    placeholder: String,
    disabled: Boolean
  },
  components: {
    TextEditor
  },
  methods: {
    userAddGoal () {
      const length = this.goals?.length || 0
      const goal = {
        title: 'Goal ' + (length + 1),
        description: '<p>New goal</p>'
      }
      let updatedGoals
      if (this.goals) {
        updatedGoals = [...this.goals, goal]
      } else {
        updatedGoals = [goal]
      }

      this.updateGoals(updatedGoals, 'added goal', goal.description, true)
    },

    userUpdateGoal (newDescription, _, index) {
      if (index < this.goals?.length) {
        const updatedGoals = JSON.parse(JSON.stringify(this.goals))
        updatedGoals[index].description = newDescription
        this.updateGoals(updatedGoals, 'updated goal', newDescription)
      }
    },

    userDeleteGoal (index) {
      if (index < this.goals?.length) {
        const description = this.goals[index].description
        const updatedGoals = [...this.goals]
        updatedGoals.splice(index, 1)
        this.updateGoals(updatedGoals, 'removed goal', description)
      }
    },

    userUpdateGoalTitle (event, index) {
      if (event.keyCode === 13) {
        this.updateGoalTitle(event, index)
        event.target.blur()
        event.preventDefault()
        event.stopPropagation()
      }
    },

    updateGoalTitle (event, index) {
      if (index < this.goals?.length) {
        const oldTitle = this.goals[index].title
        const newTitle = event.target.innerText.trim()
        if (newTitle === '') {
          event.target.innerText = oldTitle
          return
        }

        if (oldTitle !== newTitle) {
          const updatedGoals = JSON.parse(JSON.stringify(this.goals))
          updatedGoals[index].title = newTitle
          this.updateGoals(updatedGoals, 'updated goal title', newTitle)
        }
        event.target.innerText = newTitle
      }
    },

    updateGoals (updatedGoals, updateAction, description, isNewGoal) {
      const goals = updatedGoals.map(it => {
        return {
          description: it.description,
          title: it.title || null
        }
      })
      const updateNote = {
        action: updateAction,
        target: description
      }

      this.$emit('changed', goals, updateNote, isNewGoal)
    },

    moveFocus (direction, target) {
      target.firstChild.blur()
    }
  }
}
</script>

<style scoped>

.goalTitle {
  outline: none transparent;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.editor {
  padding: 3px 0 !important;
}

.delete-goal-btn .v-icon{
  visibility: hidden;
}

.delete-goal-btn:hover .v-icon {
  visibility: visible;
}

.show {
  opacity: 1;
}
</style>
