import gql from 'graphql-tag'

export default gql`
  subscription OnToggleCardReaction($workflowId: String) {
    onToggleCardReaction(workflowId: $workflowId) {
      workflowId
      requirementId
      direction
      user {
        id
        firstname
        lastname
      }
    }
  }
`
