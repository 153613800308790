<template>
  <tr>
    <td
      class="px-2"
    >
      <template v-if="displayExpand">
        <v-icon
          :class="{ 'expand-icon': true, 'expanded': isExpanded }"
          @click="$emit('expand', !isExpanded)"
        >mdi-chevron-down</v-icon>
      </template>
    </td>

    <td
      :style="{ cursor: 'pointer'}"
      @click="$router.push({ path: '/workflow/' + item.projectId + '/' + item.id })"
    >
      {{ item.name }}
    </td>

    <td
      class="text-right"
      :style="{ cursor: 'pointer'}"
      @click="$router.push({ path: '/workflow/' + item.id })"
    >
      {{ item.eventsJson ? item.eventsJson.length : 0 }}
    </td>

    <td
      class="text-right"
      :style="{ cursor: 'pointer'}"
      @click="$router.push({ path: '/workflow/' + item.id })"
    >
      {{ item.teamMembers.length }}
    </td>

    <td
      class="text-right"
      :style="{ cursor: 'pointer'}"
      @click="$router.push({ path: '/workflow/' + item.id })"
    >
      {{ approvalRate }}%
    </td>

    <td
    >
      <v-chip
        small
        v-for="(lane, index) in lanes.slice(0, 2)"
        :key="lane.id"
        :class="{'ml-1': Boolean(index), 'px-1': true}"
      >
        {{ lane.name }}
      </v-chip>

      <v-tooltip location="top" v-if="extraLanes.length">
        <template v-slot:activator="{ on, props }">
          <v-chip
            v-bind="props"
            small
            v-on="{ ...on }"
            class="ml-1 px-1"
          >
            + {{ extraLanes.length }} lane{{ extraLanes.length > 1 ? 's' : ''}}
          </v-chip>
        </template>

        <template #default>
          <p v-for="lane in extraLanes" :key="lane.id" class="mb-0">
            {{ lane.name }}
          </p>
        </template>
      </v-tooltip>
    </td>

    <td
      class="text-center"
    >
      <v-icon v-if="userVote === 1" size="x-large">mdi-thumb-up</v-icon>
      <v-icon v-else-if="userVote === 2" size="x-large">mdi-thumb-down</v-icon>
    </td>

    <td
      class="text-center"
    >
      <v-menu>
        <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              size="small"
              class="mr-2"
              >
              mdi-file-multiple
            </v-icon>
        </template>
        <v-list density="compact">
          <v-list-item prepend-icon="mdi-content-copy">
            <v-list-item-title>
              Create a copy in project:
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(projectMembership, index) in projectMemberships"
            @click="callCloneWorkflow(projectMembership.project.id)"
            :key="index"
            prepend-icon="mdi-folder-outline"
          >
            <v-list-item-title>{{ projectMembership.project?.name }} {{ projectMembership.project?.id == $route.params.projectId ? '(this project)' : '' }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-icon
        size="small"
        class="mr-2"
        @click="$emit('edit')">
        mdi-pencil
      </v-icon>
      <v-icon
        size="small"
        @click="deleteWorkflow(item.id, $route.params.projectId)">
        mdi-delete
      </v-icon>
    </td>
  </tr>
</template>

<script>
import { deleteWorkflow, cloneWorkflow } from '@/api/workflowApi'
import { mapState } from 'vuex'

export default {
  props: {
    item: Object,
    isExpanded: Boolean,
    exclude: Array,
    headers: Array
  },

  data () {
    return {
      processingRequest: null
    }
  },

  methods: {
    async callCloneWorkflow (targetProjectId) {
      this.processingRequest = this.item.id
      await this.cloneWorkflow(targetProjectId, this.item.id)
      this.processingRequest = null
    },
    deleteWorkflow,
    cloneWorkflow
  },

  computed: {
    ...mapState({
      projectMemberships: state => state.projectMemberships.projectMemberships
    }),
    displayExpand () {
      const { children, id } = this.item

      if (!children.length || this.exclude.includes(id)) {
        return false
      }

      return true
    },

    approvalRate () {
      const { userStatusesWorkflow, teamMembers } = this.item
      const noUsers = teamMembers.length

      if (userStatusesWorkflow && noUsers > 0) {
        const approvedCount = userStatusesWorkflow.filter(item => item.vote === 1).length

        return Math.round(100 * approvedCount / noUsers)
      } else {
        return 0
      }
    },

    lanes () {
      if (!Array.isArray(this.item.lanes)) {
        return []
      }

      return this.item.lanes.slice(0, 2)
    },

    extraLanes () {
      if (!Array.isArray(this.item.lanes)) {
        return []
      }

      return this.item.lanes.slice(2)
    },

    userVote () {
      const { userStatusesWorkflow } = this.item

      if (userStatusesWorkflow.findIndex) {
        const status = userStatusesWorkflow.find(item => item.userId === this.$store.state.userId)

        if (status) {
          return status.vote
        }
      }

      return 0
    }
  },

  mounted () {
    this.$nextTick(() => {
      if (this.displayExpand && !this.isExpanded) {
        this.$emit('expand', true)
      }
    })
  }
}
</script>

<style scoped>
  .expand-icon {
    transform: rotate(-90deg);
  }

  .expand-icon.expanded {
    transform: rotate(0);
    transition: transform .3s ease-in-out;
  }

  tr > td:focus-within,
  td:focus {
    outline: none !important;
    z-index: unset;
  }
</style>
