<template>
  <v-dialog v-model="open" max-width="500px" :scrollable="true" @update:model-value="openChange" v-on:keydown.stop>
    <template v-slot:activator="{ props }">
      <v-list-item v-bind="props">
        <v-list-item-title>Export to Jira</v-list-item-title>
      </v-list-item>
    </template>
    <template #default>
      <v-card  flat="true">
        <v-toolbar density="compact" color="white">
          <v-toolbar-title v-if="jiraProject">Transfer Cards to Jira</v-toolbar-title>
          <v-toolbar-title v-else>Map your Qlerify project to a Jira project</v-toolbar-title>
          <v-btn icon @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <p class="mb-0" v-if="jiraProject">
            This project is already mapped to a Jira project. Login to your Jira Cloud workspace, select your Jira project and then select the Qlerify app from the left navigation menu to import the cards you created in Qlerify.
          </p>
          <p class="mb-0" v-else>
            If you haven't done it already, please install the Qlerify app from the Atlassian Marketplace. Then login to Qlerify from Jira and map your Qlerify project to your Jira project by following the instructions in Jira.
          </p>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['requirements'],

  data () {
    return {
      open: false,
      selected: [],
      exporting: false,
      jiraProject: false
    }
  },

  computed: {
    ...mapGetters({
      project: 'projectMemberships/activeProject'
    })
  },

  methods: {
    openChange (open) {
      if (!open) {
        this.onClose()
      }
    },

    onClose () {
      this.selected = []
      this.open = false
      this.exporting = false
    },

    computeTypeBgColor (color) {
      if (!color) {
        return '#eeeeee'
      }

      const { red, green, blue } = color

      return `rgb(${red}, ${green}, ${blue})`
    },

    computeTypeTextIsDark (color) {
      if (!color) {
        return false
      }

      const { red, green, blue } = color

      if ((red * 0.299 + green * 0.587 + blue * 0.114) > 186) {
        return false
      } else {
        return true
      }
    },

    toggleSelectAll (selectAll) {
      if (!selectAll) {
        this.selected = []
      } else {
        this.selected = this.filteredItems.map(({ id }) => id)
      }
    }
  },
  async created () {
    this.jiraProject = this.project?.jiraForgeData?.projectId
  }
}
</script>
