import { apolloClient } from '@/vue-apollo'
import ListTeamMembers from '@/graphql/queries/ListTeamMembers'
import CreateTeamMember from '@/graphql/mutations/CreateTeamMember'
import DeleteTeamMember from '@/graphql/mutations/DeleteTeamMember'
import ConvertInvitesToTeamMembers from '@/graphql/mutations/ConvertInvitesToTeamMembers'
import OnCreateTeamMember from '@/graphql/subscriptions/OnCreateTeamMember'
import OnUpdateTeamMemberLastLogin from '@/graphql/subscriptions/OnUpdateTeamMemberLastLogin'
import OnDeleteTeamMember from '@/graphql/subscriptions/OnDeleteTeamMember'
import client from '@/graphql/client'

export const teamMembers = {
  namespaced: true,
  state: {
    teamMembers: null
  },
  mutations: {
    setTeamMembers (state, payload) {
      state.teamMembers = payload
    },
    addTeamMember (state, payload) {
      const index = state.teamMembers.findIndex(teamMembers => teamMembers.id === payload.id)
      if (index === -1) {
        state.teamMembers.push(payload)
      }
    },
    updateTeamMemberLastLogin (state, payload) {
      const teamMember = state.teamMembers.find(teamMember => teamMember.id === payload.id)
      if (teamMember) {
        teamMember.lastLogin = payload.lastLogin
      }
    },
    deleteTeamMember (state, payload) {
      const index = state.teamMembers.findIndex((teamMember) => teamMember.id === payload.id)
      if (index >= 0) {
        state.teamMembers.splice(index, 1)
      }
    }
  },
  actions: {
    async createTeamMember ({ commit }, { projectId, email }) {
      try {
        const res = await apolloClient.mutate({
          mutation: CreateTeamMember,
          variables: {
            email,
            projectId
          }
        })
        commit('addTeamMember', res.data.createTeamMember.createTeamMemberFunction)
        return true
      } catch (e) {
        console.log(e)
        if (e.message.includes('already exists')) {
          alert('This team member is already added to the project.')
        } else {
          commit('snackbar/showMessage', { content: 'An error occurred while creating team member', timeout: 6000, color: 'red' }, { root: true })
        }
        return false
      }
    },

    async deleteTeamMember ({ commit }, { teamMemberId, projectId, userId, email }) {
      try {
        const res = await apolloClient.mutate({
          mutation: DeleteTeamMember,
          variables: {
            id: teamMemberId,
            projectId,
            userId,
            email
          }
        })
        commit('deleteTeamMember', res.data.deleteTeamMember)
        commit('snackbar/showMessage', { content: 'Deleted' }, { root: true })
      } catch (e) {
        console.log(e)
        commit('snackbar/showMessage', { content: 'An error occurred while deleting team member', timeout: 6000, color: 'red' }, { root: true })
      }
    },

    async convertInvitesToTeamMembers ({ commit }) {
      try {
        await apolloClient.mutate({
          mutation: ConvertInvitesToTeamMembers
        })
      } catch (e) {
        console.log(e)
        commit('snackbar/showMessage', { content: 'An error occurred while converting invitations', timeout: 6000, color: 'red' }, { root: true })
      }
    },

    async listTeamMembers ({ commit, rootState }, { projectId }) {
      const res = await client.query({
        operationName: 'listTeamMembers',
        query: ListTeamMembers,
        variables: {
          projectId
        }
      }, {
        failSilently: true
      })
      commit('setTeamMembers', res?.listTeamMembers)

      if (rootState.isUserLoggedIn === false || rootState.workflow.isViewOnlyEnabled === true) {
        return
      }

      apolloClient.subscribe({
        query: OnCreateTeamMember,
        variables: { projectId },
        fetchPolicy: 'no-cache'
      }).subscribe({
        next (res) {
          commit('addTeamMember', res.data.onCreateTeamMember.createTeamMemberFunction)
        },
        error () {
          // console.error('Subscription error:', error)
          console.log('Subscription not authorized')
        }
      })

      apolloClient.subscribe({
        query: OnUpdateTeamMemberLastLogin,
        variables: { projectId },
        fetchPolicy: 'no-cache'
      }).subscribe({
        next (res) {
          commit('updateTeamMemberLastLogin', res.data.onUpdateTeamMemberLastLogin)
        },
        error () {
          // console.error('Subscription error:', error)
          console.log('Subscription not authorized')
        }
      })

      apolloClient.subscribe({
        query: OnDeleteTeamMember,
        variables: { projectId },
        fetchPolicy: 'no-cache'
      }).subscribe({
        next (res) {
          commit('deleteTeamMember', res.data.onDeleteTeamMember)
        },
        error () {
          // console.error('Subscription error:', error)
          console.log('Subscription not authorized')
        }
      })
    }
  }
}
