<template>
  <div
    @mousedown="changeSelectedEvent(node.id)"
    class="text-box justify-center align-center"
  >
    <div
      ref="eventNameLabel"
      class="text-cell label-name align-start justify-center"
      style="text-align: left;"
      :contenteditable="!$store.state.isWorkflowDisabled && node.id == $route.params.eventId"
      @keydown.stop="checkCharacterLimit($event, characterLimit)"
      @keydown.stop.enter="$event.target.blur()"
      @keydown.stop.esc="restoreValue($event, conditionLabel || '[Label]')"
      @blur="$emit('label-blur', $event)"
      @focus="$emit('label-focus', $event)"
      @paste="onPaste($event, $refs.eventNameLabel, characterLimit)"
      v-text="conditionLabel"
      :data-test-label-name="getConnectionLabel(svgGraphData[node.id], node.parents[0]) || svgGraphData[node.id]?.conditionLabel || '[Label]'"
      @input="() => updateFontSizeDynamically(this.$refs.eventNameLabel)"
    ></div>
  </div>
</template>

<script>
import { diagramHelpers } from '@/mixins/diagramHelpers.js'
import { updateFontSizeDynamically } from '@/utils/update-font'
import { mapGetters } from 'vuex'

export default {
  mixins: [diagramHelpers],
  props: {
    node: Object,
    conditionLabel: String,
    characterLimit: {
      type: Number,
      default: 30
    }
  },

  computed: {
    ...mapGetters({
      svgGraphData: 'svgGraphData/svgGraphData'
    })
  },

  methods: {
    updateFontSizeDynamically,
    getConnectionLabel (node, parentId) {
      const index = node?.conditionLabels?.findIndex(label => label.parentId === parentId)
      if (index >= 0) {
        return node.conditionLabels[index].name
      }
      return null
    }
  },

  mounted () {
    if (this.$refs.eventNameLabel) {
      updateFontSizeDynamically(this.$refs.eventNameLabel)
    }
    if (this.selectNewConnection) {
      // yuriy
      // for some reason `this.$nextTick` doesn't fix the problem
      // with async state change of the component
      // `setTimeout` does a trick and allows to select the text
      // when element becomes selected
      //
      // staffan:
      // disabled because of cypress tests
      // window.setTimeout(() => {
      //   const node = this.$el.querySelector('.label-name')

      //   const selection = window.getSelection()
      //   const range = document.createRange()
      //   range.selectNodeContents(node)
      //   selection.removeAllRanges()
      //   selection.addRange(range)
      // }, 500)
    }
  }
}
</script>
<style scoped>
.label-name {
  flex-direction: column-reverse;
  padding: 0 0;
  max-height: 30px !important;
  width: 120px !important;
  word-break: break-word;
}

.label-name:focus-visible {
  outline: none;
}
</style>
