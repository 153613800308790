import { apolloClient } from '@/vue-apollo'
import CreateRequirementType from '@/graphql/mutations/CreateRequirementType'
import UpdateRequirementType from '@/graphql/mutations/UpdateRequirementType'
import DeleteRequirementType from '@/graphql/mutations/DeleteRequirementType'
import RequirementTypes from '@/graphql/queries/RequirementTypes'
import OnCreateRequirementType from '@/graphql/subscriptions/OnCreateRequirementType'
import OnUpdateRequirementType from '@/graphql/subscriptions/OnUpdateRequirementType'
import OnDeleteRequirementType from '@/graphql/subscriptions/OnDeleteRequirementType'

export const requirementTypes = {
  namespaced: true,
  state: {
    requirementTypes: null
  },
  mutations: {
    setRequirementTypes (state, payload) {
      state.requirementTypes = payload
    },
    addRequirementType (state, payload) {
      const index = state.requirementTypes.findIndex((requirementTypes) => requirementTypes.id === payload.id)
      if (index === -1) {
        state.requirementTypes.push(payload)
      }
    },
    updateRequirementType (state, payload) {
      // This update method is used to preserve order of appearance for the requirement types, because sometimes,
      // server is returning a payload with requirement types in a different order, making tests flaky
      payload.requirementTypes.forEach((newType) => {
        const index = state.requirementTypes.findIndex((type) => type.id === newType.id)
        if (index !== -1) {
          state.requirementTypes[index] = newType
        }
      })
    },
    deleteRequirementType (state, payload) {
      const index = state.requirementTypes.findIndex((requirementTypes) => requirementTypes.id === payload.id)
      if (index >= 0) {
        state.requirementTypes.splice(index, 1)
      }
    }
  },
  actions: {
    async createRequirementType ({ commit, dispatch, getters }, { workflowId, input }) {
      try {
        const res = await apolloClient.mutate({
          mutation: CreateRequirementType,
          variables: {
            workflowId,
            input
          },
          fetchPolicy: 'no-cache'
        })
        commit('addRequirementType', res.data.createRequirementType)
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
        return res.data.createRequirementType
      } catch (error) {
        commit('snackbar/showMessage', { content: 'Error saving, try again!', timeout: 6000, color: 'red', centered: true }, { root: true })
      }
    },

    async updateRequirementType ({ commit, dispatch, getters }, { id, workflowId, input }) {
      try {
        const res = await apolloClient.mutate({
          mutation: UpdateRequirementType,
          variables: {
            workflowId,
            id,
            input
          },
          fetchPolicy: 'no-cache'
        })
        commit('updateRequirementType', res.data.updateRequirementType)
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
      } catch (error) {
        commit('snackbar/showMessage', { content: 'Error saving, try again!', timeout: 6000, color: 'red', centered: true }, { root: true })
      }
    },

    async deleteRequirementType ({ commit, dispatch, getters }, { id, workflowId }) {
      try {
        const res = await apolloClient.mutate({
          mutation: DeleteRequirementType,
          variables: {
            workflowId,
            id
          },
          fetchPolicy: 'no-cache'
        })
        commit('deleteRequirementType', res.data.deleteRequirementType)
        commit('snackbar/showMessage', { content: 'Deleted' }, { root: true })
      } catch (error) {
        commit('snackbar/showMessage', { content: 'Error saving, try again!', timeout: 6000, color: 'red', centered: true }, { root: true })
      }
    },

    async listRequirementTypes ({ commit, rootState }, { workflowId }) {
      const res = await apolloClient.query({
        query: RequirementTypes,
        variables: { workflowId },
        fetchPolicy: 'no-cache'
      })
      commit('setRequirementTypes', res.data.requirementTypes)

      if (rootState.isUserLoggedIn === false || rootState.workflow.isViewOnlyEnabled === true) {
        return
      }

      apolloClient.subscribe({
        query: OnCreateRequirementType,
        variables: { workflowId },
        fetchPolicy: 'no-cache'
      }).subscribe({
        next (res) {
          commit('addRequirementType', res.data.onCreateRequirementType)
        },
        error (error) {
          console.error('Subscription error:', error)
        }
      })

      apolloClient.subscribe({
        query: OnUpdateRequirementType,
        variables: { workflowId },
        fetchPolicy: 'no-cache'
      }).subscribe({
        next (res) {
          commit('updateRequirementType', res.data.onUpdateRequirementType)
        },
        error (error) {
          console.error('Subscription error:', error)
        }
      })

      apolloClient.subscribe({
        query: OnDeleteRequirementType,
        variables: { workflowId },
        fetchPolicy: 'no-cache'
      }).subscribe({
        next (res) {
          commit('deleteRequirementType', res.data.onDeleteRequirementType)
        },
        error (error) {
          console.error('Subscription error:', error)
        }
      })
    }
  }
}
