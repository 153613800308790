import client from '@/graphql/client'
import GetJiraIssues2 from '@/graphql/queries/GetJiraIssues2'

export const jira = {
  namespaced: true,
  state: {
    jiraIssues: null
  },
  mutations: {
    setJiraIssues (state, payload) {
      state.jiraIssues = payload
    }
  },
  actions: {
    async getJiraIssues ({ commit }, { projectId, workflowId, apiToken }) {
      const res = await client.query({
        operationName: 'GetJiraIssues2',
        query: GetJiraIssues2,
        variables: {
          projectId,
          workflowId,
          apiToken
        }
      }, {
        failSilently: true
      })
      commit('setJiraIssues', res?.getJiraIssues2)
      return res
    }
  }
}
