<template>
  <v-dialog
    v-model="open"
  >
    <template v-slot:activator="{ on }">
      <slot :openDialog="openDialog" v-on="{ ...on }"></slot>
    </template>

    <v-card flat class="help-card mb-10">
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title><v-icon> mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>How to use Aggregates</b></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon color="grey" @click.native="open = false">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3" style="height: 90vh; overflow: scroll;">
        <h3><v-icon>mdi-alpha-a-box</v-icon>&nbsp;Aggregates and Systems Overview</h3>
        Here, under the 'Aggregates' tab, you can view your workflow from an aggregate/system
        perspective. Commands are grouped by aggregate/system. You can also see the
        command parameters and the entities (with data fields) within each aggregate/system.
        You can assign one or more aggregates to a Bounded Context and generate the code
        for a Service API. This approach is effective when you want to gradually break down a
        monolith and replace it with well-designed Service APIs. It is also useful when designing
        new APIs.
        <br /><br />
        <h3><v-icon icon="mdi-cog"></v-icon>&nbsp;Configuration</h3>
        First, make sure to have a card type set as ‘COMMAND’
        and another set as ‘AGGR.’ (Aggregate) in the workflow settings
        (the <v-icon size="x-small">mdi-cog</v-icon> icon top right).
        <br />
        <img src="../../public/help-aggregates-2.png" alt="help-aggregates-2" style="max-width: 100%;"/>
        <br /><br />
        <h3>Add / update cards on Events</h3>
        Now you can specify commands and aggregates/systems for the events in the diagram.
        <br />
        <ul class="ml-5">
          <li>Put the names of commands in cards of the type set as ‘COMMAND’</li>
          <li>Put the names of aggregates/systems in cards of the type set as ‘AGGR.’</li>
          <li>Note: Only add ONE command and ONE aggregate/system per Event. Additional cards will be ignored.</li>
          <li>
            <b>Hint:</b> When modeling, consider each event as one command executed on a specific
            system. For example, 'Create Order'. If 'Create Order' can be accomplished in two steps,
            such as creating a new customer just before the new order, consider breaking up the event
            into two (or more) events.
          </li>
        </ul>
        <br />
        <img src="../../public/help-aggregates-4.png" alt="help-aggregates-4" style="max-width: 80%;"/>
        <br /><br />
        <h3><v-icon>mdi-alpha-a-box</v-icon>&nbsp;View Aggregates/Systems</h3>
        Now you can view Commands and Events grouped by Aggregate/System.
        The names and descriptions can be updated directly in the diagram.
        In the example below we see a “Warehouse Management
        Service” that has 3 methods:
        <ul class="ml-5">
          <li>Print picking list</li>
          <li>Confirm picked products</li>
          <li>Confirm packaging checklist</li>
        </ul>
        <br />
        Commands and events belong together if they are positioned
        at the same height. The first row can be read as:
        <i>“The actor <b>Inventory Manager</b> executes the command <b>Print a
        picking list</b> on the aggregate/system <b>Warehouse Management Service</b>
        and by doing so triggers the event: <b>Available
        inventory for product reserved</b>”</i>
        <br /><br />
        <img src="../../public/help-aggregates-1.png" alt="help-aggregates-1" style="max-width: 100%;"/>
        <h3>Data Fields</h3>
        If you have captured Event Stories and Dimensions/Entities under the tab Data Models you
        can also view the data fields and associated entities by clicking the checkboxes
        'Show data fields' and 'Show entities'.
        <br /><br />
        <img class="mb-24" src="../../public/help-aggregates-3.png" alt="help-aggregates-3" style="max-width: 100%;"/>
        <br /><br />
        <h3>Bounded Contexts</h3>
        The next step is to assign one or several aggregates to a bounded context. This is done by clicking
        a button on the aggregate or entity. Related entities can belong to the same bounded
        context or to another bounded context. If a related entity belongs to another bounded
        context, a label with the text 'Integration with other bounded contexts' indicates that we have
        an integration point.
        <br /><br />
        <img class="mb-24" src="../../public/help-aggregates-6.png" alt="help-aggregates-6" style="max-width: 100%;"/>
        <br /><br />
        <h3>Service API / Code Generation</h3>
        Code can be generated for a Bounded Context, such as a full Service API or an
        OpenAPI/Swagger Specification. You can select from various programming languages,
        databases, and testing frameworks. Additionally, there is an option to generate
        only the OpenAPI Specification. If you wish to use a different technology stack,
        you can manually update the YAML specification used for AI code generation by
        clicking on 'Customize specification.' For example, you could choose to generate code in
        TypeScript instead of JavaScript. After finalizing the specification, click the
        'Generate API' button. Wait for the generation to complete, then download the source
        code as a ZIP file.
        <br /><br />
        <img class="mb-24" src="../../public/help-aggregates-7.png" alt="help-aggregates-7" style="max-width: 100%;"/>
      </v-card-text><br /><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  },
  methods: {
    openDialog () {
      this.open = true
    }
  }
}
</script>

<style>
.v-dialog .help-card {
  position: absolute;
  top: 0;
  right: 0;
  width: 75%;
  max-width: 1000px;
  overflow-y: scroll;
}
</style>
