<template>
  <v-row class="my-6 text-divider-container">
    <v-divider></v-divider>
    <span class="text-divider__text">{{text}}</span>
    <v-divider></v-divider>
  </v-row>
</template>
<script>
export default {
  name: 'text-divider',
  props: {
    text: String
  }
}
</script>
<style scoped>

a {
  color: black;
}

.text-divider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  > hr {
    flex: 1 1 0px
  }
}

.text-divider__text {
  margin: 0 10px;
  font-size: 12px;
  line-height: 16px;
  color: #979797;
}
</style>
