<template>
  <v-menu
    v-model="menuCardType[card.id]"
    :disabled="$store.state.isWorkflowDisabled"
    style="z-index: 9999;"
  >
    <template v-slot:activator="{ props }">
      <i
        v-if="small"
        class="pa-0 mx-1 rounded add-card-of-type"
        @click="!$store.state.isWorkflowDisabled && openSelector(card)"
        :style="{ background: getCardBackgroundColour(card.cardType) }"
        v-bind="props"
        >
      </i>
      <v-btn
        v-else
        :tabindex="tabIndex"
        v-bind="props"
        @keydown="!$store.state.isWorkflowDisabled && keydown($event, props.onKeydown)"
        class="status-button card-type"
        :style="{
          background: getCardBackgroundColour(card.cardType),
          color: getCardTextColour(card.cardType)
        }"
      >
        <div style="text-transform: none; text-align: left" class="px-2 btn">
          <template v-if="card.cardType && cardTypeExists(card.cardType)">
            {{ card.cardType.title }}
          </template>
          <template v-else>
            <v-icon size="small">mdi-dots-horizontal  </v-icon>
          </template>
        </div>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-subheader>Select type:</v-list-subheader>
        <v-list-item
          v-for="cardType in filteredRequirementTypes"
          :key="cardType.id"
          @click="changeCardType(card, cardType.id)"
          prepend-icon="card-type-icon"
        >
          <template v-slot:prepend>
            <i
              class="pa-0 mr-2 rounded add-card-of-type"
              :style="{ background: getCardBackgroundColour(cardType) }"
              >
            </i>
          </template>
          <v-list-item-action>
            {{ cardType.title }}
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="changeCardType(card, null)">
          <v-list-item-action>
            <v-icon size="small">mdi-dots-horizontal</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-btn variant="text" size="small" class="ml-auto mr-auto" @click="openTypeSettings">
          <v-icon size="small" class="mr-2">mdi-pencil</v-icon> Edit card types
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import requirementTypesApi from '@/api/requirementTypesApi'
import workflowApi from '@/api/workflowApi'
import { EventBus } from '@/event-bus/event-bus.js'
import { mapState } from 'vuex'

export default {
  props: {
    card: Object,
    tabIndex: Number,
    small: Boolean
  },
  data () {
    return {
      menuCardType: {} // keep track of if a certain menu is open
    }
  },
  computed: {
    ...mapState({
      requirementTypes: state => state.requirementTypes.requirementTypes
    }),
    filteredRequirementTypes () {
      return this.requirementTypes.filter(requirementType => !requirementType.triggerType && !requirementType.aggregateType)
    }
  },
  methods: {
    keydown (event, fallback) {
      if (!this.menuCardType[this.card.id]) {
        this.$emit('keydown-card-type', event)
      } else {
        fallback(event)
      }
    },
    getCardBackgroundColour (cardType) {
      return requirementTypesApi.getBackgroundColour(cardType)
    },
    getCardTextColour (cardType) {
      return requirementTypesApi.getTextColour(cardType)
    },
    cardTypeExists (cardType) {
      const res = requirementTypesApi.cardTypeExists(cardType)
      return res
    },
    async changeCardType (item, cardTypeId) {
      await workflowApi.updateRequirementsJsonMutation(this.$route.params.id, [{
        requirementId: item.id,
        cardTypeId,
        updateNote: {
          action: 'changed card type of',
          target: item.description
        }
      }])
    },
    openSelector () {
      this.menuCardType[this.card.id] = true
    },
    openTypeSettings () {
      EventBus.$emit('open-types-settings', 'CARDS')
    }
  }
}
</script>

<style scoped>
.v-btn.status-button {
  padding: 0 0;
  margin-left: 0;
  margin-right: 0;
  min-width: 40px;
}

.add-card-of-type {
  width: 16px;
  height: 16px;
  cursor: move;
}
</style>
