<template>
  <v-card class="dashboard-container pa-6 mt-3 mx-0">
    <template v-if="insideProject">
      <h3 class="text-h5 font-weight-bold pb-4">Quickly create a workflow with AI
        <dashboard-help-dialog-ai #default="{ props }">
          <v-btn variant="text" icon size="small" v-bind="props" class="mr-2">
            <v-icon> mdi-help-circle-outline</v-icon>
          </v-btn>
        </dashboard-help-dialog-ai>
      </h3>
      <p><b>Create a workflow containing ...</b></p>
    </template>
    <template v-else>
      <v-badge
            color="green"
            content="New"
            offset-y="-6"
            offset-x="-6"
          >
        <h3 class="text-h5 font-weight-bold pb-4" style="color: #229AD6;">Or create a workflow with AI
        </h3>
      </v-badge>
      <dashboard-help-dialog-ai #default="{ props }">
        <v-btn variant="text" icon size="small" v-bind="props">
          <v-icon> mdi-help-circle-outline</v-icon>
        </v-btn>
      </dashboard-help-dialog-ai>

      <p><b>Create a workflow containing ...</b></p>
    </template>

    <v-container fluid>
      <v-row>
        <template
          v-for="(example, index) in this.examples"
          :key="index"
        >
          <v-chip
            class="mt-2 mb-2 mr-2"
            @click="createAWorkflowContaining = example; selectedIndex = index; errorMessages = ''"
            :color="index == selectedIndex ? 'secondary' : ''">
            <b style="font-size: 1rem">{{ example }}</b>
          </v-chip>
        </template>
      </v-row>
      <v-row>
        <v-col
          cols="9"
          class="d-flex align-end justify-end pb-0 pl-0"
        >
            <v-textarea
              id="ai-input"
              v-model="createAWorkflowContaining"
              label="Create a workflow containing ..."
              variant="outlined"
              bg-color="grey-lighten-4"
              :auto-grow="true"
              rows="3"
              :rules="[rules.required]"
              :error-messages="errorMessages"
              @keydown="errorMessages=''"
              :persistent-hint="true"
              hint="Continue the sentence 'Create a workflow containing ...'">
            </v-textarea>
        </v-col>
        <v-col
          cols="3"
          :class="{
            'd-flex': true,
            'align-end': true,
            'justify-end': true,
            'pb-8': true,
            'ml-auto': examples.length % 2 === 0
          }"
        >
          <v-btn
            color="primary"
            @click="openDisclaimerDialog()"
            :loading="loading"
          >
            CREATE WITH AI!
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <disclaimer-dialog
      ref="disclaimerDialog"
      @conditions-accepted="onConditionsAccepted()"
    />
  </v-card>
</template>

<script>
import { chatGPT } from '@/api/chatGPT/index'
import workflowApi from '@/api/workflowApi'
import { EventBus } from '@/event-bus/event-bus.js'
import projectApi from '@/api/projectApi'
import DisclaimerDialog from '@/components/DisclaimerDialog.vue'
import DashboardHelpDialogAi from './DashboardHelpDialogAi.vue'
import { mapState } from 'vuex'
import store from '@/store'

export default {
  components: {
    DisclaimerDialog,
    DashboardHelpDialogAi
  },

  props: {
    insideProject: Boolean
  },

  data () {
    return {
      loading: false,
      createAWorkflowContaining: '',
      selectedIndex: null,
      rules: {
        required: value => !!value || 'Required at least 10 characters.'
      },
      valid: true,
      errorMessages: ''
    }
  },

  computed: {
    ...mapState({
      organisations: state => state.organisations.organisations
    }),
    examples () {
      return chatGPT.getExamples()
    }
  },

  methods: {
    truncate (str) {
      return str.length > 40 ? str.substring(0, 37) + '...' : str
    },
    openDisclaimerDialog () {
      if (this.createAWorkflowContaining.length < 10) {
        this.errorMessages = 'Requires at least 10 characters'
        return
      }
      this.$refs.disclaimerDialog.openDialog()
    },
    onConditionsAccepted () {
      this.createWithAi()
    },
    async createWithAi () {
      this.loading = true
      let projectId
      try {
        if (this.$route.params.projectId) {
          projectId = this.$route.params.projectId
        } else {
          let organisation
          if (Array.isArray(this.organisations)) {
            organisation = this.organisations[0]
          } else {
            this.error = 'Cannot find user organisations.'
            return
          }
          projectId = await store.dispatch('project/createProject', {
            name: await projectApi.getNextProjectName('Generated Project'),
            organisationId: organisation.id
          })
        }
        // used by the disclaimer dialog
        localStorage.setItem(`showAiInfo-${projectId}`, true)
        const workflowName = this.capitalizeFirstLetter(this.truncate(this.createAWorkflowContaining))
        const workflowId = await workflowApi.createWorkflow({
          projectId,
          name: workflowName
        }, true)
        await workflowApi.updateWorkflowNoHistoryMutation(
          projectId,
          {
            id: workflowId,
            expectedVersion: 1,
            aiDescription: this.createAWorkflowContaining
          }
        )
        const path = '/workflow/' + projectId + '/' + workflowId
        await this.$router.push({ path }).catch(() => { })
        this.$nextTick(() => {
          this.$loadingState.start()
          setTimeout(() => {
            this.$loadingState.stop()
            EventBus.$emit('generate-diagram', workflowId)
          }, 1500)
        })
        this.loading = false
      } catch (e) {
        this.loading = false
        console.log(e)
      }
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  }
}
</script>

<style scoped>
.dashboard-container {
  max-width: 800px;
  margin-left: 12px;
  margin-top: 12px;
}
</style>
