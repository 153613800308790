<template>
  <v-data-table
    no-data-text="0 workflows (click on 'Create New Workflow' to add one)"
    hide-default-footer
    disable-pagination
    :hide-default-header="hideDefaultHeader"
    :headers="headers"
    :items="items"
    :search="search"
    :must-sort="mustSort"
    v-model:expanded="expanded"
  >
    <template v-slot:item="{ item, headers }">
      <workflows-table-row
        :item="item"
        :is-expanded="isExpanded(item)"
        :processing-request="processingRequest"
        :exclude="exclude"
        :headers="headers"
        @expand="toggleExpand(item)"
        @edit="$emit('edit', item)"
      ></workflows-table-row>
    </template>

    <template v-slot:expanded-row="{ item, columns }">
      <tr>
        <td :colspan="columns.length" class="pr-0">
          <workflows-table
            :hide-default-header="true"
            :headers="headers"
            :items="mapWorkflows(item.children)"
            :search="search"
            :exclude="excludeIds(item)"
            :processing-request="processingRequest"
            :workflows-by-id="workflowsById"
            @edit="$emit('edit', $event)"
          ></workflows-table>
        </td>
      </tr>
    </template>
    <template v-slot:bottom></template>
  </v-data-table>
</template>

<script>
import WorkflowsTableRow from '@/components/WorkflowsTableRow'

export default {
  name: 'workflows-table',

  components: {
    WorkflowsTableRow
  },

  props: {
    hideDefaultHeader: Boolean,
    headers: Array,
    items: Array,
    search: String,
    mustSort: Boolean,
    exclude: Array,
    processingRequest: String,
    workflowsById: Object
  },

  data () {
    return {
      expanded: []
    }
  },

  methods: {
    toggleExpand (item) {
      const index = this.expanded.indexOf(item.id)
      if (index > -1) {
        this.expanded.splice(index, 1)
      } else {
        this.expanded.push(item.id)
      }
    },

    isExpanded (item) {
      return this.expanded.includes(item.id)
    },

    mapWorkflows (ids) {
      return ids.map(id => this.workflowsById[id]).filter(item => item !== undefined)
    },

    excludeIds (item) {
      return this.exclude.concat(item.id)
    }
  }
}
</script>

<style scoped>
  tr > td:focus-within,
  td:focus {
    outline: none !important;
    z-index: unset;
  }
</style>
