import gql from 'graphql-tag'

export default gql`
  subscription OnCreateTeamMember($projectId: String) {
    onCreateTeamMember (projectId: $projectId) {
      projectId
      createTeamMemberFunction {
        id
        userId
        email
        projectId
        lastLogin
        user {
          id
          firstname
          lastname
          email
        }
        project {
          id
          name
          organisationId
          organisation {
            id
            name
            userId
            user {
              id
              firstname
              lastname
            }
          }
        }
      }
    }
  }
`
