const loadingStatePlugin = {
  install: (app, { store }) => {
    if (!store) {
      throw new Error('Please provide vuex store.')
    }

    app.config.globalProperties.$loadingState = {
      start: function (message = null) {
        store.commit('loading/startLoading', message)
      },
      stop: function () {
        store.commit('loading/stopLoading')
      }
    }
  }
}
export default loadingStatePlugin
