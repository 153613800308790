<template>
  <v-dialog v-model="dialogProject" max-width="500px">
    <template v-slot:activator="{ props }">
      <slot name="activator" :props="props">
        <v-btn
          v-bind="props"
          :color="buttonTheme === 'dark' ? 'primary' : 'white'"
          class="mb-2"
          v-bind:class="{ 'primary--text': buttonTheme === 'light' }"
        >
          {{ buttonText }}
        </v-btn>
      </slot>
    </template>

    <v-card>
      <v-container class="pr-0">
        <v-row class="v-container--fluid justify-space-between align-top">
          <v-card-title>
            <span v-if="editProject && editProject.id" class="text-h5">Edit Project</span>
            <span v-else class="text-h5">New Organisation</span>
          </v-card-title>

          <v-btn variant="text" icon color="grey" @click="closeProject">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-container>
      <v-card-text>
        <v-container grid-list-md>
          <v-form ref="formProject" v-model="validProject">
            <v-select
              v-model="editProject.organisationId"
              :rules="[rules.required]"
              item-value="id"
              item-title="name"
              :items="organisations"
              label="Select organisation"
              variant="underlined"
              :no-data-text="`No organisations${createOrganisationLabel ? ' (' + createOrganisationLabel + ')' : ''}`"
            >
            </v-select>
            <v-text-field
              ref="projectNameInput"
              class="mt-2"
              v-model="editProject.name"
              :rules="[rules.required, rules.counter]"
              label="Project name"
              variant="underlined"
              v-on:keyup.enter="saveProject"
              :autofocus="true"
            >
            </v-text-field>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="text" @click="closeProject">Cancel</v-btn>
        <v-btn color="primary" variant="elevated" @click="saveProject">{{
          this.editProject && this.editProject.id ? "Update" : "Add"
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import projectApi from '@/api/projectApi'
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    buttonText: {
      type: String,
      default: ''
    },
    buttonTheme: {
      type: String,
      default: ''
    },
    setProject: {
      type: Boolean,
      default: true
    },
    createOrganisationLabel: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dialogProject: false,
    validProject: true,
    rules: {
      required: value => !!value || 'Required.',
      counter: value => (value && value.length <= 30) || 'Max 30 characters'
    },
    editProject: {
      name: ''
    }
  }),
  methods: {
    async saveProject () {
      const result = await this.$refs.formProject.validate()
      if (result.valid) {
        if (this.editProject.id) {
          // update project
          const index = this.projects.findIndex(ws => (ws.name === this.editProject.name) && (ws.id !== this.editProject.id))
          if (index >= 0) {
            alert('A project with this name already exists.')
          } else {
            await projectApi.updateProject(this.editProject)
            this.dialogProject = false
          }
        } else {
          // create new project
          const index = this.projects.findIndex(ws => ws.name === this.editProject.name)
          if (index >= 0) {
            alert('A project with this name already exists.')
          } else {
            await projectApi.createProject(this.editProject, this.setProject)
            this.dialogProject = false
          }
        }
      }
    },
    closeProject () {
      this.dialogProject = false
    },
    openProjectDialog () {
      this.dialogProject = true
    },
    setItem (item) {
      this.editProject = item
    },
    getLastUpdatedOragnisationId () {
      let res = null
      if (this.organisations?.length > 0) {
        res = JSON.parse(JSON.stringify(this.organisations))
        res.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : ((b.updatedAt < a.updatedAt) ? -1 : 0))
      }
      return res?.[0].id
    },
    selectAllTextInInput () {
      const inputElement = this.$refs.projectNameInput.$el.querySelector('input')
      inputElement.focus()
      inputElement.select()
    }
  },
  watch: {
    dialogProject (val) {
      if (!val) {
        this.editProject = {
          name: ''
        }
      } else {
        if (!this.editProject || !this.editProject.id) {
          if (this.$refs.formProject) {
            this.$refs.formProject.reset()
          }
          this.editProject = {
            organisationId: this.getLastUpdatedOragnisationId(),
            name: this.projects?.length > 0 ? '' : 'My first project'
          }
          this.$nextTick(() => {
            this.selectAllTextInInput()
          })
        }
      }
    }
  },
  computed: {
    ...mapState({
      organisations: state => state.organisations.organisations || [],
      projectMemberships: state => state.projectMemberships.projectMemberships,
      user: state => state.user.user
    }),
    ...mapGetters({
      projects: 'projectMemberships/myProjects'
    }),
    organisationsSorted () {
      let res = this.organisations
      res = res?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      return res
    }
  }
}
</script>
