<template>
  <div
    class="create-shape-actions-container"
    :data-test-actions-container="selectedEvent.name"
  >
    <v-tooltip location="right" open-delay="1000">
      <template v-slot:activator="{ props }">
       <button
         v-bind="props"
         class="captures-description"
         @click="$emit('add-shape', { type: 'bpmn:Task' })"
       >
         <v-icon size="small" color="white">custom:TaskIcon</v-icon>
       </button>
      </template>

      <template #default>
        <div>
          <p class="text-body-2 mb-1">Add event</p>
        </div>
      </template>
    </v-tooltip>

    <v-tooltip location="right" open-delay="1000">
      <template v-slot:activator="{ props }">
       <button
         v-bind="props"
         class="captures-description"
         @click="$emit('add-shape', { type: 'bpmn:ExclusiveGateway' })"
       >
         <v-icon size="small" color="white">custom:DecisionIcon</v-icon>
       </button>
      </template>

      <template #default>
        <div>
          <p class="text-body-2 mb-1">Add decision</p>
        </div>
      </template>
    </v-tooltip>

    <v-tooltip location="right" open-delay="1000">
      <template v-slot:activator="{ props }">
        <button
          :disabled="addConnectionDisabled()"
          @click="eventBusEmitEnableTargets()"
        >
          <v-icon v-bind="props" size="small" color="white">custom:ConnectionIcon</v-icon>
        </button>
      </template>

      <template #default>
        <div>
          <p v-if="addConnectionDisabled()" class="text-body-2 mb-1">
            Disabled (this event already has a connection)
          </p>
          <p v-else class="text-body-2 mb-1">Add connection</p>
        </div>
      </template>
    </v-tooltip>

    <v-tooltip location="right" open-delay="1000">
      <template v-slot:activator="{ props: tooltipActivator }">
        <bpmn-diagram-actions-shape-top-transform
          :selectedEvent="selectedEvent"
          :base-data="baseData"
          :svgGraph="svgGraph"
          :svgGroups="svgGroups"
          :svgLanes="svgLanes"
          @add-shape="$emit('add-shape', $event)"
          class="text-center"
        >
          <template v-slot:buttons="props">
            <button v-bind="tooltipActivator" @click="props.openDialog()">
              <v-icon style="width: 22px; height: 20px" size="small" color="white"
                >custom:SubworkflowIcon</v-icon
              >
            </button>
          </template>
        </bpmn-diagram-actions-shape-top-transform>
      </template>

      <template #default>
        <div>
          <p class="text-body-2 mb-1">Add subprocess</p>
        </div>
      </template>
    </v-tooltip>
  </div>
</template>

<script>
import BpmnDiagramActionsShapeTopTransform from '@/components/BpmnDiagramActionsShapeTopTransform'
import { EventBus } from '@/event-bus/event-bus.js'

export default {
  components: {
    BpmnDiagramActionsShapeTopTransform
  },
  props: {
    selectedEvent: Object,
    baseData: Object,
    svgGraph: Object,
    svgGroups: Object,
    svgLanes: Object
  },
  data () {
    return {
      subworkflowDialog: false
    }
  },
  methods: {
    addConnectionDisabled () {
      const res = (this.selectedEvent.childIds?.length > 0) && (this.selectedEvent.type !== 'bpmn:ExclusiveGateway')
      return res
    },
    eventBusEmitEnableTargets () {
      EventBus.$emit('enable-connection-targets', true)
    }
  }
}
</script>

<style scoped>
.create-shape-actions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px 0;
  background: #343434;
  border-radius: 5px;
}

.create-shape-actions-container > * {
  width: 100%;
}

.container-title {
  font-size: 9px;
  line-height: 13px;
  letter-spacing: 0.5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  user-select: none;
}

button {
  line-height: 20px;
}

button:not(:disabled):hover,
button:focus {
  background: rgba(255, 255, 255, 0.2);
}

.v-tooltip__content {
  background: white;
  color: var(--color-primary);
  border-radius: 10px;
}

.v-tooltip__content code {
  background: var(--color-primary);
  color: white;
}
</style>
