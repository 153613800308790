<template>
  <v-snackbar v-model="show" :color="color || 'white'" :location="centered ? 'center center' : 'bottom right'" :timeout="timeout">
    <v-progress-circular
      v-if="showProgress"
      indeterminate
      color="white"
      size="16"
      width="2">
    </v-progress-circular>
    {{ message }}
    <template v-slot:actions>
        <v-btn
          variant="text"
          @click="show = false"
          icon="mdi-close"
        />
      </template>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      message: '',
      color: '',
      timeout: 2000,
      showProgress: false,
      centered: false
    }
  },

  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'snackbar/showMessage') {
        this.message = state.snackbar.content
        this.color = state.snackbar.color
        this.centered = state.snackbar.centered
        this.timeout = state.snackbar.timeout || 2000
        this.showProgress = state.snackbar.showProgress
        this.show = true
      }
    })
  },
  methods: {
    closeSnackbar () {
      this.show = false
    }
  }
}
</script>
