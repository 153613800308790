<template>
  <v-dialog max-width="500px" v-model="dialogVisible">
    <template v-slot:activator="{ props }">
      <slot :props="props"></slot>
    </template>
    <v-card>
      <v-toolbar color="white">
        <v-toolbar-title>AI prompt for generating events</v-toolbar-title>
        <v-btn variant="text" icon color="grey" @click="closeDialog()">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <div v-for="(prompt, promptIndex) in prompts" v-bind:key="prompt.type" class="my-2">
          <div class="d-flex">
            <b style="padding: 10px 0;">{{prompt.type}}</b>
            <v-spacer/>
            <v-btn v-if="!prompt.error" size="small" variant="text" icon="mdi-content-copy" @click="copyPrompt(promptIndex)">
            </v-btn>
          </div>
          <p v-if="prompt.error" class="code-block bg-error">
            {{ prompt.error }}
          </p>
          <p v-else class="code-block">
            {{ prompt.content }}
          </p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store'
import { promptHelpers } from '@/api/chatGPT/promptHelpers'
import { mapState, mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialogVisible: false
    }
  },
  computed: {
    ...mapState({
      workflow: state => state.workflow.workflow,
      requirementTypes: state => state.requirementTypes.requirementTypes
    }),
    ...mapGetters({
      svgLanesData: 'svgGraphData/svgLanesData',
      svgGraphData: 'svgGraphData/svgGraphData'
    }),
    systemPrompt () {
      return promptHelpers.actor(true)
    },
    userPrompt () {
      return promptHelpers.requestNewDiagram(this.workflow, this.requirementTypes, this.workflow.aiDescription, this.workflow.aiVerbPastTense)
    },
    userPromptError () {
      if (!this.workflow.aiDescription) {
        return 'Workflow description is required'
      }
      return null
    },
    assistantPrompt () {
      let nodeList = []
      {
        let longestPath = 0
        const leafNodes = Object.values(this.svgGraphData).filter(node => !node.childIds?.length)
        leafNodes.forEach(node => {
          const path = []
          let pathLength = 0
          while (node) {
            path.push(node)
            pathLength++
            if (node.parents.length === 0 || node.parents[0] === 'start') {
              break
            }
            node = this.svgGraphData[node.parents[0]]
          }
          if (pathLength > longestPath) {
            longestPath = pathLength
            nodeList = path.reverse()
          }
        })
      }

      const result = nodeList.map(node => {
        return {
          role: this.svgLanesData[node.laneId].name,
          description: node.name
        }
      })
      return JSON.stringify(result)
    },
    prompts () {
      return [
        { type: 'System', content: this.systemPrompt },
        { type: 'User', content: this.userPrompt, error: this.userPromptError },
        { type: 'Assistant', content: this.assistantPrompt }
      ]
    }
  },
  methods: {
    copyPrompt (index) {
      navigator.clipboard.writeText(this.prompts[index].content)
      store.commit('snackbar/showMessage', { content: 'Copied to clipboard', timeout: 3000, color: 'primary', centered: false }, { root: true })
    },
    closeDialog () {
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped>
.code-block {
  background-color: #f5f5f5;
  border-radius: 4px;
  font-family: monospace;
  font-size: 14px;
  padding: 8px;
  margin: 0;
  max-width: 100%;
  max-height: 300px;
  overflow: hidden auto;
}
</style>
