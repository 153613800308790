<template>
  <v-card>
    <v-card-title class="text-h5">Qlerify Online Help</v-card-title>

    <v-card-text>
      <h2>Show/hide navigation menu</h2>
      <p>Click on the show/hide symbol in the left part of the page header (next to the logo).</p>

      <h2>Create a new organization</h2>
      <p>Go to settings (click on the settings symbol in the right part of the page header), select the “MY ORGANISATIONS” tab and click on the button “NEW ORGANISATION”. Enter the name of the organization.</p>

      <h2>Delete an organization</h2>
      <p>Go to settings (click on the settings symbol in the right part of the page header), select the “MY ORGANISATIONS” tab and click on the trash can symbol. Note: if you delete an organization, everything related to that organization will be permanently deleted (projects, diagrams, cards, votes, etc).</p>

      <h2>Change organization name</h2>
      <p>Go to settings (click on the settings symbol in the right part of the page header), select the “MY ORGANISATIONS” tab and click on the edit/pencil symbol. Enter the name of the organization.</p>

      <h2>Create a new project</h2>
      <p>In the dashboard, click on the button "CREATE NEW PROJECT" or go to settings (click on the settings symbol in the right part of the page header) and click on the button “NEW PROJECT”. You can also create a new project from the drop down list "Select project" in the header.</p>

      <h2>Select a project</h2>
      <p>After you have created a project or after you have been invited to a project, you can select the desired project from the drop down list in the page header.</p>

      <h2>Delete a project</h2>
      <p>Go to settings (click on the settings symbol in the right part of the page header) and click on the trash can symbol. You can only delete projects you have created. Note: all your data related to the project will be permanently deleted.</p>

      <h2>Change project name or project ownership</h2>
      <p>Go to settings (click on the settings symbol in the right part of the page header) and click on the edit/pencil symbol. Enter the new name of the project. You can also change ownership of the project to another organisation.</p>

      <h2>Invite a team member to the project</h2>
      <p>Click on Team Members in the navigation menu to the left. Click on the button “INVITE TEAM MEMBER TO PROJECT” and enter the email address of the team member. The new team member will receive an email with a link to the login/signup page.</p>

      <h2>Remove a team member from the project</h2>
      <p>Click on Team Members in the navigation menu to the left. Click on the trash can symbol to the right to remove the team member from the project.</p>

      <h2>Create a new business process/user journey/value stream</h2>
      <p>Click on Workflows in the navigation menu to the left. Click on the button “NEW WORKFLOW” and enter a name for the workflow.</p>

      <h2>Delete a business process/user journey/value stream</h2>
      <p>Click on Workflows in the navigation menu to the left. Click on the trash can symbol to the right. Note: if you delete a diagram, everything related to that diagram will be permanently deleted (events, cards, votes, comments, etc).</p>

      <h2>Add event/process step </h2>
      <p>Click on Workflows in the navigation menu to the left and select the desired diagram (click on a row in the list). If it is a new diagram: Click on the button “add the first event to this workflow” and enter event name and lane (actor). If you want to add a event/process step after an existing event: Click on the event and then on the plus symbol close to the event. Select lane and enter the name of the new event. It is possible to create a new lane here simply by entering the name of the new lane. Finally click on “ADD”. The event (and the lane and its corresponding swimlane if it is a new lane) will be created and added to the diagram.</p>

      <h2>Delete event/process step </h2>
      <p>Click on the event and then on the delete/trash can symbol. Note: All cards associated with the event/process step will also be permanently deleted.</p>

      <h2>Add decision/condition and define the alternative paths</h2>
      <p>Click on the event and then on the plus symbol. Select “Add decision” and enter an expression for the decision/condition and click on “ADD”. The name can be expressed as a question and each path is then an answer to the question, e.g. “yes” or “no”. A path can be added to an existing decision/condition by clicking on the decision/condition and then clicking on the plus symbol. Select “Add connection”, enter the label of the connection and select the target event.</p>

      <h2>Delete decision/condition</h2>
      <p>Select the decision/condition and click on the delete/trash can symbol.</p>

      <h2>Add connection to a decision</h2>
      <p>Click on the decision and then on the plus symbol. Select “Add connection”, enter the connection label, select the target event and click on "ADD".</p>

      <h2>Delete connection in diagram </h2>
      <p>Click on the decision and then on the symbol that looks like an arrow pointing to the right. Select the connection and click on "OK". You can only delete a connection as long as it does not break the diagram.</p>

      <h2>Move a event/process step or a decision</h2>
      <p>You can move the diagram elements simply clicking on an element and then drag and drop it to the desired postion in the diagram. All the connections will be reconnected automatically and the related elements will be repositioned automatically to the optimal position.</p>

      <h2>Change name of a event or decision</h2>
      <p>Click on the event or decision and click on the edit/pen symbol. Enter the new name (event description or question) and click on “UPDATE”.</p>

      <h2>Add a new lane</h2>
      <p>New lanes can be created when you add a event or edit a event. See Add event/process step above.</p>

      <h2>Delete a lane</h2>
      <p>Lanes that are no longer used in a diagram are automatically deleted when associated events are deleted.</p>

      <h2>Change lane for a event/process step</h2>
      <p>Select a event and click on the edit/pen symbol. You can select an existing lane from the list or create a new lane by entering the name of the new lane. A new swimlane will be created If you create a new lane. Click on "UPDATE" to apply your changes.</p>

      <h2>Change the name of a lane/swimlane</h2>
      <p>Click on the text in the left part of the swimlane. Edit the text and click somewhere in the workspace to apply your changes.</p>

      <h2>Like and comment on a diagram</h2>
      <p>Click on the thumbs up/down symbol to approve/disapprove and enter a comment</p>

      <h2>Visualise completion status</h2>
      <p>You can visualize the state of completion of the diagram by changing workflow status. There are currently three states to choose from: “Initial Construction”, “Improve with feedback” and “Completed”:
        <ul>
          <li><strong>Initial Construction</strong>: Use this state when a lot of changes occur.</li>
          <li><strong>Improve with feedback</strong>: Use this state when you want to collect feedback from team members to finalize your diagrams. You can choose to reset diagram approvals and comments every time a change is made to the diagram. </li>
          <li><strong>Completed</strong>: The diagram is locked for editing. Use this state when you have collected enough feedback and the diagram has been reviewed by key stake holders.</li>
        </ul>
      </p>

      <h2>User Story Mapping - Release Filter</h2>
      <p>You can apply a visual filter on the entire workspace, thereby visualising the desired content in each release. Use the dropdown "Filter Releases" and select the releases you want to include. Se below for linking a product backlog item to a release.</p>

      <h2>Add a new card to a event/process step</h2>
      <p>Click on the event/process step to add a card connected to this step. Click on the button “ADD CARD TO …”. Cards can be expressed in the desired format and you can add e.g. epics, user stories, non-functional cards, business rules or any other information the developers need to know. Cards are displayed in a list below the diagram.</p>

      <h2>Card Type</h2>
      <p>You can assign a type to each card/product backlog item when you create them, such as Epic or User Story. Select the type from the dropdown list and enter the text. You can change the type at any time.</p>

      <h2>Delete a card</h2>
      <p>Click on the event/process step. Click on the delete/trash can symbol below the diagram to the right of the card you want to delete.</p>

      <h2>Edit a card</h2>
      <p>Click on the event/process step. Click on the edit/pen symbol to the right of the card and update the card.</p>

      <h2>Prioritize cards</h2>
      <p>Click on the event/process step. Select the desired priority in the column “My prio”. You can choose between Must have, Should have, Could have or Won’t have (according to the MoSCow scale). The priority shown in column “Team prio (combined)” is the average priority value based on the ranking from all team members. You can also see how many team members that have voted/ranked each card and comments from team members by clicking the comment symbol.</p>

      <h2>Comment on cards</h2>
      <p>You can leave a comment for each card. Click on the event/process step and then click on the comment symbol for the corresponding card below the diagram (in column “Team prio (combined)”). Select Add your comment and enter your comment. Save your comment by clicking outside the box. You can update your comment at any time.</p>

      <h2>Add attachments for cards</h2>
      <p>You can upload documents such as UI sketches or screenshots or provide a link to an external web page. Click on the attachments symbol next to the card description and select a document or paste a URL in the field. Click on "ATTACH" to save your changes. You can add or delete attachments at any time.</p>

      <h2>Show all cards for a diagram</h2>
      <p>Click on the text “(show all)” below the diagram to show all the cards for all events.</p>

      <h2>User Story Mapping - linking user stories to releases</h2>
      <p>Each box in the diagram can be a step in a user journey or an epic. You can add user stories or any other type of card for each step or epic as described above. Each card can be assigned a release number (or version number) by selecting a number in the Release column. It is now possible to visualize the scope of a release/version and what epics/user journey steps and cards that are included in a certain release or version by using the filter function descibed above the diagram.</p>

      <h2>Sorting Cards</h2>
      <p>You can choose the order in which your cards are displayed by clicking on the desired column header, e.g. My prio to have the most important cards on the top of the list.</p>

      <h2>Selecting priority in the Product Backlog</h2>
      <p>You can create a prioritized Product Backlog with all your cards simply by activating the switch “Product backlog view” below the diagram and drag and drop each card to the desired position in the backlog. Simply place your mouse on the beginning of the row and drag the card up or down to the desired position.</p>

      <h2>Export content to excel</h2>
      <p>You can export lanes, process events, cards, comments on cards and ranking of cards to a CSV-file. This file can then easily be imported to your favorite ALM-tool, such as Atlassian Jira, Microsoft Azure DevOps, CA Agile Central, CollabNet VersionOne, Trello, etc. Select one of your diagrams and click on the export button located at the top right corner of your diagram.</p>

      <h2>Save as pdf file</h2>
      <p>You can save the diagram in pdf format through your browser’s print function. Choose the desired scale and select “save as pdf” instead of sending the document to the printer.</p>

      <h2>Change the order of swimlanes</h2>
      <p>You can change the order of the swimlanes if you want your actors/lanes to appear in a certain order. Point your mouse on the left part of the swimlane you want to move and click on the up/down arrow that appears.</p>

      <h2>Account Info and Change Password</h2>
      <p>Click on the my account symbol in the right part of the page header. Here you can see your account info. Click on the button “CHANGE PASSWORD” to change your password. Your password must include letters, numbers and symbols.</p>

      <h2>Short keys för Mac users that can be used as an alternative to using the mouse</h2>
      <p>
        <ul>
          <li>Select a event/condition and press Control+N to add a new event/condition.</li>
          <li>Select a event/condition and press Control+Backspace to delete a event/condition.</li>
          <li>When editing a event: Press Tab to move on to the next input field/button.   </li>
          <li>When editing a event: Press Enter instead of Clicking on "UPDATE" (when the button is highlighted).</li>
        </ul>
      </p>
      <h2>Support</h2>
      <p>Please contact us at <a href="mailto:support@qlerify.com">support@qlerify.com</a> if you have any questions or comments regarding Qlerify. We love to hear feedback from our customers!</p>
    </v-card-text>
  </v-card>
</template>
