import gql from 'graphql-tag'

export default gql`
  subscription OnDeleteTeamMember($projectId: String!)  {
    onDeleteTeamMember (projectId: $projectId) {
      id
      projectId
    }
  }
`
