<template>
  <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0"
      y="0"
      width="14"
      height="14"
      rx="0"
      ry="0"
      style="stroke-width: 1px"
    ></rect>

    <path
      d="M7,2 l0,10 m -5,-5 l 10,0"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'subprocess-icon'
}
</script>

<style scoped>
path {
  stroke-width: 2px;
  stroke: black;
}
</style>
