<template>
  <div>
    <v-card>
      <v-card-actions class="pl-3 pr-3 pt-3 pb-3">
        <v-btn color="primary" class="pl-2 pr-2" @click="csvExport(csvData)">Export to CSV</v-btn>
        <v-btn class="pl-2 pr-2" @click="closeDialog()">Cancel</v-btn>
      </v-card-actions>
      <v-card-text>
        <table>
          <tr>
            <th v-for="(th, index) in columns" v-bind:key="index">{{th}}</th>
          </tr>
          <tr v-if="csvData.length == 0">
            <td colspan="9">No cards to display</td>
          </tr>
          <tr v-else v-for="(row, index) in csvData" v-bind:key="index">
            <td>{{ row.index }}</td>
            <td>{{ row.role }}</td>
            <td>{{ row.event }}</td>
            <td v-html="row.requirementDescription"></td>
            <td>{{ row.cardType }}</td>
            <td>{{ row.release }}</td>
            <td>
              <template v-if="row.comments && row.comments.length > 0">
                <dl v-for="(item, index) in row.comments.split('\n')" class="dl" v-bind:key="'a' + index">
                  <dd>{{ item }}</dd>
                </dl>
              </template>
            </td>
            <td>
              <template v-if="row.prio && row.prio.length > 0">
                <dl v-for="(item, index) in row.prio.split('\n')" class="dl" v-bind:key="'b' + index">
                  <dd>{{ item }}</dd>
                </dl>
              </template>
            </td>
            <td>{{ row.averagePrio }}</td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { cardHelpers } from '@/mixins/cardHelpers.js'
import { mapState } from 'vuex'

export default {
  props: ['workflow', 'showDone'],
  mixins: [cardHelpers],
  data () {
    return {
      columns: ['Backlog Prio', 'Lane', 'Event', 'Card', 'Type', 'Release', 'Comments', 'Team Member Prio', 'Combined Prio']
    }
  },
  computed: {
    ...mapState({
      listTeamMembers: state => state.teamMembers.teamMembers,
      requirementTypes: state => state.requirementTypes.requirementTypes,
      showReleaseNo: state => state.workflow.showReleaseNo
    }),
    filteredCards () {
      let requirements = []
      const { eventId } = this.$route.params
      const displayTypeInBacklog = {}

      if (!Array.isArray(this.workflow.eventsJson)) {
        return requirements
      }

      if (Array.isArray(this.requirementTypes)) {
        for (const { id, showInBacklog } of this.requirementTypes) {
          displayTypeInBacklog[id] = showInBacklog
        }
      }

      for (const event of this.workflow.eventsJson) {
        if (!eventId || eventId === event.id) {
          // add eventId to each requirement using map
          const requirementsJson = event.requirementsJson?.map((req) => {
            return { ...req, eventId: event.id }
          })
          requirements = requirements.concat(requirementsJson)
        }
      }

      requirements = requirements
        .filter((requirement) => requirement)
        .filter(({ done }) => this.showDone === Boolean(done))
        .filter(({ cardType }) => {
          if (!cardType || !Object.prototype.hasOwnProperty.call(displayTypeInBacklog, cardType.id)) {
            return true
          }

          return displayTypeInBacklog[cardType.id]
        })

      if (this.showReleaseNo && this.showReleaseNo.length > 0) {
        requirements = requirements
          .filter(({ releaseNo }) => this.showReleaseNo.includes(releaseNo))
      }

      requirements = this.sortCards(requirements)

      return requirements
    },
    csvData () {
      let result = []

      if (!this.filteredCards.length) {
        return result
      }

      const eventsDict = {}
      const lanesDict = {}

      // construct events dict
      for (const { id, description, laneId } of this.workflow.eventsJson) {
        eventsDict[id] = { description, laneId }
      }

      // construct lanes dict
      for (const { id, name } of this.workflow.lanes) {
        lanesDict[id] = name
      }
      result = this.filteredCards.map(({ description, requirementStatuses, eventId, cardType, releaseNo, comments }, i) => {
        if (!requirementStatuses) {
          requirementStatuses = []
        }
        const event = eventsDict[eventId]
        const role = lanesDict[event.laneId]

        let membersPrio = ''
        let membersComments = ''
        const membersPrioAvg = this.getAveragePrio(requirementStatuses)

        const teamMemberReactions = this.getTeamMembersReactionsList(requirementStatuses, this.listTeamMembers)
        for (const teamMemberReaction of teamMemberReactions || []) {
          if (teamMemberReaction) {
            const fullname = `${teamMemberReaction.user?.firstname} ${teamMemberReaction.user?.lastname}`
            const { prio } = teamMemberReaction

            if (typeof prio === 'number' && prio >= 1 && prio <= 4) {
              membersPrio += `${fullname}: ${this.toMoscowScale(prio)}\n`
            }
          }
        }
        membersPrio = membersPrio.slice(0, -1)

        for (const comment of comments || []) {
          if (comment) {
            const fullname = `${comment.author.firstname} ${comment.author.lastname}`
            const { text } = comment

            if (text) {
              membersComments += `${fullname}: ${text}\n`
            }
          }
        }

        // remove html tags from description
        const html = description
        const div = document.createElement('div')
        div.innerHTML = html
        description = div.innerText

        return {
          index: `"${i + 1}"`,
          role: `"${role}"`,
          event: `"${event.description}"`,
          requirementDescription: `"${description}"`,
          cardType: `"${cardType?.title || ''}"`,
          release: `"${releaseNo || ''}"`,
          comments: `"${membersComments || ''}"`,
          prio: `"${membersPrio}"`,
          averagePrio: `"${membersPrioAvg}"`
        }
      })
      return result
    }
  },
  methods: {
    csvExport (arrData) {
      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += [
        Object.values(this.columns).join(';'),
        ...arrData.map(item => Object.values(item).join(';'))
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')

      const data = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute('download', 'export.csv')
      link.click()
    },
    getAveragePrio (requirementStatuses) {
      let totalScore = 0
      let noOfVotes = 0

      for (const requirementStatus of requirementStatuses) {
        if (!requirementStatus) continue

        const { prio } = requirementStatus

        if (typeof prio === 'number' && prio >= 1 && prio <= 4) {
          totalScore += prio
          noOfVotes++
        }
      }

      if (noOfVotes > 0) {
        const res = Math.ceil(totalScore / noOfVotes)
        return this.toMoscowScale(res)
      } else {
        return ''
      }
    },
    closeDialog () {
      this.$emit('closeExportCsvDialog')
    },
    toMoscowScale (prio) {
      const tmp = Math.ceil(prio)
      switch (tmp) {
        case 1:
          return '1 Must have'
        case 2:
          return '2 Should have'
        case 3:
          return '3 Could have'
        case 4:
          return '4 Won\'t have'
        default:
          return ''
      }
    },
    getLaneNameFromId (laneId) {
      let res = ''
      const index = this.workflow.lanes.findIndex((lane) => lane.id === laneId)
      if (index >= 0) {
        res = this.workflow.lanes[index].name
      }
      return res
    },
    sortCards (cards) {
      cards.sort((a, b) => {
        const sortA = a.sortkey || 'zzzzzzzzzz'
        const sortB = b.sortkey || 'zzzzzzzzzz'
        const releaseA = a.releaseNo || '9999999999'
        const releaseB = b.releaseNo || '9999999999'
        if (releaseA > releaseB) {
          return 1
        } else if (releaseB > releaseA) {
          return -1
        } else {
          if (sortA === sortB) {
            return a.id > b.id ? 1 : -1
          } else {
            return sortA > sortB ? 1 : -1
          }
        }
      })
      return cards
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  padding: 1em;
  font-family: sans-serif;
  color: #444;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  padding: 0.3em 0.5em;
  border: 1px solid #ddd;
}
.dl dt {
  float: left;
  color: #999;
}
</style>
