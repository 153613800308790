<template>
  <div
    :class="{
      'role-name-wrapper': true,
      'role-name-wrapper-first': first,
    }"
    :style="{
      width: lane.height + 'px',
      transform: 'rotate(-90deg) translateY(' + -lane.height + 'px)',
      'background-color': workflow.roleDefinition.color.rgb,
    }"
  >
    <v-tooltip location="top" v-if="!$store.state.isWorkflowDisabled && !last && !disableReorder" open-delay="1000">
      <template v-slot:activator="{ on, props }">
        <div class="move-lane-down-button" @click="moveDown(lane.id, index)">
          <button class="captures-description">
            <v-icon v-bind="props" v-on="{ ...on }" black>mdi-chevron-left</v-icon>
          </button>
        </div>
      </template>
      <span>Move the lane down</span>
    </v-tooltip>

    <div
      ref="laneName"
      :id="lane.name"
      :name="lane.name"
      :data-test="svgLanes[lane.id].name"
      class="role-name single-line d-flex justify-center align-center"
      @keydown.stop="checkCharacterLimit($event, 20)"
      @keydown.stop.enter="$event.target.blur()"
      @keydown.stop.esc="restoreValue($event, lane.name)"
      @blur="$emit('name-blur', $event, lane.name, index)"
      @paste="onPaste($event, $refs.laneName, 20, true)"
      :contenteditable="!$store.state.isWorkflowDisabled"
      v-text="lane.name"
      @input="() => updateFontSizeDynamically(this.$refs.laneName, true)"
    ></div>

    <v-tooltip location="top" v-if="!$store.state.isWorkflowDisabled && !first && !disableReorder" open-delay="1000">
      <template v-slot:activator="{ on, props }">
        <div class="move-lane-up-button" @click="moveUp(lane.id, index)">
          <button class="captures-description">
            <v-icon v-bind="props" v-on="{ ...on }">mdi-chevron-right</v-icon>
          </button>
        </div>
      </template>
      <span>Move the lane up</span>
    </v-tooltip>
  </div>
</template>

<script>
import { diagramHelpers } from '@/mixins/diagramHelpers.js'
import workflowApi from '@/api/workflowApi'
import { updateFontSizeDynamically } from '@/utils/update-font'

export default {
  mixins: [diagramHelpers],
  props: {
    first: {
      type: Boolean,
      default: false
    },
    last: {
      type: Boolean,
      default: false
    },
    lane: Object,
    index: Number,
    svgGraph: Object,
    svgLanes: Object,
    svgGroups: Object,
    workflow: Object,
    disableReorder: Boolean
  },

  methods: {
    updateFontSizeDynamically,
    switchLanes (id1, index1, id2, index2) {
      const newSvgLanes = {}
      for (const id in this.svgLanes) {
        if (id === id1) {
          newSvgLanes[id2] = this.svgLanes[id2]
        } else if (id === id2) {
          newSvgLanes[id1] = this.svgLanes[id1]
        } else {
          newSvgLanes[id] = this.svgLanes[id]
        }
      }
      workflowApi.updateWorkflow({
        svgGraph: this.svgGraph,
        svgLanes: newSvgLanes,
        svgGroups: this.svgGroups,
        workflow: this.workflow,
        updateNote: {
          action: 'switched lanes',
          target: this.svgLanes[id2].name + ', ' + this.svgLanes[id1].name
        }
      })
    },
    moveUp (laneId, index) {
      if (index === 0) {
        alert('Can\'t move this lane higher up')
        return
      }
      this.switchLanes(laneId, index, this.workflow.lanes[index - 1].id, index - 1)
    },
    moveDown (laneId, index) {
      if (index >= (this.workflow.lanes.length - 1)) {
        alert('Can\'t move this lane further down')
        return
      }
      this.switchLanes(laneId, index, this.workflow.lanes[index + 1].id, index + 1)
    }
  },

  mounted () {
    if (this.$refs.laneName) {
      // Lane height represents the width of text container, because the container is rotated by 90 degrees
      // noinspection JSSuspiciousNameCombination
      this.$refs.laneName.style.width = this.lane.height
      updateFontSizeDynamically(this.$refs.laneName, true)
    }
    if (this.lane.selectLane) {
      const node = this.$el.querySelector('.role-name.single-line')
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNodeContents(node)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }
}
</script>

<style scoped>
.role-name-wrapper {
  height: 30px;
  border: 1px solid rgba(170, 170, 170);
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: right top;
  white-space: nowrap;
}

.role-name-wrapper:hover {
  cursor: pointer;
}

.role-name-wrapper:hover .move-lane-down-button {
  display: block;
}

.role-name-wrapper:hover .move-lane-up-button {
  display: block;
}

.role-name-wrapper-first {
  border-radius: 0 10px 0 0;
}

.role-name {
  height: 30px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  word-break: break-word;
}

[contenteditable="true"].single-line {
  white-space: nowrap;
  overflow: hidden;
}
[contenteditable="true"].single-line br {
  display: none;
}
[contenteditable="true"].single-line * {
  display: inline;
  white-space: nowrap;
}

.move-lane-down-button,
.move-lane-up-button {
  width: 20px;
  position: absolute;
  bottom: 2px;
  display: none;
  background: #f1f2f2;
  border-radius: 20%;
}

.move-lane-down-button {
  left: 0px;
}

.move-lane-up-button {
  right: 0px;
}

.v-tooltip__content {
  background: white;
  color: var(--color-primary);
  border-radius: 10px;
}

.v-tooltip__content code {
  background: var(--color-primary);
  color: white;
}
</style>
