import gql from 'graphql-tag'

export default gql`
  mutation deleteProject($id: String!) {
    deleteProject(id: $id) {
        id
        organisationId
    }
  }
`
