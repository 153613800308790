export const synchronizedScrolling = {
  namespaced: true,
  state: {
    bpmnScrollLeft: 0,
    svgContainerWidth: null
  },
  mutations: {
    setBpmnScrollLeft (state, position) {
      // state used to synchronize horizontal scrolling between bpmn and user story mapping
      state.bpmnScrollLeft = position
    },
    setSvgContainerWidth (state, width) {
      state.svgContainerWidth = width
    }
  },
  getters: {
    diagramWidth: (state, getters, rootState, rootGetters) => {
      const svgContainerWidth = state.svgContainerWidth
      const svgGraphWidth = rootGetters['svgGraphData/baseData'].columns.length * 160 + 50
      const svgGropusWidth = getWidthFromGroups(rootGetters['svgGraphData/svgGroupsData'])
      const res = Math.max(svgContainerWidth, svgGraphWidth, svgGropusWidth)
      return res
    }
  }
}

function getWidthFromGroups (svgGroups) {
  if (!svgGroups) return 0
  const groupSlotXArray = Object.values(svgGroups).map(group => group.startSlotX)
  const maxSlotX = Math.max(...groupSlotXArray)
  return maxSlotX * 160 + 160 + 50
}
