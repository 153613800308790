<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 46 46" fill="none">
    <rect width="46" height="46" fill="white"/>
    <rect class="shape" x="20.5" y="5.5" width="24" height="17" rx="1.5"/>
    <path class="shape" d="M2.67468 22.75L13.5 4L24.3253 22.75H2.67468Z"/>
    <circle class="shape" cx="24.5" cy="32.5" r="11"/>
  </svg>
</template>

<script>
export default {
  name: 'changeshape-icon'
}
</script>

<style scoped>
.stroke--current-color {
  stroke: currentColor;
  stroke-width: 2px;
}

rect {
  fill: none;
}

.shape {
  fill: white !important;
  stroke: black !important;
}
</style>
