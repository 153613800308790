<!-- MyMenu.vue -->
<template>
  <v-menu >
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" size="small" block>
        Configure {{isTrigger ? 'Action' : 'Aggregate'}}
      </v-btn>
    </template>
    <v-list density="compact" width="200">
      <v-list-item style="user-select: none;">
        <b v-if="!isTrigger"><small>Select Aggregate card type:</small></b>
        <b v-if="isTrigger"><small>Select Action card type:</small></b>
        <!-- <b><small>Select Action card type:</small></b> -->
        <!-- <b>Select a card type to be used for Aggregates</b> -->
      </v-list-item>
      <v-list-item v-for="item in menuItems" :key="item.id" @click="selectItem(item)">
        <v-list-item-title>
          <v-avatar :color="item?.color?.rgb || 'gray'" size="18" class="card-type-color mr-2"></v-avatar>
          <span class="card-type-title">{{ item.title }}</span>
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="createNewCardType()">
        <v-list-item-title class="no-color">
          Create new card type
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import requirementTypesApi from '@/api/requirementTypesApi'
import { EventBus } from '@/event-bus/event-bus.js'

export default {
  props: {
    requirementTypes: Array,
    isTrigger: Boolean
  },

  computed: {
    menuItems () {
      if (!this.requirementTypes) return []
      return this.requirementTypes.filter(item => !item.triggerType && !item.aggregateType)
    }
  },

  methods: {
    selectItem (item) {
      const newItem = JSON.parse(JSON.stringify(item))
      if (this.isTrigger) {
        newItem.triggerType = true
      } else {
        newItem.aggregateType = true
      }
      requirementTypesApi.updateRequirementType(this.$route.params.id, newItem)
    },

    createNewCardType () {
      EventBus.$emit('open-settings', true)
    }
  }
}
</script>

<style scoped>
.card-type-title {
  vertical-align: middle;
}

.no-color {
  margin-left: 26px;
}
</style>
