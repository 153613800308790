<template>
  <div
    ref="editor"
    class="textEditor"
    :id="id"
    :tabindex="index"
    @keydown="keydown"
    @focusin="focusin"
  ></div>
</template>

<script>
import Quill from 'quill'

export default {
  props: {
    id: String,
    index: Number,
    text: String,
    disabled: Boolean
  },
  methods: {
    initQuillEditor () {
      let element
      // eslint-disable-next-line no-undef
      this.quill = new Quill(this.$refs.editor, {
        formats: ['bold', 'underline'],
        // readOnly: this.$store.state.isWorkflowDisabled,
        modules: {
          toolbar: false,
          keyboard: {
            bindings: {
              shift_enter: {
                key: 13,
                shiftKey: true,
                handler: (range, ctx) => {
                  this.quill.insertText(range.index, '\n')
                  this.emitTextDelta()
                }
              },
              alt_enter: {
                key: 13,
                altKey: true,
                handler: (range, ctx) => {
                  this.quill.insertText(range.index, '\n')
                  this.emitTextDelta()
                }
              },
              tab: {
                key: 9,
                handler: (range, ctx) => {
                  const target = this.$refs.editor
                  this.$emit('move-focus', 'right', target)
                }
              }
            }
          }
        },
        theme: 'snow'
      })

      this.quill.keyboard.bindings[13].unshift({
        key: 13,
        handler: (range, ctx) => {
          // empty handler necessary to ignore enter
          // and pass it on to the outer handler
        }
      })

      this.$nextTick(() => {
        if (this.$refs.editor) {
          element = this.$refs.editor.firstChild
          element.addEventListener('blur', this.onBlur)
        }
      })
    },

    textCursorIsActive () {
      if (this.quill.getSelection() === null) {
        return false
      } else {
        return true
      }
    },

    onBlur (event) {
      if (event?.relatedTarget?.className === 'ql-clipboard') {
        // avoids saving when pasting into quill editor (content is not always populated)
        return
      }

      if (!this.$refs.editor) return

      const html = this.$refs.editor.childNodes[0].innerHTML
      if (html !== this.text) {
        this.$emit('text-update', html, this.id, this.index)
      }
      this.quill.setSelection(null)
    },

    keydown (event) {
      if (this.textCursorIsActive()) {
        if (event.keyCode === 13) { // enter
          if (event.altKey === false && event.shiftKey === false) {
            const target = this.$refs.editor
            this.$emit('move-focus', 'down', target, 'description')
            event.preventDefault()
          }
        }
        if (event.keyCode === 27) { // escape
          const value = this.text || ''
          const delta = this.quill.clipboard.convert(value)
          this.quill.setContents(delta, 'silent')
          this.setCaretAtEndOfEditor()
        }

        this.emitTextDelta()
      } else {
        switch (event.keyCode) {
          case 9: // tab
            break // break to avoid default
          case 13: // enter
            this.setCaretAtEndOfEditor()
            event.preventDefault()
            break
          case 37: // left arrow
            this.$emit('move-focus', 'left', event.target)
            event.preventDefault()
            break
          case 38: // up arrow
            this.$emit('move-focus', 'up', event.target, 'description')
            event.preventDefault()
            break
          case 39: // right arrow
            this.$emit('move-focus', 'right', event.target)
            event.preventDefault()
            break
          case 40: // down arrow
            this.$emit('move-focus', 'down', event.target, 'description')
            event.preventDefault()
            break
          default: {
            // clear the text area only when some
            // visible character or backspace is pressed
            if (
              (event.keyCode === 8 ||
                (event.keyCode >= 48 &&
                  event.keyCode <= 90) ||
                (event.keyCode >= 160 &&
                  event.keyCode <= 173)) &&
              event.ctrlKey === false &&
              event.metaKey === false &&
              event.altKey === false
            ) {
              this.clearContentAndSetCursor()
            }
          }
        }
      }
    },

    emitTextDelta () {
      const html = this.$refs.editor.childNodes[0].innerHTML
      this.$emit('text-delta', html, this.id, this.index)
    },

    setCaretAtEndOfEditor () {
      this.quill.setSelection(this.quill.getLength(), 0)
    },

    clearContentAndSetCursor () {
      this.quill.deleteText(0, this.quill.getLength())
      this.quill.setSelection(0, 0)
    }
  },

  mounted () {
    this.initQuillEditor()

    if (this.text) {
      const delta = this.quill.clipboard.convert(this.text)
      this.quill.setContents(delta, 'silent')
    }

    if (this.disabled) {
      this.quill.disable()
    }
  },

  beforeUnmount () {
    this.$refs.editor.firstChild.removeEventListener('blur', this.onBlur)
  },

  watch: {
    text (newText) {
      const delta = this.quill.clipboard.convert(this.text)
      this.quill.setContents(delta, 'silent')
    },
    disabled (newVal) {
      if (newVal) {
        this.quill.disable()
      } else {
        this.quill.enable()
      }
    }
  }
}
</script>

<style scoped>
.ql-container.ql-snow {
  border: none !important;
}
</style>

<style>
.textEditor p {
  margin-bottom: 0px !important;
  color: black;
}

.textEditor .ql-editor {
  user-select: text;
  padding: 0 !important;
}
</style>
