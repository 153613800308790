<template>
  <v-dialog v-model="dialogOrganisation" max-width="500px">
    <template v-slot:activator="{ props }">
      <slot name="activator" :props="props">
        <v-btn
          v-bind="props"
          :color="buttonTheme === 'dark' ? 'primary' : 'white'"
          class="mb-2"
          v-bind:class="{ 'primary--text': buttonTheme === 'light' }"
        >
          {{ buttonText }}
        </v-btn>
      </slot>
    </template>

    <v-card>
      <v-container class="pr-0">
        <v-row class="v-container--fluid justify-space-between align-top">
          <v-card-title>
            <span v-if="editOrganisation && editOrganisation.id" class="text-h5">Edit Organisation</span>
            <span v-else class="text-h5">New Organisation</span>
          </v-card-title>

          <v-btn variant="text" icon color="grey" @click="closeOrganisation">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-container>
      <v-card-text>
        <v-container grid-list-md>
          <v-form ref="formOrganisation" fast-fail @submit.prevent v-model="validOrganisation">
            <v-row wrap>
              <v-col xs12>
                <v-text-field
                    name="organisation-name-input"
                    ref="organisationNameInput"
                    variant="underlined"
                    v-model="editOrganisation.name"
                    :rules="[rules.required, rules.counter]"
                    label="Organisation name"
                    v-on:keyup.enter="saveOrganisation"
                    :autofocus="true">
                </v-text-field>
                <!-- keyup.enter doesn't work without this row, seems like a bug in vue or vuetify -->
                <v-text-field class="hidden" :disabled="true" style="display: none;"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="text" @click="closeOrganisation">Cancel</v-btn>
        <v-btn color="primary" variant="elevated" @click="saveOrganisation">{{ this.editOrganisation && this.editOrganisation.id ? 'Update' : 'Add' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import projectApi from '@/api/projectApi'
import store from '@/store'
import { mapState } from 'vuex'

export default {
  props: {
    buttonText: {
      type: String,
      default: 'Add organisation'
    },
    buttonTheme: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dialogOrganisation: false,
    validOrganisation: true,
    rules: {
      required: value => !!value || 'Required.',
      counter: value => (value && value.length <= 30) || 'Max 30 characters'
    },
    editOrganisation: {
      name: ''
    }
  }),
  methods: {
    async saveOrganisation () {
      const result = await this.$refs.formOrganisation.validate()
      if (result.valid) {
        if (this.editOrganisation.id) {
          // update organisation
          const index = this.organisations.findIndex(org => org.name === this.editOrganisation.name && org.userId === store.state.userId)
          if (index >= 0) {
            alert('This organisation already exists.')
          } else {
            projectApi.updateOrganisation(this.editOrganisation)
            this.closeOrganisation()
          }
        } else {
          // create new organisation
          const index = this.organisations.findIndex(org => org.name === this.editOrganisation.name && org.userId === store.state.userId)
          if (index >= 0) {
            alert('This organisation already exists.')
          } else {
            projectApi.createOrganisation(this.editOrganisation)
            this.closeOrganisation()
          }
        }
      }
    },
    closeOrganisation () {
      this.dialogOrganisation = false
    },
    openOrganisationDialog () {
      this.dialogOrganisation = true
    },
    setItem (item) {
      this.editOrganisation = item
    },
    selectAllTextInInput () {
      const inputElement = this.$refs.organisationNameInput.$el.querySelector('input')
      inputElement.focus()
      inputElement.select()
    }
  },
  computed: {
    ...mapState({
      organisations: state => state.organisations.organisations || [],
      currentUser: state => state.user.user
    })
  },
  watch: {
    dialogOrganisation (val) {
      if (!val) {
        this.editOrganisation = {
          name: ''
        }
      } else {
        if (!this.editOrganisation || !this.editOrganisation.id) {
          this.$refs.formOrganisation && this.$refs.formOrganisation.reset()
          this.editOrganisation = {
            name: this.organisations?.length > 0 ? '' : this.currentUser?.firstname + "'s organisation"
          }
          this.$nextTick(() => {
            this.selectAllTextInInput()
          })
        }
      }
    }
  }
}
</script>
