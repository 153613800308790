import gql from 'graphql-tag'
import { WORKFLOW_FIELDS } from '../fragments'

export default gql`
  ${WORKFLOW_FIELDS}
  mutation createWorkflow($projectId: String!, $input: CreateWorkflowInput!) {
    createWorkflow(projectId: $projectId, input: $input) {
      ...WorkflowFields
    }
  }
`
