<template>
  <div class="mb-6 overflow-y-visible">
    <v-card
      v-on:keydown.stop
      :class="{
        'diagram-container': true,
        'rounded-lg': true,
        'rounded-b-0': showLegend,
      }"
    >
      <template v-if="(!events || events?.length == 0) && !$store.state.isWorkflowDisabled">
        <div class="d-flex flex-row" :style="`position: absolute; z-index: 1; pointer-events: none; top: ${diagramYOffset}px;`">
          <v-btn
            @click="addShape({parents: ['start']})"
            class="create-first-event-button elevation-2 bg-primary text-white ml-15 mt-14 mb-15"
            style="pointer-events: auto;"
          >
            <b>Add the first event to this workflow!</b>
          </v-btn>
          <v-btn
            color="primary"
            class="generate-button text-white ml-10 mt-14 mb-15 mr-5 elevation-2"
            @click="openAiGenerator()"
            prepend-icon="mdi-creation"
            style="pointer-events: auto;">
            <b>Generate workflow with AI</b>
          </v-btn>
        </div>
      </template>
      <div ref="canvas" class="canvas-container">
        <div ref="scrollContainer" class="scroll-container">
          <svg
            v-show="events"
            :width="diagramWidth"
            :height="flowChartHeight"
            id="flowchart"
            ref="flowchart"
          >
            <g v-for="(group) in svgGroups" :key="group.id">
              <foreignObject
                :key="group.id"
                :x="getXPointFromGroup(group)"
                :y="0"
                :width="group.width > 0 ? group.width : (diagramWidth - getXPointFromGroup(group))"
                height="30"
              >
                <div
                  style="position: relative;"
                  class="hover-container"
                  :data-test="group.name"
                >
                  <div
                    :class="{
                      'group-first': group.startSlotX === 0,
                      'group-last': group.width === -1
                    }"
                    class="group"
                    :id="group.id"
                    @keydown.stop="checkCharacterLimit($event, 20)"
                    @keydown.stop.enter="$event.target.blur()"
                    @keydown.stop.esc="restoreValue($event, group.name)"
                    @blur="updateGroupName($event, group)"
                    @paste="onPaste($event, $refs.groupName, 20, true)"
                    @click="changeSelectedEvent(null)"
                    :contenteditable="!$store.state.isWorkflowDisabled"
                    v-text="group.name"
                  >
                  </div>
                  <span
                    class="show-on-hover"
                    style="position: absolute; right: 10px; top: 5px;"
                  >
                    <v-icon
                      class="delete-group-icon"
                      size="x-small"
                      icon="mdi-delete"
                      @click="deleteGroup(group)"
                    />
                  </span>
                </div>
              </foreignObject>
            </g>

            <svg
              :y="diagramYOffset"
              :width="diagramWidth"
              :height="flowChartHeight"
              >

              <g
                v-for="(lane, name, index) in svgLanes"
                :key="'swimlane-' + lane.id"
                @click="changeSelectedEvent(null)"
                @mouseenter="mouseEnterLane(lane)"
                @mouseleave="mouseLeaveLane($event, lane)"
              >
                <path
                  :d="
                    computeSwimlanePath(
                      lane.x + 30,
                      lane.y,
                      diagramWidth - 30,
                      lane.height,
                      index === 0
                    )
                  "
                  style="stroke: rgba(190, 190, 190); stroke-width: 1px"
                ></path>
              </g>

              <template v-for="node in svgGraph">
                <!-- Pre Loop to print parts of the svg that has to be on the lower layer -->
                <!-- Event Path -->
                <template v-for="(parentId, indexParent) in node.parents">
                  <path
                    v-if="svgGraph[parentId]"
                    :key="node.id + indexParent + '80'"
                    :d="createPathBetweenNodes(parentId, node.id)"
                    v-bind:class="{
                      outlined:
                        node.outlined || svgGraph[node.parents[0]]?.outlined,
                    }"
                    style="
                      fill: none;
                      stroke: rgb(118,118,118);
                      stroke-linejoin: round;
                      marker-end: url('#sequenceflow-end-white-black-1r79xjtcycpwpzz6dais2xasb');
                    "
                  ></path>
                  <!-- labels forward connections -->
                  <template
                    v-if="
                      svgGraph[parentId] && connectionHasLabel(parentId, node.id)
                    "
                  >
                    <!-- editable Event Label -->
                    <foreignObject
                      :key="node.id + indexParent + 'event-label'"
                      :x="svgGraph[parentId].x + 148"
                      :y="node.y + 10"
                      width="120"
                      height="32"
                    >
                      <bpmn-event-label
                        v-if="!node.outlined"
                        :node="node"
                        :conditionLabel="getConnectionLabel(node, parentId)"
                        @label-blur="
                          blurOnConditionLabels($event, node, parentId)
                        "
                        @label-focus="focusOnConditionLabel"
                      ></bpmn-event-label>
                    </foreignObject>
                  </template>
                </template>
              </template>
              <template v-for="node in svgGraph" :key="node.id">
                <!-- Event -->
                <!-- Event Box -->
                <template v-if="node.type == 'bpmn:Task'">
                  <bpmn-event
                    :key="node.id + '40'"
                    :node="node"
                    :svgGraph="svgGraph"
                    :svgGroups="svgGroups"
                    :svgLanes="svgLanes"
                    :conditionLabel="(svgGroups || [])[node.groupId]?.name || 'null'"
                    :eventDefinition="workflow.eventDefinition"
                    :workflow="workflow"
                    :connection-target="
                      enableConnectionTargets &&
                      connectionTargetsIds.includes(node.id)
                    "
                    class="bpmn-event-container"
                    @move-right="$emit('move-right')"
                    @move-left="$emit('move-left')"
                  ></bpmn-event>
                </template>

                <template v-else>
                  <bpmn-gateway
                    :key="node.id + '60'"
                    :node="node"
                    :svgGraph="svgGraph"
                    :svgLanes="svgLanes"
                    :svgGroups="svgGroups"
                    :workflow="workflow"
                    :connection-target="
                      enableConnectionTargets &&
                      connectionTargetsIds.includes(node.id)
                    "
                    :eventDefinition="workflow.eventDefinition"
                    @move-right="$emit('move-right')"
                    @move-left="$emit('move-left')"
                  ></bpmn-gateway>
                </template>
                <foreignObject
                  :key="node.id + 'star'"
                  v-if="node.dataModels?.length > 0"
                  :x="node.x"
                  :y="node.y - 5"
                  width="16"
                  height="16"
                >
                <v-tooltip location="right" open-delay="1000">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" size="x-small" icon="mdi-star"></v-icon>
                  </template>
                  <span>
                    This event has data models connected to it
                  </span>
                </v-tooltip>
                </foreignObject>

                <!-- button menu on selected event or exclusive gateway -->
                <!--
                as for vue v2.6.11:
                Slots do not render in foreignObjects if the foreignObject
                is not in the template of the same component that has
                the root svg element of the svg. Means you can't split svg
                into components properly.

                https://github.com/vuejs/vue/issues/11315
                https://github.com/vuejs/vue/pull/11349

              must be fixed in vue v2.6.13
              -->
              <!-- colour badges on events and exclusive gateways -->
              <foreignObject
                :key="node.id + 'badge'"
                v-if="diagramRequirementTypes?.length > 0"
                v-show="!dragging"
                :x="node.x - 8"
                :y="computeRequirementsSummaryYPosition(node)"
                width="116"
                height="16"
              >
                <requirements-dialog
                  v-if="eventsById[node.id]"
                  :node="eventsById[node.id]"
                  :types-to-display-in-diagram="diagramRequirementTypes"
                  :cards="cardsByEventId[node.id]"
                  :show-done="showDone"
                  @add-card-ai="addCardAI"
                  @ask-for-reply="onAskForReply"
                >
                  <template #default="{ props, openDialog, pinTheDialog }">
                    <v-layout v-bind="props" class="align-center justify-center card-badges-container">
                        <v-avatar
                          v-for="(cardType, index) in diagramRequirementTypes"
                          :key="cardType.id"
                          :class="{
                            'd-none':
                              !(cardCount(svgGraph[node.id], cardType) > 0) &&
                              $route.params.eventId != node.id,
                            'ml-1': index !== 0,
                            'requirement-badge': true
                          }"
                          :color="getBackgroundColour(cardType)"
                          @mouseover.stop="delay(openDialog)"
                          @mouseout.stop="resetDelay()"
                          @click="pinTheDialog()"
                          size="16"
                        >
                          <span
                            class="requirement-badge-number"
                            :style="'color: ' + getTextColour(cardType)"
                          >
                            {{ cardCount(svgGraph[node.id], cardType) }}
                          </span>
                        </v-avatar>
                    </v-layout>
                  </template>
                </requirements-dialog>
              </foreignObject>
            </template>

              <!-- definitions of arrow -->
              <defs>
                <marker
                  id="sequenceflow-end-white-black-1r79xjtcycpwpzz6dais2xasb"
                  viewBox="0 0 20 20"
                  refX="11"
                  refY="10"
                  markerWidth="10"
                  markerHeight="10"
                  orient="auto"
                >
                  <path
                    d="M 1 5 L 11 10 L 1 15 Z"
                    style="
                      fill: rgb(128,128,128);
                      stroke-width: 1px;
                      stroke-linecap: round;
                      stroke-dasharray: 10000, 1;
                      stroke: rgb(128,128,128);
                    "
                  ></path>
                </marker>
              </defs>

              <g v-for="group of Object.values(svgGroups).slice(1)" :key="group.id">
                <path
                  :d="`M ${group.x} 0 V 999999`"
                  style="stroke: rgb(170, 170, 170); stroke-width: 1px;"
                ></path>
                <path
                  v-show="dragging && group.id === this.selectedElement?.id"
                  :d="`M ${groupDragX} 0 V 999999`"
                  style="stroke: rgb(170, 170, 170); stroke-width: 2px;"
                ></path>
                <rect
                  :data-test="`group-divider-${group.name}`"
                  :id="group.id"
                  :x="group.x - 4" y="0"
                  width="8" height="999999"
                  :class="group.startSlotX === 0 ? 'notDraggable' : 'draggable horizontal'"
                  :fill="dragging && group.id === this.selectedElement?.id ? 'rgb(46, 156, 230)' : 'transparent'"
                  stroke="transparent"
                >
                </rect>
              </g>
            </svg>
          </svg>
          <!-- menus located outside the svg so they can be rendered outside the borders -->
          <div
            v-if="
              $route.params.eventId &&
              svgGraph?.[$route.params.eventId] &&
              !$store.state.isWorkflowDisabled
            "
            v-show="!dragging"
            :style="`
            position: absolute;
            left: ${svgGraph[$route.params.eventId].x + 24}px;
            top: ${svgGraph[$route.params.eventId].y - 25 + diagramYOffset}px;
            height: 21;
          `"
            class="text-left"
          >
            <bpmn-diagram-actions-shape-top
              :node="svgGraph[$route.params.eventId]"
              :events="events"
              :baseData="baseData"
              :svgGraph="svgGraph"
              :svgGroups="svgGroups"
              :svgLanes="svgLanes"
              :workflow="workflow"
              :color="
                svgGraph[$route.params.eventId].color
                  ? svgGraph[$route.params.eventId].color.rgb
                  : workflow.eventDefinition.color.rgb
              "
              @change-shape="showTransformOptions = !showTransformOptions"
            ></bpmn-diagram-actions-shape-top>
          </div>

          <div
            v-if="
              $route.params.eventId &&
              svgGraph?.[$route.params.eventId] &&
              !$store.state.isWorkflowDisabled
            "
            v-show="!dragging"
            :style="`
            position: absolute;
            left: ${svgGraph?.[$route.params.eventId]?.x + 105}px;
            top: ${svgGraph?.[$route.params.eventId]?.y - 1 + diagramYOffset}px;
            width: 22;
            height: 105;
          `"
          >
            <bpmn-diagram-actions-shape-right
              v-if="svgGraph[$route.params.eventId]"
              :selectedEvent="svgGraph[$route.params.eventId]"
              :baseData="baseData"
              @add-shape="addShape"
              :svgGraph="svgGraph"
              :svgGroups="svgGroups"
              :svgLanes="svgLanes"
            ></bpmn-diagram-actions-shape-right>
          </div>

          <!-- Subprocess icon -->
          <div
            v-for="node in subprocesses"
            :key="node.id + '-subprocess'"
            width="14"
            height="14"
            style="line-height: 1em; font-size: 0"
            class="subprocess-icon"
            :style="`
            position: absolute;
            left: ${svgGraph?.[node.id]?.x + 43}px;
            top: ${svgGraph?.[node.id]?.y + 62 + diagramYOffset}px;
            width: 14px;
            height: 14px;
          `"
          >
            <v-tooltip location="top" open-delay="1000">
              <template v-slot:activator="{ on, props }">
                <router-link
                  :to="
                    '/workflow/' +
                    $route.params.projectId +
                    '/' +
                    node.subprocess.id
                  "
                  target="_blank"
                >
                  <v-icon v-bind="props" size="20" v-on="{ ...on }">custom:SubprocessIcon</v-icon>
                </router-link>
              </template>
              <span>Open subprocess: {{ node.subprocess.name }}</span>
            </v-tooltip>
          </div>
        </div>

        <div
          class="context-menu-top"
          v-if="svgGraph?.[$route.params.eventId]?.x && showTransformOptions"
          :style="`
            position: absolute;
            left: ${
              svgGraph?.[$route.params.eventId]?.x +
              56 -
              bpmnScrollLeft
            }px;
            top: ${svgGraph?.[$route.params.eventId]?.y - 48 - scrollTop + diagramYOffset}px;
            width: 70;
            height: 21;
          `"
          v-show="!dragging"
        >
          <bpmn-diagram-actions-shape-top-transform
            class="dark position-fixed"
            :selectedEvent="svgGraph[$route.params.eventId]"
            :baseData="baseData"
            :svgGraph="svgGraph"
            :svgGroups="svgGroups"
            :svgLanes="svgLanes"
            @transform-shape="hideTransformOptions"
            v-click-outside="closeTransformOptions()"
          />
        </div>

        <!-- Swimlane titles -->
        <div
          v-for="(lane, name, index) in svgLanes"
          :key="index"
          :style="`
            width: 30px;
            position: absolute;
            left: ${lane.x}px;
            top: ${lane.y + diagramYOffset}px;
            height: ${lane.height}px;
          `"
        >
          <!-- editable Lane name (rotated) -->
          <div class="lane-title-container position-relative">
            <bpmn-swim-lane-title
              :first="index === 0 && !hasGroups"
              :last="index === noOfLanes - 1"
              :lane="lane"
              :index="index"
              :color="workflow.roleDefinition.color.rgb"
              :svgGraph="svgGraph"
              :svgLanes="svgLanes"
              :svgGroups="svgGroups"
              :workflow="workflow"
              @name-blur="blurOnRoleName"
            ></bpmn-swim-lane-title>

            <v-tooltip location="top" v-if="!$store.state.isWorkflowDisabled" open-delay="1000">
              <template v-slot:activator="{ on, props }">
                <div
                  :ref="`delete-role-button-${workflow.lanes?.[index]?.id}`"
                  class="delete-role-button"
                  @click="
                    deleteLane(
                      workflow.lanes[index] && workflow.lanes[index].id,
                      index
                    )
                  "
                >
                  <button class="captures-description">
                    <v-icon v-bind="props" class="delete-lane-icon" v-on="{ ...on }"
                      >mdi-delete-outline</v-icon
                    >
                  </button>
                </div>
              </template>
              <span>Delete this role</span>
            </v-tooltip>
          </div>
        </div>
      </div>

      <bpmn-diagram-actions-bottom
        v-if="events"
        :selectedEvent="svgGraphData[$route.params.eventId]"
        :requirement-types="diagramRequirementTypes"
        :base-data="baseData"
        :svgGraph="svgGraph"
        :svgGroups="svgGroups"
        :svgLanes="svgLanes"
        @add-shape="addShape"
        @add-shape-ai="addShapeAI"
        @add-group="addGroup"
      ></bpmn-diagram-actions-bottom>
    </v-card>

    <diagram-legend
      :show-legend="showLegend"
      :card-types="diagramRequirementTypes"
      :event-definition="workflow.eventDefinition"
      :role-definition="workflow.roleDefinition"
      @show-legend="showLegend = true"
      @hide-legend="showLegend = false"
    ></diagram-legend>
    <bpmn-generator
      :bpm="thisComponent()"
      :workflow="workflow"
      :workflowTasks="events ? events : {}"
      ref="generator"
    />
  </div>
</template>

<script>
import BpmnEvent from '@/components/BpmnEvent'
import BpmnGateway from '@/components/BpmnGateway'
import BpmnDiagramActionsShapeTop from '@/components/BpmnDiagramActionsShapeTop'
import BpmnDiagramActionsShapeRight from '@/components/BpmnDiagramActionsShapeRight'
import BpmnDiagramActionsBottom from '@/components/BpmnDiagramActionsBottom'
import BpmnSwimLaneTitle from '@/components/BpmnSwimLaneTitle'
import RequirementsDialog from '@/components/RequirementsDialog'
import BpmnDiagramActionsShapeTopTransform from '@/components/BpmnDiagramActionsShapeTopTransform'
import BpmnEventLabel from '@/components/BpmnEventLabel'
// import BpmnEventLabelVertical from '@/components/BpmnEventLabelVertical'
import DiagramLegend from '@/components/DiagramLegend'
import workflowApi from '@/api/workflowApi'
import { workflowApiHelpers } from '@/api/workflowApiHelpers'
import requirementTypesApi from '@/api/requirementTypesApi'
import { diagramHelpers } from '@/mixins/diagramHelpers.js'
import { EventBus } from '@/event-bus/event-bus.js'
import BpmnGenerator from '@/components/BpmnGenerator'
import { mapState, mapGetters, mapMutations } from 'vuex'
import randomUUID from '@/utils/uuid'
import dragFunctions from '@/helpers/dragFunctions'
import positionHelpers from '@/helpers/positionHelpers'
import dataHelpers from '@/helpers/dataHelpers'

export default {
  name: 'Bpmn',
  components: {
    BpmnEvent,
    BpmnEventLabel,
    BpmnGateway,
    BpmnDiagramActionsShapeTop,
    BpmnDiagramActionsShapeRight,
    BpmnDiagramActionsBottom,
    BpmnSwimLaneTitle,
    RequirementsDialog,
    BpmnDiagramActionsShapeTopTransform,
    DiagramLegend,
    BpmnGenerator
  },
  data () {
    return {
      aiGenerator: false,
      selectedElement: null,
      timeout: null,
      dragOffsetX: null,
      dragOffsetY: null,
      startDragX: null,
      startDragY: null,
      slotX: 0,
      watchInPause: false,
      svgGraph: null,
      svgLanes: null,
      cardsByEventId: {},
      svgGroups: {},
      // savingChanges: false,
      dragging: false,
      enableConnectionTargets: false,
      showTransformOptions: false,
      showLegend: false,
      triggerUpdateFromDrag: false,
      autoscroll: null,
      clientY: null,
      scrollTop: null,
      scrollLeft: null
    }
  },
  props: [
    'showDone'
  ],
  mixins: [diagramHelpers],
  computed: {
    ...mapGetters({
      baseData: 'svgGraphData/baseData',
      svgLanesData: 'svgGraphData/svgLanesData',
      svgGraphData: 'svgGraphData/svgGraphData',
      svgGroupsData: 'svgGraphData/svgGroupsData',
      diagramWidth: 'synchronizedScrolling/diagramWidth'
    }),
    ...mapState({
      workflow: state => state.workflow.workflow,
      requirementTypes: state => state.requirementTypes.requirementTypes,
      events: (state) => state.workflow.workflow.eventsJson || [],
      showReleaseNo: state => state.workflow.showReleaseNo,
      bpmnScrollLeft: state => state.synchronizedScrolling.bpmnScrollLeft
    }),
    diagramRequirementTypes () {
      return this.requirementTypes?.filter(({ showInDiagram }) => showInDiagram)
    },
    hasGroups () {
      return Object.keys(this.svgGroups)?.length > 0
    },
    diagramYOffset () {
      return this.hasGroups ? 30 : 0
    },
    flowChartHeight () {
      let res = this.diagramYOffset
      for (const lane in this.svgLanes) {
        res = res + this.svgLanes[lane].height
      }
      return res
    },
    noOfLanes () {
      return Object.keys(this.svgLanes).length
    },
    eventsById () {
      if (!this.events) { return {} }
      return this.events.reduce((accum, curr) => {
        accum[curr.id] = curr
        return accum
      }, {})
    },
    listValidTargetsForSequenceArrows () {
      const { eventId } = this.$route.params

      if (!eventId || !Array.isArray(this.events)) {
        return []
      }

      const res = this.events.filter(wt =>
        (wt.id !== eventId) &&
        (!wt.parents || !wt.parents.find(parent => parent === eventId))
      )
      return res
    },
    connectionTargetsIds () {
      return this.listValidTargetsForSequenceArrows.map(({ id }) => id)
    },
    subprocesses () {
      return this.events ? this.events.filter(event => event.subprocess) : []
    },
    groupDragX () {
      return positionHelpers.getGroupXPointFromSlot(this.slotX)
    },
    slotToGroupMap () {
      const groups = Object.values(this.svgGroups)
      if (groups?.length > 0) {
        return dataHelpers.slotToGroupMap(groups, this.baseData.columns.length)
      } else {
        return {}
      }
    }
  },
  methods: {
    ...mapMutations({
      setBpmnScrollLeft: 'synchronizedScrolling/setBpmnScrollLeft',
      setSvgContainerWidth: 'synchronizedScrolling/setSvgContainerWidth'
    }),
    getXPointFromGroup (group) {
      return positionHelpers.getGroupXPointFromSlot(group.startSlotX)
    },
    openAiGenerator () {
      this.$refs.generator.openSettings()
    },
    onAskForReply (event) {
      this.$refs.generator.addReplyToDiagram(event)
    },
    thisComponent () {
      return this
    },
    mouseEnterLane (lane) {
      if (lane.emptyLane) {
        if (this.$refs[`delete-role-button-${lane.id}`] && this.$refs[`delete-role-button-${lane.id}`][0]) {
          this.$refs[`delete-role-button-${lane.id}`][0].style.display = 'block'
        }
      }
    },
    mouseLeaveLane (event, lane) {
      if (!(event.relatedTarget?.classList?.contains('delete-lane-icon'))) {
        if (this.$refs[`delete-role-button-${lane.id}`] && this.$refs[`delete-role-button-${lane.id}`][0]) {
          this.$refs[`delete-role-button-${lane.id}`][0].style.display = 'none'
        }
      }
    },
    resetDiagram () {
      this.endDrag(false)
      this.svgGraph = JSON.parse(JSON.stringify(this.svgGraphData))
      this.svgLanes = JSON.parse(JSON.stringify(this.svgLanesData))
      this.svgGroups = JSON.parse(JSON.stringify(this.svgGroupsData))

      this.$store.commit('updateSvgGraph', JSON.parse(JSON.stringify(this.svgGraphData)))
      this.$store.commit('updateSvgLanes', JSON.parse(JSON.stringify(this.svgLanesData)))
    },
    findCollisionWrapper (svgGraph, node) {
      return workflowApiHelpers.findCollision(svgGraph, node)
    },
    cardCount (node, cardType) {
      const count = node.requirementsJson?.filter((req) => {
        return !req.done &&
          req.cardType &&
          req.cardType.id === cardType.id &&
          (!this.showReleaseNo || this.showReleaseNo?.length === 0 || this.showReleaseNo?.includes(req.releaseNo))
      }).length
      return count || ''
    },
    computeCardsByEventId () {
      if (!this.events) { return {} }
      return this.events.reduce((accum, event) => {
        accum[event.id] = event.requirementsJson?.filter((req) =>
          !this.showReleaseNo ||
          this.showReleaseNo.length === 0 ||
          this.showReleaseNo.includes(req.releaseNo)
        ) || []
        return accum
      }, {})
    },
    delay (callback) {
      this.timeout = setTimeout(callback, 500)
    },
    resetDelay () {
      clearTimeout(this.timeout)
    },
    getBackgroundColour (cardType) {
      return requirementTypesApi.getBackgroundColour(cardType)
    },
    getTextColour (cardType) {
      return requirementTypesApi.getTextColour(cardType)
    },
    connectionHasLabel (parentId, childId) {
      const xDistance = this.svgGraph[childId].x - this.svgGraph[parentId].x
      if (xDistance > 160) {
        if (this.svgGraph[parentId].type === 'bpmn:ExclusiveGateway') {
          return true
        }
      }
      return false
    },
    createBackwardConnection (parentId, childId) {
      let ySegment3
      const xStart = this.svgGraph[parentId].x + 100 // add the size of the shape
      const yStart = this.svgGraph[parentId].y + 44
      const xEnd = this.svgGraph[childId].x
      const yEnd = this.svgGraph[childId].y + 44 // Convert shape position coordinate of path
      const xSegment2 = xStart + 26
      if (yStart > yEnd) {
        ySegment3 = yStart + 51
      } else {
        ySegment3 = yEnd + 51
      }
      const xSegment4 = xEnd - 26
      const ySegment5 = yEnd
      const radius = Math.abs(this.getRadius(xStart, xEnd))
      const marginParent = this.svgGraph[parentId].margin
      const marginChild = this.svgGraph[childId].margin
      const d = 'M' + (xStart - marginParent) + ',' + yStart +
        ' L' + (xSegment2 - radius) + ',' + yStart +
        ' Q' + xSegment2 + ',' + yStart + ',' + xSegment2 + ',' + (yStart + radius) +
        ' L' + xSegment2 + ',' + (ySegment3 - radius) +
        ' Q' + xSegment2 + ',' + ySegment3 + ',' + (xSegment2 - radius) + ',' + ySegment3 +
        ' L' + (xSegment4 + radius) + ',' + ySegment3 +
        ' Q' + xSegment4 + ',' + ySegment3 + ',' + xSegment4 + ',' + (ySegment3 - radius) +
        ' L' + xSegment4 + ',' + (ySegment5 + radius) +
        ' Q' + xSegment4 + ',' + ySegment5 + ',' + (xSegment4 + radius) + ',' + ySegment5 +
        ' L' + (xEnd + marginChild) + ',' + ySegment5
      return d
    },
    createForwardConnection (parentId, childId) {
      let verticalOffset, xSegment2
      if (this.svgGraph[parentId].y < this.svgGraph[childId].y) {
        verticalOffset = 15 - Math.min((5 * this.svgGraph[parentId].y / 140), 30)
      } else {
        verticalOffset = -15 + Math.min((5 * this.svgGraph[parentId].y / 140), 30)
      }
      const xStart = this.svgGraph[parentId].x + 100 // add the size of the shape
      const yStart = this.svgGraph[parentId].y + 40
      const xEnd = this.svgGraph[childId].x
      const yEnd = this.svgGraph[childId].y + 40 // Convert shape position coordinate of path
      if (this.svgGraph[parentId].type === 'bpmn:ExclusiveGateway') {
        xSegment2 = xStart + 30
      } else {
        xSegment2 = xEnd - 30
      }
      const radius = this.getRadius(yStart, yEnd)
      const marginParent = this.svgGraph[parentId].margin
      const marginChild = this.svgGraph[childId].margin
      // forward connection
      const d = 'M' + (xStart - marginParent) + ',' + yStart +
        ' L' + (xSegment2 - Math.abs(radius) + verticalOffset) + ',' + yStart +
        ' Q' + (xSegment2 + verticalOffset) + ',' + yStart + ',' + (xSegment2 + verticalOffset) + ',' + (yStart + radius) +
        ' L' + (xSegment2 + verticalOffset) + ',' + (yEnd - radius) +
        ' Q' + (xSegment2 + verticalOffset) + ',' + yEnd + ',' + (xSegment2 + verticalOffset + Math.abs(radius)) + ',' + yEnd +
        ' L' + (xEnd + marginChild) + ',' + yEnd
      return d
    },
    createPathBetweenNodes (parentId, childId) {
      const xDistance = this.svgGraph[childId].x - this.svgGraph[parentId].x
      if (xDistance > 140) {
        return this.createForwardConnection(parentId, childId)
      } else if (xDistance === 0) {
        return this.createVerticalConnection(parentId, childId)
        // } else if (xDistance < 0) {
      } else if (xDistance < 0 && childId !== this.selectedElement?.id && parentId !== this.selectedElement?.id) {
        return this.createBackwardConnection(parentId, childId)
      } else {
        return this.createStraightLine(parentId, childId)
      }
    },
    createStraightLine (parentId, childId) {
      const xStart = this.svgGraph[parentId].x + 100 // add the size of the shape
      const yStart = this.svgGraph[parentId].y + 40
      const xEnd = this.svgGraph[childId].x
      const yEnd = this.svgGraph[childId].y + 40 // Convert shape position coordinate of path
      const marginParent = this.svgGraph[parentId].margin
      const marginChild = this.svgGraph[childId].margin
      const d = 'M' + (xStart - marginParent) + ',' + yStart +
        ' L' + (xEnd + marginChild) + ',' + yEnd
      return d
    },
    createVerticalConnection (parentId, childId) {
      const direction = Math.sign(this.svgGraph[childId].y - this.svgGraph[parentId].y)
      const xStart = this.svgGraph[parentId].x + 50 // add half the size of the shape
      const yStart = this.svgGraph[parentId].y + 40 + 40 * direction - (this.svgGraph[parentId].margin * direction / 2) // top or bottom
      const xEnd = this.svgGraph[childId].x + 50
      const yEnd = this.svgGraph[childId].y + 40 - 40 * direction + (this.svgGraph[childId].margin * direction / 2)
      const d = 'M' + xStart + ',' + yStart +
        ' L' + (xEnd) + ',' + yEnd
      return d
    },
    getRadius (yStart, yEnd) {
      const sign = Math.sign(yEnd - yStart)
      const res = Math.min(Math.abs(10 * Math.sign(yStart - yEnd)), Math.abs(yStart - yEnd) / 2)
      return res * sign
    },
    async addShapeAI (type, parentId = 'start', description = null, laneName = null) {
      this.$refs.generator.addShapeToDiagram(parentId)
    },
    async addCardAI (eventId, cardTypeId) {
      this.$refs.generator.addCardToDiagram(eventId, cardTypeId)
    },
    findLaneIdForNewShape (laneName, lanes, events) {
      if (!lanes?.[0]?.id) {
        // no lanes
        return null
      }
      if (laneName) {
        // lookup lane id by name
        const lane = lanes.find(lane => lane.name === laneName)
        return lane ? lane.id : null
      }
      if (events?.length > 0) {
        // return the laneId of the selected event
        return this.svgGraph[this.$route.params.eventId]?.laneId
      }
      // empty diagram, use the first lane
      return lanes[0].id
    },
    async addShape (shape = {}, options = {}) {
      // use nextTick to wait for blur events to complete first to update descriptions or labels
      await this.$nextTick()
      let {
        id,
        type = 'bpmn:Task',
        parents = [this.$route.params.eventId || 'start'],
        description = (type === 'bpmn:Task'
          ? workflowApiHelpers.getNextDescription(this.workflow.eventsJson)
          : workflowApiHelpers.getNextDecisionDescription(this.workflow.eventsJson)),
        laneId = options.createNewLaneIfNoLaneId ? null : this.svgGraph[this.$route.params.eventId]?.laneId,
        subprocess = null,
        color,
        dataModels,
        requirementsJson,
        conditionLabels
      } = shape
      if (parents[0] !== 'start' && this.workflow?.eventsJson?.findIndex(event => event.id === parents[0]) === -1) {
        // prevent double click on the same event, not necessary check but makes frontend nicer
        return
      }
      if ((!options.createNewLaneIfNoLaneId && !laneId) || options.laneName) {
        laneId = this.findLaneIdForNewShape(options.laneName, this.workflow.lanes, this.events)
      }
      const newEventId = await workflowApi.addEventToWorkflow(
        { id, type, parents, description, laneId, subprocess, color, dataModels, requirementsJson, conditionLabels },
        options,
        this.svgLanes,
        this.svgGraph,
        this.svgGroups,
        this.workflow
      )
      this.$router.push({ path: '/workflow/' + this.workflow.projectId + '/' + this.workflow.id + '/' + newEventId }).catch(() => { })
    },
    addGroup (anchorEventId) {
      let startSlotX = 0
      const groupArray = Object.values(this.svgGroups)
      if (this.hasGroups) {
        const columnCount = this.baseData.columns.length
        const lengthArray = groupArray.map(group => group.startSlotX)
        const lastGroup = Math.max(...lengthArray)
        startSlotX = Math.max(lastGroup + 1, columnCount)
      }

      const group = {
        id: randomUUID(),
        name: 'Group ' + (groupArray.length + 1),
        startSlotX,
        x: positionHelpers.getGroupXPointFromSlot(startSlotX),
        width: -1
      }
      this.svgGroups[group.id] = group

      workflowApi.updateWorkflow({
        svgGraph: this.svgGraph,
        svgLanes: this.svgLanes,
        svgGroups: this.svgGroups,
        workflow: this.workflow,
        updateNote: {
          action: 'added group',
          target: group.name
        }
      })
    },
    updateGroupName (event, group) {
      const { textContent: newValue } = event.target
      this.svgGroups[group.id].name = newValue
      workflowApi.updateWorkflow({
        svgGraph: this.svgGraph,
        svgGroups: this.svgGroups,
        workflow: this.workflow,
        updateNote: {
          action: 'changed name of group',
          target: group.name
        }
      })
    },
    deleteGroup (group) {
      workflowApi.deleteGroup(group, this.svgGraph, this.svgLanes, this.svgGroups, this.workflow)
    },
    deleteLane (id, index) {
      const name = this.svgLanes[id].name
      delete this.svgLanes[id]
      workflowApi.updateWorkflow({
        svgLanes: this.svgLanes,
        svgGraph: this.svgGraph,
        svgGroups: this.svgGroups,
        workflow: this.workflow,
        updateNote: {
          action: 'deleted lane',
          target: name
        }
      })
    },
    enableAutoscroll (event) {
      this.clientY = event.clientY // save starting pos
      this.autoscroll = setInterval(() => {
        if (this.dragging) {
          if (this.clientY < 93 && this.selectedElement.y > 0) {
            // autoscroll up
            window.scrollBy(0, -5)
            this.scrollTop -= 5
            this.selectedElement.y -= 5
          } else {
            // autoscroll down
            const boundingRect = document.getElementById('flowchart')?.getBoundingClientRect()
            if (boundingRect) {
              const bottomOfSvg = boundingRect.y + boundingRect.height
              if ((window.innerHeight - this.clientY) < 93 && (bottomOfSvg - this.clientY) > 30) {
                window.scrollBy(0, 5)
                this.scrollTop += 5
                this.selectedElement.y += 5
              }
            }
          }
        }
      }, 20)
    },
    disableAutoscroll () {
      clearInterval(this.autoscroll)
    },
    updateAutoscrollParameters (event) {
      this.clientY = event.clientY
    },
    startDrag (event) {
      if (!this.selectedElement && !this.$store.state.isWorkflowDisabled) {
        const targetId = event.target.id.replace('p-', '')
        const isDraggable = event.target.classList.contains('draggable')
        if (isDraggable) {
          if (this.svgGraph[targetId]) {
            this.selectedElement = this.svgGraph[targetId]
            document.getElementById(this.selectedElement.id).removeAttribute('data-test-slot')
          }

          if (this.svgGroups[targetId]) {
            this.selectedElement = this.svgGroups[targetId]
          }

          if (this.selectedElement) {
            this.dragging = true
            this.dragOffsetX = event.pageX - this.selectedElement.x
            this.dragOffsetY = event.pageY - this.selectedElement.y
            this.startDragX = event.pageX
            this.startDragY = event.pageY
            this.watchInPause = true
            this.slotX = 0
            if (this.svgGraph[this.selectedElement.id]) {
              this.slotX = positionHelpers.getSlotXFromId(this.selectedElement.id, this.svgGraph)
            } else if (this.svgGroups[this.selectedElement.id]) {
              this.slotX = this.svgGroups[this.selectedElement.id].startSlotX
            }
            this.triggerUpdateFromDrag = false
            this.currentScrollLeft = this.$refs.scrollContainer.scrollLeft
            this.disableNormalScroll()
            this.enableAutoscroll(event)
          }
        }
      }
    },
    endDrag (update = true) {
      this.disableAutoscroll()
      this.enableNormalScroll()
      if (this.selectedElement) {
        if (this.svgGraph[this.selectedElement.id]) {
          // dragging a shape
          this.snapToGrid(this.selectedElement)
          const yMovement = Math.abs(this.dragOffsetY - this.startDragY + this.selectedElement.y)
          document.getElementById(this.selectedElement.id).removeAttribute('data-test-slot')
          if (update && (this.triggerUpdateFromDrag || yMovement > 0)) {
            workflowApi.updateWorkflow({
              svgGraph: this.svgGraph,
              svgLanes: this.svgLanes,
              svgGroups: this.svgGroups,
              workflow: this.workflow,
              updateNote: {
                action: this.selectedElement.type === 'bpmn:ExclusiveGateway' ? 'moved decision' : 'moved event',
                target: this.selectedElement.name
              }
            })
          }
        }

        if (this.svgGroups[this.selectedElement.id]) {
          // dragging a group divider
          this.svgGroups[this.selectedElement.id].startSlotX = this.slotX
          this.selectedElement.x = positionHelpers.getGroupXPointFromSlot(this.slotX)
          workflowApi.updateGroupStartingPosition(this.selectedElement.id, this.svgGraph, this.svgLanes, this.svgGroups, this.workflow)
        }
      }
      this.selectedElement = null
      this.dragging = false
      this.dragOffsetX = null
      this.dragOffsetY = null
      this.lastComittedSlotX = null
      this.triggerUpdateFromDrag = false
      this.currentScrollLeft = 0
    },
    drag (event) {
      this.clientY = event.offsetY
      this.screenY = event.screenY
      if (this.selectedElement) {
        this.updateAutoscrollParameters(event)
        this.watchInPause = false
        if (this.svgGraph[this.selectedElement.id]) {
          // Dragging an event
          this.selectedElement.x = event.pageX - this.dragOffsetX + (this.$refs.scrollContainer.scrollLeft - this.currentScrollLeft)
          this.selectedElement.y = event.pageY - this.dragOffsetY
          const { slotX } = positionHelpers.getSlotsFromNode(this.selectedElement, this.svgGraph)
          if (
            (
              slotX === (this.slotX + 1) &&
              (
                this.hasSlotToTheRight(this.selectedElement) ||
                !this.selectedElement.childIds?.length > 0 ||
                this.hasGroups
              )
            ) ||
            (
              slotX === (this.slotX - 1) &&
              this.hasSlotToTheLeft(this.selectedElement)
            )
          ) {
            this.slotX = slotX
            this.triggerUpdateFromDrag = true // changed slot
          }
        } else if (this.svgGroups[this.selectedElement.id]) {
          // Dragging a group
          const previousGroup = this.workflow.groups[this.selectedElement.index - 1]
          const maxLeft = 50 + (previousGroup.startSlotX + 1) * 160
          const nextGroup = this.workflow.groups[this.selectedElement.index + 1]
          const maxRight = 50 + (nextGroup ? (nextGroup.startSlotX - 1) : 9999) * 160
          const pointX = event.pageX - this.dragOffsetX + (this.$refs.scrollContainer.scrollLeft - this.currentScrollLeft)
          this.selectedElement.x = Math.min(Math.max(maxLeft, pointX), maxRight)
          this.selectedElement.y = event.pageY - this.dragOffsetY
          const slotX = positionHelpers.getSlotXFromPoint(this.selectedElement.x)
          if (slotX !== this.slotX) {
            this.slotX = slotX
            this.triggerUpdateFromDrag = true // changed slot
            this.svgGroups[this.selectedElement.id].startSlotX = this.slotX
            this.svgGroups[this.selectedElement.id].width = nextGroup ? (nextGroup.startSlotX - this.slotX) * 160 : -1
            this.svgGroups[previousGroup.id].width = (this.slotX - previousGroup.startSlotX) * 160 + (previousGroup.startSlotX === 0 ? 50 : 0)
          }
        }
      }
    },
    getYPointFromSlot (slot) {
      return 30 + 140 * slot
    },
    hasSlotToTheLeft (selectedElement) {
      const isStartingPoint = (selectedElement.parents || []).findIndex(id => id === 'start') >= 0
      if (!isStartingPoint &&
        selectedElement.parents.findIndex(parentId => positionHelpers.getSlotXFromId(parentId, this.svgGraph) <= this.slotX) >= 0) {
        return true
      } else if (selectedElement.groupId && this.svgGroups[selectedElement.groupId].slotX !== 0) {
        return true
      } else {
        return false
      }
    },
    hasSlotToTheRight (selectedElement) {
      if (selectedElement.childIds?.length > 0 &&
        selectedElement.childIds.findIndex(childId => positionHelpers.getSlotXFromId(childId, this.svgGraph) >= this.slotX) >= 0) {
        return true
      } else {
        return false
      }
    },
    snapToGrid (draggedNode) {
      let lane
      // snap to grid x
      const isStartingPoint = (draggedNode.parents || []).findIndex(id => id === 'start') >= 0
      if (draggedNode.groupId && this.svgGroups[draggedNode.groupId]) {
        draggedNode.x = 80 + this.svgGroups[draggedNode.groupId].startSlotX * 160
      } else if (draggedNode.parents && draggedNode.parents[0] && !isStartingPoint) {
        draggedNode.x = this.svgGraph[draggedNode.parents[0]].x + 160
      } else if (isStartingPoint) {
        draggedNode.x = 80
      } else {
        if (draggedNode.childIds && draggedNode.childIds[0]) {
          draggedNode.x = this.svgGraph[draggedNode.childIds[0]].x - 160
        }
      }

      // find lane where dropped
      for (lane in this.svgLanes) {
        if ((draggedNode.y + 40) < (this.svgLanes[lane].y + this.svgLanes[lane].height)) {
          break
        }
      }
      // snap to grid y
      draggedNode.offset = Math.floor((draggedNode.y + 40 - this.svgLanes[lane].y) / 140)
      // prevent dropping outside lane
      if (draggedNode.offset < 0) {
        draggedNode.offset = 0
      }
      const laneHeightInOffsetUnits = (this.svgLanes[lane].height / 140) - 1
      if (draggedNode.offset > laneHeightInOffsetUnits) {
        draggedNode.offset = laneHeightInOffsetUnits
      }

      draggedNode.y = this.svgLanes[lane].y + 30 + draggedNode.offset * 140
      draggedNode.laneId = lane
      this.svgGraph[draggedNode.id] = draggedNode
      workflowApiHelpers.updateVerticalPositionsIfCollision(this.svgGraph, this.svgLanes, draggedNode.id, true)
    },
    disableNormalScroll () {
      // Get the current page scroll position
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop
      this.scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

      // if any scroll is attempted, set this to the previous value
      window.onscroll = () => {
        window.scrollTo(this.scrollLeft, this.scrollTop)
      }
    },
    enableNormalScroll () {
      // remove the eventhandler by assigning an empty function
      window.onscroll = function () { }
    },
    computeSwimlanePath (x, y, width, height, top) {
      const _width = top ? width - 10 : width
      const _height = top ? height - 10 : height
      const topRight = top ? 'q10,0 10,10' : ''
      return `M${x},${y}h${_width}${topRight}v${_height}h${-width}z`
    },
    computeRequirementsSummaryYPosition (node) {
      switch (node.type) {
        case 'bpmn:ExclusiveGateway': {
          const lastLane = !this.svgLanes[node.laneId].laneBelow
          const offset = lastLane ? 102 : 112

          return node.y + offset
        }
        case 'bpmn:Task':
        default:
          return node.y + 82
      }
    },
    closeTransformOptions () {
      return { handler: this.hideTransformOptions, include: this.getBodyIfSubworkflowDialogIsOpen }
    },
    getBodyIfSubworkflowDialogIsOpen () {
      if (document.querySelector('.selectSubworkflowDialog')) {
        return [document.querySelector('body')]
      } else {
        return []
      }
    },
    hideTransformOptions () {
      this.showTransformOptions = false
    },
    handleScroll (ev) {
      const scrollLeft = this.$refs.scrollContainer.scrollLeft
      this.setBpmnScrollLeft(scrollLeft)
    },
    handleWindowResize () {
      const svgContainerWidth = window.innerWidth - 80
      this.setSvgContainerWidth(svgContainerWidth)
    },
    shouldSwitchWithChild (newSlotX, oldSlotX, { childNode }, preserveOrder) {
      return (
        childNode &&
        newSlotX > oldSlotX &&
        positionHelpers.getSlotXFromId(childNode.id, this.svgGraph) === newSlotX &&
        !preserveOrder
      )
    },
    shouldSwitchWithParent (newSlotX, oldSlotX, { parentNode }, preserveOrder) {
      return (
        parentNode &&
        newSlotX < oldSlotX &&
        newSlotX === positionHelpers.getSlotXFromId(parentNode.id, this.svgGraph) &&
        !preserveOrder
      )
    },
    // set the attribute of a DOM element
    // for testing purposes
    setDOMAttribute (id, attribute, value) {
      document.getElementById(id).setAttribute(attribute, value)
    }
  },
  mounted () {
    this.handleWindowResize()
    window.addEventListener('resize', this.handleWindowResize)
    document.addEventListener('mouseup', this.endDrag)
    this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll)
    const svg = document.getElementById('flowchart')
    if (svg) {
      svg.addEventListener('mousedown', this.startDrag)
      svg.addEventListener('mousemove', this.drag)
      svg.addEventListener('mouseup', this.endDrag)
    }
    EventBus.$on('enable-connection-targets', val => {
      this.enableConnectionTargets = val
      if (val) {
        if (!this.onEscPressed) {
          this.onEscPressed = event => {
            EventBus.$emit('enable-connection-targets', false)
          }
          window.addEventListener('keyup', this.onEscPressed)
        }
      } else {
        if (this.onEscPressed) {
          window.removeEventListener('keyup', this.onEscPressed)
          delete this.onEscPressed
        }
      }
    })
    EventBus.$on('reset-diagram', () => {
      this.resetDiagram()
    })
    this.svgLanes = Object.assign({}, this.svgLanesData)
    this.resetDiagram()

    this.cardsByEventId = this.computeCardsByEventId()
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.handleWindowResize)
    document.removeEventListener('mouseup', this.endDrag)
    this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll)
    const svg = document.getElementById('flowchart')
    if (svg) {
      svg.removeEventListener('mousedown', this.startDrag)
      svg.removeEventListener('mousemove', this.drag)
      svg.removeEventListener('mouseup', this.endDrag)
    }
    if (this.onEscPressed) {
      window.removeEventListener('keyup', this.onEscPressed)
    }
  },

  watch: {
    svgGraphData: {
      handler: function (newVal, oldVal) {
        this.resetDiagram()
      },
      deep: true
    },
    svgLanesData: function (newLanesData, oldLanesData) {
      this.svgLanes = Object.assign({}, this.svgLanesData)
    },
    slotX: function (newSlotX, oldSlotX) {
      if (!this.watchInPause && this.svgGraph[this.selectedElement?.id]) {
        this.setDOMAttribute(this.selectedElement.id, 'data-test-slot', newSlotX)
        const involvedNodes = dragFunctions.getSurroundingNodes(this.selectedElement.id, this.svgGraph, this.svgGroups)
        // console.log('involvedNodes', involvedNodes)
        // console.log('newSlotX', newSlotX)
        // console.log('oldSlotX', oldSlotX)
        // console.log('this.svgGraph BEFORE', JSON.parse(JSON.stringify(this.svgGraph)))
        // console.log('this.svgGroups', JSON.parse(JSON.stringify(this.svgGroups)))
        let { switchedPlaces, preserveOrder } = dragFunctions.handleGroupSwitch(involvedNodes, newSlotX, oldSlotX, this.slotToGroupMap, this.svgGraph, this.svgGroups)
        // console.log('this.svgGraph AFTER', JSON.parse(JSON.stringify(this.svgGraph)))
        if (this.shouldSwitchWithChild(newSlotX, oldSlotX, involvedNodes, preserveOrder)) {
          // console.log('involvedNodes', involvedNodes)
          // console.log('this.svgGraph', JSON.parse(JSON.stringify(this.svgGraph)))
          // console.log('this.svgGroups', JSON.parse(JSON.stringify(this.svgGroups)))
          dragFunctions.switchCurrentNodeWithChild(involvedNodes, this.svgGraph, this.svgGroups) // dragging right
          // console.log('this.svgGraph AFTER', JSON.parse(JSON.stringify(this.svgGraph)))
          switchedPlaces = true
        } else if (this.shouldSwitchWithParent(newSlotX, oldSlotX, involvedNodes, preserveOrder)) {
          // console.log('involvedNodes', involvedNodes)
          // console.log('this.svgGraph', JSON.parse(JSON.stringify(this.svgGraph)))
          // console.log('this.svgGroups', JSON.parse(JSON.stringify(this.svgGroups)))
          dragFunctions.switchCurrentNodeWithParent(involvedNodes, this.svgGraph, this.svgGroups) // dragging left
          // console.log('this.svgGraph AFTER', JSON.parse(JSON.stringify(this.svgGraph)))
          switchedPlaces = true
        }
        if (switchedPlaces) {
          workflowApiHelpers.refreshDiagramPartially(this.svgGraph, this.svgLanes, this.svgGroups, involvedNodes, this.selectedElement)
        }
      }
    },
    disabled (newVal) {
      if (newVal) {
        const svg = document.getElementById('flowchart')
        svg.removeEventListener('mousedown', this.startDrag)
        svg.removeEventListener('mousemove', this.drag)
        svg.removeEventListener('mouseup', this.endDrag)
      } else {
        const svg = document.getElementById('flowchart')
        svg.addEventListener('mousedown', this.startDrag)
        svg.addEventListener('mousemove', this.drag)
        svg.addEventListener('mouseup', this.endDrag)
      }
    },
    $route: {
      handler: function (newRouteValue) {
        this.showTransformOptions = false
      },
      deep: true
    },
    bpmnScrollLeft (newVal) {
      // receive scroll event from the UserStoryMapping component
      this.$refs.scrollContainer.scrollLeft = newVal
    },
    events: {
      handler: function (newVal) {
        this.cardsByEventId = this.computeCardsByEventId()
      },
      deep: true
    }
  }
}

</script>

<style scoped>
svg {
  fill: #ffffff;
}

.canvas-container {
  overflow-y: auto;
  min-height: 140px;
}

.scroll-container {
  overflow-y: auto;
  min-height: 140px;
  position: relative;
}

.scroll-container::-webkit-scrollbar {
  height: 10px;
  visibility: visible;
}

.scroll-container::-webkit-scrollbar-thumb {
  height: 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: #aaa;
  background-origin: content-box;
}

.group {
  background-color: rgb(224, 224, 224);
  border: solid 1px rgb(170, 170, 170);
  padding: 4px 8px 3px 8px;
  text-align: center;
}

.group-first {
  border-top-left-radius: 10px;
}

.group-last {
  border-top-right-radius: 10px;
}

.v-tooltip__content {
  background: white;
  color: var(--color-primary);
  border-radius: 10px;
}

.v-tooltip__content code {
  background: var(--color-primary);
  color: white;
}

.requirement-badge {
  flex-shrink: 0;
}

.requirement-badge-number {
  font-size: 12px;
  line-height: 16px;
}

.requirement-section-header {
  display: flex;
  justify-content: space-between;
}

.requirement-section-header > *:first-child {
  flex-grow: 1;
  margin-right: 4px;
}

.requirement-section-header > * {
  padding: 2px 8px;
  border-radius: 6px;
}

.requirement-description {
  border-bottom: 1px solid #e3e3e3;
}

.bpmn-event-container:focus-within + .subprocess-icon {
  display: none;
}

.delete-role-button {
  position: absolute;
  left: 35px;
  top: 10px;
  display: none;
  cursor: pointer;
}

.lane-title-container {
  width: 100%;
  height: 100%;
}

.v-avatar {
  border-radius: 30% !important;
  position: static;
}
</style>

<style>
[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.diagram-container {
  margin-bottom: 0px;
  border-radius: 10px !important;
  overflow: visible;
  background: white;
  min-height: 176px;
}

.outlined {
  stroke-width: 0.5px !important;
}

#flowchart path {
  stroke: black;
  stroke-width: 2px;
  fill-opacity: 1;
}

#flowchart {
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.canvas-container {
  font-size: 14px;
}

.swim-lane {
  stroke: black;
  stroke-width: 2px;
  fill-opacity: 0;
}

/* .swim-lane:hover ~ .move-lane-down-button{
  display: block;
} */

.text-box {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  line-height: 1.3;
}

.text-cell {
  display: flex;
  text-align: center;
  width: 100%;
  max-height: 80px;
  color: black;
  font-family: Roboto, sans-serif;
}

.separator {
  height: 60px;
}

.create-first-event-button {
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none !important;
}

.draggable {
  cursor: move !important;
}

.draggable.horizontal {
  cursor: ew-resize !important;
  user-select: none;
}

.notDraggable {
  cursor: default !important;
}

rect:hover,
text:hover {
  cursor: pointer;
}

.diagram-actions {
  width: 100%;
  height: 36px;
  border-top: 1px solid #e3e3e3;
}

/* .add-button:first-child {
  margin-left: 30px;
  border-left: 1px solid #e3e3e3;
} */

.add-button {
  border-right: 1px solid #e3e3e3;
  padding: 0 10px;
}

.add-button:disabled {
  color: #9e9e9e;
}
.hidden {
  visibility: hidden;
}
svg .position-fixed {
  position: fixed;
}
svg a {
  position: fixed;
}

</style>
