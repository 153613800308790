<template>
  <v-layout class="d-flex flex-column">
    <v-row class="py-2 align-center flex-wrap">
      <v-col xs2 class="text-truncate type-title" v-if="!editMode" @click="editMode = (true && !$store.state.isWorkflowDisabled)" style="height: 45px;">
        {{ requirementType.title }}
      </v-col>

      <v-col xs2 class="d-flex align-center justify-center type-title-input-container flex-nowrap py-0" v-else>
        <input class="type-title-input justify-center flex-shrink-1" v-model="editTypeText" ref="titleInput" @keydown.27.stop="cancelEdit($event)"
          @keyup.enter.prevent.stop="$event.target.blur()" @blur="blurField($event)" :disabled="$store.state.isWorkflowDisabled" />

        <v-btn size="x-small" variant="text" icon class="ma-0 justify-center" :disabled="!editTypeText.trim().length || $store.state.isWorkflowDisabled" @click="applyTitleChange">
          <v-icon>mdi-check</v-icon>
        </v-btn>

        <v-btn size="x-small" variant="text" icon class="ma-0" @mousedown="cancelEdit($event)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>

      <v-col xs1 class="text-center py-0">
        <workflow-settings-requirement-type-color-picker :color="requirementType.color && requirementType.color.rgb"
          @change="changeColor" :disabled="$store.state.isWorkflowDisabled"></workflow-settings-requirement-type-color-picker>
      </v-col>

      <v-col xs2 class="d-flex justify-center py-0">
        <v-checkbox
          density="compact"
          class="justify-center ma-0 pa-0 type-checkbox-column"
          hide-details
          @click.capture="() => {}"
          :model-value="requirementType.showInDiagram"
          @update:model-value="toggleShowInDiagram" :disabled="$store.state.isWorkflowDisabled || requirementType.aggregateType || requirementType.triggerType"></v-checkbox>
      </v-col>

      <v-col xs3 class="d-flex justify-center py-0">
        <v-checkbox
          density="compact"
          class="ma-0 pa-0 type-checkbox-column"
          hide-details
          @click.capture="() => {}"
          :model-value="requirementType.showInBacklog"
          @update:model-value="toggleShowInBacklog"
          :disabled="$store.state.isWorkflowDisabled || requirementType.aggregateType || requirementType.triggerType"></v-checkbox>
      </v-col>

      <v-col xs1 class="d-flex justify-center py-0">
        <v-select
          :model-value="requirementType.aiCount || 0"
          variant="solo"
          flat
          hide-details
          density="compact"
          :items="requirementType.aggregateType || requirementType.triggerType ? [0, 1] : [0, 1, 2, 3]"
          @update:model-value="saveAiCount"
        >
          <template v-slot:append></template>
        </v-select>
      </v-col>

      <v-col xs1 class="d-flex justify-center py-0">
        <v-checkbox density="compact" class="ma-0 pa-0 type-checkbox-column" hide-details
          :model-value="requirementType.triggerType" @update:model-value="toggleTriggerType"
          false-icon="mdi-radiobox-blank" true-icon="mdi-radiobox-marked" :disabled="$store.state.isWorkflowDisabled"></v-checkbox>
      </v-col>

      <v-col xs1 class="d-flex justify-center py-0">
        <v-checkbox density="compact" class="ma-0 pa-0 type-checkbox-column" hide-details
          :model-value="requirementType.aggregateType" @update:model-value="toggleAggregateType"
          false-icon="mdi-radiobox-blank" true-icon="mdi-radiobox-marked" :disabled="$store.state.isWorkflowDisabled"></v-checkbox>
      </v-col>

      <v-col xs1 class="d-flex justify-end py-0">
        <v-checkbox v-if="requirementType.showInBacklog" density="compact" class="ma-0 pa-0 type-checkbox-column" hide-details
          :model-value="requirementType.defaultType" @update:model-value="toggleDefaultType"
          false-icon="mdi-radiobox-blank" true-icon="mdi-radiobox-marked" :disabled="$store.state.isWorkflowDisabled">
        </v-checkbox>
        <v-tooltip location="top" open-delay="1000">
          <template v-slot:activator="{ props }">
            <span v-bind="props">
              <v-btn size="small" variant="text" icon class="ma-0" @click="deleteRequirementType(requirementType.id)" :disabled="$store.state.isWorkflowDisabled">
                <v-icon icon="mdi-delete"></v-icon>
              </v-btn>
            </span>
          </template>

          <span>Delete type</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row class="mt-0 mb-2">
      <v-col class="pt-0 pb-0">
        <div v-if="!showDescription" class="text-caption text-light-blue expension-btn" @click="openDescription">
          Show description <v-icon size="x-small" color="light-blue">chevron_right</v-icon>
        </div>

        <div v-else class="text-caption text-light-blue expension-btn" @click="closeDescription">
          Hide description <v-icon size="x-small" color="light-blue">chevron_right</v-icon>
        </div>

      <v-expand-transition>
        <v-card v-show="showDescription" class="rounded-lg ma-2">
          <textarea v-model="descriptionModel" rows="2" class="card-description-textarea pa-2" ref="textareaElement" @focus="computeHeight"
                    @keydown.exact="computeHeight" @keydown.enter.exact.prevent="submitDescriptionChange"
                    @keydown.alt.enter.exact.prevent="handleDescriptionAltEnter" @keydown.27.stop="cancelDescriptionChange()"
                    @cut="computeHeight" @paste="computeHeight" @drop="computeHeight" :disabled="$store.state.isWorkflowDisabled"></textarea>

            <v-layout justify-end class="pa-1">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-icon size="small" v-bind="props">info_outline</v-icon>
                </template>
                <div style="width: 470px;">
                  Please provide a clear and concise description using standard terminology,
                  ensuring that you clearly state the key details about the new type to help
                  the AI generate accurate content
                </div>
              </v-tooltip>
              <v-btn size="small" class="ma-0 mx-2 action-button" color="primary" variant="text"
                    :disabled="descriptionModel === requirementType.description" @click="cancelDescriptionChange">Cancel</v-btn>

              <v-btn size="small" class="ma-0 action-button" color="primary"
                    :disabled="descriptionModel === requirementType.description" @click="submitDescriptionChange">Save</v-btn>
            </v-layout>
          </v-card>
        </v-expand-transition>
      </v-col>
    </v-row>
    <div class="mt-5"></div>
  </v-layout>
</template>

<script>
import WorkflowSettingsRequirementTypeColorPicker from '@/components/WorkflowSettingsRequirementTypeColorPicker'
import store from '@/store'
import requirementTypesApi from '@/api/requirementTypesApi'

export default {
  components: {
    WorkflowSettingsRequirementTypeColorPicker
  },

  props: {
    requirementType: Object,
    enableEdit: String
  },

  data () {
    return {
      editTypeText: '',
      showDescription: false,
      descriptionModel: '',
      editMode: false
    }
  },

  watch: {
    editMode (newVal) {
      if (newVal) {
        this.editTypeText = this.requirementType.title

        this.$nextTick(() => {
          if (this.$refs.titleInput) {
            this.$refs.titleInput.focus()
          }
        })
      } else {
        this.editTypeText = ''
        this.$emit('diable-edit')
      }
    },
    enableEdit (newVal) {
      if (newVal === this.requirementType.id) {
        this.editMode = true
      }
    }
  },

  methods: {
    blurField (event) {
      this.applyTitleChange(event)
    },
    cancelEdit (ev) {
      this.editTypeText = this.requirementType.title
      ev.target.blur()
    },
    async _emitUpdate (payload) {
      await requirementTypesApi.updateRequirementType(this.$route.params.id, this.requirementType, payload)
      this.editTypeId = null
      this.editMode = false
    },

    applyTitleChange (ev) {
      const payload = {
        title: this.editTypeText
      }
      if (this.editTypeText !== this.requirementType.title) {
        this._emitUpdate(payload)
      } else {
        this.editMode = false
      }
    },

    changeColor (color) {
      this._emitUpdate({ color })
    },

    toggleShowInDiagram (value) {
      const payload = {
        showInDiagram: Boolean(value)
      }
      if (this.editMode) {
        payload.title = this.editTypeText
      }
      this._emitUpdate(payload)
    },

    toggleShowInBacklog (value) {
      const payload = {
        showInBacklog: Boolean(value)
      }
      if (this.editMode) {
        payload.title = this.editTypeText
      }
      this._emitUpdate(payload)
    },

    toggleDefaultType (value) {
      const payload = {
        defaultType: !this.requirementType.defaultType
      }
      if (this.editMode) {
        payload.title = this.editTypeText
      }
      this._emitUpdate(payload)
    },

    toggleTriggerType (value) {
      const payload = {
        triggerType: !this.requirementType.triggerType,
        aggregateType: false,
        showInBacklog: !!this.requirementType.aggregateType,
        showInDiagram: !this.requirementType.aggregateType
      }
      if (this.editMode) {
        payload.title = this.editTypeText
      }
      if (this.requirementType.aiCount > 1) {
        payload.aiCount = 1
      }
      this._emitUpdate(payload)
    },

    toggleAggregateType (value) {
      const payload = {
        aggregateType: !this.requirementType.aggregateType,
        triggerType: false,
        showInBacklog: !!this.requirementType.aggregateType,
        showInDiagram: !this.requirementType.aggregateType
      }
      if (this.editMode) {
        payload.title = this.editTypeText
      }
      if (this.requirementType.aiCount > 1) {
        payload.aiCount = 1
      }
      this._emitUpdate(payload)
    },

    saveAiCount (value) {
      const payload = {
        aiCount: value
      }
      if (this.editMode) {
        payload.title = this.editTypeText
      }
      this._emitUpdate(payload)
    },

    computeHeight () {
      if (!this.$refs.textareaElement) {
        return
      }

      const { textareaElement } = this.$refs

      this.$nextTick(() => {
        setImmediate(() => {
          textareaElement.style.height = 'auto'
          textareaElement.style.height = `${textareaElement.scrollHeight}px`
        })
      })
    },

    openDescription () {
      this.descriptionModel = this.requirementType.description || ''
      this.showDescription = true

      setTimeout(() => {
        if (this.$refs.textareaElement) {
          this.$refs.textareaElement.focus()
        }
      }, 50)
    },

    closeDescription () {
      this.showDescription = false
    },

    handleDescriptionAltEnter (event) {
      const { textareaElement } = this.$refs
      const startPosition = textareaElement.selectionStart
      const endPosition = textareaElement.selectionEnd
      const result = [
        this.descriptionModel.slice(0, startPosition),
        '\n',
        this.descriptionModel.slice(endPosition)
      ].join('')

      // update textarea value
      this.descriptionModel = result

      // set cursor position
      this.$nextTick(() => {
        textareaElement.selectionEnd = endPosition + 1
      })
    },

    cancelDescriptionChange () {
      this.descriptionModel = this.requirementType.description || ''
      this.$refs.textareaElement.blur()
      this.closeDescription()
    },

    submitDescriptionChange (event) {
      this._emitUpdate({ description: this.descriptionModel })
      event.target.blur()
    },

    async deleteRequirementType (id) {
      const workflowId = this.$route.params.id
      await store.dispatch('requirementTypes/deleteRequirementType', { id, workflowId })
    },

    setFocus (id) {
      if (id === this.requirementType.id) {
        this.editMode = true
      }
    }
  }
}
</script>

<style scoped>
.type-checkbox-column {
  justify-content: center;
}

.type-title {
  height: 28px;
  line-height: 28px !important;
}

.type-title-input-container input {
  min-width: 0;
  box-sizing: border-box;
  height: 28px;
  line-height: 28px;
  outline: none;
  border: none;
  border-bottom: 2px solid currentColor;
}

textarea {
  width: 100%;
  resize: none;
  outline: none;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  min-height: 40px;
}

.action-button {
  border-radius: 6px !important;
  min-width: 60px;
}

.expension-btn {
  cursor: pointer;
}

::v-deep .v-select__menu-icon {
    display: none !important;
}
</style>
