<template>
  <v-card class="auth-form-container pa-6">
    <img src="@/assets/qlerify-logo-small.png"
         alt="Qlerify logo" class="mx-auto mb-5" style="display: block;"/>
    <template v-if="$route.query.thanks">
      <h3 class="text-h5 font-weight-bold pb-4 text-center">Your account is now enabled for login.</h3>
      <p class="text-center">
        Please contact us at
        <a href="mailto:support@qlerify.com">support@qlerify.com</a> with
        <br />any questions, suggestions or feedback!
      </p>
    </template>
    <template v-else-if="$route.query.resetPasswordSuccess">
      <h3 class="text-h5 font-weight-bold pa-3 mb-1 bg-green-lighten-1 rounded text-center">Your password has been reset!</h3>
      <p class="text-center">
        You can now login with your new password.
      </p>
    </template>

    <template v-else>
        <h3 class="text-h5 font-weight-bold pb-4 text-center">Log in.</h3>
    </template>

    <google-sign-in-button/>
    <text-divider text="or"/>
    <v-form ref="form" v-model="valid">
      <v-container class="pt-0">
        <v-row class="my-0">
          <v-col class="pa-0 pb-2">
            <v-text-field
              name="email"
              label="Email"
              type="email"
              variant="solo"
              ref="username"
              v-model="email"
              :rules="[rules.required, rules.email]"
              v-on:keyup.enter="submit"
              tabindex="1"
              autocomplete="username"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="pa-0">
            <v-text-field
              name="password"
              label="Password"
              id="password"
              variant="solo"
              ref="password"
              v-model="password"
              :append-inner-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append-inner="() => (e1 = !e1)"
              :type="e1 ? 'password' : 'text'"
              min="8"
              :rules="[rules.required]"
              counter
              v-on:keyup.enter="submit"
              tabindex="2"
              autocomplete="current-password"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="pa-0">
            <p class="bg-error" v-html="error" />
            <p class="message" v-html="message" />
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="pa-0">
            <router-link :to="{ path: '/reset-password' }">
              <span class="text-subtitle-2">Forgot password?</span>
            </router-link>
            <br />
            <router-link :to="{ path: '/confirm' }" tabindex="4">
              <span class="text-subtitle-2">Got a registration code?</span>
            </router-link>
          </v-col>
          <v-col class="pa-0 d-flex align-center">
            <v-btn min-width="180px" color="primary" class="ml-auto d-flex" @click="submit" tabindex="3">
              Log in
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="my-6">
          <v-col class="pa-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="pa-0 d-flex justify-center">
            <p class="ma-0">
              Don't have an account?

              <router-link :to="{ path: '/signup', query: { projectId: $route.query.projectId || undefined }  }" tabindex="5">
                <span class="light--text">Sign up</span>
              </router-link>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import store from '@/store'
import { authHelpers } from '@/api/authHelpers'
import GoogleSignInButton from '@/components/GoogleSignInButton.vue'
import TextDivider from '@/components/TextDivider.vue'
import { Auth } from '@aws-amplify/auth'

export default {
  components: { TextDivider, GoogleSignInButton },
  data () {
    return {
      valid: true,
      email: '',
      password: '',
      error: null,
      message: null,
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      e1: true
    }
  },
  created () {
    if (this.$route.query.username !== '') {
      this.email = this.$route.query.username
    }
  },
  async mounted () {
    if (this.$route.query.thanks) {
      // window.gtag('event', 'conversion', { send_to: 'AW-676520547/ZI1ACJashMwBEOPEy8IC' })
    }
    if (this.$route.query.username) {
      this.$refs.password.focus()
    }
    if (Auth && this.$route.query.googleSignIn) {
      try {
        const currentUser = await Auth.currentAuthenticatedUser()
        if (currentUser) {
          try {
            this.$loadingState.start()

            const sessionIdInfo = currentUser.signInUserSession.idToken.payload
            await this.initializeSession(sessionIdInfo)
          } catch (err) {
            this.$loadingState.stop()
            this.error = err.message.replace('GraphQL error: ', '')
            authHelpers.logoutAndClearStore()
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  methods: {
    async updateUser () {
      const attributes = await this.$cognitoAuth.getUserAttributes()
      const indexCompany = attributes.findIndex(item => item.Name === 'custom:organisation')
      const company = indexCompany >= 0 ? attributes[indexCompany].Value : null
      const indexReferralSource = attributes.findIndex(item => item.Name === 'custom:referral_source')
      const referralSource = indexReferralSource >= 0 ? attributes[indexReferralSource].Value : null
      const indexUsageIntent = attributes.findIndex(item => item.Name === 'custom:usage_intent')
      const usageIntent = indexUsageIntent >= 0 ? attributes[indexUsageIntent].Value : null
      const indexFirstname = attributes.findIndex(item => item.Name === 'given_name')
      const firstname = attributes[indexFirstname].Value
      const indexLastname = attributes.findIndex(item => item.Name === 'family_name')
      const lastname = attributes[indexLastname].Value
      const indexEmail = attributes.findIndex(item => item.Name === 'email')
      const email = attributes[indexEmail].Value
      const indexPhoneNumber = attributes.findIndex(item => item.Name === 'phone_number')
      const phoneNumber = indexPhoneNumber >= 0 ? attributes[indexPhoneNumber].Value : null
      const queryResult = await store.dispatch('user/getCurrentUser')
      const variables = {
        firstname,
        lastname,
        email,
        company,
        referralSource,
        usageIntent,
        phoneNumber,
        increaseLoginCounter: true
      }
      if (queryResult.data.getCurrentUser) {
        await store.dispatch('user/updateUser', variables)
      } else {
        await store.dispatch('user/createUser', variables)
        window.gtag_report_conversion()
        if (company) {
          await store.dispatch('organisations/createOrganisation', { name: company })
        }
        await store.dispatch('teamMembers/convertInvitesToTeamMembers')
        this.$store.dispatch('projectMemberships/listProjectMembershipsForCurrentUser')
      }
    },
    async submit () {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        try {
          this.$loadingState.start()
          await this.$cognitoAuth.signin(this.email, this.password)

          const session = await this.$cognitoAuth.getSession()
          const sessionIdInfo = session.getIdToken().payload

          await this.initializeSession(sessionIdInfo)
        } catch (err) {
          console.log(err)
          this.$loadingState.stop()
          this.error = err.message.replace('GraphQL error: ', '')
          authHelpers.logoutAndClearStore()
        }
      }
    },
    async initializeSession (sessionIdInfo) {
      let adminUser = false
      if (sessionIdInfo['cognito:groups']) {
        if (sessionIdInfo['cognito:groups'][0] === 'Admin') {
          adminUser = true
        }
      }
      store.commit('login', { email: sessionIdInfo.email, adminUser, userId: sessionIdInfo['cognito:username'] })
      await this.updateUser()
      this.$loadingState.stop()
      const path = `/dashboard/${this.$route.query.projectId ? this.$route.query.projectId : ''}`
      await this.$router.push({ path })
    }
  }
}
</script>

<style scoped>
div.error {
  color: red;
  background: none !important;
  border: none;
}

a {
  color: rgba(0, 0, 0, 0.87);
}

.auth-form-container {
  max-width: 600px;
  margin: 24px auto;
}
</style>
