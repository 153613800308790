<template>
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.5 3H0.5V11H12.5V3Z" class="stroke--current-color" />
    <path d="M0.5 0.5V3H6.5L4.5 0.5H0.5Z" class="stroke--current-color" />
  </svg>
</template>

<script>
export default {
  name: 'project-icon'
}
</script>

<style scoped>
.stroke--current-color {
  stroke: currentColor;
}

rect {
  fill: none;
}
</style>
