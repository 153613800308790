<template>
  <v-row v-if="workflow" class="align-center justify-end ma-0 flex-nowrap">
    <v-tooltip location="bottom" v-if="workflowStatusCompleted">
      <template v-slot:activator="{ props }">
        <v-btn class="mr-3" v-bind="props" variant="outlined" color="red" :ripple="false" prepend-icon="mdi-lock">
          This workflow is locked
        </v-btn>
      </template>

      <span
        >This workflow is marked as completed. To unlock this workflow click
        the settings button to the right and go to general settings.</span
      >
    </v-tooltip>
    <v-tooltip v-if="!$store.state.workflow.isViewOnlyEnabled" location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn
          :disabled="!previousVersionExists || workflowStatusCompleted"
          variant="text"
          icon
          size="default"
          v-bind="props"
          class="mr-2 captures-description"
          @click="undo(workflow)"
        >
          <v-icon size="large" icon="mdi-undo"></v-icon>
        </v-btn>
      </template>
      <span
        >Undo: {{ changeNoteCurrent?.userRealName }}
        {{ changeNoteCurrent?.action }}
        <span v-html="changeNoteCurrent?.target?.substring(0, 30)"></span>
        <span v-if="changeNoteCurrent?.target?.length > 30">...</span>
      </span>
    </v-tooltip>
    <v-tooltip v-if="!$store.state.workflow.isViewOnlyEnabled" location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn
          :disabled="!laterVersionExists || workflowStatusCompleted"
          @click="redo(workflow)"
          variant="text"
          icon
          size="default"
          v-bind="props"
          class="mr-2 captures-description"
        >
          <v-icon size="large" icon="mdi-redo"></v-icon>
        </v-btn>
      </template>
      <span
        >Redo: {{ changeNoteRedo?.userRealName }}
        {{ changeNoteRedo?.action }}
        <span v-html="changeNoteRedo?.target?.substring(0, 30)"></span>
        <span v-if="changeNoteRedo?.target?.length > 30">...</span>
      </span>
    </v-tooltip>
    <workflow-help-dialog
      v-slot="{ openDialog }"
      content-class="dialog-top-right"
    >
      <v-btn variant="text" icon size="default" @click="openDialog" class="mr-2">
        <v-icon size="large">mdi-help-circle-outline</v-icon>
      </v-btn>
    </workflow-help-dialog>

    <v-btn variant="text" icon size="default" class="workflowGoals-open-btn mr-2" @click="$refs.workflowGoals.open()">
      <v-icon size="large">mdi-bullseye-arrow</v-icon>
    </v-btn>
    <workflow-goals ref="workflowGoals" :workflow="workflow">
    </workflow-goals>

    <v-tooltip location="bottom" v-if="hasEvents" open-delay="1000">
      <template v-slot:activator="{ props }">
        <div class="flex-shrink-1 flex-grow-0 mr-2" v-bind="props">
          <v-select
            ref="releaseSelector"
            :model-value="showReleaseNo"
            @update:model-value="$store.commit('workflow/setShowReleaseNo', $event)"
            :items="releases"
            label="Filter Releases"
            :multiple="true"
            density="compact"
            :flat="true"
            variant="solo"
            hide-details="true"
            class="select-release-no pa-0"
          >
            <template v-slot:selection="{ item, index }">
              <v-card-text class="pa-0 pl-3 pt-3 text-white" v-if="index === 0">{{ item.title }}</v-card-text>

              <v-card-text class="pa-0 pt-3 text-white" v-else-if="index <= 1">, {{ item.title }}</v-card-text>

              <v-card-text class="pa-0 pt-3 text-grey" v-else-if="index === 2" >
                (+{{ showReleaseNo.length - 2 }} others)</v-card-text
              >
            </template>
          </v-select>
        </div>
      </template>

      <span>View the content included in one or several releases</span>
    </v-tooltip>

      <v-tooltip location="bottom" open-delay="1000">
        <template v-slot:activator="{ props: tooltipActivator }">
          <v-menu transition="slide-y-transition" location="bottom">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="{ ...props, ...tooltipActivator }"
                :disabled="!workflow.eventsJson || !workflow.eventsJson.length"
                variant="outlined"
                class="export-button mr-2"
                prepend-icon="mdi-cloud-upload"
              >
                Export
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-list-subheader>Export options:</v-list-subheader>
                <v-list-item @click="exportCSV(workflow.id)">
                  <v-list-item-title>Export CSV</v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportJson(workflow.id)">
                  <v-list-item-title>Export JSON</v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportToPdf()">
                  <v-list-item-title>Export PDF</v-list-item-title>
                </v-list-item>

                <workflow-export-requirements-dialog
                  :requirements="eventRequirements"
                >
                </workflow-export-requirements-dialog>
              </v-list>
            </v-card>
          </v-menu>
        </template>
        <span>Export</span>
      </v-tooltip>

    <v-tooltip location="bottom" open-delay="1000">
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          @click="openShareDialog = true"
          variant="flat"
          color="primary"
          class="export-button mr-2"
          prepend-icon="mdi-account-plus-outline"
        >
          Share
        </v-btn>
      </template>
      <span>Share workflow</span>
    </v-tooltip>

    <v-tooltip v-if="!$store.state.workflow.isViewOnlyEnabled" location="bottom" open-delay="1000">
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          @click="showPreferredDialog"
          size="small"
          color="primary"
          class="px-3 workflow-settings-button mr-1"
          icon
        >
          <v-icon icon="mdi-cog-outline" size="large"></v-icon>
        </v-btn>
      </template>
      <span>Workflow settings</span>
    </v-tooltip>

    <v-dialog v-model="exportCsvDialog" v-on:keydown.stop>
      <v-card>
        <workflow-export-csv
          ref="exportCsv"
          :workflow="workflow"
          :show-done="showDone"
          @closeExportCsvDialog="closeExportCsvDialog"
        />
      </v-card>
    </v-dialog>

    <v-dialog v-model="exportJsonDialog" v-on:keydown.stop>
      <v-card>
        <workflow-export-json
          ref="exportJson"
          :workflow="workflow"
          @closeExportJsonDialog="closeExportJsonDialog"
        />
      </v-card>
    </v-dialog>

    <workflow-share-dialog
      :is-share-enabled="isShareEnabled()"
      :open-share-dialog="openShareDialog"
      :workflow="workflow"
      @close="closeShareDialog"
    />

    <disclaimer-dialog
      ref="disclaimerDialog"
      @conditions-accepted="onConditionsAccepted()"
    />

    <workflow-settings-dialog
      :workflow="workflow"
      :open="workflowSettingsOpen"
      :tab-id="workflowSettingsTab"
      :disabled="workflowStatusCompleted"
      @close="closeWorkflowSettings"
    ></workflow-settings-dialog>
  </v-row>
</template>

<script>
import workflowApi from '@/api/workflowApi'
import WorkflowExportCsv from '@/components/WorkflowExportCsv'
import WorkflowExportJson from '@/components/WorkflowExportJson'
import WorkflowHelpDialog from '@/components/WorkflowHelpDialog'
import WorkflowGoals from '@/components/WorkflowGoals'
import WorkflowExportRequirementsDialog from '@/components/WorkflowExportRequirementsDialog'
import WorkflowShareDialog from '@/components/WorkflowShareDialog.vue'
import { EventBus } from '@/event-bus/event-bus.js'
import WorkflowSettingsDialog from '@/components/WorkflowSettingsDialog'
import DisclaimerDialog from '@/components/DisclaimerDialog'
import { workflowHelpers } from '@/mixins/workflowHelpers.js'
import { addSwimLanes, replaceForeignObjectsWithTextAndCenter } from '@/helpers/svgConvert'
import { mapState, mapGetters } from 'vuex'
import jsPDF from 'jspdf'

export default {
  mixins: [workflowHelpers],
  components: {
    WorkflowExportCsv,
    WorkflowExportJson,
    WorkflowHelpDialog,
    WorkflowGoals,
    WorkflowExportRequirementsDialog,
    WorkflowShareDialog,
    WorkflowSettingsDialog,
    DisclaimerDialog
  },
  props: ['previousVersionExists', 'versions', 'releases', 'showDone'],

  data () {
    return {
      exportCsvDialog: false,
      exportJsonDialog: false,
      openShareDialog: false,
      workflowSettingsOpen: false,
      aiDisclaimerPreference: localStorage.getItem(`showAiInfo-${this.$route.params.projectId}`) === 'true',
      workflowSettingsTab: null
    }
  },
  methods: {
    undo: workflowApi.undo,
    redo: workflowApi.redo,
    exportCSV () {
      this.$nextTick(() => {
        this.exportCsvDialog = true
      })
    },
    exportJson () {
      this.$nextTick(() => {
        this.exportJsonDialog = true
        // this.$refs.exportCsv.refreshQuery()
      })
    },
    exportToPdf () {
      const flowchartOriginal = document.getElementById('flowchart')
      const flowchartTransformed = flowchartOriginal.cloneNode(true)

      replaceForeignObjectsWithTextAndCenter(flowchartTransformed)

      const laneColor = this.workflow.roleDefinition.color.rgb
      addSwimLanes(flowchartTransformed, this.svgLanesData, laneColor, this.hasGroups)

      flowchartTransformed.setAttribute('fill', 'white')

      flowchartTransformed.getBoundingClientRect() // force layout calculation
      const width = flowchartTransformed.width.baseVal.value
      const height = flowchartTransformed.height.baseVal.value

      // eslint-disable-next-line new-cap
      const doc = new jsPDF(width > height ? 'l' : 'p', 'pt', [width, height])
      doc.svg(flowchartTransformed).then(() => doc.save(`${this.workflow.name}.pdf`))
    },
    closeExportCsvDialog () {
      this.exportCsvDialog = false
    },
    closeExportJsonDialog () {
      this.exportJsonDialog = false
    },
    showPreferredDialog () {
      this.workflowSettingsOpen = false
      this.$refs.disclaimerDialog.openDialog()
    },
    closeShareDialog () {
      this.openShareDialog = false
    },
    isShareEnabled () {
      const res = !this.$store.state.workflow.isViewOnlyEnabled && this.project?.organisation?.userId === this.$store.state.userId
      return res
    },
    onConditionsAccepted () {
      this.workflowSettingsOpen = true
    },
    closeWorkflowSettings () {
      this.workflowSettingsTab = null
      this.workflowSettingsOpen = false
    },
    openTypesSettings (tab = 'CARDS') {
      this.workflowSettingsTab = tab
      this.workflowSettingsOpen = true
    }
  },
  computed: {
    ...mapGetters({
      project: 'projectMemberships/activeProject',
      svgLanesData: 'svgGraphData/svgLanesData'
    }),
    ...mapState({
      workflow: state => state.workflow.workflow,
      showReleaseNo: state => state.workflow.showReleaseNo
    }),
    changeNoteCurrent () {
      if (!this.workflow?.sameAsVersion) {
        return this.workflow?.updateNote
      } else {
        const index = this.versions?.findIndex(element => element.version === this.workflow.sameAsVersion)
        return this.versions?.[index]?.updateNote
      }
    },
    changeNoteRedo () {
      if (!this.workflow?.sameAsVersion) {
        return {
          userRealName: '',
          action: '',
          target: ''
        }
      } else {
        const index = this.versions?.findIndex(element => element.version === (this.workflow.sameAsVersion + 1))
        return this.versions?.[index]?.updateNote || ''
      }
    },
    laterVersionExists () {
      if (this.workflow?.sameAsVersion &&
        this.versions?.findIndex(element => element.version === (this.workflow.sameAsVersion + 2)) >= 0) {
        return true
      } else {
        return false
      }
    },
    hasEvents () {
      return this.workflow?.eventsJson && this.workflow.eventsJson.length
    },
    hasGroups () {
      return this.workflow?.groups && this.workflow.groups.length
    },
    eventRequirements () {
      const events = this.workflow?.eventsJson
      let requirements = []
      if (!Array.isArray(events)) {
        return requirements
      }
      for (const event of events) {
        if (event.requirementsJson) {
          requirements = requirements.concat(event.requirementsJson)
        }
      }
      return requirements
    }
  },
  watch: {
    releases (newVal) {
      if (!this.showReleaseNo) return
      // filter out selected releases that no longer exists
      const showReleaseNo = this.showReleaseNo.filter(release => {
        return newVal.findIndex(item => item.value === release) !== -1
      })
      this.$store.commit('workflow/setShowReleaseNo', showReleaseNo)
    }
  },
  async mounted () {
    EventBus.$on('open-types-settings', (tab) => {
      this.openTypesSettings(tab)
    })
    EventBus.$on('open-settings', (isTrue) => {
      this.workflowSettingsOpen = isTrue
    })
    // Next line prevents the release selector from being populated by data from different workflow
    // when the user navigates to workflow page
    this.$store.commit('workflow/setShowReleaseNo', null)
  }
}
</script>
