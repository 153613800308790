import { EventBus } from '@/event-bus/event-bus.js'
import workflowApi from '@/api/workflowApi'
import { workflowApiHelpers } from '@/api/workflowApiHelpers'
import { updateFontSizeDynamically } from '@/utils/update-font.js'
import randomUUID from '@/utils/uuid'

export const diagramHelpers = {
  methods: {
    checkCharacterLimit (ev, maxLength) {
      const selectedText = window.getSelection().toString()
      if (ev.target.isContentEditable) {
        if (ev.target.innerText.length >= maxLength &&
          ev.key !== 'Backspace' &&
          ev.key !== 'ArrowUp' &&
          ev.key !== 'ArrowDown' &&
          ev.key !== 'ArrowLeft' &&
          ev.key !== 'ArrowRight' &&
          !(ev.key === 'a' && ev.metaKey === true) &&
          !selectedText) {
          ev.preventDefault()
        }
      }
    },

    insertTextAtCursor (elem, text, maxLength) {
      const selection = window.getSelection()
      const range = selection.getRangeAt(0)
      const currentText = elem.textContent
      const selectedText = window.getSelection().toString()

      const remainingCharacters = maxLength - currentText.length + selectedText.length

      if (text.length > remainingCharacters) {
        text = text.slice(0, remainingCharacters)
      }

      // Delete the selected text and insert the new text at the cursor position
      range.deleteContents()
      range.insertNode(document.createTextNode(text))
      range.collapse(false)
      selection.removeAllRanges()
      selection.addRange(range)
    },

    onPaste (ev, elem, maxLength, onWidth = false) {
      ev.preventDefault()
      const pastedText = (ev.clipboardData || window.clipboardData).getData('text/plain')

      this.insertTextAtCursor(elem, pastedText, maxLength)
      updateFontSizeDynamically(elem, onWidth)
    },

    restoreValue (ev, value) {
      if (ev.target.isContentEditable) {
        ev.target.textContent = value
        ev.target._ignoreBlur = true
        ev.target.blur()
      }
    },

    toggleFocus (ev) {
      if (ev.target.isContentEditable) {
        ev.target.blur()
      } else {
        ev.target.focus()
      }
    },

    changeSelectedEvent (eventId) {
      EventBus.$emit('enable-connection-targets', false)
      if (eventId) {
        this.$router.push({ path: '/workflow/' + this.$route.params.projectId + '/' + this.$route.params.id + '/' + eventId }).catch(() => { })
      } else {
        this.$router.push({ path: '/workflow/' + this.$route.params.projectId + '/' + this.$route.params.id }).catch(() => { })
      }
    },

    blurActionForDescription (ev, node) {
      if (ev.target.innerText.length === 0) {
        this.restoreValue(ev, node.name)
      }
      if (ev.target.innerText !== this.svgGraphData[node.id]?.name) {
        this.svgGraph[node.id].name = ev.target.innerText
        if (ev.relatedTarget?.classList?.contains('captures-description')) {
          // anoter button was pushed that will
          // handle the update of the description
          return
        }
        workflowApi.updateWorkflow({
          svgGraph: this.svgGraph,
          svgLanes: this.svgLanes,
          svgGroups: this.svgGroups,
          workflow: this.workflow,
          updateNote: {
            action: this.svgGraph[node.id].type === 'bpmn:Task' ? 'updated event text to' : 'updated decision text to',
            target: ev.target.innerText
          }
        })
        // }
      }
    },

    blurOnDescription (ev, node) {
      if (this.isBrowserSafari()) {
        setTimeout(() => {
          // wait for possible click event to fire and update the svgGraph
          this.blurActionForDescription(ev, node)
        }, 1000)
      } else {
        this.blurActionForDescription(ev, node)
      }
    },

    blurActionForConditionLabel: function (ev, node, parentId) {
      const eventButtons = document.getElementById('event-action-buttons')
      eventButtons.style.removeProperty('display')
      const index = this.svgGraphData[node.id]?.conditionLabels?.findIndex(label => label.parentId === parentId)
      if (index >= 0) {
        if (ev.target.innerText !== this.svgGraphData[node.id]?.conditionLabels[index].name) {
          this.svgGraph[node.id].conditionLabels[index].name = ev.target.innerText
          if (ev.relatedTarget?.classList?.contains('captures-description')) {
            // anoter button was pushed that will
            // handle the update of the description
            return
          }
          return workflowApi.updateWorkflow({
            svgGraph: this.svgGraph,
            svgLanes: this.svgLanes,
            svgGroups: this.svgGroups,
            workflow: this.workflow,
            updateNote: {
              action: 'updated label to',
              target: ev.target.innerText
            }
          })
        }
      } else {
        if (!this.svgGraph[node.id]?.conditionLabels) {
          this.svgGraph[node.id].conditionLabels = []
        }
        this.svgGraph[node.id].conditionLabels.push({
          parentId,
          name: ev.target.innerText
        })
        return workflowApi.updateWorkflow({
          svgGraph: this.svgGraph,
          svgLanes: this.svgLanes,
          svgGroups: this.svgGroups,
          workflow: this.workflow,
          updateNote: {
            action: 'updated label to',
            target: ev.target.innerText
          }
        })
      }
      if (ev.target.innerText !== this.svgGraphData[node.id]?.conditionLabels[index].name) {
        this.svgGraph[node.id].conditionLabel = ev.target.innerText
        workflowApi.updateWorkflow({
          svgGraph: this.svgGraph,
          svgLanes: this.svgLanes,
          svgGroups: this.svgGroups,
          workflow: this.workflow,
          updateNote: {
            action: 'updated label to',
            target: ev.target.innerText
          }
        })
      }
    },

    blurOnConditionLabels (ev, node, parentId) {
      if (this.isBrowserSafari()) {
        // wait for possible click event to fire and update the svgGraph
        setTimeout(() => { this.blurActionForConditionLabel(ev, node, parentId) }, 1000)
      } else {
        this.blurActionForConditionLabel(ev, node, parentId)
      }
    },

    getConnectionLabel (node, parentId) {
      const index = node?.conditionLabels?.findIndex(label => label.parentId === parentId)
      if (index >= 0) {
        return node.conditionLabels[index].name
      }
      return node.conditionLabel || '[Label]'
    },

    focusOnConditionLabel (ev) {
      const eventButtons = document.getElementById('event-action-buttons')
      if (eventButtons) {
        eventButtons.style.display = 'none'
      }
    },

    addConnection (targetEventId) {
      const sourceEventId = this.$route.params.eventId
      EventBus.$emit('enable-connection-targets', false)
      workflowApi.addConnectionToEvent(sourceEventId, targetEventId, this.svgGraph, this.svgLanes, this.svgGroups, this.workflow)
    },

    async addCardToEvent (eventId, cardTypeId, text) {
      const index = this.workflow.eventsJson.findIndex((event) => event.id === eventId)

      // This snippet checks if there is already a requirement that is an aggregate or trigger in the event
      // There should be only one card of each
      if (this.workflow.eventsJson[index].requirementsJson) {
        const eventRequirements = this.workflow.eventsJson[index].requirementsJson
        const triggerAggregateIds = this.requirementTypes
          .filter(req => req.aggregateType || req.triggerType)
          .map(req => req.id)

        // check if there is already a requirement with the same card type in the event
        // that is either a trigger or aggregate
        const eventTriggerRequirement = eventRequirements.find(req => triggerAggregateIds.includes(req.cardTypeId) && req.cardTypeId === cardTypeId)

        if (eventTriggerRequirement) {
          this.$store.commit('snackbar/showMessage', { content: 'Error adding. Cannot add multiple cards for Aggregate or Trigger card type.', timeout: 3000, color: 'red', centered: true }, { root: true })
          return
        }
      }

      EventBus.$emit('open-card-on-event', { eventId: this.node.id, cardTypeId })
      const res = await workflowApi.createRequirementJson(this.$route.params.id, eventId, {
        cardTypeId,
        description: text
      })
      if (res.requirements?.length) {
        EventBus.$emit('focus-card', { id: res.requirements[0].id })
      }
    },

    async addLane (svgGraph, svgLanes, svgGroups, workflow, roleName, selectLane, updateWorkflow = true) {
      let lastLaneId = null
      if (workflow.lanes) {
        lastLaneId = workflow.lanes[workflow.lanes.length - 1]?.id
      }
      const newLaneId = randomUUID()
      svgLanes[newLaneId] = {
        id: newLaneId,
        index: workflow.lanes?.length || 0,
        x: 0,
        y: svgLanes[lastLaneId] ? svgLanes[lastLaneId].y + svgLanes[lastLaneId].height - 10 : 0, // -10 is necessary because the last lane is 10px higher
        height: 150,
        name: roleName,
        laneAbove: lastLaneId,
        emptyLane: true,
        selectLane
      }
      if (lastLaneId) {
        // necessary because the last lane is 10px higher
        svgLanes[lastLaneId].height = svgLanes[lastLaneId].height - 10
      }
      workflowApiHelpers.updateLane(svgLanes[newLaneId], svgGraph, svgLanes)
      if (updateWorkflow) {
        await workflowApi.updateWorkflow({
          svgGraph,
          svgLanes,
          svgGroups,
          workflow,
          updateNote: {
            action: 'added lane',
            target: roleName
          }
        })
      }
      return svgLanes[newLaneId]
    },

    isSelectedEvent (eventId) {
      return eventId === this.$route.params.eventId
    },

    currentEventIsAtTheBottomOfItsLane (svgLanes, svgGraph, eventId) {
      let bottomOfLane = 0
      if (svgLanes[svgGraph[eventId]?.laneId]) {
        bottomOfLane = svgLanes[svgGraph[eventId].laneId].y + svgLanes[svgGraph[eventId].laneId].height
      }
      if (bottomOfLane === (svgGraph[eventId]?.y + 110) || bottomOfLane === (svgGraph[eventId]?.y + 120)) {
        return true
      } else {
        return false
      }
    },

    disableGatewayFromTransformMenu (node) {
      return Array.isArray(node.childIds) && node.childIds.length > 1
    },

    async blurActionOnRoleName (ev, oldValue, index) {
      const { textContent: newValue, isContentEditable } = ev.target
      if (newValue.length === 0) {
        this.restoreValue(ev, oldValue)
        return
      }
      if (isContentEditable) {
        ev.target._ignoreBlur = true
        ev.target.blur()
        if (this.workflow.lanes[index]) {
          const id = this.workflow.lanes[index].id
          if (newValue === this.workflow.lanes[index].name) {
            return
          }
          this.svgLanes[id].name = newValue
          if (ev.relatedTarget?.classList?.contains('captures-description')) {
            // anoter button was pushed that will
            // handle the update of the description
            return
          }
          workflowApi.updateWorkflow({
            svgLanes: this.svgLanes,
            svgGraph: this.svgGraph,
            svgGroups: this.svgGroups,
            workflow: this.workflow,
            updateNote: {
              action: 'updated lane name to',
              target: newValue
            }
          })
        }
      }
    },

    async blurOnRoleName (ev, oldValue, index) {
      if (this.isBrowserSafari()) {
        // wait for possible click event to fire and update the svgGraph
        setTimeout(() => { this.blurActionOnRoleName(ev, oldValue, index) }, 1000)
      } else {
        this.blurActionOnRoleName(ev, oldValue, index)
      }
    },

    isBrowserSafari () {
      const browserUserAgent = window.navigator.userAgent.toLowerCase()
      const isBrowserSafari = browserUserAgent && (browserUserAgent.indexOf('safari/') > 0) && !(browserUserAgent.indexOf('edg/') > 0) && !(browserUserAgent.indexOf('chrome/') > 0)
      return isBrowserSafari
    }
  }
}
