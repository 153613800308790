import JiraIcon from './customIcons/Jira'
import SubprocessIcon from './customIcons/Subprocess'
import TaskIcon from './customIcons/TaskIcon'
import ChangeShapeIcon from './customIcons/ChangeShapeIcon'
import DecisionIcon from './customIcons/DecisionIcon'
import ConnectionIcon from './customIcons/ConnectionIcon'
import OrganisationIcon from './customIcons/OrganisationIcon'
import ProjectIcon from './customIcons/ProjectIcon'
import SubworkflowIcon from './customIcons/SubworkflowIcon'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
import { mdi } from 'vuetify/iconsets/mdi'
import { h } from 'vue'

const customSvgNameTocomponent = {
  JiraIcon,
  SubprocessIcon,
  TaskIcon,
  ChangeShapeIcon,
  DecisionIcon,
  ConnectionIcon,
  OrganisationIcon,
  ProjectIcon,
  SubworkflowIcon
}

const customSvgs = {
  component: (props) => h(customSvgNameTocomponent[props.icon])
}

export default createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        colors: {
          background: '#F1F2F2',
          primary: '#022A44',
          secondary: '#229AD6',
          accent: '#C5539F',
          info: '#229AD6',
          error: '#FF5252'
        }
      }
    }
  },
  icons: {
    defaultSet: 'mdi',
    sets: {
      mdi,
      custom: customSvgs
    }
  }
})
