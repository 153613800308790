import gql from 'graphql-tag'

export default gql`
  subscription OnDeleteRequirementAttachment($workflowId: String) {
    onDeleteRequirementAttachment(workflowId: $workflowId) {
      id
      requirementId
      workflowId
    }
  }
`
