import gql from 'graphql-tag'

export default gql`
  mutation CreateRequirementType($workflowId: ID!, $input: RequirementTypeInput!) {
    createRequirementType(workflowId: $workflowId, input: $input) {
      id
      slot
      title
      description
      color {
        red
        green
        blue
        rgb
      }
      showInDiagram
      showInBacklog
      workflowId
      triggerType
      aggregateType
      defaultType
      aiCount
    }
  }
`
