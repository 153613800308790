<template>
  <div class="auth-card-wrapper">
    <v-card class="auth-card">
      <div class="auth-card-container">
        <div class="d-flex align-center justify-space-between w-100 logos">
          <img
            class="logo"
            alt="Qlerify logo"
            src="@/assets/qlerify-logo.png"
          />
          <div class="gl-homes-logo">
            <a href="https://www.glhomes.com/" target="_blank">
              <img
                class="logo"
                alt="GL Homes logo"
                src="@/assets/gl-homes.svg"
              />
            </a>
          </div>
        </div>
        <h3 class="login-title">Sign In</h3>
      </div>

      <div class="auth-card-container" style="margin-bottom: 24px">
        <v-btn class="gl-homes-cta" @click="signIn" style="border-radius: 8px!important;">
          <img
            alt="Microsoft logo"
            src="@/assets/ms-symbollockup_signin_dark.svg"
          />
        </v-btn>
      </div>

      <div class="auth-card-footer">
        <div class="auth-card-footer-part">
          <p class="text">
            Use your GL Homes Microsoft account to log in to Qlerify.
          </p>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import store from '@/store'
import { Auth } from '@aws-amplify/auth'
import { authHelpers } from '@/api/authHelpers'

export default {
  data () {
    return {
      e1: true
    }
  },
  created () {
    if (this.$route.query.username !== '') {
      this.email = this.$route.query.username
    }
  },
  async mounted () {
    if (this.$route.query.thanks) {
      // window.gtag('event', 'conversion', { send_to: 'AW-676520547/ZI1ACJashMwBEOPEy8IC' })
    }
    if (this.$route.query.username) {
      this.$refs.password.focus()
    }
    if (Auth && this.$route.query.googleSignIn) {
      try {
        const currentUser = await Auth.currentAuthenticatedUser()
        if (currentUser) {
          try {
            this.$loadingState.start()

            const sessionIdInfo = currentUser.signInUserSession.idToken.payload
            await this.initializeSession(sessionIdInfo)
          } catch (err) {
            this.$loadingState.stop()
            this.error = err.message.replace('GraphQL error: ', '')
            authHelpers.logoutAndClearStore()
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  methods: {
    signIn () {
      Auth.federatedSignIn({ customProvider: 'GLHOMES' })
    },
    async updateUser () {
      const attributes = await this.$cognitoAuth.getUserAttributes()
      const indexCompany = attributes.findIndex(item => item.Name === 'custom:organisation')
      const company = indexCompany >= 0 ? attributes[indexCompany].Value : null
      const indexReferralSource = attributes.findIndex(item => item.Name === 'custom:referral_source')
      const referralSource = indexReferralSource >= 0 ? attributes[indexReferralSource].Value : null
      const indexUsageIntent = attributes.findIndex(item => item.Name === 'custom:usage_intent')
      const usageIntent = indexUsageIntent >= 0 ? attributes[indexUsageIntent].Value : null
      const indexFirstname = attributes.findIndex(item => item.Name === 'given_name')
      const firstname = attributes[indexFirstname].Value
      const indexLastname = attributes.findIndex(item => item.Name === 'family_name')
      const lastname = attributes[indexLastname].Value
      const indexEmail = attributes.findIndex(item => item.Name === 'email')
      const email = attributes[indexEmail].Value
      const indexPhoneNumber = attributes.findIndex(item => item.Name === 'phone_number')
      const phoneNumber = indexPhoneNumber >= 0 ? attributes[indexPhoneNumber].Value : null
      const queryResult = await store.dispatch('user/getCurrentUser')
      const variables = {
        firstname,
        lastname,
        email,
        company,
        referralSource,
        usageIntent,
        phoneNumber,
        increaseLoginCounter: true
      }
      if (queryResult.data.getCurrentUser) {
        await store.dispatch('user/updateUser', variables)
      } else {
        await store.dispatch('user/createUser', variables)
        window.gtag_report_conversion()
        if (company) {
          await store.dispatch('organisations/createOrganisation', { name: company })
        }
        await store.dispatch('teamMembers/convertInvitesToTeamMembers')
        this.$store.dispatch('projectMemberships/listProjectMembershipsForCurrentUser')
      }
    },
    async initializeSession (sessionIdInfo) {
      let adminUser = false
      if (sessionIdInfo['cognito:groups']) {
        if (sessionIdInfo['cognito:groups'][0] === 'Admin') {
          adminUser = true
        }
      }
      store.commit('login', { email: sessionIdInfo.email, adminUser, userId: sessionIdInfo['cognito:username'] })
      await this.updateUser()
      this.$loadingState.stop()
      const path = `/dashboard/${this.$route.query.projectId ? this.$route.query.projectId : ''}`
      await this.$router.push({ path })
    }
  }
}
</script>

<style scoped>
.gl-homes-cta {
  height: auto !important;
  padding: 0 !important;
  transition: 0.2s filter ease-in-out !important;

  &:hover {
    filter: brightness(130%);
    -webkit-filter: brightness(130%);
  }
}

.auth-card-wrapper {
  padding-top: 133px;
  padding-bottom: 124px;
}

.auth-card {
  width: 444px;
  margin: 0 auto;
  border-radius: 12px;
  background: #FCFCFC;
  padding: 12px 16px 16px;
  border: 1px solid #FCFCFC;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);

  .auth-card-container {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .auth-card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .auth-card-footer-part {
      gap: 10px;
      display: flex;
      align-items: center;
    }
  }
}

.text {
  color: #1D1F22;
  font-size: 13px;
  text-align: left;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 14.3px;
  letter-spacing: -0.03em;
  font-family: "Inter", sans-serif
}

.logos {
  margin-bottom: 24px;
}

.logo {
  width: 110px;
  height: 48px;
}

.gl-homes-logo {
  padding: 0 12px;
  border-radius: 8px;
}

.login-title {
  color: #1F2833;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  letter-spacing: -0.03em;
  font-family: "Roboto", sans-serif;
}
</style>
