import { createStore } from 'vuex'
import { snackbar } from './snackbar/snackbar.js'
import { loading } from './loading/loading.js'
import { dialog } from './dialog/dialog.js'
import { workflows } from './workflows/workflows.js'
import { workflow } from './workflow/workflow.js'
import { workflowHistory } from './workflowHistory/workflowHistory.js'
import { teamMembers } from './teamMembers/teamMembers.js'
import { projectMemberships } from './teamMembers/projectMemberships.js'
import { organisations } from './organisations/organisations.js'
import { project } from './project/project.js'
import { requirementTypes } from './requirementTypes/requirementTypes.js'
import { user } from './user/user.js'
import { jira } from './jira/jira.js'
import { svgGraphData } from './workflow/svgGraphData.js'
import { synchronizedScrolling } from './synchronizedScrolling/synchronizedScrolling.js'
import { boundedContexts } from './workflow/boundedContexts.js'

export default createStore({
  strict: true,
  state: {
    isUserLoggedIn: localStorage.getItem('isUserLoggedIn') === 'true' || false,
    userInfo: localStorage.getItem('userInfo') || null,
    adminUser: localStorage.getItem('adminUser') === 'true' || false,
    userId: localStorage.getItem('userId') || null,
    svgGraph: {},
    svgLanes: {},
    isWorkflowDisabled: false,
    wsMessageForCypress: null
  },
  mutations: {
    login (state, data) {
      state.isUserLoggedIn = true
      state.userInfo = data.email
      state.adminUser = data.adminUser
      state.userId = data.userId

      localStorage.setItem('isUserLoggedIn', 'true')
      localStorage.setItem('userInfo', data.email)
      localStorage.setItem('adminUser', data.adminUser)
      localStorage.setItem('userId', data.userId)
    },
    logout (state) {
      state.isUserLoggedIn = false
      state.userInfo = ''
      state.adminUser = false
      state.userId = null

      localStorage.removeItem('isUserLoggedIn')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('adminUser')
      localStorage.removeItem('userId')

      state.workflow.workflow = {}
      state.workflows.workflows = null
      state.workflows.demoWorkflows = null
      state.workflowHistory.workflowHistory = null
      state.teamMembers.teamMembers = []
      state.projectMemberships.projectMemberships = null
      state.organisations.organisations = null
      state.requirementTypes.requirementTypes = null
      state.user.user = null
      state.svgGraph = {}
      state.svgLanes = {}
      state.isWorkflowDisabled = false
      state.wsMessageForCypress = null
      state.jiraIssues = null
      state.boundedContexts.boundedContexts = {}
    },
    setWorkflowDisabled (state, data) {
      state.isWorkflowDisabled = data
    },
    setWsMessageForCypress (state, data) {
      state.wsMessageForCypress = data
    },
    updateSvgGraph (state, data) {
      state.svgGraph = data
    },
    updateSvgGraphNode (state, { nodeId, nodeData }) {
      state.svgGraph[nodeId] = nodeData
    },
    addSvgGraphDataModelAtBeginning (state, { eventId, name, typeGroup, dataFields }) {
      if (!state.svgGraph[eventId]) {
        state.svgGraph[eventId] = { dataModels: [] }
      }

      state.svgGraph[eventId].dataModels.unshift({
        name,
        type: typeGroup,
        dataFields
      })
    },
    addSvgGraphDataFieldAtIndex (state, { eventId, index1, index2, dataField }) {
      state.svgGraph[eventId].dataModels[index1].dataFields.splice(index2 + 1, 0, dataField)
    },
    resetSvgGraphDataModels (state, { eventId }) {
      state.svgGraph[eventId].dataModels = []
    },
    updateSvgGraphDataField (state, { eventId, indexDataModel, indexDataField, fieldName, value }) {
      state.svgGraph[eventId].dataModels[indexDataModel].dataFields[indexDataField][fieldName] = value
    },
    updateSvgGraphDataModelField (state, { eventId, indexDataModel, fieldName, value }) {
      state.svgGraph[eventId].dataModels[indexDataModel][fieldName] = value
    },
    removeSvgGraphDataModelOnIndex (state, { eventId, dataModelIndex }) {
      // Ensure that the necessary properties exist before attempting to remove
      if (
        state.svgGraph[eventId] &&
        state.svgGraph[eventId].dataModels &&
        state.svgGraph[eventId].dataModels[dataModelIndex]
      ) {
        // Remove the dataField at the specified index
        state.svgGraph[eventId].dataModels.splice(dataModelIndex, 1)
      }
    },
    removeSvgGraphDataFieldOnIndex (state, { eventId, dataModelIndex, dataFieldIndex }) {
      // Remove the dataField at the specified index
      state.svgGraph[eventId].dataModels[dataModelIndex].dataFields.splice(dataFieldIndex, 1)
    },
    moveSvgGraphDataField (state, { eventId, index1, index2, field }) {
      if (state.svgGraph[eventId] &&
        state.svgGraph[eventId].dataModels &&
        state.svgGraph[eventId].dataModels[index1] &&
        state.svgGraph[eventId].dataModels[index1].dataFields) {
        // Assuming dataFields is an array
        state.svgGraph[eventId].dataModels[index1].dataFields[index2] = field
      }
    },
    updateSvgLanes (state, data) {
      state.svgLanes = data
    }
  },
  actions: {
  },
  modules: {
    snackbar,
    loading,
    dialog,
    workflows,
    workflow,
    workflowHistory,
    teamMembers,
    projectMemberships,
    organisations,
    project,
    requirementTypes,
    user,
    jira,
    svgGraphData,
    synchronizedScrolling,
    boundedContexts
  }
})
