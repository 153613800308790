<template>
  <v-dialog
    :modelValue="open"
    @update:modelValue="open = $event"
    max-width="290"
  >
    <v-card>
      <v-row fluid justify-end align-top>
        <v-spacer></v-spacer>
        <v-col class="flex-grow-0 flex-shrink-1">
          <v-btn variant="text" icon color="grey" @click="$emit('close')">
            <v-icon >mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-card-text class="py-0">
        There is more than one connection that can be removed, which one do you want to remove?
        <v-list>
          <v-list-item
            v-for="(target, i) in connections"
            :key="i"
            @click="$emit('delete-connection', target.parentId, target.childId)"
          >
            <v-list-item-title>{{ target.eventName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          variant="text"
          @click="$emit('close')">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    open: Boolean,
    connections: Array
  }
}
</script>
