import gql from 'graphql-tag'

export default gql`
  mutation updateUserStatusWorkflow($workflowId: String!, $vote: Int!, $message: String, $projectId: String!) {
    updateUserStatusWorkflow(
      projectId: $projectId
      workflowId: $workflowId
      vote: $vote
      message: $message)
    {
      workflowId
      userId
      vote
      message
      lastUpdate
    }
  }
`
