<template>
  <v-card v-if="!loadingState" class="mb-5">
    <div
      @keydown.stop
      @mousedown.stop="changeSelectedEvent(null)"
      @click.prevent="changeSelectedEvent(null)"
    >
      <v-toolbar flat color="white" height="48" @click.stop @mousedown.stop>
        <v-col cols="4" class="pt-3 d-flex subheading font-weight-bold align-center">
          <div class="mx-2 d-flex">
            <v-icon color="primary">mdi-alpha-a-box</v-icon>&nbsp;
            Aggregates
          </div>
          <workflow-help-dialog-aggregates
            v-slot="{ openDialog }"
            content-class="dialog-top-right"
          >
            <v-btn variant="text" icon="mdi-help-circle-outline" @click="openDialog" class="mr-2"></v-btn>
          </workflow-help-dialog-aggregates>
        </v-col>
        <v-spacer></v-spacer>
        <v-tooltip location="top" open-delay="1000">
          <template v-slot:activator="{ on }">
            <span v-on="{ ...on }">
              <v-checkbox
                v-model="showFields"
                label="Show data fields"
                hide-details
                class="mx-4"
              ></v-checkbox>
            </span>
          </template>
          <span>Show data fields from Data Models</span>
        </v-tooltip>
        <v-tooltip location="top" open-delay="1000">
          <template v-slot:activator="{ on }">
            <span v-on="{ ...on }">
              <v-checkbox
                v-model="showEntities"
                label="Show entities"
                hide-details
                class="mx-4"
              ></v-checkbox>
            </span>
          </template>
          <span>Show Entities</span>
        </v-tooltip>
        <!-- <v-checkbox
          :input-value="showActionsAndAggregates"
          @change="showActionsAndAggregates = !showActionsAndAggregates; $router.push({ query: { selectedTab: 3, showActionsAndAggregates: showActionsAndAggregates } })"
          label="Show Actions and Aggregates in the workflow diagram"
          hide-details
          class="mx-4"
        ></v-checkbox> -->
      </v-toolbar>

      <template v-for="(boundedContext, boundedContextIndex) in boundedContexts" :key="boundedContextIndex">
        <div v-if="boundedContext.name != null" class="bounded-context mx-5 mt-5 mb-10">
          <v-tabs
            class="bounded-context-tabs"
            v-model="boundedContextTabs[boundedContext.name]"
            density="compact"
            bg-color="grey-lighten-4"
          >
            <v-text-field
              v-if="renameBoundedContextId === boundedContext.id"
              class="mx-5 mt-n4 pa-0"
              autofocus
              hide-details
              variant="underlined"
              :rules="[value => (value.length <= 40) || 'Max 40 characters']"
              v-model="renameBoundedContextName"
              v-on:keyup.enter="$event.target.blur()"
              @focus="$event.target.select()"
              @blur="renameBoundedContext()"
            />
            <h3
              v-else
              class="px-5"
              style="padding-top: 7px;"
              @click="renameBoundedContextId = boundedContext.id; renameBoundedContextName = boundedContext.name"
            >{{ boundedContext.name }}</h3>

            <div v-show="renameBoundedContextId !== boundedContext.id">
              <v-tooltip location="top" open-delay="1000">
                <template v-slot:activator="{ props }">
                  <v-tab v-bind="props" value="diagram" :width="24" :ripple="false"><v-icon size="x-large">mdi-file-tree</v-icon></v-tab>
                </template>

                <span>
                  Click here to view a diagram of your interfaces, data models and aggregates
                </span>
              </v-tooltip>
              <v-tooltip location="top" open-delay="1000">
                <template v-slot:activator="{ props }">
                  <v-tab v-bind="props" value="code" :ripple="false" ><v-icon size="x-large">mdi-code-block-tags</v-icon></v-tab>
                </template>

                <span>
                  Click here to generate API code and documentation for the aggregates inside this bounded context
                </span>
              </v-tooltip>
            </div>
            <v-spacer></v-spacer>
            <h4 style="user-select: none;" class="text-right mx-5 pt-2 text-grey">Bounded Context</h4>
          </v-tabs>

          <div v-if="boundedContextTabs[boundedContext.name] === 'code'">
            <workflow-aggregates-generate-code :boundedContext="boundedContext" />
          </div>

          <div v-else>
            <template v-if="boundedContext.systems?.length">
              <workflow-aggregates-bounded-context-header/>
            </template>
            <template v-else>
              <div class="pa-5 d-flex">
                <span class="pt-3">No systems in this bounded context</span>
                <v-spacer></v-spacer>
                <v-btn icon="mdi-delete" @click="confirmDeleteBoundedContext(boundedContext.id)"/>
              </div>
            </template>

            <template
              v-for="(system, systemIndex) in boundedContext.systems"
              :key="'bc' + boundedContextIndex + '-system' + systemIndex"
            >
              <workflow-aggregate
                :system="system"
                :systemIndex="system.index"
                :showFields="showFields"
                :showEntities="showEntities"
                :boundedContexts="boundedContexts"
                :refreshTrigger="refreshKey"
              />
            </template>
          </div>
        </div>
        <div v-if="boundedContext.name == null" class="ma-5">
          <workflow-aggregates-bounded-context-header/>
          <template
            v-for="(system, systemIndex) in boundedContext.systems"
            :key="'bc' + boundedContextIndex + '-system' + systemIndex"
          >
            <workflow-aggregate
              :system="system"
              :systemIndex="system.index"
              :showFields="showFields"
              :showEntities="showEntities"
              :boundedContexts="boundedContexts"
              :refreshTrigger="refreshKey"
            />
          </template>
        </div>
      </template>
      <v-dialog v-model="showDeleteDialog" max-width="500">
        <v-card>
          <v-card-title>Confirm Delete</v-card-title>
          <v-card-text>
            Are you sure you want to delete this bounded context?
            Any code generated for this bounded context will be deleted.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn variant="text" @click="showDeleteDialog = false; deleteBoundedContext(deleteBoundedContextId)">Delete</v-btn>
            <v-btn variant="text" @click="showDeleteDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import WorkflowHelpDialogAggregates from '@/components/WorkflowHelpDialogAggregates'
import { diagramHelpers } from '@/mixins/diagramHelpers'
import { sanitizeCardDescription } from '@/utils/sanitize'
import { mapState, mapGetters } from 'vuex'
import WorkflowAggregate from '@/components/WorkflowAggregate.vue'
import WorkflowAggregatesGenerateCode from '@/components/WorkflowAggregatesGenerateCode.vue'
import WorkflowAggregatesBoundedContextHeader from './WorkflowAggregatesBoundedContextHeader.vue'

export default {
  mixins: [diagramHelpers],
  components: {
    WorkflowHelpDialogAggregates,
    WorkflowAggregate,
    WorkflowAggregatesGenerateCode,
    WorkflowAggregatesBoundedContextHeader
  },
  props: ['svgGroups'],
  data () {
    return {
      refreshKey: 0,
      showFields: false,
      showEntities: false,
      showDeleteDialog: false,
      deleteBoundedContextId: null,
      renameBoundedContextId: null,
      renameBoundedContextName: '',
      showActionsAndAggregates: this.$route.query?.showActionsAndAggregates === 'true',
      boundedContextTabs: {}
    }
  },
  computed: {
    ...mapState({
      requirementTypes: state => state.requirementTypes.requirementTypes,
      workflow: state => state.workflow.workflow
    }),
    ...mapGetters({
      loadingState: 'loading/getLoadingState'
    }),
    events () {
      const events = this.workflow.eventsJson || []
      return events.filter(event => event.type === 'bpmn:Task')
    },

    sortedEvents () {
      // create a copy of the events array and sort it
      const res = [...this.events].sort((a, b) => a.x - b.x)
      return res
    },

    systems () {
      const triggerTypeName = this.getTriggerTypeName
      const systemTypeName = this.getSystemTypeName
      const systems = []
      for (const event of this.sortedEvents) {
        const systemCard = this.getSystemCard(event, systemTypeName)
        let triggerCard = this.getTriggerCard(event, triggerTypeName)
        // adding eventId to be able to link to the event when generating code
        triggerCard = { ...triggerCard, eventId: event.id }
        const index = systemCard && systems.findIndex(item => {
          return item.systemCard && sanitizeCardDescription(item.systemCard.description) === sanitizeCardDescription(systemCard.description)
        })

        const laneIndex = this.workflow.lanes.findIndex(lane => lane.id === event.laneId)
        const prevRole = Object.assign({ laneIndex }, this.workflow.lanes[laneIndex])
        const dataFields = event.dataModels?.find(model => model.type === 'Event Story')?.dataFields || []

        if (systemCard && index >= 0) {
          // group on prevRole inside one system, if the prevRole is in this
          // system, insert data after the prevRole othewiwe insert at the end
          systems[index].systemCardIds.push(systemCard.id)
          const roleIndex = systems[index].roles.findLastIndex(item => item.id === prevRole.id)
          if (roleIndex >= 0) {
            systems[index].actions.splice(roleIndex + 1, 0, triggerCard)
            systems[index].events.splice(roleIndex + 1, 0, event)
            systems[index].roles.splice(roleIndex + 1, 0, prevRole)
            dataFields.forEach(it => systems[index].dataFields.set(it.name, it))
          } else {
            systems[index].actions.push(triggerCard)
            systems[index].events.push(event)
            systems[index].roles.push(prevRole)
            dataFields.forEach(it => systems[index].dataFields.set(it.name, it))
          }
        } else {
          systems.push({
            systemCardIds: systemCard?.id ? [systemCard.id] : [],
            systemCard,
            actions: triggerCard ? [triggerCard] : [],
            events: event ? [event] : [],
            roles: [prevRole],
            dataFields: new Map(dataFields.map(it => [it.name, it]))
          })
        }
      }

      systems.forEach(it => {
        it.dataFields = Array.from(it.dataFields.values())
      })
      return systems
    },

    boundedContexts () {
      const result = JSON.parse(JSON.stringify(this.workflow.boundedContexts || []))
      const notInBC = { name: null, systems: [] }
      const bcMap = {}
      result.forEach(it => {
        bcMap[it.id] = it
      })

      this.systems.forEach((system, systemIndex) => {
        let bc = bcMap[system.systemCard?.boundedContext]
        if (!bc) {
          bc = notInBC
        }

        if (!bc.systems) {
          bc.systems = []
        }

        bc.systems.push({ ...system, index: systemIndex })
      })

      // Place systems that don't have a bounded context at the bottom
      result.push(notInBC)

      return result
    },

    // // get the title of the RequirementType that has the attribute triggerType set to true
    getTriggerTypeName () {
      const res = this.requirementTypes?.find(type => type.triggerType)
      return res?.title
    },

    getSystemTypeName () {
      const res = this.requirementTypes?.find(type => type.aggregateType)
      return res?.title
    }
  },
  methods: {
    getSystemCard (event, systemTypeName) {
      if (!systemTypeName) return null
      const card = event.requirementsJson?.find(card => card.cardType?.title === systemTypeName)
      return card
    },
    getTriggerCard (event, triggerTypeName) {
      if (!triggerTypeName) return null
      const card = event.requirementsJson?.find(card => card.cardType?.title === triggerTypeName)
      return card
    },
    renameBoundedContext () {
      const bcIndex = this.workflow.boundedContexts.findIndex(it => it.id === this.renameBoundedContextId)
      let bc = null
      if (bcIndex >= 0) {
        bc = this.workflow.boundedContexts[bcIndex]
      }

      if (bc && this.renameBoundedContextName && bc.name !== this.renameBoundedContextName) {
        if (this.workflow.boundedContexts.find(it => it.name === this.renameBoundedContextName)) {
          this.$store.commit('snackbar/showMessage', { content: 'There is already a bounded context named: ' + this.renameBoundedContextName, timeout: 6000, color: 'red', centered: true }, { root: true })
        } else {
          const newBoundedContexts = this.workflow.boundedContexts.filter(it => it.id !== bc.id)
          newBoundedContexts.splice(bcIndex, 0, { id: bc.id, name: this.renameBoundedContextName })

          this.$store.dispatch('workflow/updateWorkflow', {
            projectId: this.workflow.projectId,
            input: {
              id: this.workflow.id,
              expectedVersion: this.workflow.version,
              boundedContexts: newBoundedContexts,
              updateNote: {
                action: 'renamed bounded context',
                target: name
              }
            }
          })
        }
      }
      this.renameBoundedContextId = null
      this.renameBoundedContextName = ''
    },
    confirmDeleteBoundedContext (id) {
      this.showDeleteDialog = true
      this.deleteBoundedContextId = id
    },
    async deleteBoundedContext (id) {
      const bc = this.workflow.boundedContexts.find(it => it.id === id)
      if (bc) {
        const name = bc.name
        const boundedContexts = this.workflow.boundedContexts.filter(it => it.id !== id)
        // TODO: delete all the files associated with this!
        await this.$store.dispatch('workflow/updateWorkflow', {
          projectId: this.workflow.projectId,
          input: {
            id: this.workflow.id,
            expectedVersion: this.workflow.version,
            boundedContexts,
            updateNote: {
              action: 'deleted bounded context',
              target: name
            }
          }
        })
      }
    }
  },
  watch: {
    boundedContextTabs: {
      deep: true,
      handler () {
        this.refreshKey++
      }
    },
    boundedContexts () {
      this.refreshKey++
    },
    systems () {
      this.refreshKey++
    },
    showFields () {
      this.refreshKey++
    },
    showEntities () {
      this.refreshKey++
    },
    'workflow.version' () {
      this.refreshKey++
    }
  }
}
</script>

<style scoped>
.bounded-context {
  background-color: rgb(251, 251, 251);
  border: 2px solid rgb(54, 50, 50);
  border-radius: 10px;
  overflow: hidden;
}

.bounded-context-tabs .v-tab {
  margin: 0 2px;
  padding: 0;
  width: 32px;
  min-width: 32px;
}
</style>
