import { sanitizeCardDescription } from '@/utils/sanitize'

export default class ApiSpecificationBuilder {
  constructor (spec, res = '') {
    this.indent = 0
    this.res = res
    this.spec = spec || {}
  }

  toString () {
    return this.res.trim()
  }

  pushInfo () {
    this.beginObject('info')
    this.pushValue('name', 'info.name')
    this.pushValue('framework', 'info.framework', false)
    this.pushValue('database', 'info.database', false)
    this.pushValue('language', 'info.language', false)
    this.pushValue('runtime', 'info.runtime', false)
    this.endObject()
  }

  updateInfo () {
    const rem = this.removeObject('info')
    this.pushInfo()
    this.res += rem
  }

  pushKeyValuePairs (keyValuePair) {
    Object.entries(keyValuePair).forEach(([key, valueKey]) => {
      this.pushValue(key, valueKey, false)
    })
  }

  pushDependencies () {
    this.pushArray('dependencies', 'dependencies')
  }

  updateDependencies () {
    const rem = this.removeObject('dependencies')
    this.pushDependencies()
    this.res += rem
  }

  pushEnvironment () {
    this.pushArray('environment', 'environment')
  }

  updateEnvironment () {
    const rem = this.removeObject('environment')
    this.pushEnvironment()
    this.res += rem
  }

  pushEndpoints (boundedContext, includeUserStories) {
    if (boundedContext.systems) {
      this.beginObject('api endpoints')
      boundedContext.systems.forEach(system => {
        system.actions.forEach(action => {
          const event = system.events.find(event => event.id === action.eventId)
          const dataModel = event.dataModels?.find(dataModel => dataModel.type === 'Event Story')
          const dataFields = dataModel?.dataFields || []

          this.beginObject('- ' + this.sanitize(action.description))
          this.pushString('path: /api/v1/' + this.sanitize(action.description).replaceAll(' ', '-').toLowerCase())
          let method = 'GET'
          for (const field of dataFields) {
            if (field.tags?.includes('WRITE')) {
              method = 'POST'
              break
            }
          }
          this.pushString(`method: ${method}`)
          this.beginObject('request')
          this.pushString('body format: JSON')
          this.beginObject('body params')
          dataFields.forEach(field => {
            if (field.tags?.includes('WRITE')) {
              this.pushString('- ' + this.toCamelCase(field.name))
            }
          })
          this.endObject() // body params
          this.endObject() // request

          this.beginObject('response')
          dataFields.forEach(field => {
            this.pushString('- ' + this.toCamelCase(field.name))
          })
          this.endObject() // response

          if (includeUserStories) {
            this.beginObject('user stories or business requirements')
            event.requirementsJson.forEach(requirement => {
              if (!requirement.cardType.triggerType && !requirement.cardType.aggregateType) {
                this.pushString('- ' + this.sanitize(requirement.description))
              }
            })
            this.endObject()
          }

          this.endObject()
        })
      })
      this.endObject()
    } else {
      this.pushString('api endpoints: null')
    }

    this.beginObject('api endpoint response format')
    this.pushString('format: JSON')
    this.pushString('success: { success: true, data: { RESPONSE_DATA } }')
    this.pushString('error: { success: false, message: "ERROR_MESSAGE" }')
    this.endObject()
  }

  pushModels (boundedContext, dataModels) {
    if (boundedContext.systems) {
      this.beginObject('models')
      boundedContext.systems.forEach(system => {
        // Push aggregate root model
        this.beginObject(`- name: ${this.sanitize(system.systemCard.description)}`, true)
        this.pushString('note: Aggreagate Root Model, make sure to include sensible defaults for all fields.')
        this.beginObject('attributes')
        system.dataFields.forEach(dataField => {
          this.pushString('- ' + this.toCamelCase(dataField.name))
        })
        this.endObject()
        this.endObject()

        // Push data models in our bounded context
        if (dataModels) {
          dataModels.forEach(dataModel => {
            if (dataModel.boundedContext === boundedContext.id) {
              this.beginObject(`- name: ${dataModel.name}`, true)
              this.beginObject('attributes')
              dataModel.dataFields.forEach(dataField => {
                this.pushString('- ' + this.toCamelCase(dataField.name))
              })
              this.endObject()
              this.endObject()
            }
          })
        }
      })
      this.endObject()
    } else {
      this.pushString('models: null')
    }
  }

  pushSecurity () {
    if (!this.spec['security.authentication'] && !this.spec['security.authorization'] &&
        !this.spec['security.dataValidation'] && !this.spec['security.errorHandling']) {
      this.pushString('security: null')
    } else {
      this.beginObject('security')
      this.pushValue('authentication', 'security.authentication', false)
      this.pushValue('authorization', 'security.authorization', false)
      this.pushValue('data validation', 'security.dataValidation', false)
      this.pushValue('error handling', 'security.errorHandling', false)
      this.endObject()
    }
  }

  updateSecurity () {
    const rem = this.removeObject('security')
    this.pushSecurity()
    this.res += rem
  }

  pushTesting () {
    if (!this.spec['testing.unitTests']) {
      this.pushString('testing: null')
    } else {
      this.beginObject('testing')
      this.pushValue('unit testing framework', 'testing.unitTests')
      this.beginObject('test cases')
      this.pushString('- Endpoint Tests: Test for successful and failed record creation, fetching records.')
      this.endObject() // test cases
      this.endObject() // testing
    }
  }

  updateTesting () {
    const rem = this.removeObject('testing')
    this.pushTesting()
    this.res += rem
  }

  pushDocumentation () {
    this.beginObject('documentation')
    this.pushValue('api documentation tool', 'documentation.api')
    this.beginObject('documentation details')
    this.pushString('- Detailed documentation for each endpoint including possible request and response examples.')
    this.pushString('- Model documentation describing fields and their types.')
    this.endObject() // documentation details
    this.endObject() // documentation
  }

  pushString (string) {
    this.pushNewLine()
    this.res += string
  }

  pushValue (name, valueKey, required = true) {
    let value = this.spec[valueKey]

    const isArray = typeof value === 'object' && Array.isArray(value) && value.length > 0
    if (isArray) {
      this.pushArray(name, valueKey, required)
    } else {
      const hasValue = typeof value === 'string' && value.length > 0
      if (hasValue || required) {
        this.pushNewLine()
        if (hasValue) {
          value = value.replace(/\n/g, '\\n')
        }
        this.res += `${name}: ${hasValue ? value : 'null'}`
      }
    }
  }

  pushArray (name, valueKey, required = true) {
    const array = this.spec[valueKey]
    const hasValue = Array.isArray(array) && array.length > 0
    if (hasValue || required) {
      this.pushNewLine()
      this.res += `${name}:`
      if (hasValue) {
        this.indent++
        array.forEach(item => {
          this.pushNewLine()
          this.res += '- ' + item
        })
        this.indent--
      } else {
        this.res += ' null'
      }
    }
  }

  beginObject (name, ignoreColon = false) {
    this.pushNewLine()
    this.res += name + (ignoreColon ? '' : ':')
    this.indent++
  }

  endObject () {
    this.indent--
  }

  removeObject (name) {
    let tmpRes = ''
    const lines = this.res.split('\n')
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].startsWith(`${name}:`)) {
        tmpRes = lines.slice(0, i).join('\n')
        i++
        while (i < lines.length && lines[i].startsWith('  ')) {
          i++
        }
        lines.splice(0, i)
      }
    }

    // Removes everything after the object and returns the remainder of the string
    this.res = tmpRes
    return '\n' + lines.join('\n')
  }

  pushNewLine () {
    this.res += '\n'
    for (let i = 0; i < this.indent; i++) {
      this.res += '  '
    }
  }

  sanitize (str) {
    return sanitizeCardDescription(str)
  }

  toCamelCase (str) {
    const words = str.split(' ')
    const camelCaseWords = words.map((word, index) => {
      if (index === 0) {
        return word.toLowerCase()
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    return camelCaseWords.join('')
  }
}
