<template>
  <div>
    <navigation-top />
    <v-card>
      <v-tabs>
        <v-tab ripple> Workflows </v-tab>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          variant="underlined"
          hide-details
          class="mt-0 pt-0 search-field"
        ></v-text-field>
        <v-spacer></v-spacer>

        <workflow-dialog
          ref="dialogWorkflow"
          buttonTheme="dark"
          buttonText="Create New Workflow"
        ></workflow-dialog>

      </v-tabs>
      <v-window>
        <v-card flat>
          <workflows-table
            class="round-bottom"
            :headers="headersWorkflows"
            :items="workflowsList"
            :search="search"
            must-sort
            :exclude="[]"
            :processing-request="processingRequest"
            :workflows-by-id="workflowsById"
            @edit="editItemWorkflow"
          ></workflows-table>
        </v-card>
      </v-window>
    </v-card>
  </div>
</template>

<script>
import WorkflowDialog from '@/components/WorkflowDialog'
import WorkflowsTable from '@/components/WorkflowsTable'
import NavigationTop from '../components/NavigationTop.vue'
import { mapState } from 'vuex'

export default {
  components: {
    WorkflowDialog,
    WorkflowsTable,
    NavigationTop
  },
  data: () => ({
    statuses: [
      'Under Construction', // status 0
      'Improve with feedback', // status 1
      'Completed' // status 2
    ],
    statusColors: [
      'blue-grey darken-2',
      'purple',
      'success'
    ],
    valid: true,
    rules: {
      required: value => !!value || 'Required.',
      counter: value => value.length <= 40 || 'Max 40 characters'
    },
    search: '',
    dialogWorkflow: false,
    headersWorkflows: [
      {
        title: '',
        key: 'data-table-expand',
        sortable: false,
        width: 40
      },
      {
        title: 'Name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      {
        title: 'Events',
        align: 'end',
        sortable: false,
        width: 75
      },
      {
        title: 'Members',
        align: 'end',
        sortable: false,
        width: 100
      },
      {
        title: 'Appr. rate',
        align: 'end',
        sortable: false,
        width: 120
      },
      {
        title: 'Lanes',
        align: 'start',
        sortable: false,
        width: 320
      },
      {
        title: 'Your approval',
        align: 'center',
        sortable: false,
        width: 120
      },
      {
        title: '',
        align: 'center',
        sortable: false,
        width: 125
      }
    ],
    editedIndexWorkflow: -1,
    editedItemWorkflow: {
      name: ''
    },
    defaultItemWorkflow: {
      name: ''
    },
    processingRequest: null
  }),

  computed: {
    ...mapState({ workflows: state => state.workflows.workflows || [] }),
    formTitleWorkflow () {
      return this.editedIndexWorkflow === -1 ? 'New Workflow' : 'Edit Workflow'
    },

    withChildren () {
      const withChildren = []
      if (!Array.isArray(this.workflows)) {
        return []
      }
      for (const workflow of this.workflows) {
        const w = { ...workflow, children: [], parents: [] }
        if (workflow.eventsJson) {
          for (const event of workflow.eventsJson) {
            if (event.subprocess) {
              w.children.push(event.subprocess.id)
            }
          }
        }
        withChildren.push(w)
      }
      for (const w of withChildren) {
        for (const w1 of withChildren) {
          const conditions = [
            w1.children.includes(w.id),
            !w1.parents.includes(w.id),
            w1.id !== w.id
          ]

          if (conditions.every(Boolean)) {
            w.parents.push(w1.id, ...w1.parents)
          }
        }
      }
      return withChildren
    },

    workflowsList () {
      return this.withChildren.filter(({ parents }) => !parents.length)
    },

    excludeIds () {
      return this.workflowsList.map(({ id }) => id)
    },

    workflowsById () {
      const result = {}

      for (const workflow of this.withChildren) {
        result[workflow.id] = workflow
      }
      return result
    }
  },
  watch: {
    dialogWorkflow (val) {
      val || this.closeWorkflow()
    }
  },

  methods: {
    getVote (userStatusesWorkflow) {
      let res = 0
      if (userStatusesWorkflow.findIndex) {
        const index = userStatusesWorkflow.findIndex(item => item.userId === this.$store.state.userId)
        if (index > -1) {
          res = userStatusesWorkflow[index].vote
        }
      }
      return res
    },

    filterUniqueRoles (events) {
      const res = new Set()
      if (Array.isArray(events) && events.length > 0) {
        for (const event of events) {
          res.add(event.laneId)
        }
      }
      return res
    },

    getApprovalRate (userStatusesWorkflow, noUsers) {
      if (userStatusesWorkflow && noUsers > 0) {
        const approvedCount = userStatusesWorkflow.filter(item => item.vote === 1).length
        return Math.round(100 * approvedCount / noUsers)
      } else {
        return 0
      }
    },
    editItemWorkflow (item) {
      this.$refs.dialogWorkflow.setItem(Object.assign({}, item))
      this.$refs.dialogWorkflow.openWorkflowDialog()
    },
    lanesDictionary (workflow) {
      const res = workflow.lanes && Object.assign({}, ...workflow.lanes.map((lane) => ({ [lane.id]: lane.name })))
      return res
    }
  }
}
</script>

<style>
.v-chip__content {
  font-size: 10px;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.hidden {
  display: none;
}

.search-field {
  align-self: center;
}
</style>
