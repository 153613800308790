import gql from 'graphql-tag'

export default gql`
  subscription OnUpdateRequirementComment($workflowId: String) {
    onUpdateRequirementComment(workflowId: $workflowId) {
      requirementId
      workflowId
      id
      text
      dateUpdated
    }
  }
`
