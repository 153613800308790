<template>
  <svg viewBox="0 0 2048 2048">
    <g transform="translate(0 995.638)">
      <rect width="1699.302" height="1400.778" x="174.349" y="-672.027" rx="266.951" stroke="blue" stroke-width="300" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M677.543 48.007V740.92h692.914V48.007H677.543zm63.473 63.472h565.968v565.97H741.016v-565.97zm235.505 80.502v157.701H818.817v94.963H976.52v157.702h94.958V444.645h157.704v-94.963H1071.479v-157.7h-94.957z" fill="currentColor"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'subworkflow-icon'
}
</script>

<style scoped>
rect {
  stroke: currentColor !important;
  fill: none !important;
  stroke-width: 200 !important;
}

path {
  stroke: currentColor !important;
  fill: none !important;
  stroke-width: 60 !important;
}

</style>
