import cognitoAuth from '@/cognito'
import { ApolloClient, ApolloLink, HttpLink, ApolloCache } from '@apollo/client'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { onError } from '@apollo/client/link/error'
import { EventBus } from '@/event-bus/event-bus.js'
import store from '@/store'

const emptyCacheObj = {}

let cypressRunning
try {
  if (Cypress) {
    cypressRunning = true
  }
} catch (e) {
  cypressRunning = false
}

export class VoidCache extends ApolloCache {
  read (options) { return null }
  write (options) { return undefined }
  diff (options) { return {} }
  watch (watch) { return () => {} }
  async reset () {}
  evict (options) { return false }
  restore (data) { return this }
  extract (optimistic) { return emptyCacheObj }
  removeOptimistic (id) {}
  batch (options) { return undefined }
  performTransaction (update, optimisticId) {}
  recordOptimisticTransaction (transaction, optimisticId) {}
  transformDocument (document) { return document }
  transformForLink (document) { return document }
  identify (object) { return undefined }
  gc () { return [] }
  modify (options) { return false }
  readQuery (options, optimistic) { return null }
  readFragment (options, optimistic) { return null }
  writeQuery (opts) { return undefined }
  writeFragment (opts) { return undefined }
  updateQuery (options, update) { return null }
  updateFragment (options, update) { return null }
}

loadDevMessages()
loadErrorMessages()

let url, region, auth
if (cypressRunning) {
  url = Cypress.env('VUE_APP_APPSYNC_GRAPHQL_ENDPOINT')
  region = Cypress.env('VUE_APP_APPSYNC_REGION')
  auth = {
    type: Cypress.env('VUE_APP_APPSYNC_AUTHENTICATION_TYPE'),
    jwtToken: async () => {
      return await cognitoAuth.getIdTokenPromise()
    }
  }
} else {
  url = process.env.VUE_APP_APPSYNC_GRAPHQL_ENDPOINT
  region = process.env.VUE_APP_APPSYNC_REGION
  auth = {
    type: process.env.VUE_APP_APPSYNC_AUTHENTICATION_TYPE,
    jwtToken: async () => {
      return await cognitoAuth.getIdTokenPromise()
    }
  }
}

const httpLink = new HttpLink({ uri: url })

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log('graphQLErrors', graphQLErrors)
    for (const err of graphQLErrors) {
      // Handle unauthorized error
      if (
        err?.errorType === 'Unauthorized' ||
        err?.errorType === 'UnauthorizedException'
      ) {
        console.log('Unauthorized! Redirecting to login...')
        EventBus.$emit('redirect-to-login')
        if (store?.commit) {
          store.commit('snackbar/showMessage', {
            content: 'Your session has expired. Please log in again to continue.',
            timeout: 6000,
            color: 'red',
            centered: true
          }, { root: true })
        }
      }
    }
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const link = ApolloLink.from([
  errorLink,
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink)
])

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
}

// Create the apollo client
const apolloClient = new ApolloClient({
  link,
  cache: new VoidCache(),
  defaultOptions,
  connectToDevTools: true
})

export { apolloClient } // also export the apollo client
