<template>
  <v-menu
    location="bottom"
    min-width="200px"
    offset="24"
    :close-on-content-click="false"
    class="rounded-lg"
    v-model="dialogOpen"
    @keydown.esc="dialogOpen = false"
  >
    <template #activator="{ props }">
      <slot :props="props" :open-dialog="openDialog" :pinTheDialog="pinTheDialog" @click="pinTheDialog"></slot>
    </template>

    <template #default>
      <v-card
        class="cards-dialog-content"
        @click="pinDialog = true"
        @mouseenter="clearTimeout"
        @mouseleave="onOverlayMouseover"
        :ripple="false"
        :link="false"
      >
        <v-card-text>
          <v-row class="flex-nowrap">
              <v-col v-for="cardType in typesByCards.withCards" :key="cardType.id" class="px-1 py-2 requirements-list">
                <div class="column-title">
                  <h4 class="text-overline text-center text-black">
                    {{ cardType.title }}
                  </h4>
                </div>

                <draggable
                  v-model="cardsByTypes[cardType.id]"
                  group="drop-target"
                  :data-type-id="cardType.id"
                  :disabled="$store.state.isWorkflowDisabled"
                  :sort="true"
                  @add="onDraggableAdd($event)"
                  @update="onDraggableUpdate(cardType.id, $event)"
                >
                  <requirement-description
                    v-for="(item, index) in cardsByTypes[cardType.id]"
                    class="mb-3"
                    :key="item.id"
                    :item="item"
                    :showAi="true"
                    :show-as-invisible="isCardTypeDisabled(cardType) && index > 0"
                    compact-replies
                    hideTypeSelector
                    @keydown.stop=""
                    @ask-for-reply="onAskForReply"
                  >
                    <requirement-card-description-field
                      :item="item"
                      :focus-card="focusCard"
                      @move-focus="moveFocus"
                      :scrollIntoViewArg="false"
                    ></requirement-card-description-field>
                  </requirement-description>
                </draggable>
                <div class="d-flex">
                  <div style="width: calc(100% - 42px);">
                    <v-btn
                      size="small"
                      :style="{
                        'border-color': getBackgroundColour(cardType) + ' !important',
                      }"
                      style="border-style: solid !important; border-width: 1px 0 1px 1px !important; border-radius: 10px 0px 0px 10px !important;"
                      block
                      @click="
                        submitCardForm(node.id, cardType.id, '<p>New card</p>')
                      "
                      :disabled="$store.state.isWorkflowDisabled || isCardTypeDisabled(cardType)"
                    >
                      + Add
                    </v-btn>
                  </div>
                  <div style="width: 42px;">
                    <v-tooltip location="top" open-delay="1000">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          size="small"
                          :style="{
                            'border-color': getBackgroundColour(cardType) + ' !important',
                          }"
                          style="border-style: solid !important; border-width: 1px 1px 1px 1px !important; border-radius: 0px 10px 10px 0px !important;"
                          block
                          @click="$emit('add-card-ai', node.id, cardType.id)"
                          :disabled="$store.state.isWorkflowDisabled || isCardTypeDisabled(cardType)"
                          v-bind="props"
                        >
                          <v-icon size="small">mdi-creation</v-icon>
                        </v-btn>
                      </template>
                      Add one card with the help of AI. NOTE: By clicking this button you will share information about your current workflow with Open AI.
                    </v-tooltip>
                  </div>
                </div>
              </v-col>

            <v-col
              v-if="typesByCards.withoutCards.length > 0"
              class="px-1 py-2 requirements-list"
            >
                <div v-for="cardType in typesByCards.withoutCards" :key="cardType.id" class="mb-4">
                  <div class="column-title">
                    <h4 class="text-overline text-center text-black">
                      {{ cardType.title }}
                    </h4>
                  </div>
                  <div class="d-flex">
                    <div style="width: 80%;">
                      <v-btn
                        size="small"
                        :style="{
                          'border-color': getBackgroundColour(cardType) + ' !important',
                        }"
                        style="border-style: solid !important; border-width: 1px 0 1px 1px !important; border-radius: 10px 0px 0px 10px !important;"
                        block
                        @click="
                          submitCardForm(node.id, cardType.id, '<p>New card</p>')
                        "
                        :disabled="$store.state.isWorkflowDisabled"
                      >
                        + Add
                      </v-btn>
                    </div>
                    <div style="width: 20%;">
                      <v-tooltip location="top" open-delay="1000">
                        <template v-slot:activator="{ props }">
                          <v-btn
                            size="small"
                            :style="{
                              'border-color': getBackgroundColour(cardType) + ' !important',
                            }"
                            style="border-style: solid !important; border-width: 1px 1px 1px 1px !important; border-radius: 0px 10px 10px 0px !important;"
                            block
                            @click="$emit('add-card-ai', node.id, cardType.id)"
                            :disabled="$store.state.isWorkflowDisabled"
                            v-bind="props"
                          >
                            <v-icon size="small">mdi-creation</v-icon>
                          </v-btn>
                        </template>
                        Add one card with the help of AI. NOTE: By clicking this button you will share information about your current workflow with Open AI.
                      </v-tooltip>
                    </div>
                  </div>
                </div>
            </v-col>
            <v-col
              v-if="node.dataModels?.length > 0"
              class="px-1 py-2 requirements-list"
            >
              <div class="column-title" v-if="dataFieldsRead.length > 0">
                <h4 class="text-overline text-center text-black">
                  Read Model
                </h4>
              </div>
              <template v-for="(dataField, index) in dataFieldsRead" :key="`${index}-write`">
                <v-text-field
                  :model-value="dataField.exampleData?.[0]"
                  :label="dataField.name"
                  variant="outlined"
                  readonly
                  density="compact"
                  class="text-body-3 small-font"
                ></v-text-field>
              </template>
              <div class="column-title" v-if="dataFieldsWriteCreate.length > 0">
                <h4 class="text-overline text-center text-black">
                  Write Model
                </h4>
              </div>
              <v-text-field
                v-for="(dataField, index) in dataFieldsWriteCreate"
                :key="`${index}-read`"
                :model-value="dataField.exampleData?.[0]"
                :label="dataField.name"
                variant="outlined"
                readonly
                density="compact"
                class="text-body-3 small-font"
              ></v-text-field>
              <v-btn @click="$router.push({
                path: '/workflow/' + $route.params.projectId + '/' + $route.params.id + '/' + node.id,
                query: { selectedTab: 2 }
              })" size="small" block>Goto data models</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </v-menu>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'
import midString from '@/utils/mid-string'
import workflowApi from '@/api/workflowApi'
import { EventBus } from '@/event-bus/event-bus.js'
import requirementTypesApi from '@/api/requirementTypesApi'
import RequirementDescription from '@/components/RequirementDescription'
import RequirementCardDescriptionField from '@/components/RequirementCardDescriptionField'

export default {
  components: {
    draggable: VueDraggableNext,
    RequirementDescription,
    RequirementCardDescriptionField
  },

  props: {
    typesToDisplayInDiagram: Array,
    node: Object,
    cards: {
      type: Array,
      default () {
        return []
      }
    },
    showDone: Boolean
  },

  data () {
    return {
      dialogOpen: false,
      pinDialog: false,
      cardsByTypes: {},
      textAreaFormLoading: false,
      focusCard: null,
      timer: null
    }
  },

  computed: {
    dataFieldsWrite () {
      const index = this.node.dataModels.findIndex(model => model.type === 'Event Story')
      if (index === -1) {
        return []
      }
      return this.node.dataModels?.[index].dataFields.filter(field => field.tags?.includes('WRITE'))
    },
    dataFieldsRead () {
      const index = this.node.dataModels.findIndex(model => model.type === 'Event Story')
      return this.node.dataModels?.[index].dataFields.filter(field => field.tags?.includes('READ'))
    },
    dataFieldsCreate () {
      const index = this.node.dataModels.findIndex(model => model.type === 'Event Story')
      if (index === -1) {
        return []
      }
      return this.node.dataModels?.[index].dataFields.filter(field => field.tags?.includes('CREATE'))
    },
    dataFieldsWriteCreate () {
      const index = this.node.dataModels.findIndex(model => model.type === 'Event Story')
      if (index === -1) {
        return []
      }
      const createFields = this.node.dataModels?.[index].dataFields.filter(field => field.tags?.includes('CREATE'))
      const writeFields = this.node.dataModels?.[index].dataFields.filter(field => field.tags?.includes('WRITE'))
      const union = [...new Set([...createFields, ...writeFields])]

      return union
    },
    typesByCards () {
      const cardTypes = new Set()

      if (this.node.requirementsJson) {
        this.node.requirementsJson.forEach(({ cardType, done }) => {
          if (Boolean(done) === this.showDone && cardType) {
            cardTypes.add(cardType.id)
          }
        })
      }

      return this.typesToDisplayInDiagram.reduce((result, cardType) => {
        if (cardTypes.has(cardType.id)) {
          result.withCards.push(cardType)
        } else {
          result.withoutCards.push(cardType)
        }

        return result
      }, {
        withCards: [],
        withoutCards: []
      })
    }
  },

  watch: {
    dialogOpen (value, oldValue) {
      if (value && !oldValue) {
        window.addEventListener('keydown', this.onKeyDown)

        const overlay = window.document.createElement('div')
        overlay.classList.add('menu-custom-overlay')

        const { left, top, width, height } = this.$el.parentElement.getBoundingClientRect()
        const summaryReflection = window.document.createElement('div')
        summaryReflection.style.position = 'absolute'
        summaryReflection.style.left = `${left}px`
        summaryReflection.style.top = `${top + window.scrollY}px`
        summaryReflection.style.width = `${width}px`
        summaryReflection.style.height = `${height + 8}px`
        summaryReflection.style.zIndex = '7'

        // Sometimes dialog appears with delay,
        // which causes 'close' event to be fired before
        // user actually sees the dialog.
        // So, I had to add a .5s delay for event listener
        // to prevent such behaviour
        setTimeout(() => {
          overlay.addEventListener('mouseover', this.onOverlayMouseover, { once: true })
        }, 400)

        this.$root.$el.appendChild(overlay)
        this.$root.$el.appendChild(summaryReflection)
        this.overlay = overlay
        this.summaryReflection = summaryReflection
      } else if (!value) {
        this.overlay.remove()
        this.summaryReflection.remove()
        this.pinDialog = false
        window.removeEventListener('keydown', this.onKeyDown)
      }
    },

    cards (newVal) {
      this.computeCardsByType(newVal)
    }
  },

  mounted () {
    this.computeCardsByType(this.cards)
    EventBus.$on('open-card-on-event', item => {
      if (item.eventId === this.node.id) {
        this.dialogOpen = true
        this.pinDialog = true
      }
    })
    EventBus.$on('pin-dialog', item => {
      if (item.eventId === this.node.id) {
        this.pinDialog = true
      }
    })
  },

  methods: {
    isCardTypeDisabled (cardType) {
      return cardType.aggregateType || cardType.triggerType
    },
    pinTheDialog () {
      this.pinDialog = true
    },
    clearTimeout () {
      clearTimeout(this.timer)
    },
    onAskForReply (event) {
      this.$emit('ask-for-reply', Object.assign({}, event, { node: this.node }))
    },
    moveFocus (direction, target) {
      target.firstChild.blur()
    },

    async submitCardForm (eventId, cardTypeId, text) {
      // this.$emit('submit-requirement-form', eventId, cardTypeId, event)
      this.textAreaFormLoading = true
      const res = await workflowApi.createRequirementJson(this.$route.params.id, eventId, {
        cardTypeId,
        description: text
      })

      this.textAreaFormLoading = false
      if (res.requirements?.length) {
        this.focusCard = res.requirements[0].id
      }
    },

    getBackgroundColour (cardType) {
      return requirementTypesApi.getBackgroundColour(cardType)
    },

    openDialog () {
      this.dialogOpen = true
    },

    onKeyDown (ev) {
      if (ev.keyCode === 27) { // close menu on ESC pressed
        this.dialogOpen = false
        this.pinDialog = false
      }
    },

    onOverlayMouseover () {
      if (!this.pinDialog) {
        this.timer = setTimeout(() => {
          this.dialogOpen = false
        }, 500)
      }
    },

    computeCardsByType (allCards) {
      const typeCards = {}

      if (!allCards) {
        return {}
      }

      // add all types
      this.typesToDisplayInDiagram.forEach(cardType => {
        typeCards[cardType.id] = []
      })

      allCards
        .filter(({ done }) => this.showDone === Boolean(done))
        .forEach(card => {
          const newCard = { ...card }
          newCard.eventId = this.node.id

          const { cardType } = card

          if (!cardType) {
            return
          }

          if (!typeCards[cardType.id]) {
            typeCards[cardType.id] = []
          }

          typeCards[cardType.id].push(newCard)
        })

      for (const cards of Object.values(typeCards)) {
        cards.sort((a, b) => {
          return a.sortkey < b.sortkey
            ? -1
            : a.sortkey > b.sortkey
              ? 1
              : 0
        })
      }

      this.cardsByTypes = typeCards
    },

    onDraggableAdd (ev) {
      const { item, to, newIndex } = ev
      const { cardId } = item.dataset
      const { typeId } = to.dataset

      const prevCard = this.cardsByTypes[typeId][newIndex - 1]
      const nextCard = this.cardsByTypes[typeId][newIndex + 1]
      const prevSortKey = prevCard ? prevCard.sortkey : ''
      const nextSortKey = nextCard ? nextCard.sortkey : ''
      const sortkey = midString(prevSortKey, nextSortKey)

      this.$emit('update-requirement', cardId, this.node.id, { cardType: typeId, sortkey })
    },

    onDraggableUpdate (cardTypeId, ev) {
      const { item, newIndex } = ev
      const { cardId } = item.dataset
      const card = this.node.requirementsJson.find(({ id }) => id === cardId)

      const prevCard = this.cardsByTypes[cardTypeId][newIndex - 1]
      const nextCard = this.cardsByTypes[cardTypeId][newIndex + 1]
      const prevSortKey = prevCard ? prevCard.sortkey : ''
      const nextSortKey = nextCard ? nextCard.sortkey : ''
      const sortkey = midString(prevSortKey, nextSortKey)

      this.$emit('update-requirement', card.id, this.node.id, { sortkey })
    }
  }
}
</script>

<style scoped>
.requirements-list {
  width: 250px;
}

.create-form {
  border-width: 1px;
  border-top-width: 8px;
  border-style: solid;
}

.column-title + .create-form {
  margin-top: 0 !important;
}

.cards-dialog-content.v-card--link::before {
  display: none;
}

.cards-dialog-content {
  cursor: default;
}
</style>

<style>
.menu-custom-overlay {
  position: fixed;
  background: transparent;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 7;
}

.small-font {
  font-size: 12px;
}

.small-font .v-label {
  font-size: 12px;
}
</style>
