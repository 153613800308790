import gql from 'graphql-tag'

export default gql`
  mutation DeleteRequirementAttachment($workflowId: String, $id: ID!) {
    deleteRequirementAttachment(workflowId: $workflowId, id: $id) {
      id
      requirementId
      workflowId
    }
  }
`
