import gql from 'graphql-tag'

export default gql`
  query listOrganisations {
    listOrganisations {
      id
      name
      userId
      user {
        id
        firstname
        lastname
      }
      createdAt
      updatedAt
    }
  }
`
