import gql from 'graphql-tag'
import { WORKFLOW_FIELDS } from '../fragments'

export default gql`
  ${WORKFLOW_FIELDS}
  mutation deleteRequirementJson($projectId: String!, $workflowId: String!, $eventId: String!, $eventIndex: Int!, $requirementId: ID!, $requirementIndex: Int!, $expectedVersion: Int!, $requirementDescription: String) {
    deleteRequirementJson(projectId: $projectId, workflowId: $workflowId, eventId: $eventId, eventIndex: $eventIndex, requirementId: $requirementId, requirementIndex: $requirementIndex, expectedVersion: $expectedVersion, requirementDescription: $requirementDescription) {
      ...WorkflowFields
    }
  }
`
