import store from '@/store'
import { examples } from '@/api/chatGPT/examples'
import { promptHelpers } from '@/api/chatGPT/promptHelpers'

let fetchController

export const chatGPT = {

  async fetchChatCompletion (messages, aiVersion, returnReader = false, streamText = false) {
    if (messages.length === 0) {
      throw new Error('Cannot get response without any prompt to ChatGPT')
    }

    try {
      fetchController = new AbortController()
      const response = await fetch(process.env.VUE_APP_CHAT_LAMBDA_URL, {
        signal: fetchController.signal,
        method: 'POST',
        body: JSON.stringify({
          aiVersion,
          messages,
          streamText
        })
      })

      if (response.ok) {
        if (returnReader) {
          return response.body.getReader()
        } else {
          const rawText = await response.text()
          const data = JSON.parse(rawText.replace('jsonobjectdelimiter', ''))
          return data
        }
      } else {
        throw new Error('Network response was not ok')
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        store.commit('snackbar/showMessage', { content: 'ChatGPT failed to respond to the query, please try again later', timeout: 6000, color: 'red' }, { root: true })
        console.error('Error:', error)
      }
    }
  },

  async abortFetch () {
    if (fetchController) {
      try {
        fetchController.abort()
      } catch (error) {
        console.log(error)
      }
    }
  },

  async fetchChat (content, aiVersion, returnReader = false) {
    const message = {
      role: 'user',
      content
    }
    return await this.fetchChatCompletion([message], aiVersion, returnReader)
  },

  async fetchNewDiagram (chat, workflow, requirementTypes, actionFunction) {
    const command = workflow.aiDescription
    const aiVersion = workflow.aiVersion
    if (!command) {
      store.commit('snackbar/showMessage', { content: 'Please enter a description in settings: Get help from AI to build your workflow', timeout: 6000, color: 'red', centered: true }, { root: true })
      return
    }

    chat.pushSystem(promptHelpers.actor(true))
    chat.pushUser(promptHelpers.requestNewDiagram(workflow, requirementTypes, command, workflow.aiVerbPastTense))

    const addedEvents = await chat.streamJsonResponses(actionFunction, aiVersion)
    chat.pushAssistant(JSON.stringify(addedEvents))

    return chat
  },
  async fetchAllCards (chat, workflow, requirementTypes, actionFunction) {
    const command = workflow.aiDescription
    const aiVersion = workflow.aiVersion
    if (!command) {
      store.commit('snackbar/showMessage', { content: 'Please enter a description in settings: Get help from AI to build your workflow', timeout: 6000, color: 'red', centered: true }, { root: true })
      return
    }
    chat.pushUser(promptHelpers.requestAllCardsForEachStep(workflow, requirementTypes))

    const addedCards = await chat.streamJsonResponses(actionFunction, aiVersion)
    chat.pop() // TODO: to avoid hitting the token limit we don't push this step in the chat log
    return addedCards
  },
  async fetchAllDataModels (chat, workflow, requirementTypes, actionFunction) {
    const command = workflow.aiDescription
    const aiVersion = workflow.aiVersion
    if (!command) {
      store.commit('snackbar/showMessage', { content: 'Please enter a description in settings: Get help from AI to build your workflow', timeout: 6000, color: 'red', centered: true }, { root: true })
      return
    }
    chat.pushUser(promptHelpers.requestDataModelsForEachStep(workflow, workflow.beamAiOptions.dataFieldCount, requirementTypes))

    const addedDataModels = await chat.streamJsonResponses(actionFunction, aiVersion)
    return addedDataModels
  },
  async fetchShape (parentId, bpm, requirementTypes) {
    const { workflow: currentDiagram, currentStep } = promptHelpers.diagramToJson(parentId, bpm.workflow, bpm.svgGraph, bpm.svgLanes)
    const formatOptions = {
      step: currentStep, shouldAddCards: true, shouldAddDataModels: true
    }
    let content = ''
    content += promptHelpers.actor()
    content += promptHelpers.backgroundCurrentDiagram(bpm.workflow.aiDescription, currentDiagram, requirementTypes)
    content += promptHelpers.requestAddOneStep(bpm.workflow, currentDiagram, currentStep)
    if (bpm.workflow.beamAiOptions) {
      content += promptHelpers.descriptionAddOneStep(bpm.workflow.beamAiOptions.dataFieldCount, requirementTypes)
    }
    content += promptHelpers.formatInstruction(bpm.workflow, requirementTypes, formatOptions)
    const step = await chatGPT.fetchChat(content, bpm.workflow.aiVersion)
    return step
  },
  async fetchReplyToDiagram (input, bpm, requirementTypes) {
    const cardIndex = input.node.requirementsJson.findIndex(card => card.id === input.requirementId)
    const card = input.node.requirementsJson[cardIndex]
    const { workflow: currentDiagram, currentStep } = promptHelpers.diagramToJson(input.node.id, bpm.workflow, bpm.svgGraph, bpm.svgLanes)
    let content = ''
    content += promptHelpers.actor()
    content += promptHelpers.backgroundCurrentDiagram(bpm.workflow.aiDescription, currentDiagram, requirementTypes)
    content += 'Question or request:\n'
    content += `${input.text}\n\n`
    content += 'Description:\n'
    content += `The "Question or request" above specifically refers to step ${currentStep} (${currentDiagram[currentStep - 1].role} - ${currentDiagram[currentStep - 1].description}) in the workflow and the meta data: ${card.cardType.title} "${card.description}".\n\n`
    if (card.comments.length > 0) {
      content += `The question above also refers to the comments on ${card.cardType.title} "${card.description}":\n\n`
      for (const comment of card.comments) {
        content += `> ${comment.text}\n\n`
      }
    }
    content += 'Reply:\n'
    content += 'Please make sure to reply with only one simple JSON object, containing only one key and one value, the key should be named "message", follow the example { "message": "This is an example mesage" }'
    const res = await chatGPT.fetchChat(content, bpm.workflow.aiVersion)
    return res
  },
  async fetchEventStory (eventId, bpm, requirementTypes) {
    const { workflow: currentDiagram, currentStep } = promptHelpers.diagramToJson(eventId, bpm.workflow, bpm.svgGraph, bpm.svgLanes)
    const roleName = currentDiagram[currentStep - 1].role
    const description = currentDiagram[currentStep - 1].description
    let content = ''
    content += promptHelpers.actor()
    content += promptHelpers.backgroundCurrentDiagram(bpm.workflow.aiDescription, currentDiagram, requirementTypes)
    content += 'Request:\n'
    content += `Focus on step ${currentStep}: ${roleName} - ${description}. `
    let count
    if (bpm.workflow.beamAiOptions && bpm.workflow.beamAiOptions.dataFieldCount > 0) {
      count = bpm.workflow.beamAiOptions.dataFieldCount
    } else {
      count = 6 // set it to max because here we're explicity asking for data fields
    }
    content += promptHelpers.dataFieldInstruction(count, 'create-event-story')
    content += 'Formatting:\n'
    content += 'Please make sure to format the answer as ONE valid JSON object (including the example data), not two objects.'
    content += 'The result should be formatted as valid JSON similar to this example: '
    content += JSON.stringify({ dataFields: promptHelpers.getSelectedDataFields(examples.dataFieldsExampleCreateOrder, count) })
    const step = await chatGPT.fetchChat(content, bpm.workflow.aiVersion)
    step.role = roleName
    step.description = description
    return step
  },
  async fetchFactTable (eventId, bpm, requirementTypes) {
    const { workflow, currentStep } = promptHelpers.diagramToJson(eventId, bpm.workflow, bpm.svgGraph, bpm.svgLanes)
    const roleName = workflow[currentStep - 1].role
    const description = workflow[currentStep - 1].description
    let content = ''
    content += promptHelpers.actor()
    content += promptHelpers.backgroundCurrentDiagram(bpm.workflow.aiDescription, workflow, requirementTypes)
    const exampleDataFields = {
      factTable: examples.factTableExampleCreateOrder
    }
    content += 'Request:\n'
    content += `Focusing on step ${currentStep}: ${roleName} - ${description}. `
    content += 'Create an example of a database fact table by providing around 6 example fields and 3 rows of example data that could '
    content += `be used for recording the business event ${roleName} - ${description}. `
    content += 'By fact table we mean the table at the center of a database star schema when performing dimensional database modelling. '
    content += 'A fact table typically contains fields for IDs pointing to other tables (dimensions) and quantities and dates. '
    content += 'Each field in the fact table database should be assigned exactly one category from the following list of categories: '
    content += '[Who, What, Where, How many, Why, How, When], based on which category that best describes the actual field in the fact table.\n\n'
    content += 'Formatting:\n'
    content += 'Please make sure to format the answer as ONE valid JSON object (including the example data), not two objects. '
    content += 'The property factTable should only occur once in the whole reply. '
    content += 'The result should be formatted as valid JSON similar to this example: '
    content += JSON.stringify(exampleDataFields)
    const step = await chatGPT.fetchChat(content, bpm.workflow.aiVersion)
    step.roleName = roleName
    step.description = description
    return step
  },
  async fetchCard (eventId, workflow, svgGraph, svgLanes, cardTypeId, requirementTypes) {
    const { workflow: currentDiagram, currentStep } = promptHelpers.diagramToJson(eventId, workflow, svgGraph, svgLanes)
    let content = ''
    content += promptHelpers.actor()
    content += promptHelpers.backgroundCurrentDiagram(workflow.aiDescription, currentDiagram, requirementTypes)
    const index = requirementTypes.findIndex(requirementType => requirementType.id === cardTypeId)
    content += 'Request:\n'
    content += `Suggest one more ${requirementTypes[index].title} (${requirementTypes[index].description}) `
    content += `to be added to step ${currentStep} (${currentDiagram[currentStep - 1].role}: ${currentDiagram[currentStep - 1].description}) `
    content += 'that is relevant to the workflow as a whole and not already exists in the workflow.\n\n'
    content += 'Return:\n'
    content += `Return only the new ${requirementTypes[index].title} in a JSON object. `
    content += 'The result should be formatted as valid JSON similar to this example: '
    content += JSON.stringify(promptHelpers.createExampleObjectCard(index, requirementTypes))
    const obj = await chatGPT.fetchChat(content, workflow.aiVersion)
    return obj
  },
  async fetchFactTableAndDimensions (eventId, svgGraph, svgLanes, workflow) {
    const { workflow: currentDiagram, currentStep } = promptHelpers.diagramToJson(eventId, workflow, svgGraph, svgLanes, { excludeCards: true })
    const roleName = currentDiagram[currentStep - 1].role
    const description = currentDiagram[currentStep - 1].description
    let content = ''
    content += promptHelpers.actor()
    content += promptHelpers.factBackgroundAndInstruction(workflow.aiDescription, roleName, description, eventId, svgGraph)
    content += ' Also create dimension tables matching the fact table. '
    content += 'The dimension tables should have around 5 fields each. '
    content += 'Fill each dimension table with 3 rows of example data. '
    content += 'Each field in the dimension tables should be assigned exactly one category from the following list of categories: '
    content += '[Who, What, When, Where, How many, Why, How], based on which category that best describes the actual field in the dimension table.\n\n'
    content += 'Formatting:\n'
    content += 'Format the reply as valid JSON. '
    content += 'The reply should contain one property called factTable on the top level and one propery called dimensionTables. Here is an example: '
    const exampleDataFields = {
      factTable: examples.factTableExampleCreateOrder,
      dimensionTables: [{
        tableName: 'Customer',
        dataFields: examples.customerDimensionTableExample
      }, {
        tableName: 'Product',
        dataFields: examples.productDimensionTableExample
      }]
    }
    content += JSON.stringify(exampleDataFields)
    const res = await chatGPT.fetchChat(content, workflow.aiVersion)
    return res
  },
  async fetchDimensions (eventId, svgGraph, svgLanes, workflow) {
    const node = svgGraph[eventId]
    const lane = svgLanes[node.laneId]
    let content = ''
    content += promptHelpers.actor()
    content += 'Background:\n'
    content += 'A database fact table is a data base table holding information about a business event. Usually it is displayed at the center of a star schema and has a number of ID\'s pointing to other database tables (dimensions). '
    content += `Here are the fields in a database fact table for capturing relevant data for the business event "${lane.name}: ${node.name}": [`

    const index = svgGraph[eventId].dataModels.findIndex(model => model.type === 'Fact Table')
    const fields = svgGraph[eventId].dataModels[index].dataFields
    content += fields.map(field => field.name).join(', ') + ']. '
    // content += 'Customer\nProduct\nOrder Date\nQuantity\n\n'

    content += `The business event belongs in a workflow containing ${workflow.aiDescription}.\n\n`
    content += 'Request:\n'
    content += 'Create dimension tables based on the fact table. The dimensions tables should have around 5 fields each. Fill each dimension table with 3 rows of example data. '
    content += 'Each field in the each dimension table should be assigned exactly one category from the following list of categories: [Who, What, Where, How many, Why, How, When], '
    content += 'based on which category that best describes the actual field in the dimension table.\n\n'
    content += 'Formatting:\n'
    content += 'The reply must contain exactly one property called dimensionTables. Format the reply as valid JSON according to this example: '
    const exampleDataFields = {
      dimensionTables: [{
        tableName: 'Customer',
        dataFields: examples.customerDimensionTableExample
      }, {
        tableName: 'Product',
        dataFields: examples.productDimensionTableExample
      }]
    }
    content += JSON.stringify(exampleDataFields)
    const res = await chatGPT.fetchChat(content, workflow.aiVersion)
    return res
  },
  async fetchFactTable2 (eventId, workflow, svgGraph, svgLanes) {
    const node = svgGraph[eventId]
    const roleName = svgLanes[node.laneId].name
    const description = node.name
    let content = ''
    content += promptHelpers.actor()
    content += promptHelpers.factBackgroundAndInstruction(workflow.aiDescription, roleName, description, eventId, svgGraph)
    content += '\n\n'
    content += 'Formatting:\n'
    content += 'Format the reply as valid JSON. '
    content += 'The reply should contain one property called factTable on the top level. Here is an example: '
    const exampleDataFields = {
      factTable: examples.factTableExampleCreateOrder
    }
    content += JSON.stringify(exampleDataFields)
    const res = await chatGPT.fetchChat(content, workflow.aiVersion)
    return res
  },
  getExamples () {
    return [
      'an incident management process for ITIL version 3',
      'a manufacturing process for outdoor clothing',
      'an order process used by Amazon',
      'a process for last mile delivery for an electronics retailer',
      'a KYC process for onboarding a large corporate bank customer',
      'a process for ordering home delivery of food',
      'a recruitment process for engineers used by Google',
      'a CRM process used by Sales Force'
    ]
  },
  async validatePrompt (workflow, bpmn) {
    let prompt = ''
    prompt += promptHelpers.actor()
    prompt += 'Question:\n'
    prompt += `Does this sentence make sense as a prompt for generating a workflow: "Create a workflow containing ${workflow.aiDescription}"\n\n`
    prompt += 'Answer with JSON. If the answer is no, then if possible provide a likely interpretation in "did you mean":\n'
    prompt += '{"answer": "yes"} or {"answer": "no", "did you mean": "this instead"}\n'
    prompt += '"did you mean" answer must always start with text "Create a workflow containing ". "did you mean" answer must be a statement sentence.'
    prompt += '"did you mean" answer will only be valid if this rules are fulfilled.'
    const res = await chatGPT.fetchChat(prompt, bpmn.workflow.aiVersion)
    return res
  }
}
