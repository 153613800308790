import gql from 'graphql-tag'

export default gql`
  query getCurrentUser {
    getCurrentUser {
      id
      email
      firstname
      lastname
      company
      phoneNumber
      job
      picture {
        name
        url
        file {
          region
          bucket
          key
        }
        mimeType
      }
    }
  }
`
