<template>
  <v-layout fluid class="attachment-card">
    <v-col class="flex-shrink-1 flex-grow-0">
      <div class="attachment-preview-placeholder">{{ type }}</div>
    </v-col>

    <v-col class="attachment-info-container">
      <div class="attachment-title">
        <a :href="url" target="_blank" rel="noopener">{{ title }}</a>
      </div>

      <div class="attachment-actions-container">
        <span class="attachment-date" :title="date">{{ formatedDate }}</span>

        <v-btn
          v-if="type === 'FILE'"
          size="small"
          variant="flat"
          class="action-button"
          @click="mediaDialogOpen = true"
        >
          Open
        </v-btn>

        <v-btn
          v-else
          :href="url"
          size="small"
          variant="flat"
          target="_blank"
          rel="noopener"
          class="action-button"
        >
          Open
        </v-btn>

        <v-btn size="small" variant="flat" class="action-button" @click="deleteAttachment">
          <v-icon start>mdi-delete</v-icon>
          Delete
        </v-btn>
      </div>
    </v-col>

    <requirement-attachment-media-dialog
      v-if="type === 'FILE'"
      :open="mediaDialogOpen"
      @open-change="handleOpenChange"
      :title="title"
      :url="url"
      :media-type="mediaType"
    ></requirement-attachment-media-dialog>
  </v-layout>
</template>

<script>
import RequirementAttachmentMediaDialog from '@/components/RequirementAttachmentMediaDialog'
import format from 'date-fns/format'
import store from '../store'

export default {
  components: {
    RequirementAttachmentMediaDialog
  },
  props: {
    attachmentId: String,
    type: String,
    title: String,
    date: String,
    url: String,
    mimeType: String,
    item: Object
  },
  data () {
    return {
      mediaDialogOpen: false
    }
  },
  computed: {
    formatedDate () {
      return format(new Date(this.date), 'MMM d \'at\' p')
    },
    mediaType () {
      switch (this.mimeType) {
        case 'image/apng':
        case 'image/avif':
        case 'image/gif':
        case 'image/jpeg':
        case 'image/png':
        case 'image/svg+xml':
        case 'image/webp':
          return 'image'

        default:
          return 'unknown'
      }
    }
  },
  methods: {
    deleteAttachment () {
      const workflowId = this.$route.params.id
      store.dispatch('workflow/deleteCardAttachment', {
        workflowId,
        id: this.attachmentId
      })
    },
    handleOpenChange (open) {
      this.mediaDialogOpen = open
    }
  }
}
</script>

<style>
  .attachment-card:not(:last-child) {
    margin-bottom: 16px;
  }

  .attachment-preview-placeholder {
    width: 100px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eaecf0;
    border-radius: 4px;
    color: #022a44;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 12px;
  }

  .attachment-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    max-width: calc(100% - 115px);
  }

  .attachment-title {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .attachment-actions-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
  }

  .attachment-date {
    color: rgba(0, 0, 0, .54);
  }

  .action-button {
    margin: 0;
  }
</style>
