import { apolloClient } from '@/vue-apollo'
import CreateWorkflow from '@/graphql/mutations/CreateWorkflow'
import DeleteWorkflow from '@/graphql/mutations/DeleteWorkflow'
import CloneWorkflow from '@/graphql/mutations/CloneWorkflow'
import ListWorkflows from '@/graphql/queries/ListWorkflows'
import ListDemoWorkflows from '@/graphql/queries/ListDemoWorkflows'
import OnCreateOrUpdateWorkflow from '@/graphql/subscriptions/OnCreateOrUpdateWorkflow'
import OnDeleteWorkflow from '@/graphql/subscriptions/OnDeleteWorkflow'

export const workflows = {
  namespaced: true,
  state: {
    workflows: null,
    demoWorkflows: null
  },
  mutations: {
    setWorkflows (state, payload) {
      state.workflows = payload
    },
    updateWorkflows (state, payload) {
      const index = state.workflows?.findIndex((workflow) => workflow.id === payload.id)
      if (index >= 0) {
        state.workflows[index] = Object.assign(state.workflows[index], payload)
      } else {
        if (!state.workflows) { state.workflows = [] }
        state.workflows.push(payload)
      }
    },
    deleteWorkflow (state, payload) {
      const index = state.workflows.findIndex((workflow) => workflow.id === payload.id)
      if (index >= 0) {
        state.workflows.splice(index, 1)
      }
    },
    setDemoWorkflows (state, payload) {
      state.demoWorkflows = payload
    }
  },
  actions: {
    async createWorkflow ({ commit }, { workflow, silent = false }) {
      try {
        const res = await apolloClient.mutate({
          mutation: CreateWorkflow,
          variables: {
            projectId: workflow.projectId,
            input: {
              name: workflow.name
            }
          }
        })
        commit('updateWorkflows', res.data.createWorkflow)
        if (!silent) {
          commit('snackbar/showMessage', { content: 'Created' }, { root: true })
        }
        return res.data.createWorkflow.id
      } catch (e) {
        console.log(e)
      }
    },

    async deleteWorkflow ({ commit }, { projectId, workflowId }) {
      try {
        const res = await apolloClient.mutate({
          mutation: DeleteWorkflow,
          variables: {
            projectId,
            workflowId
          },
          fetchPolicy: 'no-cache'
        })
        commit('deleteWorkflow', res.data.deleteWorkflow.deleteWorkflow)
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
        return res.data.deleteWorkflow.id
      } catch (e) {
        console.log(e)
      }
    },

    async cloneWorkflow ({ commit }, { targetProjectId, workflowId }) {
      commit('snackbar/showMessage', { content: 'Copying workflow...', showProgress: true, timeout: -1 }, { root: true })
      try {
        commit('loading/startLoading', null, { root: true })
        await apolloClient.mutate({
          mutation: CloneWorkflow,
          variables: {
            workflowId,
            projectId: targetProjectId
          },
          fetchPolicy: 'no-cache'
        })
        await apolloClient.reFetchObservableQueries()
        commit('loading/stopLoading', null, { root: true })
        commit('snackbar/showMessage', { content: 'Workflow has been successfully copied.' }, { root: true })
      } catch (e) {
        commit('loading/stopLoading', null, { root: true })
        commit('snackbar/showMessage', { content: 'Error copying workflow.', color: 'red' }, { root: true })
        console.log(e)
      }
    },

    async listWorkflows ({ commit, rootState }, projectId) {
      const res = await apolloClient.query({
        query: ListWorkflows,
        variables: { projectId },
        fetchPolicy: 'no-cache'
      })

      if (res.errors) {
        commit('setWorkflows', null)
        return
      }

      commit('setWorkflows', res.data.listWorkflows?.project?.workflows)

      if (rootState.isUserLoggedIn === false || rootState.workflow.isViewOnlyEnabled === true) {
        return
      }

      apolloClient.subscribe({
        query: OnCreateOrUpdateWorkflow,
        variables: { projectId },
        fetchPolicy: 'no-cache'
      }).subscribe({
        next (res) {
          commit('updateWorkflows', res.data.onCreateOrUpdateWorkflow)
        },
        error () {
          // console.error('Subscription error:', error)
          console.log('Subscription not authorized')
        }
      })

      apolloClient.subscribe({
        query: OnDeleteWorkflow,
        variables: { projectId },
        fetchPolicy: 'no-cache'
      }).subscribe({
        next (res) {
          commit('deleteWorkflow', res.data.onDeleteWorkflow.deleteWorkflow)
        },
        error () {
          // console.error('Subscription error:', error)
          console.log('Subscription not authorized')
        }
      })
    },

    async listDemoWorkflows ({ commit }) {
      const res = await apolloClient.query({
        query: ListDemoWorkflows,
        fetchPolicy: 'no-cache'
      })
      commit('setDemoWorkflows', res.data.listDemoWorkflows)
    }
  }
}
