<template>
  <v-dialog
    v-model="open"
    max-width="700px"
    scollable
    content-class="dialog-top-right"
  >
    <template #activator="{ props }">
      <slot :props="props"></slot>
    </template>

    <v-card flat>
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title><v-icon> mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>Share publicly</b></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="mt-1">
        <h3>Share workflow</h3>
        The access level can only be changed by the project owner.
        <br><br>
        <h3>Can View</h3>
        Select this option if you want the recipient to have read-only access to the workflow. They will be able to view the content but will not be able to make any changes.
        <br /><br />
        <h3>No access</h3>
        This option makes the workflow private, making it visible only to users who have been invited to the project (team members).
        <br /><br />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  }
}
</script>
