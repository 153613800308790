<template>
  <div>
    <v-expand-transition>
      <v-card
        v-show="showLegend"
        class="rounded-lg rounded-t-0 pa-2 mt-2"
      >
        <v-container fluid>
          <v-row class="justify-start">
            <!-- role -->
            <v-col class="col-xs-2 legend-container" v-if="roleDefinition">
              <v-card class="pa-2 legend-item">
                <div class="legend-item-content d-flex">
                  <div
                    class="legend-item-role-name text-center text-body-2 rounded-lg rounded-e-0 mr-2"
                    :style="{
                      'background-color': roleDefinition.color.rgb
                    }"
                  >
                    <div class="legend-item-role-name-text px-1">{{roleDefinition.title}}</div>
                  </div>

                  <pre class="text-body-2">{{roleDefinition.description}}</pre>
                </div>
              </v-card>
            </v-col>

            <!-- event -->
            <v-col class="col-xs-2 legend-container" v-if="eventDefinition">
              <v-card class="pa-2 legend-item">
                <div class="legend-item-title text-center mb-2">
                  <div class="py-1 px-3 rounded-lg text-body-2">{{eventDefinition.title}}</div>
                </div>

                <div
                  class="legend-item-event-shape rounded-lg d-flex align-center justify-center"
                  :style="{
                    'background-color': eventDefinition.color.rgb
                  }"
                >
                  <pre class="pa-2 text-body-2 text-center">{{eventDefinition.description}}</pre>
                </div>
              </v-card>
            </v-col>

            <v-col
              class="col-xs-2 legend-container"
              v-for="cardType in types"
              :key="cardType.id"
            >
              <v-card class="pa-2 legend-item">
                <div class="legend-item-title text-center mb-4">
                  <div
                    class="py-1 px-3 rounded-xl text-body-2"
                    :style="{
                      'background-color': `rgb(${cardType.color.red},${cardType.color.green},${cardType.color.blue})`,
                      color: getTextColour(cardType)
                    }"
                  >{{cardType.title}}</div>
                </div>

                <pre class="text-body-2">{{cardType.description}}</pre>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-expand-transition>

    <div
      v-if="!showLegend"
      @click="$emit('show-legend')"
      class="rounded-lg rounded-t-0 pa-1 bg-white mx-auto text-center show-legend-btn elevation-3"
    >
      Show legend <v-icon size="small">mdi-chevron-down</v-icon>
    </div>

    <div
      v-else
      @click="$emit('hide-legend')"
      class="rounded-lg rounded-t-0 pa-1 bg-white mx-auto text-center show-legend-btn elevation-3"
    >
      Hide legend <v-icon size="small">mdi-chevron-up</v-icon>
    </div>
  </div>
</template>

<script>
import requirementTypesApi from '@/api/requirementTypesApi'

export default {
  props: {
    showLegend: Boolean,
    cardTypes: Array,
    roleDefinition: Object,
    eventDefinition: Object
  },

  methods: {
    getTextColour (cardType) {
      return requirementTypesApi.getTextColour(cardType)
    }
  },

  computed: {
    types () {
      if (!Array.isArray(this.cardTypes)) return []

      return this.cardTypes.filter(({ description }) => description)
    }
  }
}
</script>

<style scoped>
  .show-legend-btn {
    width: 140px;
    cursor: pointer;
  }

  .legend-item {
    min-width: 230px;
    max-width: 230px;
    height: 200px;
    overflow: hidden;
  }

  .legend-container {
    min-width: 250px;
    max-width: 250px;
  }

  .legend-item-content {
    height:  100%;
    align-items: stretch;
  }

  .legend-item-role-name {
    width: 30px;
    border: 1px solid grey;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    height: 100%;
  }

  .legend-item-role-name-text {
    transform: rotate(180deg);
  }

  .legend-item-title > div {
    display: inline-block;
  }

  .legend-item-event-shape {
    margin: 0 20px;
    height: 140px;
    border: 2px solid black;
    position: relative;
  }

  .legend-item-event-shape::before,
  .legend-item-event-shape::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 0px;
    border: 1px solid black;
    top: 50%;
  }

  .legend-item-event-shape::before {
    left: -20px;
  }

  .legend-item-event-shape::after {
    right: -20px;
  }

  pre {
    max-height: 100%;
    white-space: pre-wrap;
    word-break: break-word;
    overflow: hidden;
  }
</style>
