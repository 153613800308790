<template>
  <div class="event-action-buttons" id="event-action-buttons">
    <workflow-delete-item-dialog
      :itemType="'Event'"
    >

    </workflow-delete-item-dialog>

    <workflow-settings-requirement-type-color-picker
      appearance="diagram"
      :variants="['lighten']"
      :color="color"
      @change="changeColor"
    >
      <template #activator="{ props: onMenu }">
        <v-tooltip location="top" open-delay="1000">
          <template v-slot:activator="{ props: tooltipActivator }">
            <button v-bind="onMenu" class="change-color-shape-action">
              <!-- eslint-disable vue/valid-v-on vue/no-parsing-error -->
              <v-avatar
                size="14"
                class="color-item"
                :color="color"
                v-bind="tooltipActivator"
              ></v-avatar>
              <!-- eslint-enable vue/valid-v-on vue/no-parsing-error -->
            </button>
          </template>

          <div>
            <p v-if="node.type === 'bpmn:Task'" class="text-body-2 mb-1">
              Select color
            </p>
            <p v-else class="text-body-2 mb-1">
              Select color (disabled for decision)
            </p>
          </div>
        </v-tooltip>
      </template>
    </workflow-settings-requirement-type-color-picker>

    <v-tooltip location="top" open-delay="1000">
      <template v-slot:activator="{ props }">
        <div v-bind="props" class="d-flex">
          <v-icon
            @click="initiateDeleteConnection"
            color="white"
            size="small"
            :class="{'captures-description': hasRemovableConnection == 1}"
            >mdi-keyboard-tab</v-icon
          >
        </div>
      </template>
      <div>
        <p v-if="isLeafNode">
          Delete connection (disabled, no connection can be deleted)
        </p>
        <p v-else-if="hasRemovableConnection > 0" class="text-body-2 mb-1">
          Delete connection
        </p>
        <p v-else class="text-body-2 mb-1">
          Delete connection (creates a new starting point)
        </p>
      </div>
    </v-tooltip>

    <v-tooltip location="bottom" open-delay="1000">
      <template v-slot:activator="{ props, on }">
        <div v-on="{ ...on }" class="d-flex align-center" :disabled="disableGatewayFromTransformMenu(node)">
          <v-icon
            px-1
            class="transform-event-btn"
            color="white"
            size="21"
            @click.stop="$emit('change-shape')"
            v-bind="props"
            >custom:ChangeShapeIcon</v-icon
          >
        </div>
      </template>

      <template #default>
        <div>
          <p
            v-if="!disableGatewayFromTransformMenu(node)"
            class="text-body-2 mb-1"
          >
            Change shape
          </p>
          <p v-else class="text-body-2 mb-1">
            Change shape (disabled because of multiple connections)
          </p>
        </div>
      </template>
    </v-tooltip>

    <v-tooltip location="top" open-delay="1000">
      <template v-slot:activator="{ props }">
        <div v-bind="props" class="d-flex align-center" :disabled="!atTheBottomOfLane">
          <v-icon
            color="white"
            size="small"
            class="captures-description"
            @click="moveDownInEventGroup()"
            >mdi-arrow-down</v-icon
          >
        </div>
      </template>
      <div>
        <p v-if="atTheBottomOfLane" class="text-body-2 mb-1">
          Expand lane and move down
        </p>
        <p v-else class="text-body-2 mb-1">
          Expand lane disabled (use drag & drop to move down)
        </p>
      </div>
    </v-tooltip>

    <bpmn-delete-connection-dialog
      :open="confirmDialog"
      :connections="connections"
      @close="confirmDialog = false"
      @delete-connection="deleteConnection"
    ></bpmn-delete-connection-dialog>
  </div>
</template>

<script>
import WorkflowSettingsRequirementTypeColorPicker from '@/components/WorkflowSettingsRequirementTypeColorPicker'
import { workflowApiHelpers } from '@/api/workflowApiHelpers'
import workflowApi from '@/api/workflowApi'
import BpmnDeleteConnectionDialog from '@/components/BpmnDeleteConnectionDialog'
import { diagramHelpers } from '@/mixins/diagramHelpers.js'
import WorkflowDeleteItemDialog from '@/components/WorkflowDeleteItemDialog'

export default {
  components: {
    WorkflowSettingsRequirementTypeColorPicker,
    BpmnDeleteConnectionDialog,
    WorkflowDeleteItemDialog
  },
  mixins: [diagramHelpers],
  props: {
    node: Object,
    active: Boolean,
    color: String,
    events: Array,
    baseData: Object,
    svgGraph: Object,
    svgGroups: Object,
    svgLanes: Object,
    workflow: Object
  },

  data () {
    return {
      connections: [],
      confirmDialog: false
    }
  },

  methods: {
    changeColor (color) {
      const node = this.svgGraph[this.$route.params.eventId]
      node.color = { ...color, rgb: `rgb(${color.red},${color.green},${color.blue})` }
      workflowApi.updateWorkflow({
        svgGraph: this.svgGraph,
        svgLanes: this.svgLanes,
        svgGroups: this.svgGroups,
        workflow: this.workflow,
        updateNote: {
          action: 'changed color of',
          target: node.name
        }
      })
    },
    initiateDeleteConnection () {
      if (this.isLeafNode) return
      const parentId = this.$route.params.eventId
      this.connections = []
      const childIds = this.svgGraph[parentId].childIds
      if (childIds.length > 1) {
        this.showConfirmationDialog(childIds)
      } else {
        this.deleteConnection(parentId, childIds[0])
      }
    },
    showConfirmationDialog (childIds) {
      childIds.forEach(childId => {
        const target = this.svgGraph[childId]
        this.connections.push({ parentId: this.$route.params.eventId, childId, eventName: target.name })
      })
      this.confirmDialog = true
    },
    deleteConnection (parentId, childId) {
      this.confirmDialog = false
      workflowApi.deleteConnectionFromEvent(parentId, childId, this.svgGraph, this.svgLanes, this.svgGroups, this.workflow)
    },
    moveDownInEventGroup () {
      this.svgGraph[this.$route.params.eventId].offset++
      workflowApi.updateWorkflow({
        svgGraph: this.svgGraph,
        svgLanes: JSON.parse(JSON.stringify(this.svgLanes)),
        svgGroups: this.svgGroups,
        workflow: this.workflow,
        updateNote: {
          action: 'moved down event',
          target: this.svgGraph[this.$route.params.eventId].name
        }
      })
    }
  },

  computed: {
    isLeafNode () {
      return !this.svgGraph[this.$route.params.eventId]?.childIds?.length
    },
    hasRemovableConnection () {
      return workflowApiHelpers.getRemovableSequenceFlows(this.events, this.$route.params.eventId).length
    },
    atTheBottomOfLane () {
      return this.currentEventIsAtTheBottomOfItsLane(this.svgLanes, this.svgGraph, this.$route.params.eventId)
    }
  },

  watch: {
    confirmDialog: function (val) {
      if (!val) {
        this.connections = []
      }
    }
  }
}
</script>

<style scoped>
.event-action-buttons {
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
  padding: 0 1px;
  background: #343434;
}

.event-action-buttons > span {
  display: none;
}

button {
  padding: 0px 2px;
}

button {
  height: 21px;
  display: flex;
  align-items: center;
}

.v-tooltip__content {
  background: white;
  color: var(--color-primary);
  border-radius: 10px;
}

.v-tooltip__content code {
  background: var(--color-primary);
  color: white;
}

.transform-event-btn:disabled {
  opacity: 0.5;
}
</style>
