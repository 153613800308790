<template>
  <v-card class="auth-confirm-container pa-6">
    <img src="@/assets/qlerify-logo-small.png"
        name="preview-image-url" alt="Qlerify logo" class="mx-auto" style="display: block;"/>
    <h3 class="text-h5 font-weight-bold pb-4 text-center">Confirm your new account.</h3>
    <p class="text-center">
      An email has been sent to you with a registration code. Please <br />
      confirm your account by entering the registration code below.
    </p>

    <v-form ref="form" v-model="valid">
      <v-container>
        <v-row class="my-0">
          <v-col class="pa-0">
            <p class="text-caption ma-0 text-grey-darken-2">
              Email
            </p>

            <v-text-field
              label="Email"
              type="email"
              variant="solo"
              v-model="username"
              :rules="[rules.required, rules.email]"
              validate-on="blur"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="pa-0">
            <p class="text-caption ma-0 text-grey-darken-2">
              Enter your registration code
            </p>

            <v-text-field
              label="Enter your registration code"
              id="code"
              variant="solo"
              v-model="code"
              :rules="[rules.required]"
              validate-on="blur"
              v-on:keyup.enter="submit"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="pa-0">
            <p class="bg-error" v-html="error" />
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="pa-0 d-flex justify-center">
            <v-btn color="primary" min-width="180px" @click="submit"> Confirm </v-btn>
          </v-col>
        </v-row>

        <v-row class="my-6">
          <v-col class="pa-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="pa-0 d-flex justify-center">
            <p class="ma-0">
              Already have an account?

              <router-link :to="{ path: '/login' }">
                <span class="light--text">Log in</span>
              </router-link>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      valid: true,
      email: '',
      code: '',
      error: null,
      message: null,
      username: '',
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      e1: true
    }
  },
  created () {
    if (this.$route.query.username !== '') {
      this.username = this.$route.query.username
    }
  },
  methods: {
    async submit () {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const code = this.code.trim()

        this.$cognitoAuth.confirmRegistration(this.username, code, (err, result) => {
          if (err) {
            this.error = err.message
            console.error(err)
          } else {
            this.$router.replace({
              path: '/login',
              query: {
                thanks: true,
                username: this.username,
                projectId: this.$route.query.projectId || undefined
              }
            })
          }
        })
      }
    }
  }
}
</script>

<style scoped>
div.error {
  color: red;
  background: none !important;
  border: none;
}

.auth-confirm-container {
  max-width: 600px;
  margin: 24px auto;
}
</style>
