<template>
  <v-card
    class="rounded-lg"
    style="margin: 1px;"
    :flat="flat"
  >
    <textarea
      v-model="text"
      rows="1"
      class="pa-2"
      ref="textareaElement"
      :readonly="$store.state.isWorkflowDisabled"
      @focus="computeHeight"
      @keydown.exact="computeHeight"
      @keydown.enter.exact.prevent="handleEnter"
      @keydown.alt.enter.exact.prevent="handleAltEnter"
      @cut="computeHeight"
      @paste="computeHeight"
      @drop="computeHeight"
    ></textarea>

    <v-container>
      <v-row class="justify-end flex-nowrap pa-1" v-if="!$store.state.isWorkflowDisabled">
        <v-col class="flex-shrink-1 flex-grow-0 pa-0">
          <slot name="prepend-actions"></slot>
        </v-col>
        <v-btn
          size="small"
          class="ma-0 mr-1 pr-0 pl-0 action-button"
          color="primary"
          variant="text"
          @click="cancel"
          v-if="!hideCancel"
        >Cancel</v-btn>

        <v-tooltip v-if="showAi" open-delay="1000" location="bottom">
          <template v-slot:activator="{ props }">
            <v-btn
              size="small"
              class="ma-0 mx-1 pr-0 pl-0 ai-button"
              @click="submitToAi"
              color="primary" variant="text"
              :disabled="!text.trim().length"
              :loading="loading"
              v-bind="props"
            >
              Ask AI
              <v-icon size="small">
                mdi-creation
              </v-icon>
            </v-btn>
          </template>
          <span>Ask AI a question! NOTE: By clicking this button you will <br />share information about your current workflow with Open AI.</span>
        </v-tooltip>

        <v-btn
          size="small"
          class="ma-0 ml-1 px-0 action-button"
          color="primary"
          :disabled="!text.trim().length"
          :loading="loading"
          @click="submit"
        >Save</v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    hideCancel: Boolean,
    initialText: String,
    loading: Boolean,
    flat: Boolean,
    showAi: Boolean
  },

  data () {
    return {
      text: ''
    }
  },

  mounted () {
    this.$nextTick(() => {
      if (this.initialText) {
        this.text = this.initialText
      }
      setTimeout(() => {
        if (this.$refs.textareaElement) {
          this.$refs.textareaElement.focus()
        }
      }, 50)
    })
  },

  methods: {
    submit () {
      this.$emit('submit', { text: this.text, generatedByAi: false })
      this.text = ''
    },

    submitToAi () {
      this.$emit('submit', { text: this.text, generatedByAi: true })
      this.text = ''
    },

    cancel () {
      this.$emit('cancel')
      this.text = ''
    },

    computeHeight () {
      if (!this.$refs.textareaElement) {
        return
      }

      const { textareaElement } = this.$refs
      this.$nextTick(() => {
        setImmediate(() => {
          textareaElement.style.height = 'auto'
          textareaElement.style.height = `${textareaElement.scrollHeight}px`
        })
      })
    },

    handleEnter () {
      this.$emit('submit', { text: this.text })
    },

    handleAltEnter (event) {
      const { textareaElement } = this.$refs
      const startPosition = textareaElement.selectionStart
      const endPosition = textareaElement.selectionEnd
      const result = [
        this.text.slice(0, startPosition),
        '\n',
        this.text.slice(endPosition)
      ].join('')

      // update textarea value
      this.text = result

      // set cursor position
      this.$nextTick(() => {
        textareaElement.selectionEnd = endPosition + 1
      })
    }
  }
}
</script>

<style scoped>
textarea {
  width: 100%;
  resize: none;
  outline: none;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  min-height: 40px;
}

.action-button {
  border-radius: 6px !important;
  min-width: 50px;
}
.ai-button {
  border-radius: 6px !important;
  min-width: 30px;
  pointer-events: auto;
}
</style>
