import gql from 'graphql-tag'

export default gql`
  mutation convertInvitesToTeamMembers {
    convertInvitesToTeamMembers {
      id
      projectId
      userId
    }
  }
`
