<template>
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect class="stroke--current-color" x="2" y="0.5" width="18" height="15" rx="2.5"/>
  </svg>
</template>

<script>
export default {
  name: 'task-icon'
}
</script>

<style scoped>
.stroke--current-color {
  stroke: currentColor;
  stroke-width: 2px;
}

rect {
  fill: none;
}
</style>
