<template>
  <v-dialog :scrollable="true" max-width="30%" v-model="open" content-class="dialog-top-right">
    <template v-slot:activator="{ props }">
      <v-icon v-bind="props" class="ml-2">mdi-help-circle-outline</v-icon>
    </template>

    <v-card>
      <v-toolbar density="compact" flat dark color="primary">
        <v-toolbar-title><v-icon> mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>Create Fact Table</b></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <h3><span class="bg-red-lighten-4">Create Fact Table</span></h3>
        There are 5 different ways to create a Fact Table:<br /><br />
        <ul>
          <li><b>From Event Story with AI</b><br />Uses AI to analyse the Event Story and create a suggestion for the Fact Table. Recommended.</li><br />
          <li><b>From Workflow with AI</b><br />Uses AI to analyze the Workflow (and not the Event Story) to create a suggestion for the Fact Table. Recommended if you think your Event Story might be missing information or if you don't have an Event Story.</li><br />
          <li><b>Copy Event Story</b><br />Creates a copy of the Event Story as a Fact Table.</li><br />
          <li><b>Static Example Data</b><br />A simple Fact Table containing example data will be created.</li><br />
          <li><b>Empty Table:</b><br />An empty table will be created.</li>
        </ul>
        <br />
        Note: When using AI, information about your workflow will be shared with OpenAI.
        <br />
      </v-card-text><br /><br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  }
}
</script>

<style>
.v-dialog .help-ai {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 30%;
  overflow-y: scroll;
}
</style>
