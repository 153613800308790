<template>
  <v-tooltip location="top" open-delay="1000">
    <template v-slot:activator="{ props }">
      <v-avatar v-if="generatedByAi" color="#022a44" size="20" class="avatar mx-1" v-bind="props">
        <v-icon size="x-small" color="#022a44">mdi-creation</v-icon>
      </v-avatar>
      <v-avatar v-else color="#022a44" size="20" class="avatar mx-1" v-bind="props">
        <span class="text-caption initials">{{ initials }}</span>
      </v-avatar>
    </template>

    <div>
      <p class="text-body-2 mb-0">{{ (generatedByAi ? 'Generated by AI by ' : '') + fullname }}</p>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  props: ['fullname', 'generatedByAi'],

  computed: {
    initials () {
      const { fullname } = this

      if (typeof fullname !== 'string' || !fullname.trim().length) {
        return '*'
      }

      const [first, last] = this.fullname.split(' ')

      const f = first.charAt(0)
      const l = typeof last === 'string' ? last.charAt(0) : ''

      return f + l
    }
  }
}
</script>

<style scoped>
.avatar {
  flex-shrink: 0;
  border: 1px solid #022a44;
  background: none !important;
}

.initials {
  text-transform: uppercase;
  line-height: 1em;
  color: #022a44 !important;
}

.v-tooltip__content {
  background: white;
  color: var(--color-primary);
  border-radius: 10px;
}
</style>
