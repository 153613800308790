import gql from 'graphql-tag'

export default gql`
  subscription OnUpdateRequirementsJson($workflowId: String!)  {
    onUpdateRequirementsJson (workflowId: $workflowId) {
      workflowId
      version
      updatedAt
      updateNote {
        userRealName
        action
        target
      }
      requirements {
        requirementId
        requirementIndex
        description
        sortkey
        cardTypeId
        releaseNo
        done
        version
        eventId
        eventIndex
        boundedContext
      }
    }
  }
`
