<template>
  <v-dialog v-model="dialogWorkflow" max-width="500px" v-on:keydown.stop>
    <template v-slot:activator="{ props }">
      <slot name="activator" :props="props">
        <v-btn
          v-bind="props"
          :color="buttonTheme == 'dark' ? 'primary' : 'white'"
          v-bind:class="{ 'primary--text': buttonTheme == 'light', 'tabs-bar-button': true, 'ma-2': true }"
        >
          {{ buttonText }}
        </v-btn>
      </slot>
    </template>

    <v-card>
      <v-container class="pr-0">
        <v-row class="v-container--fluid justify-space-between align-top">
          <v-card-title>
            <span v-if="editWorkflow && editWorkflow.id" class="text-h5">Edit Workflow</span>
            <span v-else class="text-h5">New Workflow</span>
          </v-card-title>

          <v-btn variant="text" icon color="grey" @click="closeWorkflow">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-container>

      <v-card-text>
        <v-container grid-list-md>
          <v-form ref="formWorkflow" v-model="valid">
            <v-container class="flex-wrap">
              <v-col cols="12">
                <v-text-field
                  name="workflow-name"
                  variant="underlined"
                  v-model="editWorkflow.name"
                  :rules="[rules.required, rules.counter]"
                  label="Workflow name"
                  v-on:keyup.enter="saveWorkflow"
                  :focused="true"
                  :autofocus="true"
                  :persistent-hint="editWorkflow.status === 2"
                  :hint="editWorkflow.status === 2 ? 'Workflow is locked' : ''"
                  :disabled="editWorkflow.status === 2"
                  ref="workflowName">
                </v-text-field>
                <!-- keyup.enter doesn't work without this row, seems like a bug in vue or vuetify -->
                <v-text-field class="hidden" disabled style="display: none;"></v-text-field>
              </v-col>
            </v-container>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="text" @click="closeWorkflow">Cancel</v-btn>
        <v-btn color="primary" variant="elevated" :disabled="editWorkflow.status === 2" @click="saveWorkflow">{{ this.editWorkflow && this.editWorkflow.id ? 'Update' : 'Add' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import workflowApi from '@/api/workflowApi'
import { mapState } from 'vuex'

export default {
  props: {
    buttonText: {
      type: String,
      default: ''
    },
    buttonTheme: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dialogWorkflow: false,
    valid: true,
    rules: {
      required: value => !!value || 'Required.',
      counter: value => (value && value.length <= 40) || 'Max 40 characters'
    },
    editWorkflow: {
      name: ''
    }
  }),
  computed: {
    ...mapState({
      workflows: state => state.workflows.workflows
    })
  },
  methods: {
    async saveWorkflow () {
      const { valid } = await this.$refs.formWorkflow.validate()
      if (valid) {
        const index = this.workflows.findIndex(ws => (ws.name === this.editWorkflow.name))
        if (index >= 0) {
          alert('A workflow with this name already exists.')
          return
        }
        this.editWorkflow.projectId = this.$route.params.projectId
        if (this.editWorkflow.id) {
          workflowApi.updateWorkflow({
            workflowName: this.editWorkflow.name,
            updateNote: {
              action: 'changed workflow name to',
              target: this.editWorkflow.name
            },
            workflow: this.editWorkflow
          })
        } else {
          await workflowApi.createWorkflow(this.editWorkflow)
        }
        this.closeWorkflow()
      }
    },
    closeWorkflow () {
      this.dialogWorkflow = false
    },
    openWorkflowDialog () {
      this.dialogWorkflow = true
    },
    setItem (item) {
      this.editWorkflow = item
    },
    selectAllTextInInput () {
      const inputElement = this.$refs.workflowName.$el.querySelector('input')
      inputElement.focus()
      inputElement.select()
    },
    getNextWorkflowName (workflows) {
      let number = this.workflows ? this.workflows.length + 1 : 1
      let res = 'Workflow ' + number
      while (workflows?.findIndex(ws => (ws.name === res)) >= 0) {
        number++
        res = 'Workflow ' + number
      }
      return res
    }
  },
  watch: {
    dialogWorkflow (val) {
      if (!val) {
        this.editWorkflow = {
          name: ''
        }
      } else if (!this.editWorkflow || !this.editWorkflow.id) {
        if (this.$refs.formWorkflow) {
          this.$refs.formWorkflow.reset()
        }
        this.editWorkflow = {
          name: this.getNextWorkflowName(this.workflows)
        }
        this.$nextTick(() => {
          this.selectAllTextInInput()
        })
      }
    }
  }
}
</script>

<style scoped>
.tabs-bar-button {
  align-self: center;
}
</style>
