import gql from 'graphql-tag'

export default gql`
  query listUsers {
    listUsers {
      id
      firstname
      lastname
      email
      company
      organisations {
        id
        name
        projects {
          id
          name
          organisation {
            id
            name
          }
          workflows {
            id
            name
            projectId
            eventsJson {
              id
            }
          }
          teamMembers {
            id
            userId
            lastLogin
            email
          }
        }
      }
      registrationDate
      lastLogin
      loginCounter
      referralSource
      usageIntent
    }
  }
`
