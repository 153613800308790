import gql from 'graphql-tag'

export default gql`
  mutation CreateRequirementAttachment($workflowId: String, $input: CreateRequirementAttachmentInput!) {
    createRequirementAttachment(workflowId: $workflowId, input: $input) {
      id
      type
      url
      dateCreated
      name
      mimeType
      requirementId
      workflowId
    }
  }
`
