import gql from 'graphql-tag'

export default gql`
  mutation createProject($name: String!, $organisationId: String!) {
    createProject(name: $name, organisationId: $organisationId) {
      createProjectFunction {
        id
        name
        organisationId
        organisation {
          id
          name
          userId
          user {
            id
            firstname
            lastname
          }
        }
      }
      createAdminTeamMember {
        id
        userId
        project {
          id
          name
          organisationId
          organisation {
            id
            name
            userId
            user {
              id
              firstname
              lastname
            }
          }
        }
      }
    }
  }
`
