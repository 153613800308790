<template>
  <v-dialog :model-value="openShareDialog" @update:model-value="handleToogleDialog" width="650" v-on:keydown.stop>
    <v-card>
      <v-row class="d-flex justify-space-between align-center ma-2">
          <v-card-title>
            <span class="text-h5">Share workflow {{ workflow.name }}</span>
          </v-card-title>
          <v-btn variant="text" color="grey" @click="$emit('close')" icon="mdi-close">
          </v-btn>
      </v-row>

      <div>
        <v-card-text class="pt-0">
          <v-container grid-list>
            <span>Copy the link below to share the workflow.</span>
            <div class="access-level-select-container">
              <v-icon>mdi-earth</v-icon>
              <span>Anyone with the link</span>
              <v-select
                  :disabled="!isShareEnabled"
                  v-model="accessLevel"
                  :items="accessLevelItems"
                  item-title="name"
                  item-value="value"
                  flat
                  variant="solo"
                  hide-details
                  :class="isShareEnabled ? 'access-level-select' : 'access-level-select access-disabled'"
                  color="#4C83D1"
              >
              </v-select>
              <share-help-dialog #default="{ props }">
                <v-btn variant="text" icon size="x-small" v-bind="props" class="mr-2">
                  <v-icon size="20"> mdi-help-circle-outline</v-icon>
                </v-btn>
              </share-help-dialog>
            </div>
            <div class="link-container">
              <a :href="windowLocation">{{windowLocation}}</a>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="text" @click="$emit('close')">Cancel</v-btn>
          <v-btn color="primary" @click="saveLinkToClipboard">Copy link</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import ShareHelpDialog from '@/components/ShareHelpDialog.vue'
import workflowApi from '@/api/workflowApi'

export default {
  name: 'workflow-share-dialog',
  components: { ShareHelpDialog },
  props: {
    isShareEnabled: {},
    openShareDialog: {},
    workflow: {}
  },
  data () {
    return {
      accessLevelItems: [{
        name: 'No access',
        value: 'PRIVATE'
      }, {
        name: 'Can view',
        value: 'READ'
      }]
    }
  },
  computed: {
    accessLevel: {
      get: function () {
        return this.workflow.accessLevel || this.accessLevelItems[0]
      },
      set: function (value) {
        workflowApi.updateWorkflowNoHistoryMutation(
          this.$route.params.projectId,
          {
            id: this.$route.params.id,
            expectedVersion: this.workflow.version,
            accessLevel: value
          }
        )
      }
    },
    windowLocation: {
      get: function () { return window.location }
    }
  },
  methods: {
    saveLinkToClipboard () {
      navigator.clipboard.writeText(this.windowLocation)
      this.$store.commit('snackbar/showMessage', { content: 'Link copied. You can share it with your team!', timeout: 2000, centered: true })
    },
    handleToogleDialog (val) {
      if (!val) {
        this.$emit('close')
      }
    }
  }
}
</script>
<style scoped>

.v-list__tile_comments > div {
  height: auto;
}

nowrap {
  white-space: nowrap;
}
</style>
<style>

.access-level-select-container {
  display: flex;
  align-items: center;
}

.access-level-select-container span {
  margin: 0 5px;
}

.access-level-select >>> fieldset {
  border-style: none;
}

.access-level-select option {
  border: none; /* Remove border on option elements */
  padding: 5px; /* Add some padding for visual separation */
  font-size: 16px; /* Set a font size */
  color: #333; /* Set font color */
  appearance: none;
}

.link-container {
  border: 1px solid #979797;
  border-radius: 4px;
  padding: 5px 8px;
}

</style>
