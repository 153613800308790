import gql from 'graphql-tag'

export default gql`
  mutation deleteTeamMember($id: String!, $projectId: String!, $userId: String, $email: String) {
    deleteTeamMember(id: $id, projectId: $projectId, userId: $userId, email: $email) {
        id
        projectId
        userId
    }
  }
`
