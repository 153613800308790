import gql from 'graphql-tag'

export default gql`
  mutation UpdateRequirementType($workflowId: ID!, $id: ID!, $input: RequirementTypeInput!) {
    updateRequirementType(workflowId: $workflowId, id: $id, input: $input) {
      workflowId
      requirementTypes {
        id
        slot
        title
        description
        color {
          red
          green
          blue
          rgb
        }
        showInDiagram
        showInBacklog
        triggerType
        aggregateType
        defaultType
        aiCount
        workflowId
      }
    }
  }
`
