import gql from 'graphql-tag'
import { WORKFLOW_FIELDS } from '@/graphql/fragments'

export default gql`
  ${WORKFLOW_FIELDS}
  query getWorkflow($workflowId: String!, $projectId: String!) {
    getWorkflow(workflowId: $workflowId, projectId: $projectId) {
      ...WorkflowFields
    }
  }
`
