import gql from 'graphql-tag'

export default gql`
  mutation updateTeamMemberLastLogin($id: String!, $projectId: String!) {
    updateTeamMemberLastLogin(id: $id, projectId: $projectId) {
      id
      projectId
      lastLogin
    }
  }
`
