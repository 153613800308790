import client from '@/graphql/client'
import ListBCObjects from '../../graphql/queries/ListBCObjects'
import CreateBCObject from '../../graphql/mutations/CreateBCObject'
import UpdateBCObject from '../../graphql/mutations/UpdateBCObject'
import DeleteBoundedContext from '../../graphql/mutations/DeleteBoundedContext'

export const boundedContexts = {
  namespaced: true,
  state: {
    boundedContexts: {}
  },
  mutations: {
    createObject (state, payload) {
      state.boundedContexts[payload.id] = payload
    },
    putObjects (state, payload) {
      payload.forEach(object => {
        if (!state.boundedContexts[object.boundedContextId]) {
          state.boundedContexts[object.boundedContextId] = []
        }
        state.boundedContexts[object.boundedContextId].push(object)
      })
    },
    updateObject (state, payload) {
      const objects = state.boundedContexts[payload.boundedContextId]
      if (!objects) return

      const objectIndex = objects.findIndex(o => o.id === payload.id)
      if (objectIndex === -1) return

      objects[objectIndex] = payload
    },
    clearBoundedContext (state, id) {
      state.boundedContexts[id] = null
    }
  },
  actions: {
    async createObject ({ commit }, { boundedContextId, filepath, contents, prompt }) {
      const res = await client.query({
        operationName: 'CreateBCObject',
        query: CreateBCObject,
        variables: {
          input: {
            boundedContextId,
            filepath,
            contents,
            prompt
          }
        }
      })
      commit('createObject', res.createBCObject)
      return { ...res.createBCObject }
    },

    async getObjectsFromServer ({ commit }, boundedContextId) {
      const res = await client.query({
        operationName: 'ListBCObjects',
        query: ListBCObjects,
        variables: { boundedContextId }
      })
      commit('putObjects', res.listBCObjects)
      return res.listBCObjects
    },

    async deleteBoundedContext ({ commit }, boundedContextId) {
      await client.query({
        operationName: 'DeleteBoundedContext',
        query: DeleteBoundedContext,
        variables: { boundedContextId }
      })
      commit('clearBoundedContext', boundedContextId)
      return true
    },

    async updateObjectContents ({ commit }, object) {
      const res = await client.query({
        operationName: 'UpdateBCObject',
        query: UpdateBCObject,
        variables: {
          id: object.id,
          input: {
            contents: object.contents
          }
        }
      })
      commit('updateObject', res.updateBCObject)
    }
  }
}
