import gql from 'graphql-tag'

const ATTACHMENT_FIELDS = gql`
  fragment AttachmentFields on Attachment {
    id
    type
    url
    dateCreated
    name
    mimeType
  }
`
export const REQUIREMENT_FIELDS = gql`
  ${ATTACHMENT_FIELDS}
  fragment RequirementFields on Requirement {
    id
    description
    userId
    created
    updated
    releaseNo
    sortkey
    done
    version
    creator {
      firstname
      lastname
      id
    }
    status {
      userId
      prio
      message
      id
      lastUpdate
    }
    attachments {
      ...AttachmentFields
    }
    requirementStatuses {
      userId
      prio
      message
      id
      lastUpdate
    }
    cardTypeId
    cardType {
      id
      title
      triggerType
      aggregateType
      color {
        red
        green
        blue
      }
    }
    comments {
      id
      author {
        id
        firstname
        lastname
      }
      text
      dateCreated
      dateUpdated
      generatedByAi
    }
    reactions {
      direction
      user {
        id
        firstname
        lastname
      }
    }
    assignee {
      id
      firstname
      lastname
    }
    boundedContext
  }
`

export const WORKFLOW_FIELDS = gql`
  ${REQUIREMENT_FIELDS}
  fragment WorkflowFields on Workflow {
    id
    projectId
    name
    accessLevel
    version
    sameAsVersion
    status
    updatedAt
    starred
    aiDescription
    aiVersion
    aiVerbPastTense
    beamAiOptions {
      dataFieldCount
    }
    updateNote {
      userRealName
      action
      target
    }
    lanes {
      id
      name
    }
    eventDefinition {
      title
      description
      color {
        red
        green
        blue
        rgb
      }
    }
    roleDefinition {
      title
      description
      color {
        red
        green
        blue
        rgb
      }
    }
    teamMembers {
      id
      userId
      email
      user {
        id
        email
        firstname
        lastname
      }
    }
    userStatusesWorkflow {
      vote
      message
      lastUpdate
      userId
    }
    eventsJson {
      id
      __typename @skip(if: true)
      laneId
      groupId
      description
      type
      conditionLabel
      version
      conditionLabels {
        parentId
        name
      }
      parents
      offset
      subprocess {
        id
        name
      }
      dataModels {
        name
        type
        dataFields {
          verb
          name
          type
          description
          fieldName
          tableName
          systemName
          tags
          exampleData
        }
      }
      color {
        red
        green
        blue
        rgb
      }
      requirementsJson {
        ...RequirementFields
      }
    }
    dataModels {
      name
      type
      boundedContext
      connectedToEvents
      dataFields {
        verb
        name
        type
        description
        fieldName
        tableName
        systemName
        tags
        exampleData
      }
    }
    goals {
      title
      description
    }
    boundedContexts {
      id
      name
    }
    groups {
      id
      name
      startSlotX
    }
  }
`
