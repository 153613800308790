import { apolloClient } from '@/vue-apollo'
import CreateOrganisation from '@/graphql/mutations/CreateOrganisation'
import DeleteOrganisation from '@/graphql/mutations/DeleteOrganisation'
import ListOrganisations from '@/graphql/queries/ListOrganisations'

export const organisations = {
  namespaced: true,
  state: {
    organisations: null
  },
  mutations: {
    setOrganisations (state, payload) {
      state.organisations = payload
    },
    addOrUpdateOrganisation (state, payload) {
      const index = state.organisations?.findIndex((organisation) => organisation.id === payload.id)
      if (index >= 0) {
        state.organisations[index] = Object.assign(state.organisations[index], payload)
      } else {
        if (!state.organisations) { state.organisations = [] }
        state.organisations.push(payload)
      }
    },
    deleteOrganisation (state, payload) {
      const index = state.organisations.findIndex((organisation) => organisation.id === payload.id)
      if (index >= 0) {
        state.organisations.splice(index, 1)
      }
    }
  },
  actions: {
    async createOrganisation ({ commit }, { name }) {
      try {
        const res = await apolloClient.mutate({
          mutation: CreateOrganisation,
          variables: {
            name
          },
          fetchPolicy: 'no-cache'
        })
        commit('addOrUpdateOrganisation', res.data.createOrganisation)
        commit('snackbar/showMessage', { content: 'Created' }, { root: true })
        return res.data.createOrganisation.id
      } catch (e) {
        console.log(e)
      }
    },

    async updateOrganisation ({ commit, dispatch }, { name, id }) {
      try {
        const res = await apolloClient.mutate({
          mutation: CreateOrganisation,
          variables: {
            id,
            name
          },
          fetchPolicy: 'no-cache'
        })
        commit('addOrUpdateOrganisation', res.data.createOrganisation)
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
        // update project names in projectMemberships
        await dispatch('projectMemberships/listProjectMembershipsForCurrentUser', null, { root: true })
        return res.data.createOrganisation.id
      } catch (e) {
        console.log(e)
      }
    },

    async deleteOrganisation ({ commit }, { id }) {
      try {
        const res = await apolloClient.mutate({
          mutation: DeleteOrganisation,
          variables: {
            id
          },
          fetchPolicy: 'no-cache'
        })
        commit('deleteOrganisation', res.data.deleteOrganisation)
        commit('snackbar/showMessage', { content: 'Deleted' }, { root: true })
      } catch (e) {
        alert(e.message)
        console.log(e)
      }
    },

    async listOrganisations ({ commit }) {
      const res = await apolloClient.query({
        query: ListOrganisations,
        fetchPolicy: 'no-cache'
      })
      commit('setOrganisations', res.data.listOrganisations)
    }
  }
}
