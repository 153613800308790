<template>
  <v-dialog
    v-model="open"
    max-width="600px"
    scollable
    v-on:keydown.stop
  >
    <template v-slot:activator="{ on }">
      <slot :openDialog="openDialog" v-on="{ ...on }"></slot>
    </template>

    <v-card elevation="0">
      <v-toolbar density="compact" elevation="0" dark color="primary">
        <v-toolbar-title><v-icon size="small">mdi-help-circle-outline</v-icon>&nbsp;&nbsp;<b>About workflows</b></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon color="grey" @click="open = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="mt-1">
        <h3>Workflow</h3>
        Use workflows for fast collaborative modelling. Popular use cases include:<br />
        <b class="ml-5">1. EventStorming</b><br />
        <b class="ml-5">2. Simplified BPM</b><br />
        <b class="ml-5">3. User Story Mapping</b><br />
        <b class="ml-5">4. Business Event Analysis & Modelling (BEAM)</b><br /><br />

        <h3>Events</h3>
        Each <b>event</b> in the workflow can be a domain event, a step in a user journey, a step in a business process, or a scrum epic.<br /><br />

        <h3>Lanes</h3>
        <b>Lanes</b> are typically used to indicate which role (or system) is the main actor for each step.<br /><br />

        <h3>Cards</h3>
        Once you have a basic structure in place with lanes and events, you can enrich your model with <b>cards</b>, such as user stories, problems, opportunities, comments, etc. Cards are configurable under the <v-icon size="x-small">mdi-cog-outline</v-icon> icon in the top right. You can choose which card types to display in the workflow and/or in the backlog.<br /><br />

        <h3>Releases</h3>
        Each card can be assigned a <b>release</b> number by selecting a number from the 'Release' column in the 'All cards' table below the diagram (Product Backlog). You can then apply a visual filter to the entire workspace, thereby visualizing the desired scope in each iteration. Use the dropdown 'Filter Releases' to select the releases you want to include.<br /><br />

        <h3>Data Models</h3>
        To gain a deeper understanding of a business process, it's helpful to collect sample data. Navigate to the 'Data Models' tab, select an event, attach an Event Story, and capture example data relevant to that step. <a target="_blank" href="https://www.youtube.com/watch?v=vMjWU7MZC0Y">Click here for a quick 3 min video introduction to BEAM</a><br /><br />

        <h3>AI</h3>
        You can use AI to quickly create any kind of workflow, enriched with cards of your choice. It can be used to create a workflow from scratch or to add steps, cards, comments, and Data Models to an existing workflow. To create a complete workflow with AI, start from an empty workflow and click the 'Generate Workflow with AI' button.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  },
  methods: {
    openDialog () {
      this.open = true
    }
  }
}
</script>
