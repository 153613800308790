import gql from 'graphql-tag'

export default gql`
  mutation toggleRequirementReaction($workflowId: String!, $input: RequirementReactionInput) {
    toggleRequirementReaction(workflowId: $workflowId, input: $input) {
      workflowId
      requirementId
      direction
      user {
        id
        firstname
        lastname
      }
    }
  }
`
