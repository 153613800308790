<template>
  <div v-if="!loadingState">
    <navigation-top />
    <div v-if="hasRecentWorkflows">
      <v-card class="dashboard-container pa-6 ma-0 mb-3 pr-0">
        <h3 class="text-h5 font-weight-bold">Recent workflows</h3>

        <v-row class="row py-3">
          <v-col
            v-for="workflow in recentWorkflowsWithData"
            :key="workflow.id"
            cols="6"
          >
            <dashboard-workflow-card
              :workflow="workflow"
              :hideActions="true"
            ></dashboard-workflow-card>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <!-- no project and no organisation -->
    <div>
      <div v-if="!hasOrganisations">
        <v-card class="dashboard-container pa-6 ma-0 mb-3">
          <h3 class="text-h5 font-weight-bold">Welcome to <span style="color: #229AD6;">QLERIFY!</span></h3>
          <br />
          <p>Begin by creating an Organisation!</p>

          <v-container :fluid="true">
            <v-row justify="start">
              <v-col class="flex-grow-0 pl-0">
                <settings-organisation-dialog>
                  <template #activator="{ props }">
                    <v-btn color="primary" v-bind="props">
                      Create an organisation
                    </v-btn>
                  </template>
                </settings-organisation-dialog>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>

      <!-- available projects -->
      <div v-if="hasProjects">
        <v-card class="dashboard-container pa-6 ma-0">
          <h3 class="text-h5 font-weight-bold pb-4">Select a project</h3>

          <p>
            Select or create a new project. Manage projects & organisations under settings in the left menu
            <v-btn
              :href="`/settings/${
              $route.params.projectId ? $route.params.projectId : ''
            }`"
            icon="mdi-cog"
            density="compact"
            variant="plain"
            />
          </p>
          <v-data-table
            :headers="headersProjects"
            :items="filteredProjectMemberships"
            v-model:sort-by="sortBy"
            hide-default-footer
            class="elevation-1 round-bottom"
            no-data-text=""
            items-per-page="-1"
            :must-sort="true"
          >
            <template v-slot:bottom></template>
            <template v-slot:item="props">
              <tr
                :style="{ cursor: 'pointer' }"
                @click="setProject(props.item.project.id)"
              >
                <td>
                  {{ props.item.project.organisation.name }}</td>
                <td>
                  {{ props.item.project.name }}
                </td>
                <td>
                  {{
                    props.item.project.organisation.user.firstname +
                    " " +
                    props.item.project.organisation.user.lastname
                  }}
                  {{
                    props.item.project.organisation.user.id == currentUser?.id
                      ? "(you)"
                      : ""
                  }}
                </td>
                <td>
                  {{ props.item.lastLogin ? formatDistanceToNowStrict(new Date(props.item.lastLogin), { addSuffix: true }) : '' }}
                </td>
                <td class="text-right" style="outline: none">
                  <template
                    v-if="
                      props.item.project.organisation.user.id == currentUser?.id
                    "
                  >
                    <v-icon
                      size="small"
                    class="mr-2"
                    @click.stop="editItemProject(props.item.project)"
                    color="grey-darken-1"
                    icon="mdi-pencil"
                  />
                  <v-icon
                    size="small"
                    @click.stop="deleteItemProject(props.item.project)"
                      color="grey-darken-1"
                    icon="mdi-delete"
                  />
                </template>
              </td>
            </tr>
          </template>
          </v-data-table>

          <v-container :fluid="true" class="ma-0 mb-3">
            <v-row justify="end">
              <v-col class="flex-grow-0">
                <settings-project-dialog
                  ref="dialogProject"
                  :setProject="false"
                  createOrganisationLabel="click CREATE AN ORGANISATION"
                >
                  <template #activator="{ props }">
                    <v-btn color="primary" v-bind="props"> Create a project</v-btn>
                  </template>
                </settings-project-dialog>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <dashboard-ai-project
          :insideProject="false"
        ></dashboard-ai-project>

        <dashboard-demo-project
          @project-created="onProjectCreated($event)"
        ></dashboard-demo-project>
      </div>

      <div v-if="hasOrganisations && !hasProjects">
        <v-card class="dashboard-container pa-6 ma-0">
          <div class="d-flex align-center pb-2">
            <h3 class="text-h5 font-weight-bold">Welcome to <span style="color: #229AD6;">QLERIFY!</span></h3>
          </div>

          <p>
            You have no projects yet. To start modelling you first need to create or be invited to a <strong>project</strong>.
          </p>

          <v-container>
            <v-row justify="start">
              <v-col class="flex-grow-0 pl-0">
                <settings-project-dialog :setProject="false">
                  <template #activator="{ props }">
                    <v-btn color="primary" v-bind="props"> Create a project </v-btn>
                  </template>
                </settings-project-dialog>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <dashboard-ai-project
          :insideProject="false"
        ></dashboard-ai-project>

        <dashboard-demo-project
          :organisations="organisations"
          @project-created="onProjectCreated($event)"
        ></dashboard-demo-project>

      </div>

      <dashboard-video />
    </div>
  </div>
</template>

<script>
import SettingsProjectDialog from '@/components/SettingsProjectDialog'
import SettingsOrganisationDialog from '@/components/SettingsOrganisationDialog'
import DashboardWorkflowCard from '@/components/DashboardWorkflowCard'
import DashboardDemoProject from '@/components/DashboardDemoProject'
import DashboardAiProject from '@/components/DashboardAiProject'
import DashboardVideo from '@/components/DashboardVideo'
import { EventBus } from '@/event-bus/event-bus.js'
import projectApi from '@/api/projectApi'
import NavigationTop from '@/components/NavigationTop.vue'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import recentWorkflowsApi from '@/api/recentWorkflowsApi'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    SettingsProjectDialog,
    SettingsOrganisationDialog,
    DashboardWorkflowCard,
    DashboardDemoProject,
    DashboardAiProject,
    DashboardVideo,
    NavigationTop
  },

  data () {
    return {
      sortBy: [{ key: 'lastLogin', order: 'desc' }],
      headersProjects: [
        {
          title: 'Organisation',
          align: 'left',
          sortable: true,
          value: 'project.organisation.name'
        },
        {
          title: 'Project',
          align: 'left',
          sortable: true,
          value: 'project.name'
        },
        {
          title: 'Owner',
          align: 'left',
          sortable: false
        },
        {
          title: 'Last seen',
          align: 'left',
          sortable: true,
          value: 'lastLogin',
          key: 'lastLogin'
        },
        {
          title: '',
          align: 'center',
          sortable: false
        }
      ],
      recentWorkflowsWithData: []
    }
  },
  methods: {
    formatDistanceToNowStrict,
    setProject (id) {
      EventBus.$emit('set-project', { id })
    },
    editItemProject (item) {
      this.$refs.dialogProject.setItem(Object.assign({}, item))
      this.$refs.dialogProject.openProjectDialog()
    },
    async deleteItemProject (item) {
      const del = confirm('Are you sure you want to delete this project and all its team members, events and cards? This operation cannot be undone.')
      if (del) {
        recentWorkflowsApi.deleteProject(item.id)
        await projectApi.deleteProject(item)
      }
    },
    async onProjectCreated (project) {
      EventBus.$emit('set-project', project)
    }
  },
  computed: {
    ...mapState({
      projectMemberships: state => state.projectMemberships.projectMemberships,
      organisations: state => state.organisations.organisations,
      currentUser: state => state.user.user,
      recentWorkflows: state => state.recentWorkflows.recentWorkflows
    }),
    ...mapGetters({
      loadingState: 'loading/getLoadingState'
    }),
    hasRecentWorkflows () {
      return this.recentWorkflowsWithData?.length > 0
    },
    filteredProjectMemberships () {
      return this.projectMemberships?.filter(pm => pm.project)
    },
    hasOrganisations () {
      return Boolean(this.organisations && this.organisations.length)
    },
    hasProjects () {
      if (this.projectMemberships && this.projectMemberships.length && this.projectMemberships[0].project) {
        return true
      }
      if (this.organisations) {
        for (const organisation of this.organisations) {
          if (organisation.projects && organisation.projects.length) {
            return true
          }
        }
      }
      return false
    },
    recentWorkflows () {
      // get recent workflows from localStorage and add projectName or Public
      const recentWorkflows = recentWorkflowsApi.getList()
      recentWorkflows.forEach((it, _) => {
        const membership = this.filteredProjectMemberships?.find(it2 => it2.project.id === it.projectId)
        const projectName = membership ? membership.project.name : 'Public'
        it.projectName = projectName
      })
      return recentWorkflows
    }
  },
  watch: {
    recentWorkflows: {
      handler: async function (val) {
        this.$loadingState.start()
        // add morre data to recent workflows
        // by fetching the workflow summary
        const recentWorkflowsWithData = []
        for (const it of val) {
          const workflow = await recentWorkflowsApi.getWorkflowSummary(it, it.projectName)
          if (workflow) {
            recentWorkflowsWithData.push(workflow)
          }
        }
        this.$loadingState.stop()
        this.recentWorkflowsWithData = recentWorkflowsWithData
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>
.dashboard-container {
  max-width: 1000px;
  margin-left: 12px;
  margin-top: 12px;
}
</style>
