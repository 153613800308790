<template>
  <div v-on:keydown.stop>
    <v-data-table
      v-if="rows"
      :items="rows"
      :items-per-page="-1"
      :group-by="groupBy"
      no-data-text="No cards (click on 'Add Card ...' to add one)"
      class="hide-first-column elevation-1"
    >
      <template v-slot:bottom></template>
      <template v-slot:headers></template>

      <template #group-header="props">
        {{ props.isGroupOpen(props.item) ? '' : props.toggleGroup(props.item) }}
        <td
          :colspan="noOfColumns + 2"
          class="bg-grey-darken-2 px-4 py-2 text-white"
          style="position: relative"
        >
          <b
            :data-release="props.item.value"
            @dragover="dragoverGroup($event, props.item.value)"
          >
            {{ getReleaseName(props.item.value) }}
          </b>
          <div
            @dragover="dragoverGroup($event, props.item.value)"
            v-if="firstReleaseIsHigherThanOne(props.item.value)"
            style="
              position: absolute;
              width: 200px;
              margin: auto;
              left: 0;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
            "
            class="dragover-to-add-release-top text-center"
          >
            Drag here to add release
          </div>
        </td>
      </template>
      <template #item="props">
        <tr :key="props.item.release + '-' + props.item.rowInRelease">
          <td class="pre-column"></td>
          <template v-for="(column, index) in columns" :key="index">
            <td
              :data-column="index"
              :data-row="props.item.rowInRelease"
              :data-release="props.item.release"
              class="text-left description-column"
              @click="focusOnThisCell"
              @dragover="dragoverCell"
              @dragenter="dragenterCell"
              @drop="drop"
            >
              <div
                v-if="!!props.item.cards[index]"
                :id="props.item.cards[index]?.id"
                :class="{
                  dragging:
                    props.item.cards[index].id == draggedCardData?.card.id,
                }"
                class="mt-1 mb-1 card-container draggable"
                :draggable="!$store.state.isWorkflowDisabled"
                @dragstart="dragstart"
                @dragend="dragEnd"
              >
                <requirement-description
                  :item="props.item.cards[index]"
                  :disabled="$store.state.isWorkflowDisabled"
                  :compact-replies="true"
                  :hideActions="hideActions"
                  :showAi="false"
                  withDates
                  hideAvatar
                  @ask-for-reply="$emit('ask-for-reply', $event)"
                >
                  <requirement-card-description-field
                    :item="props.item.cards[index]"
                    :index="1000 * props.item.rowIndex + parseInt(index) + 1"
                    :disabled="$store.state.isWorkflowDisabled"
                    :focus-card="focusCard"
                    @move-focus="moveFocus"
                    :scrollIntoViewArg="true"
                  ></requirement-card-description-field>
                </requirement-description>
              </div>
            </td>
          </template>
          <td
            class="post-column"
            :style="`min-width: ${
              (diagramWidth) -
              noOfColumns * 160 -
              120
            }px;`"
          ></td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import RequirementDescription from '@/components/RequirementDescription'
import RequirementCardDescriptionField from '@/components/RequirementCardDescriptionField'
import workflowApi from '@/api/workflowApi'
import { cardHelpers } from '@/mixins/cardHelpers.js'
import midString from '@/utils/mid-string'
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    RequirementDescription,
    RequirementCardDescriptionField
  },

  mixins: [cardHelpers],

  props: {
    items: Array,
    idIndexMap: Object,
    search: String,
    focusCard: String,
    showDone: Boolean,
    hideActions: Boolean,
    releases: Array
  },

  data () {
    return {
      eventId: null,
      editRequirement: null,
      rows: [],
      draggedCardData: null,
      draggedCardDataStartingPosition: null,
      synchronizingScroll: false,
      groupBy: [{
        key: 'release',
        order: 'asc'
      }]
    }
  },

  computed: {
    ...mapState({
      requirementTypes: state => state.requirementTypes.requirementTypes,
      showReleaseNo: state => state.workflow.showReleaseNo,
      bpmnScrollLeft: state => state.synchronizedScrolling.bpmnScrollLeft
    }),
    ...mapGetters({
      svgGraphData: 'svgGraphData/svgGraphData',
      baseData: 'svgGraphData/baseData',
      diagramWidth: 'synchronizedScrolling/diagramWidth'
    }),
    noOfColumns () {
      return Object.keys(this.columns).length
    },

    noOfRows () {
      return this.rowsData.rows.length
    },

    columns () {
      const res = {}
      for (let i = 0; i < this.baseData?.columns?.length; i++) {
        res[i] = this.baseData?.columnsWithCards[i] || {}
      }
      return res
    },

    rowsData () {
      const rows = []
      const displayTypeInBacklog = {}
      const releases = JSON.parse(JSON.stringify(this.releases))
      releases.push({
        value: 'unassigned',
        text: 'unassigned'
      })
      let rowIndex = 0
      let rowsInReleaseCounter
      if (Array.isArray(this.requirementTypes)) {
        for (const { id, showInBacklog } of this.requirementTypes) {
          displayTypeInBacklog[id] = showInBacklog
        }
      }
      for (const release of releases) {
        rowsInReleaseCounter = 0
        for (const column in this.columns) {
          if (this.columns[column][release.value]?.length > 0) {
            const cards = this.columns[column][release.value]
              .filter(({ done }) => this.showDone === Boolean(done))
              .filter(({ cardType }) => {
                if (!cardType || !Object.prototype.hasOwnProperty.call(displayTypeInBacklog, cardType.id)) {
                  return true
                }
                return displayTypeInBacklog[cardType.id]
              })
            cards.sort((a, b) => {
              if (a.sortkey < b.sortkey) {
                return -1
              } else {
                return 1
              }
            })
            let i = 0
            for (const card of cards) {
              if (!this.showReleaseNo || this.showReleaseNo.length === 0 || this.showReleaseNo.includes(card.releaseNo)) {
                if (!this.search || this.includeInSearch(this.search, card)) {
                  let index = rows.findIndex(row => row.release === release.value && row.rowInRelease === i)
                  if (index >= 0) {
                    if (!rows[index].cards) { rows[index].cards = [] }
                    rows[index].cards[column] = card
                  } else {
                    const row = {
                      release: release.value,
                      rowInRelease: i,
                      rowIndex: rowIndex + i
                    }
                    row.cards = []
                    row.cards[column] = card
                    index = rows.push(row) - 1
                  }
                  i++
                }
              }
            }
            if (i > rowsInReleaseCounter) {
              rowsInReleaseCounter = i
            }
          }
        }
        if (rowsInReleaseCounter > 0) {
          rowIndex = rowIndex + rowsInReleaseCounter
        }
      }
      if (!(this.showReleaseNo?.length > 0)) {
        this.fillReleaseGaps(rows)
      }
      return {
        rows
      }
    }
  },

  methods: {
    ...mapMutations({
      setBpmnScrollLeft: 'synchronizedScrolling/setBpmnScrollLeft'
    }),
    getNextUnusedReleaseNo () {
      return 1 + Math.max(...this.rows.map(el => isNaN(el.release) ? 0 : el.release))
    },
    getReleaseName (group) {
      if (group === 'z-release-placehoder') {
        return 'Release ' + this.getNextUnusedReleaseNo()
      } else if (group) {
        return 'Release ' + group
      } else {
        return 'No release assigned'
      }
    },
    firstReleaseIsHigherThanOne (group) {
      if ((group === 'unassigned' && this.releases?.length === 1 && this.releases?.[0]?.value === 'unassigned') || (this.releases[0]?.value === group && group > 1)) {
        return true
      } else {
        return false
      }
    },
    moveFocus (direction, target) {
      switch (direction) {
        case 37: // left arrow
        case 'left':
          this.focusOnPreviousTabindex(target)
          break

        case 38: // up arrow
        case 'up':
          this.focusOnPreviousRow(target)
          break

        case 39: // right arrow
        case 'right':
          this.focusOnNextTabindex(target)
          break

        case 13: // enter
        case 40: // down arrow
        case 'down':
          this.focusOnNextRow(target)
          break
      }
    },
    fillReleaseGaps (rows) {
      for (let i = rows.length - 2; i > -1; i--) {
        if (rows[i].release < (rows[i + 1].release - 1)) {
          rows.splice(i + 1, 0, {
            release: rows[i + 1].release - 1,
            rowInRelease: 0,
            cards: []
          })
          i++
        }
      }
    },
    handleScroll (ev) {
      // when this component is scrolled, also scroll the bpmn component
      const scrollLeft = document.getElementsByClassName('v-table__wrapper')[0].scrollLeft
      this.setBpmnScrollLeft(scrollLeft)
    },
    findHighestNode (eventIds) {
      const eventIdsWithY = eventIds.map(id => { return { y: this.svgGraphData[id].y, id } })
      eventIdsWithY.sort((a, b) => a.y - b.y)
      return eventIdsWithY[0].id
    },
    drop (ev) {
      let eventId
      ev.preventDefault()
      const data = ev.dataTransfer.getData('text')
      const events = this.baseData.columns[this.draggedCardData.columnIndex]
      if (this.draggedCardData.columnIndex !== this.draggedCardDataStartingPosition.columnIndex) {
        const eventIds = []
        for (const laneId in events) {
          for (const event of events[laneId]) {
            eventIds.push(event.id)
          }
        }
        eventId = this.findHighestNode(eventIds)
      } else {
        eventId = this.baseData.cardIdToEventIdMap[this.draggedCardDataStartingPosition.card.id]
      }
      const rows = this.rows.filter(row => row.release === this.draggedCardData.release)
      const sortKeyAbove = rows[this.draggedCardData.rowInRelease - 1]?.cards[this.draggedCardData.columnIndex]?.sortkey || ''
      const sortKeyBelow = rows[this.draggedCardData.rowInRelease + 1]?.cards?.[this.draggedCardData.columnIndex]?.sortkey || ''
      if (this.draggedCardData.release === 'unassigned') {
        this.draggedCardData.release = null
      } else if (this.draggedCardData.release === 'z-release-placehoder') {
        this.draggedCardData.release = this.getNextUnusedReleaseNo()
      }
      const index = this.items.findIndex(req => req.id === data) // data holds the id
      if (this.items[index]?.eventId === eventId) {
        // only change prio
        this.changeReleaseNo(this.items[index], this.draggedCardData.release, sortKeyAbove, sortKeyBelow)
      } else {
        // change event (and possibly also prio)
        const sortkey = midString(sortKeyAbove, sortKeyBelow)
        workflowApi.moveRequirementJson(this.$route.params.id, '', eventId, data, this.draggedCardData.release, sortkey)
      }
    },
    changeReleaseNo (item, releaseNo, sortKeyAbove, sortKeyBelow) {
      const sortkey = midString(sortKeyAbove || '', sortKeyBelow || '')
      workflowApi.updateRequirementsJsonMutation(this.$route.params.id, [{
        requirementId: item.id,
        releaseNo,
        sortkey,
        updateNote: {
          action: 'changed release number of',
          target: item.description
        }
      }])
    },
    getCardData (id) {
      for (const rowIndex in this.rows) {
        for (const columnIndex in this.rows[rowIndex].cards) {
          if (this.rows[rowIndex].cards[columnIndex]?.id === id) {
            return {
              card: JSON.parse(JSON.stringify(this.rows[rowIndex].cards[columnIndex])),
              rowIndex: parseInt(rowIndex),
              columnIndex: parseInt(columnIndex),
              release: this.rows[rowIndex].release || null,
              rowInRelease: this.rows[rowIndex].rowInRelease
            }
          }
        }
      }
    },
    getDragTarget (ev) {
      if (typeof ev.target.closest === 'function') {
        return ev.target.closest('.card-container')
      } else {
        return ev.target.parentNode.closest('.card-container')
      }
    },
    dragstart (ev) {
      const target = this.getDragTarget(ev)
      this.draggedCardData = this.getCardData(target.id)
      this.draggedCardDataStartingPosition = JSON.parse(JSON.stringify(this.draggedCardData))
      ev.dataTransfer.setData('text', target.id)
      ev.dataTransfer.setData('component', 'user-story-mapping')
      return target.classList?.add('dragging')
    },
    dragEnd (ev) {
      const target = this.getDragTarget(ev)
      target.classList.remove('dragging')
      this.draggedCardData = null
    },
    dragoverGroup (ev, group) {
      const target = ev.target
      if (!target || !this.draggedCardData || group !== this.rows[0].release || this.rows[0].release === 1) {
        return // not the first release so don't do anything
      }
      if (this.rows[0].placeholder === true) {
        return // we already have a placeholder
      }
      this.rows.unshift({
        cards: new Array(this.noOfColumns),
        release: group === 'unassigned' ? 1 : group - 1,
        rowInRelease: 0,
        placeholder: true
      })
      this.$nextTick(() => {
        window.scrollBy({
          top: 60,
          left: 0,
          behavior: 'smooth'
        })
      })
      if (ev) { ev.preventDefault() }
    },
    dragoverBottomPanel (ev) {
      const lastRow = this.rows.length - 1
      if (this.rows[lastRow].placeholder === true) {
        return // we already have a placeholder
      }
      this.rows.push({
        cards: new Array(this.noOfColumns),
        release: 'z-release-placehoder',
        rowInRelease: 0,
        placeholder: true
      })
      if (ev) { ev.preventDefault() }
    },
    dragoverCell (ev) {
      ev.preventDefault()
    },
    dragenterCell (ev) {
      const targetCell = this.getTargetCell(ev)
      if (targetCell && !this.sameCell(this.draggedCardData, targetCell)) {
        // restore all cards that might have been moved by dragging around
        if (!targetCell.placeholder &&
          (this.draggedCardData.columnIndex !== targetCell.columnIndex ||
            this.draggedCardData.release !== targetCell.release)) {
          this.rows = JSON.parse(JSON.stringify(this.rowsData.rows))
          this.rows[this.draggedCardDataStartingPosition.rowIndex].cards[this.draggedCardDataStartingPosition.columnIndex] = null
        }

        // assign variables
        const row = this.rows[targetCell.rowIndex]

        // move away colliding cards
        if (targetCell.card && targetCell.card.id !== this.draggedCardData.card.id) {
          if (this.draggedCardData.columnIndex === targetCell.columnIndex &&
            this.draggedCardData.rowIndex === (targetCell.rowIndex - 1) &&
            this.draggedCardData.release === targetCell.release) {
            // drag down from above
            this.moveCardUp(targetCell)
            this.insertDraggedCard(this.draggedCardData, targetCell)
          } else {
            // drag from sides or below
            this.moveCardDown(this.rows, row, targetCell.rowIndex, targetCell.columnIndex, this.draggedCardData.card.id)
            this.insertDraggedCard(this.draggedCardData, targetCell)
          }
        } else {
          // no collision
          this.removeDraggedCard(this.draggedCardData)
          this.insertDraggedCard(this.draggedCardData, targetCell)
        }
      }
      ev.preventDefault()
    },
    sameCell (draggedCardData, targetCell) {
      if (draggedCardData.columnIndex === targetCell.columnIndex && draggedCardData.rowIndex === targetCell.rowIndex && draggedCardData.release === targetCell.release) {
        return true
      } else {
        return false
      }
    },
    getTargetCell (ev) {
      let res
      const targetCell = ev.target.closest('.description-column')
      if (targetCell) {
        if (targetCell.dataset.release === 'z-release-placehoder') {
          res = {
            release: 'z-release-placehoder',
            rowInRelease: parseInt(targetCell.dataset.row),
            columnIndex: parseInt(targetCell.dataset.column)
          }
        } else {
          res = {
            release: parseInt(targetCell.dataset.release) || 'unassigned',
            rowInRelease: parseInt(targetCell.dataset.row),
            columnIndex: parseInt(targetCell.dataset.column)
          }
        }
        const targetCellRowIndex = this.rows.findIndex(row => {
          return row.release === res.release && row.rowInRelease === res.rowInRelease
        })
        if (targetCellRowIndex === -1) {
          return null
        }

        res.rowIndex = targetCellRowIndex
        res.card = this.rows[targetCellRowIndex].cards[res.columnIndex] || null
        res.placeholder = this.rows[targetCellRowIndex].placeholder
        return res
      }
    },
    insertDraggedCard (draggedCardData, targetCell) {
      let rowIndex = targetCell.rowIndex
      while (
        rowIndex > 0 &&
        (this.rows[rowIndex - 1].cards[targetCell.columnIndex] === null || this.rows[rowIndex - 1].cards[targetCell.columnIndex] === undefined) &&
        this.rows[rowIndex - 1].release === targetCell.release
      ) {
        rowIndex--
      }
      this.rows[rowIndex].cards[targetCell.columnIndex] = draggedCardData.card
      this.draggedCardData = this.getCardData(this.draggedCardData.card.id)
    },
    removeDraggedCard (draggedCardData) {
      if (this.rows[draggedCardData.rowIndex]?.cards[draggedCardData.columnIndex]?.id === draggedCardData.card.id) {
        this.rows[draggedCardData.rowIndex].cards[draggedCardData.columnIndex] = null
      }
    },
    moveCardUp (targetCell) {
      const targetRow = this.rows[targetCell.rowIndex]
      const targetCard = JSON.parse(JSON.stringify(targetRow.cards[targetCell.columnIndex]))
      this.rows[targetCell.rowIndex - 1].cards[targetCell.columnIndex] = targetCard
      this.rows[targetCell.rowIndex].cards[targetCell.columnIndex] = undefined
    },
    moveCardDown (rows, row, rowIndex, columnIndex, ignoreCardId) {
      let nextRow = this.rows.find(rowObj => {
        return rowObj.release === row.release && rowObj.rowInRelease === row.rowInRelease + 1
      })
      if (!nextRow) {
        const index = this.addEmptyRowToRelease(this.rows, row.release, row.rowInRelease + 1, rowIndex + 1)
        nextRow = this.rows[index]
      }
      if (nextRow.cards[columnIndex] && nextRow.cards[columnIndex].id !== ignoreCardId) {
        this.moveCardDown(rows, nextRow, rowIndex + 1, columnIndex)
      }
      nextRow.cards[columnIndex] = row.cards[columnIndex]
      row.cards[columnIndex] = undefined
    },
    addEmptyRowToRelease (rows, release, rowInRelease, rowIndex) {
      this.rows.splice(rowIndex, 0, {
        release,
        rowInRelease,
        cards: []
      })
      return rowIndex
    },
    includeInSearch (search, card) {
      if (typeof search !== 'string' || !search.trim()) {
        return true
      }

      const {
        id,
        description,
        releaseNo,
        cardType,
        status,
        creator,
        eventId
      } = card
      let computedStatus

      if (status) {
        const statusValues = ['mo', 's', 'co', 'w']
        const prioIndex = status.prio - 1

        computedStatus = statusValues[prioIndex]
      }

      const searchFields = [
        id,
        description,
        releaseNo,
        cardType && cardType.title,
        computedStatus,
        creator && `${creator.firstname} ${creator.lastname}`,
        eventId
      ].filter(Boolean).join(' ').toLowerCase()
      const searchText = search.toString().toLowerCase()

      return searchFields.includes(searchText)
    },
    focusOnThisCell (event) {
      const pre = event.target.querySelector('pre')
      if (pre) {
        pre.focus()
      }
    },
    focusOnNextRow (target) {
      let nextRowTabindex = parseInt(target.tabIndex) + 1000
      if (nextRowTabindex > 0) {
        while (nextRowTabindex <= this.noOfRows * 1000) {
          const nextElement = document.querySelector(`[tabindex='${nextRowTabindex}']`)
          if (nextElement) {
            nextElement.focus()
            return true
          }
          nextRowTabindex += 1000
        }
      }
      target.blur()
      target.focus()
    },
    focusOnPreviousRow (target) {
      let prevRowTabindex = parseInt(target.tabIndex) - 1000
      if (prevRowTabindex > 0) {
        while (prevRowTabindex >= 0) {
          const previousElement = document.querySelector(`[tabindex='${prevRowTabindex}']`)
          if (previousElement) {
            previousElement.focus()
            break
          }
          prevRowTabindex -= 1000
        }
      }
    },
    focusOnPreviousTabindex (target) {
      let prevTabindex = parseInt(target.tabIndex) - 1
      const startPosition = prevTabindex
      if (prevTabindex > 0) {
        while (prevTabindex > (startPosition - this.noOfColumns)) {
          const nextElement = document.querySelector(`[tabindex='${prevTabindex}']`)
          if (nextElement && nextElement.classList && nextElement.classList.contains('description')) {
            nextElement.focus()
            break
          }
          prevTabindex--
        }
      }
    },
    focusOnNextTabindex (target) {
      let nextTabindex = parseInt(target.tabIndex) + 1
      const startPosition = nextTabindex
      if (nextTabindex > 0) {
        while (nextTabindex <= (startPosition + this.noOfColumns)) {
          const nextElement = document.querySelector(`[tabindex='${nextTabindex}']`)
          if (nextElement) {
            nextElement.focus()
            break
          }
          nextTabindex++
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const element = document.getElementsByClassName('v-table__wrapper')[0]
      element.addEventListener('scroll', this.handleScroll)
      this.rows = JSON.parse(JSON.stringify(this.rowsData.rows))
      const element2 = document.getElementsByClassName('dragover-to-add-release')[0]
      if (element2) {
        element2.addEventListener('dragover', this.dragoverBottomPanel)
      }
    })
  },
  beforeUnmount () {
    let res = document.getElementsByClassName('v-table__wrapper')[0]?.removeEventListener('scroll', this.handleScroll)
    res = document.getElementsByClassName('dragover-to-add-release')[0]?.removeEventListener('dragover', this.dragoverBottomPanel)
    return res // need to assign a variable and use it to be able to use the optional chaining operator (?)
  },
  watch: {
    bpmnScrollLeft (newVal) {
      // receive scroll event from bpmn component
      document.getElementsByClassName('v-table__wrapper')[0].scrollLeft = newVal
    },
    rowsData: {
      handler (newRowsData, oldRowsData) {
        this.rows = JSON.parse(JSON.stringify(this.rowsData.rows))
      },
      deep: true
    }
  }

}
</script>

<style scoped>
.v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
  box-shadow: none;
}

.dragover-to-add-release-top {
  border: 2px dashed gray;
}

pre {
  font-family: "Roboto";
  font-size: inherit;
  font-weight: inherit;
  display: inherit;
  padding: inherit;
  padding-left: 0;
  vertical-align: inherit;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-break: break-word; /* Internet Explorer 5.5+ */
}

pre[contenteditable="true"]:focus {
  outline: none;
}

pre:focus {
  outline: none;
}

[contenteditable="true"] br {
  display: none;
}

.btn {
  /* sets the background for the base class */
  background: rgb(var(--red), var(--green), var(--blue));

  /* calculates perceived lightness using the sRGB Luma method
    Luma = (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255 */
  --r: calc(var(--red) * 0.2126);
  --g: calc(var(--green) * 0.7152);
  --b: calc(var(--blue) * 0.0722);
  --sum: calc(var(--r) + var(--g) + var(--b));
  --perceived-lightness: calc(var(--sum) / 255);

  /* shows either white or black color depending on perceived darkness */
  color: hsl(
    0,
    0%,
    calc((var(--perceived-lightness) - var(--threshold)) * -10000000%)
  );
}

td.description-column {
  border-left: thin solid rgba(0, 0, 0, 0.12);
  padding-left: 8px !important;
  padding-right: 8px !important;
  min-width: 160px;
  max-width: 160px;
  width: 160px;
  vertical-align: top;
  z-index: 0;
}

.v-tooltip__content {
  background: white;
  color: var(--color-primary);
  border-radius: 10px;
}

.v-tooltip__content code {
  background: var(--color-primary);
  color: white;
}

.sortable-chosen {
  background: #eee;
}

.white-background-on-hover:hover {
  background: #fff !important;
}

.v-data-table > .v-table__wrapper > table {
  width: 120px;
}

.draggable {
  cursor: move;
}

.draggable.dragging {
  opacity: 0.5;
}

html {
  scroll-behavior: smooth;
}

td.pre-column {
  border-bottom: 0px !important;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  min-height: 120px;
  height: 120px !important;
}
</style>
