<template>
  <v-list-item
    v-if="isLeafItem"
    class="file-tree-item"
    prepend-icon="mdi-file"
    :title="displayName || name"
    :value="filepath"
  >
  <template v-slot:append>
    <v-icon ref="loader" class="continuous-rotation" :style="!object?.isLoading ? 'opacity: 0;' : ''">mdi-loading</v-icon>
    <v-tooltip v-if="object?.isDirty && !object?.isLoading" location="top" open-delay="1000">
      <template v-slot:activator="{ props }">
        <v-icon class="small" v-bind="props">mdi-circle</v-icon>
      </template>
      <span>Unsaved changes click Ctrl/ Command-S to save</span>
    </v-tooltip>
  </template>
  </v-list-item>

  <v-list-group
    v-else
    class="file-tree-group"
    expand-icon=""
    collapse-icon=""
    :value="filepath">
    <template v-slot:activator="{ props }">
      <v-list-item
        class="file-tree-item file-tree-group"
        :prepend-icon="thisOpened ? 'mdi-chevron-down' : 'mdi-chevron-right'"
        v-bind="props"
        :title="name"
      ></v-list-item>
    </template>

    <file-list-item
      v-for="[childName, grandChildren] of childEntries"
      :key="childName"
      :path="filepath + '/'"
      :name="childName"
      :children="grandChildren"
      :objects="objects"
      :opened="opened"
    />
  </v-list-group>
</template>

<script>
const referenceTime = new Date()

export default {
  name: 'FileListItem',
  props: {
    path: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    displayName: {
      type: String,
      required: false
    },
    children: {
      type: Object,
      required: false
    },
    objects: {
      type: Object,
      required: false
    },
    opened: {
      type: Array,
      default: () => []
    }
  },

  mounted () {
    if (this.$refs.loader) {
      const now = new Date()
      const elapsedTime = (now - referenceTime) / 1000
      this.$refs.loader.$el.style.animationDelay = `-${elapsedTime}s`
    }
  },

  computed: {
    isLeafItem () {
      if (!this.children) return true
      return Object.keys(this.children).length === 0
    },
    childEntries () {
      const newChildren = Object.entries({ ...this.children })
      newChildren.sort((a, b) => !!Object.keys(b[1]).length - !!Object.keys(a[1]).length)
      return newChildren
    },
    filepath () {
      return this.path + this.name
    },
    object () {
      const object = this.objects && this.objects[this.filepath]
      return object
    },
    thisOpened () {
      if (!this.isLeafItem) {
        return this.opened.findIndex(it => it === this.filepath) >= 0
      }
      return false
    }
  }
}
</script>

<style scoped>

v-list {
  --prepend-width: 0px;
}

</style>

<style>

.file-tree-group {
  --prepend-width: 0px !important;
  /* padding-inline-start: var(--indent-padding) !important; */
}

.v-list > .file-tree-group {
  --prepend-width: -16px !important;
}

.file-tree-item {
  padding: 0 4px !important;
  height: 24px !important;
  min-height: 24px !important;
  font-size: 12px !important;
  padding-inline-start: var(--indent-padding) !important;
  /* padding-inline-start: 16px !important; */
  /* --indent-padding: calc(8px + var(--indent-padding)); */
  /* padding-inline-start: calc(2px + var(--indent-padding)) !important; */
}

.file-tree-item .v-icon {
  font-size: 20px;
  padding-left: 16px;
}

.file-tree-item .v-icon.small {
  padding-left: 0px;
  font-size: 10px;
}

.file-tree-item .v-list-item__prepend {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 24px;
}

.file-tree-item .v-list-item__append {
  width: 28px;
  min-width: 28px;
  max-width: 28px;
}
</style>

<style scoped>

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.continuous-rotation {
  padding-left: 0px;
  font-size: 18px;
  animation: rotation 0.5s infinite linear;
}
</style>
