import gql from 'graphql-tag'
import { WORKFLOW_FIELDS } from '../fragments'

export default gql`
  ${WORKFLOW_FIELDS}
  mutation restorePreviousWorkflowVersion($projectId: String!, $input: RestorePreviousWorkflowVersionInput!) {
    restorePreviousWorkflowVersion(projectId: $projectId, input: $input) {
      ...WorkflowFields
    }
  }
`
