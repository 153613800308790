import { chatGPT } from '@/api/chatGPT'

export class ChatLog {
  constructor () {
    this.messages = []
    this.isStreaming = false
  }

  push (role, content) {
    this.messages.push({ role, content })
  }

  pop () {
    this.messages.pop()
  }

  pushSystem (content) {
    this.push('system', content)
  }

  pushUser (content) {
    this.push('user', content)
  }

  pushAssistant (content) {
    this.push('assistant', content)
  }

  async fetchJsonResponse (aiVersion) {
    return await chatGPT.fetchChatCompletion(this.messages, aiVersion)
  }

  async streamJsonResponses (actionFunction, aiVersion) {
    this.isStreaming = true
    const responses = []
    const reader = await chatGPT.fetchChatCompletion(this.messages, aiVersion, true)
    const decoder = new TextDecoder()
    while (true) {
      if (!reader) break
      const { value, done } = await reader.read()
      if (done || !this.isStreaming) break
      const str = decoder.decode(value, { stream: true })
      const stringArray = str.split('jsonobjectdelimiter')
      stringArray.pop() // last element is empty
      for await (const strObj of stringArray) {
        const obj = JSON.parse(strObj)
        responses.push(obj)
        await actionFunction(obj)
      }
    }
    this.isStreaming = false

    return responses
  }

  async streamTextResponse (actionFunction, aiVersion) {
    this.isStreaming = true
    const reader = await chatGPT.fetchChatCompletion(this.messages, aiVersion, true, true, true)
    const decoder = new TextDecoder()
    while (true) {
      if (!reader) break
      const { value, done } = await reader.read()
      if (done || !this.isStreaming) break
      const str = decoder.decode(value, { stream: true })
      await actionFunction(str)
    }
    this.isStreaming = false
  }

  stopStreaming () {
    this.isStreaming = false
  }

  debugLogMessages () {
    for (const msg of this.messages) {
      console.log('>', msg.role)
      console.log(msg.content)
    }
  }
}
