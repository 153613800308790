export default {
  getSlotXFromPoint (x) {
    return Math.floor(x / 160)
  },
  getSlotYFromPoint (y) {
    return Math.floor((y + 40) / 140)
  },
  getSlotXFromId (nodeId, svgGraph) {
    if (nodeId === 'start') {
      return 0
    }
    return this.getSlotXFromPoint(svgGraph[nodeId].x)
  },
  getSlotYFromId (nodeId, svgGraph) {
    return this.getSlotYFromPoint(svgGraph[nodeId].y)
  },
  getSlotsFromNode (node, svgGraph) {
    const res = {}
    res.slotX = this.getSlotXFromId(node.id, svgGraph)
    res.slotY = this.getSlotYFromId(node.id, svgGraph)
    return res
  },
  getYPointFromOffset (offset, laneId, svgLanes) {
    return svgLanes[laneId].y + 30 + 140 * offset
  },
  getGroupFromSlotX (eventSlotX, svgGroups) {
    const groups = Object.values(svgGroups || {})
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i]
      if (eventSlotX >= group.startSlotX && (!groups[i + 1] || eventSlotX < groups[i + 1].startSlotX)) {
        return group
      }
    }
  },
  getGroupFromXCoordinate (x, svgGroups) {
    const slotX = this.getSlotXFromPoint(x)
    return this.getGroupFromSlotX(slotX, svgGroups)
  },
  findGroupStartingAtCoordinate (x, svgGroups) {
    const slotX = this.getSlotXFromPoint(x)
    const groupArray = Object.values(svgGroups || {})
    const group = groupArray.find(group => group.startSlotX === slotX)
    return group
  },
  getXPointFromSlot (slot) {
    return 80 + 160 * slot
  },
  getParentX (node, parent, svgGroups) {
    if (node.groupId && svgGroups[node.groupId]) {
      const group = svgGroups[node.groupId]
      const x = this.getXPointFromSlot(group.startSlotX)
      return x - 160
    } else {
      return parent.x
    }
  },
  getGroupXPointFromSlot (slot) {
    if (slot === 0) return 0
    return this.getXPointFromSlot(slot) - 30
  }
}
