import { WORKFLOW_FIELDS } from '../fragments'

export default `
  ${WORKFLOW_FIELDS.loc.source.body}
  mutation updateWorkflow($projectId: String!, $input: UpdateWorkflowInput!) {
    updateWorkflow(projectId: $projectId, input: $input) {
      ...WorkflowFields
    }
  }
`
