<template>
  <div
    v-if="event"
    v-on:keydown.stop
    v-on:cut.stop
    v-on:copy.stop
    v-on:paste.stop
    style="position: relative;"
    class="d-flex flex-column"
  >
    <!-- create new event story -->
    <v-dialog v-model="dialogDataModel1" max-width="500px" :disabled="alreadyAnEventStory || $store.state.isWorkflowDisabled">
      <template v-slot:activator="{ props }">
        <div v-bind="props" style="top: -35px !important; right: 360px !important; position: absolute;"
          :class="`${ alreadyAnEventStory ? 'bg-grey-lighten-4' : 'bg-yellow-lighten-4' }`"
          class="mb-2  px-5 mr-1 main-label hover-pointer">
          <v-col cols="8" style="display: flex;" class="font-weight-bold pa-0">
            <div :class="`${ alreadyAnEventStory ? 'text-grey' : '' }`" class="pa-0 mr-1 d-flex" style="white-space: nowrap;">
              Event Story<v-icon :color="alreadyAnEventStory ? '#AAA' : '#444'">mdi-plus</v-icon>
            </div>
          </v-col>
        </div>
      </template>

      <v-card>
        <v-row class="fluid justify-space-between align-top ma-2">
            <v-card-title>
              <span class="text-h5"><span class="bg-yellow-lighten-4 rounded-lg px-1">Create Event Story</span></span>
              <workflow-help-dialog-create-event-story />
            </v-card-title>
            <v-btn variant="text" icon color="grey" @click="dialogDataModel1 = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-row>
        <v-card-text>
          <v-container grid-list-md>
            <v-radio-group v-model="subTypeEventStory" class="ml-5 mt-0 pt-0">
              <v-radio label="Create Event Story with AI" value="ai" :disabled="alreadyAnEventStory">
                <template v-slot:label>
                  <div class="d-flex align-center">Add Event Story with AI&nbsp;<v-icon>mdi-creation</v-icon></div>
                </template>
              </v-radio>
              <v-radio label="Table with static example data" value="example" :disabled="alreadyAnEventStory" />
              <v-radio label="Empty table" value="empty" :disabled="alreadyAnEventStory" />
            </v-radio-group>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="text" @click="dialogDataModel1 = false">Cancel</v-btn>
          <v-btn data-test="add-event-story" color="primary" @click="addDataModel('Event Story')">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- create a fact table -->
    <v-dialog v-model="dialogDataModel2" max-width="500px" :disabled="alreadyAFactTable || $store.state.isWorkflowDisabled">
      <template v-slot:activator="{ props }">
        <div v-bind="props" style="top: -35px !important; right: 180px !important; position: absolute;"
          class="mb-2 mr-1 main-label hover-pointer px-5"
          :class="`${ alreadyAFactTable ? 'bg-grey-lighten-4' : 'bg-red-lighten-4' }`"
        >
          <v-col cols="8" style="display: flex;" class="subheading font-weight-bold pa-0">
            <div
              class="px-1 mr-1 d-flex pa-0"
              :class="`${ alreadyAFactTable ? 'text-grey' : '' } lighten-4`"
              style="white-space: nowrap;">
              Fact Table<v-icon :color="alreadyAFactTable ? '#AAA' : '#444'">mdi-plus</v-icon>
            </div>
          </v-col>
        </div>
      </template>

      <v-card>
        <v-row class="fluid justify-space-between align-top ma-2">
            <v-card-title class="align-center">
              <span class="text-h5"><span class="bg-red-lighten-4 rounded-lg px-1">Create Fact Table</span></span>
              <workflow-help-dialog-create-fact-table>
              </workflow-help-dialog-create-fact-table>
            </v-card-title>
            <v-btn variant="text" icon color="grey" @click="dialogDataModel2 = false">
              <v-icon >mdi-close</v-icon>
            </v-btn>
        </v-row>
        <v-card-text>
          <v-container grid-list-md>
            <v-radio-group v-model="subTypeFactTable" class="ml-5 mt-0 pt-0" :disabled="alreadyAFactTable">
              <v-radio label="From Event Story with AI" value="ai-event-story" :disabled="!alreadyAnEventStory">
                <template v-slot:label>
                  <div class="d-flex align-center">From Event Story with AI&nbsp;<v-icon>mdi-creation</v-icon></div>
                </template>
              </v-radio>
              <v-radio label="From Workflow with AI" value="ai-workflow">
                <template v-slot:label>
                  <div class="d-flex align-center">From Workflow with AI&nbsp;<v-icon>mdi-creation</v-icon></div>
                </template>
              </v-radio>
              <v-radio label="Copy Event Story" value="copy-event-story" :disabled="!alreadyAnEventStory"/>
              <v-radio label="Static example data" value="example" />
              <v-radio label="Empty table" value="empty" />
            </v-radio-group>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="text" @click="dialogDataModel2 = false">Cancel</v-btn>
          <v-btn color="primary" data-test="add-fact-table" @click="addDataModel('Fact Table')">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- create new dimension -->
    <v-dialog v-model="dialogDataModel3" max-width="500px" :disabled="$store.state.isWorkflowDisabled">
      <template v-slot:activator="{ props }">
        <div v-bind="props" style="top: -35px !important; right: 0 !important; position: absolute;"
          class="mb-2 bg-green-lighten-4 px-5 mr-1 main-label hover-pointer">
          <v-col cols="8" style="display: flex;" class="subheading font-weight-bold pa-0">
            <div class="mr-1 bg-green-lighten-4 d-flex pa-0" style="white-space: nowrap;">
              Dimension<v-icon color="#444">mdi-plus</v-icon>
            </div>
          </v-col>
        </div>
      </template>

      <v-card>
        <v-row class="fluid justify-space-between align-top ma-2">
            <v-card-title>
              <span class="text-h5"><span class="bg-green-lighten-4 rounded-lg px-1">Create Dimension</span></span>
              <workflow-help-dialog-create-dimension />
            </v-card-title>
            <v-btn variant="text" icon color="grey" @click="dialogDataModel3 = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-row>
        <v-card-text>
          <v-container grid-list-md>
            <v-radio-group v-model="subTypeDimension">
              <!-- radio button three -->
              <v-radio label="Fact Table + Dimensions from Event Story with AI" value="fact-dimensions-ai" :disabled="alreadyAFactTable || !alreadyAnEventStory">
                <template v-slot:label>
                  <div class="d-flex align-center">Fact Table + Dimensions from Event Story with AI&nbsp;<v-icon>mdi-creation</v-icon></div>
                </template>
              </v-radio>
              <v-radio label="Dimensions from Fact Table with AI" value="dimensions-ai" :disabled="!alreadyAFactTable">
                <template v-slot:label>
                  <div class="d-flex align-center">Dimensions from Fact Table with AI&nbsp;<v-icon>mdi-creation</v-icon></div>
                </template>
              </v-radio>
              <v-radio label="Static example data" value="example" data-test="dimension-static-example"/>
              <v-radio label="Empty table" value="empty" data-test="dimension-empty-example" />
            </v-radio-group>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="text" @click="dialogDataModel3 = false">Cancel</v-btn>
          <v-btn color="primary" data-test="add-dimension" @click="addDataModel('Dimension')">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card v-if="!event.dataModels || event.dataModels.length == 0" flat color="white" class="mb-2 elevation-2 rounded-lg px-0 pb-2">
      <v-col cols="12" style="align-items: center; display: flex;" class="subheading font-weight-bold">No&nbsp;<span class="bg-yellow-lighten-4 rounded-lg px-1">
        Event Story</span>&nbsp;or&nbsp;<span class="bg-red-lighten-4 rounded-lg px-1">Fact Table</span>&nbsp;created yet.
        <workflow-help-dialog-beam #default="{ on }">
          <v-icon v-on="{ ...on }" size="small" class="ml-2">mdi-help-circle-outline</v-icon>
        </workflow-help-dialog-beam>
      </v-col>
      <v-col cols="12">
      Click on <span class="bg-yellow-lighten-4 rounded-lg px-1">Event Story</span> to create a data model.
      </v-col>
    </v-card>

    <!-- ******************* -->
    <!-- ** event stories ** -->
    <!-- ******************* -->
    <template v-for="(dataModel, indexDataModel) in allDataModels">
      <beam-table
        v-if="dataModel.type == 'Event Story'"
        :key="'bce-' + indexDataModel"
        :data-model="dataModel"
        :index-data-model="indexDataModel"
        :dimensions="dimensions"
        :eventId="event.id"
        :eventDescription="event?.description"
        :laneName="svgLanes[event.laneId]?.name"
        @blur-data-model-name="blurDataModelName($event, indexDataModel)"
        @delete-data-model="deleteDataModel($event, indexDataModel)" @blur-table-cell="blurTableCell"
        @click-type="clickType" @add-column="addColumn" @delete-data-field="deleteDataField" @move-left="moveLeft"
        @click-tags="clickTags"
        @move-right="moveRight"
        :rows="[
            {
              type: 'verb',
              fieldName: 'verb',
              rowLabel: 'Story line:'
            },
            {
              type: 'header-after-verb',
              fieldName: 'name'
            },
            {
              type: 'select',
              fieldName: 'type',
              rowLabel: 'Type'
            },
            {
              type: 'text',
              fieldName: 'description',
              rowLabel: 'Description'
            },
            {
              type: 'checkbox',
              fieldName: 'tags',
              rowLabel: 'Tags'
            },
            {
              type: 'example-data',
              fieldName: 'exampleData'
            }]" />
    </template>

    <template v-if="alreadyAFactTable || starDimensionsLeft.length > 0">
      <v-card class="table-container beam-table-container mb-5">
        <v-toolbar flat color="white">
          <v-col cols="8" style="display: flex;" class="subheading font-weight-bold">
            <div class="main-label px-1 mr-1 d-flex align-center">
              <v-icon color="primary">mdi-star</v-icon>&nbsp;
              Associated Entities / Star Schema
            </div>
            <workflow-help-dialog-star-schema />
          </v-col>
          <v-spacer></v-spacer>
        </v-toolbar>
        <!-- the columns are rendered in a different order than they are displayed in order
        for the lines to be hidden behind the fact table (middle column) -->
        <v-col fluid class="mb-0 elevation-1" style="position: relative; background-color: #f2f1f1;">
          <v-row class="d-flex">
            <v-col cols="12" sm="4" class="pa-3 justify-space-around between d-flex flex-column align-center"
              style="border: 0; order: 1;">
              <template :key="index" v-for="(dimension, index) in starDimensionsLeft">
                <ConnectingLine
                  :refreshKey="starSchemaRefreshKey"
                  endElementId="start-element"
                  :startElementId="`end-element-${index}`"
                  :spacingEndElement="20"
                />
                <v-card
                  :id="`end-element-${index}`" class="d-flex pa-2 flex-column align-start ma-5 bg-white" variant="outlined" tile.dataFields
                  max-width="200" :ref="`left-dimensions-${index}`">
                  <div class="bg-primary btc-monospace text-white px-2" style="min-width: 100%; border-radius: 0;">{{
                      dimension.dimensionName }}</div>
                  <div v-for="(field, index2) in dimension" :key="index2" class="btc-monospace">
                    {{ field.name }}
                  </div>
                </v-card>
              </template>
            </v-col>
            <v-col cols="12" sm="4" class="pa-3 justify-space-around between d-flex flex-column align-center"
              style="border: 0; order: 3;">
              <template v-for="(dimension, index) in starDimensionsRight" :key="index">
                <v-card :id="`end-element-right-${index}`" class="d-flex pa-2 flex-column align-start ma-5 bg-white" variant="outlined" tile.dataFields
                  max-width="200" :ref="`left-dimensions-${index}`">
                  <div class="bg-primary btc-monospace text-white px-2" style="min-width: 100%; border-radius: 0;">{{
                    dimension.dimensionName }}</div>
                  <div v-for="(field, index2) in dimension" :key="index2" class="btc-monospace">
                    {{ field.name }}
                  </div>
                </v-card>
                <ConnectingLine
                  :refreshKey="starSchemaRefreshKey"
                  startElementId="start-element"
                  :endElementId="`end-element-right-${index}`"
                  :spacingStartElement="20"
                />
              </template>
            </v-col>
            <v-col cols="12" sm="4" class="pa-3 justify-center d-flex flex-column align-center"
              style="border: 0; order: 2;">
              <v-card class="d-flex pa-2 flex-column align-start ma-5 bg-white" id="start-element" variant="outlined" rounded="0" max-width="200" ref="fact">
                <div class="bg-primary btc-monospace text-white px-2" style="min-width: 100%; border-radius: 0;">
                  {{ getFactTable.name }}
                </div>
                <template v-if="alreadyAFactTable">
                  <div v-for="(fact, index) in factFields" :key="index" class="btc-monospace">
                    {{ fact.entity }}
                  </div>
                </template>
                <template v-else>
                  <div class="btc-monospace">
                    No fact table created
                  </div>
                </template>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </template>

    <!-- *********** -->
    <!-- ** facts ** -->
    <!-- *********** -->
    <template v-for="(dataModel, indexDataModel) in allDataModels">
      <beam-table
        v-if="dataModel.type == 'Fact Table'"
        :key="'bcf-' + indexDataModel"
        :data-model="dataModel"
        :index-data-model="indexDataModel"
        :dimensions="dimensions"
        :eventId="event.id"
        :eventDescription="event?.description"
        :laneName="svgLanes[event.laneId]?.name"
        @blur-data-model-name="blurDataModelName($event, indexDataModel)"
        @delete-data-model="deleteDataModel($event, indexDataModel)"
        @blur-table-cell="blurTableCell"
        @click-type="clickType"
        @add-column="addColumn"
        @delete-data-field="deleteDataField"
        @move-left="moveLeft"
        @move-right="moveRight"
        :rows="[
            {
              type: 'header',
              fieldName: 'name',
              rowLabel: 'Entity'
            },
            {
              type: 'select',
              fieldName: 'type',
              rowLabel: 'Type'
            },
            {
              type: 'dimension-data',
              fieldName: 'systemName',
              rowLabel: 'Source System'
            },
            {
              type: 'dimension-data',
              fieldName: 'tableName',
              rowLabel: 'Table Name'
            },
            {
              type: 'dimension-data',
              fieldName: 'fieldName',
              rowLabel: 'Field Name'
            },
            {
              type: 'example-data',
              fieldName: 'exampleData'
            }]" />
      <!-- <br v-if="dataModel.type == 'Fact Table'" :key="'brf-' + indexDataModel" /> -->
    </template>

    <!-- **************** -->
    <!-- ** dimensions ** -->
    <!-- **************** -->
    <template v-for="(dataModel, indexDataModel) in allDataModels">
      <beam-table v-if="dataModel.type == 'Dimension'" :key="'bcd-' + indexDataModel" :data-model="dataModel"
        :index-data-model="indexDataModel" :dimensions="dimensions" :eventId="event.id"
        @blur-data-model-name="blurDataModelName($event, indexDataModel, true)"
        @delete-data-model="deleteDataModelDimension($event, indexDataModel)" @blur-table-cell="blurTableCellDimension"
        @click-type="clickTypeDimension" @add-column="addColumnDimension" @delete-data-field="deleteDataFieldDimension"
        @move-left="moveLeftDimension" @move-right="moveRightDimension"
        @remove-connected-to-events="removeConnectedToEvents($event, indexDataModel)"
        @add-connected-to-events="addConnectedToEvents($event, indexDataModel)" :rows="[
            {
              type: 'header',
              fieldName: 'name',
              rowLabel: 'Entity'
            },
            {
              type: 'select',
              fieldName: 'type',
              rowLabel: 'Type'
            },
            {
              type: 'example-data',
              fieldName: 'exampleData'
            }]" />
    </template>
  </div>
</template>

<script>
import BeamTable from '@/components/BeamTable'
import workflowApi from '@/api/workflowApi'
import { chatGPT } from '@/api/chatGPT/index'
import ConnectingLine from './ConnectingLine.vue'
import WorkflowHelpDialogBeam from '@/components/WorkflowHelpDialogBeam'
import WorkflowHelpDialogStarSchema from '@/components/WorkflowHelpDialogStarSchema'
import WorkflowHelpDialogCreateEventStory from '@/components/WorkflowHelpDialogCreateEventStory'
import WorkflowHelpDialogCreateFactTable from '@/components/WorkflowHelpDialogCreateFactTable'
import WorkflowHelpDialogCreateDimension from '@/components/WorkflowHelpDialogCreateDimension'
import { mapState } from 'vuex'

export default {
  components: {
    BeamTable,
    ConnectingLine,
    WorkflowHelpDialogBeam,
    WorkflowHelpDialogStarSchema,
    WorkflowHelpDialogCreateEventStory,
    WorkflowHelpDialogCreateFactTable,
    WorkflowHelpDialogCreateDimension
  },
  props: [
    'event',
    'generatorFactTableFunc',
    'generatorEventStoryFunc'
  ],
  data () {
    return {
      testing: false,
      isMounted: false,
      valid: false,
      dialogReq: false,
      starSchemaRefreshKey: 0,
      search: '',
      sourceMenu: {},
      menu: {},
      defaultSort: {
        sortBy: 'row',
        totalItems: 10000,
        rowsPerPage: 10000
      },
      select: null,
      types: [
        '[Who]',
        '[What]',
        '[When]',
        '[Where]',
        '[How]',
        '[How many]'
      ],
      typesDimension: [
        '[BK]',
        '[MD]',
        '[BK, MD]'
      ],
      typesFact: [
        '[SK]',
        '[GD]',
        '[DD]'
      ],
      dimensionsHeaders: [
        { text: 'Entity', value: 'entity' },
        { text: 'Key', value: 'keyType' },
        { text: 'Source System', value: 'sourceSystem' },
        { text: 'Table name', value: 'table' },
        { text: 'Type', value: 'type' }
      ],
      dialogDataModel1: false,
      dialogDataModel2: false,
      dialogDataModel3: false,
      typeGroup: 'Event Story',
      subTypeEventStory: null,
      subTypeFactTable: null,
      subTypeDimension: null,
      eventStoryExampleData: [{
        name: 'Customer',
        type: '[Who]',
        description: 'Example data for a customer',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: ['John Doe', 'Jane Smith']
      }, {
        verb: 'orders',
        name: 'Product',
        type: '[What]',
        description: 'Example data for a product',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: ['MacBook Air', 'Huawei Pro']
      }, {
        verb: 'on',
        name: 'Order Date',
        type: '[When]',
        description: 'Example data for a valid date',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: ['18-May-2011', '29-Jun-2011']
      },
      {
        verb: 'in',
        name: 'Quantity',
        type: '[How many]',
        description: 'Example data for quantity',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: [2, 1]
      }],
      factTableExampleData: [{
        name: 'Customer ID',
        type: '[Who]',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: ['00001', '50230']
      }, {
        name: 'Product ID',
        type: '[What]',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: ['40016', '40680']
      }, {
        name: 'Order Date ID',
        type: '[When]',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: ['2011-05-18', '2011-06-29']
      },
      {
        name: 'Delivery Due Date ID',
        type: '[When]',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: ['20110603', '20110717']
      }],
      dimensionExampleData: [{
        name: 'Product ID',
        type: '',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: ['40016', '40680']
      }, {
        name: 'Product Description',
        type: '',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: ['Macbook Air', 'Huawei Pro']
      }, {
        name: 'Product Type',
        type: '',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: ['Laptop', 'Tower']
      },
      {
        name: 'Brand',
        type: '',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: ['Apple', 'Huawei']
      },
      {
        name: 'Subcategory',
        type: '',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: ['Mobile', 'Desktop']
      }]
    }
  },
  computed: {
    ...mapState({ workflow: state => state.workflow.workflow }),
    ...mapState(['svgGraph']),
    ...mapState(['svgLanes']),
    alreadyAnEventStory () {
      return this.event.dataModels?.filter(model => model.type === 'Event Story').length > 0
    },
    alreadyAFactTable () {
      return this.event.dataModels?.filter(model => model.type === 'Fact Table').length > 0
    },
    dimensions () {
      return this.workflow.dataModels || []
    },
    getFactTable () {
      const index = this.event.dataModels?.findIndex(model => model.type === 'Fact Table')
      if (index > -1) {
        return this.event.dataModels[index]
      } else {
        return false
      }
    },
    starDimensionsLeft () {
      const res = []
      let index = 0
      if (this.workflow.dataModels) {
        for (const dataModel of this.workflow.dataModels) {
          if (dataModel.type === 'Dimension' && dataModel.connectedToEvents?.includes(this.event.id)) {
            if (index % 2 === 0) {
              res.push({ ...dataModel.dataFields, dimensionName: dataModel.name })
            }
            index++
          }
        }
      }
      return res
    },
    starDimensionsRight () {
      const res = []
      let index = 0
      if (this.workflow.dataModels) {
        for (const dataModel of this.workflow.dataModels) {
          if (dataModel.type === 'Dimension' && dataModel.connectedToEvents?.includes(this.event.id)) {
            if (index % 2 !== 0) {
              res.push({ ...dataModel.dataFields, dimensionName: dataModel.name })
            }
            index++
          }
        }
      }
      return res
    },
    factFields () {
      const res = []
      if (this.event.dataModels) {
        for (const dataModel of this.event.dataModels) {
          if (dataModel.type === 'Fact Table') {
            for (const field of dataModel.dataFields) {
              res.push({
                entity: field.name,
                keyType: field.type,
                type: 'fact',
                sourceSystem: field.systemName,
                table: field.tableName
              })
            }
          }
        }
      }
      return res
    },
    allDataModels () {
      const eventModels = this.event.dataModels || []
      const workflowModels = this.workflow.dataModels || []
      return [...eventModels, ...workflowModels]
    }
  },
  methods: {
    findFirstRelativeParent (el) {
      while (el && el.parentElement) {
        el = el.parentElement
        const position = getComputedStyle(el).position
        if (position === 'relative' || position === 'absolute' || position === 'fixed') {
          return el
        }
      }
      return null // No relative parent found
    },
    getRelativeParentCoordinates (el) {
      const relativeParent = this.findFirstRelativeParent(el)
      if (relativeParent) {
        const rect = relativeParent.getBoundingClientRect()
        return {
          x: rect.left + window.scrollX,
          y: rect.top + window.scrollY
        }
      }
      return null // No relative parent found
    },
    winScrollY () {
      return window.scrollY
    },
    getFactLeft () {
      const offsets = this.$refs.fact?.$el?.getBoundingClientRect()
      const res = offsets
      const coordinates = this.getRelativeParentCoordinates(this.$refs.dimension2?.[0]?.$el)
      if (coordinates) {
        offsets.y = offsets.y - coordinates.y
        offsets.x = offsets.x - coordinates.x
      }
      return res
    },
    getDimensions (workflow) {
      const res = []
      if (workflow.eventsJson) {
        for (const event of workflow.eventsJson) {
          if (event.dataModels) {
            for (const dataModel of event.dataModels) {
              if (dataModel.type === 'Dimension') {
                res.push(dataModel)
              }
            }
          }
        }
      }
      return res
    },
    idTable (indexDataModel, row, column) {
      return (indexDataModel + 1) * 10000 + row * 100 + column
    },
    blurTableCell (event, indexDataModel, indexDataField, fieldName, indexAttribute = -1, eventId) {
      let oldVal
      if (indexAttribute === null) { indexAttribute = -1 }
      const ev = this.workflow.eventsJson.find(event => event.id === eventId)
      if (indexAttribute >= 0) {
        oldVal = ev.dataModels[indexDataModel].dataFields[indexDataField][fieldName][indexAttribute]
      } else {
        oldVal = ev.dataModels[indexDataModel].dataFields[indexDataField][fieldName]
      }
      oldVal = oldVal === undefined ? '' : oldVal // // if undefined, set to empty string to avoid unecceary save
      if (oldVal !== event.target.innerText) {
        const dataModels = JSON.parse(JSON.stringify(ev.dataModels))
        if (indexAttribute >= 0) {
          dataModels[indexDataModel].dataFields[indexDataField][fieldName][indexAttribute] = event.target.innerText
        } else {
          dataModels[indexDataModel].dataFields[indexDataField][fieldName] = event.target.innerText
        }
        workflowApi.updateDataModels({
          eventId: ev.id,
          dataModelsOnEvent: dataModels,
          workflow: this.workflow
        })
      }
    },
    blurTableCellDimension (event, indexDataModel, indexDataField, fieldName, indexAttribute = -1, eventId) {
      let oldVal
      const ev = this.workflow.eventsJson.find(event => event.id === eventId)
      indexDataModel = indexDataModel - (ev.dataModels?.length || 0)
      if (indexAttribute !== null && indexAttribute >= 0) {
        oldVal = this.workflow.dataModels[indexDataModel].dataFields[indexDataField][fieldName][indexAttribute]
      } else {
        oldVal = this.workflow.dataModels[indexDataModel].dataFields[indexDataField][fieldName]
      }
      if (oldVal === undefined && event.target.innerText === '') {
        return
      }
      const newDataModels = JSON.parse(JSON.stringify(this.workflow.dataModels))
      if (oldVal !== event.target.innerText) {
        if (indexAttribute !== null && indexAttribute >= 0) {
          newDataModels[indexDataModel].dataFields[indexDataField][fieldName][indexAttribute] = event.target.innerText
        } else {
          newDataModels[indexDataModel].dataFields[indexDataField][fieldName] = event.target.innerText
        }
        workflowApi.updateDataModels({
          eventId: ev.id,
          dataModelsOnWorkflow: newDataModels,
          workflow: this.workflow
        })
      }
    },
    clickDataSource (index1, index2, entityName) {
      const updatedDataModels = JSON.parse(JSON.stringify(this.event.dataModels))
      updatedDataModels.dataModels[index1].dataFields[index2].fieldName = entityName
      workflowApi.updateDataModels({
        eventId: this.$route.params.eventId,
        dataModelsOnEvent: updatedDataModels,
        workflow: this.workflow
      })
    },
    deleteDataField (index1, index2) {
      const del = confirm('Are you sure you want to delete this field?')
      if (del) {
        const updatedDataModels = JSON.parse(JSON.stringify(this.event.dataModels))
        updatedDataModels[index1].dataFields.splice(index2, 1)
        workflowApi.updateDataModels({
          eventId: this.$route.params.eventId,
          dataModelsOnEvent: updatedDataModels,
          workflow: this.workflow
        })
      }
    },
    async deleteDataFieldDimension (index1, index2) {
      index1 = index1 - (this.event.dataModels?.length || 0)
      const del = confirm('Are you sure you want to delete this field?')
      if (del) {
        const newDataModels = JSON.parse(JSON.stringify(this.workflow.dataModels))
        newDataModels[index1].dataFields.splice(index2, 1)

        await workflowApi.updateDataModels({
          eventId: this.event.id,
          dataModelsOnWorkflow: newDataModels,
          workflow: this.workflow
        })
        this.starSchemaRefreshKey++
      }
    },
    moveRight (index1, index2) {
      if (this.event.dataModels[index1].dataFields.length > (index2 + 1)) {
        const updatedDataModels = JSON.parse(JSON.stringify(this.event.dataModels))
        const field = updatedDataModels[index1].dataFields[index2]

        updatedDataModels[index1].dataFields[index2] = updatedDataModels[index1].dataFields[index2 + 1]
        updatedDataModels[index1].dataFields[index2 + 1] = field
        workflowApi.updateDataModels({
          eventId: this.$route.params.eventId,
          dataModelsOnEvent: updatedDataModels,
          workflow: this.workflow
        })
      }
    },
    moveRightDimension (index1, index2) {
      index1 = index1 - (this.event.dataModels?.length || 0)
      if (this.workflow.dataModels[index1].dataFields.length > (index2 + 1)) {
        const newDataModels = JSON.parse(JSON.stringify(this.workflow.dataModels))
        const field = newDataModels[index1].dataFields[index2]

        newDataModels[index1].dataFields[index2] = newDataModels[index1].dataFields[index2 + 1]
        newDataModels[index1].dataFields[index2 + 1] = field
        workflowApi.updateDataModels({
          eventId: this.event.id,
          dataModelsOnWorkflow: newDataModels,
          workflow: this.workflow
        })
      }
    },
    moveLeft (index1, index2) {
      if (index2 >= 1) {
        const updatedDataModels = JSON.parse(JSON.stringify(this.event.dataModels))
        const field = updatedDataModels[index1].dataFields[index2]

        updatedDataModels[index1].dataFields[index2] = updatedDataModels[index1].dataFields[index2 - 1]
        updatedDataModels[index1].dataFields[index2 - 1] = field

        workflowApi.updateDataModels({
          eventId: this.$route.params.eventId,
          dataModelsOnEvent: updatedDataModels,
          workflow: this.workflow
        })
      }
    },
    moveLeftDimension (index1, index2) {
      index1 = index1 - (this.event.dataModels?.length || 0)
      if (index2 >= 1) {
        const newDataModels = JSON.parse(JSON.stringify(this.workflow.dataModels))
        const field = newDataModels[index1].dataFields[index2]
        newDataModels[index1].dataFields[index2] = newDataModels[index1].dataFields[index2 - 1]
        newDataModels[index1].dataFields[index2 - 1] = field

        workflowApi.updateDataModels({
          eventId: this.event.id,
          dataModelsOnWorkflow: newDataModels,
          workflow: this.workflow
        })
      }
    },
    deleteDataModel (index) {
      const del = confirm('Are you sure you want to delete this data model?')
      if (del) {
        const updatedDataModels = JSON.parse(JSON.stringify(this.event.dataModels))

        updatedDataModels.splice(index, 1)
        workflowApi.updateDataModels({
          eventId: this.$route.params.eventId,
          dataModelsOnEvent: updatedDataModels,
          workflow: this.workflow
        })
      }
    },
    async deleteDataModelDimension (index) {
      index = index - (this.event.dataModels?.length || 0)
      const del = confirm('Are you sure you want to delete this data model?')
      if (del) {
        const newDataModels = JSON.parse(JSON.stringify(this.workflow.dataModels))
        newDataModels.splice(index, 1)

        await workflowApi.updateDataModels({
          eventId: this.event.id,
          dataModelsOnWorkflow: newDataModels,
          workflow: this.workflow
        })

        this.starSchemaRefreshKey++
      }
    },
    createFactTableFromLLMResponse (name, typeGroup, factTableFieldsFromLLM, eventId) {
      const factDataFields = factTableFieldsFromLLM.map(field => {
        return {
          name: field.fieldName,
          type: `[${field.category}]`,
          fieldName: '',
          tableName: '',
          systemName: '',
          exampleData: field.exampleData
        }
      })
      const res = ({
        name,
        type: typeGroup,
        dataFields: factDataFields
      })
      return res
    },
    createDimensionTableFromLLMResponse (dimensionTableFromChat, eventId) {
      const dataFieldsDimension = dimensionTableFromChat.dataFields.map(field => {
        return {
          name: field.fieldName,
          type: `[${field.category}]`,
          fieldName: '',
          tableName: '',
          systemName: '',
          exampleData: field.exampleData
        }
      })
      const res = ({
        name: dimensionTableFromChat.tableName,
        type: 'Dimension',
        dataFields: dataFieldsDimension,
        connectedToEvents: [eventId]
      })
      return res
    },
    async addDataModel (typeGroup) {
      let dataModelsOnWorkflow
      let dataModelsOnEvent
      if (!this.event.dataModels) {
        this.$store.commit('resetSvgGraphDataModels', { eventId: this.$route.params.eventId })
      }
      switch (typeGroup) {
        case 'Event Story':
          if (this.subTypeEventStory === 'ai') {
            this.generatorEventStoryFunc?.(this.event.id)
          } else {
            const name = this.event.description + ' by ' + this.getLaneNameFromId(this.event.laneId)
            dataModelsOnEvent = [...(this.event.dataModels || []), {
              name,
              type: typeGroup,
              dataFields: this.subTypeEventStory === 'example' ? this.eventStoryExampleData : []
            }]
          }
          break
        case 'Dimension':
          if (this.subTypeDimension === 'fact-dimensions-ai') {
            if (!this.workflow.aiDescription) {
              this.$store.commit('snackbar/showMessage', { content: 'Please enter a description in settings: Get help from AI to build your workflow', timeout: 6000, color: 'red', centered: true }, { root: true })
              return
            }
            this.$loadingState.start()
            const res = await chatGPT.fetchFactTableAndDimensions(this.$route.params.eventId, this.svgGraph, this.svgLanes, this.workflow)
            this.$loadingState.stop()
            // add fact table
            const name = this.event.description + ' by ' + this.getLaneNameFromId(this.event.laneId)
            const typeGroup = 'Fact Table'
            const factTableFieldsFromLLM = res.factTable
            const factTable = this.createFactTableFromLLMResponse(name, typeGroup, factTableFieldsFromLLM)
            dataModelsOnEvent = [...(this.event.dataModels || []), factTable]
            // add dimension tables
            dataModelsOnWorkflow = [...(this.workflow.dataModels || [])]
            for (const dimensionTableFromLLM of res.dimensionTables) {
              const index = this.workflow.dataModels?.findIndex(model => model.name === dimensionTableFromLLM.tableName)
              if (index >= 0) {
                continue // skip if already exists
              }
              const dimensionTable = this.createDimensionTableFromLLMResponse(dimensionTableFromLLM, this.$route.params.eventId)
              dataModelsOnWorkflow = [...dataModelsOnWorkflow, dimensionTable]
            }
          } else if (this.subTypeDimension === 'dimensions-ai') {
            if (!this.workflow.aiDescription) {
              this.$store.commit('snackbar/showMessage', { content: 'Please enter a description in settings: Get help from AI to build your workflow', timeout: 6000, color: 'red', centered: true }, { root: true })
              return
            }
            this.$loadingState.start()
            const res = await chatGPT.fetchDimensions(this.$route.params.eventId, this.svgGraph, this.svgLanes, this.workflow)
            this.$loadingState.stop()
            // add dimension tables
            dataModelsOnWorkflow = [...(this.workflow.dataModels || [])]
            for (const dimensionTableFromLLM of res.dimensionTables) {
              const index = this.workflow.dataModels?.findIndex(model => model.name === dimensionTableFromLLM.tableName)
              if (index >= 0) {
                continue // skip if already exists
              }
              const dimensionTable = this.createDimensionTableFromLLMResponse(dimensionTableFromLLM, this.$route.params.eventId)
              dataModelsOnWorkflow = [...dataModelsOnWorkflow, dimensionTable]
            }
          } else if (this.subTypeDimension === 'example') {
            dataModelsOnWorkflow = [...(this.workflow.dataModels || []), {
              name: 'New Dimension ' + ((this.workflow.dataModels?.length || 0) + 1),
              type: 'Dimension',
              dataFields: this.dimensionExampleData,
              connectedToEvents: [this.$route.params.eventId]
            }]
          } else if (this.subTypeDimension === 'empty') {
            dataModelsOnWorkflow = [...(this.workflow.dataModels || []), {
              name: 'New Dimension ' + ((this.workflow.dataModels?.length || 0) + 1),
              type: 'Dimension',
              dataFields: [],
              connectedToEvents: [this.$route.params.eventId]
            }]
          }
          this.starSchemaRefreshKey++
          break
        case 'Fact Table': {
          let name = this.event.description + ' by ' + this.getLaneNameFromId(this.event.laneId)
          let dataFields
          if (this.subTypeFactTable === 'ai-workflow') {
            this.generatorFactTableFunc?.(this.event.id)
            break
          } else {
            if (this.subTypeFactTable === 'empty') {
              dataFields = []
            } else if (this.subTypeFactTable === 'example') {
              name = 'Example Fact Table'
              dataFields = this.factTableExampleData
            } else if (this.subTypeFactTable === 'copy-event-story') {
              const index = this.event.dataModels.findIndex(model => model.type === 'Event Story')
              const eventStoryDataField = this.event.dataModels[index].dataFields
              dataFields = eventStoryDataField
            } else if (this.subTypeFactTable === 'ai-event-story') {
              typeGroup = 'Fact Table'
              this.$loadingState.start()
              const res = await chatGPT.fetchFactTable2(this.$route.params.eventId, this.workflow, this.svgGraph, this.svgLanes)
              this.$loadingState.stop()
              dataFields = res.factTable.map(field => {
                return {
                  name: field.fieldName,
                  type: `[${field.category}]`,
                  fieldName: '',
                  tableName: '',
                  systemName: '',
                  exampleData: field.exampleData
                }
              })
            }
            const factTable = {
              name,
              type: 'Fact Table',
              dataFields
            }
            dataModelsOnEvent = [...(this.event.dataModels || []), factTable]
            break
          }
        }
      }
      if (dataModelsOnEvent || dataModelsOnWorkflow) {
        await workflowApi.updateDataModels({
          eventId: this.$route.params.eventId,
          dataModelsOnEvent,
          dataModelsOnWorkflow: dataModelsOnWorkflow?.length > 0 ? dataModelsOnWorkflow : undefined,
          workflow: this.workflow
        })
      }
      this.closeDialogs()
    },
    save (dataModelsWorkflow) {
      const dataModelsCloned = dataModelsWorkflow ? JSON.parse(JSON.stringify(dataModelsWorkflow)) : undefined
      this.dialogDataModel1 = false
      this.dialogDataModel2 = false
      this.dialogDataModel3 = false
      workflowApi.updateWorkflow({
        svgGraph: JSON.parse(JSON.stringify(this.svgGraph)),
        workflow: JSON.parse(JSON.stringify(this.workflow)),
        dataModels: dataModelsCloned,
        updateNote: {
          action: 'updated data models',
          target: ''
        }
      })
    },
    addColumn (index1, index2) {
      if (index2 === undefined) {
        index2 = this.event.dataModels[index1].dataFields.length
      }
      const updatedDataModels = JSON.parse(JSON.stringify(this.event.dataModels))

      updatedDataModels[index1].dataFields.splice(index2 + 1, 0, {
        verb: 'new verb',
        name: 'New Field ' + (this.event.dataModels[index1].dataFields.length + 1),
        type: '',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: ['']
      })
      workflowApi.updateDataModels({
        eventId: this.event.id,
        dataModelsOnEvent: updatedDataModels,
        workflow: this.workflow
      })
    },
    async addColumnDimension (index1) {
      index1 = index1 - (this.event.dataModels?.length || 0)
      const newDataModels = JSON.parse(JSON.stringify(this.workflow.dataModels))
      newDataModels[index1].dataFields.push({
        verb: 'new verb',
        name: 'New Field ' + (this.workflow.dataModels[index1].dataFields.length + 1),
        type: '',
        fieldName: '',
        tableName: '',
        systemName: '',
        exampleData: ['']
      })
      await workflowApi.updateDataModels({
        eventId: this.event.id,
        dataModelsOnWorkflow: newDataModels,
        workflow: this.workflow
      })
      this.starSchemaRefreshKey++
    },
    async blurDataModelName (event, index, dimension = false) {
      if (!dimension) {
        if (this.event.dataModels[index].name !== event.target.innerText) {
          const updatedDataModels = JSON.parse(JSON.stringify(this.event.dataModels))

          updatedDataModels[index].name = event.target.innerText
          workflowApi.updateDataModels({
            eventId: this.event.id,
            dataModelsOnEvent: updatedDataModels,
            workflow: this.workflow
          })
        }
      } else {
        const dataModelsWorkflow = JSON.parse(JSON.stringify(this.workflow.dataModels))
        index = index - (this.event.dataModels?.length || 0)
        if (dataModelsWorkflow[index].name !== event.target.innerText) {
          dataModelsWorkflow[index].name = event.target.innerText
          await workflowApi.updateDataModels({
            eventId: this.event.id,
            dataModelsOnWorkflow: dataModelsWorkflow,
            workflow: this.workflow
          })
          this.starSchemaRefreshKey++
        }
      }
    },
    async removeConnectedToEvents (eventId, index) {
      index = index - (this.event.dataModels?.length || 0)
      const dataModelsWorkflow = JSON.parse(JSON.stringify(this.workflow.dataModels))
      dataModelsWorkflow[index].connectedToEvents = dataModelsWorkflow[index].connectedToEvents.filter(el => {
        return (el !== eventId) && (this.svgGraph[el])
      })
      await workflowApi.updateDataModels({
        eventId: this.event.id,
        dataModelsOnWorkflow: dataModelsWorkflow,
        workflow: this.workflow
      })
      this.starSchemaRefreshKey++
    },
    async addConnectedToEvents (eventId, index) {
      index = index - (this.event.dataModels?.length || 0)
      const dataModelsWorkflow = JSON.parse(JSON.stringify(this.workflow.dataModels))
      if (dataModelsWorkflow[index].connectedToEvents) {
        dataModelsWorkflow[index].connectedToEvents = dataModelsWorkflow[index].connectedToEvents.filter(el => {
          return (el !== eventId) && (this.svgGraph[el])
        })
      } else {
        dataModelsWorkflow[index].connectedToEvents = []
      }
      dataModelsWorkflow[index].connectedToEvents.push(eventId)
      await workflowApi.updateDataModels({
        eventId: this.event.id,
        dataModelsOnWorkflow: dataModelsWorkflow,
        workflow: this.workflow
      })
      this.starSchemaRefreshKey++
    },
    clickType (event, index1, index2) {
      if (this.event.dataModels[index1].dataFields[index2].type !== event.target.innerText) {
        const updatedDataModels = JSON.parse(JSON.stringify(this.event.dataModels))

        updatedDataModels[index1].dataFields[index2].type = event.target.innerText
        workflowApi.updateDataModels({
          eventId: this.$route.params.eventId,
          dataModelsOnEvent: updatedDataModels,
          workflow: this.workflow
        })
      }
    },
    clickTypeDimension (event, index1, index2) {
      index1 = index1 - (this.event.dataModels?.length || 0)
      if (this.workflow.dataModels[index1].dataFields[index2].type !== event.target.innerText) {
        const newDataModels = JSON.parse(JSON.stringify(this.workflow.dataModels))
        newDataModels[index1].dataFields[index2].type = event.target.innerText
        workflowApi.updateDataModels({
          eventId: this.event.id,
          dataModelsOnWorkflow: newDataModels,
          workflow: this.workflow
        })
      }
    },
    clickTags (event, index1, index2) {
      const ev = this.workflow.eventsJson.find(ev => ev.id === this.$route.params.eventId)
      const dataModels = JSON.parse(JSON.stringify(ev.dataModels))
      dataModels[index1].dataFields[index2].tags = event
      workflowApi.updateDataModels({
        eventId: ev.id,
        dataModelsOnEvent: dataModels,
        workflow: this.workflow
      })
    },
    getLaneNameFromId (laneId) {
      let res = ''
      const index = this.workflow.lanes.findIndex((lane) => lane.id === laneId)
      if (index >= 0) {
        res = this.workflow.lanes[index].name
      }
      return res
    },
    closeDialogs () {
      this.dialogDataModel1 = false
      this.dialogDataModel2 = false
      this.dialogDataModel3 = false
    }
  },
  watch: {
    dialogDataModel1 (val) {
      if (val) {
        if (!this.alreadyAnEventStory) {
          this.subTypeEventStory = 'ai'
        } else {
          this.subTypeEventStory = null
        }
      }
    },
    dialogDataModel2 (val) {
      if (val) {
        if (this.alreadyAnEventStory) {
          this.subTypeFactTable = 'ai-event-story'
        } else {
          this.subTypeFactTable = 'ai-workflow'
        }
      }
    },
    dialogDataModel3 (val) {
      if (val) {
        if (this.alreadyAnEventStory && !this.alreadyAFactTable) {
          this.subTypeDimension = 'fact-dimensions-ai'
        } else if (this.alreadyAFactTable) {
          this.subTypeDimension = 'dimensions-ai'
        } else {
          this.subTypeDimension = 'example'
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.isMounted = true
    })
  }
}
</script>

<style scoped>
.add-data-model-button {
  height: 30px !important;
  border-radius: 10px !important;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.btc-monospace {
  font-family: monospace;
  font-size: 13px !important;
}
.main-label {
  color: #444;
  border-radius: 10px;
}
.hover-pointer {
  cursor: pointer;
}

</style>
