<template>
  <component
    :is="tag"
    ref="cell"
    v-bind:class="classes"
    :tabindex="tab"
    contenteditable="false"
    @blur="blurCell"
    @click="clickDescription"
    @keydown="keydownCell($event)"
    v-text="value"></component>
</template>

<script>
export default {
  name: 'beam-table-cell',

  props: {
    cell: Boolean,
    header: Boolean,
    monospace: Boolean,
    dimensionData: Boolean,
    tab: Number,
    tag: {
      type: String,
      default: 'td'
    },
    value: String
  },

  methods: {
    blurCell (event) {
      // Parent blur event will be triggered after this event, which will cause the update of the content
      event.target.setAttribute('contenteditable', 'false')
    },
    clickDescription (event) {
      if (this.$store.state.isWorkflowDisabled === false) {
        const contenteditable = event.target.getAttribute('contenteditable')
        if (contenteditable !== 'true') {
          event.target.setAttribute('contenteditable', 'true')
          event.target.focus()
        }
      }
    },
    isPrintableCharacter (keycode) {
      const printable =
        (keycode > 47 && keycode < 58) || // number keys
        keycode === 32 || keycode === 13 || // spacebar & return key(s) (if you want to allow carriage returns)
        (keycode > 64 && keycode < 91) || // letter keys
        (keycode > 95 && keycode < 112) || // numpad keys
        (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
        (keycode > 218 && keycode < 223) // [\]' (in order)
      return printable
    },
    keydownCell (event) {
      switch (event.keyCode) {
        case 13: // enter
          if (event.target.getAttribute('contenteditable') === 'true') {
            this.$emit('leave-cell', event)
          } else {
            event.target.setAttribute('contenteditable', 'true')
            event.target.focus()
            this.setEndOfContenteditable(event.target)
            event.preventDefault()
          }
          break
        case 27: // escape
          this.restoreValue(event, this.value)
          break
        case 37: // left
        case 38: // up
        case 39: // right
        case 40: // down
          if (event.target.getAttribute('contenteditable') !== 'true') {
            this.$emit('leave-cell', event)
          }
          break
        default:
          if (!this.$store.state.isWorkflowDisabled && event.target.getAttribute('contenteditable') !== 'true' && this.isPrintableCharacter(event.keyCode) && !event.altKey && !event.metaKey) {
            event.target.setAttribute('contenteditable', 'true')
            event.target.innerText = ''
            event.target.focus()
            this.setEndOfContenteditable(event.target)
          }
      }
      event.stopPropagation()
    },
    restoreValue (event, value) {
      if (event.target.isContentEditable) {
        event.target.innerText = value
        this.setEndOfContenteditable(event.target)
        // event.target._ignoreBlur = true
        // event.target.blur()
        // this.$nextTick(() => {
        //   event.target.focus()
        // })
      }
    },
    setEndOfContenteditable (contentEditableElement) {
      let range, selection
      if (document.createRange) { // Firefox, Chrome, Opera, Safari, IE 9+
        range = document.createRange() // Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(contentEditableElement) // Select the entire contents of the element with the range
        range.collapse(false) // collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection() // get the selection object (allows you to change selection)
        selection.removeAllRanges() // remove any selections already made
        selection.addRange(range) // make the range you have just created the visible selection
      }
    },
    setFocus () {
      this.$refs.cell.focus()
    }
  },
  computed: {
    classes () {
      return {
        'btc-monospace': this.monospace,
        'btc-cell': this.cell,
        'bg-orange-lighten-5': this.dimensionData,
        'btc-header-cell': this.header,
        'text-white': this.header
      }
    }
  }
}
</script>

<style>
div {
  outline: none !important;
}

.btc-monospace {
  font-family: monospace;
}

.btc-cell {
  font-size: 13px !important;
}

td.btc-cell.orange.lighten-5 {
  border-bottom: thin solid rgba(0,0,0,.12) !important;
  border-color: rgba(0,0,0,.12);
}

.btc-header-cell {
  background-color: #041f34;
  padding: 6px 24px 6px 24px;
  font-size: 14px;
  font-weight: 500;
  height: 100%;
  border-right: thin solid rgb(128, 128, 128);
}

.btc-header-cell:focus {
  background-color: #303035 !important;
}

</style>
