<template>
  <div class="ma-1 align-center">
    <v-container class="ma-1 pa-0 pl-5 d-flex align-center" style="height: 48px; min-width: 100%">
      <v-row class="" style="height: 48px; line-height: 48px; flex-wrap: nowrap;">
        <navigation-links />
        <div :class="isRenaming ? 'd-none' : 'd-inline-block'">
          <workflow-selector v-if="$route.params.id" ref="workflowSelector" @rename="renameWorkflow" />
        </div>
        <div v-if="isRenaming" class="d-flex">
          &nbsp;&nbsp;>&nbsp;&nbsp;
          <v-text-field
            class="navigation-top-rename py-0 pa-0 ma-0"
            autofocus
            hide-details
            variant="underlined"
            :rules="[value => (value.length <= 40) || 'Max 40 characters']"
            v-model="newName"
            v-on:keyup.enter="$event.target.blur()"
            @focus="$event.target.select()"
            @blur="saveWorkflow"
          >
          </v-text-field>
        </div>
      </v-row>
      <v-spacer></v-spacer>
      <slot><!-- slot for injecting additional buttons, used by workflow --></slot>
      <user-profile-header v-if="$store.state.isUserLoggedIn"></user-profile-header>
      <v-btn
        v-else
        variant="outlined"
        class="export-button mr-2"
        @click="redirectToLogin"
      >
        <v-icon :start="true" dark>mdi-login</v-icon>
        Login
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import UserProfileHeader from '@/components/UserProfileHeader'
import NavigationLinks from './NavigationLinks.vue'
import WorkflowSelector from './WorkflowSelector.vue'

export default {
  components: {
    NavigationLinks,
    WorkflowSelector,
    UserProfileHeader
  },
  data () {
    return {
      isRenaming: false,
      newName: ''
    }
  },
  methods: {
    redirectToLogin () {
      this.$router.push({ path: '/login' })
    },
    renameWorkflow (currName) {
      this.newName = currName
      this.isRenaming = true
    },
    saveWorkflow () {
      this.isRenaming = false
      this.$refs.workflowSelector.renameWorkflow(this.newName)
    }
  }
}
</script>

<style>
.navigation-top-rename input {
  padding: 1px;
}
</style>

<style scoped>
.navigation-top-rename {
  width: 200px;
  margin-top: 12px;
}
</style>
