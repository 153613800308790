<template>
  <div style="position: relative" v-on:keydown.stop>
    <v-card class="backlog-container" style="position: static">
      <div class="backlog-actions-container">
        <v-text-field
          variant="outlined"
          density="compact"
          class="backlog-search-field"
          v-model="search"
          append-inner-icon="mdi-magnify"
          label="Search"
          :single-line="true"
          hide-details
        ></v-text-field>
        <v-checkbox
          :model-value="showDone"
          @update:model-value="$emit('display-done', $event)"
          label="Show Done"
          hide-details
          class="mt-0 pt-0 ml-2 mr-4 flex-grow-0 flex-shrink-1"
        >
          <template #label="{ label }">
            <div class="text-no-wrap"> {{ label }} </div>
          </template>
        </v-checkbox>
        <v-checkbox
          v-if="selectedTab == 1"
          v-model="hideActions"
          label="Hide actions"
          hide-details
          class="mt-0 pt-0 mr-4 flex-grow-0 flex-shrink-1"
        ></v-checkbox>
        <workflow-backlog-add-button
          :currentEvent="getCurrentEvent"
          :requirementTypes="backlogRequirementTypes"
          @focus-card="focusCard = $event.id"
        />
      </div>

      <requirements-table
        v-if="selectedTab == 0"
        ref="requirementsTable"
        :items="filteredItems ? filteredItems.requirements : []"
        :events="filteredItems ? filteredItems.events : {}"
        :search="search"
        :idIndexMap="requirementsIdIndexMap"
        :requirementTypes="backlogRequirementTypes"
        :workflow="workflow"
        :focus-card="focusCard"
        :jiraData2="jiraData2"
        :loadingJiraInfo="loadingJiraInfo"
        :availableReleases="availableReleases"
        @open-requirement-dialog="handleOpenRequirementDialog"
        @item-actions-done="doneRequirement"
        @change-release-no="changeReleaseNo"
        @change-status="changeStatus"
        @assign-card="onAssignCard"
        @ask-for-reply="$emit('ask-for-reply', $event)"
        @drag-reorder="dragReorder"
      >
      </requirements-table>
      <user-story-mapping
        v-if="selectedTab == 1"
        ref="userStoryMapping"
        :items="filteredItems2 ? filteredItems2.requirements : []"
        :search="search"
        :idIndexMap="requirementsIdIndexMap"
        :requirementTypes="backlogRequirementTypes"
        :focus-card="focusCard"
        :showDone="showDone"
        :hideActions="hideActions"
        :releases="releases"
        @open-requirement-dialog="handleOpenRequirementDialog"
        @item-actions-done="doneRequirement"
        @change-release-no="changeReleaseNo"
        @change-status="changeStatus"
        @assign-card="onAssignCard"
      >
      </user-story-mapping>
    </v-card>
    <v-container class="ma-0 pa-0 hover-container" fluid>
      <v-row class="ma-0">
        <v-col style="position: absolute;" v-if="selectedTab == 1" class="d-flex flex-grow-1 px-0 justify-center">
          <div v-if="$store.state.isUserLoggedIn" class="d-flex flex-shrink-0 font-weight-light dragover-to-add-release px-10">Drag here to add release {{ releases[0]?.value > 1 ? ' (or to the top release)' : '' }}</div>
        </v-col>
        <v-col class="d-flex justify-end px-0">
          <workflow-backlog-add-button
            :currentEvent="getCurrentEvent"
            :requirementTypes="backlogRequirementTypes"
            @focus-card="focusCard = $event.id"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="requirementDialog" max-width="800px" min-width="800px" style="z-index: 99999;">
      <v-card
        v-if="filteredItems && filteredItems.requirements?.[requirementIndex]"
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Card details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="requirementDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-list>
          <div>&nbsp;</div>
          <div class="text-subtitle-1 px-4">
            <div>
              <u>Lane:</u>
              {{
                laneNamesById[
                  filteredItems.events[
                    filteredItems.requirements[requirementIndex].eventId
                  ].laneId
                ]
              }}
            </div>
            <div>
              <u>Event:</u>
              {{
                filteredItems.events[
                  filteredItems.requirements[requirementIndex].eventId
                ].description
              }}
            </div>
            <div>
              <u>Card:</u>
              <pre class="card-text" v-html="filteredItems.requirements[requirementIndex].description" style="font-family: Roboto"></pre>
            </div>
            <div>&nbsp;</div>
          </div>
          <v-divider></v-divider>
          <v-container>
            <v-row
              class="d-flex mb-4 mt-4"
              v-for="(teamMemberReaction, i) in sortOnPrio(
                getTeamMembersReactionsList(
                  filteredItems.requirements[requirementIndex].requirementStatuses,
                  listTeamMembers
                )
              )"
              v-bind:key="i"
            >
              <v-col cols="1" class="text-center pa-0">
                <v-icon>mdi-account</v-icon>
              </v-col>

              <v-col cols="5" class="pa-0">
                <v-list-item-title
                  v-text="
                    teamMemberReaction.user
                      ? teamMemberReaction.user.firstname + ' ' + teamMemberReaction.user.lastname
                      : teamMemberReaction.user?.email
                  "
                ></v-list-item-title>
              </v-col>

              <v-col cols="6" class="pa-0">
                <v-avatar
                  :color="
                    $refs.requirementsTable.getColor(
                      teamMemberReaction
                        ? teamMemberReaction.prio
                        : 0
                    )
                  "
                  size="30"
                >
                  <span>{{
                    $refs.requirementsTable.getMoSCoW(
                      teamMemberReaction
                        ? teamMemberReaction.prio
                        : 0
                    )
                  }}</span>
                </v-avatar>
              </v-col>
            </v-row>
          </v-container>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RequirementsTable from '@/components/RequirementsTable'
import UserStoryMapping from '@/components/UserStoryMapping'
import WorkflowBacklogAddButton from '@/components/WorkflowBacklogAddButton'
// import AssignCard from '@/mutations/AssignCard'
import midString from '@/utils/mid-string'
import workflowApi from '@/api/workflowApi'
import { cardHelpers } from '@/mixins/cardHelpers.js'
import { mapState } from 'vuex'
import store from '../store'

export default {
  components: {
    RequirementsTable,
    UserStoryMapping,
    WorkflowBacklogAddButton
  },
  mixins: [cardHelpers],
  props: [
    'eventId',
    'workflow',
    'showDone',
    'jiraData2',
    'loadingJiraInfo',
    'selectedTab',
    'releases'
  ],
  data () {
    return {
      editedIndexRequirement: -1,
      valid: true,
      rules: {
        required: value => !!value || 'Required.',
        numbers: value => {
          const pattern = /^[0-9]*$/
          return pattern.test(value) || 'Only numbers.'
        },
        max100chars: (v) => (v && v.length <= 100) || 'Max 100 characters',
        max280chars: (v) => (v && v.length <= 280) || 'Max 280 characters',
        max500chars: (v) => (!v || (v && v.length <= 500)) || 'Max 500 characters'
      },
      myMessage: null,
      requirementDialog: false,
      requirementIndex: null,
      activeTab: 0,
      search: null,
      selectedTypes: [],
      attachmentsDialog: false,
      focusCard: null,
      hideActions: false
    }
  },
  computed: {
    ...mapState({
      events: state => state.workflow.workflow.eventsJson,
      listTeamMembers: state => state.teamMembers.teamMembers,
      requirementTypes: state => state.requirementTypes.requirementTypes,
      showReleaseNo: state => state.workflow.showReleaseNo
    }),
    backlogRequirementTypes () {
      return this.requirementTypes?.filter(({ showInBacklog }) => showInBacklog)
    },
    getCurrentEvent () {
      if (this.eventId && this.events) {
        const index = this.events.findIndex(item => item.id === this.eventId)
        return this.events[index]
      } else {
        return null
      }
    },
    availableReleases () {
      let max = 1
      if (this.events) {
        for (let i = 0; i < this.events.length; i++) {
          for (let j = 0; j < this.events[i].requirementsJson?.length; j++) {
            if (this.events[i].requirementsJson[j].releaseNo > max) {
              max = this.events[i].requirementsJson[j].releaseNo
            }
          }
        }
      }
      return max + 1
    },
    filteredItems () {
      if (!this.events) return

      let requirements = []
      const events = {}
      const { eventId } = this.$route.params
      const displayTypeInBacklog = {}

      if (Array.isArray(this.requirementTypes)) {
        for (const { id, showInBacklog } of this.requirementTypes) {
          displayTypeInBacklog[id] = showInBacklog
        }
      }

      for (const event of this.events) {
        if (!eventId || eventId === event.id) {
          if (event.requirementsJson) {
            requirements = requirements.concat(event.requirementsJson.map((requirement) => {
              return { ...requirement, eventId: event.id, releaseIndex: requirement.releaseNo || 0 }
            }))
          }
          events[event.id] = { ...event }
        }
      }
      requirements = requirements
        .filter(({ done }) => this.showDone === Boolean(done))
        .filter(({ cardType }) => {
          if (!cardType || !Object.prototype.hasOwnProperty.call(displayTypeInBacklog, cardType.id)) {
            return true
          }
          return displayTypeInBacklog[cardType.id]
        })

      if (this.showReleaseNo && this.showReleaseNo.length > 0) {
        requirements = requirements
          .filter(({ releaseNo }) => this.showReleaseNo.includes(releaseNo))
      }
      requirements = this.sortCards(requirements)
      return { requirements, events }
    },

    filteredItems2 () {
      if (!this.events) return

      let requirements = []
      const events = {}

      // take the array thie.requirementTypes and make it into an object
      // where the key is the id and the value is the showInBacklog
      // this is done to make it easier to check if a card type should be shown in the backlog
      const displayTypeInBacklog = this.requirementTypes.reduce((accum, { id, showInBacklog }) => {
        accum[id] = showInBacklog
        return accum
      }, {})

      for (const event of this.events) {
        if (event.requirementsJson) {
          requirements = requirements.concat(event.requirementsJson.map((requirement) => {
            return { ...requirement, eventId: event.id }
          }))
        }
        events[event.id] = event
      }
      requirements = requirements
        .filter(({ done }) => this.showDone === Boolean(done))
        .filter(({ cardType }) => {
          if (!cardType || !Object.prototype.hasOwnProperty.call(displayTypeInBacklog, cardType.id)) {
            return true
          }
          return displayTypeInBacklog[cardType.id]
        })

      if (this.showReleaseNo && this.showReleaseNo.length > 0) {
        requirements = requirements
          .filter(({ releaseNo }) => this.showReleaseNo.includes(releaseNo))
      }
      requirements = this.sortCards(requirements)
      return { requirements, events }
    },

    requirementsIdIndexMap () {
      let requirements = []

      if (!this.events) {
        return {}
      }

      for (const event of this.events) {
        if (event.requirementsJson) {
          requirements = requirements.concat(event.requirementsJson)
        }
      }

      const displayTypeInBacklog = {}

      if (Array.isArray(this.requirementTypes)) {
        for (const { id, showInBacklog } of this.requirementTypes) {
          displayTypeInBacklog[id] = showInBacklog
        }
      }

      requirements = requirements
        .filter(({ done }) => this.showDone === Boolean(done))
        .filter(({ cardType }) => {
          if (!cardType || !Object.prototype.hasOwnProperty.call(displayTypeInBacklog, cardType.id)) {
            return true
          }
          return displayTypeInBacklog[cardType.id]
        })
      requirements = this.sortCards(requirements)

      const result = requirements.reduce(function (accumulator, currentValue, index) {
        accumulator[currentValue.id] = index
        return accumulator
      }, {})

      return result
    },

    laneNamesById () {
      const result = {}

      if (!this.workflow || !Array.isArray(this.workflow.lanes)) {
        return result
      }

      return this.workflow.lanes.reduce((accum, lane) => {
        accum[lane.id] = lane.name

        return accum
      }, {})
    }
  },
  methods: {
    sortCards (cards) {
      cards.sort((a, b) => {
        const sortA = a.sortkey || 'zzzzzzzzzz'
        const sortB = b.sortkey || 'zzzzzzzzzz'
        const releaseA = a.releaseNo || '9999999999'
        const releaseB = b.releaseNo || '9999999999'
        if (releaseA > releaseB) {
          return 1
        } else if (releaseB > releaseA) {
          return -1
        } else {
          if (sortA === sortB) {
            return a.id > b.id ? 1 : -1
          } else {
            return sortA > sortB ? 1 : -1
          }
        }
      })
      return cards
    },
    getRoundedAverage (teamMembersReactions) {
      return Math.round(this.getAverage(teamMembersReactions))
    },
    async changeStatus (item, prio) {
      await store.dispatch('workflow/updateUserStatus', {
        requirementId: item.id,
        status: prio,
        prio,
        message: item.message,
        workflowId: this.$route.params.id,
        eventId: item.eventId,
        projectId: this.$route.params.projectId
      })
    },
    changeReleaseNo (item, releaseNo) {
      let previous, next
      const cards = this.filteredItems.requirements?.filter(item => item.releaseNo === releaseNo)
      if (cards?.length > 0) {
        if (item.releaseNo === null || (releaseNo !== null && releaseNo < item.releaseNo)) {
          previous = cards[cards.length - 1].sortkey // last card in releaseNo
          next = ''
        } else {
          previous = ''
          next = cards[0].sortkey // first card in releaseNo
        }
      } else {
        previous = ''
        next = ''
      }
      const sortkey = this.midString(previous, next)
      workflowApi.updateRequirementsJsonMutation(this.$route.params.id, [{
        requirementId: item.id,
        releaseNo,
        sortkey,
        updateNote: {
          action: 'changed release number of',
          target: item.description
        }
      }])
    },
    sortOnPrio (teamMembersReactions) {
      // var res = teamMembersReactions.sort((a, b) => {
      //   return a.prio - b.prio
      // })
      // console.log('returning res', res)
      return teamMembersReactions
    },
    showAll () {
      this.$emit('show-all')
    },
    dragReorder ({ item, oldIndex, newIndex }) {
      let previousSortkey, nextSortkey

      // set new release number for the item that was dragged
      // and pass the update note only on the first item (should be refactored to make more sense)
      const newReleaseNoForItem = parseInt(this.getReleaseNoFromTableRowElement(item))
      const index = this.filteredItems.requirements.findIndex(element => element.id === item.id)
      const target = this.filteredItems.requirements[index]?.description
      const cardsToUpdate = [{
        requirementId: item.id,
        releaseNo: newReleaseNoForItem || null,
        updateNote: {
          action: 'moved card',
          target
        }
      }]

      // get the sortkey from the previous row
      const previousEventId = item.previousElementSibling?.getAttribute('data-workflow-event-id') || null
      if (previousEventId) {
        previousSortkey = item.previousElementSibling.getAttribute('data-sortkey')
      }

      // if the next item has the same sortkey as the previous item (can happen when rearraging cards for one event at a time),
      // then we need to assign a new sortkey to this item, also check the following items
      let nextItem = item.nextElementSibling
      while (nextItem?.getAttribute('data-sortkey') === previousSortkey) {
        cardsToUpdate.push({
          requirementId: nextItem.id,
          releaseNo: undefined // set it to undefined to avoid updating releaseNo (because null will update)
        })
        nextItem = nextItem.nextElementSibling
      }
      const nextEventId = nextItem?.getAttribute('data-workflow-event-id') || null
      if (nextEventId) {
        nextSortkey = nextItem.getAttribute('data-sortkey')
      }
      let card
      const cards = []
      // assign a new sortkey to the dragged item
      // and to all the following items, if there are items with duplicate sortkeys
      while ((card = cardsToUpdate.pop())) {
        const sortkey = this.midString(previousSortkey || '', nextSortkey || '')
        cards.push({ sortkey, ...card })
        nextSortkey = sortkey
      }
      workflowApi.updateRequirementsJsonMutation(this.$route.params.id, cards)
    },

    getReleaseNoFromTableRowElement (element) {
      const previousElement = element.previousElementSibling
      const nextElement = element.nextElementSibling
      let releaseNo = previousElement?.getAttribute('data-release-no')
      if (releaseNo) {
        return releaseNo
      }
      releaseNo = nextElement?.getAttribute('data-release-no')
      if (releaseNo) {
        return releaseNo
      }
      // don't know if the following 4 rows are needed
      releaseNo = previousElement?.firstElementChild.getAttribute('data-release-no')
      if (releaseNo) {
        return releaseNo
      }
      return null
    },

    midString,

    doneRequirement (item, done) {
      let action
      if (done) {
        action = 'marked card as done'
      } else {
        action = 'reopened card'
      }
      workflowApi.updateRequirementsJsonMutation(this.$route.params.id, [{
        requirementId: item.id,
        done,
        updateNote: {
          action,
          target: item.description
        }
      }])
    },
    handleOpenRequirementDialog (item) {
      this.requirementIndex = this.filteredItems.requirements.findIndex(it => it.id === item.id)
      this.requirementDialog = true
    },
    onAssignCard (item, userId) {
      // const { id: cardId, eventId } = item

      // return this.$apollo.mutate({
      //   mutation: AssignCard,
      //   variables: {
      //     id: cardId,
      //     projectId: this.$route.params.projectId,
      //     workflowId: this.$route.params.id,
      //     eventId,
      //     userId
      //   }
      // })
      //   .then(() => {
      //     this.$snackbar.showMessage({ content: 'Updated successfully' })
      //   })
      //   .catch(() => {
      //     this.error = 'An error occurred while updating card'
      //   })
    }
  }
}
</script>

<style scoped>
.card-text {
  word-wrap: break-word; /* Wrap long words to fit within the width */
  white-space: normal; /* Allow text to wrap to multiple lines */
}
.backlog-container {
  border-radius: 10px;
  overflow: hidden;
}

.backlog-actions-container {
  height: 36px;
  width: 50%;
  position: absolute;
  right: 0;
  top: -40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
  box-shadow: none;
}

div.show-prio {
  text-transform: none;
}

.v-expansion-panel__header__icon {
  display: none;
}

.selected-type {
  margin-right: 4px;
}

.add-requirement-button {
  height: 30px !important;
  max-width: 310px;
  border-radius: 10px !important;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.dragover-to-add-release {
  border: 2px dashed gray;
}
</style>

<style>
.backlog-search-field {
  max-width: 220px !important;
}

.backlog-search-field .v-input__control {
  min-height: 36px !important;
}

.backlog-search-field .v-field {
  min-height: 36px !important;
  border-radius: 10px !important;
}

.backlog-search-field .v-input__slot .v-label {
  font-size: 14px;
  top: 8px !important;
}

.backlog-search-field .v-input__append-inner {
  margin-top: 6px !important;
}

.add-requirement-button .v-btn__content {
  height: 16px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline;
  max-width: 100%;
}
</style>
