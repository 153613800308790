import gql from 'graphql-tag'

export default gql`
  mutation updateUserStatus($requirementId: String!, $status: Int, $prio: Int, $message: String, $workflowId: String!, $eventId: String!, $projectId: String!) {
    updateUserStatus(
      requirementId: $requirementId
      status: $status
      prio: $prio
      message: $message
      workflowId: $workflowId
      eventId: $eventId
      projectId: $projectId)
    {
      userStatus {
        userId
        requirementId
        prio
        message
        id
        lastUpdate
      }
      workflowId
      eventId
    }
  }
`
