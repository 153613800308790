import gql from 'graphql-tag'
// import { WORKFLOW_FIELDS } from '../fragments'

export default gql`
  query listWorkflowHistory($projectId: String!, $workflowId: String!) {
    listWorkflowHistory(projectId: $projectId, workflowId: $workflowId) {
      id
      email
      workflowHistory(workflowId: $workflowId) {
        id
        version
        updatedAt
        updateNote {
          userRealName
          action
          target
        }
      }
    }
  }
`
