let awsExports, cypressRunning
try {
  if (Cypress) {
    cypressRunning = true
  }
} catch (e) {
  cypressRunning = false
}

if (cypressRunning) {
  awsExports = {
    Auth: {
      region: Cypress.env('VUE_APP_AWS_REGION'),
      userPoolId: Cypress.env('VUE_APP_COGNITO_USER_POOL_ID'),
      userPoolWebClientId: Cypress.env('VUE_APP_COGNITO_USER_POOL_CLIENT_ID')
    },
    oauth: {
      domain: Cypress.env('VUE_APP_COGNITO_DOMAIN'),
      scope: [
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: Cypress.env('VUE_APP_COGNITO_GOOGLE_REDIRECT_URI'),
      redirectSignOut: Cypress.env('VUE_APP_COGNITO_GOOGLE_REDIRECT_URI'),
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
} else {
  awsExports = {
    Auth: {
      region: process.env.VUE_APP_AWS_REGION,
      userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID
    },
    oauth: {
      domain: process.env.VUE_APP_COGNITO_DOMAIN,
      scope: [
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: process.env.VUE_APP_COGNITO_GOOGLE_REDIRECT_URI,
      redirectSignOut: process.env.VUE_APP_COGNITO_GOOGLE_REDIRECT_URI,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
}

export default awsExports
