<template>
  <v-menu
    v-model="menu"
    max-width="400"
    location="right center"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ props: activatorProps }">
      <div
        v-bind="activatorProps"
        class="d-flex justify-end pa-1"
        style="width: 100%;"
      >
        <v-chip
          size="small"
          density="compact"
          @click="() => {}"
        >{{ boundedContextName || 'Select bounded context'}}
        </v-chip>
      </div>
    </template>
    <!-- <template v-slot:default="{ isActive }"> -->
    <template v-slot:default>
      <v-card class="ml-3 pa-3" width="300">
        <div class="d-flex pb-4 justify-space-between">
          <v-btn
            :style="!createBoundedContext ? 'visibility: hidden;' : ''"
            variant="text"
            size="small"
            icon="mdi-arrow-left"
            @click="createBoundedContext = false"
          />
          <span class="text-h7 font-weight-bold pt-2">Bounded Contexts</span>
          <v-btn
            variant="text"
            size="small"
            icon="mdi-close"
            @click="menu = false"
          />
        </div>
        <v-text-field
          v-if="!disabled"
          :rules="[maxNameLength]"
          ref="searchBar"
          class="select-bounded-context"
          autofocus
          density="compact"
          v-model="search"
          :placeholder="!createBoundedContext ? 'Search for bounded contexts' : 'Name your bounded context'"
          variant="outlined"
          @keydown="keyDownBoundedContext">
        </v-text-field>

        <v-list ref="searchList">
          <template v-if="createBoundedContext">
            <v-list-item
              density="compact"
              :disabled="!search"
              @click="updateMenuValue(search)"
            >
              <v-list-item-title>Create</v-list-item-title>
            </v-list-item>
          </template>
          <template v-else>
            <!-- list all bounded contexts -->
            <v-list-item
              v-for="(bc, index) in filteredBoundedContexts"
              density="compact"
              :key="index"
              @click="updateMenuValue(bc.name)"
            >
              <v-list-item-title>{{ bc.name }}</v-list-item-title>
            </v-list-item>

            <v-divider class="my-2"></v-divider>

            <v-list-item
              density="compact"
              @click="createBoundedContext = true"
            >
              <v-list-item-title>Create a new bounded context</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="boundedContextName"
              density="compact"
              @click="updateMenuValue('')"
            >
              <v-list-item-title>Detach from bounded context</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </template>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'
import workflowApi from '@/api/workflowApi'
import randomUUID from '@/utils/uuid'
import { diagramHelpers } from '@/mixins/diagramHelpers.js'

export default {
  mixins: [diagramHelpers],
  props: [
    'dimension',
    'requirement',
    'boundedContexts',
    'refreshTrigger'
  ],
  data () {
    return {
      open: false,
      svgGraph: null,
      svgLanes: null,
      refreshKey: 0,
      menu: false,
      keyDownPressed: false,
      search: '',
      createBoundedContext: false
    }
  },
  computed: {
    disabled () {
      return this.$store.state.isWorkflowDisabled
    },
    ...mapState({
      workflow: state => state.workflow.workflow
    }),
    boundedContextName () {
      if (!this.workflow.boundedContexts) return ''

      let id
      if (this.dimension) {
        id = this.dimension.boundedContext
      } else {
        id = this.requirement.boundedContext
      }
      return this.workflow.boundedContexts.find(it => it.id === id)?.name || ''
    },
    filteredBoundedContexts () {
      if (!this.workflow.boundedContexts) return []
      const list = this.workflow.boundedContexts.filter(bc => bc.name)
      // TODO: improve fuzzy search, was generated by ChatGPT
      return list.filter(bc => bc.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  methods: {
    maxNameLength (v) {
      return v.length <= 60 || 'Name must be less than 60 characters'
    },
    keyDownBoundedContext (event) {
      if (event.key === 'Enter') {
        event.target.blur()
        this.updateMenuValue(event.target.value)
      }
      if (event.key === 'ArrowDown') {
        this.$refs.searchList?.$el?.firstElementChild?.focus()
        event.stopPropagation()
        event.preventDefault()
      } else if (event.key === 'ArrowUp') {
        this.$refs.searchList?.$el?.lastElementChild?.focus()
        event.stopPropagation()
        event.preventDefault()
      }
    },
    async updateMenuValue (event) {
      this.menu = false

      let boundedContext = (this.workflow.boundedContexts || []).find(it => it.name === event)
      if (!boundedContext) {
        if (event) {
          boundedContext = await this.createNewBoundedContext(event)
        } else {
          boundedContext = { id: null }
        }
      }

      if (this.dimension) {
        await this.updateBoundedContextOnDimension(this.dimension, boundedContext.id)
      } else {
        await workflowApi.updateRequirementsJsonMutation(this.$route.params.id, [{ requirementId: this.requirement.id, boundedContext: boundedContext.id }])
      }
    },
    async createNewBoundedContext (name) {
      const boundedContext = {
        id: randomUUID(),
        name
      }
      await this.$store.dispatch('workflow/updateWorkflow', {
        projectId: this.workflow.projectId,
        input: {
          id: this.workflow.id,
          expectedVersion: this.workflow.version,
          boundedContexts: [...(this.workflow.boundedContexts || []), boundedContext],
          updateNote: {
            action: 'added bounded context',
            target: name
          }
        }
      })
      return boundedContext
    },
    async updateBoundedContextOnDimension (dimension, event) {
      const index = this.workflow.dataModels?.findIndex(model => model.name === dimension.dimensionName)
      const dataModelsWorkflow = JSON.parse(JSON.stringify(this.workflow.dataModels))
      dataModelsWorkflow[index].boundedContext = event
      await this.$store.dispatch('workflow/updateWorkflow', {
        projectId: this.workflow.projectId,
        input: {
          id: this.workflow.id,
          expectedVersion: this.workflow.version,
          dataModels: dataModelsWorkflow,
          updateNote: {
            action: 'updated data models',
            target: ''
          }
        }
      })
    }
  },
  watch: {
    createBoundedContext (newValue) {
      if (newValue) {
        this.$refs.searchBar?.focus()
      } else {
        this.search = ''
      }
    },
    menu (newValue) {
      if (newValue) {
        this.search = ''
        this.createBoundedContext = false
      }
    }
  }
}
</script>

<style scoped>

</style>
