<template>
  <div ref="editorContainer" class="editor-container" @keydown="keyDown"></div>
</template>

<script>
import loader from '@monaco-editor/loader'

export default {
  name: 'CodeEditor',
  props: {
    modelValue: {
      type: String,
      required: false
    },
    isDirty: {
      type: Boolean,
      default: false
    },
    language: {
      type: String,
      default: 'javascript'
    },
    theme: {
      type: String,
      default: 'vs-light'
    }
  },
  data () {
    return {
      version: 0
    }
  },
  watch: {
    modelValue (newValue) {
      if (this.editor && newValue !== this.editor.getValue()) {
        this.editor.setValue(newValue)
      }
    },
    language (newLanguage) {
      if (this.editor) {
        this.editor.setModelLanguage(this.editor.getModel(), newLanguage)
      }
    },
    theme (newTheme) {
      this.editor.setTheme(newTheme)
    }
  },
  mounted () {
    loader.init().then(monaco => {
      this.editor = monaco.editor.create(this.$refs.editorContainer, {
        value: this.modelValue,
        language: this.language,
        theme: this.theme
      })

      this.version = this.editor.getModel().getAlternativeVersionId()

      this.editor.onDidChangeModelContent(() => {
        const value = this.editor.getValue()
        this.$emit('update:modelValue', value)

        const dirtyVersion = this.editor.getModel().getAlternativeVersionId()
        this.$emit('update:isDirty', this.version !== dirtyVersion)
      })
    })
  },
  methods: {
    keyDown (event) {
      if (this.isDirty && ((event.ctrlKey || event.metaKey) && event.key === 's')) {
        event.preventDefault()
        event.stopPropagation()

        this.version = this.editor.getModel().getAlternativeVersionId()
        this.$emit('update:isDirty', false)
        this.$emit('save')
      }
    }
  },
  beforeDestroy () {
    if (this.editor) {
      this.editor.dispose()
    }
  }
}
</script>

<style>
.editor-container {
  width: 100%;
  height: 600px;
}
</style>
