import gql from 'graphql-tag'

export default gql`
  subscription OnCreateTeamMemberForUser($userId: String) {
    onCreateTeamMember (userId: $userId) {
      userId
      projectId
      createTeamMemberFunction {
        id
        userId
        email
        projectId
        lastLogin
        user {
          id
          firstname
          lastname
          email
        }
        project {
          id
          name
          organisationId
          organisation {
            id
            name
            userId
            user {
              id
              firstname
              lastname
            }
          }
          jiraForgeData {
            projectId
          }
        }
      }
    }
  }
`
