import { examples } from '@/api/chatGPT/examples'
import dataHelpers from '@/helpers/dataHelpers'
export const promptHelpers = {
  actor (systemPrompt = false) {
    let res = ''
    if (!systemPrompt) {
      res += 'Actor:\n'
    }
    res += 'Act as a business (or organisation) mapping out its process steps, roles and the data flows.'
    if (!systemPrompt) {
      res += '\n\n'
    }
    return res
  },
  roleAndDescriptionInstruction (onlyOneCard = false, verbPastTense = false) {
    let res = ''
    if (onlyOneCard) {
      res += 'The step '
    } else {
      res += 'Each step '
    }
    res += 'must contain the role or actor name with the max length of 18 characters. '
    if (onlyOneCard) {
      res += 'The step '
    } else {
      res += 'Each step '
    }
    res += 'must contain the description of the step with the max length of 40 characters, '
    res += `the description should contain a verb ${verbPastTense ? 'in past tense ' : '(imperative) '}and an object.`
    return res
  },
  requestNewDiagram (workflow, requirementTypes, description, verbPastTense) {
    let res = ''
    res += 'Request:\n'
    res += `Create a workflow (a sequence of steps in chronological order) containing ${description}. `
    res += this.roleAndDescriptionInstruction(false, verbPastTense)
    res += '\n\n'
    res += this.formatInstruction(workflow, requirementTypes, {
      step: false, shouldAddCards: false, shouldAddDataModels: false
    })
    return res
  },
  requestAllCardsForEachStep (workflow, requirementTypes) {
    let res = ''
    res += 'Request:\n'
    res += this.cardTypeInstruction(requirementTypes, false)
    res += this.importantConsideration(requirementTypes)
    res += this.formatInstruction(workflow, requirementTypes, {
      step: false, shouldAddCards: true, shouldAddDataModels: false
    })
    return res
  },
  requestDataModelsForEachStep (workflow, dataFieldCount, requirementTypes) {
    let res = ''
    res += 'Request:\n'
    res += this.dataFieldInstruction(dataFieldCount, 'create-workflow')
    res += this.formatInstruction(workflow, requirementTypes, {
      step: false, shouldAddCards: false, shouldAddDataModels: true
    })
    return res
  },
  backgroundCurrentDiagram (description, workflow, requirementTypes) {
    let res = ''
    res += 'Background:\n'
    res += `Here is a workflow containing ${description} in JSON format: `
    res += JSON.stringify(workflow) + '. '
    res += 'For each step, in the workflow provided, it contains the number of the step, the role and the description.'
    res += this.metaDataInstruction(requirementTypes)
    res += '\n\n'
    return res
  },
  metaDataInstruction (requirementTypes) {
    let res = ''
    let foundType = false
    res += ' Each step (in the workflow) can also contain meta data with the following meaning: ['
    for (const requirementType of requirementTypes) {
      if (requirementType.aiCount > 0) {
        if (res.slice(-1) !== ' ') {
          res += ' '
        }
        res += `"${requirementType.title.replace(/\s+/g, '-').toLowerCase()}" : "${requirementType.description}",`
        foundType = true
      }
    }
    if (res.slice(-1) === ',') {
      res = res.slice(0, -1)
    }
    res += '].'
    if (!foundType) {
      res = ''
    }
    return res
  },
  dataFieldInstruction (count, mode = 'create-workflow') {
    let res = ''
    if (count > 0) {
      if (mode === 'add-shape') {
        res += 'Also create a list of '
      } else if (mode === 'create-workflow') {
        res += 'For each step, create a list of '
      } else if (mode === 'create-event-story') {
        res += 'For this step in the process, create a list of '
      }
      res += `${count} different types of data let's call them 'dataFields' that are relevant for the step. `
      res += "The 'dataFields' should reflect what information an IT system or software should provide to the role in order to perform the step or what information an IT system or software should capture from the role that is performing the step. "
      res += "The first instance of 'dataFields' should contain the name of the role or actor (who is performing the step). "
      res += "The second instance of 'dataFields' should contain the object from the description of the step (what is being done). "
      res += "The third instance of 'dataFields' should contain a date or a time stamp (when). "
      if (count > 3) {
        if (count === 4) {
          res += "The last instance of 'dataFields' should be based on what else is relevant for the step. "
        } else {
          res += "The following instances of 'dataFields' should be based on what else is relevant for the step. "
        }
      }
      res += "Categorise each instance of 'dataFields' with with one of these 7 categories depending on what is most suitable: [Who, What, When, Where, How many, Why, How]. "
      res += "For each instance of 'dataFields': Create a list with 3 items of typical example data. "
      res += "Add to each instance of 'dataFields' except the first, a connecting word (as 'connectingWord') that is a verb, preposition or function word ('orders', 'from', 'to', 'on'). "
      res += "The 'connectingWord' should be possible to put in front of the value of the current 'fieldName' and after the previous 'fieldName' in the list to create a readable piece of text. "
      res += "Tag each instance of 'dataFields' with 1 - 3 tags (minimum 1 tag and maximum 3 comma separated tags) based on the following rules. "
      res += "Rule 1 - Tag the instance of the 'dataFields' with 'READ' if it contains data or options that are defined in the system before the current step in the process (for example a menu or product catalog). "
      res += "Rule 2 - Tag the instance of the 'dataFields' with 'WRITE' if it contains data selected or collected while executing the current step (for example product and quantity to order). "
      res += "Rule 3 - Tag the instance of the 'dataFields' with 'CREATE' if the instance of the 'dataFields' contains a non-primitive data type like an object that can be created and stored separately as a part of executing the current step (for example a new customer object with phone number and multiple address lines)."
    }
    if (res !== '') {
      res += '\n\n'
    }
    return res
  },
  factBackgroundAndInstruction (aiDescription, roleName, description, eventId, svgGraph) {
    let res = ''
    res += 'Background:\n'
    res += `In a workflow containing '${aiDescription}' there is one business event defined as: '${roleName} - ${description}'. `
    res += 'The relevant data types/entities, that are either provided or captured in connection to this business event are: '
    const index = svgGraph[eventId].dataModels.findIndex(model => model.type === 'Event Story')
    const fields = svgGraph[eventId].dataModels[index].dataFields
    res += '[' + fields.map(field => field.name).join(', ') + ']\n\n'
    res += 'Request:\n'
    res += 'Create an example of a database fact table based on the data types/entities listed above. '
    res += 'The fields of the fact table are typically either ID\'s pointing to dimension tables or quantities or dates or simple info. '
    res += 'Fill the fact table with 3 rows of example data. '
    res += 'Each field in the fact table database should be assigned exactly one category from the following list of categories: '
    res += '[Who, What, Where, How many, Why, How, When], based on which category that best describes the actual field in the fact table.'
    return res
  },
  cardTypeInstruction (cardTypes, onlyOneCard = false) {
    let res = ''
    for (const cardType of cardTypes) {
      if (cardType.aiCount === 1) {
        if (res.slice(-1) === '.') {
          res += ' '
        }
        res += `For each step, add only 1 typical ${this.adjustEnding(cardType.title, 1)}: ${this.removeTrailingDot(cardType.description)} (as ${cardType.title.replace(/\s+/g, '-').toLowerCase()}).`
      }
      if (cardType.aiCount > 1) {
        if (res.slice(-1) === '.') {
          res += ' '
        }
        if (onlyOneCard) {
          res += 'Create a list of '
        } else {
          res += 'For each step, create a list of '
        }
        res += `${cardType.aiCount} typical ${this.adjustEnding(cardType.title, cardType.aiCount)}: ${this.removeTrailingDot(cardType.description)} (as ${cardType.title.replace(/\s+/g, '-').toLowerCase()}).`
      }
    }
    if (res !== '') {
      res += '\n\n'
    }
    return res
  },
  cardTypeInstruction2 (requirementType, addShape = false, content) {
    let res = ''
    if (requirementType.aiCount === 1) {
      if (content.slice(-1) === '.') {
        res += ' '
      }
      res += `Also add 1 typical ${this.adjustEnding(requirementType.title, 1)}: ${this.removeTrailingDot(requirementType.description)} (as ${requirementType.title.replace(/\s+/g, '-').toLowerCase()}).`
    }
    if (requirementType.aiCount > 1) {
      if (content.slice(-1) === '.') {
        res += ' '
      }
      res += `Also add ${requirementType.aiCount} typical ${this.adjustEnding(requirementType.title, requirementType.aiCount)}: ${this.removeTrailingDot(requirementType.description)} (as ${requirementType.title.replace(/\s+/g, '-').toLowerCase()}).`
    }
    return res
  },
  getSelectedDataFields (dataFieldsExample, count) {
    const res = []
    if (count > 0) {
      for (let i = 0; i < count; i++) {
        res.push(dataFieldsExample[i])
      }
      return res
    } else {
      return undefined
    }
  },
  createExampleObject (workflow, requirementTypes, step = null, { role, description, dataFieldsExample }, shouldAddCards = true, shouldAddDataModels = true) {
    const exampleObj = {}
    exampleObj.role = role
    exampleObj.description = description
    if (step) {
      exampleObj.step = 0.5 + step
    }
    if (shouldAddDataModels && workflow.beamAiOptions) {
      exampleObj.dataFields = this.getSelectedDataFields(dataFieldsExample, workflow.beamAiOptions.dataFieldCount)
    }
    if (shouldAddCards) {
      for (let i = 0; i < requirementTypes?.length; i++) {
        if (requirementTypes[i].aiCount > 0) {
          const key = requirementTypes[i].title.replace(/\s+/g, '-').toLowerCase()
          exampleObj[key] = []
          for (let j = 0; j < requirementTypes[i].aiCount; j++) {
            if (j === 0) {
              exampleObj[key].push(`Example description ${requirementTypes[i].title.toLowerCase()} X`)
            } else if (j === 1) {
              exampleObj[key].push(`Example description ${requirementTypes[i].title.toLowerCase()} Y`)
            } else {
              exampleObj[key].push(`Example description ${requirementTypes[i].title.toLowerCase()} Z`)
            }
          }
        }
      }
    }
    return exampleObj
  },
  formatInstruction (workflow, requirementTypes, formatOptions) {
    const { step, shouldAddCards, shouldAddDataModels } = formatOptions
    let content = ''
    if (!step) {
      // send 2 example objects
      content += '**Formatting**:\n'
      content += 'The result should be formatted as a valid JSON array similar to this example: \n\n'
      content += '```json\n'
      const exampleObj1 = this.createExampleObject(workflow, requirementTypes, step, { role: 'Role description', description: 'Do task A', dataFieldsExample: examples.dataFieldsExampleCreateOrder }, shouldAddCards, shouldAddDataModels)
      const exampleObj2 = this.createExampleObject(workflow, requirementTypes, step, { role: 'Role description', description: 'Perform action B', dataFieldsExample: examples.dataFieldsExampleDeliverOrder }, shouldAddCards, shouldAddDataModels)
      content += JSON.stringify([exampleObj1, exampleObj2])
      content += '\n```'
    } else {
      // sent only 1 example object
      content += '**Formatting**:\n'
      content += 'Don\'t return all the steps. Only return the new step. The result should be formatted as a valid JSON array of size 1, containing only one object similar to this example: \n\n'
      content += '```json\n'
      const exampleObj1 = this.createExampleObject(workflow, requirementTypes, step, { role: 'Role X', description: 'Do task Y', dataFieldsExample: examples.dataFieldsExampleCreateOrder })
      content += JSON.stringify([exampleObj1])
      content += '\n```'
    }
    return content
  },
  importantConsideration (requirementTypes) {
    let res = ''
    res += '**Very important consideration**: \n'
    res += 'Please, it is very crucial that you make sure the output json contains'
    res += ' '
    res += requirementTypes.filter(r => r.aiCount).map(r => `exactly ${r.aiCount} ${r.title}`).join(' and ') + '.'
    res += ' '
    res += 'No more and no less!'
    res += '\n\n'
    return res
  },
  adjustEnding (word, quant) {
    if (quant > 1 && word.charAt(word.length - 1) === 'y') {
      const str = word.substring(0, word.length - 1)
      return str + 'ies'
    }
    if (quant > 1 && word.charAt(word.length - 1) !== 's') {
      return word + 's'
    }
    if (quant === 1 && word.charAt(word.length - 1) === 's') {
      return word.substring(0, word.length - 1)
    }
    return word
  },
  removeTrailingDot (word) {
    if (word?.charAt(word.length - 1) === '.') {
      return word.substring(0, word.length - 1)
    }
    return word
  },
  diagramToJson (eventId, workflow, svgGraph, svgLanes, options) {
    let excludeCards = false
    if (options?.excludeCards) {
      excludeCards = true
    }
    let currentStep
    const res = []
    let index = 1
    const parentChildEventMap = dataHelpers.createMainParentChildEventMap(workflow.eventsJson)
    const eventArray = []
    let tmpEventId = eventId
    while (svgGraph[tmpEventId].parents[0] !== 'start') {
      eventArray.unshift(svgGraph[tmpEventId].id)
      tmpEventId = svgGraph[tmpEventId].parents[0]
    }
    eventArray.unshift(svgGraph[tmpEventId].id)
    tmpEventId = eventId
    while (parentChildEventMap[tmpEventId] || (workflow.eventsJson.findIndex(ev => ev.parents.includes(tmpEventId)) !== -1)) {
      if (parentChildEventMap[tmpEventId]) {
        eventArray.push(parentChildEventMap[tmpEventId][0].id)
        tmpEventId = parentChildEventMap[tmpEventId][0].id
      } else {
        const indexEvent = workflow.eventsJson.findIndex(wf => wf.parents.includes(tmpEventId))
        eventArray.push(workflow.eventsJson[indexEvent].id)
        tmpEventId = workflow.eventsJson[indexEvent].id
      }
    }
    let previousEvent = null
    for (const eventId2 of eventArray) {
      const event = svgGraph[eventId2]
      const cards = {}
      if (!excludeCards && event.requirementsJson) {
        for (const card of event.requirementsJson) {
          if (!card.cardType) continue
          const key = card.cardType.title.replace(/\s+/g, '-').toLowerCase()
          if (!cards[key]) { cards[key] = [] }
          cards[key].push(card.description)
        }
      }
      if (previousEvent?.type === 'bpmn:ExclusiveGateway') {
        const index = event.conditionLabels?.findIndex(label => label.parentId === previousEvent.id)
        if (index > -1) {
          res[res.length - 1].description += ` : ${event.conditionLabels[index].name}`
        }
      }
      res.push({
        step: index,
        role: svgLanes[event.laneId].name,
        description: event.name,
        ...cards
      })
      if (event.id === eventId) {
        currentStep = index
      }
      previousEvent = event
      index++
    }
    const returnObj = { workflow: res, currentStep }
    return returnObj
  },
  requestAddOneStep (workflow, currentDiagram, currentStep) {
    let res = ''
    res += 'Request:\n'
    res += `Suggest one more step, after step ${currentStep}`
    if (currentDiagram[currentStep]) {
      res += ` and before step ${currentStep + 1}`
    }
    res += `, that is relevant to a ${workflow.aiDescription}. `
    res += `The step should reflect what is typically happening in a ${workflow.aiDescription}, after step ${currentStep} (${currentDiagram[currentStep - 1].role} - ${currentDiagram[currentStep - 1].description})`
    if (currentDiagram[currentStep]) {
      res += ` and before ${currentStep + 1} (${currentDiagram[currentStep].role} - ${currentDiagram[currentStep].description})`
    }
    res += '. '
    res += promptHelpers.roleAndDescriptionInstruction(true)
    res += '\n\n'
    return res
  },
  descriptionAddOneStep (dataFieldCount, requirementTypes) {
    let addingCards = false
    let res = ''
    res += promptHelpers.dataFieldInstruction(dataFieldCount, 'add-shape')
    for (let i = 0; i < requirementTypes.length; i++) {
      res += promptHelpers.cardTypeInstruction2(requirementTypes[i], true, res)
      if (requirementTypes[i].aiCount > 0) {
        addingCards = true
      }
    }
    if (addingCards) {
      res += '\n\n'
    }
    if (res.length > 0) {
      res = 'Description:\n' + res
    }
    return res
  },
  createExampleObjectCard (index, requirementTypes) {
    const obj = {}
    const key = requirementTypes[index].title.replace(/\s+/g, '-').toLowerCase()
    obj[key] = 'Example text'
    return obj
  }
}
