<template>
  <v-container class="pa-0">
    <v-row class="ma-0 mt-2 align-center justify-center">
      <template v-for="(item, index) in tabs" :key="'tab-' + item.id">
          <v-divider
            v-if="index"
            vertical
            class="toolbar-divider"
            :key="'divider-' + item.id"
            color="background"
          ></v-divider>

          <v-btn
            size="x-small"
            class="tab mx-3 text--caption rounded"
            color="background"
            :variant="activeTab !== item.id ? 'text' : 'flat'"

            @click="$emit('change', item.id)"
          >{{item.title}}</v-btn>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    tabs: Array,
    activeTab: String
  }
}
</script>

<style scoped>
  .toolbar-divider {
    border-color: white !important;
  }
</style>
