<template>
  <div style="display: inline-block; cursor: pointer; white-space: nowrap;">
    <v-tooltip location="bottom" open-delay="1000">
      <template v-slot:activator="{ on, props, attrs }">
        <v-icon
          v-bind="props"
          v-on="{ ...on }"
          @click="$router.push('/dashboard/').catch(() => {})"
          size="small"
          class="ma-1"
          >custom:OrganisationIcon</v-icon
        >
        <span
          style="vertical-align: middle;"
          v-on="{ ...on }"
          v-bind="attrs"
          @click="$router.push('/dashboard/').catch(() => {})"
        >
          {{ project ? project.organisation?.name + '&nbsp;>&nbsp;' : 'Showing all organisations & all projects' }}
        </span>
      </template>
      Organisation, click here to see all projects
    </v-tooltip>

    <v-tooltip v-if="project" location="bottom" open-delay="1000">
      <template v-slot:activator="{ on, props, attrs }">
        <v-icon
          v-bind="props"
          v-on="{ ...on }"
          @click="$store.state.workflow.isViewOnlyEnabled ?
            $router
              .push(`/dashboard`)
              .catch() :
            $router
              .push(`/dashboard/${project.id}`)
              .catch(() => {})
          "
          size="small"
          class="ma-1"
        >custom:ProjectIcon
        </v-icon>
        <span
          v-bind="attrs"
          v-on="{ ...on }"
          @click="$store.state.workflow.isViewOnlyEnabled ?
            $router
              .push(`/dashboard`)
              .catch() :
            $router
              .push(`/dashboard/${project.id}`)
              .catch(() => {})
          "
        >
          {{ project.name }}
        </span>
      </template>
      Project, click here to see project dashboard
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      project: 'projectMemberships/activeProject'
    })
  }
}
</script>

<style>
.projects-list-menu {
  max-height: calc(100vh - 20px);
}

.v-tooltip__content {
  background: white;
  color: var(--color-primary);
  border-radius: 10px;
}
</style>
