<template>
  <div class="text-center">
    <v-btn v-if="isGenerating" class="bg-primary btn-stop-generating" @click="userStopGenerating()"><v-icon>mdi-stop</v-icon> Stop generating</v-btn>
    <disclaimer-dialog
      ref="disclaimerDialog"
      @conditions-accepted="onConditionsAccepted()"
    />
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-container class="w-100 pb-0">
          <v-row class="justify-space-between align-center">
            <v-col cols="10">
              <v-card-title>
                <v-icon class="ml-2">mdi-creation</v-icon>&nbsp;&nbsp;
                <span class="text-h5">Generate workflow with AI</span>
                <workflow-help-dialog-ai
                  #default="{ props }"
                  content-class="dialog-top-right"
                >
                  <v-btn variant="text" icon="mdi-help-circle-outline" v-bind="props" class="mr-2"></v-btn>
                </workflow-help-dialog-ai>
              </v-card-title>
            </v-col>
            <v-col cols="2" class="d-flex justify-end">
              <v-btn variant="text" icon color="grey" @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-card-text>
          <v-form ref="formGenerateDiagram" v-model="validForm">
            <v-container class="flex-wrap pa-0">
              <v-col cols="12" class="pa-0">
                <ai-description-input
                  id="ai-description-input"
                  :text="workflow.aiDescription"
                  @change-description="debouncedSaveAiDescription($event)"
                  label="Create a workflow containing ..."
                  :items="examples"
                  ref="aiDescription"
                  :persistent-hint="true"
                  hint="Continue the sentense 'Create a workflow containing ...'"
                  />
              </v-col>
              <v-checkbox
                :model-value="workflow.aiVerbPastTense"
                @update:model-value="saveAiVerbSetting()"
                label="Use past tense verbs to describe events"
                hide-details
              ></v-checkbox>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-text class="mt-0 mb-4 text-black">
          <v-card class="pa-2 mt-3">
            <v-container class="flex-wrap align-center pb-2">
              <v-col cols="12" class="text-caption font-weight-medium text-uppercase">
                For each step generate 0 - 3 example cards (choose 0 - 3).
                <workflow-help-dialog-cards />
              </v-col>
            </v-container>

            <template v-for="requirementType in requirementTypes" :key="requirementType.id">
              <v-container class="flex-wrap align-center py-2">
                <v-row class="mb-2">
                  <v-col cols="2" class="d-flex justify-center">
                    <v-select
                      class="card-ai-count"
                      :model-value="requirementType.aiCount || 0" variant="solo" :flat="true" hide-details density="compact"
                      :items="requirementType.aggregateType || requirementType.triggerType ? [0, 1] : [0, 1, 2, 3]"
                      @update:model-value="saveAiCount($event, requirementType)">
                    </v-select>
                  </v-col>
                  <v-col cols="2" class="d-flex justify-left">
                    <v-chip size="large" variant="elevated" :color="requirementType.color.rgb">
                      <strong>{{requirementType.title}}</strong>
                    </v-chip>
                  </v-col>
                  <v-col cols="8" class="type-title">
                    {{ requirementType.description }}
                  </v-col>
                </v-row>
              </v-container>
            </template>

          </v-card>
        </v-card-text>
        <v-card-text class="text-black">
          <v-card class="pa-2">
            <v-container class="flex-wrap align-center pb-2">
              <v-col cols="12" class="text-caption font-weight-medium text-uppercase">
                For each step generate 3 - 6 fields of example data.
                <workflow-help-dialog-example-data #default="{ props }">
                  <v-icon v-bind="props" size="small" class="ml-2"> mdi-help-circle-outline</v-icon>
                </workflow-help-dialog-example-data>
              </v-col>
            </v-container>
            <v-container class="flex-wrap align-center py-2">
              <v-row>
                <v-col cols="2" class="d-flex justify-center">
                  <v-select class="beam-ai-count"
                            v-model="dataFieldCount" :flat="true" variant="solo" hide-details density="compact"
                            :items="[0, 3, 4, 5, 6]" @update:model-value="saveBeamAiOptions">
                  </v-select>
                </v-col>
                <v-col cols="10" class="type-title">
                  Examples of <b>data fields</b> for this step
                </v-col>
              </v-row>
            </v-container>
          </v-card>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="text" @click="dialog = false">Cancel</v-btn>
          <v-btn id="test-generate-btn" color="primary" @click="generateDiagramFromText">{{
            "Generate workflow"
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showValidationDialog" max-width="600px">
      <v-card>
        <div class="d-flex justify-space-between pa-2 pb-0">
          <v-card-title>
            <span class="text-h5">Validation issue</span>
          </v-card-title>
          <v-btn variant="text" icon color="grey" @click="showValidationDialog = false">
            <v-icon >mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>{{validationMessage}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="text" @click="forceInvalidatedAction">Force generation</v-btn>
          <v-btn color="primary" variant="text" @click="openSettingsOrGenerator()">Edit description</v-btn>
          <v-btn color="primary" @click="setSuggestion">{{
              "Use suggestion"
            }}</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Checks from '@/helpers/checks'
import requirementTypesApi from '@/api/requirementTypesApi'
import workflowApi from '@/api/workflowApi'
import store from '@/store'
import WorkflowHelpDialogAi from '@/components/WorkflowHelpDialogAi'
import WorkflowHelpDialogExampleData from '@/components/WorkflowHelpDialogExampleData'
import WorkflowHelpDialogCards from '@/components/WorkflowHelpDialogCards'
import { chatGPT } from '@/api/chatGPT/index'
import AiDescriptionInput from '@/components/AiDescriptionInput.vue'
import { EventBus } from '@/event-bus/event-bus.js'
import { mapGetters, mapState } from 'vuex'
import DisclaimerDialog from '@/components/DisclaimerDialog.vue'
import debounce from '@/utils/debounce'
import { diagramHelpers } from '@/mixins/diagramHelpers'
import { ChatLog } from '@/api/chatGPT/chatLog'

export default {
  props: [
    'bpm',
    'events'
  ],
  components: {
    AiDescriptionInput,
    WorkflowHelpDialogAi,
    WorkflowHelpDialogExampleData,
    WorkflowHelpDialogCards,
    DisclaimerDialog
  },
  methods: {
    openSettingsOrGenerator () {
      this.showValidationDialog = false

      if (this.workflow.eventsJson && this.workflow.eventsJson.length > 0) {
        EventBus.$emit('open-settings', true)
        return
      }

      this.$emit('open-generator')
    },
    openSettings () {
      this.$refs.disclaimerDialog.openDialog()
    },
    onConditionsAccepted () {
      this.dialog = true
    },
    changeSelectedEvent: diagramHelpers.methods.changeSelectedEvent,
    async addReplyToDiagram (input) {
      if (!this.workflow.aiDescription) {
        this.$loadingState.stop()
        store.commit('snackbar/showMessage', { content: 'Please enter a description in settings: Get help from AI to build your workflow', timeout: 6000, color: 'red', centered: true }, { root: true })
        return
      }
      this.$loadingState.start()
      if (!await this.validatePrompt()) {
        this.forcedAction = this.prepareForcedAction(this.addReplyToDiagram, input)
        this.$loadingState.stop()
        return
      }
      const res = await chatGPT.fetchReplyToDiagram(input, this.bpm, this.requirementTypes)
      this.$loadingState.stop()
      await store.dispatch('workflow/addCardReply', {
        workflowId: input.workflowId,
        requirementId: input.requirementId,
        input: {
          text: res.message,
          generatedByAi: true
        }
      })
    },
    async saveAiCount (value, requirementType) {
      const payload = {
        aiCount: value
      }
      await requirementTypesApi.updateRequirementType(this.$route.params.id, requirementType, payload)
    },
    async saveAiVerbSetting () {
      await workflowApi.updateWorkflowNoHistoryMutation(
        this.$route.params.projectId,
        {
          id: this.$route.params.id,
          expectedVersion: this.workflow.version,
          aiVerbPastTense: !this.workflow.aiVerbPastTense
        }
      )
    },
    getTextColour (cardType) {
      const res = requirementTypesApi.getTextColour(cardType)
      return res
    },
    userStopGenerating () {
      this.isGenerating = false
      this.$loadingState.stop()
      chatGPT.abortFetch()
      store.commit('snackbar/showMessage', { content: 'Generation was stopped by the user', timeout: 3000, color: 'blue', centered: false }, { root: true })
    },
    generateDiagramFromText (validateForm = true) {
      if (validateForm && !this.workflow.aiDescription) {
        this.$store.commit('snackbar/showMessage', { content: 'Cannot generate workflow with empty prompt', timeout: 6000, color: 'red', centered: true }, { root: true })
        return
      }

      this.$nextTick(async () => {
        this.$loadingState.start('Validating the query...')
        if (!await this.validatePrompt()) {
          this.forcedAction = this.prepareForcedAction(this.generateDiagramFromText, validateForm)
          return
        }
        this.dialog = false
        this.isGenerating = true
        try {
          const chat = await this.createDiagram()
          await this.addCardsToEvents(chat)
          await this.addDataModelsToEvents(chat)
          this.$loadingState.stop()
          this.changeSelectedEvent(null)
        } catch (e) {
          if (e.name !== 'AbortError') {
            console.error(e)
          }
        }
        this.isGenerating = false
        this.$loadingState.stop()
      })
    },
    async forceInvalidatedAction () {
      try {
        this.forceWithoutValidation = true
        this.showValidationDialog = false
        await this.forcedAction()
      } catch (e) {
        console.log(e)
      } finally {
        this.forceWithoutValidation = false
      }
    },
    setSuggestion () {
      this.$nextTick(async () => {
        await this.saveAiDescription(this.suggestedAiDescription)
        this.showValidationDialog = false
        this.openSettingsOrGenerator()
      })
    },
    debouncedSaveAiDescription ({ description, skipDebounce }) {
      if (skipDebounce) {
        this.saveAiDescription(description)
      } else {
        this.saveAiDescriptionDebouncer(description)
      }
    },
    async saveAiDescription (e) {
      await workflowApi.updateWorkflowNoHistoryMutation(
        this.$route.params.projectId,
        {
          id: this.$route.params.id,
          expectedVersion: this.workflow.version,
          aiDescription: e
        }
      )
    },
    saveBeamAiOptions () {
      workflowApi.updateWorkflowNoHistoryMutation(
        this.$route.params.projectId,
        {
          id: this.$route.params.id,
          expectedVersion: this.workflow.version,
          beamAiOptions: {
            dataFieldCount: this.dataFieldCount
          }
        }
      )
    },
    addShapeToDiagram (parentId) {
      this.$loadingState.start()
      this.$nextTick(async () => {
        try {
          await this.addShape(parentId, this.bpm, this.requirementTypes)
        } catch (e) {
          console.error(e)
          this.$loadingState.stop()
        }
      })
    },
    addCardToDiagram (eventId, cardTypeId) {
      this.$loadingState.start()
      this.$nextTick(async () => {
        try {
          await this.addCard(eventId, cardTypeId)
        } catch (e) {
          console.error(e)
          this.$loadingState.stop()
        }
      })
    },
    async cancellableRequest (promptFunction, ...args) {
      this.isGenerating = true
      const result = await promptFunction(...args)
      if (this.isGenerating) {
        this.isGenerating = false
        return result
      } else {
        return null
      }
    },
    async addShape (parentId, bpm, requirementTypes) {
      if (!this.workflow.aiDescription) {
        this.$loadingState.stop()
        store.commit('snackbar/showMessage', { content: 'Please enter a description in settings: Get help from AI to build your workflow', timeout: 6000, color: 'red', centered: true }, { root: true })
        return
      }
      this.$loadingState.start()
      if (!await this.validatePrompt()) {
        this.forcedAction = this.prepareForcedAction(this.addShape, parentId, bpm, requirementTypes)
        return
      }

      const step = await this.cancellableRequest(chatGPT.fetchShape, parentId, bpm, requirementTypes)
      if (step) {
        try {
          await this.runStep('create-shape', { type: '1', taskDescription: step.description, laneDescription: step.role })
          for (let i = 0; i < requirementTypes.length; i++) {
            if (requirementTypes[i].aiCount > 0) {
              const requirementType = requirementTypes[i].title.replace(/\s+/g, '-').toLowerCase()
              if (step[requirementType]) {
                for (const cardText of step[requirementType]) {
                  await this.runStep('add-card', { cardTypeName: requirementTypes[i].title, cardText })
                }
              }
            }
          }
          await this.addDataModels(step)
          store.commit('snackbar/showMessage', { content: 'Completed', timeout: 3000, color: 'blue', centered: false }, { root: true })
        } catch (error) {
          console.error(error)
          store.commit('snackbar/showMessage', { content: 'Error adding', timeout: 6000, color: 'red', centered: true }, { root: true })
        }
      }
      this.$loadingState.stop()
    },
    async addEventStory (parentId) {
      if (!this.workflow.aiDescription) {
        this.$loadingState.stop()
        store.commit('snackbar/showMessage', { content: 'Please enter a description in settings: Get help from AI to build your workflow', timeout: 6000, color: 'red', centered: true }, { root: true })
        return
      }
      this.$loadingState.start()
      if (!await this.validatePrompt()) {
        this.forcedAction = this.prepareForcedAction(this.addEventStory, parentId)
        return
      }

      const step = await this.cancellableRequest(chatGPT.fetchEventStory, this.$route.params.eventId, this.bpm, this.requirementTypes)
      if (step) {
        try {
          this.addDataModels(step)
          store.commit('snackbar/showMessage', { content: 'Completed', timeout: 3000, color: 'blue', centered: false }, { root: true })
        } catch (error) {
          console.error(error)
          store.commit('snackbar/showMessage', { content: 'Error adding', timeout: 6000, color: 'red', centered: true }, { root: true })
        }
      }
      this.$loadingState.stop()
    },
    async addFactTable (parentId) {
      if (!this.workflow.aiDescription) {
        this.$loadingState.stop()
        store.commit('snackbar/showMessage', { content: 'Please enter a description in settings: Get help from AI to build your workflow', timeout: 6000, color: 'red', centered: true }, { root: true })
        return
      }
      this.$loadingState.start()
      if (!await this.validatePrompt()) {
        this.forcedAction = this.prepareForcedAction(this.addFactTable, parentId)
        return
      }
      const step = await this.cancellableRequest(chatGPT.fetchFactTable, this.$route.params.eventId, this.bpm, this.requirementTypes)
      if (step) {
        try {
          this.addDataModels({ dataFields: step.factTable, role: step.roleName, description: step.description, type: 'Fact Table' })
          store.commit('snackbar/showMessage', { content: 'Completed', timeout: 3000, color: 'blue', centered: false }, { root: true })
        } catch (error) {
          console.error(error)
          store.commit('snackbar/showMessage', { content: 'Error adding', timeout: 6000, color: 'red', centered: true }, { root: true })
        }
      }
      this.$loadingState.stop()
    },
    async addCard (eventId, cardTypeId) {
      if (!this.workflow.aiDescription) {
        this.$loadingState.stop()
        store.commit('snackbar/showMessage', { content: 'Please enter a description in settings: Get help from AI to build your workflow', timeout: 6000, color: 'red', centered: true }, { root: true })
        return
      }
      if (!await this.validatePrompt()) {
        this.forcedAction = this.prepareForcedAction(this.addCard, eventId, cardTypeId)
        return
      }

      const obj = await this.cancellableRequest(chatGPT.fetchCard, eventId, this.workflow, this.bpm.svgGraph, this.bpm.svgLanes, cardTypeId, this.requirementTypes)
      if (obj) {
        try {
          const index = this.requirementTypes.findIndex(requirementType => requirementType.id === cardTypeId)
          if (this.requirementTypes[index].title.replace(/\s+/g, '-').toLowerCase() === Object.keys(obj)[0]) {
            await this.runStep('add-card', { cardTypeName: this.requirementTypes[index].title, cardText: Object.values(obj)[0], eventId })
          }
          store.commit('snackbar/showMessage', { content: 'Completed', timeout: 3000, color: 'blue', centered: false }, { root: true })
        } catch (error) {
          console.error(error)
          store.commit('snackbar/showMessage', { content: 'Error adding', timeout: 6000, color: 'red', centered: true }, { root: true })
        }
      }
      this.$loadingState.stop()
    },
    async createDiagram () {
      if (!this.isGenerating) return
      this.$loadingState.start('Creating events...')
      this.chat = new ChatLog()
      await chatGPT.fetchNewDiagram(this.chat, this.workflow, this.requirementTypes, this.addEvents)
      if (this.isGenerating) store.commit('snackbar/showMessage', { content: 'Added all events', timeout: 3000, color: 'blue', centered: false }, { root: true })
      return this.chat
    },
    async addCardsToEvents (chat) {
      if (!this.isGenerating) return
      if (!this.requirementTypes.filter(r => r.aiCount).length) return
      this.$loadingState.start('Adding cards to events...')
      await chatGPT.fetchAllCards(chat, this.workflow, this.requirementTypes, this.addCards)
      if (this.isGenerating) store.commit('snackbar/showMessage', { content: 'Added all corresponding cards', timeout: 3000, color: 'blue', centered: false }, { root: true })
    },
    async addDataModelsToEvents (chat) {
      if (!this.isGenerating) return
      if (!this.workflow.beamAiOptions?.dataFieldCount) return
      this.$loadingState.start('Adding data models to events...')
      await chatGPT.fetchAllDataModels(chat, this.workflow, this.requirementTypes, this.addDataModels)
      if (this.isGenerating) store.commit('snackbar/showMessage', { content: 'Added all corresponding data models', timeout: 3000, color: 'blue', centered: false }, { root: true })
    },
    getEventIdFromEventObj (obj) {
      const cardLaneId = Object.values(this.bpm.svgLanes).find(lane => lane.name === obj.role).id
      const eventId = this.workflow.eventsJson.find(event => event.description === obj.description && event.laneId === cardLaneId).id
      return eventId
    },
    updateRouteWithEventId (eventId) {
      this.$router.push({ path: '/workflow/' + this.$route.params.projectId + '/' + this.workflow.id + '/' + eventId }).catch(() => { })
    },
    async addEvents (eventObject) {
      await this.runStep('create-shape', {
        type: '1',
        taskDescription: eventObject.description,
        laneDescription: eventObject.role
      })
    },
    async addCards (obj) {
      const eventId = this.getEventIdFromEventObj(obj)
      for (let i = 0; i < this.requirementTypes?.length; i++) {
        if (this.requirementTypes[i].aiCount > 0) {
          const requirementType = this.requirementTypes[i].title.replace(/\s+/g, '-').toLowerCase()
          if (!Array.isArray(obj[requirementType])) {
            obj[requirementType] = [obj[requirementType]]
          }
          for (const cardText of obj[requirementType]) {
            this.updateRouteWithEventId(eventId)
            await this.runStep('add-card', { cardTypeName: this.requirementTypes[i].title, cardText, eventId })
          }
        }
      }
    },
    async addDataModels (obj) {
      const eventId = this.getEventIdFromEventObj(obj)
      if (obj.dataFields?.length > 0) {
        this.updateRouteWithEventId(eventId)
        await this.runStep('add-data-model', { example_data: obj.dataFields, taskDescription: obj.description, laneDescription: obj.role, type: obj.type, eventId })
      }
    },
    async runStep (operation, args = null) {
      const delay = 300
      let oldVersion = this.workflow.version
      switch (operation) {
        case 'create-shape':
          await this.bpm.addShape({
            description: args.taskDescription
          }, {
            laneName: args.laneDescription,
            createNewLaneIfNoLaneId: true
          })
          await this.ensureNextVersion(oldVersion)
          break
        case 'add-card': {
          const index = this.requirementTypes.findIndex(type => type.title === args.cardTypeName)
          if (index >= 0) {
            const cardTypeId = this.requirementTypes[index].id
            const eventId = args.eventId || this.$route.params.eventId
            await workflowApi.createRequirementJson(this.$route.params.id, eventId, {
              cardTypeId,
              description: args.cardText
            })
            await this.ensureNextVersion(oldVersion)
          }
          break
        }
        case 'add-data-model': {
          const name = args.taskDescription + ' by ' + args.laneDescription
          const dataFields = []
          for (const example of args.example_data) {
            dataFields.push({
              verb: example.connectingWord,
              name: example.fieldName.toUpperCase(),
              type: `[${example.category}]`,
              description: '',
              fieldName: '',
              tableName: '',
              systemName: '',
              tags: example.tags,
              exampleData: example.exampleData
            })
          }
          const eventId = args.eventId || this.$route.params.eventId
          const event = this.workflow?.eventsJson?.find(wt => wt.id === eventId)
          const existingDataModels = event?.dataModels || []

          const dataModels = []
          if (existingDataModels) dataModels.push(...existingDataModels)
          dataModels.push({
            name,
            type: args.type || 'Event Story',
            dataFields
          })
          await workflowApi.updateDataModels({
            eventId,
            dataModelsOnEvent: dataModels,
            workflow: this.workflow
          })
          await this.ensureNextVersion(oldVersion)
          oldVersion = this.workflow.version
          break
        }
      }
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const { errors, errorList } = Checks.checkDiagramConsistency(this.bpm.svgGraph, this.bpm.svgLanes, this.bpm.svgGroups, this.bpm.events)
          if (errors === 0) {
            resolve()
          } else {
            this.testResults = errorList
            reject(new Error('Failed with ' + errors + ' failed tests. ' + errorList.toString()))
          }
        }, delay)
      })
    },
    async ensureNextVersion (oldVersion) {
      while (oldVersion === this.workflow.version) {
        await this.wait100ms()
      }
    },
    async wait100ms () {
      return new Promise((resolve) => {
        setTimeout(() => { resolve() }, 100)
      })
    },
    async validatePrompt () {
      if (this.forceWithoutValidation === false) {
        const res = await chatGPT.validatePrompt(this.workflow, this.bpm)
        if (res?.answer === 'yes') {
          return true
        } else if (res?.answer === 'no') {
          this.$loadingState.stop()
          this.showValidationDialog = true
          let message = 'Sorry, can\'t understand the description: "' + this.workflow.aiDescription + '".'
          if (res['did you mean']) {
            message += ' Did you mean: "' + res['did you mean'].replace('Create a workflow containing ', '') + '"' + '?'
          }
          this.suggestedAiDescription = res['did you mean'].replace('Create a workflow containing ', '')
          this.validationMessage = message
          return false
        } else {
          return false
        }
      }
      return true
    },
    prepareForcedAction (fn, ...args) {
      // Prepares the function to be triggered on Force Generate button
      // Since prompt validation is used in multiple places, we need to dynamically pick which ChatGPT action we want to
      // trigger. Because some of them have different function parameters, this is the JS way of preparing such
      // functions to call them later
      return async function () {
        return await fn(...args)
      }
    }
  },
  data () {
    return {
      validForm: true,
      dialog: false,
      dataFieldCount: 0,
      testResults: [],
      chatLimitRoleLength: 'Keep the role name to max 16 characters.',
      chatLimitDescriptionLength: 'Keep the description to one sentence and max 40 characters.',
      suggestedAiDescription: '',
      forceWithoutValidation: false,
      showValidationDialog: false,
      validationMessage: '',
      forcedAction: () => {},
      saveAiDescriptionDebouncer: debounce(e => { this.saveAiDescription(e) }, 1000),
      isGenerating: false,
      chat: null
    }
  },
  computed: {
    ...mapState({
      workflow: state => state.workflow.workflow,
      requirementTypes: state => state.requirementTypes.requirementTypes
    }),
    examples () {
      return chatGPT.getExamples()
    },
    ...mapGetters({
      loadingState: 'loading/getLoadingState'
    })
  },
  watch: {
    workflow: {
      handler (newVal) {
        if (newVal?.beamAiOptions && newVal.beamAiOptions.dataFieldCount !== this.dataFieldCount) {
          this.dataFieldCount = newVal.beamAiOptions.dataFieldCount
        }
      },
      deep: true
    },
    isGenerating (newVal) {
      if (!newVal && this.chat) {
        this.chat.stopStreaming()
      }
    }
  },
  mounted () {
    EventBus.$on('generate-diagram', async (workflowId) => {
      if (!this.loadingState && (this.workflow.id === this.$route.params.id)) {
        this.$loadingState.start()
        this.generateDiagramFromText(false)
      }
    })
  }
}
</script>

<style>
.generate-button {
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none !important;
}
</style>

<style scoped>
.btn-stop-generating {
  position: fixed;
  bottom: 64px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000000;
}
</style>
