import { apolloClient } from '@/vue-apollo'
import GetWorkflow from '@/graphql/queries/GetWorkflow'
import OnCreateOrUpdateWorkflow from '@/graphql/subscriptions/OnCreateOrUpdateWorkflow'
import OnDeleteWorkflow from '@/graphql/subscriptions/OnDeleteWorkflow'
import OnAddRequirementComment from '@/graphql/subscriptions/OnAddRequirementComment'
import OnUpdateRequirementComment from '@/graphql/subscriptions/OnUpdateRequirementComment'
import OnDeleteRequirementComment from '@/graphql/subscriptions/OnDeleteRequirementComment'
import OnUpdateUserStatusWorkflow from '@/graphql/subscriptions/OnUpdateUserStatusWorkflow'
import OnToggleCardReaction from '@/graphql/subscriptions/OnToggleCardReaction'
import OnCreateRequirementAttachment from '@/graphql/subscriptions/OnCreateRequirementAttachment'
import OnDeleteRequirementAttachment from '@/graphql/subscriptions/OnDeleteRequirementAttachment'
import OnUpdateRequirementsJson from '@/graphql/subscriptions/OnUpdateRequirementsJson'
import OnUpdateUserStatus from '@/graphql/subscriptions/OnUpdateUserStatus'
import UpdateWorkflow from '@/graphql/mutations/UpdateWorkflow'
import UpdateWorkflowNoHistory from '@/graphql/mutations/UpdateWorkflowNoHistory'
import UpdateRequirementsJson from '@/graphql/mutations/UpdateRequirementsJson'
import MoveRequirementJson from '@/graphql/mutations/MoveRequirementJson'
import DeleteRequirementJson from '@/graphql/mutations/DeleteRequirementJson'
import RestorePreviousWorkflowVersion from '@/graphql/mutations/RestorePreviousWorkflowVersion'
import AddRequirementComment from '@/graphql/mutations/AddRequirementComment'
import UpdateRequirementComment from '@/graphql/mutations/UpdateRequirementComment'
import DeleteRequirementComment from '@/graphql/mutations/DeleteRequirementComment'
import ToggleRequirementReaction from '@/graphql/mutations/ToggleRequirementReaction'
import CreateRequirementAttachment from '@/graphql/mutations/CreateRequirementAttachment'
import DeleteRequirementAttachment from '@/graphql/mutations/DeleteRequirementAttachment'
import UpdateUserStatus from '@/graphql/mutations/UpdateUserStatus'
import UpdateUserStatusWorkflow from '@/graphql/mutations/UpdateUserStatusWorkflow'
import { EventBus } from '@/event-bus/event-bus.js'
import client from '@/graphql/client'

export const workflow = {
  namespaced: true,
  state: {
    workflow: null,
    loading: false,
    showReleaseNo: null,
    subscriptions: null,
    isViewOnlyEnabled: false
  },
  mutations: {
    setWorkflow (state, payload) {
      state.workflow = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
    setShowReleaseNo (state, payload) {
      state.showReleaseNo = payload
    },
    updateWorkflow (state, payload) {
      // ignore updates comming from other workflows
      if (state.workflow?.id !== payload.id) return

      // payload.sameAsVersion is used when restoring a previous version
      if (payload.eventsJson && !payload.sameAsVersion) {
        if (!state.workflow.eventsJson) {
          state.workflow.eventsJson = []
        }

        for (const event of payload.eventsJson) {
          if (event.type === 'deleted') {
            state.workflow.eventsJson = state.workflow.eventsJson.filter(it => it.id !== event.id)
            continue
          }

          const existingEvent = state.workflow.eventsJson.find(it => it.id === event.id)
          if (existingEvent) {
            if (existingEvent.version < event.version) {
              event.requirementsJson = event.requirementsJson !== null ? event.requirementsJson : existingEvent.requirementsJson
              event.dataModels = event.dataModels !== null ? event.dataModels : existingEvent.dataModels
              Object.assign(existingEvent, event)
            }
          } else {
            state.workflow.eventsJson.push(event)
          }
        }
        delete payload.eventsJson
      }

      if (state.workflow && ((state.workflow.version < payload.version) || (payload.sameAsVersion !== state.workflow.sameAsVersion))) {
        Object.assign(state.workflow || {}, payload)
      }
    },
    updateWorkflowAndAcceptSameVersion (state, payload) {
      if (!state.workflow || state.workflow.version <= payload.version) {
        Object.assign(state.workflow || {}, payload)
      }
    },
    deleteWorkflow (state, payload) {
      if (payload.id === state.workflow?.id) {
        state.workflow = null
      }
    },
    addOrUpdateCardReply (state, payload) {
      const requirement = findRequirementInWorkflow(payload.requirementId, state.workflow)
      const index = requirement.comments.findIndex(comment => comment.id === payload.id)
      if (index >= 0) {
        Object.assign(requirement.comments[index], payload)
      } else {
        requirement.comments.push(payload)
      }
    },
    async updateCards (state, { payload, rootState }) {
      const workflow = state.workflow
      for (const requirement of payload.requirements) {
        const requirementId = requirement.requirementId
        const eventId = requirement.eventId
        delete requirement.requirementId
        delete requirement.requirementIndex
        delete requirement.eventId
        delete requirement.eventIndex

        const eventIndex = workflow.eventsJson.findIndex(event => event.id === eventId)
        if (eventIndex >= 0) {
          const event = workflow.eventsJson[eventIndex]
          if (!event.requirementsJson) {
            event.requirementsJson = []
          }

          let requirementIndex = event.requirementsJson.findIndex(req => req.id === requirementId)
          if (requirementIndex === -1) {
            requirementIndex = event.requirementsJson.length

            // When creating a new card we need all required fields from Requirement type.
            // But UpdateRequirementJsonRequirementOutput does not give us all required fields
            // so we need to add them manually.
            requirement.id = requirementId
            requirement.attachments = []
            requirement.comments = []
            requirement.reactions = []

            // Add dummy card with version 0 which will be overwritten below
            const newRequirement = { ...requirement }
            newRequirement.version = 0
            event.requirementsJson.push(newRequirement)
          }

          // event.requirementsJson[requirementIndex].version += 5
          // accept old version === null for backward compatibility
          if ((requirement.version > event.requirementsJson[requirementIndex].version) || event.requirementsJson[requirementIndex].version === null) {
            Object.assign(event.requirementsJson[requirementIndex], requirement)
            const oldCardTypeId = event.requirementsJson[requirementIndex].cardType?.id
            if (oldCardTypeId !== requirement.cardTypeId) {
              const allCardTypes = rootState.requirementTypes.requirementTypes
              const newCardType = allCardTypes.find(it => it.id === requirement.cardTypeId)
              event.requirementsJson[requirementIndex].cardType = newCardType
            }
          }
        }
      }

      // Check workflow version
      if (payload.version > workflow.version) {
        workflow.updatedAt = payload.updatedAt
        workflow.updateNote = payload.updateNote
        workflow.version = payload.version
      }
    },
    deleteCardReply (state, payload) {
      const requirement = findRequirementInWorkflow(payload.requirementId, state.workflow)
      const index = requirement.comments.findIndex(comment => comment.id === payload.id)
      if (index >= 0) {
        requirement.comments.splice(index, 1)
      }
    },
    updateUserStatus (state, { payload, rootState }) {
      const index = state.workflow.eventsJson.findIndex(event => event.id === payload.eventId)
      if (index >= 0) {
        const index2 = state.workflow.eventsJson[index].requirementsJson.findIndex(req => req.id === payload.userStatus.requirementId)
        if (index2 >= 0) {
          const index3 = state.workflow.eventsJson[index].requirementsJson[index2].requirementStatuses?.findIndex(requirementStatus => requirementStatus.userId === payload.userStatus.userId)
          if (index3 >= 0) {
            state.workflow.eventsJson[index].requirementsJson[index2].requirementStatuses[index3] = payload.userStatus
            if (payload.userStatus.userId === rootState.userId) {
              state.workflow.eventsJson[index].requirementsJson[index2].status = payload.userStatus
            }
          } else {
            if (!state.workflow.eventsJson[index].requirementsJson[index2].requirementStatuses) {
              state.workflow.eventsJson[index].requirementsJson[index2].requirementStatuses = []
            }
            state.workflow.eventsJson[index].requirementsJson[index2].requirementStatuses.push(payload.userStatus)
            if (payload.userStatus.userId === rootState.userId) {
              state.workflow.eventsJson[index].requirementsJson[index2].status = payload.userStatus
            }
          }
        }
      }
    },
    updateUserStausWorkflow (state, payload) {
      const index = state.workflow.userStatusesWorkflow.findIndex(us => us.userId === payload.userId)
      if (index >= 0) {
        state.workflow.userStatusesWorkflow[index] = payload
      } else {
        state.workflow.userStatusesWorkflow.push(payload)
      }
    },
    updateCardReaction (state, payload) {
      for (const event of state.workflow.eventsJson) {
        const card = event.requirementsJson?.find(({ id }) => id === payload.requirementId)
        if (!card) continue
        const reactionIndex = card.reactions.findIndex(({ user }) => user.id === payload.user.id)
        if (reactionIndex === -1) {
          card.reactions.push(payload)
          break
        }
        const reaction = card.reactions[reactionIndex]
        if (reaction.direction === payload.direction) {
          card.reactions.splice(reactionIndex, 1) // delete reaction
        } else {
          card.reactions.splice(reactionIndex, 1, payload)
        }
      }
    },
    addRequirementAttachment (state, payload) {
      const { requirementId, ...attachment } = payload
      const requirement = findRequirementInWorkflow(requirementId, state.workflow)
      if (requirement) {
        requirement.attachments.push(attachment)
      }
    },
    deleteRequirementAttachment (state, payload) {
      const { requirementId, ...attachment } = payload
      const requirement = findRequirementInWorkflow(requirementId, state.workflow)
      if (requirement) {
        const attachmentIndex = requirement.attachments.findIndex(existingAttachment => existingAttachment.id === attachment.id)
        if (attachmentIndex >= 0) {
          requirement.attachments.splice(attachmentIndex, 1)
        }
      }
    },
    setSubscriptions (state, payload) {
      state.subscriptions = payload
    },
    unsubscribeFromAll (state) {
      if (state.subscriptions) {
        for (const subscription of state.subscriptions) {
          subscription.unsubscribe()
        }
      }
    },
    setViewOnlyMode (state, data) {
      state.isViewOnlyEnabled = data
    }
  },
  actions: {
    async updateWorkflow ({ commit, dispatch, getters }, { projectId, input }) {
      try {
        const res = await client.query({
          operationName: 'updateWorkflow',
          query: UpdateWorkflow,
          variables: {
            projectId,
            input
          }
        })
        commit('updateWorkflow', res.updateWorkflow)
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
        return res
      } catch (e) {
        showErrorMessageAndResetDiagram({ workflowId: input.id, projectId }, e, commit, dispatch)
      }
    },

    async updateWorkflowNoHistory ({ commit, dispatch, getters }, { projectId, input }) {
      try {
        const res = await apolloClient.mutate({
          mutation: UpdateWorkflowNoHistory,
          variables: {
            projectId,
            input
          },
          fetchPolicy: 'no-cache'
        })
        commit('updateWorkflowAndAcceptSameVersion', res.data.updateWorkflowNoHistory)
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
        return res
      } catch (e) {
        showErrorMessageAndResetDiagram({ workflowId: input.id, projectId }, e, commit, dispatch)
      }
    },

    async restorePreviousWorkflowVersion ({ commit, dispatch, getters }, { restoreVersion, workflow }) {
      try {
        const res = await apolloClient.mutate({
          mutation: RestorePreviousWorkflowVersion,
          variables: {
            projectId: workflow.projectId,
            input: {
              id: workflow.id,
              expectedVersion: workflow.version,
              restorePreviousWorkflowVersion: restoreVersion,
              sameAsVersion: workflow.sameAsVersion
            }
          },
          fetchPolicy: 'no-cache'
        })
        commit('updateWorkflow', res.data.restorePreviousWorkflowVersion)
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
      } catch (e) {
        showErrorMessageAndResetDiagram({ workflowId: workflow.id, projectId: workflow.projectId }, e, commit, dispatch)
      }
    },

    async updateCards ({ commit, state, rootState, dispatch }, { input }) {
      try {
        const res = await client.query({
          operationName: 'updateRequirementsJson',
          query: UpdateRequirementsJson,
          variables: {
            projectId: state.workflow.projectId,
            input
          }
        })
        commit('updateCards', { payload: res.updateRequirementsJson, rootState })
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
        return res.updateRequirementsJson
      } catch (e) {
        showErrorMessageAndResetDiagram({ workflowId: input.workflowId, projectId: state.workflow.projectId }, e, commit, dispatch)
      }
    },

    async moveCard ({ commit, state, rootGetters, dispatch }, { input }) {
      try {
        const res = await apolloClient.mutate({
          mutation: MoveRequirementJson,
          variables: {
            projectId: state.workflow.projectId,
            input
          },
          fetchPolicy: 'no-cache'
        })
        const workflow = res.data.moveRequirementJson
        workflow.sameAsVersion = 1 // trigger full workflow update
        commit('updateWorkflow', workflow)
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
      } catch (e) {
        showErrorMessageAndResetDiagram({ workflowId: input.workflowId, projectId: state.workflow.projectId }, e, commit, dispatch)
      }
    },

    async deleteCard ({ commit, state, rootGetters, dispatch }, { input }) {
      try {
        const res = await apolloClient.mutate({
          mutation: DeleteRequirementJson,
          variables: input,
          fetchPolicy: 'no-cache'
        })
        commit('updateWorkflow', res.data.deleteRequirementJson)
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
      } catch (e) {
        showErrorMessageAndResetDiagram({ workflowId: input.workflowId, projectId: state.workflow.projectId }, e, commit, dispatch)
      }
    },

    async addCardReply ({ commit, state, rootGetters, dispatch }, { workflowId, requirementId, input }) {
      try {
        const res = await apolloClient.mutate({
          mutation: AddRequirementComment,
          variables: {
            workflowId,
            requirementId,
            input
          },
          fetchPolicy: 'no-cache'
        })
        commit('addOrUpdateCardReply', res.data.addRequirementComment)
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
      } catch (e) {
        console.log(e)
        commit('snackbar/showMessage', { content: 'Error saving, try again!', timeout: 6000, color: 'red', centered: true }, { root: true })
      }
    },

    async updateCardReply ({ commit, state, rootGetters, dispatch }, { workflowId, requirementId, id, input }) {
      try {
        const res = await apolloClient.mutate({
          mutation: UpdateRequirementComment,
          variables: {
            workflowId,
            requirementId,
            id,
            input
          },
          fetchPolicy: 'no-cache'
        })
        commit('addOrUpdateCardReply', res.data.updateRequirementComment)
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
      } catch (e) {
        console.log(e)
        commit('snackbar/showMessage', { content: 'Error saving, try again!', timeout: 6000, color: 'red', centered: true }, { root: true })
      }
    },

    async deleteCardReply ({ commit, state, rootGetters, dispatch }, { workflowId, requirementId, id }) {
      try {
        await apolloClient.mutate({
          mutation: DeleteRequirementComment,
          variables: {
            workflowId,
            requirementId,
            id
          },
          fetchPolicy: 'no-cache'
        })
        // update on subscription
      } catch (e) {
        console.log(e)
        commit('snackbar/showMessage', { content: 'Error saving, try again!', timeout: 6000, color: 'red', centered: true }, { root: true })
      }
    },

    async toggleCardReaction ({ commit, state, rootGetters, dispatch }, { workflowId, input }) {
      try {
        await apolloClient.mutate({
          mutation: ToggleRequirementReaction,
          variables: {
            workflowId,
            input
          },
          fetchPolicy: 'no-cache'
        })
        // update on subscription
      } catch (e) {
        console.log(e)
        commit('snackbar/showMessage', { content: 'Error saving, try again!', timeout: 6000, color: 'red', centered: true }, { root: true })
      }
    },

    async createCardAttachment ({ commit, state, rootGetters, dispatch }, { workflowId, input }) {
      try {
        await apolloClient.mutate({
          mutation: CreateRequirementAttachment,
          variables: {
            workflowId,
            input
          },
          fetchPolicy: 'no-cache'
        })
        // update on subscription
        commit('snackbar/showMessage', { content: 'File uploaded' }, { root: true })
      } catch (e) {
        console.log(e)
        commit('snackbar/showMessage', { content: 'Error, try again!', timeout: 6000, color: 'red', centered: true }, { root: true })
      }
    },

    async deleteCardAttachment ({ commit, state, rootGetters, dispatch }, { workflowId, id }) {
      try {
        await apolloClient.mutate({
          mutation: DeleteRequirementAttachment,
          variables: {
            workflowId,
            id
          },
          fetchPolicy: 'no-cache'
        })
        // update on subscription
      } catch (e) {
        console.log(e)
        commit('snackbar/showMessage', { content: 'Error, try again!', timeout: 6000, color: 'red', centered: true }, { root: true })
      }
    },

    async updateUserStatus ({ commit, state, rootGetters, dispatch }, input) {
      try {
        await apolloClient.mutate({
          mutation: UpdateUserStatus,
          variables: {
            ...input
          },
          fetchPolicy: 'no-cache'
        })
        // update on subscription
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
      } catch (e) {
        console.log(e)
        commit('snackbar/showMessage', { content: 'Error saving, try again!', timeout: 6000, color: 'red', centered: true }, { root: true })
      }
    },

    async updateUserStatusWorkflow ({ commit, state, rootGetters, dispatch }, input) {
      try {
        await apolloClient.mutate({
          mutation: UpdateUserStatusWorkflow,
          variables: {
            ...input
          },
          fetchPolicy: 'no-cache'
        })
        // update on subscription
        commit('snackbar/showMessage', { content: 'Updated' }, { root: true })
      } catch (e) {
        console.log(e)
        commit('snackbar/showMessage', { content: 'Error saving, try again!', timeout: 6000, color: 'red', centered: true }, { root: true })
      }
    },

    async getWorkflow ({ commit, state, rootState, dispatch }, params) {
      const res = await apolloClient.query({
        query: GetWorkflow,
        variables: {
          workflowId: params.id,
          projectId: params.projectId
        },
        fetchPolicy: 'no-cache'
      })
      commit('setWorkflow', res.data.getWorkflow)

      // set view only mode
      if (rootState.isUserLoggedIn) {
        // logged in user
        if (rootState.adminUser) {
          commit('setViewOnlyMode', false)
        } else {
          const viewOnly = res.data.getWorkflow?.teamMembers?.some(e => e.userId === rootState.userId) === false
          commit('setViewOnlyMode', viewOnly)
        }
      } else {
        // not logged in user
        commit('setViewOnlyMode', true)
      }
      commit('unsubscribeFromAll')
      if (state.isViewOnlyEnabled) {
        // dont' subscribe if user is not logged in
        return
      }
      const subscriptions = []
      const addToSubscriptions = (query, variables, callback) => {
        const sub = apolloClient.subscribe({
          query,
          variables,
          fetchPolicy: 'no-cache'
        }).subscribe({
          next (res) {
            callback(res.data)
          },
          error (error) {
            console.error('Subscription error:', error)
          }
        })
        subscriptions.push(sub)
      }

      addToSubscriptions(OnCreateOrUpdateWorkflow, { projectId: params.projectId }, (data) => {
        commit('updateWorkflow', data.onCreateOrUpdateWorkflow)
        commit('setWsMessageForCypress', { version: data.onCreateOrUpdateWorkflow.version }, { root: true })
      })

      addToSubscriptions(OnDeleteWorkflow, { projectId: params.projectId }, (data) => {
        commit('deleteWorkflow', data.onDeleteWorkflow.deleteWorkflow)
      })

      addToSubscriptions(OnAddRequirementComment, { workflowId: params.id }, (data) => {
        commit('addOrUpdateCardReply', data.onAddRequirementComment)
      })

      addToSubscriptions(OnUpdateRequirementComment, { workflowId: params.id }, (data) => {
        commit('addOrUpdateCardReply', data.onUpdateRequirementComment)
      })

      addToSubscriptions(OnDeleteRequirementComment, { workflowId: params.id }, (data) => {
        commit('deleteCardReply', data.onDeleteRequirementComment)
      })

      addToSubscriptions(OnUpdateUserStatusWorkflow, { workflowId: params.id }, (data) => {
        commit('updateUserStausWorkflow', data.onUpdateUserStatusWorkflow)
      })

      addToSubscriptions(OnToggleCardReaction, { workflowId: params.id }, (data) => {
        commit('updateCardReaction', data.onToggleCardReaction)
      })

      addToSubscriptions(OnCreateRequirementAttachment, { workflowId: params.id }, (data) => {
        commit('addRequirementAttachment', data.onCreateRequirementAttachment)
      })

      addToSubscriptions(OnDeleteRequirementAttachment, { workflowId: params.id }, (data) => {
        commit('deleteRequirementAttachment', data.onDeleteRequirementAttachment)
      })

      addToSubscriptions(OnUpdateRequirementsJson, { workflowId: params.id }, (data) => {
        commit('updateCards', { payload: data.onUpdateRequirementsJson, rootState })
        commit('setWsMessageForCypress', { version: data.onUpdateRequirementsJson.version }, { root: true })
      })

      addToSubscriptions(OnUpdateUserStatus, { workflowId: params.id }, (data) => {
        commit('updateUserStatus', { payload: data.onUpdateUserStatus, rootState })
      })

      commit('setSubscriptions', subscriptions)
    }
  },
  getters: {
    getLoadingState: (state) => state.loading
  }
}

const findRequirementInWorkflow = (requirementId, workflow) => {
  let allRequirements = []
  workflow.eventsJson.forEach(({ requirementsJson }) => {
    if (requirementsJson) {
      allRequirements = allRequirements.concat(requirementsJson)
    }
  })
  const requirement = allRequirements.find(({ id }) => id === requirementId)
  return requirement
}

const showErrorMessageAndResetDiagram = ({ workflowId, projectId }, e, commit, dispatch) => {
  commit('snackbar/showMessage', { content: 'Error saving, try again!', timeout: 6000, color: 'red', centered: true }, { root: true })
  console.error(e)
  dispatch('getWorkflow', { id: workflowId, projectId })
  EventBus.$emit('reset-diagram')
}
