import cognitoAuth from '@/cognito'
import AWS from 'aws-sdk'

class Storage {
  constructor () {
    this.config = {
      apiVersion: '2006-03-01',
      params: {
        Bucket: process.env.VUE_APP_S3_BUCKET_FILE_UPLOAD_NAME
      }
    }
    this.s3 = new AWS.S3()
  }

  async init () {
    const token = await cognitoAuth.getIdTokenPromise()

    AWS.config.update({
      region: process.env.VUE_APP_AWS_REGION,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
        Logins: {
          [`cognito-idp.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${process.env.VUE_APP_COGNITO_USER_POOL_ID}`]: token
        }
      })
    })

    this.s3 = new AWS.S3(this.config)
  }

  async uploadFile (path, file) {
    if (!this.config.region) {
      await this.init()
    }

    const params = {
      Body: file,
      Key: path
    }

    try {
      // not sure why this is needed
      // without deleting IdentityId file uploads
      // fail the first time for a new user
      delete AWS.config.credentials.params.IdentityId
      const result = await this.s3.upload(params).promise()

      return result
    } catch (e) {
      throw new Error(e.message)
    }
  }

  async deleteFile (key) {
    if (!this.config.region) {
      await this.init()
    }

    const params = {
      Bucket: this.config.params.Bucket,
      Key: key
    }

    try {
      await this.s3.deleteObject(params).promise()
    } catch (e) {
      throw new Error(e.message)
    }
  }
}

Storage.region = process.env.VUE_APP_AWS_REGION

export default Storage
