<template>
  <v-card class="auth-form-container pa-6">
    <img src="@/assets/qlerify-logo-small.png"
        name="preview-image-url" alt="Qlerify logo" class="mx-auto" style="display: block;"/>
        <h3 class="text-h5 font-weight-bold pb-4 text-center">Sign up for free.</h3>
    <google-sign-in-button isForSignUp/>
    <text-divider text="or" class="mr-n2"/>
    <h3 class="text-h5 font-weight-bold pb-4 text-center">Sign up with your email address.</h3>
    <v-form ref="form" v-model="valid">
      <v-container>
        <v-row>
          <v-col xs="6" class="pl-0">

            <v-text-field
              autofocus
              name="firstname"
              variant="solo"
              validate-on="blur"
              v-model="firstname"
              :maxLength="40"
              hint="At most 40 characters"
              :rules="[rules.required]"
            >
              <template #label>
                <span >First Name <strong class="text-red">*</strong></span>
              </template>
            </v-text-field>
          </v-col>

          <v-col xs="6" class="pr-0">

            <v-text-field
              name="lastname"
              variant="solo"
              validate-on="blur"
              v-model="lastname"
              :maxLength="40"
              hint="At most 40 characters"
              :rules="[rules.required]"
            >
              <template #label>
                <span>Last Name <strong class="text-red">*</strong></span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="my-2">
          <v-col class="pa-0">

              <v-text-field
                name="phoneNumber"
                label="Phone number"
                variant="solo"
                validate-on="blur"
                v-model="phoneNumber"
                :rules="[rules.phoneNumber]"
              ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="my-2">
          <v-col class="pa-0">

            <v-text-field
              name="organisation"
              label="Organisation"
              variant="solo"
              validate-on="blur"
              v-model="company"
              :rules="[rules.required]"
              hint="An organisation is an entity that runs one or several development projects"
            >
              <template #label>
                <span>Organisation <strong class="text-red">*</strong></span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="my-2">
          <v-col class="pa-0">

            <v-text-field
              name="email"
              type="email"
              variant="solo"
              validate-on="blur"
              v-model="email"
              :rules="[rules.required, rules.email]"
            >
              <template #label>
                <span>Email <strong class="text-red">*</strong></span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="my-2">
          <v-col class="pa-0">

            <v-text-field
              id="password"
              name="password"
              counter
              variant="solo"
              v-model="password"
              :append-inner-icon="e1 ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append-inner="() => (e1 = !e1)"
              :type="e1 ? 'password' : 'text'"
              :rules="[rules.required]"
              min="8"
              hint="At least 8 characters, requires a mix of characters and numbers"
            >
              <template #label>
                <span>Password <strong class="text-red">*</strong></span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="my-2">
          <v-col class="pa-0">

            <v-textarea
              name="referralSource"
              label="How did you hear about us?"
              variant="solo"
              validate-on="blur"
              v-model="referralSource"
              :rules="[rules.required]"
            >
              <template #label>
                <span>How did you hear about us? <strong class="text-red">*</strong></span>
              </template>
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="my-2">
          <v-col class="pa-0">
            <v-textarea
              name="usageIntent"
              label="How do you intend to use Qlerify?"
              variant="solo"
              validate-on="blur"
              v-model="usageIntent"
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-container class="fluid my-2 mx-auto pa-0 text-center">
          <v-checkbox v-model="checkbox" :rules="[rules.terms]" class="d-inline-block mt-0">
            <template v-slot:label>
              <div>
                I agree to the
                <strong
                  ><u
                    ><a
                      onclick="window.open('../../Qlerify_terms_of_service_and_privacy_policy.pdf','_blank');"
                      href="#"
                      >Terms of Service & Privacy Policy</a
                    ></u
                  ></strong
                >
              </div>
            </template>
          </v-checkbox>
        </v-container>

        <v-row class="my-0">
          <v-col class="pa-0">
            <p class="bg-error" v-html="error" />
            <p class="message" v-html="message" />
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="pa-0 d-flex justify-center">
            <v-btn color="primary" min-width="200px" class="d-flex" @click="submit"> Create account </v-btn>
          </v-col>
        </v-row>

        <v-row class="my-6">
          <v-col class="pa-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="pa-0 d-flex justify-center">
            <p class="ma-0">
              Already have an account?

              <router-link :to="{ path: '/login' }">
                <span class="light--text">Log in</span>
              </router-link>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>

import GoogleSignInButton from '@/components/GoogleSignInButton.vue'
import TextDivider from '@/components/TextDivider.vue'

export default {
  components: { TextDivider, GoogleSignInButton },
  data () {
    return {
      checkbox: false,
      dialog: false,
      e1: true,
      email: '',
      error: null,
      message: null,
      firstname: '',
      lastname: '',
      password: '',
      referralSource: '',
      usageIntent: '',
      company: null,
      phoneNumber: null,
      rules: {
        required: (value) => !!value || 'Required',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail'
        },
        phoneNumber: (value) => {
          const pattern = /^\+[1-9]\d{1,14}$/
          return !value || pattern.test(value) || 'Invalid phone number. Must start with a + sign followed by country code and numbers without spaces or punctuation'
        },
        terms: (value) => value || 'You must agree to continue!'
      },
      valid: true
    }
  },
  methods: {
    async submit () {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.$cognitoAuth.signup(this.firstname, this.lastname, this.company, this.phoneNumber, this.email, this.password, this.referralSource, this.usageIntent, (err, result) => {
          if (err) {
            if (err.message.includes('Invalid phone number format.')) {
              err.message = 'Invalid phone number format or invalid country code.'
            }
            this.error = err.message
          } else {
            this.$router.replace({ path: '/confirm', query: { username: this.email, projectId: this.$route.query.projectId } })
          }
        })
      } else {
        this.error = 'One or more fields are invalid.'
      }
    }
  }
}
</script>

<style scoped>
p.error {
  color: red;
  background: none !important;
  border: none;
}

a {
  color: black;
}

.auth-form-container {
  max-width: 600px;
  margin: 24px auto;
}

.v-tooltip__content {
  background: white;
  color: var(--color-primary);
  border-radius: 10px;
}

.required:after {
  content:" *";
  color: red;
}

</style>
