<template>
  <v-data-table
    v-if="$store.state.isUserLoggedIn"
    :headers="headers"
    :items="versions"
    :items-per-page="100"
    class="elevation-1"
    disable-pagination
    hide-default-footer
  >
    <template v-slot:bottom></template>
    <template v-slot:item.current="{ item, index }">
      <v-icon v-if="index == 0 && item">check</v-icon>
    </template>
    <template v-slot:item.action="{ item, index }">
      <v-btn v-if="index != 0 && $store.state.workflow.isViewOnlyEnabled === false" size="small" @click="restoreVersion(item.version)" :disabled="$store.state.isWorkflowDisabled"> Restore </v-btn>
    </template>
    <template v-slot:item.updatedAt="{ item }">
      {{
        item.updatedAt ?
          formatDistanceToNowStrict(new Date(item.updatedAt), { addSuffix: true })
        :
          ''
      }}
    </template>
    <template v-slot:item.updateNote="{ item }">
      <strong>{{ item.updateNote?.userRealName }}</strong>
      {{ item.updateNote?.action }}
      <strong v-html="item.updateNote?.target"></strong>
    </template>
  </v-data-table>
  <div v-else class="history-view-mode">
    <v-icon color="white" >info</v-icon>
    <span>You need to <a href="/login">log in</a> to see Workflow history</span>
  </div>
</template>

<script>
import workflowApi from '@/api/workflowApi'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
// import { mapState } from 'vuex'

export default {
  props: {
    workflow: Object,
    versions: Array
  },
  data: () => ({
    headers: [
      {
        title: 'Current',
        value: 'current',
        width: '100px',
        sortable: false
      },
      {
        title: 'When',
        value: 'updatedAt',
        width: '200px',
        sortable: false
      },
      {
        title: 'Description',
        align: 'start',
        sortable: false,
        value: 'updateNote'
      },
      {
        title: 'Version',
        value: 'version',
        width: '100px',
        align: 'end',
        sortable: false
      },
      {
        title: 'Action',
        value: 'action',
        align: 'end',
        width: '200px',
        sortable: false
      }
    ]
  }),
  methods: {
    formatDistanceToNowStrict,
    restoreVersion (version) {
      workflowApi.restorePreviousWorkflowVersion(version, this.workflow)
    }
  }
  // computed: {
  //   ...mapState({ workflowHistory: state => state.workflowHistory.workflowHistory })
  // }
}
</script>

<style>
.hidden {
  display: none;
}

.search-field {
  align-self: center;
}

.history-view-mode {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 5px;
  border-radius: 3px;
  background: #4C83D1;
  color: #ffffff;
}
.history-view-mode span {
  margin-left: 5px;
}
</style>
