<template>
  <v-card v-if="project" class="px-2 mb-4">
    <v-container class="mx-0 hover-container">
      <v-row class="align-center hover-container">
        <v-col class="flex-grow-0 px-0">
          <v-icon icon="mdi-account-outline" color="grey-darken-1"></v-icon>
        </v-col>

        <v-col>
          <p v-if="teamMember.user" class="ma-0">
            {{ teamMember.user.firstname }} {{ teamMember.user.lastname }}
          </p>
          <p v-else class="ma-0">
            {{ teamMember.email }}
          </p>
        </v-col>
        <v-col class="flex-grow-0 pa-0 show-on-hover">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                :ripple="false"
                variant="plain"
                size="large"
                v-bind="props"
                class="pr-2 justify-end"
              >
                <v-icon icon="mdi-dots-vertical" size="large"></v-icon>
              </v-btn>
            </template>
            <v-list density="compact">
              <v-list-item prepend-icon="mdi-delete-outline" :disabled="teamMember.user?.id == project.organisation.userId" @click="deleteTeamMember($router, teamMember, $route.params.projectId)">

                  <v-list-item-title>Remove Team Member {{ teamMember.user?.id == project.organisation.userId ? '(cannot remove owner)' : '' }} {{ teamMember.user?.id == $store.state.userId ? '(you)' : '' }}</v-list-item-title>

              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col class="px-0 flex-grow-0">
          <v-tooltip location="top" open-delay="1000" v-if="teamMember.user">
            <template v-slot:activator="{ props }">
              <i
                v-bind="props"
                :class="{
                  'online-status': true,
                  online: isToday(new Date(teamMember.lastLogin)),
                  recent: isYesterday(new Date(teamMember.lastLogin)),
                  offline:
                    !isToday(new Date(teamMember.lastLogin)) &&
                    !isYesterday(new Date(teamMember.lastLogin)),
                }"
                >&nbsp;</i
              >
            </template>

            <span
              >
              {{
                teamMember.lastLogin ?
                'Last login ' + formatDistanceToNow(new Date(teamMember.lastLogin), {
                  addSuffix: true,
                }) : 'Not logged in yet'
              }}</span
            >
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import projectApi from '@/api/projectApi'

export default {
  props: {
    teamMember: Object,
    project: Object
  },

  data () {
    return {
    }
  },

  methods: {
    isToday,
    isYesterday,
    formatDistanceToNow,
    deleteTeamMember: projectApi.deleteTeamMember
  }
}
</script>

<style scoped>
.online-status {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.online-status.online {
  background: #c6f4b0;
}

.online-status.recent {
  background: #ffea86;
}

.online-status.offline {
  background: #f16e81;
}
</style>
